// If you are trying to change or add a service region, you've come to the right place.

import { Duration } from 'luxon';

import { DropOffOrganization, LocationPositionInput, TimeRangeRuleInput } from '../API';
import { MILE_IN_METERS } from '../constants';
import { TimezoneID } from '../formatters/formatTimezone';
import { AgeEligibilityRequirements } from '../types/AgeEligibilityRequirements';
import createWeekdaysTimeRangeRules from './createWeekdaysTimeRangeRules';

// note: the screening number is the index of the AgeEligibilityRequirements list
const leadScreeningEligibilityByStateInitials: {
  [geographicalState: string]: AgeEligibilityRequirements[];
} = {
  UT: [
    { lowerLimitMonths: 12, upperLimitMonths: 72 },
    { lowerLimitMonths: 24, upperLimitMonths: 72 },
  ],
  MA: [
    { lowerLimitMonths: 9, upperLimitMonths: 36 },
    { lowerLimitMonths: 24, upperLimitMonths: 36 },
    { lowerLimitMonths: 36, upperLimitMonths: Infinity },
  ],
};

// See https://docs.google.com/spreadsheets/d/1bHHZRPNNYxH5wLoLrgHeHTmdwfGvTXszktT0ohFQJWU/edit#gid=887660138
const SERVICE_REGION_LOOKUP: {
  [key: string]: {
    isActive: boolean;
    isActiveOnlyForReferrals?: boolean;
    requiresMatchmakingToClaimInMPX: boolean;
    postalCodes: Set<string>;
    timezone: TimezoneID;
    displayName: string;
    etaPadding: Duration;
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: number;
    maxDistanceBetweenSharedSlotAppointmentsMeters: number;
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: number;
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: number;
    leadScreeningElibilityRules?: AgeEligibilityRequirements[];
    dropOffTimeRangeRuleOverrides?: Partial<Record<DropOffOrganization, TimeRangeRuleInput[]>>; // https://docs.google.com/spreadsheets/d/1cSXG9MjBoBy6diNeQY6L4QyclVII7-0ayb4xpS9BQvM/edit#gid=0
    approximateCenter: LocationPositionInput;
  };
} = {
  'ca-bay-area-dublin': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/Los_Angeles',
    displayName: 'Dublin',
    postalCodes: new Set(['94550', '94551', '94566', '94568', '94582', '94583', '94588']),
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 12 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    approximateCenter: { longitude: -121.83446374103532, latitude: 37.70933529965743 },
  },
  'ca-bay-area-fremont': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/Los_Angeles',
    displayName: 'Fremont',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    postalCodes: new Set([
      '94536',
      '94538',
      '94539',
      '94541',
      '94542',
      '94544',
      '94545',
      '94546',
      '94552',
      '94555',
      '94560',
      '94579',
      '94580',
      '94587',
    ]),
    approximateCenter: { longitude: -122.05361254620576, latitude: 37.61702325707019 },
  },
  'ca-bay-area-marin': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/Los_Angeles',
    displayName: 'Marin',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 12 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    postalCodes: new Set([
      '94901',
      '94903',
      '94904',
      '94920',
      '94925',
      '94930',
      '94939',
      '94941',
      '94945',
      '94947',
      '94949',
      '94957',
      '94960',
      '94964',
      '94965',
    ]),
    approximateCenter: { longitude: -122.54504134082134, latitude: 37.975266421878175 },
  },
  'ca-bay-area-oakland': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: false,
    timezone: 'America/Los_Angeles',
    displayName: 'Oakland',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    postalCodes: new Set([
      '94501',
      '94502',
      '94577',
      '94578',
      '94601',
      '94602',
      '94603',
      '94605',
      '94606',
      '94607',
      '94608',
      '94609',
      '94610',
      '94611',
      '94612',
      '94613',
      '94618',
      '94619',
      '94621',
      '94702',
      '94703',
      '94704',
      '94705',
      '94706',
      '94707',
      '94708',
      '94709',
      '94710',
      '94720',
    ]),
    approximateCenter: { longitude: -122.23795734457678, latitude: 37.816539379285224 },
  },
  'ca-bay-area-richmond': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: false,
    timezone: 'America/Los_Angeles',
    displayName: 'Richmond',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    postalCodes: new Set(['94530', '94801', '94803', '94804', '94805', '94806', '94850']),
    approximateCenter: { longitude: -122.32800056408485, latitude: 37.937758222935564 },
  },
  'ca-bay-area-modesto': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/Los_Angeles',
    displayName: 'Modesto',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '95307',
      '95326',
      '95328',
      '95350',
      '95351',
      '95354',
      '95355',
      '95356',
      '95357',
      '95358',
      '95361',
      '95367',
      '95368',
      '95380',
      '95382',
    ]),
    approximateCenter: { longitude: -120.94700410282755, latitude: 37.63663429600152 },
  },
  'ca-bay-area-san-jose-east': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: false,
    timezone: 'America/Los_Angeles',
    displayName: 'San Jose (East)',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '95035',
      '95110',
      '95111',
      '95112',
      '95116',
      '95121',
      '95122',
      '95127',
      '95131',
      '95132',
      '95133',
      '95136',
      '95148',
    ]),
    approximateCenter: { longitude: -121.8430475039113, latitude: 37.350287257662416 },
  },
  'ca-bay-area-stockton-downtown': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: false,
    timezone: 'America/Los_Angeles',
    displayName: 'Stockton (Downtown)',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '95202',
      '95203',
      '95204',
      '95205',
      '95206',
      '95207',
      '95209',
      '95210',
      '95211',
      '95212',
      '95215',
      '95231',
      '95240',
      '95330',
      '95336',
    ]),
    approximateCenter: { longitude: -121.28243741794186, latitude: 37.964168540388116 },
  },
  'ca-bay-area-stockton-outer': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/Los_Angeles',
    displayName: 'Stockton (Outer)',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '95219',
      '95304',
      '95316',
      '95320',
      '95323',
      '95337',
      '95366',
      '95376',
      '95386',
    ]),
    approximateCenter: { longitude: -121.07437601038772, latitude: 37.73275585513039 },
  },
  'ca-bay-area-peninsula': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: false,
    timezone: 'America/Los_Angeles',
    displayName: 'Peninsula',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    dropOffTimeRangeRuleOverrides: {
      [DropOffOrganization.LABCORP]: createWeekdaysTimeRangeRules(
        '7f4ab76b-bc35-4b65-a10d-a56b6c3c16d8',
        '07:00',
        '14:00',
      ),
    },
    postalCodes: new Set([
      '94002',
      '94010',
      '94027',
      '94030',
      '94061',
      '94062',
      '94063',
      '94065',
      '94066',
      '94070',
      '94080',
      '94128',
      '94401',
      '94402',
      '94403',
      '94404',
    ]),
    approximateCenter: { longitude: -122.31084487750232, latitude: 37.54135075003698 },
  },
  'ca-bay-area-palo-alto': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: false,
    timezone: 'America/Los_Angeles',
    displayName: 'Palo Alto',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    dropOffTimeRangeRuleOverrides: {
      [DropOffOrganization.LABCORP]: createWeekdaysTimeRangeRules(
        'd73c3000-f803-4608-99b7-a950400ff3e4',
        '07:00',
        '14:00',
      ),
    },
    postalCodes: new Set([
      '94022',
      '94024',
      '94025',
      '94028',
      '94035',
      '94040',
      '94041',
      '94043',
      '94301',
      '94303',
      '94304',
      '94305',
      '94306',
    ]),
    approximateCenter: { longitude: -122.12717189912767, latitude: 37.404936790421516 },
  },
  'ca-bay-area-san-francisco': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: false,
    timezone: 'America/Los_Angeles',
    displayName: 'San Francisco',
    etaPadding: Duration.fromISOTime('00:10'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 12 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 3 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 3 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 3 * MILE_IN_METERS,
    dropOffTimeRangeRuleOverrides: {
      [DropOffOrganization.LABCORP]: createWeekdaysTimeRangeRules(
        '5ac9e259-7379-426b-aee1-bb3561dccb67',
        '07:00',
        '14:00',
      ),
    },
    postalCodes: new Set([
      '94005',
      '94014',
      '94015',
      '94102',
      '94103',
      '94104',
      '94105',
      '94107',
      '94108',
      '94109',
      '94110',
      '94111',
      '94112',
      '94114',
      '94115',
      '94116',
      '94117',
      '94118',
      '94121',
      '94122',
      '94123',
      '94124',
      '94127',
      '94129',
      '94130',
      '94131',
      '94132',
      '94133',
      '94134',
      '94143',
      '94158',
    ]),
    approximateCenter: { longitude: -122.4321485903041, latitude: 37.76111940754707 },
  },
  'ca-bay-area-south': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: false,
    timezone: 'America/Los_Angeles',
    displayName: 'South Bay',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    postalCodes: new Set([
      '94085',
      '94086',
      '94087',
      '94089',
      '95002',
      '95008',
      '95014',
      '95030',
      '95032',
      '95050',
      '95051',
      '95053',
      '95054',
      '95070',
      '95113',
      '95117',
      '95118',
      '95119',
      '95120',
      '95123',
      '95124',
      '95125',
      '95126',
      '95128',
      '95129',
      '95130',
      '95134',
      '95135',
      '95138',
      '95139',
    ]),
    approximateCenter: { longitude: -121.92549926976255, latitude: 37.30360521434154 },
  },
  'ca-bay-area-walnut-creek': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/Los_Angeles',
    displayName: 'Walnut Creek',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 12 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    postalCodes: new Set([
      '94506',
      '94507',
      '94516',
      '94517',
      '94518',
      '94519',
      '94520',
      '94521',
      '94523',
      '94526',
      '94528',
      '94549',
      '94556',
      '94563',
      '94575',
      '94595',
      '94596',
      '94597',
      '94598',
    ]),
    approximateCenter: { longitude: -122.03994526369922, latitude: 37.89047775648403 },
  },
  'ca-bay-area-napa': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/Los_Angeles',
    displayName: 'Napa',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    postalCodes: new Set([
      '94533',
      '94534',
      '94558',
      '94559',
      '94562',
      '94585',
      '94599',
      '95687',
      '95688',
    ]),
    approximateCenter: { longitude: -122.14288433871191, latitude: 38.32672941573963 },
  },
  'ca-bay-area-vallejo': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/Los_Angeles',
    displayName: 'Vallejo',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    postalCodes: new Set([
      '94503',
      '94510',
      '94525',
      '94547',
      '94553',
      '94564',
      '94569',
      '94572',
      '94589',
      '94590',
      '94591',
      '94592',
    ]),
    approximateCenter: { longitude: -122.22191012619744, latitude: 38.07067894318017 },
  },
  'ca-bay-area-antioch': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/Los_Angeles',
    displayName: 'Antioch',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 12 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    postalCodes: new Set([
      '94505',
      '94509',
      '94511',
      '94513',
      '94514',
      '94531',
      '94548',
      '94561',
      '94565',
    ]),
    approximateCenter: { longitude: -121.71209591443449, latitude: 37.95281758251127 },
  },
  'ca-bay-area-sacramento': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/Los_Angeles',
    displayName: 'Sacramento',
    etaPadding: Duration.fromISOTime('00:10'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 7 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 7 * MILE_IN_METERS,
    postalCodes: new Set([
      '95605',
      '95616',
      '95618',
      '95624',
      '95691',
      '95758',
      '95776',
      '95811',
      '95814',
      '95815',
      '95816',
      '95817',
      '95818',
      '95819',
      '95820',
      '95821',
      '95822',
      '95823',
      '95824',
      '95825',
      '95826',
      '95828',
      '95829',
      '95831',
      '95832',
      '95833',
      '95834',
      '95835',
      '95838',
      '95864',
    ]),
    approximateCenter: { longitude: -121.48247358938804, latitude: 38.55953102421519 },
  },
  'ca-bay-area-east-sacramento': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/Los_Angeles',
    displayName: 'Sacramento (East)',
    etaPadding: Duration.fromISOTime('00:10'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 7 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 7 * MILE_IN_METERS,
    postalCodes: new Set([
      '95608',
      '95610',
      '95621',
      '95628',
      '95630',
      '95655',
      '95660',
      '95661',
      '95662',
      '95670',
      '95677',
      '95678',
      '95746',
      '95747',
      '95765',
      '95827',
      '95841',
      '95842',
      '95843',
    ]),
    approximateCenter: { longitude: -121.28610808606257, latitude: 38.68998601448164 },
  },
  'ca-bay-area-placerville': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/Los_Angeles',
    displayName: 'Placerville',
    etaPadding: Duration.fromISOTime('00:10'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 7 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 7 * MILE_IN_METERS,
    postalCodes: new Set([
      '95613',
      '95619',
      '95623',
      '95633',
      '95634',
      '95635',
      '95651',
      '95664',
      '95667',
      '95672',
      '95682',
      '95684',
      '95709',
      '95726',
      '95762',
    ]),
    approximateCenter: { longitude: -120.82520778365466, latitude: 38.74839918050259 },
  },
  'ca-los-angeles-central': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/Los_Angeles',
    displayName: 'Central LA',
    etaPadding: Duration.fromISOTime('00:10'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 10 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 3 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 3 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 3 * MILE_IN_METERS,
    postalCodes: new Set([
      '90004',
      '90005',
      '90006',
      '90007',
      '90010',
      '90011',
      '90012',
      '90013',
      '90014',
      '90015',
      '90017',
      '90018',
      '90019',
      '90020',
      '90021',
      '90026',
      '90028',
      '90029',
      '90037',
      '90038',
      '90057',
      '90062',
      '90071',
      '90089',
    ]),
    approximateCenter: { longitude: -118.28536331616222, latitude: 34.04993668911317 },
  },
  'ca-los-angeles-westside': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: false,
    timezone: 'America/Los_Angeles',
    displayName: 'Westside',
    etaPadding: Duration.fromISOTime('00:10'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 12 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 3 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 3 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 3 * MILE_IN_METERS,
    postalCodes: new Set([
      '90008',
      '90016',
      '90024',
      '90025',
      '90034',
      '90035',
      '90036',
      '90046',
      '90048',
      '90049',
      '90064',
      '90066',
      '90067',
      '90069',
      '90073',
      '90077',
      '90095',
      '90210',
      '90211',
      '90212',
      '90230',
      '90232',
      '90272',
      '90291',
      '90292',
      '90401',
      '90402',
      '90403',
      '90404',
      '90405',
    ]),
    approximateCenter: { longitude: -118.42741469585556, latitude: 34.04754307048275 },
  },
  'ca-los-angeles-south-bay': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: false,
    timezone: 'America/Los_Angeles',
    displayName: 'South Bay',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 12 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 3 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 3 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 3 * MILE_IN_METERS,
    postalCodes: new Set([
      '90043',
      '90045',
      '90056',
      '90094',
      '90245',
      '90250',
      '90254',
      '90260',
      '90266',
      '90274',
      '90275',
      '90277',
      '90278',
      '90293',
      '90301',
      '90302',
      '90303',
      '90304',
      '90305',
      '90501',
      '90502',
      '90503',
      '90504',
      '90505',
      '90506',
      '90710',
      '90717',
      '90731',
      '90732',
      '90744',
      '90745',
    ]),
    approximateCenter: { longitude: -118.34903977207833, latitude: 33.87254550217576 },
  },
  'ca-los-angeles-thousand-oaks': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    isActiveOnlyForReferrals: true,
    timezone: 'America/Los_Angeles',
    displayName: 'Thousand Oaks',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 12 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    postalCodes: new Set([
      '90263',
      '90265',
      '90290',
      '91301',
      '91302',
      '91320',
      '91360',
      '91361',
      '91362',
      '91377',
    ]),
    approximateCenter: { longitude: -118.78936445450559, latitude: 34.1364637203348 },
  },
  'ca-los-angeles-pasadena': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/Los_Angeles',
    displayName: 'Pasadena',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 12 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    postalCodes: new Set([
      '90041',
      '90042',
      '91001',
      '91006',
      '91007',
      '91008',
      '91010',
      '91011',
      '91016',
      '91024',
      '91030',
      '91101',
      '91103',
      '91104',
      '91105',
      '91106',
      '91107',
      '91108',
      '91123',
      '91125',
      '91203',
      '91204',
      '91205',
      '91206',
      '91210',
      '91731',
      '91732',
      '91733',
      '91775',
      '91780',
    ]),
    approximateCenter: { longitude: -118.12163060140387, latitude: 34.13747626050343 },
  },
  'ca-los-angeles-compton': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/Los_Angeles',
    displayName: 'Compton',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 10 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '90001',
      '90002',
      '90003',
      '90044',
      '90047',
      '90058',
      '90059',
      '90061',
      '90201',
      '90220',
      '90221',
      '90222',
      '90241',
      '90242',
      '90247',
      '90248',
      '90249',
      '90255',
      '90262',
      '90270',
      '90280',
      '90650',
      '90706',
      '90723',
      '90746',
      '90747',
      '90805',
    ]),
    approximateCenter: { longitude: -118.2215659056073, latitude: 33.92395389939203 },
  },
  'ca-los-angeles-el-monte-cerritos': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: false,
    timezone: 'America/Los_Angeles',
    displayName: 'El Monte Cerritos',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 10 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '90601',
      '90602',
      '90603',
      '90604',
      '90605',
      '90606',
      '90638',
      '90639',
      '90670',
      '90701',
      '90703',
      '90713',
      '90715',
      '90716',
    ]),
    approximateCenter: { longitude: -118.04629932169846, latitude: 33.91291044378387 },
  },
  'ca-los-angeles-griffith-park': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: false,
    timezone: 'America/Los_Angeles',
    displayName: 'Griffith Park',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 10 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set(['90027', '90039', '90065', '90068', '90079', '90090']),
    approximateCenter: { longitude: -118.2693457986506, latitude: 34.09771495586931 },
  },
  'ca-los-angeles-orange-county-north': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/Los_Angeles',
    displayName: 'Orange County (North)',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    postalCodes: new Set([
      '90620',
      '90621',
      '90623',
      '90630',
      '90631',
      '90680',
      '90720',
      '90740',
      '92646',
      '92647',
      '92648',
      '92649',
      '92655',
      '92683',
      '92703',
      '92706',
      '92708',
      '92801',
      '92802',
      '92804',
      '92805',
      '92806',
      '92807',
      '92808',
      '92821',
      '92823',
      '92831',
      '92832',
      '92833',
      '92835',
      '92840',
      '92841',
      '92843',
      '92844',
      '92845',
      '92861',
      '92862',
      '92865',
      '92866',
      '92867',
      '92868',
      '92870',
      '92886',
      '92887',
    ]),
    approximateCenter: { longitude: -117.92273104987937, latitude: 33.81372917125466 },
  },
  'ca-los-angeles-orange-county-south': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/Los_Angeles',
    displayName: 'Orange County (South)',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    postalCodes: new Set([
      '92602',
      '92603',
      '92604',
      '92606',
      '92610',
      '92612',
      '92614',
      '92617',
      '92618',
      '92620',
      '92624',
      '92625',
      '92626',
      '92627',
      '92629',
      '92630',
      '92637',
      '92651',
      '92653',
      '92656',
      '92657',
      '92660',
      '92661',
      '92662',
      '92663',
      '92672',
      '92673',
      '92675',
      '92677',
      '92688',
      '92691',
      '92692',
      '92694',
      '92697',
      '92701',
      '92704',
      '92705',
      '92707',
      '92780',
      '92782',
      '92869',
    ]),
    approximateCenter: { longitude: -117.76951292654682, latitude: 33.628998192239294 },
  },
  'ca-los-angeles-west-covina': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/Los_Angeles',
    displayName: 'West Covina',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 12 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    postalCodes: new Set([
      '91702',
      '91706',
      '91722',
      '91723',
      '91724',
      '91740',
      '91741',
      '91744',
      '91745',
      '91746',
      '91748',
      '91773',
      '91789',
      '91790',
      '91791',
      '91792',
    ]),
    approximateCenter: { longitude: -117.89370079727689, latitude: 34.078230204769774 },
  },
  'ca-los-angeles-long-beach': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: false,
    timezone: 'America/Los_Angeles',
    displayName: 'Long Beach',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 12 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 3 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 3 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 3 * MILE_IN_METERS,
    postalCodes: new Set([
      '90712',
      '90755',
      '90802',
      '90803',
      '90804',
      '90806',
      '90807',
      '90808',
      '90810',
      '90813',
      '90814',
      '90815',
      '90822',
      '90840',
    ]),
    approximateCenter: { longitude: -118.15655654193925, latitude: 33.795299460742 },
  },
  'ca-los-angeles-east': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/Los_Angeles',
    displayName: 'East LA',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 10 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 3 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 3 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 3 * MILE_IN_METERS,
    postalCodes: new Set([
      '90022',
      '90023',
      '90031',
      '90032',
      '90033',
      '90040',
      '90063',
      '90240',
      '90640',
      '90660',
      '91754',
      '91755',
      '91770',
      '91776',
      '91801',
      '91803',
    ]),
    approximateCenter: { longitude: -118.1453375183928, latitude: 34.042705488417454 },
  },
  'ca-los-angeles-burbank': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/Los_Angeles',
    displayName: 'Burbank',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 12 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    postalCodes: new Set([
      '91201',
      '91202',
      '91207',
      '91352',
      '91501',
      '91502',
      '91504',
      '91505',
      '91506',
      '91521',
      '91522',
      '91523',
      '91601',
      '91602',
      '91605',
      '91606',
      '91607',
      '91608',
    ]),
    approximateCenter: { longitude: -118.33514053654307, latitude: 34.175458078650664 },
  },
  'ca-los-angeles-san-fernando': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/Los_Angeles',
    displayName: 'San Fernando',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 12 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    postalCodes: new Set([
      '91311',
      '91324',
      '91325',
      '91326',
      '91330',
      '91331',
      '91340',
      '91342',
      '91343',
      '91344',
      '91345',
      '91402',
    ]),
    approximateCenter: { longitude: -118.4898380128392, latitude: 34.26499222033042 },
  },
  'ca-los-angeles-sherman-oaks': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/Los_Angeles',
    displayName: 'Sherman Oaks',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 12 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    postalCodes: new Set([
      '91303',
      '91306',
      '91307',
      '91316',
      '91335',
      '91356',
      '91364',
      '91367',
      '91371',
      '91401',
      '91403',
      '91405',
      '91406',
      '91411',
      '91423',
      '91436',
      '91604',
    ]),
    approximateCenter: { longitude: -118.52032757491676, latitude: 34.17550064958255 },
  },
  'ca-los-angeles-riverside': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: false,
    timezone: 'America/Los_Angeles',
    displayName: 'Riverside',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    dropOffTimeRangeRuleOverrides: {
      [DropOffOrganization.QUEST]: createWeekdaysTimeRangeRules(
        '732f621f-45d1-41b3-bd5d-bbc8d602719f',
        '07:00',
        '18:00',
      ),
    },
    postalCodes: new Set([
      '91752',
      '92313',
      '92316',
      '92317',
      '92318',
      '92321',
      '92322',
      '92324',
      '92325',
      '92335',
      '92336',
      '92337',
      '92346',
      '92350',
      '92352',
      '92354',
      '92373',
      '92374',
      '92376',
      '92377',
      '92378',
      '92385',
      '92391',
      '92401',
      '92404',
      '92405',
      '92407',
      '92408',
      '92410',
      '92411',
      '92415',
      '92501',
      '92503',
      '92504',
      '92505',
      '92506',
      '92507',
      '92508',
      '92509',
      '92518',
      '92521',
      '92551',
      '92553',
      '92555',
      '92557',
      '92860',
      '92878',
      '92879',
      '92880',
      '92881',
      '92882',
    ]),
    approximateCenter: { longitude: -117.3380987997555, latitude: 34.04773405295545 },
  },
  'ca-los-angeles-sun-city': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/Los_Angeles',
    displayName: 'Sun City',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    dropOffTimeRangeRuleOverrides: {
      [DropOffOrganization.QUEST]: createWeekdaysTimeRangeRules(
        'd6b462cf-231b-4078-ace1-44d834d27b12',
        '07:00',
        '18:00',
      ),
    },
    postalCodes: new Set([
      '92543',
      '92545',
      '92548',
      '92567',
      '92570',
      '92571',
      '92582',
      '92583',
      '92584',
      '92585',
      '92586',
      '92587',
      '92596',
    ]),
    approximateCenter: { longitude: -117.11695889724106, latitude: 33.74778138271584 },
  },
  'ca-los-angeles-pamona': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/Los_Angeles',
    displayName: 'Pomona',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    postalCodes: new Set([
      '91701',
      '91708',
      '91709',
      '91710',
      '91711',
      '91730',
      '91737',
      '91739',
      '91750',
      '91761',
      '91762',
      '91763',
      '91764',
      '91765',
      '91766',
      '91767',
      '91768',
      '91784',
      '91786',
    ]),
    approximateCenter: { longitude: -117.67293827913296, latitude: 34.073108503314984 },
  },
  'ga-georgia-atlanta': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Atlanta (Central)',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '30002',
      '30030',
      '30032',
      '30033',
      '30079',
      '30303',
      '30305',
      '30306',
      '30307',
      '30308',
      '30309',
      '30310',
      '30311',
      '30312',
      '30313',
      '30314',
      '30315',
      '30316',
      '30317',
      '30318',
      '30319',
      '30320',
      '30322',
      '30324',
      '30326',
      '30327',
      '30329',
      '30332',
      '30334',
      '30337',
      '30339',
      '30341',
      '30342',
      '30344',
      '30345',
      '30354',
      '30363',
      '30382',
    ]),
    approximateCenter: { longitude: -84.36851623437599, latitude: 33.77692578306576 },
  },
  'ga-georgia-northeast-atlanta': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Atlanta (Northeast)',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '30017',
      '30021',
      '30024',
      '30039',
      '30043',
      '30044',
      '30045',
      '30046',
      '30047',
      '30071',
      '30072',
      '30078',
      '30083',
      '30084',
      '30087',
      '30092',
      '30093',
      '30096',
      '30097',
      '30340',
      '30360',
      '30371',
      '30393',
    ]),
    approximateCenter: { longitude: -84.1361789681905, latitude: 33.89758646844087 },
  },
  'ga-georgia-north-atlanta': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Atlanta (North)',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '30004',
      '30005',
      '30008',
      '30009',
      '30022',
      '30060',
      '30062',
      '30064',
      '30066',
      '30067',
      '30068',
      '30069',
      '30075',
      '30076',
      '30080',
      '30144',
      '30152',
      '30328',
      '30338',
      '30346',
      '30350',
    ]),
    approximateCenter: { longitude: -84.42907503543309, latitude: 33.98704041350889 },
  },
  'ga-georgia-southeast-atlanta': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Atlanta (Southeast)',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '30012',
      '30013',
      '30034',
      '30035',
      '30038',
      '30058',
      '30088',
      '30094',
      '30273',
      '30281',
      '30288',
      '30294',
    ]),
    approximateCenter: { longitude: -84.16389858742548, latitude: 33.66499448539992 },
  },
  'ga-georgia-south-atlanta': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Atlanta (South)',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '30213',
      '30214',
      '30236',
      '30238',
      '30260',
      '30272',
      '30274',
      '30291',
      '30296',
      '30297',
      '30349',
    ]),
    approximateCenter: { longitude: -84.44861558397147, latitude: 33.56758311471607 },
  },
  'ga-georgia-west-atlanta': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Atlanta (West)',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '30082',
      '30106',
      '30111',
      '30122',
      '30126',
      '30127',
      '30134',
      '30135',
      '30168',
      '30331',
      '30336',
    ]),
    approximateCenter: { longitude: -84.62996658112732, latitude: 33.78643967227722 },
  },
  'ny-new-york-midtown': {
    isActive: false,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'New York',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set(['10022']),
    approximateCenter: { longitude: -73.96793, latitude: 40.75857000000001 },
  },
  'fl-florida-south-miami': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: false,
    timezone: 'America/New_York',
    displayName: 'South Miami',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 12 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 3 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 3 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 3 * MILE_IN_METERS,
    dropOffTimeRangeRuleOverrides: {
      [DropOffOrganization.QUEST]: createWeekdaysTimeRangeRules(
        '1d7bfb29-96ee-4eff-88cb-ef074fa00d57',
        '06:00',
        '18:00',
      ),
    },
    postalCodes: new Set([
      '33010',
      '33012',
      '33013',
      '33014',
      '33015',
      '33016',
      '33018',
      '33030',
      '33031',
      '33032',
      '33033',
      '33034',
      '33035',
      '33039',
      '33054',
      '33055',
      '33056',
      '33122',
      '33125',
      '33126',
      '33127',
      '33128',
      '33129',
      '33130',
      '33131',
      '33132',
      '33133',
      '33134',
      '33135',
      '33136',
      '33137',
      '33138',
      '33139',
      '33140',
      '33141',
      '33142',
      '33143',
      '33144',
      '33145',
      '33146',
      '33147',
      '33149',
      '33150',
      '33154',
      '33155',
      '33156',
      '33157',
      '33158',
      '33160',
      '33161',
      '33162',
      '33165',
      '33166',
      '33167',
      '33168',
      '33169',
      '33170',
      '33172',
      '33173',
      '33174',
      '33175',
      '33176',
      '33177',
      '33178',
      '33179',
      '33180',
      '33181',
      '33182',
      '33183',
      '33184',
      '33185',
      '33186',
      '33187',
      '33189',
      '33190',
      '33193',
      '33194',
      '33196',
      '33199',
    ]),
    approximateCenter: { longitude: -80.30704801927314, latitude: 25.758416886317672 },
  },
  'fl-florida-fort-lauderdale': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: false,
    timezone: 'America/New_York',
    displayName: 'Fort Lauderdale',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    postalCodes: new Set([
      '33004',
      '33009',
      '33019',
      '33020',
      '33021',
      '33023',
      '33024',
      '33025',
      '33026',
      '33027',
      '33028',
      '33029',
      '33060',
      '33062',
      '33063',
      '33064',
      '33065',
      '33066',
      '33067',
      '33068',
      '33069',
      '33071',
      '33073',
      '33076',
      '33301',
      '33304',
      '33305',
      '33306',
      '33308',
      '33309',
      '33311',
      '33312',
      '33313',
      '33314',
      '33315',
      '33316',
      '33317',
      '33319',
      '33321',
      '33322',
      '33323',
      '33324',
      '33325',
      '33326',
      '33328',
      '33330',
      '33331',
      '33332',
      '33334',
      '33351',
      '33388',
      '33394',
      '33441',
      '33442',
    ]),
    approximateCenter: { longitude: -80.217609655664, latitude: 26.138045258192832 },
  },
  'fl-florida-palm-beach': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: false,
    timezone: 'America/New_York',
    displayName: 'Palm Beach',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    postalCodes: new Set([
      '33401',
      '33403',
      '33404',
      '33405',
      '33406',
      '33407',
      '33408',
      '33409',
      '33410',
      '33411',
      '33412',
      '33413',
      '33414',
      '33415',
      '33417',
      '33418',
      '33426',
      '33428',
      '33431',
      '33432',
      '33433',
      '33434',
      '33435',
      '33436',
      '33437',
      '33444',
      '33445',
      '33446',
      '33449',
      '33458',
      '33460',
      '33461',
      '33462',
      '33463',
      '33467',
      '33469',
      '33470',
      '33472',
      '33473',
      '33477',
      '33478',
      '33480',
      '33483',
      '33484',
      '33486',
      '33487',
      '33496',
      '33498',
    ]),
    approximateCenter: { longitude: -80.13246899247267, latitude: 26.614825685292388 },
  },
  'fl-florida-fort-myers': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: false,
    timezone: 'America/New_York',
    displayName: 'Fort Myers',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    postalCodes: new Set([
      '33901',
      '33903',
      '33904',
      '33905',
      '33907',
      '33908',
      '33909',
      '33912',
      '33913',
      '33914',
      '33916',
      '33917',
      '33919',
      '33920',
      '33922',
      '33928',
      '33931',
      '33936',
      '33956',
      '33957',
      '33965',
      '33966',
      '33967',
      '33971',
      '33972',
      '33973',
      '33974',
      '33976',
      '33990',
      '33991',
      '33993',
    ]),
    approximateCenter: { longitude: -81.85198311433595, latitude: 26.589343270448545 },
  },
  'fl-florida-naples': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Naples',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    postalCodes: new Set([
      '34102',
      '34103',
      '34104',
      '34105',
      '34108',
      '34109',
      '34110',
      '34112',
      '34113',
      '34114',
      '34116',
      '34117',
      '34119',
      '34120',
      '34134',
      '34135',
      '34145',
    ]),
    approximateCenter: { longitude: -81.72558536707471, latitude: 26.18250788457613 },
  },
  'ma-massachusetts-boston': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: false,
    timezone: 'America/New_York',
    displayName: 'Boston (Central)',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 8 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 2 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 2 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 2 * MILE_IN_METERS,
    leadScreeningElibilityRules: leadScreeningEligibilityByStateInitials.MA,
    postalCodes: new Set([
      '01890',
      '02108',
      '02109',
      '02110',
      '02111',
      '02113',
      '02114',
      '02115',
      '02116',
      '02118',
      '02119',
      '02120',
      '02121',
      '02125',
      '02127',
      '02128',
      '02129',
      '02130',
      '02134',
      '02135',
      '02138',
      '02139',
      '02140',
      '02141',
      '02142',
      '02143',
      '02144',
      '02145',
      '02148',
      '02149',
      '02150',
      '02151',
      '02152',
      '02155',
      '02163',
      '02176',
      '02210',
      '02215',
      '02445',
      '02446',
      '02452',
      '02453',
      '02458',
      '02459',
      '02460',
      '02461',
      '02462',
      '02464',
      '02465',
      '02466',
      '02467',
      '02468',
      '02472',
      '02474',
      '02476',
      '02478',
      '02494',
    ]),
    approximateCenter: { longitude: -71.11813690167558, latitude: 42.36278165771916 },
  },
  'ma-massachusetts-south-boston': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: false,
    timezone: 'America/New_York',
    displayName: 'Boston (South)',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 10 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 2 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 2 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 3 * MILE_IN_METERS,
    leadScreeningElibilityRules: leadScreeningEligibilityByStateInitials.MA,
    postalCodes: new Set([
      '02021',
      '02026',
      '02062',
      '02072',
      '02090',
      '02122',
      '02124',
      '02126',
      '02131',
      '02132',
      '02136',
      '02169',
      '02170',
      '02171',
      '02184',
      '02186',
      '02188',
      '02189',
      '02190',
      '02191',
      '02301',
      '02302',
      '02303',
      '02322',
      '02343',
      '02351',
      '02368',
      '02382',
      '02492',
    ]),
    approximateCenter: { longitude: -71.05773056726201, latitude: 42.202433395341544 },
  },
  'ma-massachusetts-fall-river': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Fall River',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    leadScreeningElibilityRules: leadScreeningEligibilityByStateInitials.MA,
    postalCodes: new Set([
      '02032',
      '02035',
      '02048',
      '02067',
      '02071',
      '02081',
      '02324',
      '02356',
      '02375',
      '02379',
      '02702',
      '02703',
      '02715',
      '02718',
      '02719',
      '02720',
      '02721',
      '02723',
      '02724',
      '02725',
      '02726',
      '02740',
      '02743',
      '02744',
      '02745',
      '02746',
      '02747',
      '02748',
      '02760',
      '02762',
      '02763',
      '02764',
      '02766',
      '02767',
      '02769',
      '02771',
      '02777',
      '02779',
      '02780',
      '02790',
    ]),
    approximateCenter: { longitude: -71.12315830317098, latitude: 41.84921336516423 },
  },
  'ma-massachusetts-southeast-boston': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Boston (Southeast)',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    leadScreeningElibilityRules: leadScreeningEligibilityByStateInitials.MA,
    postalCodes: new Set(['02066', '02338', '02532', '02576', '02649', '02668', '02739']),
    approximateCenter: { longitude: -70.66321952707028, latitude: 41.81787946360325 },
  },
  'ma-massachusetts-springfield': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Springfield',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    leadScreeningElibilityRules: leadScreeningEligibilityByStateInitials.MA,
    postalCodes: new Set([
      '01001',
      '01013',
      '01020',
      '01022',
      '01028',
      '01030',
      '01033',
      '01036',
      '01040',
      '01056',
      '01057',
      '01069',
      '01071',
      '01073',
      '01075',
      '01077',
      '01085',
      '01089',
      '01095',
      '01103',
      '01104',
      '01105',
      '01106',
      '01107',
      '01108',
      '01109',
      '01118',
      '01119',
      '01128',
      '01129',
      '01151',
    ]),
    approximateCenter: { longitude: -72.5648003577666, latitude: 42.13235803355799 },
  },
  'ut-utah-st-george': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/Denver',
    displayName: 'St. George',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 20 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    leadScreeningElibilityRules: leadScreeningEligibilityByStateInitials.UT,
    postalCodes: new Set([
      '84722',
      '84725',
      '84733',
      '84737',
      '84738',
      '84745',
      '84746',
      '84763',
      '84765',
      '84767',
      '84770',
      '84774',
      '84779',
      '84780',
      '84781',
      '84782',
      '84783',
      '84784',
      '84790',
    ]),
    approximateCenter: { longitude: -113.45402796371442, latitude: 37.2453982257753 },
  },
  'ut-utah-cedar-city': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/Denver',
    displayName: 'Cedar City',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 20 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    leadScreeningElibilityRules: leadScreeningEligibilityByStateInitials.UT,
    postalCodes: new Set(['84720', '84721', '84757']),
    approximateCenter: { longitude: -113.23184454999952, latitude: 37.60890598552821 },
  },
  'ut-utah-salt-lake-city': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: false,
    timezone: 'America/Denver',
    displayName: 'Salt Lake City',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 20 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    leadScreeningElibilityRules: leadScreeningEligibilityByStateInitials.UT,
    postalCodes: new Set([
      '84003',
      '84005',
      '84009',
      '84010',
      '84014',
      '84020',
      '84025',
      '84043',
      '84044',
      '84045',
      '84047',
      '84054',
      '84065',
      '84070',
      '84081',
      '84084',
      '84087',
      '84088',
      '84092',
      '84093',
      '84094',
      '84095',
      '84096',
      '84101',
      '84102',
      '84103',
      '84104',
      '84105',
      '84106',
      '84107',
      '84108',
      '84109',
      '84111',
      '84112',
      '84113',
      '84115',
      '84116',
      '84117',
      '84118',
      '84119',
      '84120',
      '84121',
      '84122',
      '84123',
      '84124',
      '84128',
      '84129',
      '84132',
      '84138',
      '84143',
      '84148',
      '84150',
    ]),
    approximateCenter: { longitude: -111.8979274910917, latitude: 40.67832948330096 },
  },
  'ut-utah-provo': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/Denver',
    displayName: 'Provo',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 20 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,

    leadScreeningElibilityRules: leadScreeningEligibilityByStateInitials.UT,
    postalCodes: new Set([
      '84004',
      '84042',
      '84057',
      '84058',
      '84059',
      '84062',
      '84097',
      '84601',
      '84602',
      '84604',
      '84606',
      '84633',
      '84651',
      '84653',
      '84655',
      '84660',
      '84663',
      '84664',
    ]),
    approximateCenter: { longitude: -111.67085345225793, latitude: 40.205735194264754 },
  },
  'ut-utah-tooele': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/Denver',
    displayName: 'Tooele',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 20 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    leadScreeningElibilityRules: leadScreeningEligibilityByStateInitials.UT,
    postalCodes: new Set(['84006', '84029', '84074']),
    approximateCenter: { longitude: -112.46026115503966, latitude: 40.58450963690276 },
  },
  'ut-utah-ogden': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/Denver',
    displayName: 'Ogden',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 20 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    leadScreeningElibilityRules: leadScreeningEligibilityByStateInitials.UT,
    postalCodes: new Set([
      '84015',
      '84037',
      '84040',
      '84041',
      '84056',
      '84067',
      '84075',
      '84201',
      '84315',
      '84401',
      '84403',
      '84404',
      '84405',
      '84408',
      '84414',
    ]),
    approximateCenter: { longitude: -112.00262814044206, latitude: 41.16302112270982 },
  },
  'ut-utah-logan': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/Denver',
    displayName: 'Logan',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 20 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    leadScreeningElibilityRules: leadScreeningEligibilityByStateInitials.UT,
    postalCodes: new Set([
      '84302',
      '84304',
      '84306',
      '84309',
      '84311',
      '84312',
      '84314',
      '84318',
      '84319',
      '84321',
      '84322',
      '84325',
      '84326',
      '84332',
      '84334',
      '84335',
      '84337',
      '84339',
      '84341',
    ]),
    approximateCenter: { longitude: -111.95945347587269, latitude: 41.731336282027605 },
  },
  'ca-los-angeles-san-diego': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: false,
    timezone: 'America/Los_Angeles',
    displayName: 'San Diego',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    dropOffTimeRangeRuleOverrides: {
      [DropOffOrganization.QUEST]: createWeekdaysTimeRangeRules(
        'ecde6328-26f7-47ec-b919-663d77fb0cdf',
        '07:00',
        '18:00',
      ),
    },
    postalCodes: new Set([
      '91902',
      '91910',
      '91911',
      '91913',
      '91914',
      '91915',
      '91932',
      '91941',
      '91942',
      '91945',
      '91950',
      '91977',
      '91978',
      '92014',
      '92019',
      '92020',
      '92021',
      '92037',
      '92040',
      '92064',
      '92071',
      '92093',
      '92101',
      '92102',
      '92103',
      '92104',
      '92105',
      '92106',
      '92107',
      '92108',
      '92109',
      '92110',
      '92111',
      '92113',
      '92114',
      '92115',
      '92116',
      '92117',
      '92118',
      '92119',
      '92120',
      '92121',
      '92122',
      '92123',
      '92124',
      '92126',
      '92128',
      '92129',
      '92130',
      '92131',
      '92134',
      '92135',
      '92136',
      '92139',
      '92140',
      '92145',
      '92152',
      '92154',
      '92155',
      '92173',
      '92182',
    ]),
    approximateCenter: { longitude: -117.09693639325641, latitude: 32.765422430804946 },
  },
  'ca-los-angeles-oceanside': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/Los_Angeles',
    displayName: 'Oceanside',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '92007',
      '92008',
      '92009',
      '92010',
      '92011',
      '92024',
      '92025',
      '92026',
      '92027',
      '92029',
      '92054',
      '92056',
      '92057',
      '92058',
      '92065',
      '92067',
      '92069',
      '92075',
      '92078',
      '92081',
      '92083',
      '92084',
      '92091',
      '92096',
      '92127',
    ]),
    approximateCenter: { longitude: -117.19944437571849, latitude: 33.125941658098505 },
  },
  'ca-los-angeles-calexico': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/Los_Angeles',
    displayName: 'Calexico',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    postalCodes: new Set(['92227', '92231', '92243', '92249', '92250', '92251', '92281']),
    approximateCenter: { longitude: -115.4909256271646, latitude: 32.8388342158977 },
  },
  'mi-michigan-harrison': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Harrison',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 25 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '48612',
      '48617',
      '48618',
      '48622',
      '48624',
      '48625',
      '48628',
      '48632',
      '48640',
      '48642',
      '48657',
      '48858',
      '48878',
      '48883',
    ]),
    approximateCenter: { longitude: -84.60639524286273, latitude: 43.77891521631247 },
  },
  'mi-michigan-pontiac': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: false,
    timezone: 'America/New_York',
    displayName: 'Pontiac',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '48009',
      '48083',
      '48084',
      '48085',
      '48098',
      '48301',
      '48302',
      '48304',
      '48307',
      '48309',
      '48310',
      '48314',
      '48320',
      '48322',
      '48323',
      '48324',
      '48326',
      '48327',
      '48328',
      '48329',
      '48340',
      '48341',
      '48342',
      '48359',
    ]),
    approximateCenter: { longitude: -83.25374075940023, latitude: 42.61318383266602 },
  },
  'mi-michigan-roseville': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: false,
    timezone: 'America/New_York',
    displayName: 'Roseville',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    dropOffTimeRangeRuleOverrides: {
      [DropOffOrganization.QUEST]: createWeekdaysTimeRangeRules(
        '5b945558-e503-4d94-8236-323f4c4da60f',
        '07:00',
        '18:00',
      ),
    },
    postalCodes: new Set([
      '48015',
      '48021',
      '48026',
      '48035',
      '48036',
      '48038',
      '48043',
      '48045',
      '48066',
      '48080',
      '48081',
      '48082',
      '48088',
      '48089',
      '48093',
      '48205',
      '48224',
      '48225',
      '48230',
      '48236',
      '48312',
      '48313',
    ]),
    approximateCenter: { longitude: -82.93928045964643, latitude: 42.50761283245141 },
  },
  'mi-michigan-oak-park': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: false,
    timezone: 'America/New_York',
    displayName: 'Oak Park',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '48017',
      '48025',
      '48030',
      '48033',
      '48034',
      '48067',
      '48069',
      '48070',
      '48071',
      '48072',
      '48073',
      '48075',
      '48076',
      '48091',
      '48092',
      '48203',
      '48219',
      '48220',
      '48221',
      '48235',
      '48237',
      '48240',
      '48334',
      '48336',
    ]),
    approximateCenter: { longitude: -83.19152004488842, latitude: 42.475021344917565 },
  },
  'mi-michigan-detroit': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: false,
    timezone: 'America/New_York',
    displayName: 'Detroit',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    dropOffTimeRangeRuleOverrides: {
      [DropOffOrganization.QUEST]: createWeekdaysTimeRangeRules(
        '275df2d2-36e9-4e56-b28f-4f2da721f73b',
        '07:00',
        '18:00',
      ),
    },
    postalCodes: new Set([
      '48120',
      '48126',
      '48201',
      '48202',
      '48204',
      '48206',
      '48207',
      '48208',
      '48209',
      '48210',
      '48211',
      '48212',
      '48213',
      '48214',
      '48215',
      '48216',
      '48223',
      '48226',
      '48227',
      '48228',
      '48234',
      '48238',
    ]),
    approximateCenter: { longitude: -83.09529619757282, latitude: 42.36308723255148 },
  },
  'mi-michigan-livonia': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: false,
    timezone: 'America/New_York',
    displayName: 'Livonia',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '48135',
      '48150',
      '48152',
      '48154',
      '48167',
      '48168',
      '48170',
      '48185',
      '48186',
      '48187',
      '48188',
      '48198',
      '48239',
    ]),
    approximateCenter: { longitude: -83.43555426394707, latitude: 42.355044429668474 },
  },
  'mi-michigan-taylor': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: false,
    timezone: 'America/New_York',
    displayName: 'Taylor',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    dropOffTimeRangeRuleOverrides: {
      [DropOffOrganization.QUEST]: createWeekdaysTimeRangeRules(
        'b19c680d-d43c-441e-a96a-d672e275ad05',
        '07:00',
        '18:00',
      ),
    },
    postalCodes: new Set([
      '48101',
      '48122',
      '48124',
      '48125',
      '48127',
      '48128',
      '48141',
      '48146',
      '48174',
      '48180',
      '48184',
      '48192',
      '48193',
      '48195',
      '48217',
      '48218',
      '48229',
      '48242',
    ]),
    approximateCenter: { longitude: -83.24231581853404, latitude: 42.25802431941293 },
  },
  'mi-michigan-flint': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: false,
    timezone: 'America/New_York',
    displayName: 'Flint',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    dropOffTimeRangeRuleOverrides: {
      [DropOffOrganization.QUEST]: createWeekdaysTimeRangeRules(
        'fa6f774e-fb2e-4b85-9322-c704218833c9',
        '07:00',
        '18:00',
      ),
    },
    postalCodes: new Set([
      '48420',
      '48423',
      '48433',
      '48437',
      '48439',
      '48458',
      '48473',
      '48502',
      '48503',
      '48504',
      '48505',
      '48506',
      '48507',
      '48509',
      '48519',
      '48529',
      '48532',
    ]),
    approximateCenter: { longitude: -83.68610695569905, latitude: 43.03292532443438 },
  },
  'mi-michigan-saginaw': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Saginaw',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    dropOffTimeRangeRuleOverrides: {
      [DropOffOrganization.QUEST]: createWeekdaysTimeRangeRules(
        '273a22cc-aca1-4fd0-b59c-d0c3fb64897b',
        '07:00',
        '18:00',
      ),
    },
    postalCodes: new Set([
      '48415',
      '48417',
      '48601',
      '48602',
      '48603',
      '48604',
      '48607',
      '48609',
      '48638',
      '48706',
      '48708',
      '48710',
      '48722',
      '48724',
      '48732',
      '48734',
      '48747',
      '48757',
    ]),
    approximateCenter: { longitude: -83.89934821928834, latitude: 43.44605976004907 },
  },
  'mi-michigan-monroe': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Monroe',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '48111',
      '48117',
      '48131',
      '48134',
      '48138',
      '48159',
      '48160',
      '48161',
      '48162',
      '48164',
      '48166',
      '48173',
      '48179',
      '48183',
      '48191',
    ]),
    approximateCenter: { longitude: -83.40645658332323, latitude: 42.059766047751864 },
  },
  'mi-michigan-milton': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Milton',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '48001',
      '48005',
      '48023',
      '48039',
      '48042',
      '48044',
      '48047',
      '48048',
      '48050',
      '48051',
      '48062',
      '48064',
      '48065',
      '48094',
      '48095',
      '48096',
      '48315',
      '48316',
      '48317',
    ]),
    approximateCenter: { longitude: -82.86127216710942, latitude: 42.72960131169041 },
  },
  'mi-michigan-wixom': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Wixom',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '48114',
      '48116',
      '48165',
      '48178',
      '48331',
      '48335',
      '48353',
      '48356',
      '48357',
      '48374',
      '48375',
      '48377',
      '48380',
      '48381',
      '48382',
      '48383',
      '48386',
      '48390',
      '48393',
    ]),
    approximateCenter: { longitude: -83.5701421506922, latitude: 42.55252050898632 },
  },
  'mi-michigan-ann-arbor': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Ann Arbor',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '48103',
      '48104',
      '48105',
      '48108',
      '48118',
      '48130',
      '48158',
      '48176',
      '48189',
      '48197',
      '49201',
      '49202',
      '49203',
      '49240',
      '49254',
    ]),
    approximateCenter: { longitude: -83.9992888791988, latitude: 42.26317602859765 },
  },
  'mi-michigan-lansing': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Lansing',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '48137',
      '48169',
      '48808',
      '48819',
      '48821',
      '48823',
      '48824',
      '48836',
      '48840',
      '48842',
      '48843',
      '48854',
      '48855',
      '48864',
      '48892',
      '48895',
      '48906',
      '48910',
      '48911',
      '48912',
      '48915',
      '48917',
      '48933',
      '49251',
      '49285',
    ]),
    approximateCenter: { longitude: -84.36241891431264, latitude: 42.65589358523296 },
  },
  'mi-michigan-holly': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Holly',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '48306',
      '48346',
      '48348',
      '48350',
      '48360',
      '48362',
      '48363',
      '48367',
      '48370',
      '48371',
      '48430',
      '48438',
      '48442',
      '48451',
      '48462',
    ]),
    approximateCenter: { longitude: -83.39152331793507, latitude: 42.79344662349737 },
  },
  'mi-michigan-grand-rapids': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: false,
    timezone: 'America/New_York',
    displayName: 'Grand Rapids',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '49301',
      '49306',
      '49315',
      '49316',
      '49321',
      '49418',
      '49428',
      '49435',
      '49503',
      '49504',
      '49505',
      '49506',
      '49507',
      '49508',
      '49509',
      '49512',
      '49519',
      '49525',
      '49534',
      '49544',
      '49546',
      '49548',
    ]),
    approximateCenter: { longitude: -85.66598110146117, latitude: 42.942379851870385 },
  },
  'mi-michigan-muskegon': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Muskegon',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '49415',
      '49441',
      '49442',
      '49444',
      '49445',
      '49448',
      '49451',
      '49456',
      '49457',
    ]),
    approximateCenter: { longitude: -86.15790329678482, latitude: 43.19920783666132 },
  },
  'mi-michigan-holland': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Holland',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '49323',
      '49401',
      '49404',
      '49417',
      '49419',
      '49423',
      '49424',
      '49426',
      '49460',
      '49464',
    ]),
    approximateCenter: { longitude: -86.00715836688256, latitude: 42.86687791866434 },
  },
  'mi-michigan-cedar-springs': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Cedar Springs',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '48809',
      '48834',
      '48838',
      '48846',
      '48865',
      '48881',
      '48884',
      '48885',
      '49319',
      '49326',
      '49330',
      '49331',
      '49339',
      '49341',
      '49343',
      '49345',
      '49347',
    ]),
    approximateCenter: { longitude: -85.3298131097755, latitude: 43.16172879384077 },
  },
  'mi-michigan-kalamazoo': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Kalamazoo',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '49001',
      '49002',
      '49004',
      '49006',
      '49007',
      '49008',
      '49009',
      '49010',
      '49012',
      '49014',
      '49015',
      '49017',
      '49024',
      '49034',
      '49037',
      '49046',
      '49048',
      '49053',
      '49060',
      '49070',
      '49078',
      '49080',
      '49083',
      '49088',
      '49328',
      '49344',
      '49348',
    ]),
    approximateCenter: { longitude: -85.50526340231147, latitude: 42.37249967412905 },
  },
  'nh-new-hampshire-nashua': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Nashua',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '03031',
      '03033',
      '03038',
      '03049',
      '03051',
      '03052',
      '03053',
      '03054',
      '03055',
      '03060',
      '03062',
      '03063',
      '03064',
      '03076',
      '03079',
      '03087',
      '03811',
      '03819',
      '03826',
      '03841',
      '03848',
      '03858',
      '03865',
      '03873',
    ]),
    approximateCenter: { longitude: -71.35073253604317, latitude: 42.82854743609666 },
  },
  'nh-new-hampshire-downtown': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Manchester',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '03032',
      '03034',
      '03036',
      '03037',
      '03042',
      '03044',
      '03045',
      '03077',
      '03101',
      '03102',
      '03103',
      '03104',
      '03106',
      '03109',
      '03110',
      '03261',
      '03290',
      '03291',
    ]),
    approximateCenter: { longitude: -71.32838958963661, latitude: 43.04150724352742 },
  },
  'nh-new-hampshire-concord': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Concord',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '03046',
      '03220',
      '03224',
      '03229',
      '03234',
      '03235',
      '03242',
      '03244',
      '03246',
      '03249',
      '03253',
      '03258',
      '03269',
      '03275',
      '03276',
      '03281',
      '03301',
      '03303',
      '03304',
      '03307',
    ]),
    approximateCenter: { longitude: -71.56922822634615, latitude: 43.31517973239664 },
  },
  'nh-new-hampshire-dover': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Dover',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '03801',
      '03820',
      '03823',
      '03824',
      '03825',
      '03827',
      '03833',
      '03835',
      '03839',
      '03840',
      '03842',
      '03844',
      '03851',
      '03856',
      '03857',
      '03861',
      '03862',
      '03867',
      '03868',
      '03869',
      '03870',
      '03874',
      '03878',
      '03884',
      '03885',
    ]),
    approximateCenter: { longitude: -70.93243175075378, latitude: 43.125189020042434 },
  },
  'oh-ohio-akron': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Akron',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '44201',
      '44203',
      '44212',
      '44215',
      '44217',
      '44221',
      '44223',
      '44224',
      '44230',
      '44233',
      '44236',
      '44240',
      '44241',
      '44243',
      '44250',
      '44251',
      '44254',
      '44256',
      '44260',
      '44262',
      '44264',
      '44266',
      '44270',
      '44272',
      '44273',
      '44276',
      '44278',
      '44280',
      '44281',
      '44286',
      '44301',
      '44302',
      '44303',
      '44304',
      '44305',
      '44306',
      '44307',
      '44308',
      '44310',
      '44311',
      '44312',
      '44313',
      '44314',
      '44319',
      '44320',
      '44321',
      '44333',
      '44632',
      '44685',
    ]),
    approximateCenter: { longitude: -81.56709471576627, latitude: 41.090411698745136 },
  },
  'oh-ohio-ashland': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Ashland',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '44028',
      '44044',
      '44050',
      '44074',
      '44090',
      '44214',
      '44235',
      '44253',
      '44275',
      '44287',
      '44805',
      '44807',
      '44818',
      '44825',
      '44837',
      '44844',
      '44851',
      '44854',
      '44855',
      '44859',
      '44865',
      '44866',
      '44867',
      '44875',
      '44878',
      '44880',
      '44882',
      '44887',
      '44890',
    ]),
    approximateCenter: { longitude: -82.47780999832005, latitude: 41.04979658416007 },
  },
  'oh-ohio-ashtabula': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Ashtabula',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '44003',
      '44004',
      '44010',
      '44023',
      '44024',
      '44026',
      '44030',
      '44032',
      '44041',
      '44047',
      '44048',
      '44057',
      '44060',
      '44064',
      '44072',
      '44077',
      '44081',
      '44082',
      '44084',
      '44085',
      '44086',
      '44093',
      '44094',
      '44095',
      '44202',
    ]),
    approximateCenter: { longitude: -80.99534306693262, latitude: 41.65840900053346 },
  },
  'oh-ohio-canton': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Canton',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '44216',
      '44449',
      '44601',
      '44606',
      '44608',
      '44612',
      '44613',
      '44614',
      '44618',
      '44620',
      '44621',
      '44622',
      '44624',
      '44626',
      '44627',
      '44634',
      '44640',
      '44641',
      '44643',
      '44644',
      '44645',
      '44646',
      '44647',
      '44652',
      '44653',
      '44656',
      '44657',
      '44659',
      '44662',
      '44663',
      '44666',
      '44667',
      '44669',
      '44670',
      '44671',
      '44672',
      '44675',
      '44677',
      '44678',
      '44680',
      '44683',
      '44688',
      '44689',
      '44691',
      '44702',
      '44703',
      '44704',
      '44705',
      '44706',
      '44707',
      '44708',
      '44709',
      '44710',
      '44714',
      '44718',
      '44720',
      '44721',
      '44730',
    ]),
    approximateCenter: { longitude: -81.42315933584293, latitude: 40.74361956105062 },
  },
  'oh-ohio-chillicothe': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Chillicothe',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '43101',
      '43102',
      '43103',
      '43107',
      '43110',
      '43112',
      '43113',
      '43115',
      '43116',
      '43125',
      '43126',
      '43130',
      '43135',
      '43136',
      '43137',
      '43138',
      '43146',
      '43149',
      '43152',
      '43154',
      '43155',
      '43156',
      '43164',
      '43217',
      '45601',
      '45628',
      '45644',
      '45647',
      '45673',
    ]),
    approximateCenter: { longitude: -82.83474850908533, latitude: 39.603526016309324 },
  },
  'oh-ohio-cincinnati': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Cincinnati',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 12 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 3 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 3 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 3 * MILE_IN_METERS,
    postalCodes: new Set([
      '45001',
      '45002',
      '45014',
      '45030',
      '45033',
      '45040',
      '45041',
      '45052',
      '45069',
      '45102',
      '45103',
      '45111',
      '45140',
      '45150',
      '45160',
      '45174',
      '45202',
      '45203',
      '45204',
      '45205',
      '45206',
      '45207',
      '45208',
      '45209',
      '45211',
      '45212',
      '45213',
      '45214',
      '45215',
      '45216',
      '45217',
      '45218',
      '45219',
      '45220',
      '45221',
      '45223',
      '45224',
      '45225',
      '45226',
      '45227',
      '45229',
      '45230',
      '45231',
      '45232',
      '45233',
      '45236',
      '45237',
      '45238',
      '45239',
      '45240',
      '45241',
      '45242',
      '45243',
      '45244',
      '45245',
      '45246',
      '45247',
      '45248',
      '45249',
      '45251',
      '45252',
      '45255',
    ]),
    approximateCenter: { longitude: -84.48104635634674, latitude: 39.177829734828755 },
  },
  'oh-ohio-cleveland': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Cleveland',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 12 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 3 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 3 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 3 * MILE_IN_METERS,
    postalCodes: new Set([
      '44001',
      '44011',
      '44012',
      '44017',
      '44022',
      '44035',
      '44039',
      '44040',
      '44052',
      '44053',
      '44054',
      '44055',
      '44056',
      '44067',
      '44070',
      '44087',
      '44092',
      '44102',
      '44103',
      '44104',
      '44105',
      '44106',
      '44107',
      '44108',
      '44109',
      '44110',
      '44111',
      '44112',
      '44113',
      '44114',
      '44115',
      '44116',
      '44117',
      '44118',
      '44119',
      '44120',
      '44121',
      '44122',
      '44123',
      '44124',
      '44125',
      '44126',
      '44127',
      '44128',
      '44129',
      '44130',
      '44131',
      '44132',
      '44133',
      '44134',
      '44135',
      '44136',
      '44137',
      '44138',
      '44139',
      '44140',
      '44141',
      '44142',
      '44143',
      '44144',
      '44145',
      '44146',
      '44147',
      '44149',
    ]),
    approximateCenter: { longitude: -81.71832777874744, latitude: 41.44689191843527 },
  },
  'oh-ohio-columbus': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Columbus',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 12 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 3 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 3 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 3 * MILE_IN_METERS,
    postalCodes: new Set([
      '43004',
      '43016',
      '43017',
      '43026',
      '43035',
      '43054',
      '43065',
      '43068',
      '43081',
      '43082',
      '43085',
      '43119',
      '43123',
      '43201',
      '43202',
      '43203',
      '43204',
      '43205',
      '43206',
      '43207',
      '43209',
      '43210',
      '43211',
      '43212',
      '43213',
      '43214',
      '43215',
      '43219',
      '43220',
      '43221',
      '43222',
      '43223',
      '43224',
      '43227',
      '43228',
      '43229',
      '43230',
      '43231',
      '43232',
      '43235',
      '43240',
    ]),
    approximateCenter: { longitude: -82.99316239938136, latitude: 40.018785160948234 },
  },
  'oh-ohio-dayton': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Dayton',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '45301',
      '45305',
      '45309',
      '45315',
      '45322',
      '45324',
      '45325',
      '45327',
      '45342',
      '45345',
      '45354',
      '45370',
      '45378',
      '45384',
      '45385',
      '45402',
      '45403',
      '45404',
      '45405',
      '45406',
      '45409',
      '45410',
      '45414',
      '45415',
      '45416',
      '45417',
      '45419',
      '45420',
      '45424',
      '45426',
      '45428',
      '45429',
      '45430',
      '45431',
      '45432',
      '45433',
      '45434',
      '45435',
      '45439',
      '45440',
      '45449',
      '45458',
      '45459',
      '45469',
    ]),
    approximateCenter: { longitude: -84.18957483137939, latitude: 39.748478487064325 },
  },
  'oh-ohio-defiance': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Defiance',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '43501',
      '43502',
      '43505',
      '43506',
      '43510',
      '43512',
      '43515',
      '43517',
      '43518',
      '43521',
      '43524',
      '43526',
      '43527',
      '43531',
      '43532',
      '43533',
      '43534',
      '43535',
      '43536',
      '43540',
      '43543',
      '43545',
      '43548',
      '43549',
      '43553',
      '43554',
      '43556',
      '43557',
      '43567',
      '43570',
      '45813',
      '45821',
      '45831',
      '45851',
      '45855',
      '45861',
      '45873',
      '45879',
      '45880',
    ]),
    approximateCenter: { longitude: -84.38026314798958, latitude: 41.39045708932918 },
  },
  'oh-ohio-findlay': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Findlay',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '43316',
      '43402',
      '43403',
      '43406',
      '43407',
      '43413',
      '43435',
      '43437',
      '43443',
      '43450',
      '43451',
      '43457',
      '43462',
      '43466',
      '43467',
      '43511',
      '43516',
      '43522',
      '43525',
      '43529',
      '43541',
      '43565',
      '43569',
      '44802',
      '44804',
      '44809',
      '44815',
      '44817',
      '44830',
      '44841',
      '44853',
      '44883',
      '45814',
      '45816',
      '45840',
      '45856',
      '45858',
      '45867',
      '45868',
      '45872',
      '45875',
      '45881',
      '45889',
      '45890',
    ]),
    approximateCenter: { longitude: -83.5954991451397, latitude: 41.19818289055859 },
  },
  'oh-ohio-greenville': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Greenville',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '45003',
      '45303',
      '45304',
      '45308',
      '45310',
      '45311',
      '45320',
      '45321',
      '45328',
      '45330',
      '45331',
      '45332',
      '45337',
      '45338',
      '45339',
      '45346',
      '45347',
      '45348',
      '45350',
      '45351',
      '45352',
      '45358',
      '45359',
      '45362',
      '45363',
      '45380',
      '45381',
      '45382',
      '45383',
      '45388',
      '45390',
      '45822',
      '45826',
      '45828',
      '45845',
      '45846',
      '45860',
      '45862',
      '45865',
      '45866',
      '45869',
      '45874',
      '45882',
      '45883',
      '45898',
      '45899',
    ]),
    approximateCenter: { longitude: -84.57909279962539, latitude: 40.198044556813855 },
  },
  'oh-ohio-hillsboro': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Hillsboro',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '45101',
      '45106',
      '45115',
      '45118',
      '45119',
      '45120',
      '45121',
      '45130',
      '45133',
      '45142',
      '45144',
      '45153',
      '45154',
      '45157',
      '45167',
      '45168',
      '45171',
      '45176',
      '45679',
      '45693',
      '45697',
    ]),
    approximateCenter: { longitude: -83.85244053320925, latitude: 38.92867885051771 },
  },
  'oh-ohio-lima': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Lima',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '43067',
      '43310',
      '43311',
      '43318',
      '43319',
      '43324',
      '43326',
      '43331',
      '43332',
      '43333',
      '43340',
      '43341',
      '43343',
      '43344',
      '43345',
      '43347',
      '43348',
      '43357',
      '43358',
      '43359',
      '43360',
      '45306',
      '45334',
      '45801',
      '45804',
      '45805',
      '45806',
      '45807',
      '45808',
      '45810',
      '45812',
      '45817',
      '45819',
      '45820',
      '45827',
      '45830',
      '45832',
      '45833',
      '45835',
      '45836',
      '45841',
      '45843',
      '45844',
      '45849',
      '45850',
      '45853',
      '45854',
      '45859',
      '45863',
      '45870',
      '45871',
      '45876',
      '45877',
      '45885',
      '45886',
      '45887',
      '45891',
      '45893',
      '45894',
      '45895',
      '45896',
      '45897',
    ]),
    approximateCenter: { longitude: -83.9577866800866, latitude: 40.66967159450228 },
  },
  'oh-ohio-mansfield': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Mansfield',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '43003',
      '43011',
      '43013',
      '43015',
      '43019',
      '43021',
      '43032',
      '43048',
      '43050',
      '43061',
      '43066',
      '43074',
      '43302',
      '43314',
      '43315',
      '43317',
      '43320',
      '43321',
      '43322',
      '43323',
      '43330',
      '43334',
      '43337',
      '43338',
      '43342',
      '43350',
      '43351',
      '43356',
      '44813',
      '44820',
      '44827',
      '44833',
      '44849',
      '44860',
      '44902',
      '44903',
      '44904',
      '44905',
      '44906',
      '44907',
    ]),
    approximateCenter: { longitude: -82.87470513199492, latitude: 40.55438214114928 },
  },
  'oh-ohio-middletown': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Middletown',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '45005',
      '45011',
      '45013',
      '45015',
      '45034',
      '45036',
      '45039',
      '45042',
      '45044',
      '45050',
      '45053',
      '45056',
      '45064',
      '45065',
      '45066',
      '45067',
      '45068',
      '45070',
    ]),
    approximateCenter: { longitude: -84.41910258848907, latitude: 39.46121746552428 },
  },
  'oh-ohio-newark': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Newark',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '43001',
      '43008',
      '43018',
      '43023',
      '43025',
      '43030',
      '43031',
      '43033',
      '43046',
      '43055',
      '43056',
      '43062',
      '43071',
      '43076',
      '43080',
      '43105',
      '43147',
      '43148',
      '43150',
      '43157',
      '43701',
      '43721',
      '43735',
      '43739',
      '43740',
      '43746',
      '43760',
      '43777',
      '43783',
      '43791',
      '43821',
      '43822',
      '43830',
      '43842',
    ]),
    approximateCenter: { longitude: -82.36615509349181, latitude: 39.97592672257279 },
  },
  'oh-ohio-piqua': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Piqua',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '45302',
      '45312',
      '45317',
      '45318',
      '45326',
      '45333',
      '45336',
      '45340',
      '45353',
      '45356',
      '45360',
      '45365',
      '45371',
      '45373',
      '45377',
      '45389',
    ]),
    approximateCenter: { longitude: -84.15840719270318, latitude: 40.18544912825047 },
  },
  'oh-ohio-sandusky': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Sandusky',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '43410',
      '43416',
      '43420',
      '43431',
      '43432',
      '43433',
      '43439',
      '43440',
      '43442',
      '43449',
      '43452',
      '43458',
      '43464',
      '43469',
      '44089',
      '44811',
      '44814',
      '44824',
      '44826',
      '44828',
      '44836',
      '44839',
      '44846',
      '44847',
      '44857',
      '44861',
      '44870',
      '44889',
    ]),
    approximateCenter: { longitude: -82.89335161402627, latitude: 41.380087038371364 },
  },
  'oh-ohio-springfield': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Springfield',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '43009',
      '43010',
      '43029',
      '43040',
      '43044',
      '43045',
      '43060',
      '43064',
      '43072',
      '43077',
      '43078',
      '43084',
      '43140',
      '43162',
      '45314',
      '45316',
      '45319',
      '45323',
      '45341',
      '45344',
      '45349',
      '45368',
      '45369',
      '45372',
      '45387',
      '45502',
      '45503',
      '45504',
      '45505',
      '45506',
    ]),
    approximateCenter: { longitude: -83.69524492693495, latitude: 39.996192723662105 },
  },
  'oh-ohio-toledo': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Toledo',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '43408',
      '43412',
      '43430',
      '43445',
      '43447',
      '43460',
      '43463',
      '43465',
      '43468',
      '43504',
      '43528',
      '43537',
      '43542',
      '43551',
      '43558',
      '43560',
      '43566',
      '43571',
      '43604',
      '43605',
      '43606',
      '43607',
      '43608',
      '43609',
      '43610',
      '43611',
      '43612',
      '43613',
      '43614',
      '43615',
      '43616',
      '43617',
      '43619',
      '43620',
      '43623',
    ]),
    approximateCenter: { longitude: -83.57716107551182, latitude: 41.62013395051255 },
  },
  'oh-ohio-wilmington': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Wilmington',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '43106',
      '43128',
      '43142',
      '43143',
      '43145',
      '43151',
      '43153',
      '43160',
      '45054',
      '45107',
      '45113',
      '45122',
      '45123',
      '45135',
      '45146',
      '45148',
      '45152',
      '45158',
      '45159',
      '45162',
      '45164',
      '45166',
      '45169',
      '45177',
      '45335',
    ]),
    approximateCenter: { longitude: -83.72417482639047, latitude: 39.46461028211678 },
  },
  'oh-ohio-youngstown': {
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Youngstown',
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '44021',
      '44046',
      '44062',
      '44065',
      '44076',
      '44080',
      '44099',
      '44231',
      '44234',
      '44255',
      '44288',
      '44401',
      '44402',
      '44403',
      '44404',
      '44405',
      '44406',
      '44408',
      '44410',
      '44411',
      '44412',
      '44413',
      '44417',
      '44418',
      '44420',
      '44425',
      '44428',
      '44429',
      '44430',
      '44431',
      '44436',
      '44437',
      '44438',
      '44440',
      '44442',
      '44443',
      '44444',
      '44445',
      '44446',
      '44450',
      '44451',
      '44452',
      '44454',
      '44460',
      '44470',
      '44471',
      '44473',
      '44481',
      '44482',
      '44483',
      '44484',
      '44485',
      '44490',
      '44491',
      '44502',
      '44503',
      '44504',
      '44505',
      '44506',
      '44507',
      '44509',
      '44510',
      '44511',
      '44512',
      '44514',
      '44515',
      '44609',
      '44619',
    ]),
    approximateCenter: { longitude: -80.79066593943492, latitude: 41.16670300790965 },
  },
  'fl-florida-jacksonville': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Jacksonville',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '32081',
      '32082',
      '32202',
      '32204',
      '32205',
      '32206',
      '32207',
      '32208',
      '32209',
      '32210',
      '32211',
      '32216',
      '32217',
      '32218',
      '32219',
      '32220',
      '32221',
      '32222',
      '32224',
      '32225',
      '32226',
      '32227',
      '32228',
      '32233',
      '32244',
      '32246',
      '32250',
      '32254',
      '32256',
      '32257',
      '32266',
      '32277',
    ]),
    approximateCenter: { longitude: -81.60254307535597, latitude: 30.30729282898878 },
  },
  'fl-florida-green-cove-springs': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Green Cove Springs',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    postalCodes: new Set([
      '32003',
      '32033',
      '32043',
      '32065',
      '32068',
      '32073',
      '32080',
      '32084',
      '32086',
      '32091',
      '32092',
      '32095',
      '32131',
      '32145',
      '32177',
      '32223',
      '32234',
      '32258',
      '32259',
      '32656',
    ]),
    approximateCenter: { longitude: -81.64096979762508, latitude: 29.95397964673996 },
  },
  'fl-florida-ocala': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Ocala',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '32159',
      '32162',
      '32163',
      '32195',
      '32617',
      '34420',
      '34470',
      '34471',
      '34472',
      '34473',
      '34474',
      '34475',
      '34476',
      '34479',
      '34480',
      '34481',
      '34482',
      '34484',
      '34491',
      '34731',
      '34785',
    ]),
    approximateCenter: { longitude: -82.081252297554, latitude: 29.065274052810107 },
  },
  'fl-florida-orlando-w': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Orlando (West)',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '32703',
      '32712',
      '32714',
      '32757',
      '32778',
      '32779',
      '32798',
      '32804',
      '32808',
      '32810',
      '32818',
      '32835',
      '34705',
      '34711',
      '34714',
      '34715',
      '34734',
      '34736',
      '34737',
      '34748',
      '34753',
      '34756',
      '34760',
      '34761',
      '34762',
      '34786',
      '34787',
      '34797',
    ]),
    approximateCenter: { longitude: -81.63610902452291, latitude: 28.62794007536539 },
  },
  'fl-florida-orlando-ne': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Orlando (Northeast)',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '32701',
      '32707',
      '32708',
      '32725',
      '32730',
      '32732',
      '32738',
      '32746',
      '32750',
      '32751',
      '32764',
      '32765',
      '32766',
      '32771',
      '32773',
      '32789',
      '32792',
      '32801',
      '32803',
      '32807',
      '32814',
      '32816',
      '32817',
      '32820',
      '32825',
      '32826',
      '32828',
    ]),
    approximateCenter: { longitude: -81.2578595461177, latitude: 28.662289435163935 },
  },
  'fl-florida-orlando-s': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Orlando (South)',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '32805',
      '32806',
      '32809',
      '32811',
      '32812',
      '32819',
      '32821',
      '32822',
      '32824',
      '32827',
      '32829',
      '32830',
      '32831',
      '32832',
      '32836',
      '32837',
      '32839',
      '33848',
      '33896',
      '34741',
      '34743',
      '34744',
      '34746',
      '34747',
      '34758',
      '34769',
      '34771',
      '34772',
    ]),
    approximateCenter: { longitude: -81.3829400486471, latitude: 28.377033246034795 },
  },
  'fl-florida-spring-hill': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Spring Hill',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    postalCodes: new Set([
      '33543',
      '33544',
      '33545',
      '33576',
      '34601',
      '34604',
      '34606',
      '34607',
      '34608',
      '34609',
      '34610',
      '34613',
      '34614',
      '34637',
      '34638',
      '34639',
      '34652',
      '34653',
      '34654',
      '34655',
      '34667',
      '34668',
      '34669',
      '34690',
      '34691',
    ]),
    approximateCenter: { longitude: -82.54177765595796, latitude: 28.358265680549984 },
  },
  'fl-florida-tampa': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Tampa',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    postalCodes: new Set([
      '33510',
      '33511',
      '33527',
      '33534',
      '33548',
      '33549',
      '33556',
      '33558',
      '33559',
      '33569',
      '33578',
      '33579',
      '33584',
      '33592',
      '33594',
      '33596',
      '33602',
      '33603',
      '33604',
      '33605',
      '33606',
      '33607',
      '33609',
      '33610',
      '33611',
      '33612',
      '33613',
      '33614',
      '33615',
      '33616',
      '33617',
      '33618',
      '33619',
      '33620',
      '33624',
      '33625',
      '33626',
      '33629',
      '33634',
      '33635',
      '33637',
      '33647',
    ]),
    approximateCenter: { longitude: -82.42988340303434, latitude: 28.000172406391485 },
  },
  'fl-florida-st-petersburg': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'St. Petersburg',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    postalCodes: new Set([
      '33701',
      '33702',
      '33703',
      '33704',
      '33705',
      '33706',
      '33707',
      '33708',
      '33709',
      '33710',
      '33711',
      '33712',
      '33713',
      '33714',
      '33716',
      '33744',
      '33755',
      '33756',
      '33759',
      '33760',
      '33761',
      '33762',
      '33763',
      '33764',
      '33765',
      '33767',
      '33770',
      '33771',
      '33772',
      '33773',
      '33774',
      '33776',
      '33777',
      '33778',
      '33781',
      '33782',
      '33785',
      '33786',
      '34677',
      '34681',
      '34683',
      '34684',
      '34685',
      '34688',
      '34689',
      '34695',
      '34698',
    ]),
    approximateCenter: { longitude: -82.73346895855646, latitude: 27.905359069242916 },
  },
  'fl-florida-bradenton': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Bradenton',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    postalCodes: new Set([
      '33570',
      '33572',
      '33573',
      '33598',
      '34201',
      '34202',
      '34203',
      '34205',
      '34207',
      '34208',
      '34209',
      '34210',
      '34211',
      '34212',
      '34219',
      '34221',
      '34222',
      '34231',
      '34232',
      '34233',
      '34234',
      '34235',
      '34236',
      '34237',
      '34238',
      '34239',
      '34240',
      '34241',
      '34243',
      '34251',
    ]),
    approximateCenter: { longitude: -82.46484040830573, latitude: 27.44366302195236 },
  },
  'fl-florida-port-charlotte': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Port Charlotte',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    postalCodes: new Set([
      '33946',
      '33947',
      '33948',
      '33952',
      '33953',
      '33954',
      '33980',
      '33981',
      '33983',
      '34223',
      '34224',
      '34229',
      '34275',
      '34285',
      '34286',
      '34287',
      '34288',
      '34289',
      '34291',
      '34292',
      '34293',
    ]),
    approximateCenter: { longitude: -82.23464212175996, latitude: 27.02405994805626 },
  },
  'fl-florida-port-st-lucie': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Port St. Lucie',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    postalCodes: new Set([
      '32962',
      '32968',
      '33455',
      '34945',
      '34946',
      '34947',
      '34949',
      '34950',
      '34951',
      '34952',
      '34953',
      '34956',
      '34957',
      '34981',
      '34982',
      '34983',
      '34984',
      '34986',
      '34987',
      '34990',
      '34994',
      '34996',
      '34997',
    ]),
    approximateCenter: { longitude: -80.35459082057679, latitude: 27.328468092819598 },
  },
  'fl-florida-palm-bay': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Palm Bay',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '32901',
      '32903',
      '32904',
      '32905',
      '32907',
      '32908',
      '32909',
      '32922',
      '32926',
      '32931',
      '32934',
      '32935',
      '32937',
      '32940',
      '32948',
      '32949',
      '32950',
      '32951',
      '32952',
      '32955',
      '32958',
      '32960',
      '32966',
      '32967',
      '32976',
    ]),
    approximateCenter: { longitude: -80.63575670417126, latitude: 28.030164978463947 },
  },
  'fl-florida-pensacola': {
    isActive: true,
    timezone: 'America/Chicago',
    displayName: 'Pensacola',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '32501',
      '32502',
      '32503',
      '32504',
      '32505',
      '32506',
      '32507',
      '32509',
      '32511',
      '32512',
      '32514',
      '32526',
      '32530',
      '32531',
      '32533',
      '32534',
      '32535',
      '32561',
      '32563',
      '32564',
      '32565',
      '32566',
      '32568',
      '32570',
      '32571',
      '32577',
      '32583',
    ]),
    approximateCenter: { longitude: -87.18584966657515, latitude: 30.567910435242574 },
  },
  'fl-florida-panama-city': {
    isActive: true,
    timezone: 'America/Chicago',
    displayName: 'Panama City',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    postalCodes: new Set([
      '32401',
      '32403',
      '32404',
      '32405',
      '32407',
      '32408',
      '32409',
      '32413',
      '32425',
      '32427',
      '32428',
      '32433',
      '32435',
      '32437',
      '32439',
      '32444',
      '32455',
      '32456',
      '32459',
      '32461',
      '32462',
      '32464',
      '32465',
      '32466',
      '32536',
      '32539',
      '32541',
      '32550',
      '32567',
    ]),
    approximateCenter: { longitude: -85.89196194011707, latitude: 30.435649642316395 },
  },
  'ky-kentucky-newport': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Newport',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '41001',
      '41005',
      '41011',
      '41014',
      '41015',
      '41016',
      '41017',
      '41018',
      '41042',
      '41048',
      '41051',
      '41059',
      '41063',
      '41071',
      '41073',
      '41074',
      '41075',
      '41076',
      '41080',
      '41085',
      '41090',
      '41091',
      '41092',
      '41094',
      '41095',
      '41099',
      '45275',
    ]),
    approximateCenter: { longitude: -84.56111546494834, latitude: 38.99843697565433 },
  },
  'ky-kentucky-carrolton': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Carrolton',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '40006',
      '40010',
      '40011',
      '40014',
      '40026',
      '40031',
      '40045',
      '40050',
      '40055',
      '40059',
      '40068',
      '40070',
      '40075',
      '40077',
      '40355',
      '41008',
      '41045',
      '41046',
      '41083',
      '41086',
      '41098',
    ]),
    approximateCenter: { longitude: -85.21761583663485, latitude: 38.53641809190129 },
  },
  'ky-kentucky-louisville': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Louisville',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '40023',
      '40025',
      '40041',
      '40047',
      '40056',
      '40067',
      '40109',
      '40118',
      '40165',
      '40202',
      '40203',
      '40204',
      '40205',
      '40206',
      '40207',
      '40208',
      '40209',
      '40210',
      '40211',
      '40212',
      '40213',
      '40214',
      '40215',
      '40216',
      '40217',
      '40218',
      '40219',
      '40220',
      '40222',
      '40223',
      '40228',
      '40229',
      '40241',
      '40242',
      '40243',
      '40245',
      '40258',
      '40272',
      '40291',
      '40292',
      '40299',
    ]),
    approximateCenter: { longitude: -85.6719304567609, latitude: 38.19734703002432 },
  },
  'ky-kentucky-cynthiana': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Cynthiana',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '40324',
      '40361',
      '40370',
      '41003',
      '41010',
      '41030',
      '41031',
      '41033',
      '41035',
      '41040',
      '41097',
    ]),
    approximateCenter: { longitude: -84.47404311219555, latitude: 38.53008862370817 },
  },
  'ky-kentucky-frankfort': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Frankfort',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set(['40003', '40065', '40076', '40342', '40372', '40601']),
    approximateCenter: { longitude: -85.01037884692924, latitude: 38.12596897552739 },
  },
  'ky-kentucky-lexington': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Lexington',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '40313',
      '40339',
      '40347',
      '40356',
      '40357',
      '40383',
      '40390',
      '40502',
      '40503',
      '40504',
      '40505',
      '40506',
      '40507',
      '40508',
      '40509',
      '40510',
      '40511',
      '40513',
      '40514',
      '40515',
      '40516',
      '40517',
      '40526',
    ]),
    approximateCenter: { longitude: -84.4676698554533, latitude: 38.02620444953507 },
  },
  'ky-kentucky-ashland': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Ashland',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '40351',
      '41101',
      '41102',
      '41121',
      '41129',
      '41132',
      '41139',
      '41143',
      '41144',
      '41146',
      '41164',
      '41168',
      '41169',
      '41183',
    ]),
    approximateCenter: { longitude: -82.86000052410246, latitude: 38.38922959137026 },
  },
  'ky-kentucky-salyersville': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Salyersville',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '40312',
      '40316',
      '40322',
      '40337',
      '40346',
      '40353',
      '40376',
      '40380',
      '40387',
      '40391',
      '41301',
      '41311',
      '41332',
      '41352',
      '41360',
      '41365',
      '41385',
      '41397',
      '41408',
      '41425',
      '41464',
      '41465',
      '41472',
    ]),
    approximateCenter: { longitude: -83.56130328066152, latitude: 37.82685870437181 },
  },
  'ky-kentucky-berea': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Berea',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '40336',
      '40385',
      '40403',
      '40404',
      '40409',
      '40419',
      '40445',
      '40447',
      '40456',
      '40460',
      '40461',
      '40472',
      '40475',
      '40481',
    ]),
    approximateCenter: { longitude: -84.22056461002515, latitude: 37.52815993558274 },
  },
  'ky-kentucky-danville': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Danville',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '40009',
      '40033',
      '40037',
      '40040',
      '40049',
      '40052',
      '40060',
      '40061',
      '40062',
      '40069',
      '40078',
      '40310',
      '40328',
      '40330',
      '40422',
      '40437',
      '40440',
      '40442',
      '40444',
      '40464',
      '40468',
      '40484',
      '40489',
      '42516',
      '42528',
      '42539',
      '42541',
      '42565',
      '42566',
      '42718',
      '42733',
      '42758',
    ]),
    approximateCenter: { longitude: -85.02938588577629, latitude: 37.52116562609835 },
  },
  'ky-kentucky-corbin': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Corbin',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '40701',
      '40729',
      '40734',
      '40737',
      '40740',
      '40741',
      '40744',
      '40759',
      '40763',
      '40769',
      '40771',
      '40813',
      '40903',
      '40906',
      '40915',
      '40921',
      '40923',
      '40940',
      '40943',
      '40949',
      '40965',
      '40977',
      '40995',
    ]),
    approximateCenter: { longitude: -83.94277708414604, latitude: 36.86610364662681 },
  },
  'ky-kentucky-somerset': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Somerset',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '42501',
      '42503',
      '42518',
      '42519',
      '42533',
      '42544',
      '42553',
      '42558',
      '42567',
      '42633',
      '42634',
      '42635',
      '42638',
      '42647',
      '42649',
      '42653',
    ]),
    approximateCenter: { longitude: -84.5668037403956, latitude: 36.92167141715595 },
  },
  'ky-kentucky-elizabethtown': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Elizabethtown',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '40004',
      '40048',
      '40051',
      '40107',
      '40121',
      '40150',
      '40160',
      '40162',
      '40175',
      '42701',
      '42716',
      '42724',
      '42732',
      '42740',
      '42748',
      '42757',
      '42776',
      '42784',
      '42788',
    ]),
    approximateCenter: { longitude: -85.82982524807662, latitude: 37.671363397344805 },
  },
  'ky-kentucky-campbellsville': {
    isActive: true,
    timezone: 'America/Chicago',
    displayName: 'Campbellsville',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '42124',
      '42129',
      '42130',
      '42154',
      '42166',
      '42214',
      '42629',
      '42642',
      '42715',
      '42728',
      '42741',
      '42742',
      '42743',
      '42753',
      '42782',
    ]),
    approximateCenter: { longitude: -85.47395707884395, latitude: 37.04701967355443 },
  },
  'ky-kentucky-bowling-green': {
    isActive: true,
    timezone: 'America/Chicago',
    displayName: 'Bowling Green',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '42101',
      '42103',
      '42104',
      '42120',
      '42122',
      '42123',
      '42134',
      '42141',
      '42156',
      '42159',
      '42160',
      '42163',
      '42164',
      '42170',
      '42171',
      '42206',
      '42274',
      '42276',
    ]),
    approximateCenter: { longitude: -86.33405160825646, latitude: 36.907566772483854 },
  },
  'ky-kentucky-owensboro': {
    isActive: true,
    timezone: 'America/Chicago',
    displayName: 'Owensboro',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '42301',
      '42303',
      '42327',
      '42348',
      '42351',
      '42355',
      '42366',
      '42368',
      '42376',
      '42378',
      '42420',
      '42451',
      '42452',
      '42458',
    ]),
    approximateCenter: { longitude: -87.1259877445806, latitude: 37.75655374091005 },
  },
  'ky-kentucky-madisonville': {
    isActive: true,
    timezone: 'America/Chicago',
    displayName: 'Madisonville',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '42211',
      '42215',
      '42217',
      '42232',
      '42240',
      '42325',
      '42344',
      '42345',
      '42367',
      '42408',
      '42410',
      '42411',
      '42413',
      '42431',
      '42436',
      '42440',
      '42441',
      '42442',
      '42445',
      '42450',
      '42453',
      '42464',
    ]),
    approximateCenter: { longitude: -87.54379482050877, latitude: 37.18457876974116 },
  },
  'ky-kentucky-mayfield': {
    isActive: true,
    timezone: 'America/Chicago',
    displayName: 'Mayfield',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '42001',
      '42003',
      '42020',
      '42025',
      '42027',
      '42029',
      '42036',
      '42038',
      '42040',
      '42044',
      '42045',
      '42048',
      '42051',
      '42054',
      '42055',
      '42058',
      '42066',
      '42069',
      '42071',
      '42082',
    ]),
    approximateCenter: { longitude: -88.4205400627726, latitude: 36.87864698216293 },
  },
  'ky-kentucky-pikeville': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Pikeville',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '41203',
      '41204',
      '41214',
      '41216',
      '41222',
      '41224',
      '41230',
      '41231',
      '41232',
      '41234',
      '41240',
      '41250',
      '41254',
      '41256',
      '41257',
      '41260',
      '41262',
      '41263',
      '41264',
      '41265',
      '41267',
      '41268',
      '41271',
      '41274',
      '41501',
      '41503',
      '41514',
      '41519',
      '41527',
      '41535',
      '41564',
      '41571',
      '41601',
      '41602',
      '41603',
      '41605',
      '41621',
      '41635',
      '41642',
      '41653',
      '41659',
      '41663',
    ]),
    approximateCenter: { longitude: -82.6196837464397, latitude: 37.73788199579259 },
  },
  'ky-kentucky-hazard': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Hazard',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '40402',
      '40486',
      '40914',
      '40962',
      '40972',
      '40983',
      '41310',
      '41314',
      '41317',
      '41339',
      '41348',
      '41351',
      '41364',
      '41366',
      '41367',
      '41386',
      '41390',
      '41701',
      '41712',
      '41714',
      '41719',
      '41721',
      '41722',
      '41723',
      '41727',
      '41729',
      '41736',
      '41739',
      '41745',
      '41749',
      '41754',
      '41762',
      '41766',
      '41778',
    ]),
    approximateCenter: { longitude: -83.41725486011168, latitude: 37.341747273445144 },
  },
  'ky-kentucky-caneyville': {
    isActive: true,
    timezone: 'America/Chicago',
    displayName: 'Caneyville',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '40119',
      '42207',
      '42210',
      '42252',
      '42261',
      '42273',
      '42275',
      '42285',
      '42320',
      '42321',
      '42323',
      '42324',
      '42326',
      '42328',
      '42330',
      '42332',
      '42333',
      '42337',
      '42338',
      '42343',
      '42347',
      '42349',
      '42350',
      '42352',
      '42354',
      '42361',
      '42369',
      '42370',
      '42712',
      '42721',
      '42726',
      '42754',
      '42762',
    ]),
    approximateCenter: { longitude: -86.72842396792392, latitude: 37.36565369569648 },
  },
  'in-indiana-gary': {
    isActive: true,
    timezone: 'America/Chicago',
    displayName: 'Gary',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 25 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '46311',
      '46312',
      '46319',
      '46320',
      '46321',
      '46322',
      '46323',
      '46324',
      '46327',
      '46342',
      '46368',
      '46373',
      '46375',
      '46394',
      '46402',
      '46403',
      '46404',
      '46405',
      '46406',
      '46407',
      '46408',
      '46409',
      '46410',
    ]),
    approximateCenter: { longitude: -87.39739916532194, latitude: 41.56693214114618 },
  },
  'in-indiana-michigan-city': {
    isActive: true,
    timezone: 'America/Chicago',
    displayName: 'Michigan City',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '46301',
      '46302',
      '46304',
      '46345',
      '46346',
      '46350',
      '46360',
      '46365',
      '46371',
      '46382',
      '46383',
      '46385',
      '46390',
      '46391',
      '46532',
    ]),
    approximateCenter: { longitude: -86.83898850854227, latitude: 41.535145893162365 },
  },
  'in-indiana-south-bend': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'South Bend',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '46506',
      '46507',
      '46514',
      '46516',
      '46517',
      '46526',
      '46528',
      '46530',
      '46536',
      '46540',
      '46544',
      '46545',
      '46550',
      '46552',
      '46553',
      '46554',
      '46556',
      '46561',
      '46573',
      '46601',
      '46613',
      '46614',
      '46615',
      '46616',
      '46617',
      '46619',
      '46628',
      '46635',
      '46637',
    ]),
    approximateCenter: { longitude: -86.12870751196924, latitude: 41.638935810687 },
  },
  'in-indiana-crown-point': {
    isActive: true,
    timezone: 'America/Chicago',
    displayName: 'Crown Point',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '46303',
      '46307',
      '46310',
      '46341',
      '46347',
      '46349',
      '46356',
      '46376',
      '46377',
      '46379',
      '46381',
      '46392',
      '47922',
      '47943',
      '47948',
      '47951',
      '47963',
      '47964',
      '47977',
      '47978',
    ]),
    approximateCenter: { longitude: -87.30580756708585, latitude: 41.100788498521545 },
  },
  'in-indiana-plymouth': {
    isActive: true,
    timezone: 'America/Chicago',
    displayName: 'Plymouth',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '46340',
      '46348',
      '46366',
      '46374',
      '46501',
      '46504',
      '46511',
      '46531',
      '46534',
      '46563',
      '46570',
      '46574',
      '46922',
      '46939',
      '46960',
      '46968',
      '46975',
      '46996',
      '47946',
      '47957',
    ]),
    approximateCenter: { longitude: -86.53540539457126, latitude: 41.211191200191436 },
  },
  'in-indiana-ft-wayne': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Fort Wayne',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '46741',
      '46743',
      '46748',
      '46765',
      '46774',
      '46788',
      '46797',
      '46798',
      '46802',
      '46803',
      '46804',
      '46805',
      '46806',
      '46807',
      '46808',
      '46809',
      '46814',
      '46815',
      '46816',
      '46818',
      '46819',
      '46825',
      '46835',
      '46845',
    ]),
    approximateCenter: { longitude: -85.09517239313661, latitude: 41.1093334660479 },
  },
  'in-indiana-lafayette': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Lafayette',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '46058',
      '46065',
      '46913',
      '46920',
      '46923',
      '46929',
      '46977',
      '47901',
      '47904',
      '47905',
      '47906',
      '47907',
      '47909',
      '47918',
      '47920',
      '47923',
      '47924',
      '47925',
      '47929',
      '47930',
      '47941',
      '47944',
      '47950',
      '47959',
      '47960',
      '47967',
      '47970',
      '47971',
      '47975',
      '47980',
      '47981',
      '47983',
      '47992',
      '47995',
      '47997',
    ]),
    approximateCenter: { longitude: -86.85326259604311, latitude: 40.51150676985941 },
  },
  'in-indiana-logansport': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Logansport',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '46901',
      '46902',
      '46911',
      '46914',
      '46917',
      '46919',
      '46926',
      '46932',
      '46936',
      '46940',
      '46947',
      '46950',
      '46952',
      '46953',
      '46958',
      '46959',
      '46961',
      '46967',
      '46970',
      '46971',
      '46978',
      '46979',
      '46985',
      '46986',
      '46987',
      '46988',
      '46992',
      '46994',
      '47926',
    ]),
    approximateCenter: { longitude: -86.11370443530049, latitude: 40.67622920573918 },
  },
  'in-indiana-terre-haute': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Terre Haute',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '47802',
      '47803',
      '47804',
      '47805',
      '47807',
      '47809',
      '47834',
      '47837',
      '47840',
      '47841',
      '47842',
      '47846',
      '47850',
      '47851',
      '47853',
      '47854',
      '47857',
      '47858',
      '47860',
      '47862',
      '47863',
      '47866',
      '47872',
      '47874',
      '47879',
      '47881',
      '47885',
    ]),
    approximateCenter: { longitude: -87.28666058970238, latitude: 39.50244261345774 },
  },
  'in-indiana-indianapolis': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Indianapolis',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '46107',
      '46201',
      '46202',
      '46203',
      '46204',
      '46205',
      '46208',
      '46214',
      '46216',
      '46217',
      '46218',
      '46219',
      '46220',
      '46221',
      '46222',
      '46224',
      '46225',
      '46226',
      '46227',
      '46228',
      '46229',
      '46231',
      '46234',
      '46235',
      '46236',
      '46237',
      '46239',
      '46240',
      '46241',
      '46250',
      '46254',
      '46256',
      '46259',
      '46260',
      '46268',
      '46278',
    ]),
    approximateCenter: { longitude: -86.13925207243044, latitude: 39.79694323808314 },
  },
  'in-indiana-richmond': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Richmond',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '46127',
      '46148',
      '47003',
      '47324',
      '47325',
      '47327',
      '47330',
      '47331',
      '47335',
      '47337',
      '47339',
      '47341',
      '47345',
      '47346',
      '47352',
      '47353',
      '47357',
      '47362',
      '47374',
      '47385',
      '47387',
      '47392',
      '47393',
    ]),
    approximateCenter: { longitude: -85.13395703918526, latitude: 39.807433614497484 },
  },
  'in-indiana-lawrenceburg': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Lawrenceburg',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '47001',
      '47006',
      '47010',
      '47012',
      '47016',
      '47022',
      '47025',
      '47031',
      '47032',
      '47041',
      '47060',
    ]),
    approximateCenter: { longitude: -84.98845021296891, latitude: 39.24926377516368 },
  },
  'in-indiana-seymour': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Seymour',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '46124',
      '47201',
      '47203',
      '47226',
      '47227',
      '47232',
      '47236',
      '47244',
      '47246',
      '47247',
      '47265',
      '47273',
      '47274',
      '47280',
      '47282',
      '47283',
    ]),
    approximateCenter: { longitude: -85.78823253799982, latitude: 39.14018526726861 },
  },
  'in-indiana-scottsburg': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Scottsburg',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 25 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set(['47102', '47138', '47170', '47229', '47230', '47243', '47250', '47270']),
    approximateCenter: { longitude: -85.66041238751293, latitude: 38.75536290107915 },
  },
  'in-indiana-new-albany': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'New Albany',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '47106',
      '47111',
      '47112',
      '47114',
      '47119',
      '47122',
      '47124',
      '47126',
      '47129',
      '47130',
      '47136',
      '47141',
      '47143',
      '47150',
      '47161',
      '47163',
      '47164',
      '47165',
      '47172',
      '47177',
    ]),
    approximateCenter: { longitude: -85.87578595510392, latitude: 38.39744636081684 },
  },
  'in-indiana-vincennes': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Vincennes',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '47501',
      '47512',
      '47516',
      '47524',
      '47528',
      '47529',
      '47535',
      '47557',
      '47558',
      '47561',
      '47562',
      '47564',
      '47567',
      '47568',
      '47573',
      '47578',
      '47584',
      '47591',
      '47596',
      '47597',
      '47598',
    ]),
    approximateCenter: { longitude: -87.26190222072071, latitude: 38.68043898505294 },
  },
  'in-indiana-evansville': {
    isActive: true,
    timezone: 'America/Chicago',
    displayName: 'Evansville',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '47601',
      '47610',
      '47612',
      '47613',
      '47619',
      '47620',
      '47630',
      '47631',
      '47633',
      '47638',
      '47639',
      '47640',
      '47647',
      '47648',
      '47649',
      '47660',
      '47665',
      '47666',
      '47670',
      '47708',
      '47710',
      '47711',
      '47712',
      '47713',
      '47714',
      '47715',
      '47720',
      '47725',
    ]),
    approximateCenter: { longitude: -87.5567621201497, latitude: 38.131757183444144 },
  },
  'in-indiana-jasper': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Jasper',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '47513',
      '47515',
      '47521',
      '47527',
      '47532',
      '47541',
      '47542',
      '47546',
      '47575',
      '47580',
      '47585',
      '47586',
      '47590',
    ]),
    approximateCenter: { longitude: -86.86566483087888, latitude: 38.29736619189163 },
  },
  'tx-texas-el-paso': {
    isActive: true,
    timezone: 'America/Denver',
    displayName: 'El Paso',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '79821',
      '79835',
      '79836',
      '79838',
      '79849',
      '79901',
      '79902',
      '79903',
      '79904',
      '79905',
      '79906',
      '79907',
      '79908',
      '79911',
      '79912',
      '79915',
      '79916',
      '79918',
      '79922',
      '79924',
      '79925',
      '79927',
      '79928',
      '79930',
      '79932',
      '79934',
      '79935',
      '79936',
      '79938',
      '79968',
    ]),
    approximateCenter: { longitude: -106.39440129056148, latitude: 31.790489054040826 },
  },
  'tx-texas-lubbock': {
    isActive: true,
    timezone: 'America/Chicago',
    displayName: 'Lubbock',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '79313',
      '79336',
      '79339',
      '79345',
      '79350',
      '79358',
      '79363',
      '79367',
      '79372',
      '79382',
      '79401',
      '79403',
      '79404',
      '79406',
      '79407',
      '79409',
      '79410',
      '79411',
      '79412',
      '79413',
      '79414',
      '79415',
      '79416',
      '79423',
      '79424',
    ]),
    approximateCenter: { longitude: -102.0132717910159, latitude: 33.57838840051127 },
  },
  'tx-texas-dallas-fort-worth': {
    isActive: true,
    timezone: 'America/Chicago',
    displayName: 'Fort Worth',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    postalCodes: new Set([
      '75054',
      '75261',
      '76001',
      '76002',
      '76005',
      '76006',
      '76010',
      '76011',
      '76012',
      '76013',
      '76014',
      '76015',
      '76016',
      '76017',
      '76018',
      '76019',
      '76021',
      '76022',
      '76028',
      '76034',
      '76036',
      '76039',
      '76040',
      '76051',
      '76052',
      '76053',
      '76054',
      '76060',
      '76063',
      '76071',
      '76092',
      '76102',
      '76103',
      '76104',
      '76105',
      '76106',
      '76107',
      '76108',
      '76109',
      '76110',
      '76111',
      '76112',
      '76114',
      '76115',
      '76116',
      '76117',
      '76118',
      '76119',
      '76120',
      '76122',
      '76123',
      '76126',
      '76127',
      '76129',
      '76131',
      '76132',
      '76133',
      '76134',
      '76135',
      '76137',
      '76140',
      '76148',
      '76155',
      '76164',
      '76177',
      '76179',
      '76180',
      '76182',
      '76244',
      '76248',
      '76262',
    ]),
    approximateCenter: { longitude: -97.25055088801136, latitude: 32.7687965998178 },
  },
  'tx-texas-dallas': {
    isActive: true,
    timezone: 'America/Chicago',
    displayName: 'Dallas',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '75001',
      '75006',
      '75007',
      '75010',
      '75023',
      '75038',
      '75039',
      '75040',
      '75041',
      '75042',
      '75043',
      '75044',
      '75048',
      '75050',
      '75051',
      '75052',
      '75060',
      '75061',
      '75062',
      '75063',
      '75074',
      '75075',
      '75080',
      '75081',
      '75082',
      '75088',
      '75089',
      '75093',
      '75094',
      '75104',
      '75115',
      '75116',
      '75134',
      '75137',
      '75141',
      '75146',
      '75149',
      '75150',
      '75172',
      '75180',
      '75181',
      '75182',
      '75201',
      '75202',
      '75203',
      '75204',
      '75205',
      '75206',
      '75207',
      '75208',
      '75209',
      '75210',
      '75211',
      '75212',
      '75214',
      '75215',
      '75216',
      '75217',
      '75218',
      '75219',
      '75220',
      '75223',
      '75224',
      '75225',
      '75226',
      '75227',
      '75228',
      '75229',
      '75230',
      '75231',
      '75232',
      '75233',
      '75234',
      '75235',
      '75236',
      '75237',
      '75238',
      '75240',
      '75241',
      '75243',
      '75244',
      '75246',
      '75247',
      '75248',
      '75249',
      '75251',
      '75252',
      '75253',
      '75254',
      '75270',
      '75287',
    ]),
    approximateCenter: { longitude: -96.78648675762956, latitude: 32.82598058502405 },
  },
  'tx-texas-san-antonio': {
    isActive: true,
    timezone: 'America/Chicago',
    displayName: 'San Antonio (Central)',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '78002',
      '78009',
      '78023',
      '78039',
      '78052',
      '78059',
      '78069',
      '78073',
      '78101',
      '78109',
      '78112',
      '78148',
      '78150',
      '78152',
      '78201',
      '78202',
      '78203',
      '78204',
      '78205',
      '78206',
      '78207',
      '78208',
      '78209',
      '78210',
      '78211',
      '78212',
      '78213',
      '78214',
      '78215',
      '78216',
      '78217',
      '78218',
      '78219',
      '78220',
      '78221',
      '78222',
      '78223',
      '78224',
      '78225',
      '78226',
      '78227',
      '78228',
      '78229',
      '78230',
      '78231',
      '78232',
      '78233',
      '78234',
      '78235',
      '78236',
      '78237',
      '78238',
      '78239',
      '78240',
      '78241',
      '78242',
      '78243',
      '78244',
      '78245',
      '78247',
      '78248',
      '78249',
      '78250',
      '78251',
      '78252',
      '78253',
      '78254',
      '78255',
      '78256',
      '78257',
      '78258',
      '78259',
      '78260',
      '78261',
      '78263',
      '78264',
      '78284',
    ]),
    approximateCenter: { longitude: -98.52584701716228, latitude: 29.44501992702678 },
  },
  'tx-texas-north-san-antonio': {
    isActive: true,
    timezone: 'America/Chicago',
    displayName: 'San Antonio (North)',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '78108',
      '78123',
      '78124',
      '78130',
      '78132',
      '78133',
      '78154',
      '78155',
      '78266',
      '78666',
    ]),
    approximateCenter: { longitude: -98.14382229510974, latitude: 29.668625792310326 },
  },
  'tx-texas-laredo': {
    isActive: true,
    timezone: 'America/Chicago',
    displayName: 'Laredo',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set(['78040', '78041', '78043', '78045', '78046']),
    approximateCenter: { longitude: -99.46784220069546, latitude: 27.572604360233665 },
  },
  'tx-texas-harlingen': {
    isActive: true,
    timezone: 'America/Chicago',
    displayName: 'Harlingen',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '78520',
      '78521',
      '78526',
      '78535',
      '78550',
      '78552',
      '78559',
      '78566',
      '78567',
      '78569',
      '78575',
      '78578',
      '78583',
      '78586',
      '78593',
    ]),
    approximateCenter: { longitude: -97.59721150165427, latitude: 26.13703523784006 },
  },
  'tx-texas-mcallen': {
    isActive: true,
    timezone: 'America/Chicago',
    displayName: 'McAllen',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '78501',
      '78503',
      '78504',
      '78516',
      '78537',
      '78538',
      '78539',
      '78541',
      '78542',
      '78543',
      '78557',
      '78558',
      '78560',
      '78562',
      '78570',
      '78572',
      '78573',
      '78574',
      '78576',
      '78577',
      '78579',
      '78589',
      '78596',
      '78599',
      '78668',
    ]),
    approximateCenter: { longitude: -98.1574565410466, latitude: 26.240899611801062 },
  },
  'tx-texas-rio-grande-city': {
    isActive: true,
    timezone: 'America/Chicago',
    displayName: 'Rio Grande City',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set(['78545', '78547', '78548', '78582', '78584', '78591', '78595']),
    approximateCenter: { longitude: -98.75856748804259, latitude: 26.460250090466293 },
  },
  'tx-texas-houston': {
    isActive: true,
    timezone: 'America/Chicago',
    displayName: 'Houston (Central)',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '77002',
      '77003',
      '77004',
      '77005',
      '77006',
      '77007',
      '77008',
      '77009',
      '77010',
      '77011',
      '77012',
      '77013',
      '77014',
      '77015',
      '77016',
      '77017',
      '77018',
      '77019',
      '77020',
      '77021',
      '77022',
      '77023',
      '77024',
      '77025',
      '77026',
      '77027',
      '77028',
      '77029',
      '77030',
      '77031',
      '77032',
      '77033',
      '77034',
      '77035',
      '77036',
      '77037',
      '77038',
      '77039',
      '77040',
      '77041',
      '77042',
      '77043',
      '77044',
      '77045',
      '77046',
      '77047',
      '77048',
      '77049',
      '77050',
      '77051',
      '77053',
      '77054',
      '77055',
      '77056',
      '77057',
      '77058',
      '77059',
      '77060',
      '77061',
      '77062',
      '77063',
      '77064',
      '77065',
      '77066',
      '77067',
      '77068',
      '77069',
      '77070',
      '77071',
      '77072',
      '77073',
      '77074',
      '77075',
      '77076',
      '77077',
      '77078',
      '77079',
      '77080',
      '77081',
      '77082',
      '77083',
      '77084',
      '77085',
      '77086',
      '77087',
      '77088',
      '77089',
      '77090',
      '77091',
      '77092',
      '77093',
      '77094',
      '77095',
      '77096',
      '77098',
      '77099',
      '77204',
      '77338',
      '77339',
      '77346',
      '77373',
      '77388',
      '77396',
      '77401',
      '77477',
      '77478',
      '77489',
      '77498',
      '77502',
      '77503',
      '77504',
      '77505',
      '77506',
      '77507',
      '77520',
      '77521',
      '77530',
      '77536',
      '77546',
      '77547',
      '77562',
      '77571',
      '77581',
      '77584',
      '77586',
      '77587',
      '77598',
    ]),
    approximateCenter: { longitude: -95.37433870683658, latitude: 29.765711291626626 },
  },
  'tx-texas-beaumont': {
    isActive: true,
    timezone: 'America/Chicago',
    displayName: 'Beaumont',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '77611',
      '77613',
      '77619',
      '77622',
      '77627',
      '77629',
      '77630',
      '77632',
      '77640',
      '77642',
      '77651',
      '77657',
      '77659',
      '77662',
      '77701',
      '77702',
      '77703',
      '77705',
      '77706',
      '77707',
      '77708',
      '77710',
      '77713',
    ]),
    approximateCenter: { longitude: -94.09855019410381, latitude: 30.04724892686902 },
  },
  'tx-texas-south-houston': {
    isActive: true,
    timezone: 'America/Chicago',
    displayName: 'Houston (South)',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '77510',
      '77511',
      '77515',
      '77517',
      '77518',
      '77531',
      '77534',
      '77539',
      '77541',
      '77545',
      '77550',
      '77551',
      '77563',
      '77565',
      '77566',
      '77568',
      '77573',
      '77577',
      '77578',
      '77583',
      '77590',
      '77591',
    ]),
    approximateCenter: { longitude: -95.16695440581051, latitude: 29.330940119936887 },
  },
  'tx-texas-rosenburg': {
    isActive: true,
    timezone: 'America/Chicago',
    displayName: 'Rosenburg',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '77406',
      '77407',
      '77417',
      '77430',
      '77435',
      '77441',
      '77444',
      '77450',
      '77451',
      '77454',
      '77459',
      '77461',
      '77464',
      '77469',
      '77471',
      '77476',
      '77479',
      '77485',
      '77494',
    ]),
    approximateCenter: { longitude: -95.85438105146186, latitude: 29.552783985663922 },
  },
  'tx-texas-west-houston': {
    isActive: true,
    timezone: 'America/Chicago',
    displayName: 'Houston (West)',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '77355',
      '77375',
      '77377',
      '77379',
      '77418',
      '77423',
      '77426',
      '77429',
      '77433',
      '77445',
      '77446',
      '77447',
      '77449',
      '77466',
      '77474',
      '77484',
      '77493',
    ]),
    approximateCenter: { longitude: -95.88362997672738, latitude: 29.989273647986103 },
  },
  'tx-texas-north-houston': {
    isActive: true,
    timezone: 'America/Chicago',
    displayName: 'Houston (North)',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '77301',
      '77302',
      '77303',
      '77304',
      '77306',
      '77316',
      '77318',
      '77328',
      '77354',
      '77356',
      '77357',
      '77362',
      '77365',
      '77372',
      '77378',
      '77380',
      '77381',
      '77382',
      '77384',
      '77385',
      '77386',
      '77389',
    ]),
    approximateCenter: { longitude: -95.4461244631981, latitude: 30.25826105293368 },
  },
  'tx-texas-east-houston': {
    isActive: true,
    timezone: 'America/Chicago',
    displayName: 'Houston (East)',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '77327',
      '77336',
      '77345',
      '77532',
      '77533',
      '77535',
      '77564',
      '77575',
      '77582',
    ]),
    approximateCenter: { longitude: -94.8717281611222, latitude: 30.097101981825563 },
  },
  'ca-los-angeles-lancaster': {
    isActive: true,
    timezone: 'America/Los_Angeles',
    displayName: 'Lancaster',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    postalCodes: new Set([
      '93510',
      '93534',
      '93535',
      '93536',
      '93543',
      '93550',
      '93551',
      '93552',
      '93560',
      '93591',
    ]),
    approximateCenter: { longitude: -118.10743932974759, latitude: 34.61413032470717 },
  },
  'ca-los-angeles-santa-clarita': {
    isActive: true,
    timezone: 'America/Los_Angeles',
    displayName: 'Santa Clarita',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    postalCodes: new Set([
      '91321',
      '91350',
      '91351',
      '91354',
      '91355',
      '91381',
      '91384',
      '91387',
      '91390',
    ]),
    approximateCenter: { longitude: -118.52270922834172, latitude: 34.44010316887833 },
  },
  'ca-los-angeles-simi-valley': {
    isActive: true,
    timezone: 'America/Los_Angeles',
    displayName: 'Simi Valley',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 12 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    postalCodes: new Set(['91304', '93021', '93063', '93065']),
    approximateCenter: { longitude: -118.74691117067051, latitude: 34.275885984183724 },
  },
  'ca-los-angeles-ventura': {
    isActive: true,
    timezone: 'America/Los_Angeles',
    displayName: 'Ventura',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    postalCodes: new Set([
      '93001',
      '93003',
      '93004',
      '93010',
      '93012',
      '93030',
      '93033',
      '93035',
      '93036',
      '93041',
      '93043',
      '93060',
      '93066',
    ]),
    approximateCenter: { longitude: -119.15417093376143, latitude: 34.24297342662406 },
  },
  'ca-bay-area-fresno': {
    isActive: true,
    timezone: 'America/Los_Angeles',
    displayName: 'Fresno',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    postalCodes: new Set([
      '93609',
      '93611',
      '93612',
      '93616',
      '93619',
      '93625',
      '93636',
      '93648',
      '93650',
      '93652',
      '93654',
      '93657',
      '93662',
      '93701',
      '93702',
      '93703',
      '93704',
      '93705',
      '93706',
      '93710',
      '93711',
      '93720',
      '93721',
      '93722',
      '93723',
      '93725',
      '93726',
      '93727',
      '93728',
      '93730',
      '93737',
      '93741',
    ]),
    approximateCenter: { longitude: -119.74185635420875, latitude: 36.75372530035502 },
  },
  'me-maine-kennebunk': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Kennebunk',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '03901',
      '03902',
      '03903',
      '03904',
      '03905',
      '03906',
      '03907',
      '03908',
      '03909',
      '04002',
      '04027',
      '04043',
      '04046',
      '04073',
      '04083',
      '04090',
    ]),
    approximateCenter: { longitude: -70.70131127313863, latitude: 43.29398636439545 },
  },
  'me-maine-portland': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Portland',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '04005',
      '04021',
      '04038',
      '04039',
      '04062',
      '04064',
      '04072',
      '04074',
      '04084',
      '04092',
      '04093',
      '04096',
      '04097',
      '04101',
      '04102',
      '04103',
      '04105',
      '04106',
      '04107',
    ]),
    approximateCenter: { longitude: -70.35310075460801, latitude: 43.68749701131258 },
  },
  'me-maine-lewiston': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Lewiston',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '04008',
      '04011',
      '04032',
      '04069',
      '04086',
      '04210',
      '04222',
      '04236',
      '04240',
      '04250',
      '04252',
      '04256',
      '04258',
      '04260',
      '04274',
      '04280',
      '04287',
    ]),
    approximateCenter: { longitude: -70.1413615455809, latitude: 44.02785285296838 },
  },
  'me-maine-augusta': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Augusta',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '04259',
      '04265',
      '04284',
      '04330',
      '04342',
      '04344',
      '04345',
      '04346',
      '04347',
      '04348',
      '04350',
      '04351',
      '04352',
      '04353',
      '04355',
      '04357',
      '04358',
      '04363',
      '04364',
      '04901',
      '04910',
      '04917',
      '04963',
      '04989',
    ]),
    approximateCenter: { longitude: -69.78723220463532, latitude: 44.32341851129758 },
  },
  'me-maine-skohegan': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Skohegan',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '04911',
      '04912',
      '04920',
      '04922',
      '04923',
      '04924',
      '04927',
      '04928',
      '04929',
      '04930',
      '04937',
      '04942',
      '04943',
      '04950',
      '04953',
      '04957',
      '04958',
      '04961',
      '04965',
      '04967',
      '04971',
      '04976',
      '04978',
      '04979',
    ]),
    approximateCenter: { longitude: -69.60298451501934, latitude: 44.863985594068005 },
  },
  'me-maine-bangor': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Bangor',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '04401',
      '04411',
      '04412',
      '04416',
      '04419',
      '04427',
      '04428',
      '04429',
      '04434',
      '04444',
      '04449',
      '04450',
      '04456',
      '04461',
      '04468',
      '04472',
      '04473',
      '04474',
      '04488',
      '04496',
      '04605',
      '04684',
      '04932',
      '04969',
    ]),
    approximateCenter: { longitude: -68.8219400558519, latitude: 44.79456711961043 },
  },
  'ct-connecticut-new-haven': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'New Haven',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '06401',
      '06403',
      '06405',
      '06410',
      '06418',
      '06444',
      '06460',
      '06461',
      '06467',
      '06468',
      '06470',
      '06473',
      '06477',
      '06478',
      '06479',
      '06482',
      '06483',
      '06484',
      '06488',
      '06492',
      '06510',
      '06511',
      '06512',
      '06513',
      '06514',
      '06515',
      '06516',
      '06517',
      '06518',
      '06519',
      '06524',
      '06525',
      '06604',
      '06605',
      '06606',
      '06607',
      '06608',
      '06610',
      '06611',
      '06614',
      '06615',
      '06702',
      '06704',
      '06705',
      '06706',
      '06708',
      '06710',
      '06712',
      '06716',
      '06751',
      '06752',
      '06755',
      '06762',
      '06770',
      '06776',
      '06779',
      '06782',
      '06783',
      '06784',
      '06786',
      '06787',
      '06793',
      '06794',
      '06795',
      '06798',
      '06801',
      '06804',
      '06810',
      '06811',
      '06812',
    ]),
    approximateCenter: { longitude: -73.10929689216424, latitude: 41.427686194094406 },
  },
  'ct-connecticut-hartford': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Hartford',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 20 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    postalCodes: new Set([
      '06001',
      '06002',
      '06010',
      '06013',
      '06016',
      '06019',
      '06023',
      '06026',
      '06029',
      '06032',
      '06033',
      '06035',
      '06037',
      '06040',
      '06042',
      '06043',
      '06051',
      '06052',
      '06053',
      '06062',
      '06066',
      '06067',
      '06070',
      '06071',
      '06073',
      '06074',
      '06078',
      '06081',
      '06082',
      '06084',
      '06085',
      '06088',
      '06089',
      '06092',
      '06093',
      '06095',
      '06096',
      '06103',
      '06105',
      '06106',
      '06107',
      '06108',
      '06109',
      '06110',
      '06111',
      '06112',
      '06114',
      '06117',
      '06118',
      '06119',
      '06120',
      '06160',
      '06232',
      '06238',
      '06414',
      '06416',
      '06450',
      '06451',
      '06455',
      '06456',
      '06457',
      '06459',
      '06480',
      '06481',
      '06489',
    ]),
    approximateCenter: { longitude: -72.6813176756917, latitude: 41.756003026662185 },
  },
  'ct-connecticut-norwich': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Norwich',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '06226',
      '06231',
      '06234',
      '06235',
      '06237',
      '06239',
      '06247',
      '06248',
      '06249',
      '06250',
      '06254',
      '06256',
      '06264',
      '06266',
      '06268',
      '06269',
      '06280',
      '06320',
      '06330',
      '06331',
      '06332',
      '06333',
      '06334',
      '06335',
      '06336',
      '06338',
      '06339',
      '06340',
      '06349',
      '06351',
      '06353',
      '06354',
      '06355',
      '06357',
      '06359',
      '06360',
      '06365',
      '06370',
      '06371',
      '06374',
      '06375',
      '06377',
      '06378',
      '06379',
      '06380',
      '06382',
      '06384',
      '06385',
      '06387',
      '06389',
      '06415',
      '06420',
      '06423',
      '06424',
      '06447',
      '06469',
    ]),
    approximateCenter: { longitude: -72.11834281689754, latitude: 41.574112664731125 },
  },
  'ga-georgia-savannah': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Savannah',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '30415',
      '31302',
      '31303',
      '31307',
      '31308',
      '31309',
      '31312',
      '31313',
      '31314',
      '31315',
      '31318',
      '31320',
      '31321',
      '31322',
      '31324',
      '31326',
      '31328',
      '31329',
      '31401',
      '31404',
      '31405',
      '31406',
      '31407',
      '31408',
      '31409',
      '31410',
      '31411',
      '31415',
      '31419',
    ]),
    approximateCenter: { longitude: -81.29353828136834, latitude: 32.08181390336995 },
  },
  'ga-georgia-augusta': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Augusta',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '30802',
      '30805',
      '30808',
      '30809',
      '30813',
      '30814',
      '30815',
      '30816',
      '30818',
      '30824',
      '30830',
      '30901',
      '30904',
      '30905',
      '30906',
      '30907',
      '30909',
    ]),
    approximateCenter: { longitude: -82.17203747122865, latitude: 33.39452340454999 },
  },
  'mi-michigan-marquette': {
    isActive: true,
    timezone: 'America/New_York',
    displayName: 'Marquette',
    requiresMatchmakingToClaimInMPX: true,
    etaPadding: Duration.fromISOTime('00:05'),
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 15 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '49808',
      '49814',
      '49833',
      '49841',
      '49849',
      '49855',
      '49861',
      '49866',
      '49879',
      '49885',
    ]),
    approximateCenter: { longitude: -87.68421611853299, latitude: 46.4580619923657 },
  },
  'in-indiana-santa-claus': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/Chicago',
    displayName: 'Santa Claus',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '47523',
      '47531',
      '47536',
      '47537',
      '47550',
      '47552',
      '47577',
      '47579',
      '47588',
      '47611',
      '47615',
      '47617',
      '47634',
      '47635',
      '47637',
    ]),
    approximateCenter: { longitude: -86.99317934864176, latitude: 38.05196253280069 },
  },
  'ca-los-angeles-bakersfield': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/Los_Angeles',
    displayName: 'Bakersfield',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '93215',
      '93250',
      '93263',
      '93301',
      '93304',
      '93305',
      '93306',
      '93307',
      '93308',
      '93309',
      '93311',
      '93312',
      '93313',
      '93314',
    ]),
    approximateCenter: { longitude: -119.07213010263756, latitude: 35.41137099716801 },
  },
  'ca-los-angeles-coachella': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/Los_Angeles',
    displayName: 'Coachella',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '92201',
      '92203',
      '92210',
      '92211',
      '92234',
      '92236',
      '92240',
      '92241',
      '92253',
      '92258',
      '92260',
      '92262',
      '92270',
      '92276',
    ]),
    approximateCenter: { longitude: -116.36526341842395, latitude: 33.78405324188269 },
  },
  'ca-los-angeles-murieta': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/Los_Angeles',
    displayName: 'Murieta',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '92003',
      '92028',
      '92059',
      '92061',
      '92082',
      '92530',
      '92562',
      '92563',
      '92590',
      '92591',
      '92592',
      '92595',
    ]),
    approximateCenter: { longitude: -117.15713515936959, latitude: 33.45803805752562 },
  },
  'ca-los-angeles-victorville': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/Los_Angeles',
    displayName: 'Victorville',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '92301',
      '92307',
      '92308',
      '92344',
      '92345',
      '92371',
      '92372',
      '92392',
      '92394',
      '92395',
    ]),
    approximateCenter: { longitude: -117.37738130324057, latitude: 34.48312691914782 },
  },
  'ct-connecticut-clinton': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Clinton',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '06409',
      '06412',
      '06413',
      '06417',
      '06419',
      '06422',
      '06426',
      '06437',
      '06438',
      '06441',
      '06442',
      '06443',
      '06471',
      '06472',
      '06475',
      '06498',
    ]),
    approximateCenter: { longitude: -72.55220181941611, latitude: 41.367772640754666 },
  },
  'ct-connecticut-north-east': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Northeast',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '06076',
      '06230',
      '06241',
      '06242',
      '06243',
      '06255',
      '06258',
      '06259',
      '06260',
      '06262',
      '06263',
      '06277',
      '06278',
      '06279',
      '06281',
      '06282',
    ]),
    approximateCenter: { longitude: -71.99821557317777, latitude: 41.9137764575714 },
  },
  'ct-connecticut-north-west': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Northwest',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '06018',
      '06021',
      '06024',
      '06027',
      '06031',
      '06039',
      '06057',
      '06058',
      '06059',
      '06060',
      '06061',
      '06063',
      '06065',
      '06068',
      '06069',
      '06090',
      '06091',
      '06098',
      '06750',
      '06753',
      '06754',
      '06756',
      '06757',
      '06758',
      '06759',
      '06763',
      '06777',
      '06778',
      '06785',
      '06790',
      '06791',
      '06796',
    ]),
    approximateCenter: { longitude: -73.1808918625588, latitude: 41.87054813031228 },
  },
  'ct-connecticut-stamford': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Stamford',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '06612',
      '06807',
      '06820',
      '06824',
      '06825',
      '06830',
      '06831',
      '06840',
      '06850',
      '06851',
      '06853',
      '06854',
      '06855',
      '06870',
      '06877',
      '06878',
      '06880',
      '06883',
      '06890',
      '06896',
      '06897',
      '06901',
      '06902',
      '06903',
      '06905',
      '06906',
      '06907',
    ]),
    approximateCenter: { longitude: -73.4633929683797, latitude: 41.12845291768926 },
  },
  'ga-georgia-athens': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Athens',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '30011',
      '30019',
      '30052',
      '30548',
      '30549',
      '30565',
      '30601',
      '30602',
      '30605',
      '30606',
      '30607',
      '30609',
      '30620',
      '30621',
      '30622',
      '30641',
      '30655',
      '30656',
      '30666',
      '30677',
      '30680',
      '30683',
    ]),
    approximateCenter: { longitude: -83.55986793436745, latitude: 33.935642928056154 },
  },
  'ga-georgia-carrollton': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Carrollton',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '30108',
      '30110',
      '30113',
      '30116',
      '30117',
      '30118',
      '30170',
      '30176',
      '30179',
      '30180',
      '30182',
      '30185',
      '30187',
      '30217',
      '30220',
      '30251',
      '30259',
      '30263',
      '30265',
      '30268',
      '30277',
    ]),
    approximateCenter: { longitude: -84.98087278250206, latitude: 33.526995012581146 },
  },
  'ga-georgia-columbus': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Columbus',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '31801',
      '31804',
      '31807',
      '31808',
      '31811',
      '31820',
      '31829',
      '31831',
      '31901',
      '31903',
      '31904',
      '31905',
      '31906',
      '31907',
      '31909',
    ]),
    approximateCenter: { longitude: -84.86966393825669, latitude: 32.55437366448012 },
  },
  'ga-georgia-covington': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Covington',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '30014',
      '30016',
      '30018',
      '30025',
      '30054',
      '30055',
      '30216',
      '30233',
      '30234',
      '30248',
      '30252',
      '30253',
      '30663',
    ]),
    approximateCenter: { longitude: -83.90193469617441, latitude: 33.492602175849235 },
  },
  'ga-georgia-dublin': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Dublin',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '30454',
      '31002',
      '31003',
      '31009',
      '31012',
      '31014',
      '31017',
      '31019',
      '31021',
      '31022',
      '31023',
      '31027',
      '31035',
      '31036',
      '31042',
      '31049',
      '31054',
      '31065',
      '31075',
      '31082',
      '31089',
      '31090',
      '31096',
    ]),
    approximateCenter: { longitude: -83.00969221369418, latitude: 32.573891093983164 },
  },
  'ga-georgia-eatonton': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Eatonton',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '30056',
      '30625',
      '30642',
      '30650',
      '30678',
      '31024',
      '31033',
      '31038',
      '31061',
      '31062',
      '31064',
      '31085',
      '31087',
    ]),
    approximateCenter: { longitude: -83.3768653009333, latitude: 33.32424225190959 },
  },
  'ga-georgia-gainsville': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Gainsville',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '30028',
      '30040',
      '30041',
      '30107',
      '30114',
      '30115',
      '30143',
      '30148',
      '30151',
      '30177',
      '30183',
      '30188',
      '30189',
      '30501',
      '30504',
      '30506',
      '30507',
      '30517',
      '30518',
      '30519',
      '30534',
      '30542',
      '30543',
      '30566',
      '30567',
      '30575',
    ]),
    approximateCenter: { longitude: -84.1124848521949, latitude: 34.26543386650327 },
  },
  'ga-georgia-griffin': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Griffin',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '30204',
      '30205',
      '30206',
      '30215',
      '30218',
      '30223',
      '30224',
      '30228',
      '30256',
      '30257',
      '30258',
      '30269',
      '30276',
      '30285',
      '30286',
      '30290',
      '30292',
      '30295',
      '31097',
    ]),
    approximateCenter: { longitude: -84.37375832802638, latitude: 33.16423424275137 },
  },
  'ga-georgia-la-grange': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'La Grange',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '30222',
      '30230',
      '30240',
      '30241',
      '30293',
      '31816',
      '31822',
      '31823',
      '31826',
      '31830',
      '31833',
    ]),
    approximateCenter: { longitude: -84.83402756307262, latitude: 32.938938895061746 },
  },
  'ga-georgia-macon': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Macon',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '31005',
      '31008',
      '31020',
      '31028',
      '31029',
      '31030',
      '31031',
      '31032',
      '31044',
      '31046',
      '31047',
      '31050',
      '31052',
      '31069',
      '31088',
      '31093',
      '31098',
      '31201',
      '31204',
      '31206',
      '31207',
      '31210',
      '31211',
      '31216',
      '31217',
      '31220',
    ]),
    approximateCenter: { longitude: -83.67017446926036, latitude: 32.750652070285454 },
  },
  'ga-georgia-rome': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Rome',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '30101',
      '30102',
      '30103',
      '30104',
      '30120',
      '30121',
      '30124',
      '30125',
      '30132',
      '30137',
      '30141',
      '30145',
      '30147',
      '30149',
      '30153',
      '30157',
      '30161',
      '30165',
      '30171',
      '30173',
      '30178',
      '30184',
    ]),
    approximateCenter: { longitude: -84.97290434506212, latitude: 34.13728880817953 },
  },
  'ga-georgia-statesboro': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Statesboro',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '30401',
      '30425',
      '30426',
      '30439',
      '30441',
      '30442',
      '30446',
      '30450',
      '30451',
      '30452',
      '30455',
      '30456',
      '30458',
      '30461',
      '30467',
      '30471',
      '30822',
    ]),
    approximateCenter: { longitude: -81.91148078504256, latitude: 32.64744427741229 },
  },
  'ga-georgia-tifton': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Tifton',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '31512',
      '31519',
      '31622',
      '31637',
      '31639',
      '31647',
      '31650',
      '31714',
      '31733',
      '31747',
      '31749',
      '31750',
      '31760',
      '31771',
      '31774',
      '31775',
      '31781',
      '31783',
      '31789',
      '31790',
      '31791',
      '31793',
      '31794',
      '31795',
      '31798',
    ]),
    approximateCenter: { longitude: -83.42430199246245, latitude: 31.48310689354565 },
  },
  'ga-georgia-valdosta': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Valdosta',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '31601',
      '31602',
      '31605',
      '31606',
      '31620',
      '31625',
      '31630',
      '31632',
      '31635',
      '31636',
      '31638',
      '31641',
      '31643',
      '31645',
      '31648',
      '31649',
      '31698',
      '31699',
    ]),
    approximateCenter: { longitude: -83.2386972835076, latitude: 30.908417375062054 },
  },
  'ga-georgia-vidalia': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Vidalia',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '30410',
      '30411',
      '30420',
      '30421',
      '30428',
      '30436',
      '30438',
      '30445',
      '30453',
      '30457',
      '30470',
      '30473',
      '30474',
      '31037',
      '31055',
      '31513',
      '31532',
      '31539',
      '31549',
      '31563',
    ]),
    approximateCenter: { longitude: -82.48501462378768, latitude: 32.074802214766734 },
  },
  'ga-georgia-waycross': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Waycross',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '31501',
      '31503',
      '31510',
      '31516',
      '31518',
      '31533',
      '31535',
      '31537',
      '31542',
      '31550',
      '31551',
      '31552',
      '31553',
      '31554',
      '31557',
      '31562',
      '31567',
      '31623',
      '31624',
      '31631',
      '31634',
      '31642',
    ]),
    approximateCenter: { longitude: -82.46441635981508, latitude: 31.240865164003882 },
  },
  'nh-new-hampshire-claremont': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Claremont',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '03431',
      '03445',
      '03448',
      '03456',
      '03467',
      '03601',
      '03602',
      '03603',
      '03605',
      '03607',
      '03608',
      '03609',
      '03743',
      '03745',
      '03773',
    ]),
    approximateCenter: { longitude: -72.30560685475436, latitude: 43.175501858070064 },
  },
  'nh-new-hampshire-conway': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Conway',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '03218',
      '03225',
      '03227',
      '03237',
      '03254',
      '03259',
      '03263',
      '03809',
      '03810',
      '03812',
      '03813',
      '03814',
      '03816',
      '03817',
      '03818',
      '03830',
      '03832',
      '03836',
      '03837',
      '03838',
      '03845',
      '03849',
      '03850',
      '03852',
      '03853',
      '03855',
      '03860',
      '03864',
      '03872',
      '03875',
      '03882',
      '03883',
      '03886',
      '03887',
      '03890',
      '03894',
      '03897',
    ]),
    approximateCenter: { longitude: -71.21348206681103, latitude: 43.72868933441575 },
  },
  'nh-new-hampshire-lebanon': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Lebanon',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '03216',
      '03217',
      '03221',
      '03222',
      '03226',
      '03230',
      '03233',
      '03240',
      '03241',
      '03243',
      '03245',
      '03255',
      '03256',
      '03257',
      '03260',
      '03264',
      '03268',
      '03273',
      '03278',
      '03280',
      '03284',
      '03287',
      '03741',
      '03748',
      '03750',
      '03752',
      '03753',
      '03755',
      '03756',
      '03766',
      '03768',
      '03770',
      '03781',
      '03782',
      '03784',
    ]),
    approximateCenter: { longitude: -71.9632717062539, latitude: 43.53236920762544 },
  },
  'nh-new-hampshire-peterborough': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Peterborough',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '03043',
      '03047',
      '03048',
      '03057',
      '03070',
      '03071',
      '03082',
      '03084',
      '03086',
      '03440',
      '03441',
      '03442',
      '03443',
      '03444',
      '03446',
      '03447',
      '03449',
      '03450',
      '03451',
      '03452',
      '03455',
      '03457',
      '03458',
      '03461',
      '03462',
      '03464',
      '03465',
      '03466',
      '03470',
    ]),
    approximateCenter: { longitude: -72.06438532163914, latitude: 42.884752821274255 },
  },
  'oh-ohio-athens': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Athens',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '43111',
      '43144',
      '43728',
      '45620',
      '45622',
      '45634',
      '45651',
      '45654',
      '45695',
      '45698',
      '45701',
      '45710',
      '45711',
      '45716',
      '45719',
      '45723',
      '45732',
      '45735',
      '45740',
      '45741',
      '45743',
      '45760',
      '45761',
      '45764',
      '45766',
      '45769',
      '45770',
      '45771',
      '45772',
      '45775',
      '45776',
      '45778',
      '45780',
      '45782',
    ]),
    approximateCenter: { longitude: -82.1220846519286, latitude: 39.27794807040021 },
  },
  'oh-ohio-cambridge': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Cambridge',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '43722',
      '43723',
      '43725',
      '43733',
      '43749',
      '43750',
      '43755',
      '43762',
      '43767',
      '43768',
      '43772',
      '43773',
      '43778',
      '43779',
      '43780',
      '43788',
      '43802',
      '43973',
      '43983',
      '44693',
      '44699',
    ]),
    approximateCenter: { longitude: -81.4712010910683, latitude: 40.0264218376576 },
  },
  'oh-ohio-carrollton': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Carrollton',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '43903',
      '43908',
      '43910',
      '43913',
      '43920',
      '43925',
      '43930',
      '43932',
      '43938',
      '43944',
      '43945',
      '43952',
      '43953',
      '43961',
      '43962',
      '43964',
      '43968',
      '43970',
      '44423',
      '44427',
      '44432',
      '44441',
      '44455',
      '44615',
      '44625',
      '44651',
      '44695',
    ]),
    approximateCenter: { longitude: -80.80347843143794, latitude: 40.549051104907356 },
  },
  'oh-ohio-coshocton': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Coshocton',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '43006',
      '43014',
      '43022',
      '43028',
      '43804',
      '43811',
      '43812',
      '43824',
      '43832',
      '43836',
      '43837',
      '43840',
      '43843',
      '43844',
      '43845',
      '44610',
      '44611',
      '44628',
      '44629',
      '44633',
      '44637',
      '44638',
      '44654',
      '44661',
      '44676',
      '44681',
      '44687',
      '44822',
      '44838',
      '44840',
      '44842',
      '44843',
      '44864',
    ]),
    approximateCenter: { longitude: -81.98292166484033, latitude: 40.48158155099646 },
  },
  'oh-ohio-jackson': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Jackson',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '45614',
      '45619',
      '45621',
      '45623',
      '45629',
      '45631',
      '45638',
      '45640',
      '45645',
      '45656',
      '45658',
      '45659',
      '45669',
      '45672',
      '45674',
      '45678',
      '45680',
      '45682',
      '45685',
      '45686',
      '45688',
      '45692',
      '45694',
      '45696',
    ]),
    approximateCenter: { longitude: -82.51478715974481, latitude: 38.77829441197283 },
  },
  'oh-ohio-portsmouth': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Porstmouth',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '45172',
      '45612',
      '45613',
      '45616',
      '45630',
      '45646',
      '45648',
      '45650',
      '45652',
      '45653',
      '45657',
      '45660',
      '45661',
      '45662',
      '45663',
      '45671',
      '45681',
      '45684',
      '45690',
    ]),
    approximateCenter: { longitude: -83.15758836954696, latitude: 38.93039890905649 },
  },
  'oh-ohio-st-clairsville': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'St. Clairsville',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '43718',
      '43759',
      '43901',
      '43906',
      '43907',
      '43912',
      '43917',
      '43933',
      '43935',
      '43939',
      '43943',
      '43947',
      '43948',
      '43950',
      '43963',
      '43971',
      '43974',
      '43976',
      '43977',
      '43981',
      '43985',
      '43986',
      '43988',
    ]),
    approximateCenter: { longitude: -80.89319406199628, latitude: 40.164655632799544 },
  },
  'oh-ohio-woodsfield': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Woodsfield',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '43713',
      '43716',
      '43719',
      '43747',
      '43754',
      '43757',
      '43786',
      '43793',
      '43902',
      '43914',
      '43915',
      '43931',
      '43942',
      '43946',
      '45734',
      '45745',
      '45767',
      '45789',
    ]),
    approximateCenter: { longitude: -81.09050928808921, latitude: 39.75955283998639 },
  },
  'ma-massachusetts-cape-cod': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Cape Cod',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '02534',
      '02536',
      '02537',
      '02540',
      '02542',
      '02543',
      '02556',
      '02559',
      '02561',
      '02563',
      '02601',
      '02630',
      '02631',
      '02632',
      '02633',
      '02635',
      '02637',
      '02638',
      '02639',
      '02641',
      '02642',
      '02644',
      '02645',
      '02646',
      '02648',
      '02650',
      '02652',
      '02653',
      '02655',
      '02657',
      '02659',
      '02660',
      '02664',
      '02666',
      '02667',
      '02670',
      '02671',
      '02673',
      '02675',
    ]),
    approximateCenter: { longitude: -70.27965183343106, latitude: 41.71746807176562 },
  },
  'ma-massachusetts-plymouth': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Plymouth',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '02330',
      '02346',
      '02347',
      '02360',
      '02538',
      '02558',
      '02562',
      '02571',
      '02717',
      '02738',
      '02770',
    ]),
    approximateCenter: { longitude: -70.75471143696385, latitude: 41.79629591104988 },
  },
  'ma-massachusetts-marshfield': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Marshfield',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 17 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 5 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 5 * MILE_IN_METERS,
    postalCodes: new Set([
      '02020',
      '02025',
      '02043',
      '02045',
      '02047',
      '02050',
      '02061',
      '02332',
      '02333',
      '02339',
      '02341',
      '02359',
      '02364',
      '02367',
      '02370',
    ]),
    approximateCenter: { longitude: -70.80487896163439, latitude: 42.10979337593356 },
  },
  'oh-ohio-marietta': {
    etaPadding: Duration.fromISOTime('00:05'),
    isActive: true,
    requiresMatchmakingToClaimInMPX: true,
    timezone: 'America/New_York',
    displayName: 'Marietta',
    maxDistanceBetweenNonSharedSlotAppointmentsMeters: 20 * MILE_IN_METERS,
    maxDistanceBetweenSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenConsecutiveSharedSlotSlotsMeters: 6 * MILE_IN_METERS,
    maxDistanceBetweenSharedAndNonSharedSlotAppointmentsMeters: 6 * MILE_IN_METERS,
    postalCodes: new Set([
      '43717',
      '43720',
      '43724',
      '43727',
      '43730',
      '43731',
      '43732',
      '43734',
      '43748',
      '43756',
      '43758',
      '43764',
      '43766',
      '43771',
      '43782',
      '43787',
      '45714',
      '45715',
      '45721',
      '45724',
      '45727',
      '45729',
      '45742',
      '45744',
      '45746',
      '45750',
      '45768',
      '45773',
      '45784',
      '45786',
      '45788',
    ]),
    approximateCenter: { longitude: -81.74036850400054, latitude: 39.60152534312949 },
  },
};

export default SERVICE_REGION_LOOKUP;
