import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleExclamation as faLightCircleExlamation } from '@fortawesome/pro-light-svg-icons/faCircleExclamation';
import { faSparkles as faLightSparkles } from '@fortawesome/pro-light-svg-icons/faSparkles';
import { faStar as faLightStar } from '@fortawesome/pro-light-svg-icons/faStar';
import { faTriangleExclamation as faLightTriangleExlamation } from '@fortawesome/pro-light-svg-icons/faTriangleExclamation';
import { faAngleLeft as faRegularAngleLeft } from '@fortawesome/pro-regular-svg-icons/faAngleLeft';
import { faAngleRight as faRegularAngleRight } from '@fortawesome/pro-regular-svg-icons/faAngleRight';
import { faCheck as faRegularCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faCircleXmark as faRegularCircleXmark } from '@fortawesome/pro-regular-svg-icons/faCircleXmark';
import { faClock as faRegularClock } from '@fortawesome/pro-regular-svg-icons/faClock';
import { faFilter as faRegularFilter } from '@fortawesome/pro-regular-svg-icons/faFilter';
import { faHeadset as faRegularHeadset } from '@fortawesome/pro-regular-svg-icons/faHeadset';
import { faMap as faRegularMap } from '@fortawesome/pro-regular-svg-icons/faMap';
import { faSirenOn as faRegularSirenOn } from '@fortawesome/pro-regular-svg-icons/faSirenOn';
import { faUserPlus as faRegularUserPlus } from '@fortawesome/pro-regular-svg-icons/faUserPlus';
import { faXmark as faRegularXmark } from '@fortawesome/pro-regular-svg-icons/faXmark';
import { faQuoteLeft } from '@fortawesome/pro-solid-svg-icons/faQuoteLeft';
import { faSparkles as faSolidSparkles } from '@fortawesome/pro-solid-svg-icons/faSparkles';
import { faStar as faSolidStar } from '@fortawesome/pro-solid-svg-icons/faStar';
import { faStreetView as faSolidStreetView } from '@fortawesome/pro-solid-svg-icons/faStreetView';
import { captureException } from '@sentry/react';

import isProduction from './isProduction';

// Add font awesome icons here for them to be included in the bundle.
// Once included in the bundle, they can be used anywhere by their string name.

const addedIcons = new Set();

export default function setupFontAwesome(): void {
  for (const icon of [
    faLightStar,
    faSolidStar,
    faLightSparkles,
    faSolidSparkles,
    faRegularHeadset,
    faRegularFilter,
    faSolidStreetView,
    faRegularXmark,
    faRegularSirenOn,
    faRegularClock,
    faRegularAngleLeft,
    faRegularAngleRight,
    faRegularMap,
    faRegularCircleXmark,
    faRegularCheck,
    faLightCircleExlamation,
    faLightTriangleExlamation,
    faRegularUserPlus,
    faQuoteLeft,
  ]) {
    library.add(icon);
    addedIcons.add(`${icon.prefix}|${icon.iconName}`);
  }
}

export function alertIfFontAwesomeIconNotImported(prefix: string, iconName: string): void {
  if (!addedIcons.has(`${prefix}|${iconName}`)) {
    const error = `Icon used without being imported. Add the icon in setupFontAwesome.ts. prefix: ${prefix}, icon name: ${iconName}`;
    if (isProduction()) {
      captureException(error);
    } else {
      throw new Error(error);
    }
  }
}
