/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateSerialCounterInput = {
  type: SerialCounterType,
  serialValue: number,
};

export enum SerialCounterType {
  PatientSerial = "PatientSerial",
  RequisitionSerial = "RequisitionSerial",
}


export type ModelSerialCounterConditionInput = {
  serialValue?: ModelIntInput | null,
  and?: Array< ModelSerialCounterConditionInput | null > | null,
  or?: Array< ModelSerialCounterConditionInput | null > | null,
  not?: ModelSerialCounterConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type SerialCounter = {
  __typename: "SerialCounter",
  type: SerialCounterType,
  serialValue: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateSerialCounterInput = {
  type: SerialCounterType,
  serialValue?: number | null,
};

export type DeleteSerialCounterInput = {
  type: SerialCounterType,
};

export type CreateAppointmentInput = {
  id?: string | null,
  status: AppointmentStatus,
  isFailed?: boolean | null,
  hasSuccessfulServices?: boolean | null,
  hasFailedServices?: boolean | null,
  needsRebooking?: boolean | null,
  isPreReviewed?: boolean | null,
  isPostReviewed?: boolean | null,
  isEscalated?: boolean | null,
  start: string,
  end: string,
  techID?: string | null,
  remoteTechID?: string | null,
  remoteTechMatchState?: RemoteTechMatchState | null,
  coordinatorID: string,
  location: LocationInput,
  partnerOrganization?: PartnerOrganization | null,
  preReviewChecklist?: ChecklistInput | null,
  checklist?: ChecklistInput | null,
  postReviewChecklist?: ChecklistInput | null,
  serviceRegionName: string,
  geographicalState?: string | null,
  avoidTechIDs?: Array< string > | null,
  lastBookedAt?: number | null,
  statusUpdatedAt?: number | null,
  stripeCustomerID?: string | null,
  stripePriceToCharge?: number | null,
  hasPetsAtLocation?: boolean | null,
  commentsFromCoordinatorAboutAppointment?: string | null,
  commentsFromTechAboutAppointment?: string | null,
  commentsFromTechAboutSystem?: string | null,
  commentsFromTechAboutAppointmentFailure?: string | null,
  commentsFromSchedulerAboutAppointmentFailure?: string | null,
  commentsFromSchedulerAboutCancellation?: string | null,
  partnerOrganizationBookingURL?: string | null,
  overrideMode?: AppointmentOverrideMode | null,
  sharedSlotRank: number,
  estimatedServiceDurationSeconds: number,
  stripeInvoiceLineItemIDs?: Array< string | null > | null,
  stripePaymentIntentIDs?: Array< string | null > | null,
  hasPatientConfirmed?: boolean | null,
  isGuestBooked?: boolean | null,
  referralID?: string | null,
  historyJSON?: string | null,
  externalID?: string | null,
  clonedFromAppointmentID?: string | null,
  remoteTechQualificationTypeRequired?: QualificationType | null,
  markedReadyForRemoteTechAt?: string | null,
};

export enum AppointmentStatus {
  CREATING = "CREATING", // The appointment is still being created in the booking flow.
  PLANNED = "PLANNED", // The appointment has been booked, but still needs review and routing.
  PLANNED_AWAITING_REQUISITION = "PLANNED_AWAITING_REQUISITION", // The appointment has been booked, but still needs at least one required requisition to be uploaded.
  PLANNED_AND_REVIEWED = "PLANNED_AND_REVIEWED", // The appointment has been reviewed for feasibility and routed for service.
  PLANNED_AND_REVIEWED_AWAITING_ACCEPTANCE = "PLANNED_AND_REVIEWED_AWAITING_ACCEPTANCE", // The appointment has been reviewed for feasibility and routed for service and is now awaiting tech acceptance.
  SERVICE_DEPARTED = "SERVICE_DEPARTED", // The tech has departed for the appointment.
  SERVICE_ARRIVED = "SERVICE_ARRIVED", // The tech has arrived at the appointment but not begun service with the patient.
  SERVICE_IN_PROGRESS = "SERVICE_IN_PROGRESS", // The tech has begun their service with the patient.
  SERVICE_FINISHED = "SERVICE_FINISHED", // The tech has finished their service with the patient.
  DELIVERY_DEPARTED = "DELIVERY_DEPARTED", // The tech has finished their service with the patient departed for specimen delivery.
  DELIVERY_ARRIVED = "DELIVERY_ARRIVED", // The tech has arrived at the lab, service center, or shipping center where they will drop off the specimens.
  DELIVERY_FINISHED = "DELIVERY_FINISHED", // The tech has transferred the specimens to the lab, service center, or shipping center.
  CLOSED = "CLOSED", // The tech has finished their service with the patient, any deliveries, and any comments, and marked the appointment as closed.
  CLOSED_AND_REVIEWED = "CLOSED_AND_REVIEWED", // The appointment has reviewed for completeness, including successful processing of any specimens.
  CANCELED = "CANCELED", // The appointment was canceled by the patient.
  FAILED = "FAILED", // All services for all encounters failed for some other reason, e.g. the specimens were rejected or failed to be processed.
  FAILED_WITH_ESCALATION = "FAILED_WITH_ESCALATION", // The appointment failed due a detected patient health escalation (FAILED + escalation).
  FAILED_AND_REVIEWED_REQUIRES_RESCHEDULE = "FAILED_AND_REVIEWED_REQUIRES_RESCHEDULE", // The appointment failed and should be rebooked by a CSR or coordinator (FAILED + requires follow up).
  FAILED_AND_REVIEWED = "FAILED_AND_REVIEWED", // The appointment failed and has been reviewed by a scheduler, nothing else needs to be done (FAILED + post-review complete).
}


export enum RemoteTechMatchState {
  ACCEPTED = "ACCEPTED",
  COMPLETE = "COMPLETE",
}


export type LocationInput = {
  address?: AddressInput | null,
  position?: LocationPositionInput | null,
};

export type AddressInput = {
  use?: AddressUse | null,
  lines: Array< string >,
  subunit?: string | null,
  city: string,
  state: string,
  postalCode: string,
};

export enum AddressUse {
  HOME = "HOME",
  WORK = "WORK",
  TEMP = "TEMP",
  OLD = "OLD",
  BILLING = "BILLING",
}


export type LocationPositionInput = {
  longitude: number,
  latitude: number,
};

export enum PartnerOrganization {
  DRVILLIVALAM = "DRVILLIVALAM",
  EXACTSCIENCES = "EXACTSCIENCES",
  NAVERIS = "NAVERIS",
  GUARDANT = "GUARDANT",
  GUARDANTSHIELD = "GUARDANTSHIELD",
  GRAIL = "GRAIL",
  AFFORDABLEPATERNITY = "AFFORDABLEPATERNITY",
  DISCOVERHEALTH = "DISCOVERHEALTH",
  MYDOCTORMEDICALGROUP = "MYDOCTORMEDICALGROUP",
  MYDOCTORMEDICALGROUPVIP = "MYDOCTORMEDICALGROUPVIP",
  HALESPORTS = "HALESPORTS",
  READY2NURSE = "READY2NURSE",
  PENINSULADOCTOR = "PENINSULADOCTOR",
  ALPHACARE = "ALPHACARE",
  BESTHOMEHEALTH = "BESTHOMEHEALTH",
  MERIDIANHOMEHEALTH = "MERIDIANHOMEHEALTH",
  RUPAHEALTH = "RUPAHEALTH",
  STPATRICKHOMEHEALTH = "STPATRICKHOMEHEALTH",
  ALLIANCEHOMEHEALTH = "ALLIANCEHOMEHEALTH",
  AAHCKINDREDSANJOSE = "AAHCKINDREDSANJOSE",
  AAHCKINDREDALAMEDA = "AAHCKINDREDALAMEDA",
  ASSISTONCALL = "ASSISTONCALL",
  HARMONYHHSANTACLARA = "HARMONYHHSANTACLARA",
  HARMONYHHPLEASANTHILL = "HARMONYHHPLEASANTHILL",
  FIRSTCHOICEHOMEHEALTH = "FIRSTCHOICEHOMEHEALTH",
  YOURCHOICEHOMEHEALTH = "YOURCHOICEHOMEHEALTH",
  PRIMELIVINGHOMEHEALTH = "PRIMELIVINGHOMEHEALTH",
  NORTHBAYPRIVATEMEDICINE = "NORTHBAYPRIVATEMEDICINE",
  NURSINGANDREHABATHOME = "NURSINGANDREHABATHOME",
  CONCIERGEMDLA = "CONCIERGEMDLA",
  SEHAJHOMEHEALTH = "SEHAJHOMEHEALTH",
  KRISTARAMONASMD = "KRISTARAMONASMD",
  AMOREHOMEHEALTH = "AMOREHOMEHEALTH",
  INTOUCHHOMECARE = "INTOUCHHOMECARE",
  DRIPHYDRATIONPALOALTO = "DRIPHYDRATIONPALOALTO",
  CAREMUST = "CAREMUST",
  PINNACLEHOMEHEALTH = "PINNACLEHOMEHEALTH",
  GUARDIANANGELHOMECARE = "GUARDIANANGELHOMECARE",
  EMMANUELHEALTHCAREINC = "EMMANUELHEALTHCAREINC",
  HOMEHEALTHBAYAREAINC = "HOMEHEALTHBAYAREAINC",
  ELDERCAREMANAGEMENT = "ELDERCAREMANAGEMENT",
  SILVERADOBELMONTMEMORYCARE = "SILVERADOBELMONTMEMORYCARE",
  COMFORTHOMEHEALTHCARE = "COMFORTHOMEHEALTHCARE",
  HEALTHFLEXHOMEHEALTH = "HEALTHFLEXHOMEHEALTH",
  SOCOLMD = "SOCOLMD",
  FOUNDATIONMEDICINE = "FOUNDATIONMEDICINE",
  ABLHEALTHCARE = "ABLHEALTHCARE",
  CEDARSSINAI = "CEDARSSINAI",
  CAREINTOUCHHOMEHEALTHAGENCY = "CAREINTOUCHHOMEHEALTHAGENCY",
  PERPETUALCAREHOMEHEALTH = "PERPETUALCAREHOMEHEALTH",
  OPOHEALTH = "OPOHEALTH",
  TRUEMED = "TRUEMED",
  VNHOMEHEALTHCARE = "VNHOMEHEALTHCARE",
  GEORGIAHANDSHOULDERELBOW = "GEORGIAHANDSHOULDERELBOW",
  LONETREEPOSTACUTE = "LONETREEPOSTACUTE",
  LEBAE = "LEBAE",
  ASIANNETWORKPACIFICHOMECARE = "ASIANNETWORKPACIFICHOMECARE",
  MARSHALLMEDICALCENTER = "MARSHALLMEDICALCENTER",
  SUNCRESTHOSPICE = "SUNCRESTHOSPICE",
  JOHNMUIRHEALTH = "JOHNMUIRHEALTH",
  DRIPHYDRATION = "DRIPHYDRATION",
  OSCAR = "OSCAR",
  UCSF = "UCSF",
  A1HOMEHEALTHANDHOSPICE = "A1HOMEHEALTHANDHOSPICE",
  CAPITOLHEALTHCARE = "CAPITOLHEALTHCARE",
  HEALTHFLEXHOSPICE = "HEALTHFLEXHOSPICE",
  TRUEMEDSACRAMENTO = "TRUEMEDSACRAMENTO",
  SONDERHEALTHCARE = "SONDERHEALTHCARE",
  KANEHEALTH = "KANEHEALTH",
  ESSENTIALSHOMEHEALTH = "ESSENTIALSHOMEHEALTH",
  BMCDEMO = "BMCDEMO",
  PULSEDEMO = "PULSEDEMO",
  DEMOPROVIDER = "DEMOPROVIDER",
  DEMOLAB = "DEMOLAB",
  OSCARDEMO = "OSCARDEMO",
  NATERA = "NATERA",
  MATTAPAN = "MATTAPAN",
  DOTHOUSE = "DOTHOUSE",
  CODMAN = "CODMAN",
  BMC = "BMC",
  BMCEVANS = "BMCEVANS",
  FIREFLYHEALTH = "FIREFLYHEALTH",
  SILVERLINESTAFF = "SILVERLINESTAFF",
  APOLLOHOMEHEALTH = "APOLLOHOMEHEALTH",
  STANFORDCANCERCENTER = "STANFORDCANCERCENTER",
  WELLSENSE = "WELLSENSE",
  BMCFAMILYMEDICINE = "BMCFAMILYMEDICINE",
  ROSLINDALE = "ROSLINDALE",
  BRADFORDRABINMDANDHIROYUHATANOMD = "BRADFORDRABINMDANDHIROYUHATANOMD",
  THEBODYWRX = "THEBODYWRX",
  HHRGCARELINK = "HHRGCARELINK",
  HHRGHELPINGHANDS = "HHRGHELPINGHANDS",
  HHRGMARYSHELP = "HHRGMARYSHELP",
  HHRGGOLDENPACIFICHOMEHEALTH = "HHRGGOLDENPACIFICHOMEHEALTH",
  HHRGELIXIR = "HHRGELIXIR",
  HHRGNORCAL = "HHRGNORCAL",
  STANFORDRESEARCH = "STANFORDRESEARCH",
  STORYHEALTH = "STORYHEALTH",
  INTERMOUNTAINCANCERCENTERSALTLAKE = "INTERMOUNTAINCANCERCENTERSALTLAKE",
  INTERMOUNTAINCANCERCENTERSTGEORGE = "INTERMOUNTAINCANCERCENTERSTGEORGE",
  INTERMOUNTAINCANCERCENTERRIVERTON = "INTERMOUNTAINCANCERCENTERRIVERTON",
  TRAININGPARTNER = "TRAININGPARTNER",
  ALIGNMENTSTARS = "ALIGNMENTSTARS",
  ALIGNMENTCAREEXTENSION = "ALIGNMENTCAREEXTENSION",
  CHARLESRIVERFAMILYMEDICINE = "CHARLESRIVERFAMILYMEDICINE",
  OSCARHEALTH = "OSCARHEALTH",
  MOLINAUT = "MOLINAUT",
  MOLINAMI = "MOLINAMI",
  MOLINACA = "MOLINACA",
  WELLSENSENHMEDICARE = "WELLSENSENHMEDICARE",
  WELLSENSENHMEDICAID = "WELLSENSENHMEDICAID",
  UCIHEALTH = "UCIHEALTH",
  MOLINAFL = "MOLINAFL",
  ELEVANCEHEALTH = "ELEVANCEHEALTH",
  CENTENEOH = "CENTENEOH",
  CENTENECA = "CENTENECA",
  CENTENEFL = "CENTENEFL",
  CENTENEMA = "CENTENEMA",
  CENTENEMI = "CENTENEMI",
  CENTENENH = "CENTENENH",
  UHCMA = "UHCMA",
  HUMANANY = "HUMANANY",
}


export type ChecklistInput = {
  items: Array< ChecklistItemInput >,
};

export type ChecklistItemInput = {
  title: string,
  status: ChecklistItemStatus,
  fields: Array< ChecklistItemFieldInput >,
  // The unique, stable name for the item, used for extracting structured data and tracking status changes.
  name?: string | null,
  encounterID?: string | null,
  mediaType?: MediaType | null,
  // The timestamp on item change, currently using status value change.
  updatedAt?: number | null,
};

export enum ChecklistItemStatus {
  PENDING = "PENDING",
  DONE = "DONE",
}


export type ChecklistItemFieldInput = {
  type: ChecklistItemFieldType,
  label?: string | null,
  placeholder?: string | null,
  options?: Array< ChecklistItemOptionInput > | null,
  // The unique, stable name for the Field, used for extracting structured data and tracking value changes.
  name?: string | null,
  // The timestamp on field change, stores client timestamp
  updatedAt?: number | null,
  value?: string | null,
  values?: Array< string > | null,
  upload?: S3ObjectInput | null,
  uploadS3ObjectID?: string | null,
  encounterID?: string | null,
  explanation?: string | null,
  image?: ChecklistItemImageInput | null,
  help?: string | null,
  icon?: string | null,
  isRequired?: boolean | null,
  parametersJSON?: string | null,
};

export enum ChecklistItemFieldType {
  TITLE = "TITLE",
  TEXT = "TEXT",
  INFORMATION = "INFORMATION",
  WARNING = "WARNING",
  TEXT_INPUT = "TEXT_INPUT",
  TEXT_AREA = "TEXT_AREA",
  CHECKBOX = "CHECKBOX",
  MULTIPLE_OPTIONS = "MULTIPLE_OPTIONS",
  SINGLE_OPTION = "SINGLE_OPTION",
  UPLOAD_PHOTO = "UPLOAD_PHOTO",
  IMAGE = "IMAGE",
  LOCATION = "LOCATION",
  OPEN_APP = "OPEN_APP",
  REVIEW_ENCOUNTER_COMMENTS = "REVIEW_ENCOUNTER_COMMENTS",
  REVIEW_ENCOUNTER_SERVICES = "REVIEW_ENCOUNTER_SERVICES",
  REVIEW_APPOINTMENT_COMMENTS = "REVIEW_APPOINTMENT_COMMENTS",
  REVIEW_APPOINTMENT_EXTERNAL_ID = "REVIEW_APPOINTMENT_EXTERNAL_ID",
  REVIEW_PAST_APPOINTMENT_COMMENTS = "REVIEW_PAST_APPOINTMENT_COMMENTS",
  REVIEW_PATIENT_COMMENTS = "REVIEW_PATIENT_COMMENTS",
  DEPART_TO_APPOINTMENT = "DEPART_TO_APPOINTMENT",
  ARRIVE_AT_APPOINTMENT = "ARRIVE_AT_APPOINTMENT",
  GATHER_COVID19_TEST_REASONS = "GATHER_COVID19_TEST_REASONS",
  GATHER_BLOOD_COLLECTION_DETAILS = "GATHER_BLOOD_COLLECTION_DETAILS",
  CONFIRM_PATIENT_IDENTITY = "CONFIRM_PATIENT_IDENTITY",
  GATHER_ETHNICITY = "GATHER_ETHNICITY",
  GATHER_RACES = "GATHER_RACES",
  GATHER_GENDER = "GATHER_GENDER",
  GATHER_SEX = "GATHER_SEX",
  GATHER_PATIENT_HEIGHT = "GATHER_PATIENT_HEIGHT",
  GATHER_PATIENT_WEIGHT = "GATHER_PATIENT_WEIGHT",
  GATHER_PHONE_NUMBER = "GATHER_PHONE_NUMBER",
  GATHER_EMAIL = "GATHER_EMAIL",
  CONFIRM_ADHERENCE_TO_CONSTRAINTS = "CONFIRM_ADHERENCE_TO_CONSTRAINTS",
  REVIEW_BLOOD_DRAW_HISTORY = "REVIEW_BLOOD_DRAW_HISTORY",
  REVIEW_PAST_BLOOD_COLLECTION_DETAILS = "REVIEW_PAST_BLOOD_COLLECTION_DETAILS",
  COLLECT_BLOOD_SPECIMENS = "COLLECT_BLOOD_SPECIMENS",
  LABEL_BLOOD_SPECIMENS = "LABEL_BLOOD_SPECIMENS",
  DEPART_TO_LAB = "DEPART_TO_LAB",
  DEPART_TO_DROPOFF = "DEPART_TO_DROPOFF",
  ARRIVE_AT_LAB = "ARRIVE_AT_LAB",
  TRANSFER_SPECIMENS_TO_LAB = "TRANSFER_SPECIMENS_TO_LAB",
  SUBMIT_COMMENTS = "SUBMIT_COMMENTS",
  MARK_FAILED_APPOINTMENT = "MARK_FAILED_APPOINTMENT",
  CLOSE_APPOINTMENT = "CLOSE_APPOINTMENT",
  COLLECT_VITAL = "COLLECT_VITAL",
  MONOFILAMENT_TEST_LEFT = "MONOFILAMENT_TEST_LEFT",
  MONOFILAMENT_TEST_RIGHT = "MONOFILAMENT_TEST_RIGHT",
  REVIEW_FAILED_SERVICES = "REVIEW_FAILED_SERVICES",
  REQUEST_FOR_REMOTE_TECH_MATCH = "REQUEST_FOR_REMOTE_TECH_MATCH",
  AWV_PROVIDER_CALL = "AWV_PROVIDER_CALL", // AWV stands for Annual Wellness Visit
  COLLECT_MEDICATIONS = "COLLECT_MEDICATIONS",
  REVIEW_REQUISITION = "REVIEW_REQUISITION",
  REVIEW_INSURANCE_CARD = "REVIEW_INSURANCE_CARD",
  REVIEW_MEDICARE_CARD = "REVIEW_MEDICARE_CARD",
  REVIEW_SPRINTER_FEASIBILITY = "REVIEW_SPRINTER_FEASIBILITY",
  REVIEW_BMI = "REVIEW_BMI",
  GATHER_SERVICE_REQUESTS = "GATHER_SERVICE_REQUESTS",
  CANCEL_APPOINTMENT = "CANCEL_APPOINTMENT",
  GENERATE_APPOINTMENT_CHECKLIST = "GENERATE_APPOINTMENT_CHECKLIST",
  REGISTER_PATIENTS_IN_IMAGEMOVER = "REGISTER_PATIENTS_IN_IMAGEMOVER",
  REROUTE_APPOINTMENT = "REROUTE_APPOINTMENT",
  CLOSE_PRE_REVIEW = "CLOSE_PRE_REVIEW",
  SUBMIT_PRE_REVIEW = "SUBMIT_PRE_REVIEW",
  REVIEW_CONTACT_POINTS = "REVIEW_CONTACT_POINTS",
  REVIEW_APPOINTMENT_FAILURE = "REVIEW_APPOINTMENT_FAILURE",
  REVIEW_ENCOUNTER_SERVICE_OUTCOMES = "REVIEW_ENCOUNTER_SERVICE_OUTCOMES",
  REVIEW_BILLING_DETAILS = "REVIEW_BILLING_DETAILS",
  SUBMIT_POST_REVIEW = "SUBMIT_POST_REVIEW",
  GENERATE_ENCOUNTER_SUMMARY = "GENERATE_ENCOUNTER_SUMMARY",
  UPLOAD_LAB_RESULTS = "UPLOAD_LAB_RESULTS",
  SEND_EFAX = "SEND_EFAX",
  SEND_EMAIL = "SEND_EMAIL",
  SEND_ENCOUNTER_SUMMARY_TO_PRACTITIONER = "SEND_ENCOUNTER_SUMMARY_TO_PRACTITIONER",
  REVIEW_ENCOUNTER_PATIENT_INFO = "REVIEW_ENCOUNTER_PATIENT_INFO",
  REVIEW_PATIENT_INFO = "REVIEW_PATIENT_INFO",
  REVIEW_UNSET_REQUIRED_FIELDS = "REVIEW_UNSET_REQUIRED_FIELDS",
}


export type ChecklistItemOptionInput = {
  label: string,
  value: string,
};

export type S3ObjectInput = {
  identityID: string,
  bucket?: string | null,
  region?: string | null,
  key: string,
};

export type ChecklistItemImageInput = {
  uri: string,
  width: number,
  height: number,
};

export enum MediaType {
  INSURANCE_CARD = "INSURANCE_CARD",
  MEDICARE_CARD = "MEDICARE_CARD",
  REQUISITION = "REQUISITION",
  SPRINTER_FEASIBILITY = "SPRINTER_FEASIBILITY",
  SPRINTER_CHECKLIST = "SPRINTER_CHECKLIST",
  ENCOUNTER_SUMMARY = "ENCOUNTER_SUMMARY",
  SDOH_RESOURCES = "SDOH_RESOURCES",
}


export enum AppointmentOverrideMode {
  TECH_FORCE_OVERRIDE = "TECH_FORCE_OVERRIDE",
}


export enum QualificationType {
  NURSE_PRACTITIONER = "NURSE_PRACTITIONER",
}


export type ModelAppointmentConditionInput = {
  status?: ModelAppointmentStatusInput | null,
  isFailed?: ModelBooleanInput | null,
  hasSuccessfulServices?: ModelBooleanInput | null,
  hasFailedServices?: ModelBooleanInput | null,
  needsRebooking?: ModelBooleanInput | null,
  isPreReviewed?: ModelBooleanInput | null,
  isPostReviewed?: ModelBooleanInput | null,
  isEscalated?: ModelBooleanInput | null,
  start?: ModelStringInput | null,
  end?: ModelStringInput | null,
  techID?: ModelIDInput | null,
  remoteTechID?: ModelIDInput | null,
  remoteTechMatchState?: ModelRemoteTechMatchStateInput | null,
  coordinatorID?: ModelIDInput | null,
  partnerOrganization?: ModelPartnerOrganizationInput | null,
  serviceRegionName?: ModelStringInput | null,
  geographicalState?: ModelStringInput | null,
  avoidTechIDs?: ModelIDInput | null,
  lastBookedAt?: ModelIntInput | null,
  statusUpdatedAt?: ModelIntInput | null,
  stripeCustomerID?: ModelStringInput | null,
  stripePriceToCharge?: ModelIntInput | null,
  hasPetsAtLocation?: ModelBooleanInput | null,
  commentsFromCoordinatorAboutAppointment?: ModelStringInput | null,
  commentsFromTechAboutAppointment?: ModelStringInput | null,
  commentsFromTechAboutSystem?: ModelStringInput | null,
  commentsFromTechAboutAppointmentFailure?: ModelStringInput | null,
  commentsFromSchedulerAboutAppointmentFailure?: ModelStringInput | null,
  commentsFromSchedulerAboutCancellation?: ModelStringInput | null,
  partnerOrganizationBookingURL?: ModelStringInput | null,
  overrideMode?: ModelAppointmentOverrideModeInput | null,
  sharedSlotRank?: ModelFloatInput | null,
  estimatedServiceDurationSeconds?: ModelIntInput | null,
  stripeInvoiceLineItemIDs?: ModelStringInput | null,
  stripePaymentIntentIDs?: ModelStringInput | null,
  hasPatientConfirmed?: ModelBooleanInput | null,
  isGuestBooked?: ModelBooleanInput | null,
  referralID?: ModelIDInput | null,
  historyJSON?: ModelStringInput | null,
  externalID?: ModelIDInput | null,
  clonedFromAppointmentID?: ModelIDInput | null,
  remoteTechQualificationTypeRequired?: ModelQualificationTypeInput | null,
  markedReadyForRemoteTechAt?: ModelStringInput | null,
  and?: Array< ModelAppointmentConditionInput | null > | null,
  or?: Array< ModelAppointmentConditionInput | null > | null,
  not?: ModelAppointmentConditionInput | null,
};

export type ModelAppointmentStatusInput = {
  eq?: AppointmentStatus | null,
  ne?: AppointmentStatus | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelRemoteTechMatchStateInput = {
  eq?: RemoteTechMatchState | null,
  ne?: RemoteTechMatchState | null,
};

export type ModelPartnerOrganizationInput = {
  eq?: PartnerOrganization | null,
  ne?: PartnerOrganization | null,
};

export type ModelAppointmentOverrideModeInput = {
  eq?: AppointmentOverrideMode | null,
  ne?: AppointmentOverrideMode | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelQualificationTypeInput = {
  eq?: QualificationType | null,
  ne?: QualificationType | null,
};

export type Appointment = {
  __typename: "Appointment",
  id: string,
  // The current status of the appointment (See AppointmentStatus enum)
  status: AppointmentStatus,
  // Whether the appointment has been marked failed
  isFailed?: boolean | null,
  // Whether the appointment has at least one service completed in any of the encounters
  hasSuccessfulServices?: boolean | null,
  // Whether the appointment has at least one service failed in any of the encounters
  hasFailedServices?: boolean | null,
  // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
  needsRebooking?: boolean | null,
  // Whether the appointment has passed the pre-review phase
  isPreReviewed?: boolean | null,
  // Whether the appointment has passed the post-review phase
  isPostReviewed?: boolean | null,
  // Whether the appointment has been escalated
  isEscalated?: boolean | null,
  // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
  start: string,
  // Expected appointment end date/time
  end: string,
  // The technician assigned to the appointment
  techID?: string | null,
  remoteTechID?: string | null,
  remoteTechMatchState?: RemoteTechMatchState | null,
  coordinatorID: string,
  location: Location,
  partnerOrganization?: PartnerOrganization | null,
  preReviewChecklist?: Checklist | null,
  checklist?: Checklist | null,
  postReviewChecklist?: Checklist | null,
  serviceRegionName: string,
  geographicalState?: string | null,
  avoidTechIDs?: Array< string > | null,
  lastBookedAt?: number | null,
  statusUpdatedAt?: number | null,
  stripeCustomerID?: string | null,
  // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
  stripePriceToCharge?: number | null,
  hasPetsAtLocation?: boolean | null,
  // Comments about logistics like door codes, directions, etc.
  commentsFromCoordinatorAboutAppointment?: string | null,
  // Comments about logistics like door codes, directions, etc. (but from techs)
  commentsFromTechAboutAppointment?: string | null,
  // Comments about app feedback, bugs, feature requests, etc.
  commentsFromTechAboutSystem?: string | null,
  // Comments about why an appointment was put into a failed state
  commentsFromTechAboutAppointmentFailure?: string | null,
  // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
  commentsFromSchedulerAboutAppointmentFailure?: string | null,
  // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
  commentsFromSchedulerAboutCancellation?: string | null,
  partnerOrganizationBookingURL?: string | null,
  encounters?: ModelEncounterConnection | null,
  tech?: Tech | null,
  remoteTech?: RemoteTech | null,
  coordinator: Coordinator,
  overrideMode?: AppointmentOverrideMode | null,
  // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
  // The default value is 0.5.
  sharedSlotRank: number,
  sharedSlotAppointments?:  Array<Appointment > | null,
  // Represents how long the appointment should take based on product types
  estimatedServiceDurationSeconds: number,
  // For invoiced partners, the invoice line items associated with this appointment.
  stripeInvoiceLineItemIDs?: Array< string | null > | null,
  // For patient-paid appointments, any payment attempts associated with this appointment.
  stripePaymentIntentIDs?: Array< string | null > | null,
  // Whether the appointment has been confirmed or if the patient requested a change
  // When undefined or null, it means that the confirmation is still pending
  hasPatientConfirmed?: boolean | null,
  // Whether or not the appointment was booked in an unauthenticated flow
  isGuestBooked?: boolean | null,
  // Referral information from which the appointment was created (optional)
  referralID?: string | null,
  referral?: Referral | null,
  // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
  historyJSON?: string | null,
  // An external ID associated with the appointment, such as a job ID from MPX
  externalID?: string | null,
  // The id of the appointment that this appointment is cloned from. Cloning includes information
  // such as location, patients, and requisitions that are carried over from the original.
  clonedFromAppointmentID?: string | null,
  // The type of qualification a remote tech needs to have for this appointment.
  // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
  // Not present if the appointment has no remote component.
  remoteTechQualificationTypeRequired?: QualificationType | null,
  // Appointment date/time added to "queue"
  // This timestamp is used to determine the ordering of when remoteTech should be assigned
  markedReadyForRemoteTechAt?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type Location = {
  __typename: "Location",
  address?: Address | null,
  position?: LocationPosition | null,
  satellitePhotoURL?: string | null,
  streetViewPhotoURL?: string | null,
};

export type Address = {
  __typename: "Address",
  use?: AddressUse | null,
  lines: Array< string >,
  subunit?: string | null,
  city: string,
  state: string,
  postalCode: string,
};

export type LocationPosition = {
  __typename: "LocationPosition",
  longitude: number,
  latitude: number,
};

export type Checklist = {
  __typename: "Checklist",
  items:  Array<ChecklistItem >,
};

export type ChecklistItem = {
  __typename: "ChecklistItem",
  title: string,
  // The unique, stable name for the item, used for extracting structured data and tracking status changes.
  name?: string | null,
  status: ChecklistItemStatus,
  fields:  Array<ChecklistItemField >,
  encounterID?: string | null,
  mediaType?: MediaType | null,
  updatedAt?: number | null,
};

export type ChecklistItemField = {
  __typename: "ChecklistItemField",
  type: ChecklistItemFieldType,
  label?: string | null,
  placeholder?: string | null,
  options?:  Array<ChecklistItemOption > | null,
  name?: string | null,
  value?: string | null,
  values?: Array< string > | null,
  upload?: S3Object | null,
  uploadS3ObjectID?: string | null,
  encounterID?: string | null,
  explanation?: string | null,
  image?: ChecklistItemImage | null,
  updatedAt?: number | null,
  help?: string | null,
  icon?: string | null,
  isRequired?: boolean | null,
  parametersJSON?: string | null,
};

export type ChecklistItemOption = {
  __typename: "ChecklistItemOption",
  label: string,
  value: string,
};

export type S3Object = {
  __typename: "S3Object",
  identityID: string,
  bucket?: string | null,
  region?: string | null,
  key: string,
  // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
  // making PDF form as regular PDF without editable fields (resolves issues during printing)
  url?: string | null,
};

export type ChecklistItemImage = {
  __typename: "ChecklistItemImage",
  uri: string,
  width: number,
  height: number,
};

export type ModelEncounterConnection = {
  __typename: "ModelEncounterConnection",
  items:  Array<Encounter | null >,
  nextToken?: string | null,
};

export type Encounter = {
  __typename: "Encounter",
  id: string,
  appointmentID: string,
  appointment: Appointment,
  patientID: string,
  patient: Patient,
  coordinatorID: string,
  coordinator?: Coordinator | null,
  techID?: string | null,
  tech?: Tech | null,
  partnerOrganization?: PartnerOrganization | null,
  requisitions?:  Array<Requisition > | null,
  requisitionUploads?:  Array<S3Object > | null,
  requisitionUploadS3ObjectIDs?: Array< string > | null,
  // Comments about patient info like medical/draw history, disabilities, etc.
  commentsFromCoordinatorAboutPatient?: string | null,
  // Comments about the clinical aspects of the encounter to be passed from scheduler to technician
  commentsFromSchedulerAboutEncounter?: string | null,
  // Comments about the clinical aspects of the encounter to be passed from scheduler to technician
  commentsFromSchedulerToTechAboutEncounter?: string | null,
  // Comments about the clinical aspects of the encounter and patient info not mentioned by the coordinator
  commentsFromTechAboutEncounter?: string | null,
  // Comments from customer support about actions taken on the encounter (e.g., services removed).
  commentsFromCustomerSupportAboutEncounter?: string | null,
  // Comments from the scheduler to the partner about actions taken on the encounter (e.g., services removed).
  commentsFromSchedulerToPartnerAboutEncounter?: string | null,
  covid19Questions?: COVID19Questions | null,
  bloodCollectionDetails?: SpecimenCollectionDetails | null,
  capillaryDrawCollectionDetails?: SpecimenCollectionDetails | null,
  productTypes?: Array< ProductType > | null,
  isMedicare?: boolean | null,
  isMedicareEligible?: boolean | null,
  wasPatientManuallyAdded?: boolean | null,
  serviceOutcomes?:  Array<ServiceOutcome > | null,
  diagnosisCodes?:  Array<Coding > | null,
  // The partner's identifier for this encounter/appointment:
  // - FMI Case number for Foundation,
  // - Accession Number for Guardant,
  // - Job ID for Natera
  partnersEncounterID?: CustomProperty | null,
  documents?:  Array<Document > | null,
  observations?:  Array<Observation > | null,
  procedures?:  Array<Procedure > | null,
  escalation?: Escalation | null,
  questionnaires?:  Array<QuestionnaireEmbedded > | null,
  colorectalCancerScreeningAnswers?: QuestionnaireResponse | null,
  patientEncounterSummaryLastSentAt?: string | null,
  cologuardOrderSuccessfullyPlacedAt?: string | null,
  hasPatientRequestedMailedResults?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type Patient = {
  __typename: "Patient",
  id: string,
  csrPartnerOrganization?: PartnerOrganization | null,
  partnerOrganization?: PartnerOrganization | null,
  location?: Location | null,
  coordinatorID: string,
  medicareCardID?: string | null,
  imageMoverUserID?: string | null,
  externalID?: string | null,
  secondaryExternalID?: string | null,
  specimenLabelID?: string | null,
  labOrganizationPatientIDs?:  Array<LabOrganizationPatientID > | null,
  qtMedicalPatientID?: string | null,
  planName?: string | null,
  diabeticType?: DiabeticType | null,
  authorizedTechIDs?: Array< string > | null,
  isCoordinator: boolean,
  // Phone & email to use in place of the coordinators for messaging. Used primarily for patients whose appointments are booked for them by a partner's customer service representatives.
  phone?: string | null,
  email?: string | null,
  secondaryPhone?: string | null,
  consentToContactForAppointmentBySMS?: Consent | null,
  consentToContactForAppointmentByEmail?: Consent | null,
  name: HumanName,
  caregiverName?: HumanName | null,
  birthdate?: string | null,
  sex?: Sex | null,
  gender?: Gender | null,
  ethnicity?: Ethnicity | null,
  races?: Array< Race > | null,
  passportNumber?: string | null,
  insuranceCardUploadFront?: S3Object | null,
  insuranceCardUploadBack?: S3Object | null,
  insuranceCardUploadFrontS3ObjectID?: string | null,
  insuranceCardUploadBackS3ObjectID?: string | null,
  medicareCardUploadFront?: S3Object | null,
  medicareCardUploadBack?: S3Object | null,
  medicareCardUploadFrontS3ObjectID?: string | null,
  medicareCardUploadBackS3ObjectID?: string | null,
  encounters?: ModelEncounterConnection | null,
  coordinator: Coordinator,
  commentsFromTechsAboutEncounters?:  Array<CommentsFromTechAboutEncounter > | null,
  bloodCollectionDetailsFromEncounters?:  Array<SpecimenCollectionDetailsFromEncounter > | null,
  capillaryDrawCollectionDetailsFromEncounters?:  Array<SpecimenCollectionDetailsFromEncounter > | null,
  referrals?: ModelReferralConnection | null,
  careNavigationEpisodes?: ModelCareNavigationEpisodeConnection | null,
  campaigns?: ModelPatientCampaignStateConnection | null,
  watchlistedAppointmentID?: string | null,
  watchlistedAppointment?: Appointment | null,
  responsiblePractitionerID?: string | null,
  responsiblePractitioner?: Practitioner | null,
  commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
  commentsFromCaregiverAboutResponsiblePractitioner?: string | null,
  consentToContactForMarketingSMS?: Consent | null,
  consentToContactForMarketingEmail?: Consent | null,
  consentToContactForMarketingPhone?: Consent | null,
  suggestedAppointmentLocation?: Location | null,
  suggestedAppointmentLocationAdditionalInformation?: string | null,
  mailingAddress?: Address | null,
  preferredLanguage?: string | null,
  primaryLanguageBCP47?: string | null,
  secondaryLanguagesBCP47?: Array< string > | null,
  // A unique numerical patient reference number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
  patientNumber?: string | null,
  // latest validated gap list data referencing this patient.
  // - latestGapListUploadID: the upload ID of the latest ingestion for tracking purposes
  // - latestGapListRowJSON: the entire row
  // - latestGapListPassthroughJSON: a subset of the row for passing back to the partner with patient data in reports
  latestGapListUploadID?: string | null,
  latestGapListRowJSON?: string | null,
  latestGapListPassthroughJSON?: string | null,
  // A reference to a set of branding material provided for outreach.
  brandCode?: string | null,
  population?: PatientPopulation | null,
  elationID?: string | null,
  consents?:  Array<ConsentForm > | null,
  telecom?: ModelPatientContactPointConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type LabOrganizationPatientID = {
  __typename: "LabOrganizationPatientID",
  labOrganization: LabOrganization,
  externalID: string,
  planNameForLabOrdering: string,
};

export enum LabOrganization {
  AFFORDABLE_PATERNITY = "AFFORDABLE_PATERNITY",
  BIO_REFERENCE = "BIO_REFERENCE",
  CYREX = "CYREX",
  DEMO_LAB = "DEMO_LAB",
  EXACT_SCIENCES = "EXACT_SCIENCES",
  FOUNDATION_MEDICINE = "FOUNDATION_MEDICINE",
  GRAIL = "GRAIL",
  GUARDANT = "GUARDANT",
  INTERMOUNTAIN = "INTERMOUNTAIN",
  INTERMOUNTAIN_CANCER_CENTER = "INTERMOUNTAIN_CANCER_CENTER",
  MUIRLAB = "MUIRLAB",
  KAISER = "KAISER",
  LABCORP = "LABCORP",
  MARSHALL_LABORATORY = "MARSHALL_LABORATORY",
  NATERA = "NATERA",
  NAVERIS = "NAVERIS",
  QUEST = "QUEST",
  PULSE_DEMO = "PULSE_DEMO",
  RUPA_HEALTH = "RUPA_HEALTH",
  SPRINTER_HEALTH = "SPRINTER_HEALTH",
  STANFORD = "STANFORD",
  STANFORD_CHILDRENS_HOSPITAL = "STANFORD_CHILDRENS_HOSPITAL",
  STANFORD_CANCER_CENTER = "STANFORD_CANCER_CENTER",
  STANFORD_RESEARCH = "STANFORD_RESEARCH",
  UCSF_BLOOD_DRAW_LAB = "UCSF_BLOOD_DRAW_LAB",
  WEST_PAC = "WEST_PAC",
  DOTHOUSE_LAB = "DOTHOUSE_LAB",
  CODMAN_LAB = "CODMAN_LAB",
  BMC_LAB = "BMC_LAB",
  ROSLINDALE_LAB = "ROSLINDALE_LAB",
  LETS_GET_CHECKED_LAB = "LETS_GET_CHECKED_LAB",
  OTHER = "OTHER",
  TRAINING_PARTNER_LAB = "TRAINING_PARTNER_LAB",
  UCI_LAB = "UCI_LAB",
}


// A person's diabetic type.
export enum DiabeticType {
  TYPE_1 = "TYPE_1",
  TYPE_2 = "TYPE_2",
}


export type Consent = {
  __typename: "Consent",
  permit: boolean,
  version: number,
};

export type HumanName = {
  __typename: "HumanName",
  use: NameUse,
  family?: string | null,
  given?: Array< string > | null,
  prefix?: HumanNamePrefix | null,
  suffix?: string | null,
};

export enum NameUse {
  USUAL = "USUAL",
  OFFICIAL = "OFFICIAL",
  TEMP = "TEMP",
  NICKNAME = "NICKNAME",
  ANONYMOUS = "ANONYMOUS",
  OLD = "OLD",
  MAIDEN = "MAIDEN",
}


export enum HumanNamePrefix {
  MS = "MS",
  MISS = "MISS",
  MRS = "MRS",
  MR = "MR",
  DR = "DR",
}


// A person's biological (genetic) sex. See https://www.hl7.org/fhir/patient.html#gender for more information.
export enum Sex {
  FEMALE = "FEMALE",
  MALE = "MALE",
  OTHER = "OTHER",
}


// A person's administrative gender. See https://www.hl7.org/fhir/patient.html#gender for more information.
export enum Gender {
  FEMALE = "FEMALE",
  MALE = "MALE",
  OTHER = "OTHER",
}


// A person's ethnicity, as defined by the Office of Management and Budget's Revisions to the Standards for the Classification of Federal Data on Race and Ethnicity. See https://www.govinfo.gov/content/pkg/FR-1997-10-30/pdf/97-28653.pdf for more information.
export enum Ethnicity {
  HISPANIC_OR_LATINO = "HISPANIC_OR_LATINO",
  NOT_HISPANIC_OR_LATINO = "NOT_HISPANIC_OR_LATINO",
  UNKNOWN = "UNKNOWN",
  PREFER_NOT_TO_DISCLOSE = "PREFER_NOT_TO_DISCLOSE",
}


// A person's race, as defined by the Office of Management and Budget's Revisions to the Standards for the Classification of Federal Data on Race and Ethnicity. See https://www.govinfo.gov/content/pkg/FR-1997-10-30/pdf/97-28653.pdf for more information.
export enum Race {
  AMERICAN_INDIAN_OR_ALASKA_NATIVE = "AMERICAN_INDIAN_OR_ALASKA_NATIVE",
  ASIAN = "ASIAN",
  BLACK_OR_AFRICAN_AMERICAN = "BLACK_OR_AFRICAN_AMERICAN",
  NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER = "NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER",
  PREFER_NOT_TO_DISCLOSE = "PREFER_NOT_TO_DISCLOSE",
  SOME_OTHER_RACE = "SOME_OTHER_RACE",
  UNKNOWN = "UNKNOWN",
  WHITE = "WHITE",
}


export type Coordinator = {
  __typename: "Coordinator",
  id: string,
  authorizedTechIDs?: Array< string > | null,
  name?: HumanName | null,
  // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
  isCSR?: boolean | null,
  isActive?: boolean | null,
  managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
  authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
  phone?: string | null,
  email?: string | null,
  consentToContactForAppointmentBySMS?: Consent | null,
  billingCustomerID?: string | null,
  consentToContactForResearch?: Consent | null,
  consentToContactForMarketing?: Consent | null,
  outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
  stripeCustomerID?: string | null,
  patients?: ModelPatientConnection | null,
  isArchived?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export enum ManagePartnerUsersAuthorization {
  ALLOW_MANAGE_PARTNER_USERS = "ALLOW_MANAGE_PARTNER_USERS",
  DISALLOW_MANAGE_PARTNER_USERS = "DISALLOW_MANAGE_PARTNER_USERS",
}


export type ModelPatientConnection = {
  __typename: "ModelPatientConnection",
  items:  Array<Patient | null >,
  nextToken?: string | null,
};

export type CommentsFromTechAboutEncounter = {
  __typename: "CommentsFromTechAboutEncounter",
  encounterID: string,
  appointmentStart: string,
  techName: HumanName,
  comments?: string | null,
};

export type SpecimenCollectionDetailsFromEncounter = {
  __typename: "SpecimenCollectionDetailsFromEncounter",
  encounterID: string,
  appointmentStart: string,
  techName: HumanName,
  specimenCollectionDetails: SpecimenCollectionDetails,
  failureReason?: ServiceFailureReason | null,
};

export type SpecimenCollectionDetails = {
  __typename: "SpecimenCollectionDetails",
  bodySiteLaterality?: Laterality | null,
  bodySiteStructure?: BodyStructure | null,
  deviceType?: SpecimenCollectionDeviceType | null,
  attempts?: number | null,
};

export enum Laterality {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}


export enum BodyStructure {
  EYE = "EYE",
  MEDIAN_CUBITAL = "MEDIAN_CUBITAL",
  CEPHALIC = "CEPHALIC",
  BASILIC = "BASILIC",
  HAND = "HAND",
  WRIST = "WRIST",
  FOREARM = "FOREARM",
  UPPER_ARM = "UPPER_ARM",
  OTHER = "OTHER",
}


export enum SpecimenCollectionDeviceType {
  HYPODERMIC_NEEDLE_19_GAUGE_BUTTERFLY = "HYPODERMIC_NEEDLE_19_GAUGE_BUTTERFLY",
  HYPODERMIC_NEEDLE_21_GAUGE_BUTTERFLY = "HYPODERMIC_NEEDLE_21_GAUGE_BUTTERFLY",
  HYPODERMIC_NEEDLE_21_GAUGE_STRAIGHT = "HYPODERMIC_NEEDLE_21_GAUGE_STRAIGHT",
  HYPODERMIC_NEEDLE_22_GAUGE_STRAIGHT = "HYPODERMIC_NEEDLE_22_GAUGE_STRAIGHT",
  HYPODERMIC_NEEDLE_23_GAUGE_BUTTERFLY = "HYPODERMIC_NEEDLE_23_GAUGE_BUTTERFLY",
  HYPODERMIC_NEEDLE_25_GAUGE_BUTTERFLY = "HYPODERMIC_NEEDLE_25_GAUGE_BUTTERFLY",
  OTHER = "OTHER",
}


export enum ServiceFailureReason {
  MORE_STICKS_REQUIRED = "MORE_STICKS_REQUIRED", // Blood draw failed because two sticks were attempted and failed
  ATTEMPTED_STICKS_FAILED = "ATTEMPTED_STICKS_FAILED", // Blood draw failed because the sprinter attempted the logged number of sticks failed
  NONADHERENCE_TO_ABSTENTION = "NONADHERENCE_TO_ABSTENTION", // Service failed because the patient did not adhere to required abstentions (fasting, vitamin B12 abstention, etc.)
  LAB_DID_NOT_ACCEPT_SPECIMENS = "LAB_DID_NOT_ACCEPT_SPECIMENS", // Service failed because the lab rejected the specimen at dropoff
  LAB_LOST_SPECIMENS = "LAB_LOST_SPECIMENS", // Service failed because the lab lost the specimens after dropoff
  LAB_CLOSED = "LAB_CLOSED", // Service failed because the lab was closed unexpectedly when the Sprinter arrived for dropoff
  INSUFFICIENT_SUPPLIES = "INSUFFICIENT_SUPPLIES", // Service failed because the Sprinter did not bring the supplies necessary to complete the service
  PATIENT_UNABLE_TO_PROVIDE_SAMPLE = "PATIENT_UNABLE_TO_PROVIDE_SAMPLE", // Service failed because the specimen sample (urine, blood) was not ready for collection at the time of the appointment
  INCOMPATIBLE_EQUIPMENT = "INCOMPATIBLE_EQUIPMENT", // Service failed because the equipment did not fit the patient properly (i.e. BP cuff too small, ECG lead too big)
  DEVICE_FAILURE = "DEVICE_FAILURE", // Service failed because the device malfunctioned during the appointment, or electronic results failed to transmit
  UNABLE_TO_CAPTURE_IMAGE = "UNABLE_TO_CAPTURE_IMAGE", // DRE failed because the device was unable to capture an image of the patient's retina (patient anatomic issues, machine placement issues, etc.)
  ESCALATION = "ESCALATION", // Service failed because the critical escalation protocol was engaged
  PATIENT_REFUSED_SERVICE = "PATIENT_REFUSED_SERVICE", // Service failed because the patient refused the service
  UNABLE_TO_REACH_PATIENT = "UNABLE_TO_REACH_PATIENT", // Service failed because the patient was unable to be reached
  PATIENT_RECEIVED_SERVICE_ELSEWHERE = "PATIENT_RECEIVED_SERVICE_ELSEWHERE", // Service failed because the patient received service elsewhere
  PATIENT_COLLECTED_SPECIMEN_INCORRECTLY = "PATIENT_COLLECTED_SPECIMEN_INCORRECTLY", // Service failed because the Sprinter arrived and the kit was completed incorrectly
  PATIENT_DID_NOT_QUALIFY_FOR_SERVICE = "PATIENT_DID_NOT_QUALIFY_FOR_SERVICE", // The patient has comorbidity or conditions that disqualify them from the service.
  KIT_EXPIRED = "KIT_EXPIRED", // Service failed because the kit was expired
  PATIENT_NEVER_RECEIVED_KIT = "PATIENT_NEVER_RECEIVED_KIT", // Service failed because the Sprinter arrived and the patient did not have a kit
  PATIENT_NO_SHOW = "PATIENT_NO_SHOW", // The patient was not at the location of the appointment when the Sprinter arrived
  PATIENT_NOT_AWARE_OF_APPOINTMENT = "PATIENT_NOT_AWARE_OF_APPOINTMENT", // The Sprinter arrived and the patient did not know that they had an appointment scheduled (patient home)
  SPRINTER_UNABLE_TO_GET_TO_APPOINTMENT = "SPRINTER_UNABLE_TO_GET_TO_APPOINTMENT", // Sprinter unable to make the appointment due to illness, car issues, traffic, etc.
  INSUFFICIENT_APPOINTMENT_INFORMATION = "INSUFFICIENT_APPOINTMENT_INFORMATION", // Lack of information required for the appointment (wrong requisition uploaded, patient name misspelled, etc.)
  CONNECTIVITY_ISSUES = "CONNECTIVITY_ISSUES", // Sprinter phone or tablet had connectivity issues during the appointment.
  HAZARDOUS_ENVIRONMENT = "HAZARDOUS_ENVIRONMENT", // The location of the appointment is unsafe for the Sprinter to conduct services.
  NO_AVAILABILITY_IN_AREA = "NO_AVAILABILITY_IN_AREA", // We are unable to locate or otherwise supply a valid provider in the desired location of the appointment.
  OTHER = "OTHER", // Uncatalogued reason for failure which will be captured by the Scheduler via free text field.
  SPRINTER_UNABLE_TO_CAPTURE_IMAGE = "SPRINTER_UNABLE_TO_CAPTURE_IMAGE", // Sprinter unable to capture image of the patient's eye
}


export type ModelReferralConnection = {
  __typename: "ModelReferralConnection",
  items:  Array<Referral | null >,
  nextToken?: string | null,
};

export type Referral = {
  __typename: "Referral",
  id: string,
  appointments?: ModelAppointmentConnection | null,
  patientID: string,
  patient: Patient,
  partnerOrganization: PartnerOrganization,
  requisitions?:  Array<Requisition > | null,
  requisitionUploads?:  Array<S3Object > | null,
  requisitionUploadS3ObjectIDs?: Array< string > | null,
  serviceTypes: Array< ServiceType >,
  diagnosisCodes?:  Array<Coding > | null,
  isBooked?: boolean | null,
  isExpired?: boolean | null,
  outreachOutcomesPerServiceRequest?:  Array<ServiceRequestOutreachOutcome > | null,
  ttl?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelAppointmentConnection = {
  __typename: "ModelAppointmentConnection",
  items:  Array<Appointment | null >,
  nextToken?: string | null,
};

export type Requisition = {
  __typename: "Requisition",
  id: string,
  labOrganization?: LabOrganization | null,
  serviceRequests?:  Array<ServiceRequest > | null,
  // Related type information for services requested in the service requests.
  // In a future state one should be able to point to this collection for reads instead of the serviceRequests
  // 
  // This currently must be a custom resolver as the Requisition is a "type" which cannot hold hasMany
  // references to models.
  serviceRequestTypes?:  Array<ServiceRequestType > | null,
  // A unique numerical patient requisition number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
  requisitionNumber?: string | null,
};

export type ServiceRequest = {
  __typename: "ServiceRequest",
  id: string,
  externalID: string,
  isNewServiceRequestEntry?: boolean | null,
  displayName?: string | null,
};

export type ServiceRequestType = {
  __typename: "ServiceRequestType",
  id: string,
  cptCodes: Array< string | null >,
  externalID: string,
  displayName: string,
  // Alternate names for service requests, to be used in service request lookup
  synonyms?: Array< string | null > | null,
  labOrganization: LabOrganization,
  container?: string | null,
  serviceRequestTypeContainerID?: string | null,
  serviceRequestTypeContainer?: ServiceRequestTypeContainer | null,
  specimen?: string | null,
  preferredSpecimen?: string | null,
  preferredVolumeDescription?: string | null,
  preferredVolumeMilliliters?: number | null,
  minimumVolume?: string | null,
  minimumVolumeAmount?: number | null,
  alternateSpecimen?: string | null,
  transportInstructions?: string | null,
  collectionInstructions?: string | null,
  collectionInstructionsVideoURL?: string | null,
  rejectionCriteria?: string | null,
  specimenStability?: string | null,
  patientPreparation?: string | null,
  resultAvailability?: string | null,
  serviceTypes?: Array< ServiceType > | null,
  containerSpecimenVolumes?:  Array<SpecimenContainerSpecimenVolumes > | null,
  abstentions?:  Array<Abstention > | null,
  customKit?: ServiceRequestCustomKit | null,
  collectionTimeRules?:  Array<TimeRangeRule > | null,
  requiresBinaryPatientSex?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type ServiceRequestTypeContainer = {
  __typename: "ServiceRequestTypeContainer",
  id: string,
  containerType: string,
  alternativeContainerTypes?: Array< string > | null,
  substituteContainerTypesForCalculations?: Array< string > | null,
  containerCategory: string,
  capacity: number,
  createdAt: string,
  updatedAt: string,
};

export enum ServiceType {
  BLOOD = "BLOOD",
  CAPILLARY_BLOOD_DRAW = "CAPILLARY_BLOOD_DRAW",
  CARDIAC_AUSCULTATION = "CARDIAC_AUSCULTATION",
  DIABETIC_FOOT_SCREENING = "DIABETIC_FOOT_SCREENING",
  DIABETIC_RETINAL_SCAN = "DIABETIC_RETINAL_SCAN",
  ECG = "ECG",
  FIT_KIT_PICKUP = "FIT_KIT_PICKUP",
  RAPID_COVID_TEST = "RAPID_COVID_TEST",
  URINE = "URINE",
  VITALS = "VITALS",
  CARE_NAVIGATION = "CARE_NAVIGATION",
  KIT_DELIVERY_AND_EDUCATION = "KIT_DELIVERY_AND_EDUCATION",
  KIT_DELIVERY = "KIT_DELIVERY",
  XRAY = "XRAY",
  MISCELLANEOUS_SPECIMEN = "MISCELLANEOUS_SPECIMEN",
  RACE_AND_ETHNICITY = "RACE_AND_ETHNICITY",
  SDOH_QUESTIONNAIRE = "SDOH_QUESTIONNAIRE", // SDOH = Social Determinants of Health
  SDOH_CARE_NAVIGATION_RESOURCE_GUIDANCE = "SDOH_CARE_NAVIGATION_RESOURCE_GUIDANCE",
  DEXA_SCAN_CARE_NAVIGATION_BOOKING = "DEXA_SCAN_CARE_NAVIGATION_BOOKING",
  DEXA_SCAN_CARE_NAVIGATION_CONFIRMATION = "DEXA_SCAN_CARE_NAVIGATION_CONFIRMATION",
  MAMMOGRAM_CARE_NAVIGATION_BOOKING = "MAMMOGRAM_CARE_NAVIGATION_BOOKING",
  MAMMOGRAM_CARE_NAVIGATION_CONFIRMATION = "MAMMOGRAM_CARE_NAVIGATION_CONFIRMATION",
  PCP_VISIT_CARE_NAVIGATION_BOOKING = "PCP_VISIT_CARE_NAVIGATION_BOOKING", // PCP = Primary Care Provider
  PCP_VISIT_CARE_NAVIGATION_CONFIRMATION = "PCP_VISIT_CARE_NAVIGATION_CONFIRMATION", // PCP = Primary Care Provider
  HEIGHT_AND_WEIGHT_COLLECTION = "HEIGHT_AND_WEIGHT_COLLECTION",
  COLOGUARD_KIT_ORDERING = "COLOGUARD_KIT_ORDERING",
  COLOGUARD_KIT_EDUCATION = "COLOGUARD_KIT_EDUCATION",
  ANNUAL_WELLNESS_VISIT_PROVIDER_CALL = "ANNUAL_WELLNESS_VISIT_PROVIDER_CALL",
  PAIN_SCREENING = "PAIN_SCREENING",
  BONE_DENSITY_SCAN = "BONE_DENSITY_SCAN",
  TOPICAL_FLUORIDE = "TOPICAL_FLUORIDE",
  STATIN_ADHERENCE_ASSESSMENT = "STATIN_ADHERENCE_ASSESSMENT",
}


export type SpecimenContainerSpecimenVolumes = {
  __typename: "SpecimenContainerSpecimenVolumes",
  // The ID of the container assigned to a serviceRequest, only applicable for non-combinable serviceRequests
  containerOverrideID?: string | null,
  // The type of tube e.g., SST, LAV EDTA, Urine Cup
  containerCategory?: SpecimenContainerCategory | null,
  // The minimum volume needed for a test
  minimumMilliliters?: number | null,
  // The preferred volume for a test in case we do not have minumum volume set above.
  preferredMilliliters?: number | null,
  // Whether it's acceptable to combine the specimens for this test and others into a single container during container calculations.
  // For example, if true, then blood for two tests requiring 2 mL of whole blood each can be drawn into a single 5 mL tube rather than two separate 3 mL tubes.
  isCombinable: boolean,
  // minimum container count needed for the specimen
  minimumContainerCount?: number | null,
  specimenTestTypes?: Array< SpecimenTestType | null > | null,
};

export enum SpecimenContainerCategory {
  BLACK_AND_YELLOW_NMR_LIPO_TUBE = "BLACK_AND_YELLOW_NMR_LIPO_TUBE",
  DISCARD_TUBE = "DISCARD_TUBE",
  GOLD_VACUETTE_WITH_GEL = "GOLD_VACUETTE_WITH_GEL",
  GREEN_LITHIUM_HEPARIN_TUBE_NO_GEL = "GREEN_LITHIUM_HEPARIN_TUBE_NO_GEL",
  GREEN_LITHIUM_HEPARIN_TUBE_WITH_GEL = "GREEN_LITHIUM_HEPARIN_TUBE_WITH_GEL",
  GREEN_LITHIUM_HEPARIN_TUBE_WITH_GEL_VACUETTE = "GREEN_LITHIUM_HEPARIN_TUBE_WITH_GEL_VACUETTE",
  GREEN_SODIUM_HEPARIN_TUBE = "GREEN_SODIUM_HEPARIN_TUBE",
  GREEN_VACUETTE_NO_GEL = "GREEN_VACUETTE_NO_GEL",
  GREEN_VACUETTE_WITH_GEL = "GREEN_VACUETTE_WITH_GEL",
  GRAY_GLUCOSE_TUBE = "GRAY_GLUCOSE_TUBE",
  LAVENDER_EDTA_TUBE = "LAVENDER_EDTA_TUBE",
  LAVENDER_VACUETTE = "LAVENDER_VACUETTE",
  LIGHT_BLUE_AND_BLACK_VACUETTE = "LIGHT_BLUE_AND_BLACK_VACUETTE",
  LIGHT_BLUE_AND_WHITE_VACUETTE = "LIGHT_BLUE_AND_WHITE_VACUETTE",
  LIGHT_BLUE_CITRATE_TUBE = "LIGHT_BLUE_CITRATE_TUBE",
  PEARL_TUBE = "PEARL_TUBE",
  PINK_EDTA_TUBE = "PINK_EDTA_TUBE",
  PINK_VACUETTE = "PINK_VACUETTE",
  POLYMEDCO_KIT = "POLYMEDCO_KIT",
  POLYMEDCO_OC_AUTO_SAMPLING_BOTTLE = "POLYMEDCO_OC_AUTO_SAMPLING_BOTTLE",
  PROTEASE_INHIBITOR_TUBE = "PROTEASE_INHIBITOR_TUBE",
  INSURE_FOBT_TEST_CARD = "INSURE_FOBT_TEST_CARD",
  RED_VACUETTE_WITH_GEL = "RED_VACUETTE_WITH_GEL",
  ROYAL_BLUE_TUBE = "ROYAL_BLUE_TUBE",
  ROYAL_BLUE_VACUETTE = "ROYAL_BLUE_VACUETTE",
  SERUM_SEPARATING_TUBE = "SERUM_SEPARATING_TUBE",
  SERUM_SEPARATING_TUBE_NO_GEL = "SERUM_SEPARATING_TUBE_NO_GEL",
  TAN_TUBE = "TAN_TUBE",
  TUBERCULOSIS_TUBE = "TUBERCULOSIS_TUBE",
  TIGER_SST_TUBE = "TIGER_SST_TUBE",
  URINE_CUP = "URINE_CUP",
  YELLOW_ACDA_TUBE = "YELLOW_ACDA_TUBE",
  YELLOW_ACDB_TUBE = "YELLOW_ACDB_TUBE",
  CPT_TUBE = "CPT_TUBE",
  LAVENDER_EDTA_CAPILLARY_TUBE = "LAVENDER_EDTA_CAPILLARY_TUBE",
}


export enum SpecimenTestType {
  THYROID = "THYROID",
}


export type Abstention = {
  __typename: "Abstention",
  abstentionType: AbstentionType,
  preferredDurationMinutes: number,
  requiredDurationMinutes?: number | null,
};

export enum AbstentionType {
  FOOD = "FOOD",
  NICOTINE = "NICOTINE",
  EXERCISE = "EXERCISE",
  CAFFEINE = "CAFFEINE",
  BIOTIN = "BIOTIN",
  URINATION = "URINATION",
  INTRAVENOUS_CHEMOTHERAPY = "INTRAVENOUS_CHEMOTHERAPY",
}


export type ServiceRequestCustomKit = {
  __typename: "ServiceRequestCustomKit",
  photoURL: string,
  barcodePhotoURL?: string | null,
  recipient: CustomKitRecipient,
  // requires test requisition form (TRF) completion
  requiresTRFCompletion: boolean,
  // requires benefit correction form (BCF) completion
  requiresBCFCompletion: boolean,
};

export enum CustomKitRecipient {
  PATIENT = "PATIENT",
  SPRINTER = "SPRINTER",
}


export type TimeRangeRule = {
  __typename: "TimeRangeRule",
  // The unique ID for the time range.
  id: string,
  // Whether the time range represents an addition or subtraction when considering multiple ranges.
  inclusion: TimeRangeRuleInclusion,
  // The time of day each recurrence starts (inclusive).
  start: string,
  // The time of day each recurrence ends (exclusive). Use '24:00:00' for the end of the day.
  end: string,
  // The first date that the recurrence is valid (inclusive).
  recurrenceStart: string,
  // The last date that the recurrence is valid (inclusive). If not set, the range extends indefinitely.
  recurrenceEnd?: string | null,
  // The number of periods between each recurrence. For example, with a multiple of N and period of DAY, the time range will recur every N days.
  recurrenceMultiple: number,
  // The unit of recurrence. For example, with a period of WEEK and multiple of 2, the time range will recur every other week.
  recurrencePeriod: TimeRangeRulePeriod,
  // An optional reason for the time range rule, used with time-off unavailability rules (lunch breaks, PTO, etc.)
  unavailabilityReason?: TimeRangeRuleUnavailabilityReason | null,
  // Additional notes about the time range rule.
  notes?: string | null,
  // The date and time that the rule was last updated.
  updatedAt?: string | null,
  // The user who last updated the rule.
  updatedBy?: string | null,
};

export enum TimeRangeRuleInclusion {
  INCLUDE = "INCLUDE",
  EXCLUDE = "EXCLUDE",
}


export enum TimeRangeRulePeriod {
  DAY = "DAY",
  WEEK = "WEEK",
}


export enum TimeRangeRuleUnavailabilityReason {
  EXCHANGE_SUPPLIES = "EXCHANGE_SUPPLIES",
  LUNCH = "LUNCH",
  ABSENCE = "ABSENCE",
  OFFICE = "OFFICE",
  HOLIDAY = "HOLIDAY",
  PTO = "PTO",
  SICK = "SICK",
  SHADOWING = "SHADOWING",
  TRAINING = "TRAINING",
  CAR_TROUBLE = "CAR_TROUBLE",
  OTHER = "OTHER",
}


export type Coding = {
  __typename: "Coding",
  system: string,
  code: string,
  displayName?: string | null,
};

export type ServiceRequestOutreachOutcome = {
  __typename: "ServiceRequestOutreachOutcome",
  serviceRequestExternalID: string,
  productType?: ProductType | null,
  serviceTypes?: Array< ServiceType > | null,
  labOrganization: LabOrganization,
  outcome: string,
  notes?: string | null,
};

// Product types are used for similar billing of service types.
export enum ProductType {
  CARDIAC_AUSCULTATION = "CARDIAC_AUSCULTATION",
  DIABETIC_FOOT_SCREENING = "DIABETIC_FOOT_SCREENING",
  FIT_KIT_PICKUP = "FIT_KIT_PICKUP",
  DIABETIC_RETINAL_SCAN = "DIABETIC_RETINAL_SCAN",
  ECG = "ECG",
  LAB_TEST = "LAB_TEST",
  RAPID_COVID_TEST = "RAPID_COVID_TEST",
  VITALS = "VITALS",
  CARE_NAVIGATION_DROP_IN = "CARE_NAVIGATION_DROP_IN",
  KIT_DELIVERY_AND_EDUCATION = "KIT_DELIVERY_AND_EDUCATION",
  KIT_DELIVERY = "KIT_DELIVERY",
  XRAY = "XRAY",
  QUESTIONNAIRES = "QUESTIONNAIRES",
  SDOH_CARE_NAVIGATION_RESOURCE_GUIDANCE = "SDOH_CARE_NAVIGATION_RESOURCE_GUIDANCE",
  DEXA_SCAN_CARE_NAVIGATION_BOOKING = "DEXA_SCAN_CARE_NAVIGATION_BOOKING",
  DEXA_SCAN_CARE_NAVIGATION_CONFIRMATION = "DEXA_SCAN_CARE_NAVIGATION_CONFIRMATION",
  MAMMOGRAM_CARE_NAVIGATION_BOOKING = "MAMMOGRAM_CARE_NAVIGATION_BOOKING",
  MAMMOGRAM_CARE_NAVIGATION_CONFIRMATION = "MAMMOGRAM_CARE_NAVIGATION_CONFIRMATION",
  PCP_VISIT_CARE_NAVIGATION_BOOKING = "PCP_VISIT_CARE_NAVIGATION_BOOKING", // PCP = Primary Care Provider
  PCP_VISIT_CARE_NAVIGATION_CONFIRMATION = "PCP_VISIT_CARE_NAVIGATION_CONFIRMATION", // PCP = Primary Care Provider
  COLOGUARD_KIT_ORDERING = "COLOGUARD_KIT_ORDERING",
  COLOGUARD_KIT_EDUCATION = "COLOGUARD_KIT_EDUCATION",
  HEIGHT_AND_WEIGHT_COLLECTION = "HEIGHT_AND_WEIGHT_COLLECTION", // Used for annual wellness visits
  ANNUAL_WELLNESS_VISIT_PROVIDER_CALL = "ANNUAL_WELLNESS_VISIT_PROVIDER_CALL",
  PAIN_SCREENING = "PAIN_SCREENING",
  BONE_DENSITY_SCAN = "BONE_DENSITY_SCAN",
  TOPICAL_FLUORIDE = "TOPICAL_FLUORIDE",
  STATIN_ADHERENCE_ASSESSMENT = "STATIN_ADHERENCE_ASSESSMENT",
}


export type ModelCareNavigationEpisodeConnection = {
  __typename: "ModelCareNavigationEpisodeConnection",
  items:  Array<CareNavigationEpisode | null >,
  nextToken?: string | null,
};

export type CareNavigationEpisode = {
  __typename: "CareNavigationEpisode",
  closedAt?: string | null,
  careNavigationGroup: CareNavigationGroup,
  patientID: string,
  patient: Patient,
  partnerOrganization: PartnerOrganization,
  referralID: string,
  referral: Referral,
  zendeskTicketID?: number | null,
  serviceOutcomes?:  Array<CareNavigationEpisodeServiceOutcome > | null,
  documents?:  Array<Document > | null,
  observations?:  Array<Observation > | null,
  onboardingQuestionsAndAnswers?:  Array<OnboardingQuestionAnswerPair > | null,
  createdAt: string,
  id: string,
  updatedAt: string,
};

export enum CareNavigationGroup {
  DEXA_SCAN = "DEXA_SCAN",
  MAMMOGRAM = "MAMMOGRAM",
  PCP_VISIT = "PCP_VISIT",
}


export type CareNavigationEpisodeServiceOutcome = {
  __typename: "CareNavigationEpisodeServiceOutcome",
  id: string,
  failureReason?: ServiceFailureReason | null,
  failureFault?: ServiceFailureFault | null,
  isRescheduleRequired?: boolean | null,
  serviceType: ServiceType,
  status?: ServiceStatus | null,
  commentsFromCareNavigatorAboutServiceFailure?: string | null,
  statusChangedAt?: string | null,
  serviceDetailJSON: string,
};

export enum ServiceFailureFault {
  SPRINTER_HEALTH = "SPRINTER_HEALTH",
  PATIENT = "PATIENT",
  PARTNER = "PARTNER",
  REFERRED_PROVIDER = "REFERRED_PROVIDER",
  LAB = "LAB",
  NO_FAULT = "NO_FAULT",
}


export enum ServiceStatus {
  SUCCESSFUL = "SUCCESSFUL",
  FAILED = "FAILED",
}


export type Document = {
  __typename: "Document",
  type?: DocumentType | null,
  url?: string | null,
  format?: DocumentFormat | null,
  s3Object?: S3Object | null,
  s3ObjectID?: string | null,
  uploadedAt?: number | null,
  // stringified JSON object as a set of additional data we want to store about documents (requisition number, lab report status, etc.)
  metadataJSON?: string | null,
};

export enum DocumentType {
  DIABETIC_RETINAL_SCAN_EXAM_REPORT = "DIABETIC_RETINAL_SCAN_EXAM_REPORT",
  OSTEOPOROSIS_SCREENING_REPORT = "OSTEOPOROSIS_SCREENING_REPORT",
  MAMMOGRAM_REPORT = "MAMMOGRAM_REPORT",
  LAB_RESULT = "LAB_RESULT",
  PATIENT_ENCOUNTER_SUMMARY = "PATIENT_ENCOUNTER_SUMMARY",
  CARE_NAVIGATION_EPISODE_SUMMARY = "CARE_NAVIGATION_EPISODE_SUMMARY",
  SDOH_RESOURCES = "SDOH_RESOURCES",
  ELATION_BILLING_REPORT = "ELATION_BILLING_REPORT",
  PATIENT_INFORMED_CONSENT = "PATIENT_INFORMED_CONSENT",
  PATIENT_PRIVACY_CONSENT = "PATIENT_PRIVACY_CONSENT",
}


export enum DocumentFormat {
  PDF = "PDF",
  HL7 = "HL7",
  CSV = "CSV",
  XLSX = "XLSX",
  GOOGLE_SPREADSHEET = "GOOGLE_SPREADSHEET",
}


export type Observation = {
  __typename: "Observation",
  id: string,
  basedOnServiceRequestType?: ObservationPartialServiceRequestType | null,
  identifiers?:  Array<ObservationIdentifier > | null,
  // Per FHIR specifications, we should only have value or valueCoding
  value?: string | null,
  valueCoding?:  Array<Coding > | null,
  units?: string | null,
  referenceRange?: string | null,
  category: ObservationCategory,
  performerType: ObservationPerformerType,
  performerIdentifier?: string | null,
  observedAt?: string | null,
  bodySiteLaterality?: Laterality | null,
  bodySiteStructure?: BodyStructure | null,
  notes?: string | null,
  // Also called Abnormal Flag. A categorical assessment, providing a rough qualitative interpretation of the observation value.
  // http://hl7.org/fhir/valueset-observation-interpretation.html
  interpretationCode?: ObservationInterpretationCode | null,
  source?: ObservationSource | null,
};

export type ObservationPartialServiceRequestType = {
  __typename: "ObservationPartialServiceRequestType",
  id?: string | null,
  externalID: string,
  labOrganization: LabOrganization,
};

export type ObservationIdentifier = {
  __typename: "ObservationIdentifier",
  system: ObservationIdentifierSystem,
  code: string,
  displayName?: string | null,
};

export enum ObservationIdentifierSystem {
  VENDOR_SPECIFIC = "VENDOR_SPECIFIC",
  LOINC = "LOINC",
}


export enum ObservationCategory {
  SOCIAL_HISTORY = "SOCIAL_HISTORY",
  VITAL_SIGNS = "VITAL_SIGNS",
  IMAGING = "IMAGING",
  LABORATORY = "LABORATORY",
  PROCEDURE = "PROCEDURE",
  SURVEY = "SURVEY",
  EXAM = "EXAM",
  THERAPY = "THERAPY",
  ACTIVITY = "ACTIVITY",
}


export enum ObservationPerformerType {
  TECH = "TECH",
  LAB = "LAB",
}


export enum ObservationInterpretationCode {
  CARRIER = "CARRIER",
  BETTER = "BETTER",
  SIGNIFICANT_CHANGE_DOWN = "SIGNIFICANT_CHANGE_DOWN",
  SIGNIFICANT_CHANGE_UP = "SIGNIFICANT_CHANGE_UP",
  WORSE = "WORSE",
  OFF_SCALE_LOW = "OFF_SCALE_LOW",
  OFF_SCALE_HIGH = "OFF_SCALE_HIGH",
  INSUFFICIENT_EVIDENCE = "INSUFFICIENT_EVIDENCE",
  ABNORMAL = "ABNORMAL",
  CRITICAL_ABNORMAL = "CRITICAL_ABNORMAL",
  CRITICAL_HIGH = "CRITICAL_HIGH",
  CRITICAL_LOW = "CRITICAL_LOW",
  HIGH = "HIGH",
  SIGNIFICANTLY_HIGH = "SIGNIFICANTLY_HIGH",
  LOW = "LOW",
  SIGNIFICANTLY_LOW = "SIGNIFICANTLY_LOW",
  NORMAL = "NORMAL",
  INTERMEDIATE = "INTERMEDIATE",
  MODERATELY_SUSCEPTIBLE = "MODERATELY_SUSCEPTIBLE",
  NO_CLSI_DEFINED_BREAKPOINT = "NO_CLSI_DEFINED_BREAKPOINT",
  NON_SUSCEPTIBLE = "NON_SUSCEPTIBLE",
  RESISTANT = "RESISTANT",
  SYNERGY_RESISTANT = "SYNERGY_RESISTANT",
  SUSCEPTIBLE = "SUSCEPTIBLE",
  SUSCEPTIBLE_DOSE_DEPENDENT = "SUSCEPTIBLE_DOSE_DEPENDENT",
  SYNERGY_SUSCEPTIBLE = "SYNERGY_SUSCEPTIBLE",
  VERY_SUSCEPTIBLE = "VERY_SUSCEPTIBLE",
  OUTSIDE_THRESHOLD = "OUTSIDE_THRESHOLD",
  ABOVE_HIGH_THRESHOLD = "ABOVE_HIGH_THRESHOLD",
  BELOW_LOW_THRESHOLD = "BELOW_LOW_THRESHOLD",
  HOLD_FOR_MEDICAL_REVIEW = "HOLD_FOR_MEDICAL_REVIEW",
  INDETERMINATE = "INDETERMINATE",
  EQUIVOCAL = "EQUIVOCAL",
  NEGATIVE = "NEGATIVE",
  NOT_DETECTED = "NOT_DETECTED",
  POSITIVE = "POSITIVE",
  DETECTED = "DETECTED",
  EXPECTED = "EXPECTED",
  UNEXPECTED = "UNEXPECTED",
  NON_REACTIVE = "NON_REACTIVE",
  REACTIVE = "REACTIVE",
}


export enum ObservationSource {
  CHANGE_HEALTHCARE = "CHANGE_HEALTHCARE",
  MANUAL_DATA_IMPORT = "MANUAL_DATA_IMPORT",
}


export type OnboardingQuestionAnswerPair = {
  __typename: "OnboardingQuestionAnswerPair",
  key: string,
  question: string,
  answer: string,
};

export type ModelPatientCampaignStateConnection = {
  __typename: "ModelPatientCampaignStateConnection",
  items:  Array<PatientCampaignState | null >,
  nextToken?: string | null,
};

export type PatientCampaignState = {
  __typename: "PatientCampaignState",
  id: string,
  patientID: string,
  patient: Patient,
  name: PatientCampaignStateName,
  campaignName: CampaignName,
  eligibilityStart: string,
  eligibilityEnd: string,
  cohort?: number | null,
  contextJSON: string,
  enteredAt: number,
  partnerOrganization: PartnerOrganization,
  previousStateName?: PatientCampaignStateName | null,
  reasonForTransitionToTerminalState?: ReasonForTransitionToTerminalState | null,
  createdAt: string,
  updatedAt: string,
};

export enum PatientCampaignStateName {
  ELIGIBLE = "ELIGIBLE",
  READY_FOR_OUTREACH = "READY_FOR_OUTREACH",
  FIRST_EMAIL_SENT = "FIRST_EMAIL_SENT",
  FIRST_EMAIL_NOT_SENT = "FIRST_EMAIL_NOT_SENT",
  FIRST_SMS_SENT = "FIRST_SMS_SENT",
  FIRST_SMS_NOT_SENT = "FIRST_SMS_NOT_SENT",
  FIRST_PHONE_QUEUED = "FIRST_PHONE_QUEUED",
  FIRST_PHONE_CALLED = "FIRST_PHONE_CALLED",
  SECOND_PHONE_QUEUED = "SECOND_PHONE_QUEUED",
  SECOND_PHONE_CALLED = "SECOND_PHONE_CALLED",
  THIRD_PHONE_QUEUED = "THIRD_PHONE_QUEUED",
  THIRD_PHONE_CALLED = "THIRD_PHONE_CALLED",
  FOURTH_PHONE_QUEUED = "FOURTH_PHONE_QUEUED",
  FOURTH_PHONE_CALLED = "FOURTH_PHONE_CALLED",
  DELEGATED_TO_CUSTOMER_SUPPORT = "DELEGATED_TO_CUSTOMER_SUPPORT",
  STALE = "STALE",
  APPOINTMENT_BOOKED = "APPOINTMENT_BOOKED",
  COMPLETED_APPOINTMENT = "COMPLETED_APPOINTMENT",
  INELIGIBLE_FOR_TRICKLE = "INELIGIBLE_FOR_TRICKLE",
  DETERMINED_INELIGIBLE_AFTER_TRICKLE = "DETERMINED_INELIGIBLE_AFTER_TRICKLE",
  DECLINED_SERVICES = "DECLINED_SERVICES",
  CALLBACK_PHONE_SCHEDULED = "CALLBACK_PHONE_SCHEDULED",
  CALLBACK_PHONE_QUEUED = "CALLBACK_PHONE_QUEUED",
  CALLBACK_PHONE_CALLED = "CALLBACK_PHONE_CALLED",
  STALE_READY_FOR_OUTREACH = "STALE_READY_FOR_OUTREACH",
  STALE_FIRST_EMAIL_SENT = "STALE_FIRST_EMAIL_SENT",
  STALE_FIRST_EMAIL_NOT_SENT = "STALE_FIRST_EMAIL_NOT_SENT",
  STALE_SECOND_EMAIL_SENT = "STALE_SECOND_EMAIL_SENT",
  STALE_SECOND_EMAIL_NOT_SENT = "STALE_SECOND_EMAIL_NOT_SENT",
  STALE_THIRD_EMAIL_SENT = "STALE_THIRD_EMAIL_SENT",
  STALE_THIRD_EMAIL_NOT_SENT = "STALE_THIRD_EMAIL_NOT_SENT",
  STALE_FIRST_SMS_SENT = "STALE_FIRST_SMS_SENT",
  STALE_FIRST_SMS_NOT_SENT = "STALE_FIRST_SMS_NOT_SENT",
  STALE_FIRST_PHONE_QUEUED = "STALE_FIRST_PHONE_QUEUED",
  STALE_FIRST_PHONE_CALLED = "STALE_FIRST_PHONE_CALLED",
  STALE_SECOND_PHONE_QUEUED = "STALE_SECOND_PHONE_QUEUED",
  STALE_SECOND_PHONE_CALLED = "STALE_SECOND_PHONE_CALLED",
  STALE_THIRD_PHONE_QUEUED = "STALE_THIRD_PHONE_QUEUED",
  STALE_THIRD_PHONE_CALLED = "STALE_THIRD_PHONE_CALLED",
  STALE_FOURTH_PHONE_QUEUED = "STALE_FOURTH_PHONE_QUEUED",
  STALE_FOURTH_PHONE_CALLED = "STALE_FOURTH_PHONE_CALLED",
  STALE_2 = "STALE_2",
  STALE_2_READY_FOR_OUTREACH = "STALE_2_READY_FOR_OUTREACH",
  STALE_2_FIRST_EMAIL_SENT = "STALE_2_FIRST_EMAIL_SENT",
  STALE_2_FIRST_EMAIL_NOT_SENT = "STALE_2_FIRST_EMAIL_NOT_SENT",
  STALE_2_FIRST_SMS_SENT = "STALE_2_FIRST_SMS_SENT",
  STALE_2_FIRST_SMS_NOT_SENT = "STALE_2_FIRST_SMS_NOT_SENT",
  STALE_2_FIRST_PHONE_QUEUED = "STALE_2_FIRST_PHONE_QUEUED",
  STALE_2_FIRST_PHONE_CALLED = "STALE_2_FIRST_PHONE_CALLED",
  STALE_2_SECOND_PHONE_QUEUED = "STALE_2_SECOND_PHONE_QUEUED",
  STALE_2_SECOND_PHONE_CALLED = "STALE_2_SECOND_PHONE_CALLED",
  STALE_2_THIRD_PHONE_QUEUED = "STALE_2_THIRD_PHONE_QUEUED",
  STALE_2_THIRD_PHONE_CALLED = "STALE_2_THIRD_PHONE_CALLED",
  STALE_2_FOURTH_PHONE_QUEUED = "STALE_2_FOURTH_PHONE_QUEUED",
  STALE_2_FOURTH_PHONE_CALLED = "STALE_2_FOURTH_PHONE_CALLED",
  STALE_3 = "STALE_3",
  CAMPAIGN_TERMINATED = "CAMPAIGN_TERMINATED",
  PAUSED = "PAUSED",
}


export enum CampaignName {
  OSCARCARE_VPC_FLORIDA_TELEPHONIC_ONLY_INITIAL_ENGAGEMENT_06_12_2023 = "OSCARCARE_VPC_FLORIDA_TELEPHONIC_ONLY_INITIAL_ENGAGEMENT_06_12_2023",
  OSCARCARE_VPC_FLORIDA_DIGITAL_ONLY_INITIAL_ENGAGEMENT_2023 = "OSCARCARE_VPC_FLORIDA_DIGITAL_ONLY_INITIAL_ENGAGEMENT_2023",
  ALIGNMENTSTARS_MEDICARE_CALIFORNIA_INITIAL_ENGAGEMENT_07_07_2023 = "ALIGNMENTSTARS_MEDICARE_CALIFORNIA_INITIAL_ENGAGEMENT_07_07_2023",
  OSCARHEALTH_IFP_CALIFORNIA_INITIAL_ENGAGEMENT_07_20_2023 = "OSCARHEALTH_IFP_CALIFORNIA_INITIAL_ENGAGEMENT_07_20_2023",
  OSCARHEALTH_MEDICARE_FLORIDA_DIGITAL_ONLY_INITIAL_ENGAGEMENT_07_27_2023 = "OSCARHEALTH_MEDICARE_FLORIDA_DIGITAL_ONLY_INITIAL_ENGAGEMENT_07_27_2023",
  OSCARHEALTH_MEDICARE_FLORIDA_DIGITAL_ONLY_EVENING_INITIAL_ENGAGEMENT_07_27_2023 = "OSCARHEALTH_MEDICARE_FLORIDA_DIGITAL_ONLY_EVENING_INITIAL_ENGAGEMENT_07_27_2023",
  MOLINA_UTAH_MEDICAID_INITIAL_ENGAGEMENT_2023 = "MOLINA_UTAH_MEDICAID_INITIAL_ENGAGEMENT_2023",
  MOLINA_MICHIGAN_MEDICARE_INITIAL_ENGAGEMENT_2023 = "MOLINA_MICHIGAN_MEDICARE_INITIAL_ENGAGEMENT_2023",
  MOLINA_MICHIGAN_MEDICAID_INITIAL_ENGAGEMENT_2023 = "MOLINA_MICHIGAN_MEDICAID_INITIAL_ENGAGEMENT_2023",
  WELLSENSE_NEWHAMPSHIRE_MEDICARE_INITIAL_ENGAGEMENT_2023 = "WELLSENSE_NEWHAMPSHIRE_MEDICARE_INITIAL_ENGAGEMENT_2023",
  MOLINA_UTAH_MEDICARE_INITIAL_ENGAGEMENT_2023 = "MOLINA_UTAH_MEDICARE_INITIAL_ENGAGEMENT_2023",
  WELLSENSE_MASSACHUSETTS_MEDICARE_INITIAL_ENGAGEMENT_2023 = "WELLSENSE_MASSACHUSETTS_MEDICARE_INITIAL_ENGAGEMENT_2023",
  WELLSENSE_NEWHAMPSHIRE_MEDICAID_INITIAL_ENGAGEMENT_2023 = "WELLSENSE_NEWHAMPSHIRE_MEDICAID_INITIAL_ENGAGEMENT_2023",
  MOLINA_CALIFORNIA_MEDICARE_INITIAL_ENGAGEMENT_2023 = "MOLINA_CALIFORNIA_MEDICARE_INITIAL_ENGAGEMENT_2023",
  MOLINA_CALIFORNIA_EXCHANGE_INITIAL_ENGAGEMENT_2023 = "MOLINA_CALIFORNIA_EXCHANGE_INITIAL_ENGAGEMENT_2023",
  MOLINA_MICHIGAN_MEDICAID_LEAD_SCREENING_INITIAL_ENGAGEMENT_2023 = "MOLINA_MICHIGAN_MEDICAID_LEAD_SCREENING_INITIAL_ENGAGEMENT_2023",
  MOLINA_MICHIGAN_MEDICAID_LEAD_SCREENING_INITIAL_ENGAGEMENT_2024 = "MOLINA_MICHIGAN_MEDICAID_LEAD_SCREENING_INITIAL_ENGAGEMENT_2024",
  WELLSENSE_NEWHAMPSHIRE_MEDICAID_LEAD_SCREENING_INITIAL_ENGAGEMENT_2023 = "WELLSENSE_NEWHAMPSHIRE_MEDICAID_LEAD_SCREENING_INITIAL_ENGAGEMENT_2023",
  UCIHEALTH_CALIFORNIA_MEDICAID_LEAD_SCREENING_INITIAL_ENGAGEMENT_2023 = "UCIHEALTH_CALIFORNIA_MEDICAID_LEAD_SCREENING_INITIAL_ENGAGEMENT_2023",
  UCIHEALTH_CALIFORNIA_MEDICAID_INITIAL_ENGAGEMENT_2023 = "UCIHEALTH_CALIFORNIA_MEDICAID_INITIAL_ENGAGEMENT_2023",
  MOLINA_FLORIDA_MEDICAID_INITIAL_ENGAGEMENT_2023 = "MOLINA_FLORIDA_MEDICAID_INITIAL_ENGAGEMENT_2023",
  OSCARCARE_FLORIDA_EXCHANGE_DIGITAL_ONLY_INITIAL_ENGAGEMENT_2024 = "OSCARCARE_FLORIDA_EXCHANGE_DIGITAL_ONLY_INITIAL_ENGAGEMENT_2024",
  UCIHEALTH_CALIFORNIA_MEDICAID_LEAD_SCREENING_INITIAL_ENGAGEMENT_2024 = "UCIHEALTH_CALIFORNIA_MEDICAID_LEAD_SCREENING_INITIAL_ENGAGEMENT_2024",
  MOLINA_MICHIGAN_MEDICAID_INITIAL_ENGAGEMENT_2024 = "MOLINA_MICHIGAN_MEDICAID_INITIAL_ENGAGEMENT_2024",
  MOLINA_MICHIGAN_MEDICARE_INITIAL_ENGAGEMENT_2024 = "MOLINA_MICHIGAN_MEDICARE_INITIAL_ENGAGEMENT_2024",
  ELEVANCEHEALTH_NATIONAL_MEDICARE_INITIAL_ENGAGEMENT_2024 = "ELEVANCEHEALTH_NATIONAL_MEDICARE_INITIAL_ENGAGEMENT_2024",
  UCIHEALTH_CALIFORNIA_MEDICAID_INITIAL_ENGAGEMENT_2024 = "UCIHEALTH_CALIFORNIA_MEDICAID_INITIAL_ENGAGEMENT_2024",
  MOLINA_CALIFORNIA_EXCHANGE_INITIAL_ENGAGEMENT_2024 = "MOLINA_CALIFORNIA_EXCHANGE_INITIAL_ENGAGEMENT_2024",
  MOLINA_UTAH_MEDICAID_INITIAL_ENGAGEMENT_2024 = "MOLINA_UTAH_MEDICAID_INITIAL_ENGAGEMENT_2024",
  MOLINA_UTAH_MEDICARE_INITIAL_ENGAGEMENT_2024 = "MOLINA_UTAH_MEDICARE_INITIAL_ENGAGEMENT_2024",
  MOLINA_FLORIDA_MEDICAID_INITIAL_ENGAGEMENT_2024 = "MOLINA_FLORIDA_MEDICAID_INITIAL_ENGAGEMENT_2024",
  MOLINA_CALIFORNIA_MEDICAID_INITIAL_ENGAGEMENT_2024 = "MOLINA_CALIFORNIA_MEDICAID_INITIAL_ENGAGEMENT_2024",
  MOLINA_CALIFORNIA_MEDICAID_PEDIATRIC_INITIAL_ENGAGEMENT_2024 = "MOLINA_CALIFORNIA_MEDICAID_PEDIATRIC_INITIAL_ENGAGEMENT_2024",
  TRAININGPARTNER_NATIONAL_MEDICARE_CAMPAIGN_INITIAL_ENGAGEMENT_2024 = "TRAININGPARTNER_NATIONAL_MEDICARE_CAMPAIGN_INITIAL_ENGAGEMENT_2024",
  CENTENE_CALIFORNIA_MEDICARE_INITIAL_ENGAGEMENT_2024 = "CENTENE_CALIFORNIA_MEDICARE_INITIAL_ENGAGEMENT_2024",
  CENTENE_FLORIDA_MEDICARE_INITIAL_ENGAGEMENT_2024 = "CENTENE_FLORIDA_MEDICARE_INITIAL_ENGAGEMENT_2024",
  CENTENE_MASSACHUSETTS_MEDICARE_INITIAL_ENGAGEMENT_2024 = "CENTENE_MASSACHUSETTS_MEDICARE_INITIAL_ENGAGEMENT_2024",
  CENTENE_MICHIGAN_MEDICARE_INITIAL_ENGAGEMENT_2024 = "CENTENE_MICHIGAN_MEDICARE_INITIAL_ENGAGEMENT_2024",
  CENTENE_NEWHAMPSHIRE_MEDICARE_INITIAL_ENGAGEMENT_2024 = "CENTENE_NEWHAMPSHIRE_MEDICARE_INITIAL_ENGAGEMENT_2024",
  CENTENE_OHIO_MEDICARE_INITIAL_ENGAGEMENT_2024 = "CENTENE_OHIO_MEDICARE_INITIAL_ENGAGEMENT_2024",
  CENTENE_NEWHAMPSHIRE_EXCHANGE_INITIAL_ENGAGEMENT_2024 = "CENTENE_NEWHAMPSHIRE_EXCHANGE_INITIAL_ENGAGEMENT_2024",
  ALIGNMENTSTARS_CALIFORNIA_MEDICARE_INITIAL_ENGAGEMENT_2024 = "ALIGNMENTSTARS_CALIFORNIA_MEDICARE_INITIAL_ENGAGEMENT_2024",
  MOLINA_FLORIDA_MEDICAID_LEAD_SCREENING_INITIAL_ENGAGEMENT_2024 = "MOLINA_FLORIDA_MEDICAID_LEAD_SCREENING_INITIAL_ENGAGEMENT_2024",
  OSCARCARE_NATIONAL_EXCHANGE_DIGITAL_ONLY_INITIAL_ENGAGEMENT_2024 = "OSCARCARE_NATIONAL_EXCHANGE_DIGITAL_ONLY_INITIAL_ENGAGEMENT_2024",
  UHC_MASSACHUSETTS_DUAL_MEDICAID_MEDICARE_INITIAL_ENGAGEMENT_2024 = "UHC_MASSACHUSETTS_DUAL_MEDICAID_MEDICARE_INITIAL_ENGAGEMENT_2024",
  CENTENE_OHIO_MEDICAID_INITIAL_ENGAGEMENT_2024 = "CENTENE_OHIO_MEDICAID_INITIAL_ENGAGEMENT_2024",
  MOLINA_CALIFORNIA_MEDICARE_INITIAL_ENGAGEMENT_2024 = "MOLINA_CALIFORNIA_MEDICARE_INITIAL_ENGAGEMENT_2024",
}


export enum ReasonForTransitionToTerminalState {
  ALREADY_COMPLETED_SERVICES_OUTSIDE = "ALREADY_COMPLETED_SERVICES_OUTSIDE",
  ALREADY_COMPLETED_SERVICES_WITH_SPRINTER = "ALREADY_COMPLETED_SERVICES_WITH_SPRINTER",
  INCORRECT_CONTACT_INFORMATION = "INCORRECT_CONTACT_INFORMATION",
  NOT_ASSOCIATED_WITH_PROVIDER_OR_PAYER = "NOT_ASSOCIATED_WITH_PROVIDER_OR_PAYER",
  LINE_DISCONNECTED = "LINE_DISCONNECTED",
  OUTSIDE_SERVICE_REGION = "OUTSIDE_SERVICE_REGION",
  OUTSIDE_APPOINTMENT_AVAILABILITY = "OUTSIDE_APPOINTMENT_AVAILABILITY",
  FAILED_CONTRAINDICATIONS = "FAILED_CONTRAINDICATIONS",
  DECEASED = "DECEASED",
  AGED_OUT = "AGED_OUT",
  WATCHLISTED = "WATCHLISTED",
  DECLINED_TO_SHARE_REASON = "DECLINED_TO_SHARE_REASON",
  PREFER_TO_BE_SEEN_IN_CLINIC_BY_PROVIDER = "PREFER_TO_BE_SEEN_IN_CLINIC_BY_PROVIDER",
  UNCOMFORTABLE_WITH_IN_HOME_VISIT = "UNCOMFORTABLE_WITH_IN_HOME_VISIT",
  DECLINED_TO_VERIFY_IDENTITY = "DECLINED_TO_VERIFY_IDENTITY",
  NOT_INTERESTED = "NOT_INTERESTED",
  NEEDS_WEEKEND_OR_AFTER_HOURS_APPT = "NEEDS_WEEKEND_OR_AFTER_HOURS_APPT",
  REQUESTED_DO_NOT_CALL = "REQUESTED_DO_NOT_CALL",
  INVALID_PATIENT_PHONE_PROVIDED = "INVALID_PATIENT_PHONE_PROVIDED",
  INVALID_PATIENT_LOCATION_PROVIDED = "INVALID_PATIENT_LOCATION_PROVIDED",
  APPOINTMENT_BOOKED_BEFORE_TRICKLE = "APPOINTMENT_BOOKED_BEFORE_TRICKLE",
  APPOINTMENT_BOOKED_BEFORE_FIRST_OUTREACH_ATTEMPT = "APPOINTMENT_BOOKED_BEFORE_FIRST_OUTREACH_ATTEMPT",
  EXPIRED_REFERRAL = "EXPIRED_REFERRAL",
}


export type Practitioner = {
  __typename: "Practitioner",
  id: string,
  // national provider identifier
  NPI: string,
  name?: HumanName | null,
  practitionerGroupName?: string | null,
  address?: Address | null,
  phone?: string | null,
  email?: string | null,
  fax?: string | null,
  faxConfirmationStatus?: FaxConfirmationStatus | null,
  faxLastConfirmedAt?: string | null,
  testFaxLastSentAt?: string | null,
  incorrectFaxes?: Array< string > | null,
  role?: PractitionerRole | null,
  createdAt: string,
  updatedAt: string,
};

export enum FaxConfirmationStatus {
  CONFIRMED = "CONFIRMED",
  PENDING = "PENDING",
  UNATTEMPTED = "UNATTEMPTED",
  INCORRECT = "INCORRECT",
}


export enum PractitionerRole {
  PEDIATRICIAN = "PEDIATRICIAN",
}


export enum PatientPopulation {
  MEDICAID = "MEDICAID",
  MEDICARE = "MEDICARE",
  EXCHANGE = "EXCHANGE",
  COMMERCIAL = "COMMERCIAL",
  DUAL_MEDICAID_MEDICARE = "DUAL_MEDICAID_MEDICARE",
}


export type ConsentForm = {
  __typename: "ConsentForm",
  consentedAt: string,
  type: ConsentType,
  document?: Document | null,
  platform: QuestionnairePlatform,
  formID: string,
  responseID: string,
  appointmentID: string,
  rawResponseJSON: string,
};

export enum ConsentType {
  INFORMED_CONSENT = "INFORMED_CONSENT",
  PRIVACY_CONSENT = "PRIVACY_CONSENT",
}


export enum QuestionnairePlatform {
  QUALTRICS = "QUALTRICS",
  JOTFORM = "JOTFORM",
}


export type ModelPatientContactPointConnection = {
  __typename: "ModelPatientContactPointConnection",
  items:  Array<PatientContactPoint | null >,
  nextToken?: string | null,
};

export type PatientContactPoint = {
  __typename: "PatientContactPoint",
  id: string,
  patientID: string,
  patient: Patient,
  system: ContactPointSystem,
  value: string,
  status: ContactPointStatus,
  statusLastUpdatedAt?: string | null,
  sources?:  Array<PatientContactPointSource > | null,
  createdAt: string,
  updatedAt: string,
};

export enum ContactPointSystem {
  PHONE = "PHONE",
  FAX = "FAX",
  EMAIL = "EMAIL",
  PAGER = "PAGER",
  URL = "URL",
  SMS = "SMS", // SMS should only be used for short codes. Mobile phone numbers should be used with the PHONE system.
  OTHER = "OTHER",
}


export enum ContactPointStatus {
  CONFIRMED = "CONFIRMED",
  PENDING = "PENDING", // Attempted but not confirmed
  UNATTEMPTED = "UNATTEMPTED", // Contact has not been attempted
  INCORRECT = "INCORRECT", // Attempted and explicitly determined to be incorrect
}


export type PatientContactPointSource = {
  __typename: "PatientContactPointSource",
  source: ContactPointSource,
  // Specifies a preferred order in which to use a set of contacts. ContactPoints with lower rank values are more preferred than those with higher rank values.
  rank?: number | null,
  system: ContactPointSystem,
  // The date and time the source reports the contact point as last being utilized. For example in the LexisNexis report they include the last time they saw the contact point in their data. This differs from the last time they reported it to _us_.
  reportedAsLastSeenAt?: string | null,
  // The date and time the source first reported this contact point associated to the patient
  firstReportedAt?: string | null,
  // The date and time the source last reported this contact point associated to the patient
  lastReportedAt?: string | null,
  metadataJSON?: string | null,
};

export enum ContactPointSource {
  REFERRALS_FLOW = "REFERRALS_FLOW",
  PARTNER_PORTAL = "PARTNER_PORTAL",
  PARTNER_GAP_LIST = "PARTNER_GAP_LIST",
  LEXIS_NEXIS = "LEXIS_NEXIS",
  DIRECT_TO_CONSUMER_FLOW = "DIRECT_TO_CONSUMER_FLOW",
  UNKNOWN_LEGACY = "UNKNOWN_LEGACY",
}


export type Tech = {
  __typename: "Tech",
  id: string,
  name: HumanName,
  gender?: Gender | null,
  phone?: string | null,
  email?: string | null,
  vehicle?: Vehicle | null,
  serviceTypes?: Array< ServiceType | null > | null,
  serviceTypesAvailabilityRules?:  Array<ServiceTypeAvailabilityRules > | null,
  availabilityRules?:  Array<TimeRangeRule > | null,
  locations?:  Array<Location > | null,
  profilePicture?: S3Object | null,
  profilePictureS3ObjectID?: string | null,
  isActive?: boolean | null,
  serviceRegions?: ModelTechServiceRegionConnection | null,
  appointments?: ModelAppointmentConnection | null,
  biographyItems?: Array< string > | null,
  patientTestimonials?: Array< string > | null,
  partialAppointmentsForTeamAvailabilityJSON?: string | null,
  capabilities?: Array< TechCapability > | null,
  isIneligibleForMatchmaking?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type Vehicle = {
  __typename: "Vehicle",
  make: string,
  model: string,
  color: string,
};

export type ServiceTypeAvailabilityRules = {
  __typename: "ServiceTypeAvailabilityRules",
  serviceType: ServiceType,
  availabilityRules:  Array<TimeRangeRule >,
};

export type ModelTechServiceRegionConnection = {
  __typename: "ModelTechServiceRegionConnection",
  items:  Array<TechServiceRegion | null >,
  nextToken?: string | null,
};

export type TechServiceRegion = {
  __typename: "TechServiceRegion",
  id: string,
  techID: string,
  serviceRegionName: string,
  tech: Tech,
  availabilityRules?:  Array<TimeRangeRule > | null,
  createdAt: string,
  updatedAt: string,
};

export enum TechCapability {
  PEDIATRIC_VENOUS_BLOOD_DRAW = "PEDIATRIC_VENOUS_BLOOD_DRAW",
}


export type COVID19Questions = {
  __typename: "COVID19Questions",
  isForTravel?: boolean | null,
  isForWorkOrSchool?: boolean | null,
  isForAPublicEvent?: boolean | null,
  isForPotentialExposure?: boolean | null,
  isForSymptoms?: boolean | null,
  isForOtherReasons?: boolean | null,
};

export type ServiceOutcome = {
  __typename: "ServiceOutcome",
  failureReason?: ServiceFailureReason | null,
  failureFault?: ServiceFailureFault | null,
  isRescheduleRequired?: boolean | null,
  serviceType: ServiceType,
  status?: ServiceStatus | null,
  commentsFromSchedulerAboutServiceFailure?: string | null,
  serviceDetailJSON?: string | null,
};

export type CustomProperty = {
  __typename: "CustomProperty",
  key: CustomPropertyKey,
  value: string,
};

export enum CustomPropertyKey {
  FMI_CASE_NUMBER = "FMI_CASE_NUMBER",
  GUARDANT_ACCESSION_NUMBER = "GUARDANT_ACCESSION_NUMBER",
  NATERA_JOB_NAME = "NATERA_JOB_NAME",
}


export type Procedure = {
  __typename: "Procedure",
  code: Coding,
  source: ProcedureSource,
};

export enum ProcedureSource {
  ELATION_BILLING_REPORT = "ELATION_BILLING_REPORT",
  CHECKLIST = "CHECKLIST",
}


export type Escalation = {
  __typename: "Escalation",
  isResolved?: boolean | null,
  symptoms?: Array< EscalationSymptom > | null,
  // If OTHER is selected in the symptoms this contains the list of other symptoms.
  otherSymptoms?: string | null,
  // Comments from the patient to the escalation owner, like their medical history. The escalation owner is the scheduler who is filling out the report.
  commentsFromPatientToEscalationOwner?: string | null,
  // Comments from the escalation owner to the partner about the escalation. This includes the general assessment of the situation involving the patient. The escalation owner is the scheduler who is filling out the report.
  commentsFromEscalationOwnerToPartnerAboutEscalation?: string | null,
  outcome?: EscalationOutcome | null,
  // If OTHER is selected for the outcome this contains a description of the outcome.
  otherOutcome?: string | null,
  outcomeTimeframe?: EscalationOutcomeTimeframe | null,
  outcomeApproverName?: HumanName | null,
  triggeringObservation?: Observation | null,
  createdAt?: string | null,
  resolvedAt?: string | null,
};

export enum EscalationSymptom {
  DIFFICULTY_BREATHING_OR_SHORTNESS_OF_BREATH = "DIFFICULTY_BREATHING_OR_SHORTNESS_OF_BREATH",
  CHEST_SHOULDER_OR_JAW_PAIN = "CHEST_SHOULDER_OR_JAW_PAIN",
  HEADACHE = "HEADACHE",
  DIZZINESS = "DIZZINESS",
  BLURRED_VISION = "BLURRED_VISION",
  NUMBNESS_OR_TINGLING_IN_HANDS_OR_FEET = "NUMBNESS_OR_TINGLING_IN_HANDS_OR_FEET",
  NOSEBLEED = "NOSEBLEED",
  DROWSINESS = "DROWSINESS",
  CONFUSION = "CONFUSION",
  SUDDEN_WEAKNESS = "SUDDEN_WEAKNESS",
  FAINTING = "FAINTING",
  ABDOMINAL_PAIN = "ABDOMINAL_PAIN",
  RECENT_INJURY_OR_FALL = "RECENT_INJURY_OR_FALL",
  NO_SYMPTOMS = "NO_SYMPTOMS",
  OTHER = "OTHER",
}


export enum EscalationOutcome {
  REFERRED_TO_URGENT_CARE = "REFERRED_TO_URGENT_CARE",
  REFERRED_TO_PCP_OR_SPECIALIST = "REFERRED_TO_PCP_OR_SPECIALIST",
  RECOMMENDED_EMERGENCY_DEPARTMENT_WITH_ACCOMPANIED_RIDE = "RECOMMENDED_EMERGENCY_DEPARTMENT_WITH_ACCOMPANIED_RIDE",
  SENT_TO_EMERGENCY_DEPARTMENT_WITH_EMS = "SENT_TO_EMERGENCY_DEPARTMENT_WITH_EMS",
  RECOMMENDED_EMERGENCY_DEPARTMENT_BUT_THEY_REFUSED = "RECOMMENDED_EMERGENCY_DEPARTMENT_BUT_THEY_REFUSED",
  CLOSED_AFTER_ACCIDENTAL_TRIGGERING = "CLOSED_AFTER_ACCIDENTAL_TRIGGERING",
  HANDED_OFF_TO_PARTNER = "HANDED_OFF_TO_PARTNER", // The patient was given a warm handoff to the partner's nurse line or escalation resource.
  OTHER = "OTHER",
}


export enum EscalationOutcomeTimeframe {
  IMMEDIATELY = "IMMEDIATELY",
  WITHIN_1_HOUR = "WITHIN_1_HOUR",
  WITHIN_24_HOURS = "WITHIN_24_HOURS",
  WITHIN_1_WEEK = "WITHIN_1_WEEK",
}


export type QuestionnaireEmbedded = {
  __typename: "QuestionnaireEmbedded",
  type: QuestionnaireType,
  url: string,
  response?: QuestionnaireResponse | null,
  metadataJSON?: string | null,
};

// Should be added to survey Platform callback to be able to identify the way of parsing response
export enum QuestionnaireType {
  PRAPARE_SDOH_CARE_NAVIGATION_RESOURCE_GUIDANCE = "PRAPARE_SDOH_CARE_NAVIGATION_RESOURCE_GUIDANCE",
  PATIENT_INFORMED_CONSENT = "PATIENT_INFORMED_CONSENT",
  PATIENT_PRIVACY_CONSENT = "PATIENT_PRIVACY_CONSENT",
}


export type QuestionnaireResponse = {
  __typename: "QuestionnaireResponse",
  // Canonical id of Questionnaire being answered
  responseID?: string | null,
  // Date the answers were gathered
  authoredAt?: string | null,
  items?:  Array<QuestionnaireResponseItem > | null,
};

export type QuestionnaireResponseItem = {
  __typename: "QuestionnaireResponseItem",
  // Pointer to specific question from Questionnaire e.g. LOINC code
  // (used in survey platform callback to identify question)
  externalID: string,
  // A reference to an ElementDefinition that provides the details for the item e. g. https://loinc.org/56051-6
  definition?: string | null,
  text: string,
  answers?:  Array<QuestionnaireResponseItemAnswer > | null,
  items?:  Array<QuestionnaireResponseItem > | null,
};

export type QuestionnaireResponseItemAnswer = {
  __typename: "QuestionnaireResponseItemAnswer",
  value?: string | null,
  valueCoding?: Coding | null,
};

export type RemoteTech = {
  __typename: "RemoteTech",
  id: string,
  name: HumanName,
  email?: string | null,
  phone?: string | null,
  profilePicture?: S3Object | null,
  profilePictureS3ObjectID?: string | null,
  timezone?: Timezone | null,
  availabilityRules?:  Array<TimeRangeRule > | null,
  isActive: boolean,
  qualifications?:  Array<Qualification > | null,
  remoteVisitZoomLink?: string | null,
  remoteVisitZoomLinkQRCode?: S3Object | null,
  remoteVisitZoomLinkQRCodeS3ObjectID?: string | null,
  appointments?: ModelAppointmentConnection | null,
  partialAppointmentsForTeamAvailabilityJSON?: string | null,
  matchableAndAcceptedAppointments?:  Array<Appointment > | null,
  createdAt: string,
  updatedAt: string,
};

export enum Timezone {
  AMERICA_LOS_ANGELES = "AMERICA_LOS_ANGELES",
  AMERICA_DENVER = "AMERICA_DENVER",
  AMERICA_CHICAGO = "AMERICA_CHICAGO",
  AMERICA_NEW_YORK = "AMERICA_NEW_YORK",
}


export type Qualification = {
  __typename: "Qualification",
  qualificationType: QualificationType,
  geographicalState: string,
};

export type UpdateAppointmentInput = {
  id: string,
  status?: AppointmentStatus | null,
  isFailed?: boolean | null,
  hasSuccessfulServices?: boolean | null,
  hasFailedServices?: boolean | null,
  needsRebooking?: boolean | null,
  isPreReviewed?: boolean | null,
  isPostReviewed?: boolean | null,
  isEscalated?: boolean | null,
  start?: string | null,
  end?: string | null,
  techID?: string | null,
  remoteTechID?: string | null,
  remoteTechMatchState?: RemoteTechMatchState | null,
  coordinatorID?: string | null,
  location?: LocationInput | null,
  partnerOrganization?: PartnerOrganization | null,
  preReviewChecklist?: ChecklistInput | null,
  checklist?: ChecklistInput | null,
  postReviewChecklist?: ChecklistInput | null,
  serviceRegionName?: string | null,
  geographicalState?: string | null,
  avoidTechIDs?: Array< string > | null,
  lastBookedAt?: number | null,
  statusUpdatedAt?: number | null,
  stripeCustomerID?: string | null,
  stripePriceToCharge?: number | null,
  hasPetsAtLocation?: boolean | null,
  commentsFromCoordinatorAboutAppointment?: string | null,
  commentsFromTechAboutAppointment?: string | null,
  commentsFromTechAboutSystem?: string | null,
  commentsFromTechAboutAppointmentFailure?: string | null,
  commentsFromSchedulerAboutAppointmentFailure?: string | null,
  commentsFromSchedulerAboutCancellation?: string | null,
  partnerOrganizationBookingURL?: string | null,
  overrideMode?: AppointmentOverrideMode | null,
  sharedSlotRank?: number | null,
  estimatedServiceDurationSeconds?: number | null,
  stripeInvoiceLineItemIDs?: Array< string | null > | null,
  stripePaymentIntentIDs?: Array< string | null > | null,
  hasPatientConfirmed?: boolean | null,
  isGuestBooked?: boolean | null,
  referralID?: string | null,
  historyJSON?: string | null,
  externalID?: string | null,
  clonedFromAppointmentID?: string | null,
  remoteTechQualificationTypeRequired?: QualificationType | null,
  markedReadyForRemoteTechAt?: string | null,
};

export type DeleteAppointmentInput = {
  id: string,
};

export type CreateEncounterInput = {
  id?: string | null,
  appointmentID: string,
  patientID: string,
  coordinatorID: string,
  techID?: string | null,
  partnerOrganization?: PartnerOrganization | null,
  requisitions?: Array< RequisitionInput > | null,
  requisitionUploads?: Array< S3ObjectInput > | null,
  requisitionUploadS3ObjectIDs?: Array< string > | null,
  commentsFromCoordinatorAboutPatient?: string | null,
  commentsFromSchedulerAboutEncounter?: string | null,
  commentsFromSchedulerToTechAboutEncounter?: string | null,
  commentsFromTechAboutEncounter?: string | null,
  commentsFromCustomerSupportAboutEncounter?: string | null,
  commentsFromSchedulerToPartnerAboutEncounter?: string | null,
  covid19Questions?: COVID19QuestionsInput | null,
  bloodCollectionDetails?: SpecimenCollectionDetailsInput | null,
  capillaryDrawCollectionDetails?: SpecimenCollectionDetailsInput | null,
  productTypes?: Array< ProductType > | null,
  isMedicare?: boolean | null,
  isMedicareEligible?: boolean | null,
  wasPatientManuallyAdded?: boolean | null,
  serviceOutcomes?: Array< ServiceOutcomeInput > | null,
  diagnosisCodes?: Array< CodingInput > | null,
  partnersEncounterID?: CustomPropertyInput | null,
  documents?: Array< DocumentInput > | null,
  observations?: Array< ObservationInput > | null,
  procedures?: Array< ProcedureInput > | null,
  escalation?: EscalationInput | null,
  questionnaires?: Array< QuestionnaireEmbeddedInput > | null,
  colorectalCancerScreeningAnswers?: QuestionnaireResponseInput | null,
  patientEncounterSummaryLastSentAt?: string | null,
  cologuardOrderSuccessfullyPlacedAt?: string | null,
  hasPatientRequestedMailedResults?: boolean | null,
};

export type RequisitionInput = {
  id: string,
  labOrganization?: LabOrganization | null,
  serviceRequests?: Array< ServiceRequestInput > | null,
  // A unique numerical patient requisition number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
  requisitionNumber?: string | null,
};

export type ServiceRequestInput = {
  id: string,
  isNewServiceRequestEntry?: boolean | null,
  displayName?: string | null,
  externalID: string,
};

export type COVID19QuestionsInput = {
  isForTravel?: boolean | null,
  isForWorkOrSchool?: boolean | null,
  isForAPublicEvent?: boolean | null,
  isForPotentialExposure?: boolean | null,
  isForSymptoms?: boolean | null,
  isForOtherReasons?: boolean | null,
};

export type SpecimenCollectionDetailsInput = {
  bodySiteLaterality?: Laterality | null,
  bodySiteStructure?: BodyStructure | null,
  deviceType?: SpecimenCollectionDeviceType | null,
  attempts?: number | null,
};

export type ServiceOutcomeInput = {
  failureReason?: ServiceFailureReason | null,
  failureFault?: ServiceFailureFault | null,
  isRescheduleRequired?: boolean | null,
  serviceType: ServiceType,
  status?: ServiceStatus | null,
  commentsFromSchedulerAboutServiceFailure?: string | null,
  serviceDetailJSON?: string | null,
};

export type CodingInput = {
  system: string,
  code: string,
  displayName?: string | null,
};

export type CustomPropertyInput = {
  key: CustomPropertyKey,
  value: string,
};

export type DocumentInput = {
  type?: DocumentType | null,
  url?: string | null,
  format?: DocumentFormat | null,
  s3Object?: S3ObjectInput | null,
  s3ObjectID?: string | null,
  uploadedAt?: number | null,
  // stringified JSON object as a set of additional data we want to store about documents (requisition number, lab report status, etc.)
  metadataJSON?: string | null,
};

export type ObservationInput = {
  id: string,
  identifiers?: Array< ObservationIdentifierInput > | null,
  basedOnServiceRequestType?: ObservationPartialServiceRequestTypeInput | null,
  value?: string | null,
  valueCoding?: Array< CodingInput > | null,
  units?: string | null,
  referenceRange?: string | null,
  category: ObservationCategory,
  performerType: ObservationPerformerType,
  performerIdentifier?: string | null,
  observedAt?: string | null,
  bodySiteLaterality?: Laterality | null,
  bodySiteStructure?: BodyStructure | null,
  // Also called Abnormal Flag. A categorical assessment, providing a rough qualitative interpretation of the observation value.
  // http://hl7.org/fhir/valueset-observation-interpretation.html
  interpretationCode?: ObservationInterpretationCode | null,
  notes?: string | null,
  source?: ObservationSource | null,
};

export type ObservationIdentifierInput = {
  system: ObservationIdentifierSystem,
  code: string,
  displayName?: string | null,
};

export type ObservationPartialServiceRequestTypeInput = {
  id?: string | null,
  externalID: string,
  labOrganization: LabOrganization,
};

export type ProcedureInput = {
  code: CodingInput,
  source: ProcedureSource,
};

export type EscalationInput = {
  isResolved?: boolean | null,
  symptoms?: Array< EscalationSymptom > | null,
  otherSymptoms?: string | null,
  commentsFromPatientToEscalationOwner?: string | null,
  commentsFromEscalationOwnerToPartnerAboutEscalation?: string | null,
  outcome?: EscalationOutcome | null,
  otherOutcome?: string | null,
  outcomeTimeframe?: EscalationOutcomeTimeframe | null,
  outcomeApproverName?: HumanNameInput | null,
  triggeringObservation?: ObservationInput | null,
  createdAt?: string | null,
  resolvedAt?: string | null,
};

export type HumanNameInput = {
  use: NameUse,
  family?: string | null,
  given?: Array< string > | null,
  prefix?: HumanNamePrefix | null,
  suffix?: string | null,
};

export type QuestionnaireEmbeddedInput = {
  type: QuestionnaireType,
  url: string,
  response?: QuestionnaireResponseInput | null,
  metadataJSON?: string | null,
};

export type QuestionnaireResponseInput = {
  responseID?: string | null,
  authoredAt?: string | null,
  items?: Array< QuestionnaireResponseItemInput > | null,
};

export type QuestionnaireResponseItemInput = {
  externalID: string,
  definition?: string | null,
  text: string,
  answers?: Array< QuestionnaireResponseItemAnswerInput > | null,
  items?: Array< QuestionnaireResponseItemInput > | null,
};

export type QuestionnaireResponseItemAnswerInput = {
  value?: string | null,
  valueCoding?: CodingInput | null,
};

export type ModelEncounterConditionInput = {
  appointmentID?: ModelIDInput | null,
  patientID?: ModelIDInput | null,
  coordinatorID?: ModelIDInput | null,
  techID?: ModelIDInput | null,
  partnerOrganization?: ModelPartnerOrganizationInput | null,
  requisitionUploadS3ObjectIDs?: ModelIDInput | null,
  commentsFromCoordinatorAboutPatient?: ModelStringInput | null,
  commentsFromSchedulerAboutEncounter?: ModelStringInput | null,
  commentsFromSchedulerToTechAboutEncounter?: ModelStringInput | null,
  commentsFromTechAboutEncounter?: ModelStringInput | null,
  commentsFromCustomerSupportAboutEncounter?: ModelStringInput | null,
  commentsFromSchedulerToPartnerAboutEncounter?: ModelStringInput | null,
  productTypes?: ModelProductTypeListInput | null,
  isMedicare?: ModelBooleanInput | null,
  isMedicareEligible?: ModelBooleanInput | null,
  wasPatientManuallyAdded?: ModelBooleanInput | null,
  patientEncounterSummaryLastSentAt?: ModelStringInput | null,
  cologuardOrderSuccessfullyPlacedAt?: ModelStringInput | null,
  hasPatientRequestedMailedResults?: ModelBooleanInput | null,
  and?: Array< ModelEncounterConditionInput | null > | null,
  or?: Array< ModelEncounterConditionInput | null > | null,
  not?: ModelEncounterConditionInput | null,
};

export type ModelProductTypeListInput = {
  eq?: Array< ProductType | null > | null,
  ne?: Array< ProductType | null > | null,
  contains?: ProductType | null,
  notContains?: ProductType | null,
};

export type UpdateEncounterInput = {
  id: string,
  appointmentID?: string | null,
  patientID?: string | null,
  coordinatorID?: string | null,
  techID?: string | null,
  partnerOrganization?: PartnerOrganization | null,
  requisitions?: Array< RequisitionInput > | null,
  requisitionUploads?: Array< S3ObjectInput > | null,
  requisitionUploadS3ObjectIDs?: Array< string > | null,
  commentsFromCoordinatorAboutPatient?: string | null,
  commentsFromSchedulerAboutEncounter?: string | null,
  commentsFromSchedulerToTechAboutEncounter?: string | null,
  commentsFromTechAboutEncounter?: string | null,
  commentsFromCustomerSupportAboutEncounter?: string | null,
  commentsFromSchedulerToPartnerAboutEncounter?: string | null,
  covid19Questions?: COVID19QuestionsInput | null,
  bloodCollectionDetails?: SpecimenCollectionDetailsInput | null,
  capillaryDrawCollectionDetails?: SpecimenCollectionDetailsInput | null,
  productTypes?: Array< ProductType > | null,
  isMedicare?: boolean | null,
  isMedicareEligible?: boolean | null,
  wasPatientManuallyAdded?: boolean | null,
  serviceOutcomes?: Array< ServiceOutcomeInput > | null,
  diagnosisCodes?: Array< CodingInput > | null,
  partnersEncounterID?: CustomPropertyInput | null,
  documents?: Array< DocumentInput > | null,
  observations?: Array< ObservationInput > | null,
  procedures?: Array< ProcedureInput > | null,
  escalation?: EscalationInput | null,
  questionnaires?: Array< QuestionnaireEmbeddedInput > | null,
  colorectalCancerScreeningAnswers?: QuestionnaireResponseInput | null,
  patientEncounterSummaryLastSentAt?: string | null,
  cologuardOrderSuccessfullyPlacedAt?: string | null,
  hasPatientRequestedMailedResults?: boolean | null,
};

export type DeleteEncounterInput = {
  id: string,
};

export type CreateCareNavigationEpisodeInput = {
  closedAt?: string | null,
  careNavigationGroup: CareNavigationGroup,
  patientID: string,
  partnerOrganization: PartnerOrganization,
  referralID: string,
  zendeskTicketID?: number | null,
  serviceOutcomes?: Array< CareNavigationEpisodeServiceOutcomeInput > | null,
  documents?: Array< DocumentInput > | null,
  observations?: Array< ObservationInput > | null,
  onboardingQuestionsAndAnswers?: Array< OnboardingQuestionAnswerPairInput > | null,
  createdAt?: string | null,
  id?: string | null,
};

export type CareNavigationEpisodeServiceOutcomeInput = {
  id: string,
  failureReason?: ServiceFailureReason | null,
  failureFault?: ServiceFailureFault | null,
  isRescheduleRequired?: boolean | null,
  serviceType: ServiceType,
  status?: ServiceStatus | null,
  commentsFromCareNavigatorAboutServiceFailure?: string | null,
  statusChangedAt?: string | null,
  serviceDetailJSON: string,
};

export type OnboardingQuestionAnswerPairInput = {
  key: string,
  question: string,
  answer: string,
};

export type ModelCareNavigationEpisodeConditionInput = {
  closedAt?: ModelStringInput | null,
  careNavigationGroup?: ModelCareNavigationGroupInput | null,
  patientID?: ModelIDInput | null,
  partnerOrganization?: ModelPartnerOrganizationInput | null,
  referralID?: ModelIDInput | null,
  zendeskTicketID?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCareNavigationEpisodeConditionInput | null > | null,
  or?: Array< ModelCareNavigationEpisodeConditionInput | null > | null,
  not?: ModelCareNavigationEpisodeConditionInput | null,
};

export type ModelCareNavigationGroupInput = {
  eq?: CareNavigationGroup | null,
  ne?: CareNavigationGroup | null,
};

export type UpdateCareNavigationEpisodeInput = {
  closedAt?: string | null,
  careNavigationGroup?: CareNavigationGroup | null,
  patientID?: string | null,
  partnerOrganization?: PartnerOrganization | null,
  referralID?: string | null,
  zendeskTicketID?: number | null,
  serviceOutcomes?: Array< CareNavigationEpisodeServiceOutcomeInput > | null,
  documents?: Array< DocumentInput > | null,
  observations?: Array< ObservationInput > | null,
  onboardingQuestionsAndAnswers?: Array< OnboardingQuestionAnswerPairInput > | null,
  createdAt?: string | null,
  id: string,
};

export type DeleteCareNavigationEpisodeInput = {
  id: string,
};

export type CreateS3ObjectDataInput = {
  id?: string | null,
  region: string,
  bucket: string,
  key: string,
  identityID: string,
  parentType: S3ObjectParentType,
  parentID: string,
  contentType?: string | null,
};

export enum S3ObjectParentType {
  APPOINTMENT = "APPOINTMENT",
  ENCOUNTER = "ENCOUNTER",
  CARE_NAVIGATION_EPISODE = "CARE_NAVIGATION_EPISODE",
  UNLINKABLE_REPORT = "UNLINKABLE_REPORT",
  PATIENT = "PATIENT",
  TECH = "TECH",
  REMOTE_TECH = "REMOTE_TECH",
  REFERRAL = "REFERRAL",
}


export type ModelS3ObjectDataConditionInput = {
  region?: ModelStringInput | null,
  bucket?: ModelStringInput | null,
  key?: ModelStringInput | null,
  identityID?: ModelStringInput | null,
  parentType?: ModelS3ObjectParentTypeInput | null,
  parentID?: ModelIDInput | null,
  contentType?: ModelStringInput | null,
  and?: Array< ModelS3ObjectDataConditionInput | null > | null,
  or?: Array< ModelS3ObjectDataConditionInput | null > | null,
  not?: ModelS3ObjectDataConditionInput | null,
};

export type ModelS3ObjectParentTypeInput = {
  eq?: S3ObjectParentType | null,
  ne?: S3ObjectParentType | null,
};

export type S3ObjectData = {
  __typename: "S3ObjectData",
  id: string,
  region: string,
  bucket: string,
  key: string,
  identityID: string,
  parentType: S3ObjectParentType,
  parentID: string,
  contentType?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateS3ObjectDataInput = {
  id: string,
  region?: string | null,
  bucket?: string | null,
  key?: string | null,
  identityID?: string | null,
  parentType?: S3ObjectParentType | null,
  parentID?: string | null,
  contentType?: string | null,
};

export type DeleteS3ObjectDataInput = {
  id: string,
};

export type CreatePartnerUserInviteInput = {
  id?: string | null,
  email: string,
  partnerOrganization: PartnerOrganization,
  status: PartnerUserInviteStatus,
  expiry: number,
  ttl?: number | null,
  managePartnerUsersAuthorization: ManagePartnerUsersAuthorization,
  isArchived?: boolean | null,
};

export enum PartnerUserInviteStatus {
  PENDING = "PENDING", // The CSR has been invited, but they haven't clicked on the link
  ACCEPTED = "ACCEPTED", // The CSR is done signing up
}


export type ModelPartnerUserInviteConditionInput = {
  email?: ModelStringInput | null,
  partnerOrganization?: ModelPartnerOrganizationInput | null,
  status?: ModelPartnerUserInviteStatusInput | null,
  expiry?: ModelIntInput | null,
  ttl?: ModelIntInput | null,
  managePartnerUsersAuthorization?: ModelManagePartnerUsersAuthorizationInput | null,
  isArchived?: ModelBooleanInput | null,
  and?: Array< ModelPartnerUserInviteConditionInput | null > | null,
  or?: Array< ModelPartnerUserInviteConditionInput | null > | null,
  not?: ModelPartnerUserInviteConditionInput | null,
};

export type ModelPartnerUserInviteStatusInput = {
  eq?: PartnerUserInviteStatus | null,
  ne?: PartnerUserInviteStatus | null,
};

export type ModelManagePartnerUsersAuthorizationInput = {
  eq?: ManagePartnerUsersAuthorization | null,
  ne?: ManagePartnerUsersAuthorization | null,
};

export type PartnerUserInvite = {
  __typename: "PartnerUserInvite",
  id: string,
  email: string,
  partnerOrganization: PartnerOrganization,
  status: PartnerUserInviteStatus,
  expiry: number,
  ttl?: number | null,
  managePartnerUsersAuthorization: ManagePartnerUsersAuthorization,
  isArchived?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdatePartnerUserInviteInput = {
  id: string,
  email?: string | null,
  partnerOrganization?: PartnerOrganization | null,
  status?: PartnerUserInviteStatus | null,
  expiry?: number | null,
  ttl?: number | null,
  managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
  isArchived?: boolean | null,
};

export type DeletePartnerUserInviteInput = {
  id: string,
};

export type CreatePractitionerInput = {
  id?: string | null,
  NPI: string,
  name?: HumanNameInput | null,
  practitionerGroupName?: string | null,
  address?: AddressInput | null,
  phone?: string | null,
  email?: string | null,
  fax?: string | null,
  faxConfirmationStatus?: FaxConfirmationStatus | null,
  faxLastConfirmedAt?: string | null,
  testFaxLastSentAt?: string | null,
  incorrectFaxes?: Array< string > | null,
  role?: PractitionerRole | null,
};

export type ModelPractitionerConditionInput = {
  NPI?: ModelStringInput | null,
  practitionerGroupName?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  email?: ModelStringInput | null,
  fax?: ModelStringInput | null,
  faxConfirmationStatus?: ModelFaxConfirmationStatusInput | null,
  faxLastConfirmedAt?: ModelStringInput | null,
  testFaxLastSentAt?: ModelStringInput | null,
  incorrectFaxes?: ModelStringInput | null,
  role?: ModelPractitionerRoleInput | null,
  and?: Array< ModelPractitionerConditionInput | null > | null,
  or?: Array< ModelPractitionerConditionInput | null > | null,
  not?: ModelPractitionerConditionInput | null,
};

export type ModelFaxConfirmationStatusInput = {
  eq?: FaxConfirmationStatus | null,
  ne?: FaxConfirmationStatus | null,
};

export type ModelPractitionerRoleInput = {
  eq?: PractitionerRole | null,
  ne?: PractitionerRole | null,
};

export type UpdatePractitionerInput = {
  id: string,
  NPI?: string | null,
  name?: HumanNameInput | null,
  practitionerGroupName?: string | null,
  address?: AddressInput | null,
  phone?: string | null,
  email?: string | null,
  fax?: string | null,
  faxConfirmationStatus?: FaxConfirmationStatus | null,
  faxLastConfirmedAt?: string | null,
  testFaxLastSentAt?: string | null,
  incorrectFaxes?: Array< string > | null,
  role?: PractitionerRole | null,
};

export type DeletePractitionerInput = {
  id: string,
};

export type CreateTechInput = {
  id?: string | null,
  name: HumanNameInput,
  gender?: Gender | null,
  phone?: string | null,
  email?: string | null,
  vehicle?: VehicleInput | null,
  serviceTypes?: Array< ServiceType | null > | null,
  serviceTypesAvailabilityRules?: Array< ServiceTypeAvailabilityRulesInput > | null,
  availabilityRules?: Array< TimeRangeRuleInput > | null,
  locations?: Array< LocationInput > | null,
  profilePicture?: S3ObjectInput | null,
  profilePictureS3ObjectID?: string | null,
  isActive?: boolean | null,
  biographyItems?: Array< string > | null,
  patientTestimonials?: Array< string > | null,
  partialAppointmentsForTeamAvailabilityJSON?: string | null,
  capabilities?: Array< TechCapability > | null,
  isIneligibleForMatchmaking?: boolean | null,
};

export type VehicleInput = {
  make: string,
  model: string,
  color: string,
};

export type ServiceTypeAvailabilityRulesInput = {
  serviceType: ServiceType,
  availabilityRules: Array< TimeRangeRuleInput >,
};

export type TimeRangeRuleInput = {
  id: string,
  inclusion: TimeRangeRuleInclusion,
  start: string,
  end: string,
  recurrenceStart: string,
  recurrenceEnd?: string | null,
  recurrenceMultiple: number,
  recurrencePeriod: TimeRangeRulePeriod,
  unavailabilityReason?: TimeRangeRuleUnavailabilityReason | null,
  notes?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type ModelTechConditionInput = {
  gender?: ModelGenderInput | null,
  phone?: ModelStringInput | null,
  email?: ModelStringInput | null,
  serviceTypes?: ModelServiceTypeListInput | null,
  profilePictureS3ObjectID?: ModelIDInput | null,
  isActive?: ModelBooleanInput | null,
  biographyItems?: ModelStringInput | null,
  patientTestimonials?: ModelStringInput | null,
  partialAppointmentsForTeamAvailabilityJSON?: ModelStringInput | null,
  capabilities?: ModelTechCapabilityListInput | null,
  isIneligibleForMatchmaking?: ModelBooleanInput | null,
  and?: Array< ModelTechConditionInput | null > | null,
  or?: Array< ModelTechConditionInput | null > | null,
  not?: ModelTechConditionInput | null,
};

export type ModelGenderInput = {
  eq?: Gender | null,
  ne?: Gender | null,
};

export type ModelServiceTypeListInput = {
  eq?: Array< ServiceType | null > | null,
  ne?: Array< ServiceType | null > | null,
  contains?: ServiceType | null,
  notContains?: ServiceType | null,
};

export type ModelTechCapabilityListInput = {
  eq?: Array< TechCapability | null > | null,
  ne?: Array< TechCapability | null > | null,
  contains?: TechCapability | null,
  notContains?: TechCapability | null,
};

export type CreateTechServiceRegionInput = {
  id?: string | null,
  techID: string,
  serviceRegionName: string,
  availabilityRules?: Array< TimeRangeRuleInput > | null,
};

export type ModelTechServiceRegionConditionInput = {
  techID?: ModelIDInput | null,
  serviceRegionName?: ModelStringInput | null,
  and?: Array< ModelTechServiceRegionConditionInput | null > | null,
  or?: Array< ModelTechServiceRegionConditionInput | null > | null,
  not?: ModelTechServiceRegionConditionInput | null,
};

export type UpdateTechServiceRegionInput = {
  id: string,
  techID?: string | null,
  serviceRegionName?: string | null,
  availabilityRules?: Array< TimeRangeRuleInput > | null,
};

export type DeleteTechServiceRegionInput = {
  id: string,
};

export type CreateRemoteTechInput = {
  id?: string | null,
  name: HumanNameInput,
  email?: string | null,
  phone?: string | null,
  profilePicture?: S3ObjectInput | null,
  profilePictureS3ObjectID?: string | null,
  timezone?: Timezone | null,
  availabilityRules?: Array< TimeRangeRuleInput > | null,
  isActive: boolean,
  qualifications?: Array< QualificationInput > | null,
  remoteVisitZoomLink?: string | null,
  remoteVisitZoomLinkQRCode?: S3ObjectInput | null,
  remoteVisitZoomLinkQRCodeS3ObjectID?: string | null,
  partialAppointmentsForTeamAvailabilityJSON?: string | null,
};

export type QualificationInput = {
  qualificationType: QualificationType,
  geographicalState: string,
};

export type ModelRemoteTechConditionInput = {
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  profilePictureS3ObjectID?: ModelIDInput | null,
  timezone?: ModelTimezoneInput | null,
  isActive?: ModelBooleanInput | null,
  remoteVisitZoomLink?: ModelStringInput | null,
  remoteVisitZoomLinkQRCodeS3ObjectID?: ModelIDInput | null,
  partialAppointmentsForTeamAvailabilityJSON?: ModelStringInput | null,
  and?: Array< ModelRemoteTechConditionInput | null > | null,
  or?: Array< ModelRemoteTechConditionInput | null > | null,
  not?: ModelRemoteTechConditionInput | null,
};

export type ModelTimezoneInput = {
  eq?: Timezone | null,
  ne?: Timezone | null,
};

export type UpdateRemoteTechInput = {
  id: string,
  name?: HumanNameInput | null,
  email?: string | null,
  phone?: string | null,
  profilePicture?: S3ObjectInput | null,
  profilePictureS3ObjectID?: string | null,
  timezone?: Timezone | null,
  availabilityRules?: Array< TimeRangeRuleInput > | null,
  isActive?: boolean | null,
  qualifications?: Array< QualificationInput > | null,
  remoteVisitZoomLink?: string | null,
  remoteVisitZoomLinkQRCode?: S3ObjectInput | null,
  remoteVisitZoomLinkQRCodeS3ObjectID?: string | null,
  partialAppointmentsForTeamAvailabilityJSON?: string | null,
};

export type CreateCoordinatorInput = {
  id?: string | null,
  authorizedTechIDs?: Array< string > | null,
  name?: HumanNameInput | null,
  isCSR?: boolean | null,
  isActive?: boolean | null,
  managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
  authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
  phone?: string | null,
  email?: string | null,
  consentToContactForAppointmentBySMS?: ConsentInput | null,
  billingCustomerID?: string | null,
  consentToContactForResearch?: ConsentInput | null,
  consentToContactForMarketing?: ConsentInput | null,
  outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
  stripeCustomerID?: string | null,
  isArchived?: boolean | null,
};

export type ConsentInput = {
  permit: boolean,
  version: number,
};

export type ModelCoordinatorConditionInput = {
  authorizedTechIDs?: ModelIDInput | null,
  isCSR?: ModelBooleanInput | null,
  isActive?: ModelBooleanInput | null,
  managePartnerUsersAuthorization?: ModelManagePartnerUsersAuthorizationInput | null,
  authorizedPartnerOrganizations?: ModelPartnerOrganizationListInput | null,
  phone?: ModelStringInput | null,
  email?: ModelStringInput | null,
  billingCustomerID?: ModelStringInput | null,
  outstandingPartnerOrganizationBookingURLs?: ModelStringInput | null,
  stripeCustomerID?: ModelStringInput | null,
  isArchived?: ModelBooleanInput | null,
  and?: Array< ModelCoordinatorConditionInput | null > | null,
  or?: Array< ModelCoordinatorConditionInput | null > | null,
  not?: ModelCoordinatorConditionInput | null,
};

export type ModelPartnerOrganizationListInput = {
  eq?: Array< PartnerOrganization | null > | null,
  ne?: Array< PartnerOrganization | null > | null,
  contains?: PartnerOrganization | null,
  notContains?: PartnerOrganization | null,
};

export type UpdateCoordinatorInput = {
  id: string,
  authorizedTechIDs?: Array< string > | null,
  name?: HumanNameInput | null,
  isCSR?: boolean | null,
  isActive?: boolean | null,
  managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
  authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
  phone?: string | null,
  email?: string | null,
  consentToContactForAppointmentBySMS?: ConsentInput | null,
  billingCustomerID?: string | null,
  consentToContactForResearch?: ConsentInput | null,
  consentToContactForMarketing?: ConsentInput | null,
  outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
  stripeCustomerID?: string | null,
  isArchived?: boolean | null,
};

export type DeleteCoordinatorInput = {
  id: string,
};

export type CreatePatientInput = {
  id?: string | null,
  csrPartnerOrganization?: PartnerOrganization | null,
  partnerOrganization?: PartnerOrganization | null,
  location?: LocationInput | null,
  coordinatorID: string,
  medicareCardID?: string | null,
  imageMoverUserID?: string | null,
  externalID?: string | null,
  secondaryExternalID?: string | null,
  specimenLabelID?: string | null,
  qtMedicalPatientID?: string | null,
  planName?: string | null,
  diabeticType?: DiabeticType | null,
  authorizedTechIDs?: Array< string > | null,
  isCoordinator: boolean,
  phone?: string | null,
  secondaryPhone?: string | null,
  email?: string | null,
  consentToContactForAppointmentBySMS?: ConsentInput | null,
  consentToContactForAppointmentByEmail?: ConsentInput | null,
  name: HumanNameInput,
  birthdate?: string | null,
  sex?: Sex | null,
  gender?: Gender | null,
  ethnicity?: Ethnicity | null,
  races?: Array< Race > | null,
  passportNumber?: string | null,
  insuranceCardUploadFront?: S3ObjectInput | null,
  insuranceCardUploadBack?: S3ObjectInput | null,
  insuranceCardUploadFrontS3ObjectID?: string | null,
  insuranceCardUploadBackS3ObjectID?: string | null,
  medicareCardUploadFront?: S3ObjectInput | null,
  medicareCardUploadBack?: S3ObjectInput | null,
  medicareCardUploadFrontS3ObjectID?: string | null,
  medicareCardUploadBackS3ObjectID?: string | null,
  commentsFromTechsAboutEncounters?: Array< CommentsFromTechAboutEncounterInput > | null,
  bloodCollectionDetailsFromEncounters?: Array< SpecimenCollectionDetailsFromEncounterInput > | null,
  capillaryDrawCollectionDetailsFromEncounters?: Array< SpecimenCollectionDetailsFromEncounterInput > | null,
  watchlistedAppointmentID?: string | null,
  responsiblePractitionerID?: string | null,
  consentToContactForMarketingSMS?: ConsentInput | null,
  consentToContactForMarketingEmail?: ConsentInput | null,
  consentToContactForMarketingPhone?: ConsentInput | null,
  suggestedAppointmentLocation?: LocationInput | null,
  suggestedAppointmentLocationAdditionalInformation?: string | null,
  mailingAddress?: AddressInput | null,
  preferredLanguage?: string | null,
  primaryLanguageBCP47?: string | null,
  secondaryLanguagesBCP47?: Array< string > | null,
  caregiverName?: HumanNameInput | null,
  commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
  commentsFromCaregiverAboutResponsiblePractitioner?: string | null,
  // A unique numerical patient reference number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
  patientNumber?: string | null,
  labOrganizationPatientIDs?: Array< LabOrganizationPatientIDInput > | null,
  latestGapListUploadID?: string | null,
  latestGapListRowJSON?: string | null,
  latestGapListPassthroughJSON?: string | null,
  brandCode?: string | null,
  population?: PatientPopulation | null,
  elationID?: string | null,
  consents?: Array< ConsentFormInput > | null,
};

export type CommentsFromTechAboutEncounterInput = {
  encounterID: string,
  appointmentStart: string,
  techName: HumanNameInput,
  comments?: string | null,
};

export type SpecimenCollectionDetailsFromEncounterInput = {
  encounterID: string,
  appointmentStart: string,
  techName: HumanNameInput,
  specimenCollectionDetails: SpecimenCollectionDetailsInput,
  failureReason?: ServiceFailureReason | null,
};

export type LabOrganizationPatientIDInput = {
  labOrganization: LabOrganization,
  externalID: string,
  planNameForLabOrdering: string,
};

export type ConsentFormInput = {
  consentedAt: string,
  type: ConsentType,
  document?: DocumentInput | null,
  platform: QuestionnairePlatform,
  formID: string,
  responseID: string,
  appointmentID: string,
  rawResponseJSON: string,
};

export type ModelPatientConditionInput = {
  csrPartnerOrganization?: ModelPartnerOrganizationInput | null,
  partnerOrganization?: ModelPartnerOrganizationInput | null,
  coordinatorID?: ModelIDInput | null,
  medicareCardID?: ModelStringInput | null,
  imageMoverUserID?: ModelIDInput | null,
  externalID?: ModelStringInput | null,
  secondaryExternalID?: ModelStringInput | null,
  specimenLabelID?: ModelStringInput | null,
  qtMedicalPatientID?: ModelIDInput | null,
  planName?: ModelStringInput | null,
  diabeticType?: ModelDiabeticTypeInput | null,
  authorizedTechIDs?: ModelIDInput | null,
  isCoordinator?: ModelBooleanInput | null,
  phone?: ModelStringInput | null,
  email?: ModelStringInput | null,
  secondaryPhone?: ModelStringInput | null,
  birthdate?: ModelStringInput | null,
  sex?: ModelSexInput | null,
  gender?: ModelGenderInput | null,
  ethnicity?: ModelEthnicityInput | null,
  races?: ModelRaceListInput | null,
  passportNumber?: ModelStringInput | null,
  insuranceCardUploadFrontS3ObjectID?: ModelIDInput | null,
  insuranceCardUploadBackS3ObjectID?: ModelIDInput | null,
  medicareCardUploadFrontS3ObjectID?: ModelIDInput | null,
  medicareCardUploadBackS3ObjectID?: ModelIDInput | null,
  watchlistedAppointmentID?: ModelIDInput | null,
  responsiblePractitionerID?: ModelIDInput | null,
  commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: ModelStringInput | null,
  commentsFromCaregiverAboutResponsiblePractitioner?: ModelStringInput | null,
  suggestedAppointmentLocationAdditionalInformation?: ModelStringInput | null,
  preferredLanguage?: ModelStringInput | null,
  primaryLanguageBCP47?: ModelStringInput | null,
  secondaryLanguagesBCP47?: ModelStringInput | null,
  patientNumber?: ModelStringInput | null,
  latestGapListUploadID?: ModelStringInput | null,
  latestGapListRowJSON?: ModelStringInput | null,
  latestGapListPassthroughJSON?: ModelStringInput | null,
  brandCode?: ModelStringInput | null,
  population?: ModelPatientPopulationInput | null,
  elationID?: ModelStringInput | null,
  and?: Array< ModelPatientConditionInput | null > | null,
  or?: Array< ModelPatientConditionInput | null > | null,
  not?: ModelPatientConditionInput | null,
};

export type ModelDiabeticTypeInput = {
  eq?: DiabeticType | null,
  ne?: DiabeticType | null,
};

export type ModelSexInput = {
  eq?: Sex | null,
  ne?: Sex | null,
};

export type ModelEthnicityInput = {
  eq?: Ethnicity | null,
  ne?: Ethnicity | null,
};

export type ModelRaceListInput = {
  eq?: Array< Race | null > | null,
  ne?: Array< Race | null > | null,
  contains?: Race | null,
  notContains?: Race | null,
};

export type ModelPatientPopulationInput = {
  eq?: PatientPopulation | null,
  ne?: PatientPopulation | null,
};

export type UpdatePatientInput = {
  id: string,
  csrPartnerOrganization?: PartnerOrganization | null,
  partnerOrganization?: PartnerOrganization | null,
  location?: LocationInput | null,
  coordinatorID?: string | null,
  medicareCardID?: string | null,
  imageMoverUserID?: string | null,
  externalID?: string | null,
  secondaryExternalID?: string | null,
  specimenLabelID?: string | null,
  labOrganizationPatientIDs?: Array< LabOrganizationPatientIDInput > | null,
  qtMedicalPatientID?: string | null,
  planName?: string | null,
  diabeticType?: DiabeticType | null,
  authorizedTechIDs?: Array< string > | null,
  isCoordinator?: boolean | null,
  phone?: string | null,
  email?: string | null,
  secondaryPhone?: string | null,
  consentToContactForAppointmentBySMS?: ConsentInput | null,
  consentToContactForAppointmentByEmail?: ConsentInput | null,
  name?: HumanNameInput | null,
  caregiverName?: HumanNameInput | null,
  birthdate?: string | null,
  sex?: Sex | null,
  gender?: Gender | null,
  ethnicity?: Ethnicity | null,
  races?: Array< Race > | null,
  passportNumber?: string | null,
  insuranceCardUploadFront?: S3ObjectInput | null,
  insuranceCardUploadBack?: S3ObjectInput | null,
  insuranceCardUploadFrontS3ObjectID?: string | null,
  insuranceCardUploadBackS3ObjectID?: string | null,
  medicareCardUploadFront?: S3ObjectInput | null,
  medicareCardUploadBack?: S3ObjectInput | null,
  medicareCardUploadFrontS3ObjectID?: string | null,
  medicareCardUploadBackS3ObjectID?: string | null,
  commentsFromTechsAboutEncounters?: Array< CommentsFromTechAboutEncounterInput > | null,
  bloodCollectionDetailsFromEncounters?: Array< SpecimenCollectionDetailsFromEncounterInput > | null,
  capillaryDrawCollectionDetailsFromEncounters?: Array< SpecimenCollectionDetailsFromEncounterInput > | null,
  watchlistedAppointmentID?: string | null,
  responsiblePractitionerID?: string | null,
  commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
  commentsFromCaregiverAboutResponsiblePractitioner?: string | null,
  consentToContactForMarketingSMS?: ConsentInput | null,
  consentToContactForMarketingEmail?: ConsentInput | null,
  consentToContactForMarketingPhone?: ConsentInput | null,
  suggestedAppointmentLocation?: LocationInput | null,
  suggestedAppointmentLocationAdditionalInformation?: string | null,
  mailingAddress?: AddressInput | null,
  preferredLanguage?: string | null,
  primaryLanguageBCP47?: string | null,
  secondaryLanguagesBCP47?: Array< string > | null,
  patientNumber?: string | null,
  latestGapListUploadID?: string | null,
  latestGapListRowJSON?: string | null,
  latestGapListPassthroughJSON?: string | null,
  brandCode?: string | null,
  population?: PatientPopulation | null,
  elationID?: string | null,
  consents?: Array< ConsentFormInput > | null,
};

export type DeletePatientInput = {
  id: string,
};

export type CreatePatientContactPointInput = {
  id?: string | null,
  patientID: string,
  system: ContactPointSystem,
  value: string,
  status: ContactPointStatus,
  statusLastUpdatedAt?: string | null,
  sources?: Array< PatientContactPointSourceInput > | null,
};

export type PatientContactPointSourceInput = {
  source: ContactPointSource,
  rank?: number | null,
  system: ContactPointSystem,
  reportedAsLastSeenAt?: string | null,
  firstReportedAt?: string | null,
  lastReportedAt?: string | null,
  metadataJSON?: string | null,
};

export type ModelPatientContactPointConditionInput = {
  patientID?: ModelIDInput | null,
  system?: ModelContactPointSystemInput | null,
  value?: ModelStringInput | null,
  status?: ModelContactPointStatusInput | null,
  statusLastUpdatedAt?: ModelStringInput | null,
  and?: Array< ModelPatientContactPointConditionInput | null > | null,
  or?: Array< ModelPatientContactPointConditionInput | null > | null,
  not?: ModelPatientContactPointConditionInput | null,
};

export type ModelContactPointSystemInput = {
  eq?: ContactPointSystem | null,
  ne?: ContactPointSystem | null,
};

export type ModelContactPointStatusInput = {
  eq?: ContactPointStatus | null,
  ne?: ContactPointStatus | null,
};

export type UpdatePatientContactPointInput = {
  id: string,
  patientID?: string | null,
  system?: ContactPointSystem | null,
  value?: string | null,
  status?: ContactPointStatus | null,
  statusLastUpdatedAt?: string | null,
  sources?: Array< PatientContactPointSourceInput > | null,
};

export type DeletePatientContactPointInput = {
  id: string,
};

export type CreateReferralInput = {
  id?: string | null,
  patientID: string,
  partnerOrganization: PartnerOrganization,
  requisitions?: Array< RequisitionInput > | null,
  requisitionUploads?: Array< S3ObjectInput > | null,
  requisitionUploadsS3ObjectIDs?: Array< string > | null,
  serviceTypes: Array< ServiceType >,
  diagnosisCodes?: Array< CodingInput > | null,
  isBooked?: boolean | null,
  isExpired?: boolean | null,
  outreachOutcomesPerServiceRequest?: Array< ServiceRequestOutreachOutcomeInput > | null,
  ttl?: number | null,
};

export type ServiceRequestOutreachOutcomeInput = {
  serviceRequestExternalID: string,
  productType?: ProductType | null,
  serviceTypes?: Array< ServiceType > | null,
  labOrganization: LabOrganization,
  outcome: string,
  notes?: string | null,
};

export type ModelReferralConditionInput = {
  patientID?: ModelIDInput | null,
  partnerOrganization?: ModelPartnerOrganizationInput | null,
  requisitionUploadS3ObjectIDs?: ModelIDInput | null,
  serviceTypes?: ModelServiceTypeInput | null,
  isBooked?: ModelBooleanInput | null,
  isExpired?: ModelBooleanInput | null,
  ttl?: ModelIntInput | null,
  and?: Array< ModelReferralConditionInput | null > | null,
  or?: Array< ModelReferralConditionInput | null > | null,
  not?: ModelReferralConditionInput | null,
};

export type ModelServiceTypeInput = {
  eq?: ServiceType | null,
  ne?: ServiceType | null,
};

export type UpdateReferralInput = {
  id: string,
  patientID?: string | null,
  partnerOrganization?: PartnerOrganization | null,
  requisitions?: Array< RequisitionInput > | null,
  requisitionUploads?: Array< S3ObjectInput > | null,
  requisitionUploadS3ObjectIDs?: Array< string > | null,
  serviceTypes?: Array< ServiceType > | null,
  diagnosisCodes?: Array< CodingInput > | null,
  isBooked?: boolean | null,
  isExpired?: boolean | null,
  outreachOutcomesPerServiceRequest?: Array< ServiceRequestOutreachOutcomeInput > | null,
  ttl?: number | null,
};

export type DeleteReferralInput = {
  id: string,
};

export type CreatePatientCampaignStateInput = {
  id?: string | null,
  patientID: string,
  name: PatientCampaignStateName,
  campaignName: CampaignName,
  eligibilityStart: string,
  eligibilityEnd: string,
  cohort?: number | null,
  contextJSON: string,
  enteredAt: number,
  partnerOrganization: PartnerOrganization,
  previousStateName?: PatientCampaignStateName | null,
  reasonForTransitionToTerminalState?: ReasonForTransitionToTerminalState | null,
};

export type ModelPatientCampaignStateConditionInput = {
  patientID?: ModelIDInput | null,
  name?: ModelPatientCampaignStateNameInput | null,
  campaignName?: ModelCampaignNameInput | null,
  eligibilityStart?: ModelStringInput | null,
  eligibilityEnd?: ModelStringInput | null,
  cohort?: ModelIntInput | null,
  contextJSON?: ModelStringInput | null,
  enteredAt?: ModelIntInput | null,
  partnerOrganization?: ModelPartnerOrganizationInput | null,
  previousStateName?: ModelPatientCampaignStateNameInput | null,
  reasonForTransitionToTerminalState?: ModelReasonForTransitionToTerminalStateInput | null,
  and?: Array< ModelPatientCampaignStateConditionInput | null > | null,
  or?: Array< ModelPatientCampaignStateConditionInput | null > | null,
  not?: ModelPatientCampaignStateConditionInput | null,
};

export type ModelPatientCampaignStateNameInput = {
  eq?: PatientCampaignStateName | null,
  ne?: PatientCampaignStateName | null,
};

export type ModelCampaignNameInput = {
  eq?: CampaignName | null,
  ne?: CampaignName | null,
};

export type ModelReasonForTransitionToTerminalStateInput = {
  eq?: ReasonForTransitionToTerminalState | null,
  ne?: ReasonForTransitionToTerminalState | null,
};

export type UpdatePatientCampaignStateInput = {
  id: string,
  patientID?: string | null,
  name?: PatientCampaignStateName | null,
  campaignName?: CampaignName | null,
  eligibilityStart?: string | null,
  eligibilityEnd?: string | null,
  cohort?: number | null,
  contextJSON?: string | null,
  enteredAt?: number | null,
  partnerOrganization?: PartnerOrganization | null,
  previousStateName?: PatientCampaignStateName | null,
  reasonForTransitionToTerminalState?: ReasonForTransitionToTerminalState | null,
};

export type DeletePatientCampaignStateInput = {
  id: string,
};

export type CreateProjectedEngagementActivityScheduleInput = {
  id?: string | null,
  name: string,
  status: ProjectedEngagementActivityScheduleStatus,
  acceptedAt?: string | null,
  totalBatches?: number | null,
  processedBatches?: number | null,
  parametersJSON?: string | null,
};

export enum ProjectedEngagementActivityScheduleStatus {
  PROCESSING = "PROCESSING",
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  ARCHIVED = "ARCHIVED", // The schedule has been archived and is no longer in use. It is still available in S3 but has been removed from OpenSearch.
}


export type ModelProjectedEngagementActivityScheduleConditionInput = {
  name?: ModelStringInput | null,
  status?: ModelProjectedEngagementActivityScheduleStatusInput | null,
  acceptedAt?: ModelStringInput | null,
  totalBatches?: ModelIntInput | null,
  processedBatches?: ModelIntInput | null,
  parametersJSON?: ModelStringInput | null,
  and?: Array< ModelProjectedEngagementActivityScheduleConditionInput | null > | null,
  or?: Array< ModelProjectedEngagementActivityScheduleConditionInput | null > | null,
  not?: ModelProjectedEngagementActivityScheduleConditionInput | null,
};

export type ModelProjectedEngagementActivityScheduleStatusInput = {
  eq?: ProjectedEngagementActivityScheduleStatus | null,
  ne?: ProjectedEngagementActivityScheduleStatus | null,
};

export type ProjectedEngagementActivitySchedule = {
  __typename: "ProjectedEngagementActivitySchedule",
  id: string,
  // A friendly(-ish) name for the schedule.
  name: string,
  // The status of the schedule. This largely serves as a partition key so we can query for schedules sorted by accepted at.
  status: ProjectedEngagementActivityScheduleStatus,
  acceptedAt?: string | null,
  totalBatches?: number | null,
  processedBatches?: number | null,
  parametersJSON?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateProjectedEngagementActivityScheduleInput = {
  id: string,
  name?: string | null,
  status?: ProjectedEngagementActivityScheduleStatus | null,
  acceptedAt?: string | null,
  totalBatches?: number | null,
  processedBatches?: number | null,
  parametersJSON?: string | null,
};

export type DeleteProjectedEngagementActivityScheduleInput = {
  id: string,
};

export type CreateUnlinkableReportInput = {
  id?: string | null,
  externalID: string,
  documents: Array< DocumentInput >,
  zendeskTicketID?: number | null,
  unlinkedReason: UnlinkedReason,
  hasBeenResolved?: boolean | null,
  patientName?: HumanNameInput | null,
  patientNumber?: string | null,
  requisitionNumber?: string | null,
};

export enum UnlinkedReason {
  UNKNOWN_REPORT_STATUS = "UNKNOWN_REPORT_STATUS",
  REPORT_IN_CANCELED_STATE = "REPORT_IN_CANCELED_STATE",
  REPORT_IN_CORRECTED_STATE = "REPORT_IN_CORRECTED_STATE",
  MISSING_PDF_AND_HL7_DOCUMENTS = "MISSING_PDF_AND_HL7_DOCUMENTS",
  MISSING_PDF_DOCUMENT = "MISSING_PDF_DOCUMENT",
  MISSING_HL7_DOCUMENT = "MISSING_HL7_DOCUMENT",
  MULTIPLE_PDF_DOCUMENTS = "MULTIPLE_PDF_DOCUMENTS",
  MULTIPLE_HL7_DOCUMENTS = "MULTIPLE_HL7_DOCUMENTS",
  UNABLE_TO_PARSE_HL7_DOCUMENT = "UNABLE_TO_PARSE_HL7_DOCUMENT",
  NO_OBSERVATIONS_IN_HL7_DOCUMENT = "NO_OBSERVATIONS_IN_HL7_DOCUMENT",
  ERROR_WHEN_PROCESSING_DOCUMENT_OR_OBSERVATIONS = "ERROR_WHEN_PROCESSING_DOCUMENT_OR_OBSERVATIONS",
  UNEXPECTED_ERROR_WHEN_MATCHING_PATIENTS_AND_ENCOUNTER = "UNEXPECTED_ERROR_WHEN_MATCHING_PATIENTS_AND_ENCOUNTER",
  MISSING_PATIENT_AND_REQUISITON_IDENTIFIER = "MISSING_PATIENT_AND_REQUISITON_IDENTIFIER",
  MISSING_PATIENT_FULL_NAME = "MISSING_PATIENT_FULL_NAME",
  FOUND_MULTIPLE_POSSIBLE_PATIENTS = "FOUND_MULTIPLE_POSSIBLE_PATIENTS",
  MISMATCHING_PATIENT_NAME = "MISMATCHING_PATIENT_NAME",
  MISMATCHING_DATE_OF_BIRTH = "MISMATCHING_DATE_OF_BIRTH",
  UNABLE_TO_MATCH_PATIENT = "UNABLE_TO_MATCH_PATIENT",
  MATCHED_PATIENT_BUT_NO_ENCOUNTER_FOUND = "MATCHED_PATIENT_BUT_NO_ENCOUNTER_FOUND",
  FOUND_MULTIPLE_POSSIBLE_ENCOUNTERS = "FOUND_MULTIPLE_POSSIBLE_ENCOUNTERS",
  FOUND_NO_POSSIBLE_PATIENTS_OR_ENCOUNTERS = "FOUND_NO_POSSIBLE_PATIENTS_OR_ENCOUNTERS",
  MATCHED_ENCOUNTER_DOES_NOT_HAVE_MATCHING_SERVICES = "MATCHED_ENCOUNTER_DOES_NOT_HAVE_MATCHING_SERVICES",
}


export type ModelUnlinkableReportConditionInput = {
  externalID?: ModelStringInput | null,
  zendeskTicketID?: ModelIntInput | null,
  unlinkedReason?: ModelUnlinkedReasonInput | null,
  hasBeenResolved?: ModelBooleanInput | null,
  patientNumber?: ModelStringInput | null,
  requisitionNumber?: ModelStringInput | null,
  and?: Array< ModelUnlinkableReportConditionInput | null > | null,
  or?: Array< ModelUnlinkableReportConditionInput | null > | null,
  not?: ModelUnlinkableReportConditionInput | null,
};

export type ModelUnlinkedReasonInput = {
  eq?: UnlinkedReason | null,
  ne?: UnlinkedReason | null,
};

export type UnlinkableReport = {
  __typename: "UnlinkableReport",
  id: string,
  externalID: string,
  documents:  Array<Document >,
  zendeskTicketID?: number | null,
  unlinkedReason: UnlinkedReason,
  hasBeenResolved?: boolean | null,
  patientName?: HumanName | null,
  patientNumber?: string | null,
  requisitionNumber?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateUnlinkableReportInput = {
  id: string,
  externalID?: string | null,
  documents?: Array< DocumentInput > | null,
  zendeskTicketID?: number | null,
  unlinkedReason?: UnlinkedReason | null,
  hasBeenResolved?: boolean | null,
  patientName?: HumanNameInput | null,
  patientNumber?: string | null,
  requisitionNumber?: string | null,
};

export type DeleteUnlinkableReportInput = {
  id: string,
};

export type CreateDropOffInput = {
  id?: string | null,
  organization: DropOffOrganization,
  name: string,
  phone?: string | null,
  email?: string | null,
  location: LocationInput,
  timezone?: Timezone | null,
  availabilityRules: Array< TimeRangeRuleInput >,
  hasParking?: boolean | null,
  hasDropBox?: boolean | null,
  commentsFromScheduler?: string | null,
  googleMapsPlaceID?: string | null,
  metroName: string,
  isActive: boolean,
};

export enum DropOffOrganization {
  BIO_REFERENCE = "BIO_REFERENCE",
  FEDEX = "FEDEX",
  INTERMOUNTAIN = "INTERMOUNTAIN",
  INTERMOUNTAIN_CANCER_CENTER = "INTERMOUNTAIN_CANCER_CENTER",
  LABCORP = "LABCORP",
  MARSHALL_LABORATORY = "MARSHALL_LABORATORY",
  MUIRLAB = "MUIRLAB",
  QUEST = "QUEST",
  SPRINTER_HEALTH = "SPRINTER_HEALTH",
  STANFORD = "STANFORD",
  STANFORD_CANCER_CENTER = "STANFORD_CANCER_CENTER",
  UCSF_BLOOD_DRAW_LAB = "UCSF_BLOOD_DRAW_LAB",
  UCI_LAB = "UCI_LAB",
  WEST_PAC = "WEST_PAC",
  DOTHOUSE_LAB = "DOTHOUSE_LAB",
  CODMAN_LAB = "CODMAN_LAB",
  BMC_LAB = "BMC_LAB",
  ROSLINDALE_LAB = "ROSLINDALE_LAB",
  KAISER = "KAISER",
  STANFORD_RESEARCH = "STANFORD_RESEARCH",
  TRAINING_PARTNER = "TRAINING_PARTNER",
  USPS = "USPS",
}


export type ModelDropOffConditionInput = {
  organization?: ModelDropOffOrganizationInput | null,
  name?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  email?: ModelStringInput | null,
  timezone?: ModelTimezoneInput | null,
  hasParking?: ModelBooleanInput | null,
  hasDropBox?: ModelBooleanInput | null,
  commentsFromScheduler?: ModelStringInput | null,
  googleMapsPlaceID?: ModelStringInput | null,
  metroName?: ModelStringInput | null,
  isActive?: ModelBooleanInput | null,
  and?: Array< ModelDropOffConditionInput | null > | null,
  or?: Array< ModelDropOffConditionInput | null > | null,
  not?: ModelDropOffConditionInput | null,
};

export type ModelDropOffOrganizationInput = {
  eq?: DropOffOrganization | null,
  ne?: DropOffOrganization | null,
};

export type DropOff = {
  __typename: "DropOff",
  id: string,
  organization: DropOffOrganization,
  name: string,
  phone?: string | null,
  email?: string | null,
  location: Location,
  // Timezone generated based on the lat/long location of this dropoff
  timezone?: Timezone | null,
  availabilityRules:  Array<TimeRangeRule >,
  hasParking?: boolean | null,
  hasDropBox?: boolean | null,
  commentsFromScheduler?: string | null,
  googleMapsPlaceID?: string | null,
  metroName: string,
  isActive: boolean,
  createdAt: string,
  updatedAt: string,
};

export type UpdateDropOffInput = {
  id: string,
  organization?: DropOffOrganization | null,
  name?: string | null,
  phone?: string | null,
  email?: string | null,
  location?: LocationInput | null,
  timezone?: Timezone | null,
  availabilityRules?: Array< TimeRangeRuleInput > | null,
  hasParking?: boolean | null,
  hasDropBox?: boolean | null,
  commentsFromScheduler?: string | null,
  googleMapsPlaceID?: string | null,
  metroName?: string | null,
  isActive?: boolean | null,
};

export type DeleteDropOffInput = {
  id: string,
};

export type CreateServiceRequestTypeInput = {
  id?: string | null,
  cptCodes: Array< string | null >,
  externalID: string,
  displayName: string,
  synonyms?: Array< string | null > | null,
  labOrganization: LabOrganization,
  container?: string | null,
  serviceRequestTypeContainerID?: string | null,
  specimen?: string | null,
  preferredSpecimen?: string | null,
  preferredVolumeDescription?: string | null,
  preferredVolumeMilliliters?: number | null,
  minimumVolume?: string | null,
  minimumVolumeAmount?: number | null,
  alternateSpecimen?: string | null,
  transportInstructions?: string | null,
  collectionInstructions?: string | null,
  collectionInstructionsVideoURL?: string | null,
  rejectionCriteria?: string | null,
  specimenStability?: string | null,
  patientPreparation?: string | null,
  resultAvailability?: string | null,
  serviceTypes?: Array< ServiceType > | null,
  containerSpecimenVolumes?: Array< SpecimenContainerSpecimenVolumesInput > | null,
  abstentions?: Array< AbstentionInput > | null,
  customKit?: ServiceRequestCustomKitInput | null,
  collectionTimeRules?: Array< TimeRangeRuleInput > | null,
  requiresBinaryPatientSex?: boolean | null,
};

export type SpecimenContainerSpecimenVolumesInput = {
  containerOverrideID?: string | null,
  containerCategory?: SpecimenContainerCategory | null,
  minimumMilliliters?: number | null,
  preferredMilliliters?: number | null,
  isCombinable: boolean,
  minimumContainerCount?: number | null,
  specimenTestTypes?: Array< SpecimenTestType | null > | null,
};

export type AbstentionInput = {
  abstentionType: AbstentionType,
  preferredDurationMinutes: number,
  requiredDurationMinutes?: number | null,
};

export type ServiceRequestCustomKitInput = {
  photoURL: string,
  barcodePhotoURL?: string | null,
  recipient: CustomKitRecipient,
  requiresTRFCompletion: boolean,
  requiresBCFCompletion: boolean,
};

export type ModelServiceRequestTypeConditionInput = {
  cptCodes?: ModelStringInput | null,
  externalID?: ModelStringInput | null,
  displayName?: ModelStringInput | null,
  synonyms?: ModelStringInput | null,
  labOrganization?: ModelLabOrganizationInput | null,
  container?: ModelStringInput | null,
  serviceRequestTypeContainerID?: ModelIDInput | null,
  specimen?: ModelStringInput | null,
  preferredSpecimen?: ModelStringInput | null,
  preferredVolumeDescription?: ModelStringInput | null,
  preferredVolumeMilliliters?: ModelFloatInput | null,
  minimumVolume?: ModelStringInput | null,
  minimumVolumeAmount?: ModelFloatInput | null,
  alternateSpecimen?: ModelStringInput | null,
  transportInstructions?: ModelStringInput | null,
  collectionInstructions?: ModelStringInput | null,
  collectionInstructionsVideoURL?: ModelStringInput | null,
  rejectionCriteria?: ModelStringInput | null,
  specimenStability?: ModelStringInput | null,
  patientPreparation?: ModelStringInput | null,
  resultAvailability?: ModelStringInput | null,
  serviceTypes?: ModelServiceTypeListInput | null,
  requiresBinaryPatientSex?: ModelBooleanInput | null,
  and?: Array< ModelServiceRequestTypeConditionInput | null > | null,
  or?: Array< ModelServiceRequestTypeConditionInput | null > | null,
  not?: ModelServiceRequestTypeConditionInput | null,
};

export type ModelLabOrganizationInput = {
  eq?: LabOrganization | null,
  ne?: LabOrganization | null,
};

export type UpdateServiceRequestTypeInput = {
  id: string,
  cptCodes?: Array< string | null > | null,
  externalID?: string | null,
  displayName?: string | null,
  synonyms?: Array< string | null > | null,
  labOrganization?: LabOrganization | null,
  container?: string | null,
  serviceRequestTypeContainerID?: string | null,
  specimen?: string | null,
  preferredSpecimen?: string | null,
  preferredVolumeDescription?: string | null,
  preferredVolumeMilliliters?: number | null,
  minimumVolume?: string | null,
  minimumVolumeAmount?: number | null,
  alternateSpecimen?: string | null,
  transportInstructions?: string | null,
  collectionInstructions?: string | null,
  collectionInstructionsVideoURL?: string | null,
  rejectionCriteria?: string | null,
  specimenStability?: string | null,
  patientPreparation?: string | null,
  resultAvailability?: string | null,
  serviceTypes?: Array< ServiceType > | null,
  containerSpecimenVolumes?: Array< SpecimenContainerSpecimenVolumesInput > | null,
  abstentions?: Array< AbstentionInput > | null,
  customKit?: ServiceRequestCustomKitInput | null,
  collectionTimeRules?: Array< TimeRangeRuleInput > | null,
  requiresBinaryPatientSex?: boolean | null,
};

export type DeleteServiceRequestTypeInput = {
  id: string,
};

export type CreateSpecimenContainerTypeInput = {
  id?: string | null,
  name: string,
  category: SpecimenContainerCategory,
  capacityMilliliters: number,
  serumBloodCapacityMilliliters?: number | null,
};

export type ModelSpecimenContainerTypeConditionInput = {
  name?: ModelStringInput | null,
  category?: ModelSpecimenContainerCategoryInput | null,
  capacityMilliliters?: ModelFloatInput | null,
  serumBloodCapacityMilliliters?: ModelFloatInput | null,
  and?: Array< ModelSpecimenContainerTypeConditionInput | null > | null,
  or?: Array< ModelSpecimenContainerTypeConditionInput | null > | null,
  not?: ModelSpecimenContainerTypeConditionInput | null,
};

export type ModelSpecimenContainerCategoryInput = {
  eq?: SpecimenContainerCategory | null,
  ne?: SpecimenContainerCategory | null,
};

export type SpecimenContainerType = {
  __typename: "SpecimenContainerType",
  id: string,
  name: string,
  category: SpecimenContainerCategory,
  // The whole capacity of the container. This would be the "whole blood capacity" for SST containers and the
  // general capacity for the containers which are not used for serum tests like Urine Cups and the Insure FOBT.
  capacityMilliliters: number,
  // Serum Capacity. The capacity of the container. Usually this is the volume of the container,
  // but in some cases the relevant capacity might reflect only a portion of the overall volume
  // (e.g. serum capacity in an SST, where the remaining volume is filled with clotted blood components).
  serumBloodCapacityMilliliters?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateSpecimenContainerTypeInput = {
  id: string,
  name?: string | null,
  category?: SpecimenContainerCategory | null,
  capacityMilliliters?: number | null,
  serumBloodCapacityMilliliters?: number | null,
};

export type DeleteSpecimenContainerTypeInput = {
  id: string,
};

export type CreateServiceRequestTypeContainerInput = {
  id?: string | null,
  containerType: string,
  alternativeContainerTypes?: Array< string > | null,
  substituteContainerTypesForCalculations?: Array< string > | null,
  containerCategory: string,
  capacity: number,
};

export type ModelServiceRequestTypeContainerConditionInput = {
  containerType?: ModelStringInput | null,
  alternativeContainerTypes?: ModelStringInput | null,
  substituteContainerTypesForCalculations?: ModelStringInput | null,
  containerCategory?: ModelStringInput | null,
  capacity?: ModelFloatInput | null,
  and?: Array< ModelServiceRequestTypeContainerConditionInput | null > | null,
  or?: Array< ModelServiceRequestTypeContainerConditionInput | null > | null,
  not?: ModelServiceRequestTypeContainerConditionInput | null,
};

export type UpdateServiceRequestTypeContainerInput = {
  id: string,
  containerType?: string | null,
  alternativeContainerTypes?: Array< string > | null,
  substituteContainerTypesForCalculations?: Array< string > | null,
  containerCategory?: string | null,
  capacity?: number | null,
};

export type DeleteServiceRequestTypeContainerInput = {
  id: string,
};

export type CreateSMSInput = {
  id?: string | null,
  type: SMSType,
  contextJSON: string,
  ttl?: number | null,
};

export enum SMSType {
  GENERIC_UNEXPECTED_RESPONSE = "GENERIC_UNEXPECTED_RESPONSE",
  BOOKING_CONFIRMATION = "BOOKING_CONFIRMATION",
  BOOKING_CONFIRMATION_AWV_PRE_WORK_FOCUSED = "BOOKING_CONFIRMATION_AWV_PRE_WORK_FOCUSED",
  BOOKING_CONFIRMATION_YES_RESPONSE = "BOOKING_CONFIRMATION_YES_RESPONSE",
  BOOKING_CONFIRMATION_RESCHEDULE_RESPONSE = "BOOKING_CONFIRMATION_RESCHEDULE_RESPONSE",
  BOOKING_CONFIRMATION_CANCEL_RESPONSE = "BOOKING_CONFIRMATION_CANCEL_RESPONSE",
  BOOKING_CONFIRMATION_REMINDER = "BOOKING_CONFIRMATION_REMINDER",
  BOOKING_CONFIRMATION_INVALID_RESPONSE = "BOOKING_CONFIRMATION_INVALID_RESPONSE",
  BOOKING_CONFIRMATION_ALREADY_CANCELED_RESPONSE = "BOOKING_CONFIRMATION_ALREADY_CANCELED_RESPONSE",
  CANCEL_CONFIRMATION = "CANCEL_CONFIRMATION",
  RESCHEDULE_CONFIRMATION = "RESCHEDULE_CONFIRMATION",
  DAY_BEFORE_REMINDER = "DAY_BEFORE_REMINDER",
  DAY_BEFORE_REMINDER_AWV_PRE_WORK_FOCUSED = "DAY_BEFORE_REMINDER_AWV_PRE_WORK_FOCUSED",
  DAY_OF_REMINDER = "DAY_OF_REMINDER",
  COLLECT_KIT_REMINDER = "COLLECT_KIT_REMINDER",
  DEPARTED_TO_APPOINTMENT = "DEPARTED_TO_APPOINTMENT",
  RUNNING_LATE = "RUNNING_LATE",
  ARRIVED_AT_APPOINTMENT = "ARRIVED_AT_APPOINTMENT",
  DEPARTED_TO_LAB = "DEPARTED_TO_LAB",
  TRANSFERRED_TO_LAB = "TRANSFERRED_TO_LAB",
  POST_APPOINTMENT = "POST_APPOINTMENT",
  POST_APPOINTMENT_COLLECT_KIT_REMINDER = "POST_APPOINTMENT_COLLECT_KIT_REMINDER",
  TWO_DAYS_BEFORE_REMINDER = "TWO_DAYS_BEFORE_REMINDER",
  PATIENT_ENCOUNTER_SUMMARY = "PATIENT_ENCOUNTER_SUMMARY",
  PATIENT_OUTREACH = "PATIENT_OUTREACH",
  PATIENT_OUTREACH_MORE_INFO_RESPONSE = "PATIENT_OUTREACH_MORE_INFO_RESPONSE",
  PATIENT_OUTREACH_MORE_INFO_RESPONSE_FOLLOW_UP = "PATIENT_OUTREACH_MORE_INFO_RESPONSE_FOLLOW_UP",
  PATIENT_OUTREACH_INITIAL_EASE = "PATIENT_OUTREACH_INITIAL_EASE",
  PATIENT_OUTREACH_INITIAL_TEASER = "PATIENT_OUTREACH_INITIAL_TEASER",
  PATIENT_OUTREACH_INITIAL_EMPATHETIC = "PATIENT_OUTREACH_INITIAL_EMPATHETIC",
  PATIENT_OUTREACH_FOLLOW_UP_EMPATHETIC = "PATIENT_OUTREACH_FOLLOW_UP_EMPATHETIC",
  PATIENT_OUTREACH_FOLLOW_UP_NO_VOICEMAIL_TEASER = "PATIENT_OUTREACH_FOLLOW_UP_NO_VOICEMAIL_TEASER",
  PATIENT_OUTREACH_FOLLOW_UP_LEFT_VOICEMAIL_TEASER = "PATIENT_OUTREACH_FOLLOW_UP_LEFT_VOICEMAIL_TEASER",
  PATIENT_OUTREACH_FOLLOW_UP_NO_VOICEMAIL_AUTHORITATIVE = "PATIENT_OUTREACH_FOLLOW_UP_NO_VOICEMAIL_AUTHORITATIVE",
  PATIENT_OUTREACH_FOLLOW_UP_LEFT_VOICEMAIL_AUTHORITATIVE = "PATIENT_OUTREACH_FOLLOW_UP_LEFT_VOICEMAIL_AUTHORITATIVE",
  PATIENT_OUTREACH_FOLLOW_UP_NO_VOICEMAIL_HANDWRITTEN = "PATIENT_OUTREACH_FOLLOW_UP_NO_VOICEMAIL_HANDWRITTEN",
  PATIENT_OUTREACH_FOLLOW_UP_LEFT_VOICEMAIL_HANDWRITTEN = "PATIENT_OUTREACH_FOLLOW_UP_LEFT_VOICEMAIL_HANDWRITTEN",
  PATIENT_OUTREACH_FOLLOW_UP_NO_VOICEMAIL_REWARDS = "PATIENT_OUTREACH_FOLLOW_UP_NO_VOICEMAIL_REWARDS",
  PATIENT_OUTREACH_FOLLOW_UP_LEFT_VOICEMAIL_REWARDS = "PATIENT_OUTREACH_FOLLOW_UP_LEFT_VOICEMAIL_REWARDS",
  PATIENT_OUTREACH_FOLLOW_UP_NO_VOICEMAIL_EASE = "PATIENT_OUTREACH_FOLLOW_UP_NO_VOICEMAIL_EASE",
  PATIENT_OUTREACH_FOLLOW_UP_LEFT_VOICEMAIL_EASE = "PATIENT_OUTREACH_FOLLOW_UP_LEFT_VOICEMAIL_EASE",
  PATIENT_OUTREACH_FOLLOW_UP_NO_VOICEMAIL_OUTCOMES = "PATIENT_OUTREACH_FOLLOW_UP_NO_VOICEMAIL_OUTCOMES",
  PATIENT_OUTREACH_FOLLOW_UP_LEFT_VOICEMAIL_OUTCOMES = "PATIENT_OUTREACH_FOLLOW_UP_LEFT_VOICEMAIL_OUTCOMES",
  SCHEDULER_SAME_DAY_APPOINTMENT_BOOKED = "SCHEDULER_SAME_DAY_APPOINTMENT_BOOKED",
  SCHEDULER_SAME_DAY_APPOINTMENT_RESCHEDULED = "SCHEDULER_SAME_DAY_APPOINTMENT_RESCHEDULED",
  SCHEDULER_SAME_DAY_APPOINTMENT_REMINDER = "SCHEDULER_SAME_DAY_APPOINTMENT_REMINDER",
  SCHEDULER_SAME_DAY_APPOINTMENT_AWAITING_TECH_RESPONSE = "SCHEDULER_SAME_DAY_APPOINTMENT_AWAITING_TECH_RESPONSE",
  TECH_SAME_DAY_APPOINTMENT = "TECH_SAME_DAY_APPOINTMENT",
  TECH_SAME_DAY_APPOINTMENT_REMINDER = "TECH_SAME_DAY_APPOINTMENT_REMINDER",
  TECH_SCHEDULER_SAME_DAY_APPOINTMENT_RESCHEDULED = "TECH_SCHEDULER_SAME_DAY_APPOINTMENT_RESCHEDULED",
  TECH_SCHEDULER_SAME_DAY_APPOINTMENT_CANCELED = "TECH_SCHEDULER_SAME_DAY_APPOINTMENT_CANCELED",
  REQUISITION_REUPLOADED = "REQUISITION_REUPLOADED",
}


export type ModelSMSConditionInput = {
  type?: ModelSMSTypeInput | null,
  contextJSON?: ModelStringInput | null,
  ttl?: ModelIntInput | null,
  and?: Array< ModelSMSConditionInput | null > | null,
  or?: Array< ModelSMSConditionInput | null > | null,
  not?: ModelSMSConditionInput | null,
};

export type ModelSMSTypeInput = {
  eq?: SMSType | null,
  ne?: SMSType | null,
};

export type SMS = {
  __typename: "SMS",
  id: string,
  type: SMSType,
  contextJSON: string,
  ttl?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type BookAppointmentInput = {
  appointmentID: string,
  encounterIDs?: Array< string > | null,
  promoCode?: string | null,
  start?: string | null,
  end?: string | null,
  shouldDeleteAppointmentIfUnbookable?: boolean | null,
  rescheduleReason?: string | null,
  commentsFromPartnerAboutReschedule?: string | null,
  commentsFromSchedulerAboutReschedule?: string | null,
};

export type BookAppointmentResponse = {
  __typename: "BookAppointmentResponse",
  appointment?: Appointment | null,
};

export type CancelAppointmentInput = {
  appointmentID: string,
  commentsFromSchedulerAboutCancellation?: string | null,
  commentsFromCoordinatorAboutCancellation?: string | null,
  cancelationReason?: AppointmentCancellationReason | null,
  initiator: Initiator,
};

export enum AppointmentCancellationReason {
  PATIENT_SCHEDULING_CONFLICT = "PATIENT_SCHEDULING_CONFLICT",
  SERVICE_PROVIDED_ELSEWHERE = "SERVICE_PROVIDED_ELSEWHERE",
  SERVICE_NOT_NEEDED = "SERVICE_NOT_NEEDED",
  PATIENT_DECLINED_SERVICE = "PATIENT_DECLINED_SERVICE",
  INSUFFICIENT_APPOINTMENT_INFO = "INSUFFICIENT_APPOINTMENT_INFO",
  SPRINTER_AVAILABILITY = "SPRINTER_AVAILABILITY",
  PROTOCOL_VIOLATION = "PROTOCOL_VIOLATION",
  PATIENT_NO_SHOW = "PATIENT_NO_SHOW",
  PATIENT_DEHYDRATED = "PATIENT_DEHYDRATED",
  PATIENT_MISSING_APPOINTMENT_REQUIREMENTS = "PATIENT_MISSING_APPOINTMENT_REQUIREMENTS",
  OTHER = "OTHER",
  PATIENT_CANCELED_VIA_EMAIL = "PATIENT_CANCELED_VIA_EMAIL",
  PATIENT_RESCHEDULED_VIA_EMAIL = "PATIENT_RESCHEDULED_VIA_EMAIL",
  PATIENT_CANCELED_VIA_REFERRAL_FLOW = "PATIENT_CANCELED_VIA_REFERRAL_FLOW",
}


export enum Initiator {
  PARTNER = "PARTNER",
  PATIENT = "PATIENT",
  SPRINTER_HEALTH = "SPRINTER_HEALTH",
  OTHER = "OTHER",
}


export type CancelAppointmentResponse = {
  __typename: "CancelAppointmentResponse",
  appointment?: Appointment | null,
};

export type DeepCloneAndBookAppointmentInput = {
  appointmentID: string,
  start: string,
  end: string,
};

export type DeepCloneAndBookAppointmentResponse = {
  __typename: "DeepCloneAndBookAppointmentResponse",
  appointment?: Appointment | null,
};

export type AcceptAppointmentInput = {
  appointmentID: string,
};

// In the exportReports mutation, we specify the ReportsExportType, and may receive multiple Reports with different ReportTypes as the output.
// Essentially, the ReportType specifies the type of a Report, unlike ReportsExportType which specifies the type of report(s) to be exported.
// 
// For instance, a ReportsExportType can specify that the user wants the eye exam results for group of patients,
// but the output of the exportReports may include multiple ReportTypes (e.g. images of the patients' eye, and a summary of all eye exams' resulting diagnosis codes)
// 
// We prefix EXTERNAL if we plan to send the report to partners
// We use BULK if the report contains bulk information
export enum ReportsExportType {
  EXTERNAL_BULK_ENCOUNTER_SERVICES_AND_COST_SUMMARY_FOR_INVOICING = "EXTERNAL_BULK_ENCOUNTER_SERVICES_AND_COST_SUMMARY_FOR_INVOICING",
  EXTERNAL_BULK_CARE_NAVIGATION_SERVICES_AND_COST_SUMMARY_FOR_INVOICING = "EXTERNAL_BULK_CARE_NAVIGATION_SERVICES_AND_COST_SUMMARY_FOR_INVOICING",
  EXTERNAL_BULK_LAB_SUPPLEMENTAL_DATA_SOURCE_FILE = "EXTERNAL_BULK_LAB_SUPPLEMENTAL_DATA_SOURCE_FILE",
  EXTERNAL_BULK_NONLAB_SUPPLEMENTAL_DATA_SOURCE_FILE = "EXTERNAL_BULK_NONLAB_SUPPLEMENTAL_DATA_SOURCE_FILE",
  EXTERNAL_BULK_CLAIMS_BILLING = "EXTERNAL_BULK_CLAIMS_BILLING",
  EXTERNAL_BULK_OPT_OUT_OF_ENGAGEMENT = "EXTERNAL_BULK_OPT_OUT_OF_ENGAGEMENT",
}


export enum ReportsExportDestination {
  GOOGLE_DRIVE = "GOOGLE_DRIVE",
  S3 = "S3",
}


export type Report = {
  __typename: "Report",
  name?: string | null,
  reportURL?: string | null,
  reportS3Object?: S3Object | null,
  type: ReportType,
};

export enum ReportType {
  EXTERNAL_BULK_CARE_NAVIGATION_SERVICES_AND_COST_SUMMARY_FOR_INVOICING = "EXTERNAL_BULK_CARE_NAVIGATION_SERVICES_AND_COST_SUMMARY_FOR_INVOICING",
  EXTERNAL_BULK_ENCOUNTER_SERVICES_AND_COST_SUMMARY_FOR_INVOICING = "EXTERNAL_BULK_ENCOUNTER_SERVICES_AND_COST_SUMMARY_FOR_INVOICING",
  EXTERNAL_BULK_CLAIMS_BILLING = "EXTERNAL_BULK_CLAIMS_BILLING",
  EXTERNAL_BULK_LAB_SUPPLEMENTAL_DATA_SOURCE_FILE = "EXTERNAL_BULK_LAB_SUPPLEMENTAL_DATA_SOURCE_FILE",
  EXTERNAL_BULK_NONLAB_SUPPLEMENTAL_DATA_SOURCE_FILE = "EXTERNAL_BULK_NONLAB_SUPPLEMENTAL_DATA_SOURCE_FILE",
  EXTERNAL_BULK_OPT_OUT_OF_ENGAGEMENT = "EXTERNAL_BULK_OPT_OUT_OF_ENGAGEMENT",
  INTERNAL_BULK_UPSERT_LAB_RESULTS_AS_OBSERVATIONS_OUTPUT = "INTERNAL_BULK_UPSERT_LAB_RESULTS_AS_OBSERVATIONS_OUTPUT",
  INTERNAL_BULK_UPSERT_ELATION_BILLING_REPORT_PROCEDURE_CODES_OUTPUT = "INTERNAL_BULK_UPSERT_ELATION_BILLING_REPORT_PROCEDURE_CODES_OUTPUT",
}


export enum DataImportType {
  INTERNAL_BULK_UPSERT_LAB_RESULTS_AS_OBSERVATIONS = "INTERNAL_BULK_UPSERT_LAB_RESULTS_AS_OBSERVATIONS",
  INTERNAL_BULK_UPSERT_ELATION_BILLING_REPORT_PROCEDURE_CODES = "INTERNAL_BULK_UPSERT_ELATION_BILLING_REPORT_PROCEDURE_CODES",
}


export type GenerateStripeSetupIntentInput = {
  coordinatorID: string,
  promoCode?: string | null,
  productTypes: Array< ProductType >,
};

export type GenerateStripeSetupIntentResponse = {
  __typename: "GenerateStripeSetupIntentResponse",
  clientSecret?: string | null,
  finalPrice?: number | null,
  strikethroughPrice?: number | null,
};

export type RerouteAppointmentInput = {
  appointmentID: string,
  shouldAvoidCurrentTech: boolean,
  forceTechID?: string | null,
};

export type ReviewPostAppointmentInput = {
  appointmentID: string,
};

export type ReviewPostAppointmentResponse = {
  __typename: "ReviewPostAppointmentResponse",
  appointment?: Appointment | null,
};

export type ReviewPreAppointmentInput = {
  appointmentID: string,
  requisitions?: Array< RequisitionInput > | null,
};

export type ReviewPreAppointmentResponse = {
  __typename: "ReviewPreAppointmentResponse",
  appointment?: Appointment | null,
};

export type UpdateEncounterServiceOutcomesInput = {
  encounterID: string,
  serviceOutcomes: Array< ServiceOutcomeInput >,
};

export type UpdateEncounterServiceOutcomesResponse = {
  __typename: "UpdateEncounterServiceOutcomesResponse",
  appointmentID: string,
  hasFailedServices: boolean,
  hasSuccessfulServices: boolean,
};

export type SaveEmailToContactForServiceExpansionInput = {
  email: string,
  location?: LocationInput | null,
  productType?: ProductType | null,
};

export type SaveEmailToContactForServiceExpansionResponse = {
  __typename: "SaveEmailToContactForServiceExpansionResponse",
  _?: number | null,
};

export type SendEFaxInput = {
  partnerOrganization: PartnerOrganization,
  document: S3ObjectInput,
  appointmentID: string,
  encounterID: string,
};

export type SendEFaxResponse = {
  __typename: "SendEFaxResponse",
  result?: string | null,
  errorMessage?: string | null,
  sentAt?: string | null,
};

export type SendEncounterSummaryToPractitionerInput = {
  document: S3ObjectInput,
  documentType: EncounterSummaryType,
  appointmentID: string,
  encounterID: string,
};

export enum EncounterSummaryType {
  FAX = "FAX",
  EMAIL = "EMAIL",
}


export type SendEncounterSummaryToPractitionerResponse = {
  __typename: "SendEncounterSummaryToPractitionerResponse",
  sentAt?: string | null,
  response?: string | null,
  errorMessage?: string | null,
  to?: Array< string | null > | null,
};

export type SendEncounterSummaryEmailInput = {
  partnerOrganization: PartnerOrganization,
  document: S3ObjectInput,
  appointmentID: string,
  encounterID: string,
};

export type SendEncounterSummaryEmailResponse = {
  __typename: "SendEncounterSummaryEmailResponse",
  sentAt?: string | null,
  response?: string | null,
  to?: Array< string | null > | null,
};

export type StartCareNavigationEpisodeDetails = {
  id: string,
  group: CareNavigationGroup,
  onboardingQuestions?: Array< StartCareNavigationEpisodeDetailsQuestionAnswerPair > | null,
  parametersJSON?: string | null,
};

export type StartCareNavigationEpisodeDetailsQuestionAnswerPair = {
  key: string,
  question: string,
  answer: string,
};

export type RegisterPatientsInImageMoverInput = {
  appointmentID: string,
};

export type UpdatePatientInImageMoverInput = {
  patientID: string,
};

export type GenerateChecklistInput = {
  appointmentID: string,
};

export type UpdateChecklistInput = {
  id: string,
  checklist: ChecklistInput,
  encounters?: Array< UpdateEncounterChecklistInput > | null,
  commentsFromTechAboutAppointment?: string | null,
  commentsFromSchedulerToPartnerAboutEncounter?: string | null,
  commentsFromTechAboutSystem?: string | null,
  techCurrentPosition?: LocationPositionInput | null,
};

export type UpdateEncounterChecklistInput = {
  encounterID: string,
  requisitions?: Array< RequisitionInput > | null,
  commentsFromSchedulerToTechAboutEncounter?: string | null,
  commentsFromSchedulerToPartnerAboutEncounter?: string | null,
  commentsFromTechAboutEncounter?: string | null,
  patientEthnicity?: Ethnicity | null,
  patientRaces?: Array< Race > | null,
  patientSex?: Sex | null,
  patientGender?: Gender | null,
  patientPhone?: string | null,
  patientEmail?: string | null,
  patientHeightInInches?: number | null,
  patientWeightInPounds?: number | null,
  covid19Questions?: COVID19QuestionsInput | null,
  bloodCollectionDetails?: SpecimenCollectionDetailsInput | null,
  capillaryDrawCollectionDetails?: SpecimenCollectionDetailsInput | null,
  productTypes?: Array< ProductType > | null,
  serviceOutcomes?: Array< ServiceOutcomeInput > | null,
};

export type UpdateTechAvailabilitiesInput = {
  techID: string,
  availabilityRules: Array< TimeRangeRuleInput >,
};

export type UpdateTechAvailabilitiesResponse = {
  __typename: "UpdateTechAvailabilitiesResponse",
  tech?: Tech | null,
  conflictingAppointments?:  Array<Appointment > | null,
};

export type UpdateEncounterRequisitionInput = {
  encounterID: string,
  requisitionUploads: Array< S3ObjectInput >,
  requisitionUploadS3ObjectIDs?: Array< string > | null,
};

export type UpdateEncounterRequisitionResponse = {
  __typename: "UpdateEncounterRequisitionResponse",
  appointment?: Appointment | null,
  encounter?: Encounter | null,
};

export type UpdateEncounterDocumentsResponse = {
  __typename: "UpdateEncounterDocumentsResponse",
  appointment?: Appointment | null,
  encounter?: Encounter | null,
};

export type UpdateTechServiceRegionAvailabilitiesInput = {
  techServiceRegionID: string,
  availabilityRules: Array< TimeRangeRuleInput >,
};

export type UpdateTechServiceRegionAvailabilitiesResponse = {
  __typename: "UpdateTechServiceRegionAvailabilitiesResponse",
  techServiceRegion?: TechServiceRegion | null,
  conflictingAppointments?:  Array<Appointment > | null,
};

export type UpdateTechAllAvailabilitiesInput = {
  techID: string,
  techAvailabilityRules: Array< TimeRangeRuleInput >,
  serviceRegionsAvailabilityRules: Array< ServiceRegionAvailabilityRulesInput >,
  serviceTypesAvailabilityRules: Array< ServiceTypeAvailabilityRulesInput >,
};

export type ServiceRegionAvailabilityRulesInput = {
  serviceRegionName: string,
  availabilityRules: Array< TimeRangeRuleInput >,
};

export type UpdateTechAllAvailabilitiesResponse = {
  __typename: "UpdateTechAllAvailabilitiesResponse",
  conflictingAppointments?:  Array<Appointment > | null,
};

export type ConvertCoordinatorToPartnerCSRInput = {
  coordinatorID: string,
  partnerOrganization: PartnerOrganization,
};

export type ConvertCoordinatorToPartnerCSRResponse = {
  __typename: "ConvertCoordinatorToPartnerCSRResponse",
  coordinator?: Coordinator | null,
};

export type GenerateRequisitionsForPrintingInput = {
  techID: string,
  date: string,
};

export type GenerateRequisitionsForPrintingResponse = {
  __typename: "GenerateRequisitionsForPrintingResponse",
  output?: S3Object | null,
  url?: string | null,
};

export type GenerateEncounterSummaryInput = {
  appointmentID: string,
  encounterID: string,
};

export type GenerateEncounterSummaryResponse = {
  __typename: "GenerateEncounterSummaryResponse",
  s3ObjectID: string,
  identityID: string,
  bucket?: string | null,
  key: string,
  type?: EncounterSummaryType | null,
};

export type GenerateTestFaxPDFInput = {
  appointmentID?: string | null,
  encounterID?: string | null,
  practitionerID?: string | null,
  faxType: FaxType,
  verificationCount?: number | null,
};

export enum FaxType {
  COVER_SHEET = "COVER_SHEET",
  PROVIDER_VALIDATION = "PROVIDER_VALIDATION",
  ENCOUNTER_SUMMARY = "ENCOUNTER_SUMMARY",
  ENCOUNTER_SUMMARY_WITH_NEW_LAB_RESULTS = "ENCOUNTER_SUMMARY_WITH_NEW_LAB_RESULTS",
  CARE_NAVIGATION_EPISODE_SUMMARY = "CARE_NAVIGATION_EPISODE_SUMMARY",
  COLOGUARD_KIT_ORDER = "COLOGUARD_KIT_ORDER",
}


export type GenerateTestFaxPDFResponse = {
  __typename: "GenerateTestFaxPDFResponse",
  output: S3Object,
};

export type MergeAppointmentsInput = {
  appointmentIDToBeMergedWith: string,
  appointmentIDToMergeAndDiscard: string,
};

export type MergeAppointmentsResponse = {
  __typename: "MergeAppointmentsResponse",
  appointment?: Appointment | null,
};

export type GenerateMedicareBillingTemplateInput = {
  start: string,
  end: string,
};

export type GenerateMedicareBillingTemplateResponse = {
  __typename: "GenerateMedicareBillingTemplateResponse",
  billingCSV: string,
};

export type RemovePatientFromAppointmentInput = {
  appointmentID: string,
  productType: ProductType,
  patientID?: string | null,
};

export type RemovePatientFromAppointmentResponse = {
  __typename: "RemovePatientFromAppointmentResponse",
  appointment?: Appointment | null,
};

export type InviteUserToPartnerOrganizationInput = {
  email: string,
  partnerOrganization: PartnerOrganization,
  managePartnerUsersAuthorization: ManagePartnerUsersAuthorization,
};

export type InviteUserToPartnerOrganizationResponse = {
  __typename: "InviteUserToPartnerOrganizationResponse",
  invite?: PartnerUserInvite | null,
};

export type ChangeUserAccountStatusOrPermissionInput = {
  coordinatorID: string,
  action: ChangeUserAccountStatusOrPermissionAction,
};

export enum ChangeUserAccountStatusOrPermissionAction {
  ACTIVATE = "ACTIVATE",
  DEACTIVATE = "DEACTIVATE",
  TO_BASIC_USER = "TO_BASIC_USER",
  TO_ADMIN_USER = "TO_ADMIN_USER",
}


export type ChangeUserAccountStatusOrPermissionResponse = {
  __typename: "ChangeUserAccountStatusOrPermissionResponse",
  coordinator: Coordinator,
};

export type ExpirePartnerUserInviteInput = {
  inviteID: string,
};

export type ExpirePartnerUserInviteResponse = {
  __typename: "ExpirePartnerUserInviteResponse",
  invite: PartnerUserInvite,
};

export type UpdateContainerSpecimenVolumesInput = {
  externalID: string,
  labOrganization: LabOrganization,
  isUpdatingTestToNonCombinable: boolean,
  minContainerCountAndNameArray: Array< MinContainerCountAndName | null >,
  containerCategory?: SpecimenContainerCategory | null,
};

export type MinContainerCountAndName = {
  minContainerCount?: number | null,
  containerName?: string | null,
};

export type UpdateContainerSpecimenVolumesResponse = {
  __typename: "UpdateContainerSpecimenVolumesResponse",
  id?: string | null,
};

export type CreateZendeskTicketInput = {
  ticket: ZendeskTicketCreateModel,
  requesterID?: string | null,
  appointmentID?: string | null,
  // Should be in the custom_fields in zendesk ticket; needs the category id
  category?: string | null,
  formID?: string | null,
};

export type ZendeskTicketCreateModel = {
  comment: ZendeskTicketCommentCreateModel,
  external_id?: string | null,
  type?: ZendeskTicketType | null,
  subject?: string | null,
  raw_subject?: string | null,
  priority?: ZendeskTicketPriority | null,
  status?: ZendeskTicketStatus | null,
  recipient?: string | null,
  requester_id?: number | null,
  requester?: ZendeskTicketRequesterAnonymous | null,
  submitter_id?: number | null,
  assignee_id?: number | null,
  organization_id?: number | null,
  group_id?: number | null,
  collaborator_ids?: Array< number > | null,
  follower_ids?: Array< number > | null,
  email_cc_ids?: Array< number > | null,
  forum_topic_id?: number | null,
  problem_id?: number | null,
  due_at?: string | null,
  tags?: Array< string > | null,
  custom_fields?: Array< ZendeskTicketField > | null,
  fields?: Array< ZendeskTicketField > | null,
  via_followup_source_id?: number | null,
  macro_ids?: Array< number > | null,
  ticket_form_id?: number | null,
  brand_id?: number | null,
};

export type ZendeskTicketCommentCreateModel = {
  url?: string | null,
  request_id?: number | null,
  body?: string | null,
  html_body?: string | null,
  public?: boolean | null,
  author_id?: number | null,
  uploads?: Array< string > | null,
};

export enum ZendeskTicketType {
  problem = "problem",
  incident = "incident",
  question = "question",
  task = "task",
}


export enum ZendeskTicketPriority {
  urgent = "urgent",
  high = "high",
  normal = "normal",
  low = "low",
}


export enum ZendeskTicketStatus {
  new = "new",
  open = "open",
  pending = "pending",
  hold = "hold",
  solved = "solved",
  closed = "closed",
}


export type ZendeskTicketRequesterAnonymous = {
  name: string,
  email?: string | null,
  locale_id?: number | null,
};

export type ZendeskTicketField = {
  id: number,
  value: string,
};

export type CreateZendeskTicketResponse = {
  __typename: "CreateZendeskTicketResponse",
  id?: number | null,
};

export type CreateReferralVerificationZendeskTicketInput = {
  partnerOrganization?: PartnerOrganization | null,
  brandCode?: string | null,
  phone: string,
  givenName: string,
  familyName: string,
  email?: string | null,
  DOB: string,
};

export type CreateReferralVerificationZendeskTicketResponse = {
  __typename: "CreateReferralVerificationZendeskTicketResponse",
  _?: number | null,
};

export type BookReferralInput = {
  referralID?: string | null,
  appointmentID?: string | null,
  partnerOrganization: PartnerOrganization,
  serviceTypes?: Array< ServiceType > | null,
  start: string,
  end: string,
  patientMailingAddress?: AddressInput | null,
  location?: LocationInput | null,
  guestJWT?: string | null,
  patientID?: string | null,
  utmMedium?: string | null,
  commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
  primaryLanguageBCP47?: string | null,
  colorectalCancerScreeningAnswers?: QuestionnaireResponseInput | null,
  topicalFluoridePrescreeningAnswers?: QuestionnaireResponseInput | null,
};

export type BookReferralResponse = {
  __typename: "BookReferralResponse",
  appointment?: CustomAuthPartialAppointment | null,
};

export type CustomAuthPartialAppointment = {
  __typename: "CustomAuthPartialAppointment",
  id: string,
  serviceTypes: Array< ServiceType >,
  productTypes: Array< ProductType >,
  start: string,
  end: string,
  location: Location,
  referralID?: string | null,
  status: AppointmentStatus,
  patientEncounterSummaryPresignedURL?: string | null,
};

export type CancelReferralBookingInput = {
  referralID?: string | null,
  appointmentID: string,
  commentsFromPatientAboutCancelation?: string | null,
  guestJWT?: string | null,
  patientID?: string | null,
};

export type CancelReferralBookingResponse = {
  __typename: "CancelReferralBookingResponse",
  appointmentID?: string | null,
};

export type UpdateReferralPatientInformationInput = {
  referralID: string,
  partnerOrganization: PartnerOrganization,
  phone?: string | null,
  email?: string | null,
  appointmentID: string,
  consentToContactForAppointmentBySMS: boolean,
  commentsFromCoordinatorAboutAppointment?: string | null,
  contraindicationsResponse?: string | null,
  guestJWT?: string | null,
  patientID?: string | null,
};

export type UpdateReferralPatientInformationMutationResponse = {
  __typename: "UpdateReferralPatientInformationMutationResponse",
  patientID: string,
  phone?: string | null,
  email?: string | null,
};

export type MarkFailedAllAppointmentServicesInput = {
  appointmentID: string,
};

export type UpsertPatientAndCreateReferralInput = {
  patient: CreatePatientInput,
  referral: CreateReferralInput,
};

export type UpsertPatientAndCreateReferralResponse = {
  __typename: "UpsertPatientAndCreateReferralResponse",
  patient?: Patient | null,
  referral?: Referral | null,
};

export type CreateServiceRequestInput = {
  id: string,
  displayName: string,
  externalID: string,
  labOrganization: LabOrganization,
  minimumSpecimenVolume?: number | null,
};

export type CreateServiceRequestResponse = {
  __typename: "CreateServiceRequestResponse",
  serviceRequestTypeID: string,
};

export type OnboardTechInput = {
  email: string,
  givenName: string,
  familyName: string,
  home: LocationInput,
  gender: Gender,
  isIneligibleForMatchmaking?: boolean | null,
};

export type OnboardTechResponse = {
  __typename: "OnboardTechResponse",
  id: string,
};

export type UpsertPractitionersInZendeskInput = {
  practitionerIDs: Array< string >,
};

export type UpsertPractitionersInZendeskResponse = {
  __typename: "UpsertPractitionersInZendeskResponse",
  updatedUsers?: number | null,
};

export type TriggerTestPatientCampaignStateUpdateInput = {
  state: PatientCampaignStateName,
  action: PatientCampaignAction,
  patientID: string,
  campaignName: CampaignName,
  consentToContactForMarketingSMS: ConsentInput,
  consentToContactForMarketingEmail: ConsentInput,
  consentToContactForMarketingPhone: ConsentInput,
  email?: string | null,
  phone?: string | null,
  primaryLanguageBCP47?: string | null,
};

export enum PatientCampaignAction {
  OUTREACH_SMS_UNEXPECTED_RESPONSE = "OUTREACH_SMS_UNEXPECTED_RESPONSE",
  OUTREACH_SMS_MORE_INFO_RESPONSE = "OUTREACH_SMS_MORE_INFO_RESPONSE",
  OUTREACH_EMAIL_UNEXPECTED_RESPONSE = "OUTREACH_EMAIL_UNEXPECTED_RESPONSE",
  UNSUBSCRIBE_TO_EMAIL = "UNSUBSCRIBE_TO_EMAIL",
  OPT_OUT_SMS = "OPT_OUT_SMS",
  OPT_OUT_PHONE = "OPT_OUT_PHONE",
  BOOK_APPOINTMENT = "BOOK_APPOINTMENT",
  DECLINE_SERVICES = "DECLINE_SERVICES",
  IS_INELIGIBLE = "IS_INELIGIBLE",
  REQUESTED_INFORMATION = "REQUESTED_INFORMATION",
  LEFT_VOICEMAIL = "LEFT_VOICEMAIL",
  NO_VM_VM_NOT_SET_UP = "NO_VM_VM_NOT_SET_UP",
  HUNG_UP = "HUNG_UP",
  REQUESTED_CALLBACK = "REQUESTED_CALLBACK",
  PATIENT_WILL_SCHEDULE_LATER = "PATIENT_WILL_SCHEDULE_LATER",
  FORCE_MOVE_TO_STATE = "FORCE_MOVE_TO_STATE", // Moves the patient directly to a different campaign state with logging of the transition
  QUEUE_PHONE = "QUEUE_PHONE",
  SEND_EMAIL = "SEND_EMAIL",
  SEND_SMS = "SEND_SMS",
  END_OUTREACH = "END_OUTREACH",
  TRICKLE_INTO_CAMPAIGN = "TRICKLE_INTO_CAMPAIGN",
  TRICKLE_INTO_CAMPAIGN_STALE = "TRICKLE_INTO_CAMPAIGN_STALE",
  KICK_BACK_INTO_CAMPAIGN = "KICK_BACK_INTO_CAMPAIGN",
  REACTIVATE = "REACTIVATE",
  TERMINATE_CAMPAIGN = "TERMINATE_CAMPAIGN",
}


export type TriggerTestPatientCampaignStateUpdateResponse = {
  __typename: "TriggerTestPatientCampaignStateUpdateResponse",
  result: string,
};

export type UnsubscribeFromEmailNotificationsInput = {
  patientCampaignStateID: string,
  email?: string | null,
};

export type UnsubscribeFromEmailNotificationsResponse = {
  __typename: "UnsubscribeFromEmailNotificationsResponse",
  isUnsubscribed: boolean,
};

export type SendPractitionersVerificationFaxesInput = {
  NPIs: Array< string >,
  partnerOrganization: PartnerOrganization,
};

export type SendPractitionersVerificationFaxesResponse = {
  __typename: "SendPractitionersVerificationFaxesResponse",
  totalNPI: number,
  totalPractitionersToSendTo: number,
  totalFaxesToSend: number,
  totalFaxesSent: number,
  totalPractitionersSent: number,
};

export type AutofillRequisitionFormInput = {
  encounterID: string,
  partnerOrganization: PartnerOrganization,
};

export type AutofillRequisitionFormResponse = {
  __typename: "AutofillRequisitionFormResponse",
  encounter: Encounter,
  errors?: Array< string > | null,
};

export type EscalateEncounterResponse = {
  __typename: "EscalateEncounterResponse",
  encounterID: string,
};

export type UpdateTechInput = {
  id: string,
  name?: HumanNameInput | null,
  gender?: Gender | null,
  phone?: string | null,
  email?: string | null,
  vehicle?: VehicleInput | null,
  serviceTypesAvailabilityRules?: Array< ServiceTypeAvailabilityRulesInput > | null,
  availabilityRules?: Array< TimeRangeRuleInput > | null,
  locations?: Array< LocationInput > | null,
  profilePicture?: S3ObjectInput | null,
  profilePictureS3ObjectID?: string | null,
  biographyItems?: Array< string > | null,
  patientTestimonials?: Array< string > | null,
  capabilities?: Array< TechCapability > | null,
  isIneligibleForMatchmaking?: boolean | null,
};

export type UploadDNCListResponse = {
  __typename: "UploadDNCListResponse",
  ok: boolean,
};

export enum PatientListType {
  GAP_LIST_DELTA = "GAP_LIST_DELTA",
  GAP_LIST_FULL = "GAP_LIST_FULL",
  ELIGIBILITY_LIST = "ELIGIBILITY_LIST",
}


export type UploadPatientListResponse = {
  __typename: "UploadPatientListResponse",
  resultsURL?: string | null,
};

export type PlaceLabOrderForEncounterResponse = {
  __typename: "PlaceLabOrderForEncounterResponse",
  encounter?: Encounter | null,
  errors?: Array< string > | null,
};

export type GenerateQuestionnaireForEncounterResponse = {
  __typename: "GenerateQuestionnaireForEncounterResponse",
  questionnaire: QuestionnaireEmbedded,
};

export type TrickleConfigurationResponse = {
  __typename: "TrickleConfigurationResponse",
  configuration: string,
};

export type ModelSerialCounterFilterInput = {
  type?: ModelSerialCounterTypeInput | null,
  serialValue?: ModelIntInput | null,
  and?: Array< ModelSerialCounterFilterInput | null > | null,
  or?: Array< ModelSerialCounterFilterInput | null > | null,
  not?: ModelSerialCounterFilterInput | null,
};

export type ModelSerialCounterTypeInput = {
  eq?: SerialCounterType | null,
  ne?: SerialCounterType | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelSerialCounterConnection = {
  __typename: "ModelSerialCounterConnection",
  items:  Array<SerialCounter | null >,
  nextToken?: string | null,
};

export type ModelAppointmentFilterInput = {
  id?: ModelIDInput | null,
  status?: ModelAppointmentStatusInput | null,
  isFailed?: ModelBooleanInput | null,
  hasSuccessfulServices?: ModelBooleanInput | null,
  hasFailedServices?: ModelBooleanInput | null,
  needsRebooking?: ModelBooleanInput | null,
  isPreReviewed?: ModelBooleanInput | null,
  isPostReviewed?: ModelBooleanInput | null,
  isEscalated?: ModelBooleanInput | null,
  start?: ModelStringInput | null,
  end?: ModelStringInput | null,
  techID?: ModelIDInput | null,
  remoteTechID?: ModelIDInput | null,
  remoteTechMatchState?: ModelRemoteTechMatchStateInput | null,
  coordinatorID?: ModelIDInput | null,
  partnerOrganization?: ModelPartnerOrganizationInput | null,
  serviceRegionName?: ModelStringInput | null,
  geographicalState?: ModelStringInput | null,
  avoidTechIDs?: ModelIDInput | null,
  lastBookedAt?: ModelIntInput | null,
  statusUpdatedAt?: ModelIntInput | null,
  stripeCustomerID?: ModelStringInput | null,
  stripePriceToCharge?: ModelIntInput | null,
  hasPetsAtLocation?: ModelBooleanInput | null,
  commentsFromCoordinatorAboutAppointment?: ModelStringInput | null,
  commentsFromTechAboutAppointment?: ModelStringInput | null,
  commentsFromTechAboutSystem?: ModelStringInput | null,
  commentsFromTechAboutAppointmentFailure?: ModelStringInput | null,
  commentsFromSchedulerAboutAppointmentFailure?: ModelStringInput | null,
  commentsFromSchedulerAboutCancellation?: ModelStringInput | null,
  partnerOrganizationBookingURL?: ModelStringInput | null,
  overrideMode?: ModelAppointmentOverrideModeInput | null,
  sharedSlotRank?: ModelFloatInput | null,
  estimatedServiceDurationSeconds?: ModelIntInput | null,
  stripeInvoiceLineItemIDs?: ModelStringInput | null,
  stripePaymentIntentIDs?: ModelStringInput | null,
  hasPatientConfirmed?: ModelBooleanInput | null,
  isGuestBooked?: ModelBooleanInput | null,
  referralID?: ModelIDInput | null,
  historyJSON?: ModelStringInput | null,
  externalID?: ModelIDInput | null,
  clonedFromAppointmentID?: ModelIDInput | null,
  remoteTechQualificationTypeRequired?: ModelQualificationTypeInput | null,
  markedReadyForRemoteTechAt?: ModelStringInput | null,
  and?: Array< ModelAppointmentFilterInput | null > | null,
  or?: Array< ModelAppointmentFilterInput | null > | null,
  not?: ModelAppointmentFilterInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type SearchableAppointmentFilterInput = {
  id?: SearchableIDFilterInput | null,
  start?: SearchableStringFilterInput | null,
  end?: SearchableStringFilterInput | null,
  techID?: SearchableIDFilterInput | null,
  coordinatorID?: SearchableIDFilterInput | null,
  serviceRegionName?: SearchableStringFilterInput | null,
  geographicalState?: SearchableStringFilterInput | null,
  avoidTechIDs?: SearchableIDFilterInput | null,
  lastBookedAt?: SearchableIntFilterInput | null,
  statusUpdatedAt?: SearchableIntFilterInput | null,
  stripeCustomerID?: SearchableStringFilterInput | null,
  stripePriceToCharge?: SearchableIntFilterInput | null,
  hasPetsAtLocation?: SearchableBooleanFilterInput | null,
  commentsFromCoordinatorAboutAppointment?: SearchableStringFilterInput | null,
  commentsFromTechAboutAppointment?: SearchableStringFilterInput | null,
  commentsFromTechAboutSystem?: SearchableStringFilterInput | null,
  partnerOrganizationBookingURL?: SearchableStringFilterInput | null,
  sharedSlotRank?: SearchableFloatFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  status?: SearchableStringFilterInput | null,
  partnerOrganization?: SearchableStringFilterInput | null,
  overrideMode?: SearchableStringFilterInput | null,
  metroName?: SearchableStringFilterInput | null,
  isInProgress?: SearchableBooleanFilterInput | null,
  isCompleted?: SearchableBooleanFilterInput | null,
  encountersCount?: SearchableIntFilterInput | null,
  techFullName?: SearchableStringFilterInput | null,
  patientsFullNames?: SearchableStringFilterInput | null,
  patientsFullNamesJoined?: SearchableStringFilterInput | null,
  labOrganizations?: SearchableStringFilterInput | null,
  labOrganizationsJoined?: SearchableStringFilterInput | null,
  isCSRBooked?: SearchableBooleanFilterInput | null,
  payer?: SearchableStringFilterInput | null,
  partnerVertical?: SearchableStringFilterInput | null,
  partnerDisplayName?: SearchableStringFilterInput | null,
  partnerOrganizationGroup?: SearchableStringFilterInput | null,
  fullAddress?: SearchableStringFilterInput | null,
  appointmentSearchField?: SearchableStringFilterInput | null,
  and?: Array< SearchableAppointmentFilterInput | null > | null,
  or?: Array< SearchableAppointmentFilterInput | null > | null,
  not?: SearchableAppointmentFilterInput | null,
};

export type SearchableIDFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableStringFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  customMatch?: SearchableCustomMatchFilterInput | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableCustomMatchFilterInput = {
  query: string,
  operator: string,
};

export type SearchableIntFilterInput = {
  ne?: number | null,
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
  eq?: number | null,
  range?: Array< number | null > | null,
};

export type SearchableBooleanFilterInput = {
  eq?: boolean | null,
  ne?: boolean | null,
};

export type SearchableFloatFilterInput = {
  ne?: number | null,
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
  eq?: number | null,
  range?: Array< number | null > | null,
};

export type SearchableAppointmentSortInput = {
  field?: SearchableAppointmentSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableAppointmentSortableFields {
  id = "id",
  isFailed = "isFailed",
  hasSuccessfulServices = "hasSuccessfulServices",
  hasFailedServices = "hasFailedServices",
  needsRebooking = "needsRebooking",
  isPreReviewed = "isPreReviewed",
  isPostReviewed = "isPostReviewed",
  isEscalated = "isEscalated",
  start = "start",
  end = "end",
  techID = "techID",
  remoteTechID = "remoteTechID",
  coordinatorID = "coordinatorID",
  serviceRegionName = "serviceRegionName",
  geographicalState = "geographicalState",
  avoidTechIDs = "avoidTechIDs",
  lastBookedAt = "lastBookedAt",
  statusUpdatedAt = "statusUpdatedAt",
  stripeCustomerID = "stripeCustomerID",
  stripePriceToCharge = "stripePriceToCharge",
  hasPetsAtLocation = "hasPetsAtLocation",
  commentsFromCoordinatorAboutAppointment = "commentsFromCoordinatorAboutAppointment",
  commentsFromTechAboutAppointment = "commentsFromTechAboutAppointment",
  commentsFromTechAboutSystem = "commentsFromTechAboutSystem",
  commentsFromTechAboutAppointmentFailure = "commentsFromTechAboutAppointmentFailure",
  commentsFromSchedulerAboutAppointmentFailure = "commentsFromSchedulerAboutAppointmentFailure",
  commentsFromSchedulerAboutCancellation = "commentsFromSchedulerAboutCancellation",
  partnerOrganizationBookingURL = "partnerOrganizationBookingURL",
  sharedSlotRank = "sharedSlotRank",
  estimatedServiceDurationSeconds = "estimatedServiceDurationSeconds",
  stripeInvoiceLineItemIDs = "stripeInvoiceLineItemIDs",
  stripePaymentIntentIDs = "stripePaymentIntentIDs",
  hasPatientConfirmed = "hasPatientConfirmed",
  isGuestBooked = "isGuestBooked",
  referralID = "referralID",
  historyJSON = "historyJSON",
  externalID = "externalID",
  clonedFromAppointmentID = "clonedFromAppointmentID",
  markedReadyForRemoteTechAt = "markedReadyForRemoteTechAt",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export enum SearchableSortDirection {
  asc = "asc",
  desc = "desc",
}


export type SearchableAppointmentAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableAppointmentAggregateField,
};

export enum SearchableAggregateType {
  terms = "terms",
  avg = "avg",
  min = "min",
  max = "max",
  sum = "sum",
}


export enum SearchableAppointmentAggregateField {
  id = "id",
  status = "status",
  isFailed = "isFailed",
  hasSuccessfulServices = "hasSuccessfulServices",
  hasFailedServices = "hasFailedServices",
  needsRebooking = "needsRebooking",
  isPreReviewed = "isPreReviewed",
  isPostReviewed = "isPostReviewed",
  isEscalated = "isEscalated",
  start = "start",
  end = "end",
  techID = "techID",
  remoteTechID = "remoteTechID",
  remoteTechMatchState = "remoteTechMatchState",
  coordinatorID = "coordinatorID",
  partnerOrganization = "partnerOrganization",
  serviceRegionName = "serviceRegionName",
  geographicalState = "geographicalState",
  avoidTechIDs = "avoidTechIDs",
  lastBookedAt = "lastBookedAt",
  statusUpdatedAt = "statusUpdatedAt",
  stripeCustomerID = "stripeCustomerID",
  stripePriceToCharge = "stripePriceToCharge",
  hasPetsAtLocation = "hasPetsAtLocation",
  commentsFromCoordinatorAboutAppointment = "commentsFromCoordinatorAboutAppointment",
  commentsFromTechAboutAppointment = "commentsFromTechAboutAppointment",
  commentsFromTechAboutSystem = "commentsFromTechAboutSystem",
  commentsFromTechAboutAppointmentFailure = "commentsFromTechAboutAppointmentFailure",
  commentsFromSchedulerAboutAppointmentFailure = "commentsFromSchedulerAboutAppointmentFailure",
  commentsFromSchedulerAboutCancellation = "commentsFromSchedulerAboutCancellation",
  partnerOrganizationBookingURL = "partnerOrganizationBookingURL",
  overrideMode = "overrideMode",
  sharedSlotRank = "sharedSlotRank",
  estimatedServiceDurationSeconds = "estimatedServiceDurationSeconds",
  stripeInvoiceLineItemIDs = "stripeInvoiceLineItemIDs",
  stripePaymentIntentIDs = "stripePaymentIntentIDs",
  hasPatientConfirmed = "hasPatientConfirmed",
  isGuestBooked = "isGuestBooked",
  referralID = "referralID",
  historyJSON = "historyJSON",
  externalID = "externalID",
  clonedFromAppointmentID = "clonedFromAppointmentID",
  remoteTechQualificationTypeRequired = "remoteTechQualificationTypeRequired",
  markedReadyForRemoteTechAt = "markedReadyForRemoteTechAt",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableAppointmentConnection = {
  __typename: "SearchableAppointmentConnection",
  items:  Array<Appointment | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type SearchableAggregateResult = {
  __typename: "SearchableAggregateResult",
  name: string,
  result?: SearchableAggregateGenericResult | null,
};

export type SearchableAggregateGenericResult = SearchableAggregateScalarResult | SearchableAggregateBucketResult


export type SearchableAggregateScalarResult = {
  __typename: "SearchableAggregateScalarResult",
  value: number,
};

export type SearchableAggregateBucketResult = {
  __typename: "SearchableAggregateBucketResult",
  buckets?:  Array<SearchableAggregateBucketResultItem | null > | null,
};

export type SearchableAggregateBucketResultItem = {
  __typename: "SearchableAggregateBucketResultItem",
  key: string,
  doc_count: number,
};

export type ModelEncounterFilterInput = {
  id?: ModelIDInput | null,
  appointmentID?: ModelIDInput | null,
  patientID?: ModelIDInput | null,
  coordinatorID?: ModelIDInput | null,
  techID?: ModelIDInput | null,
  partnerOrganization?: ModelPartnerOrganizationInput | null,
  requisitionUploadS3ObjectIDs?: ModelIDInput | null,
  commentsFromCoordinatorAboutPatient?: ModelStringInput | null,
  commentsFromSchedulerAboutEncounter?: ModelStringInput | null,
  commentsFromSchedulerToTechAboutEncounter?: ModelStringInput | null,
  commentsFromTechAboutEncounter?: ModelStringInput | null,
  commentsFromCustomerSupportAboutEncounter?: ModelStringInput | null,
  commentsFromSchedulerToPartnerAboutEncounter?: ModelStringInput | null,
  productTypes?: ModelProductTypeListInput | null,
  isMedicare?: ModelBooleanInput | null,
  isMedicareEligible?: ModelBooleanInput | null,
  wasPatientManuallyAdded?: ModelBooleanInput | null,
  patientEncounterSummaryLastSentAt?: ModelStringInput | null,
  cologuardOrderSuccessfullyPlacedAt?: ModelStringInput | null,
  hasPatientRequestedMailedResults?: ModelBooleanInput | null,
  and?: Array< ModelEncounterFilterInput | null > | null,
  or?: Array< ModelEncounterFilterInput | null > | null,
  not?: ModelEncounterFilterInput | null,
};

export type SearchableEncounterFilterInput = {
  id?: SearchableIDFilterInput | null,
  appointmentID?: SearchableIDFilterInput | null,
  patientID?: SearchableIDFilterInput | null,
  coordinatorID?: SearchableIDFilterInput | null,
  techID?: SearchableIDFilterInput | null,
  commentsFromCoordinatorAboutPatient?: SearchableStringFilterInput | null,
  commentsFromSchedulerToTechAboutEncounter?: SearchableStringFilterInput | null,
  commentsFromSchedulerToPartnerAboutEncounter?: SearchableStringFilterInput | null,
  commentsFromTechAboutEncounter?: SearchableStringFilterInput | null,
  isMedicare?: SearchableBooleanFilterInput | null,
  isMedicareEligible?: SearchableBooleanFilterInput | null,
  wasPatientManuallyAdded?: SearchableBooleanFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  partnerOrganization?: SearchableStringFilterInput | null,
  productTypes?: SearchableStringFilterInput | null,
  payer?: SearchableStringFilterInput | null,
  partnerVertical?: SearchableStringFilterInput | null,
  partnerDisplayName?: SearchableStringFilterInput | null,
  partnerOrganizationGroup?: SearchableStringFilterInput | null,
  isCSRBooked?: SearchableBooleanFilterInput | null,
  serviceRegionName?: SearchableStringFilterInput | null,
  metroName?: SearchableStringFilterInput | null,
  patientFullName?: SearchableStringFilterInput | null,
  techFullName?: SearchableStringFilterInput | null,
  and?: Array< SearchableEncounterFilterInput | null > | null,
  or?: Array< SearchableEncounterFilterInput | null > | null,
  not?: SearchableEncounterFilterInput | null,
};

export type SearchableEncounterSortInput = {
  field?: SearchableEncounterSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableEncounterSortableFields {
  id = "id",
  appointmentID = "appointmentID",
  patientID = "patientID",
  coordinatorID = "coordinatorID",
  techID = "techID",
  requisitionUploadS3ObjectIDs = "requisitionUploadS3ObjectIDs",
  commentsFromCoordinatorAboutPatient = "commentsFromCoordinatorAboutPatient",
  commentsFromSchedulerAboutEncounter = "commentsFromSchedulerAboutEncounter",
  commentsFromSchedulerToTechAboutEncounter = "commentsFromSchedulerToTechAboutEncounter",
  commentsFromTechAboutEncounter = "commentsFromTechAboutEncounter",
  commentsFromCustomerSupportAboutEncounter = "commentsFromCustomerSupportAboutEncounter",
  commentsFromSchedulerToPartnerAboutEncounter = "commentsFromSchedulerToPartnerAboutEncounter",
  isMedicare = "isMedicare",
  isMedicareEligible = "isMedicareEligible",
  wasPatientManuallyAdded = "wasPatientManuallyAdded",
  patientEncounterSummaryLastSentAt = "patientEncounterSummaryLastSentAt",
  cologuardOrderSuccessfullyPlacedAt = "cologuardOrderSuccessfullyPlacedAt",
  hasPatientRequestedMailedResults = "hasPatientRequestedMailedResults",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableEncounterAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableEncounterAggregateField,
};

export enum SearchableEncounterAggregateField {
  id = "id",
  appointmentID = "appointmentID",
  patientID = "patientID",
  coordinatorID = "coordinatorID",
  techID = "techID",
  partnerOrganization = "partnerOrganization",
  requisitionUploadS3ObjectIDs = "requisitionUploadS3ObjectIDs",
  commentsFromCoordinatorAboutPatient = "commentsFromCoordinatorAboutPatient",
  commentsFromSchedulerAboutEncounter = "commentsFromSchedulerAboutEncounter",
  commentsFromSchedulerToTechAboutEncounter = "commentsFromSchedulerToTechAboutEncounter",
  commentsFromTechAboutEncounter = "commentsFromTechAboutEncounter",
  commentsFromCustomerSupportAboutEncounter = "commentsFromCustomerSupportAboutEncounter",
  commentsFromSchedulerToPartnerAboutEncounter = "commentsFromSchedulerToPartnerAboutEncounter",
  productTypes = "productTypes",
  isMedicare = "isMedicare",
  isMedicareEligible = "isMedicareEligible",
  wasPatientManuallyAdded = "wasPatientManuallyAdded",
  patientEncounterSummaryLastSentAt = "patientEncounterSummaryLastSentAt",
  cologuardOrderSuccessfullyPlacedAt = "cologuardOrderSuccessfullyPlacedAt",
  hasPatientRequestedMailedResults = "hasPatientRequestedMailedResults",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableEncounterConnection = {
  __typename: "SearchableEncounterConnection",
  items:  Array<Encounter | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelCareNavigationEpisodeFilterInput = {
  closedAt?: ModelStringInput | null,
  careNavigationGroup?: ModelCareNavigationGroupInput | null,
  patientID?: ModelIDInput | null,
  partnerOrganization?: ModelPartnerOrganizationInput | null,
  referralID?: ModelIDInput | null,
  zendeskTicketID?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCareNavigationEpisodeFilterInput | null > | null,
  or?: Array< ModelCareNavigationEpisodeFilterInput | null > | null,
  not?: ModelCareNavigationEpisodeFilterInput | null,
};

export type SearchableCareNavigationEpisodeFilterInput = {
  closedAt?: SearchableStringFilterInput | null,
  patientID?: SearchableIDFilterInput | null,
  referralID?: SearchableIDFilterInput | null,
  zendeskTicketID?: SearchableIntFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  id?: SearchableIDFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  careNavigationGroup?: SearchableStringFilterInput | null,
  partnerOrganization?: SearchableStringFilterInput | null,
  and?: Array< SearchableCareNavigationEpisodeFilterInput | null > | null,
  or?: Array< SearchableCareNavigationEpisodeFilterInput | null > | null,
  not?: SearchableCareNavigationEpisodeFilterInput | null,
};

export type SearchableCareNavigationEpisodeSortInput = {
  field?: SearchableCareNavigationEpisodeSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableCareNavigationEpisodeSortableFields {
  closedAt = "closedAt",
  patientID = "patientID",
  referralID = "referralID",
  zendeskTicketID = "zendeskTicketID",
  createdAt = "createdAt",
  id = "id",
  updatedAt = "updatedAt",
}


export type SearchableCareNavigationEpisodeAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableCareNavigationEpisodeAggregateField,
};

export enum SearchableCareNavigationEpisodeAggregateField {
  closedAt = "closedAt",
  careNavigationGroup = "careNavigationGroup",
  patientID = "patientID",
  partnerOrganization = "partnerOrganization",
  referralID = "referralID",
  zendeskTicketID = "zendeskTicketID",
  createdAt = "createdAt",
  id = "id",
  updatedAt = "updatedAt",
}


export type SearchableCareNavigationEpisodeConnection = {
  __typename: "SearchableCareNavigationEpisodeConnection",
  items:  Array<CareNavigationEpisode | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelS3ObjectDataFilterInput = {
  id?: ModelIDInput | null,
  region?: ModelStringInput | null,
  bucket?: ModelStringInput | null,
  key?: ModelStringInput | null,
  identityID?: ModelStringInput | null,
  parentType?: ModelS3ObjectParentTypeInput | null,
  parentID?: ModelIDInput | null,
  contentType?: ModelStringInput | null,
  and?: Array< ModelS3ObjectDataFilterInput | null > | null,
  or?: Array< ModelS3ObjectDataFilterInput | null > | null,
  not?: ModelS3ObjectDataFilterInput | null,
};

export type ModelS3ObjectDataConnection = {
  __typename: "ModelS3ObjectDataConnection",
  items:  Array<S3ObjectData | null >,
  nextToken?: string | null,
};

export type SearchableS3ObjectDataFilterInput = {
  id?: SearchableIDFilterInput | null,
  region?: SearchableStringFilterInput | null,
  bucket?: SearchableStringFilterInput | null,
  key?: SearchableStringFilterInput | null,
  identityID?: SearchableStringFilterInput | null,
  parentID?: SearchableIDFilterInput | null,
  contentType?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  parentType?: SearchableStringFilterInput | null,
  and?: Array< SearchableS3ObjectDataFilterInput | null > | null,
  or?: Array< SearchableS3ObjectDataFilterInput | null > | null,
  not?: SearchableS3ObjectDataFilterInput | null,
};

export type SearchableS3ObjectDataSortInput = {
  field?: SearchableS3ObjectDataSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableS3ObjectDataSortableFields {
  id = "id",
  region = "region",
  bucket = "bucket",
  key = "key",
  identityID = "identityID",
  parentID = "parentID",
  contentType = "contentType",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableS3ObjectDataAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableS3ObjectDataAggregateField,
};

export enum SearchableS3ObjectDataAggregateField {
  id = "id",
  region = "region",
  bucket = "bucket",
  key = "key",
  identityID = "identityID",
  parentType = "parentType",
  parentID = "parentID",
  contentType = "contentType",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableS3ObjectDataConnection = {
  __typename: "SearchableS3ObjectDataConnection",
  items:  Array<S3ObjectData | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelPartnerUserInviteFilterInput = {
  id?: ModelIDInput | null,
  email?: ModelStringInput | null,
  partnerOrganization?: ModelPartnerOrganizationInput | null,
  status?: ModelPartnerUserInviteStatusInput | null,
  expiry?: ModelIntInput | null,
  ttl?: ModelIntInput | null,
  managePartnerUsersAuthorization?: ModelManagePartnerUsersAuthorizationInput | null,
  isArchived?: ModelBooleanInput | null,
  and?: Array< ModelPartnerUserInviteFilterInput | null > | null,
  or?: Array< ModelPartnerUserInviteFilterInput | null > | null,
  not?: ModelPartnerUserInviteFilterInput | null,
};

export type ModelPartnerUserInviteConnection = {
  __typename: "ModelPartnerUserInviteConnection",
  items:  Array<PartnerUserInvite | null >,
  nextToken?: string | null,
};

export type SearchablePartnerUserInviteFilterInput = {
  id?: SearchableIDFilterInput | null,
  email?: SearchableStringFilterInput | null,
  expiry?: SearchableIntFilterInput | null,
  ttl?: SearchableIntFilterInput | null,
  isArchived?: SearchableBooleanFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  partnerOrganization?: SearchableStringFilterInput | null,
  status?: SearchableStringFilterInput | null,
  managePartnerUsersAuthorization?: SearchableStringFilterInput | null,
  and?: Array< SearchablePartnerUserInviteFilterInput | null > | null,
  or?: Array< SearchablePartnerUserInviteFilterInput | null > | null,
  not?: SearchablePartnerUserInviteFilterInput | null,
};

export type SearchablePartnerUserInviteSortInput = {
  field?: SearchablePartnerUserInviteSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchablePartnerUserInviteSortableFields {
  id = "id",
  email = "email",
  expiry = "expiry",
  ttl = "ttl",
  isArchived = "isArchived",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchablePartnerUserInviteAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchablePartnerUserInviteAggregateField,
};

export enum SearchablePartnerUserInviteAggregateField {
  id = "id",
  email = "email",
  partnerOrganization = "partnerOrganization",
  status = "status",
  expiry = "expiry",
  ttl = "ttl",
  managePartnerUsersAuthorization = "managePartnerUsersAuthorization",
  isArchived = "isArchived",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchablePartnerUserInviteConnection = {
  __typename: "SearchablePartnerUserInviteConnection",
  items:  Array<PartnerUserInvite | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelPractitionerFilterInput = {
  id?: ModelIDInput | null,
  NPI?: ModelStringInput | null,
  practitionerGroupName?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  email?: ModelStringInput | null,
  fax?: ModelStringInput | null,
  faxConfirmationStatus?: ModelFaxConfirmationStatusInput | null,
  faxLastConfirmedAt?: ModelStringInput | null,
  testFaxLastSentAt?: ModelStringInput | null,
  incorrectFaxes?: ModelStringInput | null,
  role?: ModelPractitionerRoleInput | null,
  and?: Array< ModelPractitionerFilterInput | null > | null,
  or?: Array< ModelPractitionerFilterInput | null > | null,
  not?: ModelPractitionerFilterInput | null,
};

export type ModelPractitionerConnection = {
  __typename: "ModelPractitionerConnection",
  items:  Array<Practitioner | null >,
  nextToken?: string | null,
};

export type SearchablePractitionerFilterInput = {
  NPI?: SearchableStringFilterInput | null,
  fullName?: SearchableStringFilterInput | null,
  practitionerGroupName?: SearchableStringFilterInput | null,
  fax?: SearchableStringFilterInput | null,
  phone?: SearchableStringFilterInput | null,
  role?: SearchableStringFilterInput | null,
  and?: Array< SearchablePractitionerFilterInput | null > | null,
  or?: Array< SearchablePractitionerFilterInput | null > | null,
  not?: SearchablePractitionerFilterInput | null,
};

export type SearchablePractitionerSortInput = {
  field?: SearchablePractitionerSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchablePractitionerSortableFields {
  id = "id",
  NPI = "NPI",
  practitionerGroupName = "practitionerGroupName",
  phone = "phone",
  email = "email",
  fax = "fax",
  faxLastConfirmedAt = "faxLastConfirmedAt",
  testFaxLastSentAt = "testFaxLastSentAt",
  incorrectFaxes = "incorrectFaxes",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchablePractitionerAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchablePractitionerAggregateField,
};

export enum SearchablePractitionerAggregateField {
  id = "id",
  NPI = "NPI",
  practitionerGroupName = "practitionerGroupName",
  phone = "phone",
  email = "email",
  fax = "fax",
  faxConfirmationStatus = "faxConfirmationStatus",
  faxLastConfirmedAt = "faxLastConfirmedAt",
  testFaxLastSentAt = "testFaxLastSentAt",
  incorrectFaxes = "incorrectFaxes",
  role = "role",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchablePractitionerConnection = {
  __typename: "SearchablePractitionerConnection",
  items:  Array<Practitioner | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelTechFilterInput = {
  id?: ModelIDInput | null,
  gender?: ModelGenderInput | null,
  phone?: ModelStringInput | null,
  email?: ModelStringInput | null,
  serviceTypes?: ModelServiceTypeListInput | null,
  profilePictureS3ObjectID?: ModelIDInput | null,
  isActive?: ModelBooleanInput | null,
  biographyItems?: ModelStringInput | null,
  patientTestimonials?: ModelStringInput | null,
  partialAppointmentsForTeamAvailabilityJSON?: ModelStringInput | null,
  capabilities?: ModelTechCapabilityListInput | null,
  isIneligibleForMatchmaking?: ModelBooleanInput | null,
  and?: Array< ModelTechFilterInput | null > | null,
  or?: Array< ModelTechFilterInput | null > | null,
  not?: ModelTechFilterInput | null,
};

export type ModelTechConnection = {
  __typename: "ModelTechConnection",
  items:  Array<Tech | null >,
  nextToken?: string | null,
};

export type SearchableTechFilterInput = {
  id?: SearchableIDFilterInput | null,
  phone?: SearchableStringFilterInput | null,
  email?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  gender?: SearchableStringFilterInput | null,
  serviceTypes?: SearchableStringFilterInput | null,
  fullName?: SearchableStringFilterInput | null,
  and?: Array< SearchableTechFilterInput | null > | null,
  or?: Array< SearchableTechFilterInput | null > | null,
  not?: SearchableTechFilterInput | null,
};

export type SearchableTechSortInput = {
  field?: SearchableTechSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableTechSortableFields {
  id = "id",
  phone = "phone",
  email = "email",
  profilePictureS3ObjectID = "profilePictureS3ObjectID",
  isActive = "isActive",
  biographyItems = "biographyItems",
  patientTestimonials = "patientTestimonials",
  partialAppointmentsForTeamAvailabilityJSON = "partialAppointmentsForTeamAvailabilityJSON",
  isIneligibleForMatchmaking = "isIneligibleForMatchmaking",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableTechAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableTechAggregateField,
};

export enum SearchableTechAggregateField {
  id = "id",
  gender = "gender",
  phone = "phone",
  email = "email",
  serviceTypes = "serviceTypes",
  profilePictureS3ObjectID = "profilePictureS3ObjectID",
  isActive = "isActive",
  biographyItems = "biographyItems",
  patientTestimonials = "patientTestimonials",
  partialAppointmentsForTeamAvailabilityJSON = "partialAppointmentsForTeamAvailabilityJSON",
  capabilities = "capabilities",
  isIneligibleForMatchmaking = "isIneligibleForMatchmaking",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableTechConnection = {
  __typename: "SearchableTechConnection",
  items:  Array<Tech | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelTechServiceRegionFilterInput = {
  id?: ModelIDInput | null,
  techID?: ModelIDInput | null,
  serviceRegionName?: ModelStringInput | null,
  and?: Array< ModelTechServiceRegionFilterInput | null > | null,
  or?: Array< ModelTechServiceRegionFilterInput | null > | null,
  not?: ModelTechServiceRegionFilterInput | null,
};

export type SearchableTechServiceRegionFilterInput = {
  id?: SearchableIDFilterInput | null,
  techID?: SearchableIDFilterInput | null,
  serviceRegionName?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  metroName?: SearchableStringFilterInput | null,
  techFullName?: SearchableStringFilterInput | null,
  and?: Array< SearchableTechServiceRegionFilterInput | null > | null,
  or?: Array< SearchableTechServiceRegionFilterInput | null > | null,
  not?: SearchableTechServiceRegionFilterInput | null,
};

export type SearchableTechServiceRegionSortInput = {
  field?: SearchableTechServiceRegionSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableTechServiceRegionSortableFields {
  id = "id",
  techID = "techID",
  serviceRegionName = "serviceRegionName",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableTechServiceRegionAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableTechServiceRegionAggregateField,
};

export enum SearchableTechServiceRegionAggregateField {
  id = "id",
  techID = "techID",
  serviceRegionName = "serviceRegionName",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableTechServiceRegionConnection = {
  __typename: "SearchableTechServiceRegionConnection",
  items:  Array<TechServiceRegion | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelRemoteTechFilterInput = {
  id?: ModelIDInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  profilePictureS3ObjectID?: ModelIDInput | null,
  timezone?: ModelTimezoneInput | null,
  isActive?: ModelBooleanInput | null,
  remoteVisitZoomLink?: ModelStringInput | null,
  remoteVisitZoomLinkQRCodeS3ObjectID?: ModelIDInput | null,
  partialAppointmentsForTeamAvailabilityJSON?: ModelStringInput | null,
  and?: Array< ModelRemoteTechFilterInput | null > | null,
  or?: Array< ModelRemoteTechFilterInput | null > | null,
  not?: ModelRemoteTechFilterInput | null,
};

export type ModelRemoteTechConnection = {
  __typename: "ModelRemoteTechConnection",
  items:  Array<RemoteTech | null >,
  nextToken?: string | null,
};

export type SearchableRemoteTechFilterInput = {
  id?: SearchableIDFilterInput | null,
  email?: SearchableStringFilterInput | null,
  phone?: SearchableStringFilterInput | null,
  profilePictureS3ObjectID?: SearchableIDFilterInput | null,
  isActive?: SearchableBooleanFilterInput | null,
  remoteVisitZoomLink?: SearchableStringFilterInput | null,
  remoteVisitZoomLinkQRCodeS3ObjectID?: SearchableIDFilterInput | null,
  partialAppointmentsForTeamAvailabilityJSON?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  timezone?: SearchableStringFilterInput | null,
  and?: Array< SearchableRemoteTechFilterInput | null > | null,
  or?: Array< SearchableRemoteTechFilterInput | null > | null,
  not?: SearchableRemoteTechFilterInput | null,
};

export type SearchableRemoteTechSortInput = {
  field?: SearchableRemoteTechSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableRemoteTechSortableFields {
  id = "id",
  email = "email",
  phone = "phone",
  profilePictureS3ObjectID = "profilePictureS3ObjectID",
  isActive = "isActive",
  remoteVisitZoomLink = "remoteVisitZoomLink",
  remoteVisitZoomLinkQRCodeS3ObjectID = "remoteVisitZoomLinkQRCodeS3ObjectID",
  partialAppointmentsForTeamAvailabilityJSON = "partialAppointmentsForTeamAvailabilityJSON",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableRemoteTechAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableRemoteTechAggregateField,
};

export enum SearchableRemoteTechAggregateField {
  id = "id",
  email = "email",
  phone = "phone",
  profilePictureS3ObjectID = "profilePictureS3ObjectID",
  timezone = "timezone",
  isActive = "isActive",
  remoteVisitZoomLink = "remoteVisitZoomLink",
  remoteVisitZoomLinkQRCodeS3ObjectID = "remoteVisitZoomLinkQRCodeS3ObjectID",
  partialAppointmentsForTeamAvailabilityJSON = "partialAppointmentsForTeamAvailabilityJSON",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableRemoteTechConnection = {
  __typename: "SearchableRemoteTechConnection",
  items:  Array<RemoteTech | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelCoordinatorFilterInput = {
  id?: ModelIDInput | null,
  authorizedTechIDs?: ModelIDInput | null,
  isCSR?: ModelBooleanInput | null,
  isActive?: ModelBooleanInput | null,
  managePartnerUsersAuthorization?: ModelManagePartnerUsersAuthorizationInput | null,
  authorizedPartnerOrganizations?: ModelPartnerOrganizationListInput | null,
  phone?: ModelStringInput | null,
  email?: ModelStringInput | null,
  billingCustomerID?: ModelStringInput | null,
  outstandingPartnerOrganizationBookingURLs?: ModelStringInput | null,
  stripeCustomerID?: ModelStringInput | null,
  isArchived?: ModelBooleanInput | null,
  and?: Array< ModelCoordinatorFilterInput | null > | null,
  or?: Array< ModelCoordinatorFilterInput | null > | null,
  not?: ModelCoordinatorFilterInput | null,
};

export type ModelCoordinatorConnection = {
  __typename: "ModelCoordinatorConnection",
  items:  Array<Coordinator | null >,
  nextToken?: string | null,
};

export type SearchableCoordinatorFilterInput = {
  id?: SearchableIDFilterInput | null,
  authorizedTechIDs?: SearchableIDFilterInput | null,
  isCSR?: SearchableBooleanFilterInput | null,
  phone?: SearchableStringFilterInput | null,
  email?: SearchableStringFilterInput | null,
  billingCustomerID?: SearchableStringFilterInput | null,
  outstandingPartnerOrganizationBookingURLs?: SearchableStringFilterInput | null,
  stripeCustomerID?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  authorizedPartnerOrganizations?: SearchableStringFilterInput | null,
  partnerOrganizationGroup?: SearchableStringFilterInput | null,
  fullName?: SearchableStringFilterInput | null,
  and?: Array< SearchableCoordinatorFilterInput | null > | null,
  or?: Array< SearchableCoordinatorFilterInput | null > | null,
  not?: SearchableCoordinatorFilterInput | null,
};

export type SearchableCoordinatorSortInput = {
  field?: SearchableCoordinatorSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableCoordinatorSortableFields {
  id = "id",
  authorizedTechIDs = "authorizedTechIDs",
  isCSR = "isCSR",
  isActive = "isActive",
  phone = "phone",
  email = "email",
  billingCustomerID = "billingCustomerID",
  outstandingPartnerOrganizationBookingURLs = "outstandingPartnerOrganizationBookingURLs",
  stripeCustomerID = "stripeCustomerID",
  isArchived = "isArchived",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableCoordinatorAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableCoordinatorAggregateField,
};

export enum SearchableCoordinatorAggregateField {
  id = "id",
  authorizedTechIDs = "authorizedTechIDs",
  isCSR = "isCSR",
  isActive = "isActive",
  managePartnerUsersAuthorization = "managePartnerUsersAuthorization",
  authorizedPartnerOrganizations = "authorizedPartnerOrganizations",
  phone = "phone",
  email = "email",
  billingCustomerID = "billingCustomerID",
  outstandingPartnerOrganizationBookingURLs = "outstandingPartnerOrganizationBookingURLs",
  stripeCustomerID = "stripeCustomerID",
  isArchived = "isArchived",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableCoordinatorConnection = {
  __typename: "SearchableCoordinatorConnection",
  items:  Array<Coordinator | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelPatientFilterInput = {
  id?: ModelIDInput | null,
  csrPartnerOrganization?: ModelPartnerOrganizationInput | null,
  partnerOrganization?: ModelPartnerOrganizationInput | null,
  coordinatorID?: ModelIDInput | null,
  medicareCardID?: ModelStringInput | null,
  imageMoverUserID?: ModelIDInput | null,
  externalID?: ModelStringInput | null,
  secondaryExternalID?: ModelStringInput | null,
  specimenLabelID?: ModelStringInput | null,
  qtMedicalPatientID?: ModelIDInput | null,
  planName?: ModelStringInput | null,
  diabeticType?: ModelDiabeticTypeInput | null,
  authorizedTechIDs?: ModelIDInput | null,
  isCoordinator?: ModelBooleanInput | null,
  phone?: ModelStringInput | null,
  email?: ModelStringInput | null,
  secondaryPhone?: ModelStringInput | null,
  birthdate?: ModelStringInput | null,
  sex?: ModelSexInput | null,
  gender?: ModelGenderInput | null,
  ethnicity?: ModelEthnicityInput | null,
  races?: ModelRaceListInput | null,
  passportNumber?: ModelStringInput | null,
  insuranceCardUploadFrontS3ObjectID?: ModelIDInput | null,
  insuranceCardUploadBackS3ObjectID?: ModelIDInput | null,
  medicareCardUploadFrontS3ObjectID?: ModelIDInput | null,
  medicareCardUploadBackS3ObjectID?: ModelIDInput | null,
  watchlistedAppointmentID?: ModelIDInput | null,
  responsiblePractitionerID?: ModelIDInput | null,
  commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: ModelStringInput | null,
  commentsFromCaregiverAboutResponsiblePractitioner?: ModelStringInput | null,
  suggestedAppointmentLocationAdditionalInformation?: ModelStringInput | null,
  preferredLanguage?: ModelStringInput | null,
  primaryLanguageBCP47?: ModelStringInput | null,
  secondaryLanguagesBCP47?: ModelStringInput | null,
  patientNumber?: ModelStringInput | null,
  latestGapListUploadID?: ModelStringInput | null,
  latestGapListRowJSON?: ModelStringInput | null,
  latestGapListPassthroughJSON?: ModelStringInput | null,
  brandCode?: ModelStringInput | null,
  population?: ModelPatientPopulationInput | null,
  elationID?: ModelStringInput | null,
  and?: Array< ModelPatientFilterInput | null > | null,
  or?: Array< ModelPatientFilterInput | null > | null,
  not?: ModelPatientFilterInput | null,
};

export type SearchablePatientFilterInput = {
  id?: SearchableIDFilterInput | null,
  coordinatorID?: SearchableIDFilterInput | null,
  medicareCardID?: SearchableStringFilterInput | null,
  imageMoverUserID?: SearchableIDFilterInput | null,
  authorizedTechIDs?: SearchableIDFilterInput | null,
  isCoordinator?: SearchableBooleanFilterInput | null,
  phone?: SearchableStringFilterInput | null,
  email?: SearchableStringFilterInput | null,
  birthdate?: SearchableStringFilterInput | null,
  passportNumber?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  csrPartnerOrganization?: SearchableStringFilterInput | null,
  partnerOrganization?: SearchableStringFilterInput | null,
  sex?: SearchableStringFilterInput | null,
  gender?: SearchableStringFilterInput | null,
  ethnicity?: SearchableStringFilterInput | null,
  races?: SearchableStringFilterInput | null,
  partnerVertical?: SearchableStringFilterInput | null,
  partnerDisplayName?: SearchableStringFilterInput | null,
  partnerOrganizationGroup?: SearchableStringFilterInput | null,
  fullName?: SearchableStringFilterInput | null,
  and?: Array< SearchablePatientFilterInput | null > | null,
  or?: Array< SearchablePatientFilterInput | null > | null,
  not?: SearchablePatientFilterInput | null,
};

export type SearchablePatientSortInput = {
  field?: SearchablePatientSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchablePatientSortableFields {
  id = "id",
  coordinatorID = "coordinatorID",
  medicareCardID = "medicareCardID",
  imageMoverUserID = "imageMoverUserID",
  externalID = "externalID",
  secondaryExternalID = "secondaryExternalID",
  specimenLabelID = "specimenLabelID",
  qtMedicalPatientID = "qtMedicalPatientID",
  planName = "planName",
  authorizedTechIDs = "authorizedTechIDs",
  isCoordinator = "isCoordinator",
  phone = "phone",
  email = "email",
  secondaryPhone = "secondaryPhone",
  birthdate = "birthdate",
  passportNumber = "passportNumber",
  insuranceCardUploadFrontS3ObjectID = "insuranceCardUploadFrontS3ObjectID",
  insuranceCardUploadBackS3ObjectID = "insuranceCardUploadBackS3ObjectID",
  medicareCardUploadFrontS3ObjectID = "medicareCardUploadFrontS3ObjectID",
  medicareCardUploadBackS3ObjectID = "medicareCardUploadBackS3ObjectID",
  watchlistedAppointmentID = "watchlistedAppointmentID",
  responsiblePractitionerID = "responsiblePractitionerID",
  commentsFromPatientOrCaregiverAboutResponsiblePractitioner = "commentsFromPatientOrCaregiverAboutResponsiblePractitioner",
  commentsFromCaregiverAboutResponsiblePractitioner = "commentsFromCaregiverAboutResponsiblePractitioner",
  suggestedAppointmentLocationAdditionalInformation = "suggestedAppointmentLocationAdditionalInformation",
  preferredLanguage = "preferredLanguage",
  primaryLanguageBCP47 = "primaryLanguageBCP47",
  secondaryLanguagesBCP47 = "secondaryLanguagesBCP47",
  patientNumber = "patientNumber",
  latestGapListUploadID = "latestGapListUploadID",
  latestGapListRowJSON = "latestGapListRowJSON",
  latestGapListPassthroughJSON = "latestGapListPassthroughJSON",
  brandCode = "brandCode",
  elationID = "elationID",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchablePatientAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchablePatientAggregateField,
};

export enum SearchablePatientAggregateField {
  id = "id",
  csrPartnerOrganization = "csrPartnerOrganization",
  partnerOrganization = "partnerOrganization",
  coordinatorID = "coordinatorID",
  medicareCardID = "medicareCardID",
  imageMoverUserID = "imageMoverUserID",
  externalID = "externalID",
  secondaryExternalID = "secondaryExternalID",
  specimenLabelID = "specimenLabelID",
  qtMedicalPatientID = "qtMedicalPatientID",
  planName = "planName",
  diabeticType = "diabeticType",
  authorizedTechIDs = "authorizedTechIDs",
  isCoordinator = "isCoordinator",
  phone = "phone",
  email = "email",
  secondaryPhone = "secondaryPhone",
  birthdate = "birthdate",
  sex = "sex",
  gender = "gender",
  ethnicity = "ethnicity",
  races = "races",
  passportNumber = "passportNumber",
  insuranceCardUploadFrontS3ObjectID = "insuranceCardUploadFrontS3ObjectID",
  insuranceCardUploadBackS3ObjectID = "insuranceCardUploadBackS3ObjectID",
  medicareCardUploadFrontS3ObjectID = "medicareCardUploadFrontS3ObjectID",
  medicareCardUploadBackS3ObjectID = "medicareCardUploadBackS3ObjectID",
  watchlistedAppointmentID = "watchlistedAppointmentID",
  responsiblePractitionerID = "responsiblePractitionerID",
  commentsFromPatientOrCaregiverAboutResponsiblePractitioner = "commentsFromPatientOrCaregiverAboutResponsiblePractitioner",
  commentsFromCaregiverAboutResponsiblePractitioner = "commentsFromCaregiverAboutResponsiblePractitioner",
  suggestedAppointmentLocationAdditionalInformation = "suggestedAppointmentLocationAdditionalInformation",
  preferredLanguage = "preferredLanguage",
  primaryLanguageBCP47 = "primaryLanguageBCP47",
  secondaryLanguagesBCP47 = "secondaryLanguagesBCP47",
  patientNumber = "patientNumber",
  latestGapListUploadID = "latestGapListUploadID",
  latestGapListRowJSON = "latestGapListRowJSON",
  latestGapListPassthroughJSON = "latestGapListPassthroughJSON",
  brandCode = "brandCode",
  population = "population",
  elationID = "elationID",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchablePatientConnection = {
  __typename: "SearchablePatientConnection",
  items:  Array<Patient | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelPatientContactPointFilterInput = {
  id?: ModelIDInput | null,
  patientID?: ModelIDInput | null,
  system?: ModelContactPointSystemInput | null,
  value?: ModelStringInput | null,
  status?: ModelContactPointStatusInput | null,
  statusLastUpdatedAt?: ModelStringInput | null,
  and?: Array< ModelPatientContactPointFilterInput | null > | null,
  or?: Array< ModelPatientContactPointFilterInput | null > | null,
  not?: ModelPatientContactPointFilterInput | null,
};

export type SearchablePatientContactPointFilterInput = {
  id?: SearchableIDFilterInput | null,
  patientID?: SearchableIDFilterInput | null,
  value?: SearchableStringFilterInput | null,
  statusLastUpdatedAt?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  system?: SearchableStringFilterInput | null,
  status?: SearchableStringFilterInput | null,
  and?: Array< SearchablePatientContactPointFilterInput | null > | null,
  or?: Array< SearchablePatientContactPointFilterInput | null > | null,
  not?: SearchablePatientContactPointFilterInput | null,
};

export type SearchablePatientContactPointSortInput = {
  field?: SearchablePatientContactPointSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchablePatientContactPointSortableFields {
  id = "id",
  patientID = "patientID",
  value = "value",
  statusLastUpdatedAt = "statusLastUpdatedAt",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchablePatientContactPointAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchablePatientContactPointAggregateField,
};

export enum SearchablePatientContactPointAggregateField {
  id = "id",
  patientID = "patientID",
  system = "system",
  value = "value",
  status = "status",
  statusLastUpdatedAt = "statusLastUpdatedAt",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchablePatientContactPointConnection = {
  __typename: "SearchablePatientContactPointConnection",
  items:  Array<PatientContactPoint | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelReferralFilterInput = {
  id?: ModelIDInput | null,
  patientID?: ModelIDInput | null,
  partnerOrganization?: ModelPartnerOrganizationInput | null,
  requisitionUploadS3ObjectIDs?: ModelIDInput | null,
  serviceTypes?: ModelServiceTypeInput | null,
  isBooked?: ModelBooleanInput | null,
  isExpired?: ModelBooleanInput | null,
  ttl?: ModelIntInput | null,
  and?: Array< ModelReferralFilterInput | null > | null,
  or?: Array< ModelReferralFilterInput | null > | null,
  not?: ModelReferralFilterInput | null,
};

export type SearchableReferralFilterInput = {
  id?: SearchableIDFilterInput | null,
  patientID?: SearchableIDFilterInput | null,
  metroName?: SearchableStringFilterInput | null,
  isBooked?: SearchableBooleanFilterInput | null,
  partnerOrganization?: SearchableStringFilterInput | null,
  partnerVertical?: SearchableStringFilterInput | null,
  partnerDisplayName?: SearchableStringFilterInput | null,
  partnerOrganizationGroup?: SearchableStringFilterInput | null,
  isExpired?: SearchableBooleanFilterInput | null,
  serviceTypes?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableTechServiceRegionFilterInput | null > | null,
  or?: Array< SearchableTechServiceRegionFilterInput | null > | null,
  not?: SearchableTechServiceRegionFilterInput | null,
};

export type SearchableReferralSortInput = {
  field?: SearchableReferralSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableReferralSortableFields {
  id = "id",
  patientID = "patientID",
  requisitionUploadS3ObjectIDs = "requisitionUploadS3ObjectIDs",
  isBooked = "isBooked",
  isExpired = "isExpired",
  ttl = "ttl",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableReferralAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableReferralAggregateField,
};

export enum SearchableReferralAggregateField {
  id = "id",
  patientID = "patientID",
  partnerOrganization = "partnerOrganization",
  requisitionUploadS3ObjectIDs = "requisitionUploadS3ObjectIDs",
  serviceTypes = "serviceTypes",
  isBooked = "isBooked",
  isExpired = "isExpired",
  ttl = "ttl",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableReferralConnection = {
  __typename: "SearchableReferralConnection",
  items:  Array<Referral | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelPatientCampaignStateFilterInput = {
  id?: ModelIDInput | null,
  patientID?: ModelIDInput | null,
  name?: ModelPatientCampaignStateNameInput | null,
  campaignName?: ModelCampaignNameInput | null,
  eligibilityStart?: ModelStringInput | null,
  eligibilityEnd?: ModelStringInput | null,
  cohort?: ModelIntInput | null,
  contextJSON?: ModelStringInput | null,
  enteredAt?: ModelIntInput | null,
  partnerOrganization?: ModelPartnerOrganizationInput | null,
  previousStateName?: ModelPatientCampaignStateNameInput | null,
  reasonForTransitionToTerminalState?: ModelReasonForTransitionToTerminalStateInput | null,
  and?: Array< ModelPatientCampaignStateFilterInput | null > | null,
  or?: Array< ModelPatientCampaignStateFilterInput | null > | null,
  not?: ModelPatientCampaignStateFilterInput | null,
};

export type SearchablePatientCampaignStateFilterInput = {
  id?: SearchableIDFilterInput | null,
  patientID?: SearchableIDFilterInput | null,
  metroName?: SearchableStringFilterInput | null,
  serviceRegionName?: SearchableStringFilterInput | null,
  patientFullName?: SearchableStringFilterInput | null,
  patientPlan?: SearchableStringFilterInput | null,
  patientPreferredLanguage?: SearchableStringFilterInput | null,
  patientPrimaryLanguageBCP47?: SearchableStringFilterInput | null,
  campaignDisplayName?: SearchableStringFilterInput | null,
  campaignType?: SearchableStringFilterInput | null,
  campaignGroup?: SearchableStringFilterInput | null,
  campaignStatus?: SearchableStringFilterInput | null,
  partnerVertical?: SearchableStringFilterInput | null,
  partnerDisplayName?: SearchableStringFilterInput | null,
  partnerOrganizationGroup?: SearchableStringFilterInput | null,
  eligibilityStart?: SearchableStringFilterInput | null,
  eligibilityEnd?: SearchableStringFilterInput | null,
  cohort?: SearchableIntFilterInput | null,
  contextJSON?: SearchableStringFilterInput | null,
  enteredAt?: SearchableIntFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  name?: SearchableStringFilterInput | null,
  campaignName?: SearchableStringFilterInput | null,
  partnerOrganization?: SearchableStringFilterInput | null,
  and?: Array< SearchablePatientCampaignStateFilterInput | null > | null,
  or?: Array< SearchablePatientCampaignStateFilterInput | null > | null,
  not?: SearchablePatientCampaignStateFilterInput | null,
};

export type SearchablePatientCampaignStateSortInput = {
  field?: SearchablePatientCampaignStateSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchablePatientCampaignStateSortableFields {
  id = "id",
  patientID = "patientID",
  eligibilityStart = "eligibilityStart",
  eligibilityEnd = "eligibilityEnd",
  cohort = "cohort",
  contextJSON = "contextJSON",
  enteredAt = "enteredAt",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchablePatientCampaignStateAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchablePatientCampaignStateAggregateField,
};

export enum SearchablePatientCampaignStateAggregateField {
  id = "id",
  patientID = "patientID",
  name = "name",
  campaignName = "campaignName",
  eligibilityStart = "eligibilityStart",
  eligibilityEnd = "eligibilityEnd",
  cohort = "cohort",
  contextJSON = "contextJSON",
  enteredAt = "enteredAt",
  partnerOrganization = "partnerOrganization",
  previousStateName = "previousStateName",
  reasonForTransitionToTerminalState = "reasonForTransitionToTerminalState",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchablePatientCampaignStateConnection = {
  __typename: "SearchablePatientCampaignStateConnection",
  items:  Array<PatientCampaignState | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelProjectedEngagementActivityScheduleFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  status?: ModelProjectedEngagementActivityScheduleStatusInput | null,
  acceptedAt?: ModelStringInput | null,
  totalBatches?: ModelIntInput | null,
  processedBatches?: ModelIntInput | null,
  parametersJSON?: ModelStringInput | null,
  and?: Array< ModelProjectedEngagementActivityScheduleFilterInput | null > | null,
  or?: Array< ModelProjectedEngagementActivityScheduleFilterInput | null > | null,
  not?: ModelProjectedEngagementActivityScheduleFilterInput | null,
};

export type ModelProjectedEngagementActivityScheduleConnection = {
  __typename: "ModelProjectedEngagementActivityScheduleConnection",
  items:  Array<ProjectedEngagementActivitySchedule | null >,
  nextToken?: string | null,
};

export type SearchableProjectedEngagementActivityScheduleFilterInput = {
  id?: SearchableIDFilterInput | null,
  name?: SearchableStringFilterInput | null,
  acceptedAt?: SearchableStringFilterInput | null,
  totalBatches?: SearchableIntFilterInput | null,
  processedBatches?: SearchableIntFilterInput | null,
  parametersJSON?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  status?: SearchableStringFilterInput | null,
  and?: Array< SearchableProjectedEngagementActivityScheduleFilterInput | null > | null,
  or?: Array< SearchableProjectedEngagementActivityScheduleFilterInput | null > | null,
  not?: SearchableProjectedEngagementActivityScheduleFilterInput | null,
};

export type SearchableProjectedEngagementActivityScheduleSortInput = {
  field?: SearchableProjectedEngagementActivityScheduleSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableProjectedEngagementActivityScheduleSortableFields {
  id = "id",
  name = "name",
  acceptedAt = "acceptedAt",
  totalBatches = "totalBatches",
  processedBatches = "processedBatches",
  parametersJSON = "parametersJSON",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableProjectedEngagementActivityScheduleAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableProjectedEngagementActivityScheduleAggregateField,
};

export enum SearchableProjectedEngagementActivityScheduleAggregateField {
  id = "id",
  name = "name",
  status = "status",
  acceptedAt = "acceptedAt",
  totalBatches = "totalBatches",
  processedBatches = "processedBatches",
  parametersJSON = "parametersJSON",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableProjectedEngagementActivityScheduleConnection = {
  __typename: "SearchableProjectedEngagementActivityScheduleConnection",
  items:  Array<ProjectedEngagementActivitySchedule | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelUnlinkableReportFilterInput = {
  id?: ModelIDInput | null,
  externalID?: ModelStringInput | null,
  zendeskTicketID?: ModelIntInput | null,
  unlinkedReason?: ModelUnlinkedReasonInput | null,
  hasBeenResolved?: ModelBooleanInput | null,
  patientNumber?: ModelStringInput | null,
  requisitionNumber?: ModelStringInput | null,
  and?: Array< ModelUnlinkableReportFilterInput | null > | null,
  or?: Array< ModelUnlinkableReportFilterInput | null > | null,
  not?: ModelUnlinkableReportFilterInput | null,
};

export type ModelUnlinkableReportConnection = {
  __typename: "ModelUnlinkableReportConnection",
  items:  Array<UnlinkableReport | null >,
  nextToken?: string | null,
};

export type SearchableUnlinkableReportFilterInput = {
  id?: SearchableIDFilterInput | null,
  externalID?: SearchableStringFilterInput | null,
  zendeskTicketID?: SearchableIntFilterInput | null,
  hasBeenResolved?: SearchableBooleanFilterInput | null,
  patientNumber?: SearchableStringFilterInput | null,
  requisitionNumber?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  unlinkedReason?: SearchableStringFilterInput | null,
  and?: Array< SearchableUnlinkableReportFilterInput | null > | null,
  or?: Array< SearchableUnlinkableReportFilterInput | null > | null,
  not?: SearchableUnlinkableReportFilterInput | null,
};

export type SearchableUnlinkableReportSortInput = {
  field?: SearchableUnlinkableReportSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableUnlinkableReportSortableFields {
  id = "id",
  externalID = "externalID",
  zendeskTicketID = "zendeskTicketID",
  hasBeenResolved = "hasBeenResolved",
  patientNumber = "patientNumber",
  requisitionNumber = "requisitionNumber",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableUnlinkableReportAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableUnlinkableReportAggregateField,
};

export enum SearchableUnlinkableReportAggregateField {
  id = "id",
  externalID = "externalID",
  zendeskTicketID = "zendeskTicketID",
  unlinkedReason = "unlinkedReason",
  hasBeenResolved = "hasBeenResolved",
  patientNumber = "patientNumber",
  requisitionNumber = "requisitionNumber",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableUnlinkableReportConnection = {
  __typename: "SearchableUnlinkableReportConnection",
  items:  Array<UnlinkableReport | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelDropOffFilterInput = {
  id?: ModelIDInput | null,
  organization?: ModelDropOffOrganizationInput | null,
  name?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  email?: ModelStringInput | null,
  timezone?: ModelTimezoneInput | null,
  hasParking?: ModelBooleanInput | null,
  hasDropBox?: ModelBooleanInput | null,
  commentsFromScheduler?: ModelStringInput | null,
  googleMapsPlaceID?: ModelStringInput | null,
  metroName?: ModelStringInput | null,
  isActive?: ModelBooleanInput | null,
  and?: Array< ModelDropOffFilterInput | null > | null,
  or?: Array< ModelDropOffFilterInput | null > | null,
  not?: ModelDropOffFilterInput | null,
};

export type ModelDropOffConnection = {
  __typename: "ModelDropOffConnection",
  items:  Array<DropOff | null >,
  nextToken?: string | null,
};

export type SearchableDropOffFilterInput = {
  id?: SearchableIDFilterInput | null,
  name?: SearchableStringFilterInput | null,
  phone?: SearchableStringFilterInput | null,
  email?: SearchableStringFilterInput | null,
  serviceRegionName?: SearchableStringFilterInput | null,
  hasParking?: SearchableBooleanFilterInput | null,
  hasDropBox?: SearchableBooleanFilterInput | null,
  commentsFromScheduler?: SearchableStringFilterInput | null,
  googleMapsPlaceID?: SearchableStringFilterInput | null,
  metroName?: SearchableStringFilterInput | null,
  isActive?: SearchableBooleanFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  organization?: SearchableStringFilterInput | null,
  organizationName?: SearchableStringFilterInput | null,
  fullAddress?: SearchableStringFilterInput | null,
  and?: Array< SearchableDropOffFilterInput | null > | null,
  or?: Array< SearchableDropOffFilterInput | null > | null,
  not?: SearchableDropOffFilterInput | null,
};

export type SearchableDropOffSortInput = {
  field?: SearchableDropOffSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableDropOffSortableFields {
  id = "id",
  name = "name",
  phone = "phone",
  email = "email",
  hasParking = "hasParking",
  hasDropBox = "hasDropBox",
  commentsFromScheduler = "commentsFromScheduler",
  googleMapsPlaceID = "googleMapsPlaceID",
  metroName = "metroName",
  isActive = "isActive",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableDropOffAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableDropOffAggregateField,
};

export enum SearchableDropOffAggregateField {
  id = "id",
  organization = "organization",
  name = "name",
  phone = "phone",
  email = "email",
  timezone = "timezone",
  hasParking = "hasParking",
  hasDropBox = "hasDropBox",
  commentsFromScheduler = "commentsFromScheduler",
  googleMapsPlaceID = "googleMapsPlaceID",
  metroName = "metroName",
  isActive = "isActive",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableDropOffConnection = {
  __typename: "SearchableDropOffConnection",
  items:  Array<DropOff | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelServiceRequestTypeFilterInput = {
  id?: ModelIDInput | null,
  cptCodes?: ModelStringInput | null,
  externalID?: ModelStringInput | null,
  displayName?: ModelStringInput | null,
  synonyms?: ModelStringInput | null,
  labOrganization?: ModelLabOrganizationInput | null,
  container?: ModelStringInput | null,
  serviceRequestTypeContainerID?: ModelIDInput | null,
  specimen?: ModelStringInput | null,
  preferredSpecimen?: ModelStringInput | null,
  preferredVolumeDescription?: ModelStringInput | null,
  preferredVolumeMilliliters?: ModelFloatInput | null,
  minimumVolume?: ModelStringInput | null,
  minimumVolumeAmount?: ModelFloatInput | null,
  alternateSpecimen?: ModelStringInput | null,
  transportInstructions?: ModelStringInput | null,
  collectionInstructions?: ModelStringInput | null,
  collectionInstructionsVideoURL?: ModelStringInput | null,
  rejectionCriteria?: ModelStringInput | null,
  specimenStability?: ModelStringInput | null,
  patientPreparation?: ModelStringInput | null,
  resultAvailability?: ModelStringInput | null,
  serviceTypes?: ModelServiceTypeListInput | null,
  requiresBinaryPatientSex?: ModelBooleanInput | null,
  and?: Array< ModelServiceRequestTypeFilterInput | null > | null,
  or?: Array< ModelServiceRequestTypeFilterInput | null > | null,
  not?: ModelServiceRequestTypeFilterInput | null,
};

export type ModelServiceRequestTypeConnection = {
  __typename: "ModelServiceRequestTypeConnection",
  items:  Array<ServiceRequestType | null >,
  nextToken?: string | null,
};

export type SearchableServiceRequestTypeFilterInput = {
  id?: SearchableIDFilterInput | null,
  cptCodes?: SearchableStringFilterInput | null,
  externalID?: SearchableStringFilterInput | null,
  displayName?: SearchableStringFilterInput | null,
  synonyms?: SearchableStringFilterInput | null,
  container?: SearchableStringFilterInput | null,
  specimen?: SearchableStringFilterInput | null,
  preferredSpecimen?: SearchableStringFilterInput | null,
  preferredVolume?: SearchableStringFilterInput | null,
  minimumVolume?: SearchableStringFilterInput | null,
  minimumVolumeAmount?: SearchableFloatFilterInput | null,
  alternateSpecimen?: SearchableStringFilterInput | null,
  transportInstructions?: SearchableStringFilterInput | null,
  collectionInstructions?: SearchableStringFilterInput | null,
  rejectionCriteria?: SearchableStringFilterInput | null,
  specimenStability?: SearchableStringFilterInput | null,
  patientPreparation?: SearchableStringFilterInput | null,
  resultAvailability?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  labOrganization?: SearchableStringFilterInput | null,
  cptCodesJoined?: SearchableStringFilterInput | null,
  synonymsJoined?: SearchableStringFilterInput | null,
  and?: Array< SearchableServiceRequestTypeFilterInput | null > | null,
  or?: Array< SearchableServiceRequestTypeFilterInput | null > | null,
  not?: SearchableServiceRequestTypeFilterInput | null,
};

export type SearchableServiceRequestTypeSortInput = {
  field?: SearchableServiceRequestTypeSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableServiceRequestTypeSortableFields {
  id = "id",
  cptCodes = "cptCodes",
  externalID = "externalID",
  displayName = "displayName",
  synonyms = "synonyms",
  container = "container",
  serviceRequestTypeContainerID = "serviceRequestTypeContainerID",
  specimen = "specimen",
  preferredSpecimen = "preferredSpecimen",
  preferredVolumeDescription = "preferredVolumeDescription",
  preferredVolumeMilliliters = "preferredVolumeMilliliters",
  minimumVolume = "minimumVolume",
  minimumVolumeAmount = "minimumVolumeAmount",
  alternateSpecimen = "alternateSpecimen",
  transportInstructions = "transportInstructions",
  collectionInstructions = "collectionInstructions",
  collectionInstructionsVideoURL = "collectionInstructionsVideoURL",
  rejectionCriteria = "rejectionCriteria",
  specimenStability = "specimenStability",
  patientPreparation = "patientPreparation",
  resultAvailability = "resultAvailability",
  requiresBinaryPatientSex = "requiresBinaryPatientSex",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableServiceRequestTypeAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableServiceRequestTypeAggregateField,
};

export enum SearchableServiceRequestTypeAggregateField {
  id = "id",
  cptCodes = "cptCodes",
  externalID = "externalID",
  displayName = "displayName",
  synonyms = "synonyms",
  labOrganization = "labOrganization",
  container = "container",
  serviceRequestTypeContainerID = "serviceRequestTypeContainerID",
  specimen = "specimen",
  preferredSpecimen = "preferredSpecimen",
  preferredVolumeDescription = "preferredVolumeDescription",
  preferredVolumeMilliliters = "preferredVolumeMilliliters",
  minimumVolume = "minimumVolume",
  minimumVolumeAmount = "minimumVolumeAmount",
  alternateSpecimen = "alternateSpecimen",
  transportInstructions = "transportInstructions",
  collectionInstructions = "collectionInstructions",
  collectionInstructionsVideoURL = "collectionInstructionsVideoURL",
  rejectionCriteria = "rejectionCriteria",
  specimenStability = "specimenStability",
  patientPreparation = "patientPreparation",
  resultAvailability = "resultAvailability",
  serviceTypes = "serviceTypes",
  requiresBinaryPatientSex = "requiresBinaryPatientSex",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableServiceRequestTypeConnection = {
  __typename: "SearchableServiceRequestTypeConnection",
  items:  Array<ServiceRequestType | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelSpecimenContainerTypeFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  category?: ModelSpecimenContainerCategoryInput | null,
  capacityMilliliters?: ModelFloatInput | null,
  serumBloodCapacityMilliliters?: ModelFloatInput | null,
  and?: Array< ModelSpecimenContainerTypeFilterInput | null > | null,
  or?: Array< ModelSpecimenContainerTypeFilterInput | null > | null,
  not?: ModelSpecimenContainerTypeFilterInput | null,
};

export type ModelSpecimenContainerTypeConnection = {
  __typename: "ModelSpecimenContainerTypeConnection",
  items:  Array<SpecimenContainerType | null >,
  nextToken?: string | null,
};

export type SearchableSpecimenContainerTypeFilterInput = {
  id?: SearchableIDFilterInput | null,
  name?: SearchableStringFilterInput | null,
  capacityMilliliters?: SearchableFloatFilterInput | null,
  serumBloodCapacityMilliliters?: SearchableFloatFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  category?: SearchableStringFilterInput | null,
  and?: Array< SearchableSpecimenContainerTypeFilterInput | null > | null,
  or?: Array< SearchableSpecimenContainerTypeFilterInput | null > | null,
  not?: SearchableSpecimenContainerTypeFilterInput | null,
};

export type SearchableSpecimenContainerTypeSortInput = {
  field?: SearchableSpecimenContainerTypeSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableSpecimenContainerTypeSortableFields {
  id = "id",
  name = "name",
  capacityMilliliters = "capacityMilliliters",
  serumBloodCapacityMilliliters = "serumBloodCapacityMilliliters",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableSpecimenContainerTypeAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableSpecimenContainerTypeAggregateField,
};

export enum SearchableSpecimenContainerTypeAggregateField {
  id = "id",
  name = "name",
  category = "category",
  capacityMilliliters = "capacityMilliliters",
  serumBloodCapacityMilliliters = "serumBloodCapacityMilliliters",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableSpecimenContainerTypeConnection = {
  __typename: "SearchableSpecimenContainerTypeConnection",
  items:  Array<SpecimenContainerType | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelServiceRequestTypeContainerFilterInput = {
  id?: ModelIDInput | null,
  containerType?: ModelStringInput | null,
  alternativeContainerTypes?: ModelStringInput | null,
  substituteContainerTypesForCalculations?: ModelStringInput | null,
  containerCategory?: ModelStringInput | null,
  capacity?: ModelFloatInput | null,
  and?: Array< ModelServiceRequestTypeContainerFilterInput | null > | null,
  or?: Array< ModelServiceRequestTypeContainerFilterInput | null > | null,
  not?: ModelServiceRequestTypeContainerFilterInput | null,
};

export type ModelServiceRequestTypeContainerConnection = {
  __typename: "ModelServiceRequestTypeContainerConnection",
  items:  Array<ServiceRequestTypeContainer | null >,
  nextToken?: string | null,
};

export type SearchableServiceRequestTypeContainerFilterInput = {
  id?: SearchableIDFilterInput | null,
  containerType?: SearchableStringFilterInput | null,
  alternativeContainerTypes?: SearchableStringFilterInput | null,
  substituteContainerTypesForCalculations?: SearchableStringFilterInput | null,
  containerCategory?: SearchableStringFilterInput | null,
  capacity?: SearchableFloatFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableServiceRequestTypeContainerFilterInput | null > | null,
  or?: Array< SearchableServiceRequestTypeContainerFilterInput | null > | null,
  not?: SearchableServiceRequestTypeContainerFilterInput | null,
};

export type SearchableServiceRequestTypeContainerSortInput = {
  field?: SearchableServiceRequestTypeContainerSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableServiceRequestTypeContainerSortableFields {
  id = "id",
  containerType = "containerType",
  alternativeContainerTypes = "alternativeContainerTypes",
  substituteContainerTypesForCalculations = "substituteContainerTypesForCalculations",
  containerCategory = "containerCategory",
  capacity = "capacity",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableServiceRequestTypeContainerAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableServiceRequestTypeContainerAggregateField,
};

export enum SearchableServiceRequestTypeContainerAggregateField {
  id = "id",
  containerType = "containerType",
  alternativeContainerTypes = "alternativeContainerTypes",
  substituteContainerTypesForCalculations = "substituteContainerTypesForCalculations",
  containerCategory = "containerCategory",
  capacity = "capacity",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableServiceRequestTypeContainerConnection = {
  __typename: "SearchableServiceRequestTypeContainerConnection",
  items:  Array<ServiceRequestTypeContainer | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type AvailableAppointment = {
  __typename: "AvailableAppointment",
  start: string,
  end: string,
  slotScore?: number | null,
};

export type ListAppointmentsForPostReviewResponse = {
  __typename: "ListAppointmentsForPostReviewResponse",
  appointments?:  Array<Appointment > | null,
};

export type ExportTechScheduleInput = {
  techID: string,
  start: string,
  end: string,
};

export type ExportTechScheduleResponse = {
  __typename: "ExportTechScheduleResponse",
  exportedTechSchedule: string,
};

export type BookedAppointmentsInput = {
  start?: string | null,
  end?: string | null,
};

export type BookedAppointmentsPartnerOrganizationInput = {
  start?: string | null,
  end?: string | null,
};

export type GenerateClaimsBillingTemplateInput = {
  partnerOrganization: PartnerOrganization,
  start: string,
  end: string,
};

export type GenerateClaimsBillingTemplateResponse = {
  __typename: "GenerateClaimsBillingTemplateResponse",
  billingCSV: string,
};

export type CalculateSpecimenContainerTypeQuantitiesInput = {
  labOrganization: LabOrganization,
  serviceRequestIDs?: Array< string > | null,
};

export type CalculateSpecimenContainerTypeQuantitiesResponse = {
  __typename: "CalculateSpecimenContainerTypeQuantitiesResponse",
  specimenContainerTypeQuantities?:  Array<SpecimenContainerTypeQuantity > | null,
};

export type SpecimenContainerTypeQuantity = {
  __typename: "SpecimenContainerTypeQuantity",
  containerName: string,
  quantity: number,
};

export type GetContainerSpecimenVolumesInput = {
  externalID: string,
  labOrganization: LabOrganization,
};

export type GetContainerSpecimenVolumesResponse = {
  __typename: "GetContainerSpecimenVolumesResponse",
  containerOverrideID?: string | null,
  containerCategory?: SpecimenContainerCategory | null,
  minimumMilliliters?: number | null,
  preferredMilliliters?: number | null,
  isCombinable: boolean,
  minimumContainerCount?: number | null,
  specimenTestTypes?: Array< SpecimenTestType | null > | null,
  containerName?: string | null,
};

export type GetAllContainersResponse = {
  __typename: "GetAllContainersResponse",
  id: string,
  name: string,
  category: SpecimenContainerCategory,
  capacityMilliliters: number,
};

export type ValidatePartnerUserInviteInput = {
  partnerUserInviteID: string,
  email?: string | null,
};

export type ValidatePartnerUserInviteResponse = {
  __typename: "ValidatePartnerUserInviteResponse",
  isValid: boolean,
};

export type ValidateUnsubscribeFromEmailNotificationsInput = {
  patientCampaignStateID: string,
  email?: string | null,
};

export type ValidateUnsubscribeFromEmailNotificationsResponse = {
  __typename: "ValidateUnsubscribeFromEmailNotificationsResponse",
  isValid: boolean,
};

export type ListMatchmadeDropOffsInput = {
  // The appointment for which specimens need a dropOff
  fromAppointmentID: string,
  // If null, will assume the location of the appointment fromAppointmentID
  currentPosition?: LocationPositionInput | null,
  // either the location of toAppointmentID or the tech's home (if toAppointmentID is null)
  endPosition?: LocationPositionInput | null,
  // The tech's current time
  time: string,
  // The tech's next appointment.
  toAppointmentID?: string | null,
  // Only matchmake drop-offs with the list of specified DropOffOrganizations. Default is all DropOffOrganizations from fromAppointmentID
  dropOffOrganizationsOverride?: Array< DropOffOrganization > | null,
};

export type ListMatchmadeDropOffsResponseItem = {
  __typename: "ListMatchmadeDropOffsResponseItem",
  estimatedDriveTimeToDropOffSeconds: number,
  estimatedDriveTimeFromDropOffToNextRouteStopSeconds: number,
  dropOff: DropOff,
};

export type ExportMonthlyPatientsListWithAppointmentStatusInput = {
  partnerOrganizations: Array< PartnerOrganization >,
  startDate: string,
  endDate: string,
};

export type ExportMonthlyPatientsListWithAppointmentStatusResponse = {
  __typename: "ExportMonthlyPatientsListWithAppointmentStatusResponse",
  patientListCSV: string,
};

export type SendTestNotificationInput = {
  receiver: TestNotificationReceiver,
  isCSRBooked?: boolean | null,
  smsType?: SMSType | null,
  emailType?: EmailType | null,
  serviceType: ServiceType,
  partnerOrganization?: PartnerOrganization | null,
  abstentionTypes?: Array< string | null > | null,
  campaignName?: CampaignName | null,
  brandCode?: string | null,
};

export enum TestNotificationReceiver {
  PATIENT = "PATIENT",
  CSR = "CSR",
  COORDINATOR = "COORDINATOR",
}


export enum EmailType {
  BOOKING_CONFIRMATION = "BOOKING_CONFIRMATION",
  BOOKING_CONFIRMATION_AWV_PRE_WORK_FOCUSED = "BOOKING_CONFIRMATION_AWV_PRE_WORK_FOCUSED",
  RESCHEDULE_CONFIRMATION = "RESCHEDULE_CONFIRMATION",
  CANCEL_CONFIRMATION = "CANCEL_CONFIRMATION",
  DAY_BEFORE_REMINDER = "DAY_BEFORE_REMINDER",
  DAY_BEFORE_REMINDER_AWV_PRE_WORK_FOCUSED = "DAY_BEFORE_REMINDER_AWV_PRE_WORK_FOCUSED",
  DAY_OF_REMINDER = "DAY_OF_REMINDER",
  COLLECT_KIT_REMINDER = "COLLECT_KIT_REMINDER",
  POST_APPOINTMENT = "POST_APPOINTMENT",
  POST_APPOINTMENT_COLLECT_KIT_REMINDER = "POST_APPOINTMENT_COLLECT_KIT_REMINDER",
  FAILED_APPOINTMENT = "FAILED_APPOINTMENT",
  FAILED_APPOINTMENT_REQUIRES_RESCHEDULE = "FAILED_APPOINTMENT_REQUIRES_RESCHEDULE",
  PARTNER_USER_INVITE = "PARTNER_USER_INVITE",
  SEND_ENCOUNTER_SUMMARY = "SEND_ENCOUNTER_SUMMARY",
  SEND_ENCOUNTER_SUMMARY_WITH_NEW_LAB_RESULTS = "SEND_ENCOUNTER_SUMMARY_WITH_NEW_LAB_RESULTS",
  SDOH_RESULTS = "SDOH_RESULTS",
  PATIENT_ENCOUNTER_SUMMARY = "PATIENT_ENCOUNTER_SUMMARY",
  NEW_BOOKING_ALERT = "NEW_BOOKING_ALERT",
  TECH_FEEDBACK = "TECH_FEEDBACK",
  OUTSIDE_SERVICE_REGION_EMAIL_SUBMISSION_CONFIRMATION = "OUTSIDE_SERVICE_REGION_EMAIL_SUBMISSION_CONFIRMATION",
  UNRECOGNIZED_SERVICE_REQUEST_ID_CLICK_UP_EMAIL = "UNRECOGNIZED_SERVICE_REQUEST_ID_CLICK_UP_EMAIL",
  UNRECOGNIZED_SERVICE_REQUEST_ID_EMAIL = "UNRECOGNIZED_SERVICE_REQUEST_ID_EMAIL",
  TECH_SCHEDULER_SAME_DAY_APPOINTMENT_RESCHEDULED = "TECH_SCHEDULER_SAME_DAY_APPOINTMENT_RESCHEDULED",
  TECH_SCHEDULER_SAME_DAY_APPOINTMENT_CANCELED = "TECH_SCHEDULER_SAME_DAY_APPOINTMENT_CANCELED",
  REQUISITION_REUPLOADED = "REQUISITION_REUPLOADED",
  INVOICE_SUMMARY_EMAIL = "INVOICE_SUMMARY_EMAIL",
  INVOICE_GENERATION_EMAIL = "INVOICE_GENERATION_EMAIL",
  PATIENT_CAMPAIGN_OUTREACH_INITIAL_ENGAGEMENT = "PATIENT_CAMPAIGN_OUTREACH_INITIAL_ENGAGEMENT",
  PATIENT_CAMPAIGN_OUTREACH_INITIAL_ENGAGEMENT_AUTHORITATIVE = "PATIENT_CAMPAIGN_OUTREACH_INITIAL_ENGAGEMENT_AUTHORITATIVE",
  PATIENT_CAMPAIGN_OUTREACH_INITIAL_ENGAGEMENT_EASE = "PATIENT_CAMPAIGN_OUTREACH_INITIAL_ENGAGEMENT_EASE",
  PATIENT_CAMPAIGN_OUTREACH_INITIAL_ENGAGEMENT_REWARDS = "PATIENT_CAMPAIGN_OUTREACH_INITIAL_ENGAGEMENT_REWARDS",
  PATIENT_CAMPAIGN_OUTREACH_INITIAL_ENGAGEMENT_OUTCOMES = "PATIENT_CAMPAIGN_OUTREACH_INITIAL_ENGAGEMENT_OUTCOMES",
  PATIENT_CAMPAIGN_OUTREACH_INITIAL_ENGAGEMENT_EMPATHETIC = "PATIENT_CAMPAIGN_OUTREACH_INITIAL_ENGAGEMENT_EMPATHETIC",
  PATIENT_CAMPAIGN_OUTREACH_INITIAL_ENGAGEMENT_HANDWRITTEN = "PATIENT_CAMPAIGN_OUTREACH_INITIAL_ENGAGEMENT_HANDWRITTEN",
  PATIENT_CAMPAIGN_OUTREACH_INITIAL_ENGAGEMENT_TEASER = "PATIENT_CAMPAIGN_OUTREACH_INITIAL_ENGAGEMENT_TEASER",
  PATIENT_CAMPAIGN_OUTREACH_INITIAL_ENGAGEMENT_FOLLOW_UP_AUTHORITATIVE = "PATIENT_CAMPAIGN_OUTREACH_INITIAL_ENGAGEMENT_FOLLOW_UP_AUTHORITATIVE",
}


export type SendTestNotificationResponse = {
  __typename: "SendTestNotificationResponse",
  result: string,
};

export type GetReferralByPatientInfoInput = {
  partnerOrganization?: PartnerOrganization | null,
  givenName: string,
  familyName: string,
  birthdate: string,
  email?: string | null,
  phone?: string | null,
  resultsEncounterID?: string | null,
};

export type GetReferralByPatientInfoResponse = {
  __typename: "GetReferralByPatientInfoResponse",
  referral?: CustomAuthPartialReferral | null,
  appointment?: CustomAuthPartialAppointment | null,
  patient?: CustomAuthPartialPatient | null,
  guestJWT?: string | null,
};

export type CustomAuthPartialReferral = {
  __typename: "CustomAuthPartialReferral",
  id: string,
  serviceTypes: Array< ServiceType >,
};

export type CustomAuthPartialPatient = {
  __typename: "CustomAuthPartialPatient",
  phone?: string | null,
  email?: string | null,
  brandCode?: string | null,
  id?: string | null,
  partnerOrganization: PartnerOrganization,
  suggestedAppointmentLocation?: Location | null,
  name: HumanName,
  birthdate?: string | null,
  responsiblePractitionerName?: HumanName | null,
  responsiblePractitionerPhone?: string | null,
  responsiblePractitionerAddress?: Address | null,
  caregiverName?: HumanName | null,
};

export type ExportAppointmentsListForBillingInput = {
  startDate: string,
  endDate?: string | null,
};

export type ExportAppointmentsListForBillingResponse = {
  __typename: "ExportAppointmentsListForBillingResponse",
  listAppointmentsForBillingCSV: string,
};

export enum StatsigIDTableName {
  PatientCampaignState = "PatientCampaignState",
  Patient = "Patient",
}


export type GetStatsigIDResponse = {
  __typename: "GetStatsigIDResponse",
  id: string,
};

export type ExportServiceRegionCumulativeAvailabilityResponse = {
  __typename: "ExportServiceRegionCumulativeAvailabilityResponse",
  result: string,
};

export type GetPUTS3ObjectPresignedURLResponse = {
  __typename: "GetPUTS3ObjectPresignedURLResponse",
  url: string,
  s3ObjectID?: string | null,
};

export type GetHistoricalAvailabilityForTechInput = {
  techID: string,
  intervalStart: string,
  intervalEnd: string,
};

export type GetHistoricalAvailabilityForTechResponse = {
  __typename: "GetHistoricalAvailabilityForTechResponse",
  techAvailabilityRules:  Array<TimeRangeRule >,
  serviceRegionsAvailabilityRules:  Array<ServiceRegionAvailabilityRules >,
  partialAppointmentsForTeamAvailabilityJSON: string,
};

export type ServiceRegionAvailabilityRules = {
  __typename: "ServiceRegionAvailabilityRules",
  serviceRegionName: string,
  availabilityRules:  Array<TimeRangeRule >,
};

export type CreateSerialCounterMutationVariables = {
  input: CreateSerialCounterInput,
  condition?: ModelSerialCounterConditionInput | null,
};

export type CreateSerialCounterMutation = {
  createSerialCounter?:  {
    __typename: "SerialCounter",
    type: SerialCounterType,
    serialValue: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSerialCounterMutationVariables = {
  input: UpdateSerialCounterInput,
  condition?: ModelSerialCounterConditionInput | null,
};

export type UpdateSerialCounterMutation = {
  updateSerialCounter?:  {
    __typename: "SerialCounter",
    type: SerialCounterType,
    serialValue: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSerialCounterMutationVariables = {
  input: DeleteSerialCounterInput,
  condition?: ModelSerialCounterConditionInput | null,
};

export type DeleteSerialCounterMutation = {
  deleteSerialCounter?:  {
    __typename: "SerialCounter",
    type: SerialCounterType,
    serialValue: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateAppointmentMutationVariables = {
  input: CreateAppointmentInput,
  condition?: ModelAppointmentConditionInput | null,
};

export type CreateAppointmentMutation = {
  createAppointment?:  {
    __typename: "Appointment",
    id: string,
    // The current status of the appointment (See AppointmentStatus enum)
    status: AppointmentStatus,
    // Whether the appointment has been marked failed
    isFailed?: boolean | null,
    // Whether the appointment has at least one service completed in any of the encounters
    hasSuccessfulServices?: boolean | null,
    // Whether the appointment has at least one service failed in any of the encounters
    hasFailedServices?: boolean | null,
    // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
    needsRebooking?: boolean | null,
    // Whether the appointment has passed the pre-review phase
    isPreReviewed?: boolean | null,
    // Whether the appointment has passed the post-review phase
    isPostReviewed?: boolean | null,
    // Whether the appointment has been escalated
    isEscalated?: boolean | null,
    // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
    start: string,
    // Expected appointment end date/time
    end: string,
    // The technician assigned to the appointment
    techID?: string | null,
    remoteTechID?: string | null,
    remoteTechMatchState?: RemoteTechMatchState | null,
    coordinatorID: string,
    location:  {
      __typename: "Location",
      satellitePhotoURL?: string | null,
      streetViewPhotoURL?: string | null,
    },
    partnerOrganization?: PartnerOrganization | null,
    preReviewChecklist?:  {
      __typename: "Checklist",
    } | null,
    checklist?:  {
      __typename: "Checklist",
    } | null,
    postReviewChecklist?:  {
      __typename: "Checklist",
    } | null,
    serviceRegionName: string,
    geographicalState?: string | null,
    avoidTechIDs?: Array< string > | null,
    lastBookedAt?: number | null,
    statusUpdatedAt?: number | null,
    stripeCustomerID?: string | null,
    // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
    stripePriceToCharge?: number | null,
    hasPetsAtLocation?: boolean | null,
    // Comments about logistics like door codes, directions, etc.
    commentsFromCoordinatorAboutAppointment?: string | null,
    // Comments about logistics like door codes, directions, etc. (but from techs)
    commentsFromTechAboutAppointment?: string | null,
    // Comments about app feedback, bugs, feature requests, etc.
    commentsFromTechAboutSystem?: string | null,
    // Comments about why an appointment was put into a failed state
    commentsFromTechAboutAppointmentFailure?: string | null,
    // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
    commentsFromSchedulerAboutAppointmentFailure?: string | null,
    // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
    commentsFromSchedulerAboutCancellation?: string | null,
    partnerOrganizationBookingURL?: string | null,
    encounters?:  {
      __typename: "ModelEncounterConnection",
      nextToken?: string | null,
    } | null,
    tech?:  {
      __typename: "Tech",
      id: string,
      gender?: Gender | null,
      phone?: string | null,
      email?: string | null,
      serviceTypes?: Array< ServiceType | null > | null,
      profilePictureS3ObjectID?: string | null,
      isActive?: boolean | null,
      biographyItems?: Array< string > | null,
      patientTestimonials?: Array< string > | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      capabilities?: Array< TechCapability > | null,
      isIneligibleForMatchmaking?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    remoteTech?:  {
      __typename: "RemoteTech",
      id: string,
      email?: string | null,
      phone?: string | null,
      profilePictureS3ObjectID?: string | null,
      timezone?: Timezone | null,
      isActive: boolean,
      remoteVisitZoomLink?: string | null,
      remoteVisitZoomLinkQRCodeS3ObjectID?: string | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    coordinator:  {
      __typename: "Coordinator",
      id: string,
      authorizedTechIDs?: Array< string > | null,
      // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
      isCSR?: boolean | null,
      isActive?: boolean | null,
      managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
      authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
      phone?: string | null,
      email?: string | null,
      billingCustomerID?: string | null,
      outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
      stripeCustomerID?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    overrideMode?: AppointmentOverrideMode | null,
    // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
    // The default value is 0.5.
    sharedSlotRank: number,
    sharedSlotAppointments?:  Array< {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } > | null,
    // Represents how long the appointment should take based on product types
    estimatedServiceDurationSeconds: number,
    // For invoiced partners, the invoice line items associated with this appointment.
    stripeInvoiceLineItemIDs?: Array< string | null > | null,
    // For patient-paid appointments, any payment attempts associated with this appointment.
    stripePaymentIntentIDs?: Array< string | null > | null,
    // Whether the appointment has been confirmed or if the patient requested a change
    // When undefined or null, it means that the confirmation is still pending
    hasPatientConfirmed?: boolean | null,
    // Whether or not the appointment was booked in an unauthenticated flow
    isGuestBooked?: boolean | null,
    // Referral information from which the appointment was created (optional)
    referralID?: string | null,
    referral?:  {
      __typename: "Referral",
      id: string,
      patientID: string,
      partnerOrganization: PartnerOrganization,
      requisitionUploadS3ObjectIDs?: Array< string > | null,
      serviceTypes: Array< ServiceType >,
      isBooked?: boolean | null,
      isExpired?: boolean | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
    historyJSON?: string | null,
    // An external ID associated with the appointment, such as a job ID from MPX
    externalID?: string | null,
    // The id of the appointment that this appointment is cloned from. Cloning includes information
    // such as location, patients, and requisitions that are carried over from the original.
    clonedFromAppointmentID?: string | null,
    // The type of qualification a remote tech needs to have for this appointment.
    // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
    // Not present if the appointment has no remote component.
    remoteTechQualificationTypeRequired?: QualificationType | null,
    // Appointment date/time added to "queue"
    // This timestamp is used to determine the ordering of when remoteTech should be assigned
    markedReadyForRemoteTechAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAppointmentMutationVariables = {
  input: UpdateAppointmentInput,
  condition?: ModelAppointmentConditionInput | null,
};

export type UpdateAppointmentMutation = {
  updateAppointment?:  {
    __typename: "Appointment",
    id: string,
    // The current status of the appointment (See AppointmentStatus enum)
    status: AppointmentStatus,
    // Whether the appointment has been marked failed
    isFailed?: boolean | null,
    // Whether the appointment has at least one service completed in any of the encounters
    hasSuccessfulServices?: boolean | null,
    // Whether the appointment has at least one service failed in any of the encounters
    hasFailedServices?: boolean | null,
    // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
    needsRebooking?: boolean | null,
    // Whether the appointment has passed the pre-review phase
    isPreReviewed?: boolean | null,
    // Whether the appointment has passed the post-review phase
    isPostReviewed?: boolean | null,
    // Whether the appointment has been escalated
    isEscalated?: boolean | null,
    // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
    start: string,
    // Expected appointment end date/time
    end: string,
    // The technician assigned to the appointment
    techID?: string | null,
    remoteTechID?: string | null,
    remoteTechMatchState?: RemoteTechMatchState | null,
    coordinatorID: string,
    location:  {
      __typename: "Location",
      satellitePhotoURL?: string | null,
      streetViewPhotoURL?: string | null,
    },
    partnerOrganization?: PartnerOrganization | null,
    preReviewChecklist?:  {
      __typename: "Checklist",
    } | null,
    checklist?:  {
      __typename: "Checklist",
    } | null,
    postReviewChecklist?:  {
      __typename: "Checklist",
    } | null,
    serviceRegionName: string,
    geographicalState?: string | null,
    avoidTechIDs?: Array< string > | null,
    lastBookedAt?: number | null,
    statusUpdatedAt?: number | null,
    stripeCustomerID?: string | null,
    // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
    stripePriceToCharge?: number | null,
    hasPetsAtLocation?: boolean | null,
    // Comments about logistics like door codes, directions, etc.
    commentsFromCoordinatorAboutAppointment?: string | null,
    // Comments about logistics like door codes, directions, etc. (but from techs)
    commentsFromTechAboutAppointment?: string | null,
    // Comments about app feedback, bugs, feature requests, etc.
    commentsFromTechAboutSystem?: string | null,
    // Comments about why an appointment was put into a failed state
    commentsFromTechAboutAppointmentFailure?: string | null,
    // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
    commentsFromSchedulerAboutAppointmentFailure?: string | null,
    // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
    commentsFromSchedulerAboutCancellation?: string | null,
    partnerOrganizationBookingURL?: string | null,
    encounters?:  {
      __typename: "ModelEncounterConnection",
      nextToken?: string | null,
    } | null,
    tech?:  {
      __typename: "Tech",
      id: string,
      gender?: Gender | null,
      phone?: string | null,
      email?: string | null,
      serviceTypes?: Array< ServiceType | null > | null,
      profilePictureS3ObjectID?: string | null,
      isActive?: boolean | null,
      biographyItems?: Array< string > | null,
      patientTestimonials?: Array< string > | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      capabilities?: Array< TechCapability > | null,
      isIneligibleForMatchmaking?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    remoteTech?:  {
      __typename: "RemoteTech",
      id: string,
      email?: string | null,
      phone?: string | null,
      profilePictureS3ObjectID?: string | null,
      timezone?: Timezone | null,
      isActive: boolean,
      remoteVisitZoomLink?: string | null,
      remoteVisitZoomLinkQRCodeS3ObjectID?: string | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    coordinator:  {
      __typename: "Coordinator",
      id: string,
      authorizedTechIDs?: Array< string > | null,
      // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
      isCSR?: boolean | null,
      isActive?: boolean | null,
      managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
      authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
      phone?: string | null,
      email?: string | null,
      billingCustomerID?: string | null,
      outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
      stripeCustomerID?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    overrideMode?: AppointmentOverrideMode | null,
    // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
    // The default value is 0.5.
    sharedSlotRank: number,
    sharedSlotAppointments?:  Array< {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } > | null,
    // Represents how long the appointment should take based on product types
    estimatedServiceDurationSeconds: number,
    // For invoiced partners, the invoice line items associated with this appointment.
    stripeInvoiceLineItemIDs?: Array< string | null > | null,
    // For patient-paid appointments, any payment attempts associated with this appointment.
    stripePaymentIntentIDs?: Array< string | null > | null,
    // Whether the appointment has been confirmed or if the patient requested a change
    // When undefined or null, it means that the confirmation is still pending
    hasPatientConfirmed?: boolean | null,
    // Whether or not the appointment was booked in an unauthenticated flow
    isGuestBooked?: boolean | null,
    // Referral information from which the appointment was created (optional)
    referralID?: string | null,
    referral?:  {
      __typename: "Referral",
      id: string,
      patientID: string,
      partnerOrganization: PartnerOrganization,
      requisitionUploadS3ObjectIDs?: Array< string > | null,
      serviceTypes: Array< ServiceType >,
      isBooked?: boolean | null,
      isExpired?: boolean | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
    historyJSON?: string | null,
    // An external ID associated with the appointment, such as a job ID from MPX
    externalID?: string | null,
    // The id of the appointment that this appointment is cloned from. Cloning includes information
    // such as location, patients, and requisitions that are carried over from the original.
    clonedFromAppointmentID?: string | null,
    // The type of qualification a remote tech needs to have for this appointment.
    // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
    // Not present if the appointment has no remote component.
    remoteTechQualificationTypeRequired?: QualificationType | null,
    // Appointment date/time added to "queue"
    // This timestamp is used to determine the ordering of when remoteTech should be assigned
    markedReadyForRemoteTechAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAppointmentMutationVariables = {
  input: DeleteAppointmentInput,
  condition?: ModelAppointmentConditionInput | null,
};

export type DeleteAppointmentMutation = {
  deleteAppointment?:  {
    __typename: "Appointment",
    id: string,
    // The current status of the appointment (See AppointmentStatus enum)
    status: AppointmentStatus,
    // Whether the appointment has been marked failed
    isFailed?: boolean | null,
    // Whether the appointment has at least one service completed in any of the encounters
    hasSuccessfulServices?: boolean | null,
    // Whether the appointment has at least one service failed in any of the encounters
    hasFailedServices?: boolean | null,
    // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
    needsRebooking?: boolean | null,
    // Whether the appointment has passed the pre-review phase
    isPreReviewed?: boolean | null,
    // Whether the appointment has passed the post-review phase
    isPostReviewed?: boolean | null,
    // Whether the appointment has been escalated
    isEscalated?: boolean | null,
    // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
    start: string,
    // Expected appointment end date/time
    end: string,
    // The technician assigned to the appointment
    techID?: string | null,
    remoteTechID?: string | null,
    remoteTechMatchState?: RemoteTechMatchState | null,
    coordinatorID: string,
    location:  {
      __typename: "Location",
      satellitePhotoURL?: string | null,
      streetViewPhotoURL?: string | null,
    },
    partnerOrganization?: PartnerOrganization | null,
    preReviewChecklist?:  {
      __typename: "Checklist",
    } | null,
    checklist?:  {
      __typename: "Checklist",
    } | null,
    postReviewChecklist?:  {
      __typename: "Checklist",
    } | null,
    serviceRegionName: string,
    geographicalState?: string | null,
    avoidTechIDs?: Array< string > | null,
    lastBookedAt?: number | null,
    statusUpdatedAt?: number | null,
    stripeCustomerID?: string | null,
    // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
    stripePriceToCharge?: number | null,
    hasPetsAtLocation?: boolean | null,
    // Comments about logistics like door codes, directions, etc.
    commentsFromCoordinatorAboutAppointment?: string | null,
    // Comments about logistics like door codes, directions, etc. (but from techs)
    commentsFromTechAboutAppointment?: string | null,
    // Comments about app feedback, bugs, feature requests, etc.
    commentsFromTechAboutSystem?: string | null,
    // Comments about why an appointment was put into a failed state
    commentsFromTechAboutAppointmentFailure?: string | null,
    // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
    commentsFromSchedulerAboutAppointmentFailure?: string | null,
    // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
    commentsFromSchedulerAboutCancellation?: string | null,
    partnerOrganizationBookingURL?: string | null,
    encounters?:  {
      __typename: "ModelEncounterConnection",
      nextToken?: string | null,
    } | null,
    tech?:  {
      __typename: "Tech",
      id: string,
      gender?: Gender | null,
      phone?: string | null,
      email?: string | null,
      serviceTypes?: Array< ServiceType | null > | null,
      profilePictureS3ObjectID?: string | null,
      isActive?: boolean | null,
      biographyItems?: Array< string > | null,
      patientTestimonials?: Array< string > | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      capabilities?: Array< TechCapability > | null,
      isIneligibleForMatchmaking?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    remoteTech?:  {
      __typename: "RemoteTech",
      id: string,
      email?: string | null,
      phone?: string | null,
      profilePictureS3ObjectID?: string | null,
      timezone?: Timezone | null,
      isActive: boolean,
      remoteVisitZoomLink?: string | null,
      remoteVisitZoomLinkQRCodeS3ObjectID?: string | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    coordinator:  {
      __typename: "Coordinator",
      id: string,
      authorizedTechIDs?: Array< string > | null,
      // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
      isCSR?: boolean | null,
      isActive?: boolean | null,
      managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
      authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
      phone?: string | null,
      email?: string | null,
      billingCustomerID?: string | null,
      outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
      stripeCustomerID?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    overrideMode?: AppointmentOverrideMode | null,
    // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
    // The default value is 0.5.
    sharedSlotRank: number,
    sharedSlotAppointments?:  Array< {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } > | null,
    // Represents how long the appointment should take based on product types
    estimatedServiceDurationSeconds: number,
    // For invoiced partners, the invoice line items associated with this appointment.
    stripeInvoiceLineItemIDs?: Array< string | null > | null,
    // For patient-paid appointments, any payment attempts associated with this appointment.
    stripePaymentIntentIDs?: Array< string | null > | null,
    // Whether the appointment has been confirmed or if the patient requested a change
    // When undefined or null, it means that the confirmation is still pending
    hasPatientConfirmed?: boolean | null,
    // Whether or not the appointment was booked in an unauthenticated flow
    isGuestBooked?: boolean | null,
    // Referral information from which the appointment was created (optional)
    referralID?: string | null,
    referral?:  {
      __typename: "Referral",
      id: string,
      patientID: string,
      partnerOrganization: PartnerOrganization,
      requisitionUploadS3ObjectIDs?: Array< string > | null,
      serviceTypes: Array< ServiceType >,
      isBooked?: boolean | null,
      isExpired?: boolean | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
    historyJSON?: string | null,
    // An external ID associated with the appointment, such as a job ID from MPX
    externalID?: string | null,
    // The id of the appointment that this appointment is cloned from. Cloning includes information
    // such as location, patients, and requisitions that are carried over from the original.
    clonedFromAppointmentID?: string | null,
    // The type of qualification a remote tech needs to have for this appointment.
    // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
    // Not present if the appointment has no remote component.
    remoteTechQualificationTypeRequired?: QualificationType | null,
    // Appointment date/time added to "queue"
    // This timestamp is used to determine the ordering of when remoteTech should be assigned
    markedReadyForRemoteTechAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateEncounterMutationVariables = {
  input: CreateEncounterInput,
  condition?: ModelEncounterConditionInput | null,
};

export type CreateEncounterMutation = {
  createEncounter?:  {
    __typename: "Encounter",
    id: string,
    appointmentID: string,
    appointment:  {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    patientID: string,
    patient:  {
      __typename: "Patient",
      id: string,
      csrPartnerOrganization?: PartnerOrganization | null,
      partnerOrganization?: PartnerOrganization | null,
      coordinatorID: string,
      medicareCardID?: string | null,
      imageMoverUserID?: string | null,
      externalID?: string | null,
      secondaryExternalID?: string | null,
      specimenLabelID?: string | null,
      qtMedicalPatientID?: string | null,
      planName?: string | null,
      diabeticType?: DiabeticType | null,
      authorizedTechIDs?: Array< string > | null,
      isCoordinator: boolean,
      // Phone & email to use in place of the coordinators for messaging. Used primarily for patients whose appointments are booked for them by a partner's customer service representatives.
      phone?: string | null,
      email?: string | null,
      secondaryPhone?: string | null,
      birthdate?: string | null,
      sex?: Sex | null,
      gender?: Gender | null,
      ethnicity?: Ethnicity | null,
      races?: Array< Race > | null,
      passportNumber?: string | null,
      insuranceCardUploadFrontS3ObjectID?: string | null,
      insuranceCardUploadBackS3ObjectID?: string | null,
      medicareCardUploadFrontS3ObjectID?: string | null,
      medicareCardUploadBackS3ObjectID?: string | null,
      watchlistedAppointmentID?: string | null,
      responsiblePractitionerID?: string | null,
      commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
      commentsFromCaregiverAboutResponsiblePractitioner?: string | null,
      suggestedAppointmentLocationAdditionalInformation?: string | null,
      preferredLanguage?: string | null,
      primaryLanguageBCP47?: string | null,
      secondaryLanguagesBCP47?: Array< string > | null,
      // A unique numerical patient reference number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
      patientNumber?: string | null,
      // latest validated gap list data referencing this patient.
      // - latestGapListUploadID: the upload ID of the latest ingestion for tracking purposes
      // - latestGapListRowJSON: the entire row
      // - latestGapListPassthroughJSON: a subset of the row for passing back to the partner with patient data in reports
      latestGapListUploadID?: string | null,
      latestGapListRowJSON?: string | null,
      latestGapListPassthroughJSON?: string | null,
      // A reference to a set of branding material provided for outreach.
      brandCode?: string | null,
      population?: PatientPopulation | null,
      elationID?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    coordinatorID: string,
    coordinator?:  {
      __typename: "Coordinator",
      id: string,
      authorizedTechIDs?: Array< string > | null,
      // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
      isCSR?: boolean | null,
      isActive?: boolean | null,
      managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
      authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
      phone?: string | null,
      email?: string | null,
      billingCustomerID?: string | null,
      outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
      stripeCustomerID?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    techID?: string | null,
    tech?:  {
      __typename: "Tech",
      id: string,
      gender?: Gender | null,
      phone?: string | null,
      email?: string | null,
      serviceTypes?: Array< ServiceType | null > | null,
      profilePictureS3ObjectID?: string | null,
      isActive?: boolean | null,
      biographyItems?: Array< string > | null,
      patientTestimonials?: Array< string > | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      capabilities?: Array< TechCapability > | null,
      isIneligibleForMatchmaking?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    partnerOrganization?: PartnerOrganization | null,
    requisitions?:  Array< {
      __typename: "Requisition",
      id: string,
      labOrganization?: LabOrganization | null,
      // A unique numerical patient requisition number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
      requisitionNumber?: string | null,
    } > | null,
    requisitionUploads?:  Array< {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } > | null,
    requisitionUploadS3ObjectIDs?: Array< string > | null,
    // Comments about patient info like medical/draw history, disabilities, etc.
    commentsFromCoordinatorAboutPatient?: string | null,
    // Comments about the clinical aspects of the encounter to be passed from scheduler to technician
    commentsFromSchedulerAboutEncounter?: string | null,
    // Comments about the clinical aspects of the encounter to be passed from scheduler to technician
    commentsFromSchedulerToTechAboutEncounter?: string | null,
    // Comments about the clinical aspects of the encounter and patient info not mentioned by the coordinator
    commentsFromTechAboutEncounter?: string | null,
    // Comments from customer support about actions taken on the encounter (e.g., services removed).
    commentsFromCustomerSupportAboutEncounter?: string | null,
    // Comments from the scheduler to the partner about actions taken on the encounter (e.g., services removed).
    commentsFromSchedulerToPartnerAboutEncounter?: string | null,
    covid19Questions?:  {
      __typename: "COVID19Questions",
      isForTravel?: boolean | null,
      isForWorkOrSchool?: boolean | null,
      isForAPublicEvent?: boolean | null,
      isForPotentialExposure?: boolean | null,
      isForSymptoms?: boolean | null,
      isForOtherReasons?: boolean | null,
    } | null,
    bloodCollectionDetails?:  {
      __typename: "SpecimenCollectionDetails",
      bodySiteLaterality?: Laterality | null,
      bodySiteStructure?: BodyStructure | null,
      deviceType?: SpecimenCollectionDeviceType | null,
      attempts?: number | null,
    } | null,
    capillaryDrawCollectionDetails?:  {
      __typename: "SpecimenCollectionDetails",
      bodySiteLaterality?: Laterality | null,
      bodySiteStructure?: BodyStructure | null,
      deviceType?: SpecimenCollectionDeviceType | null,
      attempts?: number | null,
    } | null,
    productTypes?: Array< ProductType > | null,
    isMedicare?: boolean | null,
    isMedicareEligible?: boolean | null,
    wasPatientManuallyAdded?: boolean | null,
    serviceOutcomes?:  Array< {
      __typename: "ServiceOutcome",
      failureReason?: ServiceFailureReason | null,
      failureFault?: ServiceFailureFault | null,
      isRescheduleRequired?: boolean | null,
      serviceType: ServiceType,
      status?: ServiceStatus | null,
      commentsFromSchedulerAboutServiceFailure?: string | null,
      serviceDetailJSON?: string | null,
    } > | null,
    diagnosisCodes?:  Array< {
      __typename: "Coding",
      system: string,
      code: string,
      displayName?: string | null,
    } > | null,
    // The partner's identifier for this encounter/appointment:
    // - FMI Case number for Foundation,
    // - Accession Number for Guardant,
    // - Job ID for Natera
    partnersEncounterID?:  {
      __typename: "CustomProperty",
      key: CustomPropertyKey,
      value: string,
    } | null,
    documents?:  Array< {
      __typename: "Document",
      type?: DocumentType | null,
      url?: string | null,
      format?: DocumentFormat | null,
      s3ObjectID?: string | null,
      uploadedAt?: number | null,
      // stringified JSON object as a set of additional data we want to store about documents (requisition number, lab report status, etc.)
      metadataJSON?: string | null,
    } > | null,
    observations?:  Array< {
      __typename: "Observation",
      id: string,
      // Per FHIR specifications, we should only have value or valueCoding
      value?: string | null,
      units?: string | null,
      referenceRange?: string | null,
      category: ObservationCategory,
      performerType: ObservationPerformerType,
      performerIdentifier?: string | null,
      observedAt?: string | null,
      bodySiteLaterality?: Laterality | null,
      bodySiteStructure?: BodyStructure | null,
      notes?: string | null,
      // Also called Abnormal Flag. A categorical assessment, providing a rough qualitative interpretation of the observation value.
      // http://hl7.org/fhir/valueset-observation-interpretation.html
      interpretationCode?: ObservationInterpretationCode | null,
      source?: ObservationSource | null,
    } > | null,
    procedures?:  Array< {
      __typename: "Procedure",
      source: ProcedureSource,
    } > | null,
    escalation?:  {
      __typename: "Escalation",
      isResolved?: boolean | null,
      symptoms?: Array< EscalationSymptom > | null,
      // If OTHER is selected in the symptoms this contains the list of other symptoms.
      otherSymptoms?: string | null,
      // Comments from the patient to the escalation owner, like their medical history. The escalation owner is the scheduler who is filling out the report.
      commentsFromPatientToEscalationOwner?: string | null,
      // Comments from the escalation owner to the partner about the escalation. This includes the general assessment of the situation involving the patient. The escalation owner is the scheduler who is filling out the report.
      commentsFromEscalationOwnerToPartnerAboutEscalation?: string | null,
      outcome?: EscalationOutcome | null,
      // If OTHER is selected for the outcome this contains a description of the outcome.
      otherOutcome?: string | null,
      outcomeTimeframe?: EscalationOutcomeTimeframe | null,
      createdAt?: string | null,
      resolvedAt?: string | null,
    } | null,
    questionnaires?:  Array< {
      __typename: "QuestionnaireEmbedded",
      type: QuestionnaireType,
      url: string,
      metadataJSON?: string | null,
    } > | null,
    colorectalCancerScreeningAnswers?:  {
      __typename: "QuestionnaireResponse",
      // Canonical id of Questionnaire being answered
      responseID?: string | null,
      // Date the answers were gathered
      authoredAt?: string | null,
    } | null,
    patientEncounterSummaryLastSentAt?: string | null,
    cologuardOrderSuccessfullyPlacedAt?: string | null,
    hasPatientRequestedMailedResults?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateEncounterMutationVariables = {
  input: UpdateEncounterInput,
  condition?: ModelEncounterConditionInput | null,
};

export type UpdateEncounterMutation = {
  updateEncounter?:  {
    __typename: "Encounter",
    id: string,
    appointmentID: string,
    appointment:  {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    patientID: string,
    patient:  {
      __typename: "Patient",
      id: string,
      csrPartnerOrganization?: PartnerOrganization | null,
      partnerOrganization?: PartnerOrganization | null,
      coordinatorID: string,
      medicareCardID?: string | null,
      imageMoverUserID?: string | null,
      externalID?: string | null,
      secondaryExternalID?: string | null,
      specimenLabelID?: string | null,
      qtMedicalPatientID?: string | null,
      planName?: string | null,
      diabeticType?: DiabeticType | null,
      authorizedTechIDs?: Array< string > | null,
      isCoordinator: boolean,
      // Phone & email to use in place of the coordinators for messaging. Used primarily for patients whose appointments are booked for them by a partner's customer service representatives.
      phone?: string | null,
      email?: string | null,
      secondaryPhone?: string | null,
      birthdate?: string | null,
      sex?: Sex | null,
      gender?: Gender | null,
      ethnicity?: Ethnicity | null,
      races?: Array< Race > | null,
      passportNumber?: string | null,
      insuranceCardUploadFrontS3ObjectID?: string | null,
      insuranceCardUploadBackS3ObjectID?: string | null,
      medicareCardUploadFrontS3ObjectID?: string | null,
      medicareCardUploadBackS3ObjectID?: string | null,
      watchlistedAppointmentID?: string | null,
      responsiblePractitionerID?: string | null,
      commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
      commentsFromCaregiverAboutResponsiblePractitioner?: string | null,
      suggestedAppointmentLocationAdditionalInformation?: string | null,
      preferredLanguage?: string | null,
      primaryLanguageBCP47?: string | null,
      secondaryLanguagesBCP47?: Array< string > | null,
      // A unique numerical patient reference number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
      patientNumber?: string | null,
      // latest validated gap list data referencing this patient.
      // - latestGapListUploadID: the upload ID of the latest ingestion for tracking purposes
      // - latestGapListRowJSON: the entire row
      // - latestGapListPassthroughJSON: a subset of the row for passing back to the partner with patient data in reports
      latestGapListUploadID?: string | null,
      latestGapListRowJSON?: string | null,
      latestGapListPassthroughJSON?: string | null,
      // A reference to a set of branding material provided for outreach.
      brandCode?: string | null,
      population?: PatientPopulation | null,
      elationID?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    coordinatorID: string,
    coordinator?:  {
      __typename: "Coordinator",
      id: string,
      authorizedTechIDs?: Array< string > | null,
      // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
      isCSR?: boolean | null,
      isActive?: boolean | null,
      managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
      authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
      phone?: string | null,
      email?: string | null,
      billingCustomerID?: string | null,
      outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
      stripeCustomerID?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    techID?: string | null,
    tech?:  {
      __typename: "Tech",
      id: string,
      gender?: Gender | null,
      phone?: string | null,
      email?: string | null,
      serviceTypes?: Array< ServiceType | null > | null,
      profilePictureS3ObjectID?: string | null,
      isActive?: boolean | null,
      biographyItems?: Array< string > | null,
      patientTestimonials?: Array< string > | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      capabilities?: Array< TechCapability > | null,
      isIneligibleForMatchmaking?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    partnerOrganization?: PartnerOrganization | null,
    requisitions?:  Array< {
      __typename: "Requisition",
      id: string,
      labOrganization?: LabOrganization | null,
      // A unique numerical patient requisition number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
      requisitionNumber?: string | null,
    } > | null,
    requisitionUploads?:  Array< {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } > | null,
    requisitionUploadS3ObjectIDs?: Array< string > | null,
    // Comments about patient info like medical/draw history, disabilities, etc.
    commentsFromCoordinatorAboutPatient?: string | null,
    // Comments about the clinical aspects of the encounter to be passed from scheduler to technician
    commentsFromSchedulerAboutEncounter?: string | null,
    // Comments about the clinical aspects of the encounter to be passed from scheduler to technician
    commentsFromSchedulerToTechAboutEncounter?: string | null,
    // Comments about the clinical aspects of the encounter and patient info not mentioned by the coordinator
    commentsFromTechAboutEncounter?: string | null,
    // Comments from customer support about actions taken on the encounter (e.g., services removed).
    commentsFromCustomerSupportAboutEncounter?: string | null,
    // Comments from the scheduler to the partner about actions taken on the encounter (e.g., services removed).
    commentsFromSchedulerToPartnerAboutEncounter?: string | null,
    covid19Questions?:  {
      __typename: "COVID19Questions",
      isForTravel?: boolean | null,
      isForWorkOrSchool?: boolean | null,
      isForAPublicEvent?: boolean | null,
      isForPotentialExposure?: boolean | null,
      isForSymptoms?: boolean | null,
      isForOtherReasons?: boolean | null,
    } | null,
    bloodCollectionDetails?:  {
      __typename: "SpecimenCollectionDetails",
      bodySiteLaterality?: Laterality | null,
      bodySiteStructure?: BodyStructure | null,
      deviceType?: SpecimenCollectionDeviceType | null,
      attempts?: number | null,
    } | null,
    capillaryDrawCollectionDetails?:  {
      __typename: "SpecimenCollectionDetails",
      bodySiteLaterality?: Laterality | null,
      bodySiteStructure?: BodyStructure | null,
      deviceType?: SpecimenCollectionDeviceType | null,
      attempts?: number | null,
    } | null,
    productTypes?: Array< ProductType > | null,
    isMedicare?: boolean | null,
    isMedicareEligible?: boolean | null,
    wasPatientManuallyAdded?: boolean | null,
    serviceOutcomes?:  Array< {
      __typename: "ServiceOutcome",
      failureReason?: ServiceFailureReason | null,
      failureFault?: ServiceFailureFault | null,
      isRescheduleRequired?: boolean | null,
      serviceType: ServiceType,
      status?: ServiceStatus | null,
      commentsFromSchedulerAboutServiceFailure?: string | null,
      serviceDetailJSON?: string | null,
    } > | null,
    diagnosisCodes?:  Array< {
      __typename: "Coding",
      system: string,
      code: string,
      displayName?: string | null,
    } > | null,
    // The partner's identifier for this encounter/appointment:
    // - FMI Case number for Foundation,
    // - Accession Number for Guardant,
    // - Job ID for Natera
    partnersEncounterID?:  {
      __typename: "CustomProperty",
      key: CustomPropertyKey,
      value: string,
    } | null,
    documents?:  Array< {
      __typename: "Document",
      type?: DocumentType | null,
      url?: string | null,
      format?: DocumentFormat | null,
      s3ObjectID?: string | null,
      uploadedAt?: number | null,
      // stringified JSON object as a set of additional data we want to store about documents (requisition number, lab report status, etc.)
      metadataJSON?: string | null,
    } > | null,
    observations?:  Array< {
      __typename: "Observation",
      id: string,
      // Per FHIR specifications, we should only have value or valueCoding
      value?: string | null,
      units?: string | null,
      referenceRange?: string | null,
      category: ObservationCategory,
      performerType: ObservationPerformerType,
      performerIdentifier?: string | null,
      observedAt?: string | null,
      bodySiteLaterality?: Laterality | null,
      bodySiteStructure?: BodyStructure | null,
      notes?: string | null,
      // Also called Abnormal Flag. A categorical assessment, providing a rough qualitative interpretation of the observation value.
      // http://hl7.org/fhir/valueset-observation-interpretation.html
      interpretationCode?: ObservationInterpretationCode | null,
      source?: ObservationSource | null,
    } > | null,
    procedures?:  Array< {
      __typename: "Procedure",
      source: ProcedureSource,
    } > | null,
    escalation?:  {
      __typename: "Escalation",
      isResolved?: boolean | null,
      symptoms?: Array< EscalationSymptom > | null,
      // If OTHER is selected in the symptoms this contains the list of other symptoms.
      otherSymptoms?: string | null,
      // Comments from the patient to the escalation owner, like their medical history. The escalation owner is the scheduler who is filling out the report.
      commentsFromPatientToEscalationOwner?: string | null,
      // Comments from the escalation owner to the partner about the escalation. This includes the general assessment of the situation involving the patient. The escalation owner is the scheduler who is filling out the report.
      commentsFromEscalationOwnerToPartnerAboutEscalation?: string | null,
      outcome?: EscalationOutcome | null,
      // If OTHER is selected for the outcome this contains a description of the outcome.
      otherOutcome?: string | null,
      outcomeTimeframe?: EscalationOutcomeTimeframe | null,
      createdAt?: string | null,
      resolvedAt?: string | null,
    } | null,
    questionnaires?:  Array< {
      __typename: "QuestionnaireEmbedded",
      type: QuestionnaireType,
      url: string,
      metadataJSON?: string | null,
    } > | null,
    colorectalCancerScreeningAnswers?:  {
      __typename: "QuestionnaireResponse",
      // Canonical id of Questionnaire being answered
      responseID?: string | null,
      // Date the answers were gathered
      authoredAt?: string | null,
    } | null,
    patientEncounterSummaryLastSentAt?: string | null,
    cologuardOrderSuccessfullyPlacedAt?: string | null,
    hasPatientRequestedMailedResults?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteEncounterMutationVariables = {
  input: DeleteEncounterInput,
  condition?: ModelEncounterConditionInput | null,
};

export type DeleteEncounterMutation = {
  deleteEncounter?:  {
    __typename: "Encounter",
    id: string,
    appointmentID: string,
    appointment:  {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    patientID: string,
    patient:  {
      __typename: "Patient",
      id: string,
      csrPartnerOrganization?: PartnerOrganization | null,
      partnerOrganization?: PartnerOrganization | null,
      coordinatorID: string,
      medicareCardID?: string | null,
      imageMoverUserID?: string | null,
      externalID?: string | null,
      secondaryExternalID?: string | null,
      specimenLabelID?: string | null,
      qtMedicalPatientID?: string | null,
      planName?: string | null,
      diabeticType?: DiabeticType | null,
      authorizedTechIDs?: Array< string > | null,
      isCoordinator: boolean,
      // Phone & email to use in place of the coordinators for messaging. Used primarily for patients whose appointments are booked for them by a partner's customer service representatives.
      phone?: string | null,
      email?: string | null,
      secondaryPhone?: string | null,
      birthdate?: string | null,
      sex?: Sex | null,
      gender?: Gender | null,
      ethnicity?: Ethnicity | null,
      races?: Array< Race > | null,
      passportNumber?: string | null,
      insuranceCardUploadFrontS3ObjectID?: string | null,
      insuranceCardUploadBackS3ObjectID?: string | null,
      medicareCardUploadFrontS3ObjectID?: string | null,
      medicareCardUploadBackS3ObjectID?: string | null,
      watchlistedAppointmentID?: string | null,
      responsiblePractitionerID?: string | null,
      commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
      commentsFromCaregiverAboutResponsiblePractitioner?: string | null,
      suggestedAppointmentLocationAdditionalInformation?: string | null,
      preferredLanguage?: string | null,
      primaryLanguageBCP47?: string | null,
      secondaryLanguagesBCP47?: Array< string > | null,
      // A unique numerical patient reference number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
      patientNumber?: string | null,
      // latest validated gap list data referencing this patient.
      // - latestGapListUploadID: the upload ID of the latest ingestion for tracking purposes
      // - latestGapListRowJSON: the entire row
      // - latestGapListPassthroughJSON: a subset of the row for passing back to the partner with patient data in reports
      latestGapListUploadID?: string | null,
      latestGapListRowJSON?: string | null,
      latestGapListPassthroughJSON?: string | null,
      // A reference to a set of branding material provided for outreach.
      brandCode?: string | null,
      population?: PatientPopulation | null,
      elationID?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    coordinatorID: string,
    coordinator?:  {
      __typename: "Coordinator",
      id: string,
      authorizedTechIDs?: Array< string > | null,
      // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
      isCSR?: boolean | null,
      isActive?: boolean | null,
      managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
      authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
      phone?: string | null,
      email?: string | null,
      billingCustomerID?: string | null,
      outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
      stripeCustomerID?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    techID?: string | null,
    tech?:  {
      __typename: "Tech",
      id: string,
      gender?: Gender | null,
      phone?: string | null,
      email?: string | null,
      serviceTypes?: Array< ServiceType | null > | null,
      profilePictureS3ObjectID?: string | null,
      isActive?: boolean | null,
      biographyItems?: Array< string > | null,
      patientTestimonials?: Array< string > | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      capabilities?: Array< TechCapability > | null,
      isIneligibleForMatchmaking?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    partnerOrganization?: PartnerOrganization | null,
    requisitions?:  Array< {
      __typename: "Requisition",
      id: string,
      labOrganization?: LabOrganization | null,
      // A unique numerical patient requisition number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
      requisitionNumber?: string | null,
    } > | null,
    requisitionUploads?:  Array< {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } > | null,
    requisitionUploadS3ObjectIDs?: Array< string > | null,
    // Comments about patient info like medical/draw history, disabilities, etc.
    commentsFromCoordinatorAboutPatient?: string | null,
    // Comments about the clinical aspects of the encounter to be passed from scheduler to technician
    commentsFromSchedulerAboutEncounter?: string | null,
    // Comments about the clinical aspects of the encounter to be passed from scheduler to technician
    commentsFromSchedulerToTechAboutEncounter?: string | null,
    // Comments about the clinical aspects of the encounter and patient info not mentioned by the coordinator
    commentsFromTechAboutEncounter?: string | null,
    // Comments from customer support about actions taken on the encounter (e.g., services removed).
    commentsFromCustomerSupportAboutEncounter?: string | null,
    // Comments from the scheduler to the partner about actions taken on the encounter (e.g., services removed).
    commentsFromSchedulerToPartnerAboutEncounter?: string | null,
    covid19Questions?:  {
      __typename: "COVID19Questions",
      isForTravel?: boolean | null,
      isForWorkOrSchool?: boolean | null,
      isForAPublicEvent?: boolean | null,
      isForPotentialExposure?: boolean | null,
      isForSymptoms?: boolean | null,
      isForOtherReasons?: boolean | null,
    } | null,
    bloodCollectionDetails?:  {
      __typename: "SpecimenCollectionDetails",
      bodySiteLaterality?: Laterality | null,
      bodySiteStructure?: BodyStructure | null,
      deviceType?: SpecimenCollectionDeviceType | null,
      attempts?: number | null,
    } | null,
    capillaryDrawCollectionDetails?:  {
      __typename: "SpecimenCollectionDetails",
      bodySiteLaterality?: Laterality | null,
      bodySiteStructure?: BodyStructure | null,
      deviceType?: SpecimenCollectionDeviceType | null,
      attempts?: number | null,
    } | null,
    productTypes?: Array< ProductType > | null,
    isMedicare?: boolean | null,
    isMedicareEligible?: boolean | null,
    wasPatientManuallyAdded?: boolean | null,
    serviceOutcomes?:  Array< {
      __typename: "ServiceOutcome",
      failureReason?: ServiceFailureReason | null,
      failureFault?: ServiceFailureFault | null,
      isRescheduleRequired?: boolean | null,
      serviceType: ServiceType,
      status?: ServiceStatus | null,
      commentsFromSchedulerAboutServiceFailure?: string | null,
      serviceDetailJSON?: string | null,
    } > | null,
    diagnosisCodes?:  Array< {
      __typename: "Coding",
      system: string,
      code: string,
      displayName?: string | null,
    } > | null,
    // The partner's identifier for this encounter/appointment:
    // - FMI Case number for Foundation,
    // - Accession Number for Guardant,
    // - Job ID for Natera
    partnersEncounterID?:  {
      __typename: "CustomProperty",
      key: CustomPropertyKey,
      value: string,
    } | null,
    documents?:  Array< {
      __typename: "Document",
      type?: DocumentType | null,
      url?: string | null,
      format?: DocumentFormat | null,
      s3ObjectID?: string | null,
      uploadedAt?: number | null,
      // stringified JSON object as a set of additional data we want to store about documents (requisition number, lab report status, etc.)
      metadataJSON?: string | null,
    } > | null,
    observations?:  Array< {
      __typename: "Observation",
      id: string,
      // Per FHIR specifications, we should only have value or valueCoding
      value?: string | null,
      units?: string | null,
      referenceRange?: string | null,
      category: ObservationCategory,
      performerType: ObservationPerformerType,
      performerIdentifier?: string | null,
      observedAt?: string | null,
      bodySiteLaterality?: Laterality | null,
      bodySiteStructure?: BodyStructure | null,
      notes?: string | null,
      // Also called Abnormal Flag. A categorical assessment, providing a rough qualitative interpretation of the observation value.
      // http://hl7.org/fhir/valueset-observation-interpretation.html
      interpretationCode?: ObservationInterpretationCode | null,
      source?: ObservationSource | null,
    } > | null,
    procedures?:  Array< {
      __typename: "Procedure",
      source: ProcedureSource,
    } > | null,
    escalation?:  {
      __typename: "Escalation",
      isResolved?: boolean | null,
      symptoms?: Array< EscalationSymptom > | null,
      // If OTHER is selected in the symptoms this contains the list of other symptoms.
      otherSymptoms?: string | null,
      // Comments from the patient to the escalation owner, like their medical history. The escalation owner is the scheduler who is filling out the report.
      commentsFromPatientToEscalationOwner?: string | null,
      // Comments from the escalation owner to the partner about the escalation. This includes the general assessment of the situation involving the patient. The escalation owner is the scheduler who is filling out the report.
      commentsFromEscalationOwnerToPartnerAboutEscalation?: string | null,
      outcome?: EscalationOutcome | null,
      // If OTHER is selected for the outcome this contains a description of the outcome.
      otherOutcome?: string | null,
      outcomeTimeframe?: EscalationOutcomeTimeframe | null,
      createdAt?: string | null,
      resolvedAt?: string | null,
    } | null,
    questionnaires?:  Array< {
      __typename: "QuestionnaireEmbedded",
      type: QuestionnaireType,
      url: string,
      metadataJSON?: string | null,
    } > | null,
    colorectalCancerScreeningAnswers?:  {
      __typename: "QuestionnaireResponse",
      // Canonical id of Questionnaire being answered
      responseID?: string | null,
      // Date the answers were gathered
      authoredAt?: string | null,
    } | null,
    patientEncounterSummaryLastSentAt?: string | null,
    cologuardOrderSuccessfullyPlacedAt?: string | null,
    hasPatientRequestedMailedResults?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCareNavigationEpisodeMutationVariables = {
  input: CreateCareNavigationEpisodeInput,
  condition?: ModelCareNavigationEpisodeConditionInput | null,
};

export type CreateCareNavigationEpisodeMutation = {
  createCareNavigationEpisode?:  {
    __typename: "CareNavigationEpisode",
    closedAt?: string | null,
    careNavigationGroup: CareNavigationGroup,
    patientID: string,
    patient:  {
      __typename: "Patient",
      id: string,
      csrPartnerOrganization?: PartnerOrganization | null,
      partnerOrganization?: PartnerOrganization | null,
      coordinatorID: string,
      medicareCardID?: string | null,
      imageMoverUserID?: string | null,
      externalID?: string | null,
      secondaryExternalID?: string | null,
      specimenLabelID?: string | null,
      qtMedicalPatientID?: string | null,
      planName?: string | null,
      diabeticType?: DiabeticType | null,
      authorizedTechIDs?: Array< string > | null,
      isCoordinator: boolean,
      // Phone & email to use in place of the coordinators for messaging. Used primarily for patients whose appointments are booked for them by a partner's customer service representatives.
      phone?: string | null,
      email?: string | null,
      secondaryPhone?: string | null,
      birthdate?: string | null,
      sex?: Sex | null,
      gender?: Gender | null,
      ethnicity?: Ethnicity | null,
      races?: Array< Race > | null,
      passportNumber?: string | null,
      insuranceCardUploadFrontS3ObjectID?: string | null,
      insuranceCardUploadBackS3ObjectID?: string | null,
      medicareCardUploadFrontS3ObjectID?: string | null,
      medicareCardUploadBackS3ObjectID?: string | null,
      watchlistedAppointmentID?: string | null,
      responsiblePractitionerID?: string | null,
      commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
      commentsFromCaregiverAboutResponsiblePractitioner?: string | null,
      suggestedAppointmentLocationAdditionalInformation?: string | null,
      preferredLanguage?: string | null,
      primaryLanguageBCP47?: string | null,
      secondaryLanguagesBCP47?: Array< string > | null,
      // A unique numerical patient reference number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
      patientNumber?: string | null,
      // latest validated gap list data referencing this patient.
      // - latestGapListUploadID: the upload ID of the latest ingestion for tracking purposes
      // - latestGapListRowJSON: the entire row
      // - latestGapListPassthroughJSON: a subset of the row for passing back to the partner with patient data in reports
      latestGapListUploadID?: string | null,
      latestGapListRowJSON?: string | null,
      latestGapListPassthroughJSON?: string | null,
      // A reference to a set of branding material provided for outreach.
      brandCode?: string | null,
      population?: PatientPopulation | null,
      elationID?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    partnerOrganization: PartnerOrganization,
    referralID: string,
    referral:  {
      __typename: "Referral",
      id: string,
      patientID: string,
      partnerOrganization: PartnerOrganization,
      requisitionUploadS3ObjectIDs?: Array< string > | null,
      serviceTypes: Array< ServiceType >,
      isBooked?: boolean | null,
      isExpired?: boolean | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    },
    zendeskTicketID?: number | null,
    serviceOutcomes?:  Array< {
      __typename: "CareNavigationEpisodeServiceOutcome",
      id: string,
      failureReason?: ServiceFailureReason | null,
      failureFault?: ServiceFailureFault | null,
      isRescheduleRequired?: boolean | null,
      serviceType: ServiceType,
      status?: ServiceStatus | null,
      commentsFromCareNavigatorAboutServiceFailure?: string | null,
      statusChangedAt?: string | null,
      serviceDetailJSON: string,
    } > | null,
    documents?:  Array< {
      __typename: "Document",
      type?: DocumentType | null,
      url?: string | null,
      format?: DocumentFormat | null,
      s3ObjectID?: string | null,
      uploadedAt?: number | null,
      // stringified JSON object as a set of additional data we want to store about documents (requisition number, lab report status, etc.)
      metadataJSON?: string | null,
    } > | null,
    observations?:  Array< {
      __typename: "Observation",
      id: string,
      // Per FHIR specifications, we should only have value or valueCoding
      value?: string | null,
      units?: string | null,
      referenceRange?: string | null,
      category: ObservationCategory,
      performerType: ObservationPerformerType,
      performerIdentifier?: string | null,
      observedAt?: string | null,
      bodySiteLaterality?: Laterality | null,
      bodySiteStructure?: BodyStructure | null,
      notes?: string | null,
      // Also called Abnormal Flag. A categorical assessment, providing a rough qualitative interpretation of the observation value.
      // http://hl7.org/fhir/valueset-observation-interpretation.html
      interpretationCode?: ObservationInterpretationCode | null,
      source?: ObservationSource | null,
    } > | null,
    onboardingQuestionsAndAnswers?:  Array< {
      __typename: "OnboardingQuestionAnswerPair",
      key: string,
      question: string,
      answer: string,
    } > | null,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};

export type UpdateCareNavigationEpisodeMutationVariables = {
  input: UpdateCareNavigationEpisodeInput,
  condition?: ModelCareNavigationEpisodeConditionInput | null,
};

export type UpdateCareNavigationEpisodeMutation = {
  updateCareNavigationEpisode?:  {
    __typename: "CareNavigationEpisode",
    closedAt?: string | null,
    careNavigationGroup: CareNavigationGroup,
    patientID: string,
    patient:  {
      __typename: "Patient",
      id: string,
      csrPartnerOrganization?: PartnerOrganization | null,
      partnerOrganization?: PartnerOrganization | null,
      coordinatorID: string,
      medicareCardID?: string | null,
      imageMoverUserID?: string | null,
      externalID?: string | null,
      secondaryExternalID?: string | null,
      specimenLabelID?: string | null,
      qtMedicalPatientID?: string | null,
      planName?: string | null,
      diabeticType?: DiabeticType | null,
      authorizedTechIDs?: Array< string > | null,
      isCoordinator: boolean,
      // Phone & email to use in place of the coordinators for messaging. Used primarily for patients whose appointments are booked for them by a partner's customer service representatives.
      phone?: string | null,
      email?: string | null,
      secondaryPhone?: string | null,
      birthdate?: string | null,
      sex?: Sex | null,
      gender?: Gender | null,
      ethnicity?: Ethnicity | null,
      races?: Array< Race > | null,
      passportNumber?: string | null,
      insuranceCardUploadFrontS3ObjectID?: string | null,
      insuranceCardUploadBackS3ObjectID?: string | null,
      medicareCardUploadFrontS3ObjectID?: string | null,
      medicareCardUploadBackS3ObjectID?: string | null,
      watchlistedAppointmentID?: string | null,
      responsiblePractitionerID?: string | null,
      commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
      commentsFromCaregiverAboutResponsiblePractitioner?: string | null,
      suggestedAppointmentLocationAdditionalInformation?: string | null,
      preferredLanguage?: string | null,
      primaryLanguageBCP47?: string | null,
      secondaryLanguagesBCP47?: Array< string > | null,
      // A unique numerical patient reference number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
      patientNumber?: string | null,
      // latest validated gap list data referencing this patient.
      // - latestGapListUploadID: the upload ID of the latest ingestion for tracking purposes
      // - latestGapListRowJSON: the entire row
      // - latestGapListPassthroughJSON: a subset of the row for passing back to the partner with patient data in reports
      latestGapListUploadID?: string | null,
      latestGapListRowJSON?: string | null,
      latestGapListPassthroughJSON?: string | null,
      // A reference to a set of branding material provided for outreach.
      brandCode?: string | null,
      population?: PatientPopulation | null,
      elationID?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    partnerOrganization: PartnerOrganization,
    referralID: string,
    referral:  {
      __typename: "Referral",
      id: string,
      patientID: string,
      partnerOrganization: PartnerOrganization,
      requisitionUploadS3ObjectIDs?: Array< string > | null,
      serviceTypes: Array< ServiceType >,
      isBooked?: boolean | null,
      isExpired?: boolean | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    },
    zendeskTicketID?: number | null,
    serviceOutcomes?:  Array< {
      __typename: "CareNavigationEpisodeServiceOutcome",
      id: string,
      failureReason?: ServiceFailureReason | null,
      failureFault?: ServiceFailureFault | null,
      isRescheduleRequired?: boolean | null,
      serviceType: ServiceType,
      status?: ServiceStatus | null,
      commentsFromCareNavigatorAboutServiceFailure?: string | null,
      statusChangedAt?: string | null,
      serviceDetailJSON: string,
    } > | null,
    documents?:  Array< {
      __typename: "Document",
      type?: DocumentType | null,
      url?: string | null,
      format?: DocumentFormat | null,
      s3ObjectID?: string | null,
      uploadedAt?: number | null,
      // stringified JSON object as a set of additional data we want to store about documents (requisition number, lab report status, etc.)
      metadataJSON?: string | null,
    } > | null,
    observations?:  Array< {
      __typename: "Observation",
      id: string,
      // Per FHIR specifications, we should only have value or valueCoding
      value?: string | null,
      units?: string | null,
      referenceRange?: string | null,
      category: ObservationCategory,
      performerType: ObservationPerformerType,
      performerIdentifier?: string | null,
      observedAt?: string | null,
      bodySiteLaterality?: Laterality | null,
      bodySiteStructure?: BodyStructure | null,
      notes?: string | null,
      // Also called Abnormal Flag. A categorical assessment, providing a rough qualitative interpretation of the observation value.
      // http://hl7.org/fhir/valueset-observation-interpretation.html
      interpretationCode?: ObservationInterpretationCode | null,
      source?: ObservationSource | null,
    } > | null,
    onboardingQuestionsAndAnswers?:  Array< {
      __typename: "OnboardingQuestionAnswerPair",
      key: string,
      question: string,
      answer: string,
    } > | null,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};

export type DeleteCareNavigationEpisodeMutationVariables = {
  input: DeleteCareNavigationEpisodeInput,
  condition?: ModelCareNavigationEpisodeConditionInput | null,
};

export type DeleteCareNavigationEpisodeMutation = {
  deleteCareNavigationEpisode?:  {
    __typename: "CareNavigationEpisode",
    closedAt?: string | null,
    careNavigationGroup: CareNavigationGroup,
    patientID: string,
    patient:  {
      __typename: "Patient",
      id: string,
      csrPartnerOrganization?: PartnerOrganization | null,
      partnerOrganization?: PartnerOrganization | null,
      coordinatorID: string,
      medicareCardID?: string | null,
      imageMoverUserID?: string | null,
      externalID?: string | null,
      secondaryExternalID?: string | null,
      specimenLabelID?: string | null,
      qtMedicalPatientID?: string | null,
      planName?: string | null,
      diabeticType?: DiabeticType | null,
      authorizedTechIDs?: Array< string > | null,
      isCoordinator: boolean,
      // Phone & email to use in place of the coordinators for messaging. Used primarily for patients whose appointments are booked for them by a partner's customer service representatives.
      phone?: string | null,
      email?: string | null,
      secondaryPhone?: string | null,
      birthdate?: string | null,
      sex?: Sex | null,
      gender?: Gender | null,
      ethnicity?: Ethnicity | null,
      races?: Array< Race > | null,
      passportNumber?: string | null,
      insuranceCardUploadFrontS3ObjectID?: string | null,
      insuranceCardUploadBackS3ObjectID?: string | null,
      medicareCardUploadFrontS3ObjectID?: string | null,
      medicareCardUploadBackS3ObjectID?: string | null,
      watchlistedAppointmentID?: string | null,
      responsiblePractitionerID?: string | null,
      commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
      commentsFromCaregiverAboutResponsiblePractitioner?: string | null,
      suggestedAppointmentLocationAdditionalInformation?: string | null,
      preferredLanguage?: string | null,
      primaryLanguageBCP47?: string | null,
      secondaryLanguagesBCP47?: Array< string > | null,
      // A unique numerical patient reference number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
      patientNumber?: string | null,
      // latest validated gap list data referencing this patient.
      // - latestGapListUploadID: the upload ID of the latest ingestion for tracking purposes
      // - latestGapListRowJSON: the entire row
      // - latestGapListPassthroughJSON: a subset of the row for passing back to the partner with patient data in reports
      latestGapListUploadID?: string | null,
      latestGapListRowJSON?: string | null,
      latestGapListPassthroughJSON?: string | null,
      // A reference to a set of branding material provided for outreach.
      brandCode?: string | null,
      population?: PatientPopulation | null,
      elationID?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    partnerOrganization: PartnerOrganization,
    referralID: string,
    referral:  {
      __typename: "Referral",
      id: string,
      patientID: string,
      partnerOrganization: PartnerOrganization,
      requisitionUploadS3ObjectIDs?: Array< string > | null,
      serviceTypes: Array< ServiceType >,
      isBooked?: boolean | null,
      isExpired?: boolean | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    },
    zendeskTicketID?: number | null,
    serviceOutcomes?:  Array< {
      __typename: "CareNavigationEpisodeServiceOutcome",
      id: string,
      failureReason?: ServiceFailureReason | null,
      failureFault?: ServiceFailureFault | null,
      isRescheduleRequired?: boolean | null,
      serviceType: ServiceType,
      status?: ServiceStatus | null,
      commentsFromCareNavigatorAboutServiceFailure?: string | null,
      statusChangedAt?: string | null,
      serviceDetailJSON: string,
    } > | null,
    documents?:  Array< {
      __typename: "Document",
      type?: DocumentType | null,
      url?: string | null,
      format?: DocumentFormat | null,
      s3ObjectID?: string | null,
      uploadedAt?: number | null,
      // stringified JSON object as a set of additional data we want to store about documents (requisition number, lab report status, etc.)
      metadataJSON?: string | null,
    } > | null,
    observations?:  Array< {
      __typename: "Observation",
      id: string,
      // Per FHIR specifications, we should only have value or valueCoding
      value?: string | null,
      units?: string | null,
      referenceRange?: string | null,
      category: ObservationCategory,
      performerType: ObservationPerformerType,
      performerIdentifier?: string | null,
      observedAt?: string | null,
      bodySiteLaterality?: Laterality | null,
      bodySiteStructure?: BodyStructure | null,
      notes?: string | null,
      // Also called Abnormal Flag. A categorical assessment, providing a rough qualitative interpretation of the observation value.
      // http://hl7.org/fhir/valueset-observation-interpretation.html
      interpretationCode?: ObservationInterpretationCode | null,
      source?: ObservationSource | null,
    } > | null,
    onboardingQuestionsAndAnswers?:  Array< {
      __typename: "OnboardingQuestionAnswerPair",
      key: string,
      question: string,
      answer: string,
    } > | null,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};

export type CreateS3ObjectDataMutationVariables = {
  input: CreateS3ObjectDataInput,
  condition?: ModelS3ObjectDataConditionInput | null,
};

export type CreateS3ObjectDataMutation = {
  createS3ObjectData?:  {
    __typename: "S3ObjectData",
    id: string,
    region: string,
    bucket: string,
    key: string,
    identityID: string,
    parentType: S3ObjectParentType,
    parentID: string,
    contentType?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateS3ObjectDataMutationVariables = {
  input: UpdateS3ObjectDataInput,
  condition?: ModelS3ObjectDataConditionInput | null,
};

export type UpdateS3ObjectDataMutation = {
  updateS3ObjectData?:  {
    __typename: "S3ObjectData",
    id: string,
    region: string,
    bucket: string,
    key: string,
    identityID: string,
    parentType: S3ObjectParentType,
    parentID: string,
    contentType?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteS3ObjectDataMutationVariables = {
  input: DeleteS3ObjectDataInput,
  condition?: ModelS3ObjectDataConditionInput | null,
};

export type DeleteS3ObjectDataMutation = {
  deleteS3ObjectData?:  {
    __typename: "S3ObjectData",
    id: string,
    region: string,
    bucket: string,
    key: string,
    identityID: string,
    parentType: S3ObjectParentType,
    parentID: string,
    contentType?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePartnerUserInviteMutationVariables = {
  input: CreatePartnerUserInviteInput,
  condition?: ModelPartnerUserInviteConditionInput | null,
};

export type CreatePartnerUserInviteMutation = {
  createPartnerUserInvite?:  {
    __typename: "PartnerUserInvite",
    id: string,
    email: string,
    partnerOrganization: PartnerOrganization,
    status: PartnerUserInviteStatus,
    expiry: number,
    ttl?: number | null,
    managePartnerUsersAuthorization: ManagePartnerUsersAuthorization,
    isArchived?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePartnerUserInviteMutationVariables = {
  input: UpdatePartnerUserInviteInput,
  condition?: ModelPartnerUserInviteConditionInput | null,
};

export type UpdatePartnerUserInviteMutation = {
  updatePartnerUserInvite?:  {
    __typename: "PartnerUserInvite",
    id: string,
    email: string,
    partnerOrganization: PartnerOrganization,
    status: PartnerUserInviteStatus,
    expiry: number,
    ttl?: number | null,
    managePartnerUsersAuthorization: ManagePartnerUsersAuthorization,
    isArchived?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePartnerUserInviteMutationVariables = {
  input: DeletePartnerUserInviteInput,
  condition?: ModelPartnerUserInviteConditionInput | null,
};

export type DeletePartnerUserInviteMutation = {
  deletePartnerUserInvite?:  {
    __typename: "PartnerUserInvite",
    id: string,
    email: string,
    partnerOrganization: PartnerOrganization,
    status: PartnerUserInviteStatus,
    expiry: number,
    ttl?: number | null,
    managePartnerUsersAuthorization: ManagePartnerUsersAuthorization,
    isArchived?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePractitionerMutationVariables = {
  input: CreatePractitionerInput,
  condition?: ModelPractitionerConditionInput | null,
};

export type CreatePractitionerMutation = {
  createPractitioner?:  {
    __typename: "Practitioner",
    id: string,
    // national provider identifier
    NPI: string,
    name?:  {
      __typename: "HumanName",
      use: NameUse,
      family?: string | null,
      given?: Array< string > | null,
      prefix?: HumanNamePrefix | null,
      suffix?: string | null,
    } | null,
    practitionerGroupName?: string | null,
    address?:  {
      __typename: "Address",
      use?: AddressUse | null,
      lines: Array< string >,
      subunit?: string | null,
      city: string,
      state: string,
      postalCode: string,
    } | null,
    phone?: string | null,
    email?: string | null,
    fax?: string | null,
    faxConfirmationStatus?: FaxConfirmationStatus | null,
    faxLastConfirmedAt?: string | null,
    testFaxLastSentAt?: string | null,
    incorrectFaxes?: Array< string > | null,
    role?: PractitionerRole | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePractitionerMutationVariables = {
  input: UpdatePractitionerInput,
  condition?: ModelPractitionerConditionInput | null,
};

export type UpdatePractitionerMutation = {
  updatePractitioner?:  {
    __typename: "Practitioner",
    id: string,
    // national provider identifier
    NPI: string,
    name?:  {
      __typename: "HumanName",
      use: NameUse,
      family?: string | null,
      given?: Array< string > | null,
      prefix?: HumanNamePrefix | null,
      suffix?: string | null,
    } | null,
    practitionerGroupName?: string | null,
    address?:  {
      __typename: "Address",
      use?: AddressUse | null,
      lines: Array< string >,
      subunit?: string | null,
      city: string,
      state: string,
      postalCode: string,
    } | null,
    phone?: string | null,
    email?: string | null,
    fax?: string | null,
    faxConfirmationStatus?: FaxConfirmationStatus | null,
    faxLastConfirmedAt?: string | null,
    testFaxLastSentAt?: string | null,
    incorrectFaxes?: Array< string > | null,
    role?: PractitionerRole | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePractitionerMutationVariables = {
  input: DeletePractitionerInput,
  condition?: ModelPractitionerConditionInput | null,
};

export type DeletePractitionerMutation = {
  deletePractitioner?:  {
    __typename: "Practitioner",
    id: string,
    // national provider identifier
    NPI: string,
    name?:  {
      __typename: "HumanName",
      use: NameUse,
      family?: string | null,
      given?: Array< string > | null,
      prefix?: HumanNamePrefix | null,
      suffix?: string | null,
    } | null,
    practitionerGroupName?: string | null,
    address?:  {
      __typename: "Address",
      use?: AddressUse | null,
      lines: Array< string >,
      subunit?: string | null,
      city: string,
      state: string,
      postalCode: string,
    } | null,
    phone?: string | null,
    email?: string | null,
    fax?: string | null,
    faxConfirmationStatus?: FaxConfirmationStatus | null,
    faxLastConfirmedAt?: string | null,
    testFaxLastSentAt?: string | null,
    incorrectFaxes?: Array< string > | null,
    role?: PractitionerRole | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTechMutationVariables = {
  input: CreateTechInput,
  condition?: ModelTechConditionInput | null,
};

export type CreateTechMutation = {
  createTech?:  {
    __typename: "Tech",
    id: string,
    name:  {
      __typename: "HumanName",
      use: NameUse,
      family?: string | null,
      given?: Array< string > | null,
      prefix?: HumanNamePrefix | null,
      suffix?: string | null,
    },
    gender?: Gender | null,
    phone?: string | null,
    email?: string | null,
    vehicle?:  {
      __typename: "Vehicle",
      make: string,
      model: string,
      color: string,
    } | null,
    serviceTypes?: Array< ServiceType | null > | null,
    serviceTypesAvailabilityRules?:  Array< {
      __typename: "ServiceTypeAvailabilityRules",
      serviceType: ServiceType,
    } > | null,
    availabilityRules?:  Array< {
      __typename: "TimeRangeRule",
      // The unique ID for the time range.
      id: string,
      // Whether the time range represents an addition or subtraction when considering multiple ranges.
      inclusion: TimeRangeRuleInclusion,
      // The time of day each recurrence starts (inclusive).
      start: string,
      // The time of day each recurrence ends (exclusive). Use '24:00:00' for the end of the day.
      end: string,
      // The first date that the recurrence is valid (inclusive).
      recurrenceStart: string,
      // The last date that the recurrence is valid (inclusive). If not set, the range extends indefinitely.
      recurrenceEnd?: string | null,
      // The number of periods between each recurrence. For example, with a multiple of N and period of DAY, the time range will recur every N days.
      recurrenceMultiple: number,
      // The unit of recurrence. For example, with a period of WEEK and multiple of 2, the time range will recur every other week.
      recurrencePeriod: TimeRangeRulePeriod,
      // An optional reason for the time range rule, used with time-off unavailability rules (lunch breaks, PTO, etc.)
      unavailabilityReason?: TimeRangeRuleUnavailabilityReason | null,
      // Additional notes about the time range rule.
      notes?: string | null,
      // The date and time that the rule was last updated.
      updatedAt?: string | null,
      // The user who last updated the rule.
      updatedBy?: string | null,
    } > | null,
    locations?:  Array< {
      __typename: "Location",
      satellitePhotoURL?: string | null,
      streetViewPhotoURL?: string | null,
    } > | null,
    profilePicture?:  {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } | null,
    profilePictureS3ObjectID?: string | null,
    isActive?: boolean | null,
    serviceRegions?:  {
      __typename: "ModelTechServiceRegionConnection",
      nextToken?: string | null,
    } | null,
    appointments?:  {
      __typename: "ModelAppointmentConnection",
      nextToken?: string | null,
    } | null,
    biographyItems?: Array< string > | null,
    patientTestimonials?: Array< string > | null,
    partialAppointmentsForTeamAvailabilityJSON?: string | null,
    capabilities?: Array< TechCapability > | null,
    isIneligibleForMatchmaking?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTechServiceRegionMutationVariables = {
  input: CreateTechServiceRegionInput,
  condition?: ModelTechServiceRegionConditionInput | null,
};

export type CreateTechServiceRegionMutation = {
  createTechServiceRegion?:  {
    __typename: "TechServiceRegion",
    id: string,
    techID: string,
    serviceRegionName: string,
    tech:  {
      __typename: "Tech",
      id: string,
      gender?: Gender | null,
      phone?: string | null,
      email?: string | null,
      serviceTypes?: Array< ServiceType | null > | null,
      profilePictureS3ObjectID?: string | null,
      isActive?: boolean | null,
      biographyItems?: Array< string > | null,
      patientTestimonials?: Array< string > | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      capabilities?: Array< TechCapability > | null,
      isIneligibleForMatchmaking?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    availabilityRules?:  Array< {
      __typename: "TimeRangeRule",
      // The unique ID for the time range.
      id: string,
      // Whether the time range represents an addition or subtraction when considering multiple ranges.
      inclusion: TimeRangeRuleInclusion,
      // The time of day each recurrence starts (inclusive).
      start: string,
      // The time of day each recurrence ends (exclusive). Use '24:00:00' for the end of the day.
      end: string,
      // The first date that the recurrence is valid (inclusive).
      recurrenceStart: string,
      // The last date that the recurrence is valid (inclusive). If not set, the range extends indefinitely.
      recurrenceEnd?: string | null,
      // The number of periods between each recurrence. For example, with a multiple of N and period of DAY, the time range will recur every N days.
      recurrenceMultiple: number,
      // The unit of recurrence. For example, with a period of WEEK and multiple of 2, the time range will recur every other week.
      recurrencePeriod: TimeRangeRulePeriod,
      // An optional reason for the time range rule, used with time-off unavailability rules (lunch breaks, PTO, etc.)
      unavailabilityReason?: TimeRangeRuleUnavailabilityReason | null,
      // Additional notes about the time range rule.
      notes?: string | null,
      // The date and time that the rule was last updated.
      updatedAt?: string | null,
      // The user who last updated the rule.
      updatedBy?: string | null,
    } > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTechServiceRegionMutationVariables = {
  input: UpdateTechServiceRegionInput,
  condition?: ModelTechServiceRegionConditionInput | null,
};

export type UpdateTechServiceRegionMutation = {
  updateTechServiceRegion?:  {
    __typename: "TechServiceRegion",
    id: string,
    techID: string,
    serviceRegionName: string,
    tech:  {
      __typename: "Tech",
      id: string,
      gender?: Gender | null,
      phone?: string | null,
      email?: string | null,
      serviceTypes?: Array< ServiceType | null > | null,
      profilePictureS3ObjectID?: string | null,
      isActive?: boolean | null,
      biographyItems?: Array< string > | null,
      patientTestimonials?: Array< string > | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      capabilities?: Array< TechCapability > | null,
      isIneligibleForMatchmaking?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    availabilityRules?:  Array< {
      __typename: "TimeRangeRule",
      // The unique ID for the time range.
      id: string,
      // Whether the time range represents an addition or subtraction when considering multiple ranges.
      inclusion: TimeRangeRuleInclusion,
      // The time of day each recurrence starts (inclusive).
      start: string,
      // The time of day each recurrence ends (exclusive). Use '24:00:00' for the end of the day.
      end: string,
      // The first date that the recurrence is valid (inclusive).
      recurrenceStart: string,
      // The last date that the recurrence is valid (inclusive). If not set, the range extends indefinitely.
      recurrenceEnd?: string | null,
      // The number of periods between each recurrence. For example, with a multiple of N and period of DAY, the time range will recur every N days.
      recurrenceMultiple: number,
      // The unit of recurrence. For example, with a period of WEEK and multiple of 2, the time range will recur every other week.
      recurrencePeriod: TimeRangeRulePeriod,
      // An optional reason for the time range rule, used with time-off unavailability rules (lunch breaks, PTO, etc.)
      unavailabilityReason?: TimeRangeRuleUnavailabilityReason | null,
      // Additional notes about the time range rule.
      notes?: string | null,
      // The date and time that the rule was last updated.
      updatedAt?: string | null,
      // The user who last updated the rule.
      updatedBy?: string | null,
    } > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTechServiceRegionMutationVariables = {
  input: DeleteTechServiceRegionInput,
  condition?: ModelTechServiceRegionConditionInput | null,
};

export type DeleteTechServiceRegionMutation = {
  deleteTechServiceRegion?:  {
    __typename: "TechServiceRegion",
    id: string,
    techID: string,
    serviceRegionName: string,
    tech:  {
      __typename: "Tech",
      id: string,
      gender?: Gender | null,
      phone?: string | null,
      email?: string | null,
      serviceTypes?: Array< ServiceType | null > | null,
      profilePictureS3ObjectID?: string | null,
      isActive?: boolean | null,
      biographyItems?: Array< string > | null,
      patientTestimonials?: Array< string > | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      capabilities?: Array< TechCapability > | null,
      isIneligibleForMatchmaking?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    availabilityRules?:  Array< {
      __typename: "TimeRangeRule",
      // The unique ID for the time range.
      id: string,
      // Whether the time range represents an addition or subtraction when considering multiple ranges.
      inclusion: TimeRangeRuleInclusion,
      // The time of day each recurrence starts (inclusive).
      start: string,
      // The time of day each recurrence ends (exclusive). Use '24:00:00' for the end of the day.
      end: string,
      // The first date that the recurrence is valid (inclusive).
      recurrenceStart: string,
      // The last date that the recurrence is valid (inclusive). If not set, the range extends indefinitely.
      recurrenceEnd?: string | null,
      // The number of periods between each recurrence. For example, with a multiple of N and period of DAY, the time range will recur every N days.
      recurrenceMultiple: number,
      // The unit of recurrence. For example, with a period of WEEK and multiple of 2, the time range will recur every other week.
      recurrencePeriod: TimeRangeRulePeriod,
      // An optional reason for the time range rule, used with time-off unavailability rules (lunch breaks, PTO, etc.)
      unavailabilityReason?: TimeRangeRuleUnavailabilityReason | null,
      // Additional notes about the time range rule.
      notes?: string | null,
      // The date and time that the rule was last updated.
      updatedAt?: string | null,
      // The user who last updated the rule.
      updatedBy?: string | null,
    } > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateRemoteTechMutationVariables = {
  input: CreateRemoteTechInput,
  condition?: ModelRemoteTechConditionInput | null,
};

export type CreateRemoteTechMutation = {
  createRemoteTech?:  {
    __typename: "RemoteTech",
    id: string,
    name:  {
      __typename: "HumanName",
      use: NameUse,
      family?: string | null,
      given?: Array< string > | null,
      prefix?: HumanNamePrefix | null,
      suffix?: string | null,
    },
    email?: string | null,
    phone?: string | null,
    profilePicture?:  {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } | null,
    profilePictureS3ObjectID?: string | null,
    timezone?: Timezone | null,
    availabilityRules?:  Array< {
      __typename: "TimeRangeRule",
      // The unique ID for the time range.
      id: string,
      // Whether the time range represents an addition or subtraction when considering multiple ranges.
      inclusion: TimeRangeRuleInclusion,
      // The time of day each recurrence starts (inclusive).
      start: string,
      // The time of day each recurrence ends (exclusive). Use '24:00:00' for the end of the day.
      end: string,
      // The first date that the recurrence is valid (inclusive).
      recurrenceStart: string,
      // The last date that the recurrence is valid (inclusive). If not set, the range extends indefinitely.
      recurrenceEnd?: string | null,
      // The number of periods between each recurrence. For example, with a multiple of N and period of DAY, the time range will recur every N days.
      recurrenceMultiple: number,
      // The unit of recurrence. For example, with a period of WEEK and multiple of 2, the time range will recur every other week.
      recurrencePeriod: TimeRangeRulePeriod,
      // An optional reason for the time range rule, used with time-off unavailability rules (lunch breaks, PTO, etc.)
      unavailabilityReason?: TimeRangeRuleUnavailabilityReason | null,
      // Additional notes about the time range rule.
      notes?: string | null,
      // The date and time that the rule was last updated.
      updatedAt?: string | null,
      // The user who last updated the rule.
      updatedBy?: string | null,
    } > | null,
    isActive: boolean,
    qualifications?:  Array< {
      __typename: "Qualification",
      qualificationType: QualificationType,
      geographicalState: string,
    } > | null,
    remoteVisitZoomLink?: string | null,
    remoteVisitZoomLinkQRCode?:  {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } | null,
    remoteVisitZoomLinkQRCodeS3ObjectID?: string | null,
    appointments?:  {
      __typename: "ModelAppointmentConnection",
      nextToken?: string | null,
    } | null,
    partialAppointmentsForTeamAvailabilityJSON?: string | null,
    matchableAndAcceptedAppointments?:  Array< {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateRemoteTechMutationVariables = {
  input: UpdateRemoteTechInput,
  condition?: ModelRemoteTechConditionInput | null,
};

export type UpdateRemoteTechMutation = {
  updateRemoteTech?:  {
    __typename: "RemoteTech",
    id: string,
    name:  {
      __typename: "HumanName",
      use: NameUse,
      family?: string | null,
      given?: Array< string > | null,
      prefix?: HumanNamePrefix | null,
      suffix?: string | null,
    },
    email?: string | null,
    phone?: string | null,
    profilePicture?:  {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } | null,
    profilePictureS3ObjectID?: string | null,
    timezone?: Timezone | null,
    availabilityRules?:  Array< {
      __typename: "TimeRangeRule",
      // The unique ID for the time range.
      id: string,
      // Whether the time range represents an addition or subtraction when considering multiple ranges.
      inclusion: TimeRangeRuleInclusion,
      // The time of day each recurrence starts (inclusive).
      start: string,
      // The time of day each recurrence ends (exclusive). Use '24:00:00' for the end of the day.
      end: string,
      // The first date that the recurrence is valid (inclusive).
      recurrenceStart: string,
      // The last date that the recurrence is valid (inclusive). If not set, the range extends indefinitely.
      recurrenceEnd?: string | null,
      // The number of periods between each recurrence. For example, with a multiple of N and period of DAY, the time range will recur every N days.
      recurrenceMultiple: number,
      // The unit of recurrence. For example, with a period of WEEK and multiple of 2, the time range will recur every other week.
      recurrencePeriod: TimeRangeRulePeriod,
      // An optional reason for the time range rule, used with time-off unavailability rules (lunch breaks, PTO, etc.)
      unavailabilityReason?: TimeRangeRuleUnavailabilityReason | null,
      // Additional notes about the time range rule.
      notes?: string | null,
      // The date and time that the rule was last updated.
      updatedAt?: string | null,
      // The user who last updated the rule.
      updatedBy?: string | null,
    } > | null,
    isActive: boolean,
    qualifications?:  Array< {
      __typename: "Qualification",
      qualificationType: QualificationType,
      geographicalState: string,
    } > | null,
    remoteVisitZoomLink?: string | null,
    remoteVisitZoomLinkQRCode?:  {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } | null,
    remoteVisitZoomLinkQRCodeS3ObjectID?: string | null,
    appointments?:  {
      __typename: "ModelAppointmentConnection",
      nextToken?: string | null,
    } | null,
    partialAppointmentsForTeamAvailabilityJSON?: string | null,
    matchableAndAcceptedAppointments?:  Array< {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCoordinatorMutationVariables = {
  input: CreateCoordinatorInput,
  condition?: ModelCoordinatorConditionInput | null,
};

export type CreateCoordinatorMutation = {
  createCoordinator?:  {
    __typename: "Coordinator",
    id: string,
    authorizedTechIDs?: Array< string > | null,
    name?:  {
      __typename: "HumanName",
      use: NameUse,
      family?: string | null,
      given?: Array< string > | null,
      prefix?: HumanNamePrefix | null,
      suffix?: string | null,
    } | null,
    // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
    isCSR?: boolean | null,
    isActive?: boolean | null,
    managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
    authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
    phone?: string | null,
    email?: string | null,
    consentToContactForAppointmentBySMS?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    billingCustomerID?: string | null,
    consentToContactForResearch?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    consentToContactForMarketing?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
    stripeCustomerID?: string | null,
    patients?:  {
      __typename: "ModelPatientConnection",
      nextToken?: string | null,
    } | null,
    isArchived?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCoordinatorMutationVariables = {
  input: UpdateCoordinatorInput,
  condition?: ModelCoordinatorConditionInput | null,
};

export type UpdateCoordinatorMutation = {
  updateCoordinator?:  {
    __typename: "Coordinator",
    id: string,
    authorizedTechIDs?: Array< string > | null,
    name?:  {
      __typename: "HumanName",
      use: NameUse,
      family?: string | null,
      given?: Array< string > | null,
      prefix?: HumanNamePrefix | null,
      suffix?: string | null,
    } | null,
    // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
    isCSR?: boolean | null,
    isActive?: boolean | null,
    managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
    authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
    phone?: string | null,
    email?: string | null,
    consentToContactForAppointmentBySMS?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    billingCustomerID?: string | null,
    consentToContactForResearch?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    consentToContactForMarketing?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
    stripeCustomerID?: string | null,
    patients?:  {
      __typename: "ModelPatientConnection",
      nextToken?: string | null,
    } | null,
    isArchived?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCoordinatorMutationVariables = {
  input: DeleteCoordinatorInput,
  condition?: ModelCoordinatorConditionInput | null,
};

export type DeleteCoordinatorMutation = {
  deleteCoordinator?:  {
    __typename: "Coordinator",
    id: string,
    authorizedTechIDs?: Array< string > | null,
    name?:  {
      __typename: "HumanName",
      use: NameUse,
      family?: string | null,
      given?: Array< string > | null,
      prefix?: HumanNamePrefix | null,
      suffix?: string | null,
    } | null,
    // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
    isCSR?: boolean | null,
    isActive?: boolean | null,
    managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
    authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
    phone?: string | null,
    email?: string | null,
    consentToContactForAppointmentBySMS?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    billingCustomerID?: string | null,
    consentToContactForResearch?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    consentToContactForMarketing?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
    stripeCustomerID?: string | null,
    patients?:  {
      __typename: "ModelPatientConnection",
      nextToken?: string | null,
    } | null,
    isArchived?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePatientMutationVariables = {
  input: CreatePatientInput,
  condition?: ModelPatientConditionInput | null,
};

export type CreatePatientMutation = {
  createPatient?:  {
    __typename: "Patient",
    id: string,
    csrPartnerOrganization?: PartnerOrganization | null,
    partnerOrganization?: PartnerOrganization | null,
    location?:  {
      __typename: "Location",
      satellitePhotoURL?: string | null,
      streetViewPhotoURL?: string | null,
    } | null,
    coordinatorID: string,
    medicareCardID?: string | null,
    imageMoverUserID?: string | null,
    externalID?: string | null,
    secondaryExternalID?: string | null,
    specimenLabelID?: string | null,
    labOrganizationPatientIDs?:  Array< {
      __typename: "LabOrganizationPatientID",
      labOrganization: LabOrganization,
      externalID: string,
      planNameForLabOrdering: string,
    } > | null,
    qtMedicalPatientID?: string | null,
    planName?: string | null,
    diabeticType?: DiabeticType | null,
    authorizedTechIDs?: Array< string > | null,
    isCoordinator: boolean,
    // Phone & email to use in place of the coordinators for messaging. Used primarily for patients whose appointments are booked for them by a partner's customer service representatives.
    phone?: string | null,
    email?: string | null,
    secondaryPhone?: string | null,
    consentToContactForAppointmentBySMS?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    consentToContactForAppointmentByEmail?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    name:  {
      __typename: "HumanName",
      use: NameUse,
      family?: string | null,
      given?: Array< string > | null,
      prefix?: HumanNamePrefix | null,
      suffix?: string | null,
    },
    caregiverName?:  {
      __typename: "HumanName",
      use: NameUse,
      family?: string | null,
      given?: Array< string > | null,
      prefix?: HumanNamePrefix | null,
      suffix?: string | null,
    } | null,
    birthdate?: string | null,
    sex?: Sex | null,
    gender?: Gender | null,
    ethnicity?: Ethnicity | null,
    races?: Array< Race > | null,
    passportNumber?: string | null,
    insuranceCardUploadFront?:  {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } | null,
    insuranceCardUploadBack?:  {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } | null,
    insuranceCardUploadFrontS3ObjectID?: string | null,
    insuranceCardUploadBackS3ObjectID?: string | null,
    medicareCardUploadFront?:  {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } | null,
    medicareCardUploadBack?:  {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } | null,
    medicareCardUploadFrontS3ObjectID?: string | null,
    medicareCardUploadBackS3ObjectID?: string | null,
    encounters?:  {
      __typename: "ModelEncounterConnection",
      nextToken?: string | null,
    } | null,
    coordinator:  {
      __typename: "Coordinator",
      id: string,
      authorizedTechIDs?: Array< string > | null,
      // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
      isCSR?: boolean | null,
      isActive?: boolean | null,
      managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
      authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
      phone?: string | null,
      email?: string | null,
      billingCustomerID?: string | null,
      outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
      stripeCustomerID?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    commentsFromTechsAboutEncounters?:  Array< {
      __typename: "CommentsFromTechAboutEncounter",
      encounterID: string,
      appointmentStart: string,
      comments?: string | null,
    } > | null,
    bloodCollectionDetailsFromEncounters?:  Array< {
      __typename: "SpecimenCollectionDetailsFromEncounter",
      encounterID: string,
      appointmentStart: string,
      failureReason?: ServiceFailureReason | null,
    } > | null,
    capillaryDrawCollectionDetailsFromEncounters?:  Array< {
      __typename: "SpecimenCollectionDetailsFromEncounter",
      encounterID: string,
      appointmentStart: string,
      failureReason?: ServiceFailureReason | null,
    } > | null,
    referrals?:  {
      __typename: "ModelReferralConnection",
      nextToken?: string | null,
    } | null,
    careNavigationEpisodes?:  {
      __typename: "ModelCareNavigationEpisodeConnection",
      nextToken?: string | null,
    } | null,
    campaigns?:  {
      __typename: "ModelPatientCampaignStateConnection",
      nextToken?: string | null,
    } | null,
    watchlistedAppointmentID?: string | null,
    watchlistedAppointment?:  {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    responsiblePractitionerID?: string | null,
    responsiblePractitioner?:  {
      __typename: "Practitioner",
      id: string,
      // national provider identifier
      NPI: string,
      practitionerGroupName?: string | null,
      phone?: string | null,
      email?: string | null,
      fax?: string | null,
      faxConfirmationStatus?: FaxConfirmationStatus | null,
      faxLastConfirmedAt?: string | null,
      testFaxLastSentAt?: string | null,
      incorrectFaxes?: Array< string > | null,
      role?: PractitionerRole | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
    commentsFromCaregiverAboutResponsiblePractitioner?: string | null,
    consentToContactForMarketingSMS?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    consentToContactForMarketingEmail?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    consentToContactForMarketingPhone?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    suggestedAppointmentLocation?:  {
      __typename: "Location",
      satellitePhotoURL?: string | null,
      streetViewPhotoURL?: string | null,
    } | null,
    suggestedAppointmentLocationAdditionalInformation?: string | null,
    mailingAddress?:  {
      __typename: "Address",
      use?: AddressUse | null,
      lines: Array< string >,
      subunit?: string | null,
      city: string,
      state: string,
      postalCode: string,
    } | null,
    preferredLanguage?: string | null,
    primaryLanguageBCP47?: string | null,
    secondaryLanguagesBCP47?: Array< string > | null,
    // A unique numerical patient reference number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
    patientNumber?: string | null,
    // latest validated gap list data referencing this patient.
    // - latestGapListUploadID: the upload ID of the latest ingestion for tracking purposes
    // - latestGapListRowJSON: the entire row
    // - latestGapListPassthroughJSON: a subset of the row for passing back to the partner with patient data in reports
    latestGapListUploadID?: string | null,
    latestGapListRowJSON?: string | null,
    latestGapListPassthroughJSON?: string | null,
    // A reference to a set of branding material provided for outreach.
    brandCode?: string | null,
    population?: PatientPopulation | null,
    elationID?: string | null,
    consents?:  Array< {
      __typename: "ConsentForm",
      consentedAt: string,
      type: ConsentType,
      platform: QuestionnairePlatform,
      formID: string,
      responseID: string,
      appointmentID: string,
      rawResponseJSON: string,
    } > | null,
    telecom?:  {
      __typename: "ModelPatientContactPointConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePatientMutationVariables = {
  input: UpdatePatientInput,
  condition?: ModelPatientConditionInput | null,
};

export type UpdatePatientMutation = {
  updatePatient?:  {
    __typename: "Patient",
    id: string,
    csrPartnerOrganization?: PartnerOrganization | null,
    partnerOrganization?: PartnerOrganization | null,
    location?:  {
      __typename: "Location",
      satellitePhotoURL?: string | null,
      streetViewPhotoURL?: string | null,
    } | null,
    coordinatorID: string,
    medicareCardID?: string | null,
    imageMoverUserID?: string | null,
    externalID?: string | null,
    secondaryExternalID?: string | null,
    specimenLabelID?: string | null,
    labOrganizationPatientIDs?:  Array< {
      __typename: "LabOrganizationPatientID",
      labOrganization: LabOrganization,
      externalID: string,
      planNameForLabOrdering: string,
    } > | null,
    qtMedicalPatientID?: string | null,
    planName?: string | null,
    diabeticType?: DiabeticType | null,
    authorizedTechIDs?: Array< string > | null,
    isCoordinator: boolean,
    // Phone & email to use in place of the coordinators for messaging. Used primarily for patients whose appointments are booked for them by a partner's customer service representatives.
    phone?: string | null,
    email?: string | null,
    secondaryPhone?: string | null,
    consentToContactForAppointmentBySMS?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    consentToContactForAppointmentByEmail?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    name:  {
      __typename: "HumanName",
      use: NameUse,
      family?: string | null,
      given?: Array< string > | null,
      prefix?: HumanNamePrefix | null,
      suffix?: string | null,
    },
    caregiverName?:  {
      __typename: "HumanName",
      use: NameUse,
      family?: string | null,
      given?: Array< string > | null,
      prefix?: HumanNamePrefix | null,
      suffix?: string | null,
    } | null,
    birthdate?: string | null,
    sex?: Sex | null,
    gender?: Gender | null,
    ethnicity?: Ethnicity | null,
    races?: Array< Race > | null,
    passportNumber?: string | null,
    insuranceCardUploadFront?:  {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } | null,
    insuranceCardUploadBack?:  {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } | null,
    insuranceCardUploadFrontS3ObjectID?: string | null,
    insuranceCardUploadBackS3ObjectID?: string | null,
    medicareCardUploadFront?:  {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } | null,
    medicareCardUploadBack?:  {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } | null,
    medicareCardUploadFrontS3ObjectID?: string | null,
    medicareCardUploadBackS3ObjectID?: string | null,
    encounters?:  {
      __typename: "ModelEncounterConnection",
      nextToken?: string | null,
    } | null,
    coordinator:  {
      __typename: "Coordinator",
      id: string,
      authorizedTechIDs?: Array< string > | null,
      // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
      isCSR?: boolean | null,
      isActive?: boolean | null,
      managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
      authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
      phone?: string | null,
      email?: string | null,
      billingCustomerID?: string | null,
      outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
      stripeCustomerID?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    commentsFromTechsAboutEncounters?:  Array< {
      __typename: "CommentsFromTechAboutEncounter",
      encounterID: string,
      appointmentStart: string,
      comments?: string | null,
    } > | null,
    bloodCollectionDetailsFromEncounters?:  Array< {
      __typename: "SpecimenCollectionDetailsFromEncounter",
      encounterID: string,
      appointmentStart: string,
      failureReason?: ServiceFailureReason | null,
    } > | null,
    capillaryDrawCollectionDetailsFromEncounters?:  Array< {
      __typename: "SpecimenCollectionDetailsFromEncounter",
      encounterID: string,
      appointmentStart: string,
      failureReason?: ServiceFailureReason | null,
    } > | null,
    referrals?:  {
      __typename: "ModelReferralConnection",
      nextToken?: string | null,
    } | null,
    careNavigationEpisodes?:  {
      __typename: "ModelCareNavigationEpisodeConnection",
      nextToken?: string | null,
    } | null,
    campaigns?:  {
      __typename: "ModelPatientCampaignStateConnection",
      nextToken?: string | null,
    } | null,
    watchlistedAppointmentID?: string | null,
    watchlistedAppointment?:  {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    responsiblePractitionerID?: string | null,
    responsiblePractitioner?:  {
      __typename: "Practitioner",
      id: string,
      // national provider identifier
      NPI: string,
      practitionerGroupName?: string | null,
      phone?: string | null,
      email?: string | null,
      fax?: string | null,
      faxConfirmationStatus?: FaxConfirmationStatus | null,
      faxLastConfirmedAt?: string | null,
      testFaxLastSentAt?: string | null,
      incorrectFaxes?: Array< string > | null,
      role?: PractitionerRole | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
    commentsFromCaregiverAboutResponsiblePractitioner?: string | null,
    consentToContactForMarketingSMS?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    consentToContactForMarketingEmail?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    consentToContactForMarketingPhone?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    suggestedAppointmentLocation?:  {
      __typename: "Location",
      satellitePhotoURL?: string | null,
      streetViewPhotoURL?: string | null,
    } | null,
    suggestedAppointmentLocationAdditionalInformation?: string | null,
    mailingAddress?:  {
      __typename: "Address",
      use?: AddressUse | null,
      lines: Array< string >,
      subunit?: string | null,
      city: string,
      state: string,
      postalCode: string,
    } | null,
    preferredLanguage?: string | null,
    primaryLanguageBCP47?: string | null,
    secondaryLanguagesBCP47?: Array< string > | null,
    // A unique numerical patient reference number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
    patientNumber?: string | null,
    // latest validated gap list data referencing this patient.
    // - latestGapListUploadID: the upload ID of the latest ingestion for tracking purposes
    // - latestGapListRowJSON: the entire row
    // - latestGapListPassthroughJSON: a subset of the row for passing back to the partner with patient data in reports
    latestGapListUploadID?: string | null,
    latestGapListRowJSON?: string | null,
    latestGapListPassthroughJSON?: string | null,
    // A reference to a set of branding material provided for outreach.
    brandCode?: string | null,
    population?: PatientPopulation | null,
    elationID?: string | null,
    consents?:  Array< {
      __typename: "ConsentForm",
      consentedAt: string,
      type: ConsentType,
      platform: QuestionnairePlatform,
      formID: string,
      responseID: string,
      appointmentID: string,
      rawResponseJSON: string,
    } > | null,
    telecom?:  {
      __typename: "ModelPatientContactPointConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePatientMutationVariables = {
  input: DeletePatientInput,
  condition?: ModelPatientConditionInput | null,
};

export type DeletePatientMutation = {
  deletePatient?:  {
    __typename: "Patient",
    id: string,
    csrPartnerOrganization?: PartnerOrganization | null,
    partnerOrganization?: PartnerOrganization | null,
    location?:  {
      __typename: "Location",
      satellitePhotoURL?: string | null,
      streetViewPhotoURL?: string | null,
    } | null,
    coordinatorID: string,
    medicareCardID?: string | null,
    imageMoverUserID?: string | null,
    externalID?: string | null,
    secondaryExternalID?: string | null,
    specimenLabelID?: string | null,
    labOrganizationPatientIDs?:  Array< {
      __typename: "LabOrganizationPatientID",
      labOrganization: LabOrganization,
      externalID: string,
      planNameForLabOrdering: string,
    } > | null,
    qtMedicalPatientID?: string | null,
    planName?: string | null,
    diabeticType?: DiabeticType | null,
    authorizedTechIDs?: Array< string > | null,
    isCoordinator: boolean,
    // Phone & email to use in place of the coordinators for messaging. Used primarily for patients whose appointments are booked for them by a partner's customer service representatives.
    phone?: string | null,
    email?: string | null,
    secondaryPhone?: string | null,
    consentToContactForAppointmentBySMS?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    consentToContactForAppointmentByEmail?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    name:  {
      __typename: "HumanName",
      use: NameUse,
      family?: string | null,
      given?: Array< string > | null,
      prefix?: HumanNamePrefix | null,
      suffix?: string | null,
    },
    caregiverName?:  {
      __typename: "HumanName",
      use: NameUse,
      family?: string | null,
      given?: Array< string > | null,
      prefix?: HumanNamePrefix | null,
      suffix?: string | null,
    } | null,
    birthdate?: string | null,
    sex?: Sex | null,
    gender?: Gender | null,
    ethnicity?: Ethnicity | null,
    races?: Array< Race > | null,
    passportNumber?: string | null,
    insuranceCardUploadFront?:  {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } | null,
    insuranceCardUploadBack?:  {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } | null,
    insuranceCardUploadFrontS3ObjectID?: string | null,
    insuranceCardUploadBackS3ObjectID?: string | null,
    medicareCardUploadFront?:  {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } | null,
    medicareCardUploadBack?:  {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } | null,
    medicareCardUploadFrontS3ObjectID?: string | null,
    medicareCardUploadBackS3ObjectID?: string | null,
    encounters?:  {
      __typename: "ModelEncounterConnection",
      nextToken?: string | null,
    } | null,
    coordinator:  {
      __typename: "Coordinator",
      id: string,
      authorizedTechIDs?: Array< string > | null,
      // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
      isCSR?: boolean | null,
      isActive?: boolean | null,
      managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
      authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
      phone?: string | null,
      email?: string | null,
      billingCustomerID?: string | null,
      outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
      stripeCustomerID?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    commentsFromTechsAboutEncounters?:  Array< {
      __typename: "CommentsFromTechAboutEncounter",
      encounterID: string,
      appointmentStart: string,
      comments?: string | null,
    } > | null,
    bloodCollectionDetailsFromEncounters?:  Array< {
      __typename: "SpecimenCollectionDetailsFromEncounter",
      encounterID: string,
      appointmentStart: string,
      failureReason?: ServiceFailureReason | null,
    } > | null,
    capillaryDrawCollectionDetailsFromEncounters?:  Array< {
      __typename: "SpecimenCollectionDetailsFromEncounter",
      encounterID: string,
      appointmentStart: string,
      failureReason?: ServiceFailureReason | null,
    } > | null,
    referrals?:  {
      __typename: "ModelReferralConnection",
      nextToken?: string | null,
    } | null,
    careNavigationEpisodes?:  {
      __typename: "ModelCareNavigationEpisodeConnection",
      nextToken?: string | null,
    } | null,
    campaigns?:  {
      __typename: "ModelPatientCampaignStateConnection",
      nextToken?: string | null,
    } | null,
    watchlistedAppointmentID?: string | null,
    watchlistedAppointment?:  {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    responsiblePractitionerID?: string | null,
    responsiblePractitioner?:  {
      __typename: "Practitioner",
      id: string,
      // national provider identifier
      NPI: string,
      practitionerGroupName?: string | null,
      phone?: string | null,
      email?: string | null,
      fax?: string | null,
      faxConfirmationStatus?: FaxConfirmationStatus | null,
      faxLastConfirmedAt?: string | null,
      testFaxLastSentAt?: string | null,
      incorrectFaxes?: Array< string > | null,
      role?: PractitionerRole | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
    commentsFromCaregiverAboutResponsiblePractitioner?: string | null,
    consentToContactForMarketingSMS?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    consentToContactForMarketingEmail?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    consentToContactForMarketingPhone?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    suggestedAppointmentLocation?:  {
      __typename: "Location",
      satellitePhotoURL?: string | null,
      streetViewPhotoURL?: string | null,
    } | null,
    suggestedAppointmentLocationAdditionalInformation?: string | null,
    mailingAddress?:  {
      __typename: "Address",
      use?: AddressUse | null,
      lines: Array< string >,
      subunit?: string | null,
      city: string,
      state: string,
      postalCode: string,
    } | null,
    preferredLanguage?: string | null,
    primaryLanguageBCP47?: string | null,
    secondaryLanguagesBCP47?: Array< string > | null,
    // A unique numerical patient reference number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
    patientNumber?: string | null,
    // latest validated gap list data referencing this patient.
    // - latestGapListUploadID: the upload ID of the latest ingestion for tracking purposes
    // - latestGapListRowJSON: the entire row
    // - latestGapListPassthroughJSON: a subset of the row for passing back to the partner with patient data in reports
    latestGapListUploadID?: string | null,
    latestGapListRowJSON?: string | null,
    latestGapListPassthroughJSON?: string | null,
    // A reference to a set of branding material provided for outreach.
    brandCode?: string | null,
    population?: PatientPopulation | null,
    elationID?: string | null,
    consents?:  Array< {
      __typename: "ConsentForm",
      consentedAt: string,
      type: ConsentType,
      platform: QuestionnairePlatform,
      formID: string,
      responseID: string,
      appointmentID: string,
      rawResponseJSON: string,
    } > | null,
    telecom?:  {
      __typename: "ModelPatientContactPointConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePatientContactPointMutationVariables = {
  input: CreatePatientContactPointInput,
  condition?: ModelPatientContactPointConditionInput | null,
};

export type CreatePatientContactPointMutation = {
  createPatientContactPoint?:  {
    __typename: "PatientContactPoint",
    id: string,
    patientID: string,
    patient:  {
      __typename: "Patient",
      id: string,
      csrPartnerOrganization?: PartnerOrganization | null,
      partnerOrganization?: PartnerOrganization | null,
      coordinatorID: string,
      medicareCardID?: string | null,
      imageMoverUserID?: string | null,
      externalID?: string | null,
      secondaryExternalID?: string | null,
      specimenLabelID?: string | null,
      qtMedicalPatientID?: string | null,
      planName?: string | null,
      diabeticType?: DiabeticType | null,
      authorizedTechIDs?: Array< string > | null,
      isCoordinator: boolean,
      // Phone & email to use in place of the coordinators for messaging. Used primarily for patients whose appointments are booked for them by a partner's customer service representatives.
      phone?: string | null,
      email?: string | null,
      secondaryPhone?: string | null,
      birthdate?: string | null,
      sex?: Sex | null,
      gender?: Gender | null,
      ethnicity?: Ethnicity | null,
      races?: Array< Race > | null,
      passportNumber?: string | null,
      insuranceCardUploadFrontS3ObjectID?: string | null,
      insuranceCardUploadBackS3ObjectID?: string | null,
      medicareCardUploadFrontS3ObjectID?: string | null,
      medicareCardUploadBackS3ObjectID?: string | null,
      watchlistedAppointmentID?: string | null,
      responsiblePractitionerID?: string | null,
      commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
      commentsFromCaregiverAboutResponsiblePractitioner?: string | null,
      suggestedAppointmentLocationAdditionalInformation?: string | null,
      preferredLanguage?: string | null,
      primaryLanguageBCP47?: string | null,
      secondaryLanguagesBCP47?: Array< string > | null,
      // A unique numerical patient reference number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
      patientNumber?: string | null,
      // latest validated gap list data referencing this patient.
      // - latestGapListUploadID: the upload ID of the latest ingestion for tracking purposes
      // - latestGapListRowJSON: the entire row
      // - latestGapListPassthroughJSON: a subset of the row for passing back to the partner with patient data in reports
      latestGapListUploadID?: string | null,
      latestGapListRowJSON?: string | null,
      latestGapListPassthroughJSON?: string | null,
      // A reference to a set of branding material provided for outreach.
      brandCode?: string | null,
      population?: PatientPopulation | null,
      elationID?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    system: ContactPointSystem,
    value: string,
    status: ContactPointStatus,
    statusLastUpdatedAt?: string | null,
    sources?:  Array< {
      __typename: "PatientContactPointSource",
      source: ContactPointSource,
      // Specifies a preferred order in which to use a set of contacts. ContactPoints with lower rank values are more preferred than those with higher rank values.
      rank?: number | null,
      system: ContactPointSystem,
      // The date and time the source reports the contact point as last being utilized. For example in the LexisNexis report they include the last time they saw the contact point in their data. This differs from the last time they reported it to _us_.
      reportedAsLastSeenAt?: string | null,
      // The date and time the source first reported this contact point associated to the patient
      firstReportedAt?: string | null,
      // The date and time the source last reported this contact point associated to the patient
      lastReportedAt?: string | null,
      metadataJSON?: string | null,
    } > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePatientContactPointMutationVariables = {
  input: UpdatePatientContactPointInput,
  condition?: ModelPatientContactPointConditionInput | null,
};

export type UpdatePatientContactPointMutation = {
  updatePatientContactPoint?:  {
    __typename: "PatientContactPoint",
    id: string,
    patientID: string,
    patient:  {
      __typename: "Patient",
      id: string,
      csrPartnerOrganization?: PartnerOrganization | null,
      partnerOrganization?: PartnerOrganization | null,
      coordinatorID: string,
      medicareCardID?: string | null,
      imageMoverUserID?: string | null,
      externalID?: string | null,
      secondaryExternalID?: string | null,
      specimenLabelID?: string | null,
      qtMedicalPatientID?: string | null,
      planName?: string | null,
      diabeticType?: DiabeticType | null,
      authorizedTechIDs?: Array< string > | null,
      isCoordinator: boolean,
      // Phone & email to use in place of the coordinators for messaging. Used primarily for patients whose appointments are booked for them by a partner's customer service representatives.
      phone?: string | null,
      email?: string | null,
      secondaryPhone?: string | null,
      birthdate?: string | null,
      sex?: Sex | null,
      gender?: Gender | null,
      ethnicity?: Ethnicity | null,
      races?: Array< Race > | null,
      passportNumber?: string | null,
      insuranceCardUploadFrontS3ObjectID?: string | null,
      insuranceCardUploadBackS3ObjectID?: string | null,
      medicareCardUploadFrontS3ObjectID?: string | null,
      medicareCardUploadBackS3ObjectID?: string | null,
      watchlistedAppointmentID?: string | null,
      responsiblePractitionerID?: string | null,
      commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
      commentsFromCaregiverAboutResponsiblePractitioner?: string | null,
      suggestedAppointmentLocationAdditionalInformation?: string | null,
      preferredLanguage?: string | null,
      primaryLanguageBCP47?: string | null,
      secondaryLanguagesBCP47?: Array< string > | null,
      // A unique numerical patient reference number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
      patientNumber?: string | null,
      // latest validated gap list data referencing this patient.
      // - latestGapListUploadID: the upload ID of the latest ingestion for tracking purposes
      // - latestGapListRowJSON: the entire row
      // - latestGapListPassthroughJSON: a subset of the row for passing back to the partner with patient data in reports
      latestGapListUploadID?: string | null,
      latestGapListRowJSON?: string | null,
      latestGapListPassthroughJSON?: string | null,
      // A reference to a set of branding material provided for outreach.
      brandCode?: string | null,
      population?: PatientPopulation | null,
      elationID?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    system: ContactPointSystem,
    value: string,
    status: ContactPointStatus,
    statusLastUpdatedAt?: string | null,
    sources?:  Array< {
      __typename: "PatientContactPointSource",
      source: ContactPointSource,
      // Specifies a preferred order in which to use a set of contacts. ContactPoints with lower rank values are more preferred than those with higher rank values.
      rank?: number | null,
      system: ContactPointSystem,
      // The date and time the source reports the contact point as last being utilized. For example in the LexisNexis report they include the last time they saw the contact point in their data. This differs from the last time they reported it to _us_.
      reportedAsLastSeenAt?: string | null,
      // The date and time the source first reported this contact point associated to the patient
      firstReportedAt?: string | null,
      // The date and time the source last reported this contact point associated to the patient
      lastReportedAt?: string | null,
      metadataJSON?: string | null,
    } > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePatientContactPointMutationVariables = {
  input: DeletePatientContactPointInput,
  condition?: ModelPatientContactPointConditionInput | null,
};

export type DeletePatientContactPointMutation = {
  deletePatientContactPoint?:  {
    __typename: "PatientContactPoint",
    id: string,
    patientID: string,
    patient:  {
      __typename: "Patient",
      id: string,
      csrPartnerOrganization?: PartnerOrganization | null,
      partnerOrganization?: PartnerOrganization | null,
      coordinatorID: string,
      medicareCardID?: string | null,
      imageMoverUserID?: string | null,
      externalID?: string | null,
      secondaryExternalID?: string | null,
      specimenLabelID?: string | null,
      qtMedicalPatientID?: string | null,
      planName?: string | null,
      diabeticType?: DiabeticType | null,
      authorizedTechIDs?: Array< string > | null,
      isCoordinator: boolean,
      // Phone & email to use in place of the coordinators for messaging. Used primarily for patients whose appointments are booked for them by a partner's customer service representatives.
      phone?: string | null,
      email?: string | null,
      secondaryPhone?: string | null,
      birthdate?: string | null,
      sex?: Sex | null,
      gender?: Gender | null,
      ethnicity?: Ethnicity | null,
      races?: Array< Race > | null,
      passportNumber?: string | null,
      insuranceCardUploadFrontS3ObjectID?: string | null,
      insuranceCardUploadBackS3ObjectID?: string | null,
      medicareCardUploadFrontS3ObjectID?: string | null,
      medicareCardUploadBackS3ObjectID?: string | null,
      watchlistedAppointmentID?: string | null,
      responsiblePractitionerID?: string | null,
      commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
      commentsFromCaregiverAboutResponsiblePractitioner?: string | null,
      suggestedAppointmentLocationAdditionalInformation?: string | null,
      preferredLanguage?: string | null,
      primaryLanguageBCP47?: string | null,
      secondaryLanguagesBCP47?: Array< string > | null,
      // A unique numerical patient reference number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
      patientNumber?: string | null,
      // latest validated gap list data referencing this patient.
      // - latestGapListUploadID: the upload ID of the latest ingestion for tracking purposes
      // - latestGapListRowJSON: the entire row
      // - latestGapListPassthroughJSON: a subset of the row for passing back to the partner with patient data in reports
      latestGapListUploadID?: string | null,
      latestGapListRowJSON?: string | null,
      latestGapListPassthroughJSON?: string | null,
      // A reference to a set of branding material provided for outreach.
      brandCode?: string | null,
      population?: PatientPopulation | null,
      elationID?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    system: ContactPointSystem,
    value: string,
    status: ContactPointStatus,
    statusLastUpdatedAt?: string | null,
    sources?:  Array< {
      __typename: "PatientContactPointSource",
      source: ContactPointSource,
      // Specifies a preferred order in which to use a set of contacts. ContactPoints with lower rank values are more preferred than those with higher rank values.
      rank?: number | null,
      system: ContactPointSystem,
      // The date and time the source reports the contact point as last being utilized. For example in the LexisNexis report they include the last time they saw the contact point in their data. This differs from the last time they reported it to _us_.
      reportedAsLastSeenAt?: string | null,
      // The date and time the source first reported this contact point associated to the patient
      firstReportedAt?: string | null,
      // The date and time the source last reported this contact point associated to the patient
      lastReportedAt?: string | null,
      metadataJSON?: string | null,
    } > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateReferralMutationVariables = {
  input: CreateReferralInput,
  condition?: ModelReferralConditionInput | null,
};

export type CreateReferralMutation = {
  createReferral?:  {
    __typename: "Referral",
    id: string,
    appointments?:  {
      __typename: "ModelAppointmentConnection",
      nextToken?: string | null,
    } | null,
    patientID: string,
    patient:  {
      __typename: "Patient",
      id: string,
      csrPartnerOrganization?: PartnerOrganization | null,
      partnerOrganization?: PartnerOrganization | null,
      coordinatorID: string,
      medicareCardID?: string | null,
      imageMoverUserID?: string | null,
      externalID?: string | null,
      secondaryExternalID?: string | null,
      specimenLabelID?: string | null,
      qtMedicalPatientID?: string | null,
      planName?: string | null,
      diabeticType?: DiabeticType | null,
      authorizedTechIDs?: Array< string > | null,
      isCoordinator: boolean,
      // Phone & email to use in place of the coordinators for messaging. Used primarily for patients whose appointments are booked for them by a partner's customer service representatives.
      phone?: string | null,
      email?: string | null,
      secondaryPhone?: string | null,
      birthdate?: string | null,
      sex?: Sex | null,
      gender?: Gender | null,
      ethnicity?: Ethnicity | null,
      races?: Array< Race > | null,
      passportNumber?: string | null,
      insuranceCardUploadFrontS3ObjectID?: string | null,
      insuranceCardUploadBackS3ObjectID?: string | null,
      medicareCardUploadFrontS3ObjectID?: string | null,
      medicareCardUploadBackS3ObjectID?: string | null,
      watchlistedAppointmentID?: string | null,
      responsiblePractitionerID?: string | null,
      commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
      commentsFromCaregiverAboutResponsiblePractitioner?: string | null,
      suggestedAppointmentLocationAdditionalInformation?: string | null,
      preferredLanguage?: string | null,
      primaryLanguageBCP47?: string | null,
      secondaryLanguagesBCP47?: Array< string > | null,
      // A unique numerical patient reference number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
      patientNumber?: string | null,
      // latest validated gap list data referencing this patient.
      // - latestGapListUploadID: the upload ID of the latest ingestion for tracking purposes
      // - latestGapListRowJSON: the entire row
      // - latestGapListPassthroughJSON: a subset of the row for passing back to the partner with patient data in reports
      latestGapListUploadID?: string | null,
      latestGapListRowJSON?: string | null,
      latestGapListPassthroughJSON?: string | null,
      // A reference to a set of branding material provided for outreach.
      brandCode?: string | null,
      population?: PatientPopulation | null,
      elationID?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    partnerOrganization: PartnerOrganization,
    requisitions?:  Array< {
      __typename: "Requisition",
      id: string,
      labOrganization?: LabOrganization | null,
      // A unique numerical patient requisition number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
      requisitionNumber?: string | null,
    } > | null,
    requisitionUploads?:  Array< {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } > | null,
    requisitionUploadS3ObjectIDs?: Array< string > | null,
    serviceTypes: Array< ServiceType >,
    diagnosisCodes?:  Array< {
      __typename: "Coding",
      system: string,
      code: string,
      displayName?: string | null,
    } > | null,
    isBooked?: boolean | null,
    isExpired?: boolean | null,
    outreachOutcomesPerServiceRequest?:  Array< {
      __typename: "ServiceRequestOutreachOutcome",
      serviceRequestExternalID: string,
      productType?: ProductType | null,
      serviceTypes?: Array< ServiceType > | null,
      labOrganization: LabOrganization,
      outcome: string,
      notes?: string | null,
    } > | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateReferralMutationVariables = {
  input: UpdateReferralInput,
  condition?: ModelReferralConditionInput | null,
};

export type UpdateReferralMutation = {
  updateReferral?:  {
    __typename: "Referral",
    id: string,
    appointments?:  {
      __typename: "ModelAppointmentConnection",
      nextToken?: string | null,
    } | null,
    patientID: string,
    patient:  {
      __typename: "Patient",
      id: string,
      csrPartnerOrganization?: PartnerOrganization | null,
      partnerOrganization?: PartnerOrganization | null,
      coordinatorID: string,
      medicareCardID?: string | null,
      imageMoverUserID?: string | null,
      externalID?: string | null,
      secondaryExternalID?: string | null,
      specimenLabelID?: string | null,
      qtMedicalPatientID?: string | null,
      planName?: string | null,
      diabeticType?: DiabeticType | null,
      authorizedTechIDs?: Array< string > | null,
      isCoordinator: boolean,
      // Phone & email to use in place of the coordinators for messaging. Used primarily for patients whose appointments are booked for them by a partner's customer service representatives.
      phone?: string | null,
      email?: string | null,
      secondaryPhone?: string | null,
      birthdate?: string | null,
      sex?: Sex | null,
      gender?: Gender | null,
      ethnicity?: Ethnicity | null,
      races?: Array< Race > | null,
      passportNumber?: string | null,
      insuranceCardUploadFrontS3ObjectID?: string | null,
      insuranceCardUploadBackS3ObjectID?: string | null,
      medicareCardUploadFrontS3ObjectID?: string | null,
      medicareCardUploadBackS3ObjectID?: string | null,
      watchlistedAppointmentID?: string | null,
      responsiblePractitionerID?: string | null,
      commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
      commentsFromCaregiverAboutResponsiblePractitioner?: string | null,
      suggestedAppointmentLocationAdditionalInformation?: string | null,
      preferredLanguage?: string | null,
      primaryLanguageBCP47?: string | null,
      secondaryLanguagesBCP47?: Array< string > | null,
      // A unique numerical patient reference number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
      patientNumber?: string | null,
      // latest validated gap list data referencing this patient.
      // - latestGapListUploadID: the upload ID of the latest ingestion for tracking purposes
      // - latestGapListRowJSON: the entire row
      // - latestGapListPassthroughJSON: a subset of the row for passing back to the partner with patient data in reports
      latestGapListUploadID?: string | null,
      latestGapListRowJSON?: string | null,
      latestGapListPassthroughJSON?: string | null,
      // A reference to a set of branding material provided for outreach.
      brandCode?: string | null,
      population?: PatientPopulation | null,
      elationID?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    partnerOrganization: PartnerOrganization,
    requisitions?:  Array< {
      __typename: "Requisition",
      id: string,
      labOrganization?: LabOrganization | null,
      // A unique numerical patient requisition number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
      requisitionNumber?: string | null,
    } > | null,
    requisitionUploads?:  Array< {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } > | null,
    requisitionUploadS3ObjectIDs?: Array< string > | null,
    serviceTypes: Array< ServiceType >,
    diagnosisCodes?:  Array< {
      __typename: "Coding",
      system: string,
      code: string,
      displayName?: string | null,
    } > | null,
    isBooked?: boolean | null,
    isExpired?: boolean | null,
    outreachOutcomesPerServiceRequest?:  Array< {
      __typename: "ServiceRequestOutreachOutcome",
      serviceRequestExternalID: string,
      productType?: ProductType | null,
      serviceTypes?: Array< ServiceType > | null,
      labOrganization: LabOrganization,
      outcome: string,
      notes?: string | null,
    } > | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteReferralMutationVariables = {
  input: DeleteReferralInput,
  condition?: ModelReferralConditionInput | null,
};

export type DeleteReferralMutation = {
  deleteReferral?:  {
    __typename: "Referral",
    id: string,
    appointments?:  {
      __typename: "ModelAppointmentConnection",
      nextToken?: string | null,
    } | null,
    patientID: string,
    patient:  {
      __typename: "Patient",
      id: string,
      csrPartnerOrganization?: PartnerOrganization | null,
      partnerOrganization?: PartnerOrganization | null,
      coordinatorID: string,
      medicareCardID?: string | null,
      imageMoverUserID?: string | null,
      externalID?: string | null,
      secondaryExternalID?: string | null,
      specimenLabelID?: string | null,
      qtMedicalPatientID?: string | null,
      planName?: string | null,
      diabeticType?: DiabeticType | null,
      authorizedTechIDs?: Array< string > | null,
      isCoordinator: boolean,
      // Phone & email to use in place of the coordinators for messaging. Used primarily for patients whose appointments are booked for them by a partner's customer service representatives.
      phone?: string | null,
      email?: string | null,
      secondaryPhone?: string | null,
      birthdate?: string | null,
      sex?: Sex | null,
      gender?: Gender | null,
      ethnicity?: Ethnicity | null,
      races?: Array< Race > | null,
      passportNumber?: string | null,
      insuranceCardUploadFrontS3ObjectID?: string | null,
      insuranceCardUploadBackS3ObjectID?: string | null,
      medicareCardUploadFrontS3ObjectID?: string | null,
      medicareCardUploadBackS3ObjectID?: string | null,
      watchlistedAppointmentID?: string | null,
      responsiblePractitionerID?: string | null,
      commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
      commentsFromCaregiverAboutResponsiblePractitioner?: string | null,
      suggestedAppointmentLocationAdditionalInformation?: string | null,
      preferredLanguage?: string | null,
      primaryLanguageBCP47?: string | null,
      secondaryLanguagesBCP47?: Array< string > | null,
      // A unique numerical patient reference number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
      patientNumber?: string | null,
      // latest validated gap list data referencing this patient.
      // - latestGapListUploadID: the upload ID of the latest ingestion for tracking purposes
      // - latestGapListRowJSON: the entire row
      // - latestGapListPassthroughJSON: a subset of the row for passing back to the partner with patient data in reports
      latestGapListUploadID?: string | null,
      latestGapListRowJSON?: string | null,
      latestGapListPassthroughJSON?: string | null,
      // A reference to a set of branding material provided for outreach.
      brandCode?: string | null,
      population?: PatientPopulation | null,
      elationID?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    partnerOrganization: PartnerOrganization,
    requisitions?:  Array< {
      __typename: "Requisition",
      id: string,
      labOrganization?: LabOrganization | null,
      // A unique numerical patient requisition number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
      requisitionNumber?: string | null,
    } > | null,
    requisitionUploads?:  Array< {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } > | null,
    requisitionUploadS3ObjectIDs?: Array< string > | null,
    serviceTypes: Array< ServiceType >,
    diagnosisCodes?:  Array< {
      __typename: "Coding",
      system: string,
      code: string,
      displayName?: string | null,
    } > | null,
    isBooked?: boolean | null,
    isExpired?: boolean | null,
    outreachOutcomesPerServiceRequest?:  Array< {
      __typename: "ServiceRequestOutreachOutcome",
      serviceRequestExternalID: string,
      productType?: ProductType | null,
      serviceTypes?: Array< ServiceType > | null,
      labOrganization: LabOrganization,
      outcome: string,
      notes?: string | null,
    } > | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePatientCampaignStateMutationVariables = {
  input: CreatePatientCampaignStateInput,
  condition?: ModelPatientCampaignStateConditionInput | null,
};

export type CreatePatientCampaignStateMutation = {
  createPatientCampaignState?:  {
    __typename: "PatientCampaignState",
    id: string,
    patientID: string,
    patient:  {
      __typename: "Patient",
      id: string,
      csrPartnerOrganization?: PartnerOrganization | null,
      partnerOrganization?: PartnerOrganization | null,
      coordinatorID: string,
      medicareCardID?: string | null,
      imageMoverUserID?: string | null,
      externalID?: string | null,
      secondaryExternalID?: string | null,
      specimenLabelID?: string | null,
      qtMedicalPatientID?: string | null,
      planName?: string | null,
      diabeticType?: DiabeticType | null,
      authorizedTechIDs?: Array< string > | null,
      isCoordinator: boolean,
      // Phone & email to use in place of the coordinators for messaging. Used primarily for patients whose appointments are booked for them by a partner's customer service representatives.
      phone?: string | null,
      email?: string | null,
      secondaryPhone?: string | null,
      birthdate?: string | null,
      sex?: Sex | null,
      gender?: Gender | null,
      ethnicity?: Ethnicity | null,
      races?: Array< Race > | null,
      passportNumber?: string | null,
      insuranceCardUploadFrontS3ObjectID?: string | null,
      insuranceCardUploadBackS3ObjectID?: string | null,
      medicareCardUploadFrontS3ObjectID?: string | null,
      medicareCardUploadBackS3ObjectID?: string | null,
      watchlistedAppointmentID?: string | null,
      responsiblePractitionerID?: string | null,
      commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
      commentsFromCaregiverAboutResponsiblePractitioner?: string | null,
      suggestedAppointmentLocationAdditionalInformation?: string | null,
      preferredLanguage?: string | null,
      primaryLanguageBCP47?: string | null,
      secondaryLanguagesBCP47?: Array< string > | null,
      // A unique numerical patient reference number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
      patientNumber?: string | null,
      // latest validated gap list data referencing this patient.
      // - latestGapListUploadID: the upload ID of the latest ingestion for tracking purposes
      // - latestGapListRowJSON: the entire row
      // - latestGapListPassthroughJSON: a subset of the row for passing back to the partner with patient data in reports
      latestGapListUploadID?: string | null,
      latestGapListRowJSON?: string | null,
      latestGapListPassthroughJSON?: string | null,
      // A reference to a set of branding material provided for outreach.
      brandCode?: string | null,
      population?: PatientPopulation | null,
      elationID?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    name: PatientCampaignStateName,
    campaignName: CampaignName,
    eligibilityStart: string,
    eligibilityEnd: string,
    cohort?: number | null,
    contextJSON: string,
    enteredAt: number,
    partnerOrganization: PartnerOrganization,
    previousStateName?: PatientCampaignStateName | null,
    reasonForTransitionToTerminalState?: ReasonForTransitionToTerminalState | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePatientCampaignStateMutationVariables = {
  input: UpdatePatientCampaignStateInput,
  condition?: ModelPatientCampaignStateConditionInput | null,
};

export type UpdatePatientCampaignStateMutation = {
  updatePatientCampaignState?:  {
    __typename: "PatientCampaignState",
    id: string,
    patientID: string,
    patient:  {
      __typename: "Patient",
      id: string,
      csrPartnerOrganization?: PartnerOrganization | null,
      partnerOrganization?: PartnerOrganization | null,
      coordinatorID: string,
      medicareCardID?: string | null,
      imageMoverUserID?: string | null,
      externalID?: string | null,
      secondaryExternalID?: string | null,
      specimenLabelID?: string | null,
      qtMedicalPatientID?: string | null,
      planName?: string | null,
      diabeticType?: DiabeticType | null,
      authorizedTechIDs?: Array< string > | null,
      isCoordinator: boolean,
      // Phone & email to use in place of the coordinators for messaging. Used primarily for patients whose appointments are booked for them by a partner's customer service representatives.
      phone?: string | null,
      email?: string | null,
      secondaryPhone?: string | null,
      birthdate?: string | null,
      sex?: Sex | null,
      gender?: Gender | null,
      ethnicity?: Ethnicity | null,
      races?: Array< Race > | null,
      passportNumber?: string | null,
      insuranceCardUploadFrontS3ObjectID?: string | null,
      insuranceCardUploadBackS3ObjectID?: string | null,
      medicareCardUploadFrontS3ObjectID?: string | null,
      medicareCardUploadBackS3ObjectID?: string | null,
      watchlistedAppointmentID?: string | null,
      responsiblePractitionerID?: string | null,
      commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
      commentsFromCaregiverAboutResponsiblePractitioner?: string | null,
      suggestedAppointmentLocationAdditionalInformation?: string | null,
      preferredLanguage?: string | null,
      primaryLanguageBCP47?: string | null,
      secondaryLanguagesBCP47?: Array< string > | null,
      // A unique numerical patient reference number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
      patientNumber?: string | null,
      // latest validated gap list data referencing this patient.
      // - latestGapListUploadID: the upload ID of the latest ingestion for tracking purposes
      // - latestGapListRowJSON: the entire row
      // - latestGapListPassthroughJSON: a subset of the row for passing back to the partner with patient data in reports
      latestGapListUploadID?: string | null,
      latestGapListRowJSON?: string | null,
      latestGapListPassthroughJSON?: string | null,
      // A reference to a set of branding material provided for outreach.
      brandCode?: string | null,
      population?: PatientPopulation | null,
      elationID?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    name: PatientCampaignStateName,
    campaignName: CampaignName,
    eligibilityStart: string,
    eligibilityEnd: string,
    cohort?: number | null,
    contextJSON: string,
    enteredAt: number,
    partnerOrganization: PartnerOrganization,
    previousStateName?: PatientCampaignStateName | null,
    reasonForTransitionToTerminalState?: ReasonForTransitionToTerminalState | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePatientCampaignStateMutationVariables = {
  input: DeletePatientCampaignStateInput,
  condition?: ModelPatientCampaignStateConditionInput | null,
};

export type DeletePatientCampaignStateMutation = {
  deletePatientCampaignState?:  {
    __typename: "PatientCampaignState",
    id: string,
    patientID: string,
    patient:  {
      __typename: "Patient",
      id: string,
      csrPartnerOrganization?: PartnerOrganization | null,
      partnerOrganization?: PartnerOrganization | null,
      coordinatorID: string,
      medicareCardID?: string | null,
      imageMoverUserID?: string | null,
      externalID?: string | null,
      secondaryExternalID?: string | null,
      specimenLabelID?: string | null,
      qtMedicalPatientID?: string | null,
      planName?: string | null,
      diabeticType?: DiabeticType | null,
      authorizedTechIDs?: Array< string > | null,
      isCoordinator: boolean,
      // Phone & email to use in place of the coordinators for messaging. Used primarily for patients whose appointments are booked for them by a partner's customer service representatives.
      phone?: string | null,
      email?: string | null,
      secondaryPhone?: string | null,
      birthdate?: string | null,
      sex?: Sex | null,
      gender?: Gender | null,
      ethnicity?: Ethnicity | null,
      races?: Array< Race > | null,
      passportNumber?: string | null,
      insuranceCardUploadFrontS3ObjectID?: string | null,
      insuranceCardUploadBackS3ObjectID?: string | null,
      medicareCardUploadFrontS3ObjectID?: string | null,
      medicareCardUploadBackS3ObjectID?: string | null,
      watchlistedAppointmentID?: string | null,
      responsiblePractitionerID?: string | null,
      commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
      commentsFromCaregiverAboutResponsiblePractitioner?: string | null,
      suggestedAppointmentLocationAdditionalInformation?: string | null,
      preferredLanguage?: string | null,
      primaryLanguageBCP47?: string | null,
      secondaryLanguagesBCP47?: Array< string > | null,
      // A unique numerical patient reference number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
      patientNumber?: string | null,
      // latest validated gap list data referencing this patient.
      // - latestGapListUploadID: the upload ID of the latest ingestion for tracking purposes
      // - latestGapListRowJSON: the entire row
      // - latestGapListPassthroughJSON: a subset of the row for passing back to the partner with patient data in reports
      latestGapListUploadID?: string | null,
      latestGapListRowJSON?: string | null,
      latestGapListPassthroughJSON?: string | null,
      // A reference to a set of branding material provided for outreach.
      brandCode?: string | null,
      population?: PatientPopulation | null,
      elationID?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    name: PatientCampaignStateName,
    campaignName: CampaignName,
    eligibilityStart: string,
    eligibilityEnd: string,
    cohort?: number | null,
    contextJSON: string,
    enteredAt: number,
    partnerOrganization: PartnerOrganization,
    previousStateName?: PatientCampaignStateName | null,
    reasonForTransitionToTerminalState?: ReasonForTransitionToTerminalState | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateProjectedEngagementActivityScheduleMutationVariables = {
  input: CreateProjectedEngagementActivityScheduleInput,
  condition?: ModelProjectedEngagementActivityScheduleConditionInput | null,
};

export type CreateProjectedEngagementActivityScheduleMutation = {
  createProjectedEngagementActivitySchedule?:  {
    __typename: "ProjectedEngagementActivitySchedule",
    id: string,
    // A friendly(-ish) name for the schedule.
    name: string,
    // The status of the schedule. This largely serves as a partition key so we can query for schedules sorted by accepted at.
    status: ProjectedEngagementActivityScheduleStatus,
    acceptedAt?: string | null,
    totalBatches?: number | null,
    processedBatches?: number | null,
    parametersJSON?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateProjectedEngagementActivityScheduleMutationVariables = {
  input: UpdateProjectedEngagementActivityScheduleInput,
  condition?: ModelProjectedEngagementActivityScheduleConditionInput | null,
};

export type UpdateProjectedEngagementActivityScheduleMutation = {
  updateProjectedEngagementActivitySchedule?:  {
    __typename: "ProjectedEngagementActivitySchedule",
    id: string,
    // A friendly(-ish) name for the schedule.
    name: string,
    // The status of the schedule. This largely serves as a partition key so we can query for schedules sorted by accepted at.
    status: ProjectedEngagementActivityScheduleStatus,
    acceptedAt?: string | null,
    totalBatches?: number | null,
    processedBatches?: number | null,
    parametersJSON?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteProjectedEngagementActivityScheduleMutationVariables = {
  input: DeleteProjectedEngagementActivityScheduleInput,
  condition?: ModelProjectedEngagementActivityScheduleConditionInput | null,
};

export type DeleteProjectedEngagementActivityScheduleMutation = {
  deleteProjectedEngagementActivitySchedule?:  {
    __typename: "ProjectedEngagementActivitySchedule",
    id: string,
    // A friendly(-ish) name for the schedule.
    name: string,
    // The status of the schedule. This largely serves as a partition key so we can query for schedules sorted by accepted at.
    status: ProjectedEngagementActivityScheduleStatus,
    acceptedAt?: string | null,
    totalBatches?: number | null,
    processedBatches?: number | null,
    parametersJSON?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUnlinkableReportMutationVariables = {
  input: CreateUnlinkableReportInput,
  condition?: ModelUnlinkableReportConditionInput | null,
};

export type CreateUnlinkableReportMutation = {
  createUnlinkableReport?:  {
    __typename: "UnlinkableReport",
    id: string,
    externalID: string,
    documents:  Array< {
      __typename: "Document",
      type?: DocumentType | null,
      url?: string | null,
      format?: DocumentFormat | null,
      s3ObjectID?: string | null,
      uploadedAt?: number | null,
      // stringified JSON object as a set of additional data we want to store about documents (requisition number, lab report status, etc.)
      metadataJSON?: string | null,
    } >,
    zendeskTicketID?: number | null,
    unlinkedReason: UnlinkedReason,
    hasBeenResolved?: boolean | null,
    patientName?:  {
      __typename: "HumanName",
      use: NameUse,
      family?: string | null,
      given?: Array< string > | null,
      prefix?: HumanNamePrefix | null,
      suffix?: string | null,
    } | null,
    patientNumber?: string | null,
    requisitionNumber?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUnlinkableReportMutationVariables = {
  input: UpdateUnlinkableReportInput,
  condition?: ModelUnlinkableReportConditionInput | null,
};

export type UpdateUnlinkableReportMutation = {
  updateUnlinkableReport?:  {
    __typename: "UnlinkableReport",
    id: string,
    externalID: string,
    documents:  Array< {
      __typename: "Document",
      type?: DocumentType | null,
      url?: string | null,
      format?: DocumentFormat | null,
      s3ObjectID?: string | null,
      uploadedAt?: number | null,
      // stringified JSON object as a set of additional data we want to store about documents (requisition number, lab report status, etc.)
      metadataJSON?: string | null,
    } >,
    zendeskTicketID?: number | null,
    unlinkedReason: UnlinkedReason,
    hasBeenResolved?: boolean | null,
    patientName?:  {
      __typename: "HumanName",
      use: NameUse,
      family?: string | null,
      given?: Array< string > | null,
      prefix?: HumanNamePrefix | null,
      suffix?: string | null,
    } | null,
    patientNumber?: string | null,
    requisitionNumber?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUnlinkableReportMutationVariables = {
  input: DeleteUnlinkableReportInput,
  condition?: ModelUnlinkableReportConditionInput | null,
};

export type DeleteUnlinkableReportMutation = {
  deleteUnlinkableReport?:  {
    __typename: "UnlinkableReport",
    id: string,
    externalID: string,
    documents:  Array< {
      __typename: "Document",
      type?: DocumentType | null,
      url?: string | null,
      format?: DocumentFormat | null,
      s3ObjectID?: string | null,
      uploadedAt?: number | null,
      // stringified JSON object as a set of additional data we want to store about documents (requisition number, lab report status, etc.)
      metadataJSON?: string | null,
    } >,
    zendeskTicketID?: number | null,
    unlinkedReason: UnlinkedReason,
    hasBeenResolved?: boolean | null,
    patientName?:  {
      __typename: "HumanName",
      use: NameUse,
      family?: string | null,
      given?: Array< string > | null,
      prefix?: HumanNamePrefix | null,
      suffix?: string | null,
    } | null,
    patientNumber?: string | null,
    requisitionNumber?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDropOffMutationVariables = {
  input: CreateDropOffInput,
  condition?: ModelDropOffConditionInput | null,
};

export type CreateDropOffMutation = {
  createDropOff?:  {
    __typename: "DropOff",
    id: string,
    organization: DropOffOrganization,
    name: string,
    phone?: string | null,
    email?: string | null,
    location:  {
      __typename: "Location",
      satellitePhotoURL?: string | null,
      streetViewPhotoURL?: string | null,
    },
    // Timezone generated based on the lat/long location of this dropoff
    timezone?: Timezone | null,
    availabilityRules:  Array< {
      __typename: "TimeRangeRule",
      // The unique ID for the time range.
      id: string,
      // Whether the time range represents an addition or subtraction when considering multiple ranges.
      inclusion: TimeRangeRuleInclusion,
      // The time of day each recurrence starts (inclusive).
      start: string,
      // The time of day each recurrence ends (exclusive). Use '24:00:00' for the end of the day.
      end: string,
      // The first date that the recurrence is valid (inclusive).
      recurrenceStart: string,
      // The last date that the recurrence is valid (inclusive). If not set, the range extends indefinitely.
      recurrenceEnd?: string | null,
      // The number of periods between each recurrence. For example, with a multiple of N and period of DAY, the time range will recur every N days.
      recurrenceMultiple: number,
      // The unit of recurrence. For example, with a period of WEEK and multiple of 2, the time range will recur every other week.
      recurrencePeriod: TimeRangeRulePeriod,
      // An optional reason for the time range rule, used with time-off unavailability rules (lunch breaks, PTO, etc.)
      unavailabilityReason?: TimeRangeRuleUnavailabilityReason | null,
      // Additional notes about the time range rule.
      notes?: string | null,
      // The date and time that the rule was last updated.
      updatedAt?: string | null,
      // The user who last updated the rule.
      updatedBy?: string | null,
    } >,
    hasParking?: boolean | null,
    hasDropBox?: boolean | null,
    commentsFromScheduler?: string | null,
    googleMapsPlaceID?: string | null,
    metroName: string,
    isActive: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDropOffMutationVariables = {
  input: UpdateDropOffInput,
  condition?: ModelDropOffConditionInput | null,
};

export type UpdateDropOffMutation = {
  updateDropOff?:  {
    __typename: "DropOff",
    id: string,
    organization: DropOffOrganization,
    name: string,
    phone?: string | null,
    email?: string | null,
    location:  {
      __typename: "Location",
      satellitePhotoURL?: string | null,
      streetViewPhotoURL?: string | null,
    },
    // Timezone generated based on the lat/long location of this dropoff
    timezone?: Timezone | null,
    availabilityRules:  Array< {
      __typename: "TimeRangeRule",
      // The unique ID for the time range.
      id: string,
      // Whether the time range represents an addition or subtraction when considering multiple ranges.
      inclusion: TimeRangeRuleInclusion,
      // The time of day each recurrence starts (inclusive).
      start: string,
      // The time of day each recurrence ends (exclusive). Use '24:00:00' for the end of the day.
      end: string,
      // The first date that the recurrence is valid (inclusive).
      recurrenceStart: string,
      // The last date that the recurrence is valid (inclusive). If not set, the range extends indefinitely.
      recurrenceEnd?: string | null,
      // The number of periods between each recurrence. For example, with a multiple of N and period of DAY, the time range will recur every N days.
      recurrenceMultiple: number,
      // The unit of recurrence. For example, with a period of WEEK and multiple of 2, the time range will recur every other week.
      recurrencePeriod: TimeRangeRulePeriod,
      // An optional reason for the time range rule, used with time-off unavailability rules (lunch breaks, PTO, etc.)
      unavailabilityReason?: TimeRangeRuleUnavailabilityReason | null,
      // Additional notes about the time range rule.
      notes?: string | null,
      // The date and time that the rule was last updated.
      updatedAt?: string | null,
      // The user who last updated the rule.
      updatedBy?: string | null,
    } >,
    hasParking?: boolean | null,
    hasDropBox?: boolean | null,
    commentsFromScheduler?: string | null,
    googleMapsPlaceID?: string | null,
    metroName: string,
    isActive: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDropOffMutationVariables = {
  input: DeleteDropOffInput,
  condition?: ModelDropOffConditionInput | null,
};

export type DeleteDropOffMutation = {
  deleteDropOff?:  {
    __typename: "DropOff",
    id: string,
    organization: DropOffOrganization,
    name: string,
    phone?: string | null,
    email?: string | null,
    location:  {
      __typename: "Location",
      satellitePhotoURL?: string | null,
      streetViewPhotoURL?: string | null,
    },
    // Timezone generated based on the lat/long location of this dropoff
    timezone?: Timezone | null,
    availabilityRules:  Array< {
      __typename: "TimeRangeRule",
      // The unique ID for the time range.
      id: string,
      // Whether the time range represents an addition or subtraction when considering multiple ranges.
      inclusion: TimeRangeRuleInclusion,
      // The time of day each recurrence starts (inclusive).
      start: string,
      // The time of day each recurrence ends (exclusive). Use '24:00:00' for the end of the day.
      end: string,
      // The first date that the recurrence is valid (inclusive).
      recurrenceStart: string,
      // The last date that the recurrence is valid (inclusive). If not set, the range extends indefinitely.
      recurrenceEnd?: string | null,
      // The number of periods between each recurrence. For example, with a multiple of N and period of DAY, the time range will recur every N days.
      recurrenceMultiple: number,
      // The unit of recurrence. For example, with a period of WEEK and multiple of 2, the time range will recur every other week.
      recurrencePeriod: TimeRangeRulePeriod,
      // An optional reason for the time range rule, used with time-off unavailability rules (lunch breaks, PTO, etc.)
      unavailabilityReason?: TimeRangeRuleUnavailabilityReason | null,
      // Additional notes about the time range rule.
      notes?: string | null,
      // The date and time that the rule was last updated.
      updatedAt?: string | null,
      // The user who last updated the rule.
      updatedBy?: string | null,
    } >,
    hasParking?: boolean | null,
    hasDropBox?: boolean | null,
    commentsFromScheduler?: string | null,
    googleMapsPlaceID?: string | null,
    metroName: string,
    isActive: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateServiceRequestTypeMutationVariables = {
  input: CreateServiceRequestTypeInput,
  condition?: ModelServiceRequestTypeConditionInput | null,
};

export type CreateServiceRequestTypeMutation = {
  createServiceRequestType?:  {
    __typename: "ServiceRequestType",
    id: string,
    cptCodes: Array< string | null >,
    externalID: string,
    displayName: string,
    // Alternate names for service requests, to be used in service request lookup
    synonyms?: Array< string | null > | null,
    labOrganization: LabOrganization,
    container?: string | null,
    serviceRequestTypeContainerID?: string | null,
    serviceRequestTypeContainer?:  {
      __typename: "ServiceRequestTypeContainer",
      id: string,
      containerType: string,
      alternativeContainerTypes?: Array< string > | null,
      substituteContainerTypesForCalculations?: Array< string > | null,
      containerCategory: string,
      capacity: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    specimen?: string | null,
    preferredSpecimen?: string | null,
    preferredVolumeDescription?: string | null,
    preferredVolumeMilliliters?: number | null,
    minimumVolume?: string | null,
    minimumVolumeAmount?: number | null,
    alternateSpecimen?: string | null,
    transportInstructions?: string | null,
    collectionInstructions?: string | null,
    collectionInstructionsVideoURL?: string | null,
    rejectionCriteria?: string | null,
    specimenStability?: string | null,
    patientPreparation?: string | null,
    resultAvailability?: string | null,
    serviceTypes?: Array< ServiceType > | null,
    containerSpecimenVolumes?:  Array< {
      __typename: "SpecimenContainerSpecimenVolumes",
      // The ID of the container assigned to a serviceRequest, only applicable for non-combinable serviceRequests
      containerOverrideID?: string | null,
      // The type of tube e.g., SST, LAV EDTA, Urine Cup
      containerCategory?: SpecimenContainerCategory | null,
      // The minimum volume needed for a test
      minimumMilliliters?: number | null,
      // The preferred volume for a test in case we do not have minumum volume set above.
      preferredMilliliters?: number | null,
      // Whether it's acceptable to combine the specimens for this test and others into a single container during container calculations.
      // For example, if true, then blood for two tests requiring 2 mL of whole blood each can be drawn into a single 5 mL tube rather than two separate 3 mL tubes.
      isCombinable: boolean,
      // minimum container count needed for the specimen
      minimumContainerCount?: number | null,
      specimenTestTypes?: Array< SpecimenTestType | null > | null,
    } > | null,
    abstentions?:  Array< {
      __typename: "Abstention",
      abstentionType: AbstentionType,
      preferredDurationMinutes: number,
      requiredDurationMinutes?: number | null,
    } > | null,
    customKit?:  {
      __typename: "ServiceRequestCustomKit",
      photoURL: string,
      barcodePhotoURL?: string | null,
      recipient: CustomKitRecipient,
      // requires test requisition form (TRF) completion
      requiresTRFCompletion: boolean,
      // requires benefit correction form (BCF) completion
      requiresBCFCompletion: boolean,
    } | null,
    collectionTimeRules?:  Array< {
      __typename: "TimeRangeRule",
      // The unique ID for the time range.
      id: string,
      // Whether the time range represents an addition or subtraction when considering multiple ranges.
      inclusion: TimeRangeRuleInclusion,
      // The time of day each recurrence starts (inclusive).
      start: string,
      // The time of day each recurrence ends (exclusive). Use '24:00:00' for the end of the day.
      end: string,
      // The first date that the recurrence is valid (inclusive).
      recurrenceStart: string,
      // The last date that the recurrence is valid (inclusive). If not set, the range extends indefinitely.
      recurrenceEnd?: string | null,
      // The number of periods between each recurrence. For example, with a multiple of N and period of DAY, the time range will recur every N days.
      recurrenceMultiple: number,
      // The unit of recurrence. For example, with a period of WEEK and multiple of 2, the time range will recur every other week.
      recurrencePeriod: TimeRangeRulePeriod,
      // An optional reason for the time range rule, used with time-off unavailability rules (lunch breaks, PTO, etc.)
      unavailabilityReason?: TimeRangeRuleUnavailabilityReason | null,
      // Additional notes about the time range rule.
      notes?: string | null,
      // The date and time that the rule was last updated.
      updatedAt?: string | null,
      // The user who last updated the rule.
      updatedBy?: string | null,
    } > | null,
    requiresBinaryPatientSex?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateServiceRequestTypeMutationVariables = {
  input: UpdateServiceRequestTypeInput,
  condition?: ModelServiceRequestTypeConditionInput | null,
};

export type UpdateServiceRequestTypeMutation = {
  updateServiceRequestType?:  {
    __typename: "ServiceRequestType",
    id: string,
    cptCodes: Array< string | null >,
    externalID: string,
    displayName: string,
    // Alternate names for service requests, to be used in service request lookup
    synonyms?: Array< string | null > | null,
    labOrganization: LabOrganization,
    container?: string | null,
    serviceRequestTypeContainerID?: string | null,
    serviceRequestTypeContainer?:  {
      __typename: "ServiceRequestTypeContainer",
      id: string,
      containerType: string,
      alternativeContainerTypes?: Array< string > | null,
      substituteContainerTypesForCalculations?: Array< string > | null,
      containerCategory: string,
      capacity: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    specimen?: string | null,
    preferredSpecimen?: string | null,
    preferredVolumeDescription?: string | null,
    preferredVolumeMilliliters?: number | null,
    minimumVolume?: string | null,
    minimumVolumeAmount?: number | null,
    alternateSpecimen?: string | null,
    transportInstructions?: string | null,
    collectionInstructions?: string | null,
    collectionInstructionsVideoURL?: string | null,
    rejectionCriteria?: string | null,
    specimenStability?: string | null,
    patientPreparation?: string | null,
    resultAvailability?: string | null,
    serviceTypes?: Array< ServiceType > | null,
    containerSpecimenVolumes?:  Array< {
      __typename: "SpecimenContainerSpecimenVolumes",
      // The ID of the container assigned to a serviceRequest, only applicable for non-combinable serviceRequests
      containerOverrideID?: string | null,
      // The type of tube e.g., SST, LAV EDTA, Urine Cup
      containerCategory?: SpecimenContainerCategory | null,
      // The minimum volume needed for a test
      minimumMilliliters?: number | null,
      // The preferred volume for a test in case we do not have minumum volume set above.
      preferredMilliliters?: number | null,
      // Whether it's acceptable to combine the specimens for this test and others into a single container during container calculations.
      // For example, if true, then blood for two tests requiring 2 mL of whole blood each can be drawn into a single 5 mL tube rather than two separate 3 mL tubes.
      isCombinable: boolean,
      // minimum container count needed for the specimen
      minimumContainerCount?: number | null,
      specimenTestTypes?: Array< SpecimenTestType | null > | null,
    } > | null,
    abstentions?:  Array< {
      __typename: "Abstention",
      abstentionType: AbstentionType,
      preferredDurationMinutes: number,
      requiredDurationMinutes?: number | null,
    } > | null,
    customKit?:  {
      __typename: "ServiceRequestCustomKit",
      photoURL: string,
      barcodePhotoURL?: string | null,
      recipient: CustomKitRecipient,
      // requires test requisition form (TRF) completion
      requiresTRFCompletion: boolean,
      // requires benefit correction form (BCF) completion
      requiresBCFCompletion: boolean,
    } | null,
    collectionTimeRules?:  Array< {
      __typename: "TimeRangeRule",
      // The unique ID for the time range.
      id: string,
      // Whether the time range represents an addition or subtraction when considering multiple ranges.
      inclusion: TimeRangeRuleInclusion,
      // The time of day each recurrence starts (inclusive).
      start: string,
      // The time of day each recurrence ends (exclusive). Use '24:00:00' for the end of the day.
      end: string,
      // The first date that the recurrence is valid (inclusive).
      recurrenceStart: string,
      // The last date that the recurrence is valid (inclusive). If not set, the range extends indefinitely.
      recurrenceEnd?: string | null,
      // The number of periods between each recurrence. For example, with a multiple of N and period of DAY, the time range will recur every N days.
      recurrenceMultiple: number,
      // The unit of recurrence. For example, with a period of WEEK and multiple of 2, the time range will recur every other week.
      recurrencePeriod: TimeRangeRulePeriod,
      // An optional reason for the time range rule, used with time-off unavailability rules (lunch breaks, PTO, etc.)
      unavailabilityReason?: TimeRangeRuleUnavailabilityReason | null,
      // Additional notes about the time range rule.
      notes?: string | null,
      // The date and time that the rule was last updated.
      updatedAt?: string | null,
      // The user who last updated the rule.
      updatedBy?: string | null,
    } > | null,
    requiresBinaryPatientSex?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteServiceRequestTypeMutationVariables = {
  input: DeleteServiceRequestTypeInput,
  condition?: ModelServiceRequestTypeConditionInput | null,
};

export type DeleteServiceRequestTypeMutation = {
  deleteServiceRequestType?:  {
    __typename: "ServiceRequestType",
    id: string,
    cptCodes: Array< string | null >,
    externalID: string,
    displayName: string,
    // Alternate names for service requests, to be used in service request lookup
    synonyms?: Array< string | null > | null,
    labOrganization: LabOrganization,
    container?: string | null,
    serviceRequestTypeContainerID?: string | null,
    serviceRequestTypeContainer?:  {
      __typename: "ServiceRequestTypeContainer",
      id: string,
      containerType: string,
      alternativeContainerTypes?: Array< string > | null,
      substituteContainerTypesForCalculations?: Array< string > | null,
      containerCategory: string,
      capacity: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    specimen?: string | null,
    preferredSpecimen?: string | null,
    preferredVolumeDescription?: string | null,
    preferredVolumeMilliliters?: number | null,
    minimumVolume?: string | null,
    minimumVolumeAmount?: number | null,
    alternateSpecimen?: string | null,
    transportInstructions?: string | null,
    collectionInstructions?: string | null,
    collectionInstructionsVideoURL?: string | null,
    rejectionCriteria?: string | null,
    specimenStability?: string | null,
    patientPreparation?: string | null,
    resultAvailability?: string | null,
    serviceTypes?: Array< ServiceType > | null,
    containerSpecimenVolumes?:  Array< {
      __typename: "SpecimenContainerSpecimenVolumes",
      // The ID of the container assigned to a serviceRequest, only applicable for non-combinable serviceRequests
      containerOverrideID?: string | null,
      // The type of tube e.g., SST, LAV EDTA, Urine Cup
      containerCategory?: SpecimenContainerCategory | null,
      // The minimum volume needed for a test
      minimumMilliliters?: number | null,
      // The preferred volume for a test in case we do not have minumum volume set above.
      preferredMilliliters?: number | null,
      // Whether it's acceptable to combine the specimens for this test and others into a single container during container calculations.
      // For example, if true, then blood for two tests requiring 2 mL of whole blood each can be drawn into a single 5 mL tube rather than two separate 3 mL tubes.
      isCombinable: boolean,
      // minimum container count needed for the specimen
      minimumContainerCount?: number | null,
      specimenTestTypes?: Array< SpecimenTestType | null > | null,
    } > | null,
    abstentions?:  Array< {
      __typename: "Abstention",
      abstentionType: AbstentionType,
      preferredDurationMinutes: number,
      requiredDurationMinutes?: number | null,
    } > | null,
    customKit?:  {
      __typename: "ServiceRequestCustomKit",
      photoURL: string,
      barcodePhotoURL?: string | null,
      recipient: CustomKitRecipient,
      // requires test requisition form (TRF) completion
      requiresTRFCompletion: boolean,
      // requires benefit correction form (BCF) completion
      requiresBCFCompletion: boolean,
    } | null,
    collectionTimeRules?:  Array< {
      __typename: "TimeRangeRule",
      // The unique ID for the time range.
      id: string,
      // Whether the time range represents an addition or subtraction when considering multiple ranges.
      inclusion: TimeRangeRuleInclusion,
      // The time of day each recurrence starts (inclusive).
      start: string,
      // The time of day each recurrence ends (exclusive). Use '24:00:00' for the end of the day.
      end: string,
      // The first date that the recurrence is valid (inclusive).
      recurrenceStart: string,
      // The last date that the recurrence is valid (inclusive). If not set, the range extends indefinitely.
      recurrenceEnd?: string | null,
      // The number of periods between each recurrence. For example, with a multiple of N and period of DAY, the time range will recur every N days.
      recurrenceMultiple: number,
      // The unit of recurrence. For example, with a period of WEEK and multiple of 2, the time range will recur every other week.
      recurrencePeriod: TimeRangeRulePeriod,
      // An optional reason for the time range rule, used with time-off unavailability rules (lunch breaks, PTO, etc.)
      unavailabilityReason?: TimeRangeRuleUnavailabilityReason | null,
      // Additional notes about the time range rule.
      notes?: string | null,
      // The date and time that the rule was last updated.
      updatedAt?: string | null,
      // The user who last updated the rule.
      updatedBy?: string | null,
    } > | null,
    requiresBinaryPatientSex?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSpecimenContainerTypeMutationVariables = {
  input: CreateSpecimenContainerTypeInput,
  condition?: ModelSpecimenContainerTypeConditionInput | null,
};

export type CreateSpecimenContainerTypeMutation = {
  createSpecimenContainerType?:  {
    __typename: "SpecimenContainerType",
    id: string,
    name: string,
    category: SpecimenContainerCategory,
    // The whole capacity of the container. This would be the "whole blood capacity" for SST containers and the
    // general capacity for the containers which are not used for serum tests like Urine Cups and the Insure FOBT.
    capacityMilliliters: number,
    // Serum Capacity. The capacity of the container. Usually this is the volume of the container,
    // but in some cases the relevant capacity might reflect only a portion of the overall volume
    // (e.g. serum capacity in an SST, where the remaining volume is filled with clotted blood components).
    serumBloodCapacityMilliliters?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSpecimenContainerTypeMutationVariables = {
  input: UpdateSpecimenContainerTypeInput,
  condition?: ModelSpecimenContainerTypeConditionInput | null,
};

export type UpdateSpecimenContainerTypeMutation = {
  updateSpecimenContainerType?:  {
    __typename: "SpecimenContainerType",
    id: string,
    name: string,
    category: SpecimenContainerCategory,
    // The whole capacity of the container. This would be the "whole blood capacity" for SST containers and the
    // general capacity for the containers which are not used for serum tests like Urine Cups and the Insure FOBT.
    capacityMilliliters: number,
    // Serum Capacity. The capacity of the container. Usually this is the volume of the container,
    // but in some cases the relevant capacity might reflect only a portion of the overall volume
    // (e.g. serum capacity in an SST, where the remaining volume is filled with clotted blood components).
    serumBloodCapacityMilliliters?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSpecimenContainerTypeMutationVariables = {
  input: DeleteSpecimenContainerTypeInput,
  condition?: ModelSpecimenContainerTypeConditionInput | null,
};

export type DeleteSpecimenContainerTypeMutation = {
  deleteSpecimenContainerType?:  {
    __typename: "SpecimenContainerType",
    id: string,
    name: string,
    category: SpecimenContainerCategory,
    // The whole capacity of the container. This would be the "whole blood capacity" for SST containers and the
    // general capacity for the containers which are not used for serum tests like Urine Cups and the Insure FOBT.
    capacityMilliliters: number,
    // Serum Capacity. The capacity of the container. Usually this is the volume of the container,
    // but in some cases the relevant capacity might reflect only a portion of the overall volume
    // (e.g. serum capacity in an SST, where the remaining volume is filled with clotted blood components).
    serumBloodCapacityMilliliters?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateServiceRequestTypeContainerMutationVariables = {
  input: CreateServiceRequestTypeContainerInput,
  condition?: ModelServiceRequestTypeContainerConditionInput | null,
};

export type CreateServiceRequestTypeContainerMutation = {
  createServiceRequestTypeContainer?:  {
    __typename: "ServiceRequestTypeContainer",
    id: string,
    containerType: string,
    alternativeContainerTypes?: Array< string > | null,
    substituteContainerTypesForCalculations?: Array< string > | null,
    containerCategory: string,
    capacity: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateServiceRequestTypeContainerMutationVariables = {
  input: UpdateServiceRequestTypeContainerInput,
  condition?: ModelServiceRequestTypeContainerConditionInput | null,
};

export type UpdateServiceRequestTypeContainerMutation = {
  updateServiceRequestTypeContainer?:  {
    __typename: "ServiceRequestTypeContainer",
    id: string,
    containerType: string,
    alternativeContainerTypes?: Array< string > | null,
    substituteContainerTypesForCalculations?: Array< string > | null,
    containerCategory: string,
    capacity: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteServiceRequestTypeContainerMutationVariables = {
  input: DeleteServiceRequestTypeContainerInput,
  condition?: ModelServiceRequestTypeContainerConditionInput | null,
};

export type DeleteServiceRequestTypeContainerMutation = {
  deleteServiceRequestTypeContainer?:  {
    __typename: "ServiceRequestTypeContainer",
    id: string,
    containerType: string,
    alternativeContainerTypes?: Array< string > | null,
    substituteContainerTypesForCalculations?: Array< string > | null,
    containerCategory: string,
    capacity: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSMSMutationVariables = {
  input: CreateSMSInput,
  condition?: ModelSMSConditionInput | null,
};

export type CreateSMSMutation = {
  createSMS?:  {
    __typename: "SMS",
    id: string,
    type: SMSType,
    contextJSON: string,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type BookAppointmentMutationVariables = {
  input: BookAppointmentInput,
};

export type BookAppointmentMutation = {
  bookAppointment?:  {
    __typename: "BookAppointmentResponse",
    appointment?:  {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type CancelAppointmentMutationVariables = {
  input: CancelAppointmentInput,
};

export type CancelAppointmentMutation = {
  cancelAppointment?:  {
    __typename: "CancelAppointmentResponse",
    appointment?:  {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type DeepCloneAndBookAppointmentMutationVariables = {
  input: DeepCloneAndBookAppointmentInput,
};

export type DeepCloneAndBookAppointmentMutation = {
  deepCloneAndBookAppointment?:  {
    __typename: "DeepCloneAndBookAppointmentResponse",
    appointment?:  {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type AcceptAppointmentMutationVariables = {
  input: AcceptAppointmentInput,
};

export type AcceptAppointmentMutation = {
  acceptAppointment?:  {
    __typename: "Appointment",
    id: string,
    // The current status of the appointment (See AppointmentStatus enum)
    status: AppointmentStatus,
    // Whether the appointment has been marked failed
    isFailed?: boolean | null,
    // Whether the appointment has at least one service completed in any of the encounters
    hasSuccessfulServices?: boolean | null,
    // Whether the appointment has at least one service failed in any of the encounters
    hasFailedServices?: boolean | null,
    // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
    needsRebooking?: boolean | null,
    // Whether the appointment has passed the pre-review phase
    isPreReviewed?: boolean | null,
    // Whether the appointment has passed the post-review phase
    isPostReviewed?: boolean | null,
    // Whether the appointment has been escalated
    isEscalated?: boolean | null,
    // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
    start: string,
    // Expected appointment end date/time
    end: string,
    // The technician assigned to the appointment
    techID?: string | null,
    remoteTechID?: string | null,
    remoteTechMatchState?: RemoteTechMatchState | null,
    coordinatorID: string,
    location:  {
      __typename: "Location",
      satellitePhotoURL?: string | null,
      streetViewPhotoURL?: string | null,
    },
    partnerOrganization?: PartnerOrganization | null,
    preReviewChecklist?:  {
      __typename: "Checklist",
    } | null,
    checklist?:  {
      __typename: "Checklist",
    } | null,
    postReviewChecklist?:  {
      __typename: "Checklist",
    } | null,
    serviceRegionName: string,
    geographicalState?: string | null,
    avoidTechIDs?: Array< string > | null,
    lastBookedAt?: number | null,
    statusUpdatedAt?: number | null,
    stripeCustomerID?: string | null,
    // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
    stripePriceToCharge?: number | null,
    hasPetsAtLocation?: boolean | null,
    // Comments about logistics like door codes, directions, etc.
    commentsFromCoordinatorAboutAppointment?: string | null,
    // Comments about logistics like door codes, directions, etc. (but from techs)
    commentsFromTechAboutAppointment?: string | null,
    // Comments about app feedback, bugs, feature requests, etc.
    commentsFromTechAboutSystem?: string | null,
    // Comments about why an appointment was put into a failed state
    commentsFromTechAboutAppointmentFailure?: string | null,
    // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
    commentsFromSchedulerAboutAppointmentFailure?: string | null,
    // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
    commentsFromSchedulerAboutCancellation?: string | null,
    partnerOrganizationBookingURL?: string | null,
    encounters?:  {
      __typename: "ModelEncounterConnection",
      nextToken?: string | null,
    } | null,
    tech?:  {
      __typename: "Tech",
      id: string,
      gender?: Gender | null,
      phone?: string | null,
      email?: string | null,
      serviceTypes?: Array< ServiceType | null > | null,
      profilePictureS3ObjectID?: string | null,
      isActive?: boolean | null,
      biographyItems?: Array< string > | null,
      patientTestimonials?: Array< string > | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      capabilities?: Array< TechCapability > | null,
      isIneligibleForMatchmaking?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    remoteTech?:  {
      __typename: "RemoteTech",
      id: string,
      email?: string | null,
      phone?: string | null,
      profilePictureS3ObjectID?: string | null,
      timezone?: Timezone | null,
      isActive: boolean,
      remoteVisitZoomLink?: string | null,
      remoteVisitZoomLinkQRCodeS3ObjectID?: string | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    coordinator:  {
      __typename: "Coordinator",
      id: string,
      authorizedTechIDs?: Array< string > | null,
      // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
      isCSR?: boolean | null,
      isActive?: boolean | null,
      managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
      authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
      phone?: string | null,
      email?: string | null,
      billingCustomerID?: string | null,
      outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
      stripeCustomerID?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    overrideMode?: AppointmentOverrideMode | null,
    // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
    // The default value is 0.5.
    sharedSlotRank: number,
    sharedSlotAppointments?:  Array< {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } > | null,
    // Represents how long the appointment should take based on product types
    estimatedServiceDurationSeconds: number,
    // For invoiced partners, the invoice line items associated with this appointment.
    stripeInvoiceLineItemIDs?: Array< string | null > | null,
    // For patient-paid appointments, any payment attempts associated with this appointment.
    stripePaymentIntentIDs?: Array< string | null > | null,
    // Whether the appointment has been confirmed or if the patient requested a change
    // When undefined or null, it means that the confirmation is still pending
    hasPatientConfirmed?: boolean | null,
    // Whether or not the appointment was booked in an unauthenticated flow
    isGuestBooked?: boolean | null,
    // Referral information from which the appointment was created (optional)
    referralID?: string | null,
    referral?:  {
      __typename: "Referral",
      id: string,
      patientID: string,
      partnerOrganization: PartnerOrganization,
      requisitionUploadS3ObjectIDs?: Array< string > | null,
      serviceTypes: Array< ServiceType >,
      isBooked?: boolean | null,
      isExpired?: boolean | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
    historyJSON?: string | null,
    // An external ID associated with the appointment, such as a job ID from MPX
    externalID?: string | null,
    // The id of the appointment that this appointment is cloned from. Cloning includes information
    // such as location, patients, and requisitions that are carried over from the original.
    clonedFromAppointmentID?: string | null,
    // The type of qualification a remote tech needs to have for this appointment.
    // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
    // Not present if the appointment has no remote component.
    remoteTechQualificationTypeRequired?: QualificationType | null,
    // Appointment date/time added to "queue"
    // This timestamp is used to determine the ordering of when remoteTech should be assigned
    markedReadyForRemoteTechAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ExportReportsMutationVariables = {
  type: ReportsExportType,
  destination: ReportsExportDestination,
  startDate?: string | null,
  endDate?: string | null,
  parametersJSON?: string | null,
};

export type ExportReportsMutation = {
  exportReports?:  Array< {
    __typename: "Report",
    name?: string | null,
    reportURL?: string | null,
    reportS3Object?:  {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } | null,
    type: ReportType,
  } > | null,
};

export type ImportDataMutationVariables = {
  type: DataImportType,
  data: Array< DocumentInput >,
  parametersJSON?: string | null,
  outputDestination: ReportsExportDestination,
  write: boolean,
  verbose?: boolean | null,
};

export type ImportDataMutation = {
  importData?:  Array< {
    __typename: "Report",
    name?: string | null,
    reportURL?: string | null,
    reportS3Object?:  {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } | null,
    type: ReportType,
  } > | null,
};

export type GenerateStripeSetupIntentMutationVariables = {
  input: GenerateStripeSetupIntentInput,
};

export type GenerateStripeSetupIntentMutation = {
  generateStripeSetupIntent?:  {
    __typename: "GenerateStripeSetupIntentResponse",
    clientSecret?: string | null,
    finalPrice?: number | null,
    strikethroughPrice?: number | null,
  } | null,
};

export type GenerateProjectedEngagementActivityScheduleMutationVariables = {
};

export type GenerateProjectedEngagementActivityScheduleMutation = {
  generateProjectedEngagementActivitySchedule:  {
    __typename: "ProjectedEngagementActivitySchedule",
    id: string,
    // A friendly(-ish) name for the schedule.
    name: string,
    // The status of the schedule. This largely serves as a partition key so we can query for schedules sorted by accepted at.
    status: ProjectedEngagementActivityScheduleStatus,
    acceptedAt?: string | null,
    totalBatches?: number | null,
    processedBatches?: number | null,
    parametersJSON?: string | null,
    createdAt: string,
    updatedAt: string,
  },
};

export type AcceptProjectedEngagementActivityScheduleMutationVariables = {
  id: string,
};

export type AcceptProjectedEngagementActivityScheduleMutation = {
  acceptProjectedEngagementActivitySchedule:  {
    __typename: "ProjectedEngagementActivitySchedule",
    id: string,
    // A friendly(-ish) name for the schedule.
    name: string,
    // The status of the schedule. This largely serves as a partition key so we can query for schedules sorted by accepted at.
    status: ProjectedEngagementActivityScheduleStatus,
    acceptedAt?: string | null,
    totalBatches?: number | null,
    processedBatches?: number | null,
    parametersJSON?: string | null,
    createdAt: string,
    updatedAt: string,
  },
};

export type AddUnlinkableReportToEncounterMutationVariables = {
  encounterID: string,
  unlinkableReportID: string,
};

export type AddUnlinkableReportToEncounterMutation = {
  addUnlinkableReportToEncounter?:  {
    __typename: "Encounter",
    id: string,
    appointmentID: string,
    appointment:  {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    patientID: string,
    patient:  {
      __typename: "Patient",
      id: string,
      csrPartnerOrganization?: PartnerOrganization | null,
      partnerOrganization?: PartnerOrganization | null,
      coordinatorID: string,
      medicareCardID?: string | null,
      imageMoverUserID?: string | null,
      externalID?: string | null,
      secondaryExternalID?: string | null,
      specimenLabelID?: string | null,
      qtMedicalPatientID?: string | null,
      planName?: string | null,
      diabeticType?: DiabeticType | null,
      authorizedTechIDs?: Array< string > | null,
      isCoordinator: boolean,
      // Phone & email to use in place of the coordinators for messaging. Used primarily for patients whose appointments are booked for them by a partner's customer service representatives.
      phone?: string | null,
      email?: string | null,
      secondaryPhone?: string | null,
      birthdate?: string | null,
      sex?: Sex | null,
      gender?: Gender | null,
      ethnicity?: Ethnicity | null,
      races?: Array< Race > | null,
      passportNumber?: string | null,
      insuranceCardUploadFrontS3ObjectID?: string | null,
      insuranceCardUploadBackS3ObjectID?: string | null,
      medicareCardUploadFrontS3ObjectID?: string | null,
      medicareCardUploadBackS3ObjectID?: string | null,
      watchlistedAppointmentID?: string | null,
      responsiblePractitionerID?: string | null,
      commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
      commentsFromCaregiverAboutResponsiblePractitioner?: string | null,
      suggestedAppointmentLocationAdditionalInformation?: string | null,
      preferredLanguage?: string | null,
      primaryLanguageBCP47?: string | null,
      secondaryLanguagesBCP47?: Array< string > | null,
      // A unique numerical patient reference number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
      patientNumber?: string | null,
      // latest validated gap list data referencing this patient.
      // - latestGapListUploadID: the upload ID of the latest ingestion for tracking purposes
      // - latestGapListRowJSON: the entire row
      // - latestGapListPassthroughJSON: a subset of the row for passing back to the partner with patient data in reports
      latestGapListUploadID?: string | null,
      latestGapListRowJSON?: string | null,
      latestGapListPassthroughJSON?: string | null,
      // A reference to a set of branding material provided for outreach.
      brandCode?: string | null,
      population?: PatientPopulation | null,
      elationID?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    coordinatorID: string,
    coordinator?:  {
      __typename: "Coordinator",
      id: string,
      authorizedTechIDs?: Array< string > | null,
      // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
      isCSR?: boolean | null,
      isActive?: boolean | null,
      managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
      authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
      phone?: string | null,
      email?: string | null,
      billingCustomerID?: string | null,
      outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
      stripeCustomerID?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    techID?: string | null,
    tech?:  {
      __typename: "Tech",
      id: string,
      gender?: Gender | null,
      phone?: string | null,
      email?: string | null,
      serviceTypes?: Array< ServiceType | null > | null,
      profilePictureS3ObjectID?: string | null,
      isActive?: boolean | null,
      biographyItems?: Array< string > | null,
      patientTestimonials?: Array< string > | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      capabilities?: Array< TechCapability > | null,
      isIneligibleForMatchmaking?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    partnerOrganization?: PartnerOrganization | null,
    requisitions?:  Array< {
      __typename: "Requisition",
      id: string,
      labOrganization?: LabOrganization | null,
      // A unique numerical patient requisition number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
      requisitionNumber?: string | null,
    } > | null,
    requisitionUploads?:  Array< {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } > | null,
    requisitionUploadS3ObjectIDs?: Array< string > | null,
    // Comments about patient info like medical/draw history, disabilities, etc.
    commentsFromCoordinatorAboutPatient?: string | null,
    // Comments about the clinical aspects of the encounter to be passed from scheduler to technician
    commentsFromSchedulerAboutEncounter?: string | null,
    // Comments about the clinical aspects of the encounter to be passed from scheduler to technician
    commentsFromSchedulerToTechAboutEncounter?: string | null,
    // Comments about the clinical aspects of the encounter and patient info not mentioned by the coordinator
    commentsFromTechAboutEncounter?: string | null,
    // Comments from customer support about actions taken on the encounter (e.g., services removed).
    commentsFromCustomerSupportAboutEncounter?: string | null,
    // Comments from the scheduler to the partner about actions taken on the encounter (e.g., services removed).
    commentsFromSchedulerToPartnerAboutEncounter?: string | null,
    covid19Questions?:  {
      __typename: "COVID19Questions",
      isForTravel?: boolean | null,
      isForWorkOrSchool?: boolean | null,
      isForAPublicEvent?: boolean | null,
      isForPotentialExposure?: boolean | null,
      isForSymptoms?: boolean | null,
      isForOtherReasons?: boolean | null,
    } | null,
    bloodCollectionDetails?:  {
      __typename: "SpecimenCollectionDetails",
      bodySiteLaterality?: Laterality | null,
      bodySiteStructure?: BodyStructure | null,
      deviceType?: SpecimenCollectionDeviceType | null,
      attempts?: number | null,
    } | null,
    capillaryDrawCollectionDetails?:  {
      __typename: "SpecimenCollectionDetails",
      bodySiteLaterality?: Laterality | null,
      bodySiteStructure?: BodyStructure | null,
      deviceType?: SpecimenCollectionDeviceType | null,
      attempts?: number | null,
    } | null,
    productTypes?: Array< ProductType > | null,
    isMedicare?: boolean | null,
    isMedicareEligible?: boolean | null,
    wasPatientManuallyAdded?: boolean | null,
    serviceOutcomes?:  Array< {
      __typename: "ServiceOutcome",
      failureReason?: ServiceFailureReason | null,
      failureFault?: ServiceFailureFault | null,
      isRescheduleRequired?: boolean | null,
      serviceType: ServiceType,
      status?: ServiceStatus | null,
      commentsFromSchedulerAboutServiceFailure?: string | null,
      serviceDetailJSON?: string | null,
    } > | null,
    diagnosisCodes?:  Array< {
      __typename: "Coding",
      system: string,
      code: string,
      displayName?: string | null,
    } > | null,
    // The partner's identifier for this encounter/appointment:
    // - FMI Case number for Foundation,
    // - Accession Number for Guardant,
    // - Job ID for Natera
    partnersEncounterID?:  {
      __typename: "CustomProperty",
      key: CustomPropertyKey,
      value: string,
    } | null,
    documents?:  Array< {
      __typename: "Document",
      type?: DocumentType | null,
      url?: string | null,
      format?: DocumentFormat | null,
      s3ObjectID?: string | null,
      uploadedAt?: number | null,
      // stringified JSON object as a set of additional data we want to store about documents (requisition number, lab report status, etc.)
      metadataJSON?: string | null,
    } > | null,
    observations?:  Array< {
      __typename: "Observation",
      id: string,
      // Per FHIR specifications, we should only have value or valueCoding
      value?: string | null,
      units?: string | null,
      referenceRange?: string | null,
      category: ObservationCategory,
      performerType: ObservationPerformerType,
      performerIdentifier?: string | null,
      observedAt?: string | null,
      bodySiteLaterality?: Laterality | null,
      bodySiteStructure?: BodyStructure | null,
      notes?: string | null,
      // Also called Abnormal Flag. A categorical assessment, providing a rough qualitative interpretation of the observation value.
      // http://hl7.org/fhir/valueset-observation-interpretation.html
      interpretationCode?: ObservationInterpretationCode | null,
      source?: ObservationSource | null,
    } > | null,
    procedures?:  Array< {
      __typename: "Procedure",
      source: ProcedureSource,
    } > | null,
    escalation?:  {
      __typename: "Escalation",
      isResolved?: boolean | null,
      symptoms?: Array< EscalationSymptom > | null,
      // If OTHER is selected in the symptoms this contains the list of other symptoms.
      otherSymptoms?: string | null,
      // Comments from the patient to the escalation owner, like their medical history. The escalation owner is the scheduler who is filling out the report.
      commentsFromPatientToEscalationOwner?: string | null,
      // Comments from the escalation owner to the partner about the escalation. This includes the general assessment of the situation involving the patient. The escalation owner is the scheduler who is filling out the report.
      commentsFromEscalationOwnerToPartnerAboutEscalation?: string | null,
      outcome?: EscalationOutcome | null,
      // If OTHER is selected for the outcome this contains a description of the outcome.
      otherOutcome?: string | null,
      outcomeTimeframe?: EscalationOutcomeTimeframe | null,
      createdAt?: string | null,
      resolvedAt?: string | null,
    } | null,
    questionnaires?:  Array< {
      __typename: "QuestionnaireEmbedded",
      type: QuestionnaireType,
      url: string,
      metadataJSON?: string | null,
    } > | null,
    colorectalCancerScreeningAnswers?:  {
      __typename: "QuestionnaireResponse",
      // Canonical id of Questionnaire being answered
      responseID?: string | null,
      // Date the answers were gathered
      authoredAt?: string | null,
    } | null,
    patientEncounterSummaryLastSentAt?: string | null,
    cologuardOrderSuccessfullyPlacedAt?: string | null,
    hasPatientRequestedMailedResults?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type RerouteAppointmentMutationVariables = {
  input: RerouteAppointmentInput,
};

export type RerouteAppointmentMutation = {
  rerouteAppointment?:  {
    __typename: "Appointment",
    id: string,
    // The current status of the appointment (See AppointmentStatus enum)
    status: AppointmentStatus,
    // Whether the appointment has been marked failed
    isFailed?: boolean | null,
    // Whether the appointment has at least one service completed in any of the encounters
    hasSuccessfulServices?: boolean | null,
    // Whether the appointment has at least one service failed in any of the encounters
    hasFailedServices?: boolean | null,
    // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
    needsRebooking?: boolean | null,
    // Whether the appointment has passed the pre-review phase
    isPreReviewed?: boolean | null,
    // Whether the appointment has passed the post-review phase
    isPostReviewed?: boolean | null,
    // Whether the appointment has been escalated
    isEscalated?: boolean | null,
    // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
    start: string,
    // Expected appointment end date/time
    end: string,
    // The technician assigned to the appointment
    techID?: string | null,
    remoteTechID?: string | null,
    remoteTechMatchState?: RemoteTechMatchState | null,
    coordinatorID: string,
    location:  {
      __typename: "Location",
      satellitePhotoURL?: string | null,
      streetViewPhotoURL?: string | null,
    },
    partnerOrganization?: PartnerOrganization | null,
    preReviewChecklist?:  {
      __typename: "Checklist",
    } | null,
    checklist?:  {
      __typename: "Checklist",
    } | null,
    postReviewChecklist?:  {
      __typename: "Checklist",
    } | null,
    serviceRegionName: string,
    geographicalState?: string | null,
    avoidTechIDs?: Array< string > | null,
    lastBookedAt?: number | null,
    statusUpdatedAt?: number | null,
    stripeCustomerID?: string | null,
    // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
    stripePriceToCharge?: number | null,
    hasPetsAtLocation?: boolean | null,
    // Comments about logistics like door codes, directions, etc.
    commentsFromCoordinatorAboutAppointment?: string | null,
    // Comments about logistics like door codes, directions, etc. (but from techs)
    commentsFromTechAboutAppointment?: string | null,
    // Comments about app feedback, bugs, feature requests, etc.
    commentsFromTechAboutSystem?: string | null,
    // Comments about why an appointment was put into a failed state
    commentsFromTechAboutAppointmentFailure?: string | null,
    // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
    commentsFromSchedulerAboutAppointmentFailure?: string | null,
    // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
    commentsFromSchedulerAboutCancellation?: string | null,
    partnerOrganizationBookingURL?: string | null,
    encounters?:  {
      __typename: "ModelEncounterConnection",
      nextToken?: string | null,
    } | null,
    tech?:  {
      __typename: "Tech",
      id: string,
      gender?: Gender | null,
      phone?: string | null,
      email?: string | null,
      serviceTypes?: Array< ServiceType | null > | null,
      profilePictureS3ObjectID?: string | null,
      isActive?: boolean | null,
      biographyItems?: Array< string > | null,
      patientTestimonials?: Array< string > | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      capabilities?: Array< TechCapability > | null,
      isIneligibleForMatchmaking?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    remoteTech?:  {
      __typename: "RemoteTech",
      id: string,
      email?: string | null,
      phone?: string | null,
      profilePictureS3ObjectID?: string | null,
      timezone?: Timezone | null,
      isActive: boolean,
      remoteVisitZoomLink?: string | null,
      remoteVisitZoomLinkQRCodeS3ObjectID?: string | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    coordinator:  {
      __typename: "Coordinator",
      id: string,
      authorizedTechIDs?: Array< string > | null,
      // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
      isCSR?: boolean | null,
      isActive?: boolean | null,
      managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
      authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
      phone?: string | null,
      email?: string | null,
      billingCustomerID?: string | null,
      outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
      stripeCustomerID?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    overrideMode?: AppointmentOverrideMode | null,
    // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
    // The default value is 0.5.
    sharedSlotRank: number,
    sharedSlotAppointments?:  Array< {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } > | null,
    // Represents how long the appointment should take based on product types
    estimatedServiceDurationSeconds: number,
    // For invoiced partners, the invoice line items associated with this appointment.
    stripeInvoiceLineItemIDs?: Array< string | null > | null,
    // For patient-paid appointments, any payment attempts associated with this appointment.
    stripePaymentIntentIDs?: Array< string | null > | null,
    // Whether the appointment has been confirmed or if the patient requested a change
    // When undefined or null, it means that the confirmation is still pending
    hasPatientConfirmed?: boolean | null,
    // Whether or not the appointment was booked in an unauthenticated flow
    isGuestBooked?: boolean | null,
    // Referral information from which the appointment was created (optional)
    referralID?: string | null,
    referral?:  {
      __typename: "Referral",
      id: string,
      patientID: string,
      partnerOrganization: PartnerOrganization,
      requisitionUploadS3ObjectIDs?: Array< string > | null,
      serviceTypes: Array< ServiceType >,
      isBooked?: boolean | null,
      isExpired?: boolean | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
    historyJSON?: string | null,
    // An external ID associated with the appointment, such as a job ID from MPX
    externalID?: string | null,
    // The id of the appointment that this appointment is cloned from. Cloning includes information
    // such as location, patients, and requisitions that are carried over from the original.
    clonedFromAppointmentID?: string | null,
    // The type of qualification a remote tech needs to have for this appointment.
    // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
    // Not present if the appointment has no remote component.
    remoteTechQualificationTypeRequired?: QualificationType | null,
    // Appointment date/time added to "queue"
    // This timestamp is used to determine the ordering of when remoteTech should be assigned
    markedReadyForRemoteTechAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ReviewPostAppointmentMutationVariables = {
  input: ReviewPostAppointmentInput,
};

export type ReviewPostAppointmentMutation = {
  reviewPostAppointment?:  {
    __typename: "ReviewPostAppointmentResponse",
    appointment?:  {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type ReviewPreAppointmentMutationVariables = {
  input: ReviewPreAppointmentInput,
};

export type ReviewPreAppointmentMutation = {
  reviewPreAppointment?:  {
    __typename: "ReviewPreAppointmentResponse",
    appointment?:  {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type ForceRescheduleAppointmentMutationVariables = {
  appointmentID: string,
  start: string,
  end: string,
};

export type ForceRescheduleAppointmentMutation = {
  forceRescheduleAppointment?:  {
    __typename: "Appointment",
    id: string,
    // The current status of the appointment (See AppointmentStatus enum)
    status: AppointmentStatus,
    // Whether the appointment has been marked failed
    isFailed?: boolean | null,
    // Whether the appointment has at least one service completed in any of the encounters
    hasSuccessfulServices?: boolean | null,
    // Whether the appointment has at least one service failed in any of the encounters
    hasFailedServices?: boolean | null,
    // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
    needsRebooking?: boolean | null,
    // Whether the appointment has passed the pre-review phase
    isPreReviewed?: boolean | null,
    // Whether the appointment has passed the post-review phase
    isPostReviewed?: boolean | null,
    // Whether the appointment has been escalated
    isEscalated?: boolean | null,
    // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
    start: string,
    // Expected appointment end date/time
    end: string,
    // The technician assigned to the appointment
    techID?: string | null,
    remoteTechID?: string | null,
    remoteTechMatchState?: RemoteTechMatchState | null,
    coordinatorID: string,
    location:  {
      __typename: "Location",
      satellitePhotoURL?: string | null,
      streetViewPhotoURL?: string | null,
    },
    partnerOrganization?: PartnerOrganization | null,
    preReviewChecklist?:  {
      __typename: "Checklist",
    } | null,
    checklist?:  {
      __typename: "Checklist",
    } | null,
    postReviewChecklist?:  {
      __typename: "Checklist",
    } | null,
    serviceRegionName: string,
    geographicalState?: string | null,
    avoidTechIDs?: Array< string > | null,
    lastBookedAt?: number | null,
    statusUpdatedAt?: number | null,
    stripeCustomerID?: string | null,
    // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
    stripePriceToCharge?: number | null,
    hasPetsAtLocation?: boolean | null,
    // Comments about logistics like door codes, directions, etc.
    commentsFromCoordinatorAboutAppointment?: string | null,
    // Comments about logistics like door codes, directions, etc. (but from techs)
    commentsFromTechAboutAppointment?: string | null,
    // Comments about app feedback, bugs, feature requests, etc.
    commentsFromTechAboutSystem?: string | null,
    // Comments about why an appointment was put into a failed state
    commentsFromTechAboutAppointmentFailure?: string | null,
    // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
    commentsFromSchedulerAboutAppointmentFailure?: string | null,
    // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
    commentsFromSchedulerAboutCancellation?: string | null,
    partnerOrganizationBookingURL?: string | null,
    encounters?:  {
      __typename: "ModelEncounterConnection",
      nextToken?: string | null,
    } | null,
    tech?:  {
      __typename: "Tech",
      id: string,
      gender?: Gender | null,
      phone?: string | null,
      email?: string | null,
      serviceTypes?: Array< ServiceType | null > | null,
      profilePictureS3ObjectID?: string | null,
      isActive?: boolean | null,
      biographyItems?: Array< string > | null,
      patientTestimonials?: Array< string > | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      capabilities?: Array< TechCapability > | null,
      isIneligibleForMatchmaking?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    remoteTech?:  {
      __typename: "RemoteTech",
      id: string,
      email?: string | null,
      phone?: string | null,
      profilePictureS3ObjectID?: string | null,
      timezone?: Timezone | null,
      isActive: boolean,
      remoteVisitZoomLink?: string | null,
      remoteVisitZoomLinkQRCodeS3ObjectID?: string | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    coordinator:  {
      __typename: "Coordinator",
      id: string,
      authorizedTechIDs?: Array< string > | null,
      // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
      isCSR?: boolean | null,
      isActive?: boolean | null,
      managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
      authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
      phone?: string | null,
      email?: string | null,
      billingCustomerID?: string | null,
      outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
      stripeCustomerID?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    overrideMode?: AppointmentOverrideMode | null,
    // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
    // The default value is 0.5.
    sharedSlotRank: number,
    sharedSlotAppointments?:  Array< {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } > | null,
    // Represents how long the appointment should take based on product types
    estimatedServiceDurationSeconds: number,
    // For invoiced partners, the invoice line items associated with this appointment.
    stripeInvoiceLineItemIDs?: Array< string | null > | null,
    // For patient-paid appointments, any payment attempts associated with this appointment.
    stripePaymentIntentIDs?: Array< string | null > | null,
    // Whether the appointment has been confirmed or if the patient requested a change
    // When undefined or null, it means that the confirmation is still pending
    hasPatientConfirmed?: boolean | null,
    // Whether or not the appointment was booked in an unauthenticated flow
    isGuestBooked?: boolean | null,
    // Referral information from which the appointment was created (optional)
    referralID?: string | null,
    referral?:  {
      __typename: "Referral",
      id: string,
      patientID: string,
      partnerOrganization: PartnerOrganization,
      requisitionUploadS3ObjectIDs?: Array< string > | null,
      serviceTypes: Array< ServiceType >,
      isBooked?: boolean | null,
      isExpired?: boolean | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
    historyJSON?: string | null,
    // An external ID associated with the appointment, such as a job ID from MPX
    externalID?: string | null,
    // The id of the appointment that this appointment is cloned from. Cloning includes information
    // such as location, patients, and requisitions that are carried over from the original.
    clonedFromAppointmentID?: string | null,
    // The type of qualification a remote tech needs to have for this appointment.
    // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
    // Not present if the appointment has no remote component.
    remoteTechQualificationTypeRequired?: QualificationType | null,
    // Appointment date/time added to "queue"
    // This timestamp is used to determine the ordering of when remoteTech should be assigned
    markedReadyForRemoteTechAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateEncounterServiceOutcomesMutationVariables = {
  input: UpdateEncounterServiceOutcomesInput,
};

export type UpdateEncounterServiceOutcomesMutation = {
  updateEncounterServiceOutcomes?:  {
    __typename: "UpdateEncounterServiceOutcomesResponse",
    appointmentID: string,
    hasFailedServices: boolean,
    hasSuccessfulServices: boolean,
  } | null,
};

export type SaveEmailToContactForServiceExpansionMutationVariables = {
  input: SaveEmailToContactForServiceExpansionInput,
};

export type SaveEmailToContactForServiceExpansionMutation = {
  saveEmailToContactForServiceExpansion?:  {
    __typename: "SaveEmailToContactForServiceExpansionResponse",
    _?: number | null,
  } | null,
};

export type SendEFaxMutationVariables = {
  input: SendEFaxInput,
};

export type SendEFaxMutation = {
  sendEFax?:  {
    __typename: "SendEFaxResponse",
    result?: string | null,
    errorMessage?: string | null,
    sentAt?: string | null,
  } | null,
};

export type SendEncounterSummaryToPractitionerMutationVariables = {
  input: SendEncounterSummaryToPractitionerInput,
};

export type SendEncounterSummaryToPractitionerMutation = {
  sendEncounterSummaryToPractitioner?:  {
    __typename: "SendEncounterSummaryToPractitionerResponse",
    sentAt?: string | null,
    response?: string | null,
    errorMessage?: string | null,
    to?: Array< string | null > | null,
  } | null,
};

export type SendEncounterSummaryEmailMutationVariables = {
  input: SendEncounterSummaryEmailInput,
};

export type SendEncounterSummaryEmailMutation = {
  sendEncounterSummaryEmail?:  {
    __typename: "SendEncounterSummaryEmailResponse",
    sentAt?: string | null,
    response?: string | null,
    to?: Array< string | null > | null,
  } | null,
};

export type StartCareNavigationEpisodesMutationVariables = {
  referralID: string,
  episodeDetails: Array< StartCareNavigationEpisodeDetails >,
};

export type StartCareNavigationEpisodesMutation = {
  startCareNavigationEpisodes?:  Array< {
    __typename: "CareNavigationEpisode",
    closedAt?: string | null,
    careNavigationGroup: CareNavigationGroup,
    patientID: string,
    patient:  {
      __typename: "Patient",
      id: string,
      csrPartnerOrganization?: PartnerOrganization | null,
      partnerOrganization?: PartnerOrganization | null,
      coordinatorID: string,
      medicareCardID?: string | null,
      imageMoverUserID?: string | null,
      externalID?: string | null,
      secondaryExternalID?: string | null,
      specimenLabelID?: string | null,
      qtMedicalPatientID?: string | null,
      planName?: string | null,
      diabeticType?: DiabeticType | null,
      authorizedTechIDs?: Array< string > | null,
      isCoordinator: boolean,
      // Phone & email to use in place of the coordinators for messaging. Used primarily for patients whose appointments are booked for them by a partner's customer service representatives.
      phone?: string | null,
      email?: string | null,
      secondaryPhone?: string | null,
      birthdate?: string | null,
      sex?: Sex | null,
      gender?: Gender | null,
      ethnicity?: Ethnicity | null,
      races?: Array< Race > | null,
      passportNumber?: string | null,
      insuranceCardUploadFrontS3ObjectID?: string | null,
      insuranceCardUploadBackS3ObjectID?: string | null,
      medicareCardUploadFrontS3ObjectID?: string | null,
      medicareCardUploadBackS3ObjectID?: string | null,
      watchlistedAppointmentID?: string | null,
      responsiblePractitionerID?: string | null,
      commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
      commentsFromCaregiverAboutResponsiblePractitioner?: string | null,
      suggestedAppointmentLocationAdditionalInformation?: string | null,
      preferredLanguage?: string | null,
      primaryLanguageBCP47?: string | null,
      secondaryLanguagesBCP47?: Array< string > | null,
      // A unique numerical patient reference number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
      patientNumber?: string | null,
      // latest validated gap list data referencing this patient.
      // - latestGapListUploadID: the upload ID of the latest ingestion for tracking purposes
      // - latestGapListRowJSON: the entire row
      // - latestGapListPassthroughJSON: a subset of the row for passing back to the partner with patient data in reports
      latestGapListUploadID?: string | null,
      latestGapListRowJSON?: string | null,
      latestGapListPassthroughJSON?: string | null,
      // A reference to a set of branding material provided for outreach.
      brandCode?: string | null,
      population?: PatientPopulation | null,
      elationID?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    partnerOrganization: PartnerOrganization,
    referralID: string,
    referral:  {
      __typename: "Referral",
      id: string,
      patientID: string,
      partnerOrganization: PartnerOrganization,
      requisitionUploadS3ObjectIDs?: Array< string > | null,
      serviceTypes: Array< ServiceType >,
      isBooked?: boolean | null,
      isExpired?: boolean | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    },
    zendeskTicketID?: number | null,
    serviceOutcomes?:  Array< {
      __typename: "CareNavigationEpisodeServiceOutcome",
      id: string,
      failureReason?: ServiceFailureReason | null,
      failureFault?: ServiceFailureFault | null,
      isRescheduleRequired?: boolean | null,
      serviceType: ServiceType,
      status?: ServiceStatus | null,
      commentsFromCareNavigatorAboutServiceFailure?: string | null,
      statusChangedAt?: string | null,
      serviceDetailJSON: string,
    } > | null,
    documents?:  Array< {
      __typename: "Document",
      type?: DocumentType | null,
      url?: string | null,
      format?: DocumentFormat | null,
      s3ObjectID?: string | null,
      uploadedAt?: number | null,
      // stringified JSON object as a set of additional data we want to store about documents (requisition number, lab report status, etc.)
      metadataJSON?: string | null,
    } > | null,
    observations?:  Array< {
      __typename: "Observation",
      id: string,
      // Per FHIR specifications, we should only have value or valueCoding
      value?: string | null,
      units?: string | null,
      referenceRange?: string | null,
      category: ObservationCategory,
      performerType: ObservationPerformerType,
      performerIdentifier?: string | null,
      observedAt?: string | null,
      bodySiteLaterality?: Laterality | null,
      bodySiteStructure?: BodyStructure | null,
      notes?: string | null,
      // Also called Abnormal Flag. A categorical assessment, providing a rough qualitative interpretation of the observation value.
      // http://hl7.org/fhir/valueset-observation-interpretation.html
      interpretationCode?: ObservationInterpretationCode | null,
      source?: ObservationSource | null,
    } > | null,
    onboardingQuestionsAndAnswers?:  Array< {
      __typename: "OnboardingQuestionAnswerPair",
      key: string,
      question: string,
      answer: string,
    } > | null,
    createdAt: string,
    id: string,
    updatedAt: string,
  } > | null,
};

export type RegisterPatientsInImageMoverMutationVariables = {
  input: RegisterPatientsInImageMoverInput,
};

export type RegisterPatientsInImageMoverMutation = {
  registerPatientsInImageMover?:  Array< {
    __typename: "Patient",
    id: string,
    csrPartnerOrganization?: PartnerOrganization | null,
    partnerOrganization?: PartnerOrganization | null,
    location?:  {
      __typename: "Location",
      satellitePhotoURL?: string | null,
      streetViewPhotoURL?: string | null,
    } | null,
    coordinatorID: string,
    medicareCardID?: string | null,
    imageMoverUserID?: string | null,
    externalID?: string | null,
    secondaryExternalID?: string | null,
    specimenLabelID?: string | null,
    labOrganizationPatientIDs?:  Array< {
      __typename: "LabOrganizationPatientID",
      labOrganization: LabOrganization,
      externalID: string,
      planNameForLabOrdering: string,
    } > | null,
    qtMedicalPatientID?: string | null,
    planName?: string | null,
    diabeticType?: DiabeticType | null,
    authorizedTechIDs?: Array< string > | null,
    isCoordinator: boolean,
    // Phone & email to use in place of the coordinators for messaging. Used primarily for patients whose appointments are booked for them by a partner's customer service representatives.
    phone?: string | null,
    email?: string | null,
    secondaryPhone?: string | null,
    consentToContactForAppointmentBySMS?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    consentToContactForAppointmentByEmail?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    name:  {
      __typename: "HumanName",
      use: NameUse,
      family?: string | null,
      given?: Array< string > | null,
      prefix?: HumanNamePrefix | null,
      suffix?: string | null,
    },
    caregiverName?:  {
      __typename: "HumanName",
      use: NameUse,
      family?: string | null,
      given?: Array< string > | null,
      prefix?: HumanNamePrefix | null,
      suffix?: string | null,
    } | null,
    birthdate?: string | null,
    sex?: Sex | null,
    gender?: Gender | null,
    ethnicity?: Ethnicity | null,
    races?: Array< Race > | null,
    passportNumber?: string | null,
    insuranceCardUploadFront?:  {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } | null,
    insuranceCardUploadBack?:  {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } | null,
    insuranceCardUploadFrontS3ObjectID?: string | null,
    insuranceCardUploadBackS3ObjectID?: string | null,
    medicareCardUploadFront?:  {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } | null,
    medicareCardUploadBack?:  {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } | null,
    medicareCardUploadFrontS3ObjectID?: string | null,
    medicareCardUploadBackS3ObjectID?: string | null,
    encounters?:  {
      __typename: "ModelEncounterConnection",
      nextToken?: string | null,
    } | null,
    coordinator:  {
      __typename: "Coordinator",
      id: string,
      authorizedTechIDs?: Array< string > | null,
      // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
      isCSR?: boolean | null,
      isActive?: boolean | null,
      managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
      authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
      phone?: string | null,
      email?: string | null,
      billingCustomerID?: string | null,
      outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
      stripeCustomerID?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    commentsFromTechsAboutEncounters?:  Array< {
      __typename: "CommentsFromTechAboutEncounter",
      encounterID: string,
      appointmentStart: string,
      comments?: string | null,
    } > | null,
    bloodCollectionDetailsFromEncounters?:  Array< {
      __typename: "SpecimenCollectionDetailsFromEncounter",
      encounterID: string,
      appointmentStart: string,
      failureReason?: ServiceFailureReason | null,
    } > | null,
    capillaryDrawCollectionDetailsFromEncounters?:  Array< {
      __typename: "SpecimenCollectionDetailsFromEncounter",
      encounterID: string,
      appointmentStart: string,
      failureReason?: ServiceFailureReason | null,
    } > | null,
    referrals?:  {
      __typename: "ModelReferralConnection",
      nextToken?: string | null,
    } | null,
    careNavigationEpisodes?:  {
      __typename: "ModelCareNavigationEpisodeConnection",
      nextToken?: string | null,
    } | null,
    campaigns?:  {
      __typename: "ModelPatientCampaignStateConnection",
      nextToken?: string | null,
    } | null,
    watchlistedAppointmentID?: string | null,
    watchlistedAppointment?:  {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    responsiblePractitionerID?: string | null,
    responsiblePractitioner?:  {
      __typename: "Practitioner",
      id: string,
      // national provider identifier
      NPI: string,
      practitionerGroupName?: string | null,
      phone?: string | null,
      email?: string | null,
      fax?: string | null,
      faxConfirmationStatus?: FaxConfirmationStatus | null,
      faxLastConfirmedAt?: string | null,
      testFaxLastSentAt?: string | null,
      incorrectFaxes?: Array< string > | null,
      role?: PractitionerRole | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
    commentsFromCaregiverAboutResponsiblePractitioner?: string | null,
    consentToContactForMarketingSMS?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    consentToContactForMarketingEmail?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    consentToContactForMarketingPhone?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    suggestedAppointmentLocation?:  {
      __typename: "Location",
      satellitePhotoURL?: string | null,
      streetViewPhotoURL?: string | null,
    } | null,
    suggestedAppointmentLocationAdditionalInformation?: string | null,
    mailingAddress?:  {
      __typename: "Address",
      use?: AddressUse | null,
      lines: Array< string >,
      subunit?: string | null,
      city: string,
      state: string,
      postalCode: string,
    } | null,
    preferredLanguage?: string | null,
    primaryLanguageBCP47?: string | null,
    secondaryLanguagesBCP47?: Array< string > | null,
    // A unique numerical patient reference number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
    patientNumber?: string | null,
    // latest validated gap list data referencing this patient.
    // - latestGapListUploadID: the upload ID of the latest ingestion for tracking purposes
    // - latestGapListRowJSON: the entire row
    // - latestGapListPassthroughJSON: a subset of the row for passing back to the partner with patient data in reports
    latestGapListUploadID?: string | null,
    latestGapListRowJSON?: string | null,
    latestGapListPassthroughJSON?: string | null,
    // A reference to a set of branding material provided for outreach.
    brandCode?: string | null,
    population?: PatientPopulation | null,
    elationID?: string | null,
    consents?:  Array< {
      __typename: "ConsentForm",
      consentedAt: string,
      type: ConsentType,
      platform: QuestionnairePlatform,
      formID: string,
      responseID: string,
      appointmentID: string,
      rawResponseJSON: string,
    } > | null,
    telecom?:  {
      __typename: "ModelPatientContactPointConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } > | null,
};

export type UpdatePatientInImageMoverMutationVariables = {
  input: UpdatePatientInImageMoverInput,
};

export type UpdatePatientInImageMoverMutation = {
  updatePatientInImageMover?:  {
    __typename: "Patient",
    id: string,
    csrPartnerOrganization?: PartnerOrganization | null,
    partnerOrganization?: PartnerOrganization | null,
    location?:  {
      __typename: "Location",
      satellitePhotoURL?: string | null,
      streetViewPhotoURL?: string | null,
    } | null,
    coordinatorID: string,
    medicareCardID?: string | null,
    imageMoverUserID?: string | null,
    externalID?: string | null,
    secondaryExternalID?: string | null,
    specimenLabelID?: string | null,
    labOrganizationPatientIDs?:  Array< {
      __typename: "LabOrganizationPatientID",
      labOrganization: LabOrganization,
      externalID: string,
      planNameForLabOrdering: string,
    } > | null,
    qtMedicalPatientID?: string | null,
    planName?: string | null,
    diabeticType?: DiabeticType | null,
    authorizedTechIDs?: Array< string > | null,
    isCoordinator: boolean,
    // Phone & email to use in place of the coordinators for messaging. Used primarily for patients whose appointments are booked for them by a partner's customer service representatives.
    phone?: string | null,
    email?: string | null,
    secondaryPhone?: string | null,
    consentToContactForAppointmentBySMS?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    consentToContactForAppointmentByEmail?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    name:  {
      __typename: "HumanName",
      use: NameUse,
      family?: string | null,
      given?: Array< string > | null,
      prefix?: HumanNamePrefix | null,
      suffix?: string | null,
    },
    caregiverName?:  {
      __typename: "HumanName",
      use: NameUse,
      family?: string | null,
      given?: Array< string > | null,
      prefix?: HumanNamePrefix | null,
      suffix?: string | null,
    } | null,
    birthdate?: string | null,
    sex?: Sex | null,
    gender?: Gender | null,
    ethnicity?: Ethnicity | null,
    races?: Array< Race > | null,
    passportNumber?: string | null,
    insuranceCardUploadFront?:  {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } | null,
    insuranceCardUploadBack?:  {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } | null,
    insuranceCardUploadFrontS3ObjectID?: string | null,
    insuranceCardUploadBackS3ObjectID?: string | null,
    medicareCardUploadFront?:  {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } | null,
    medicareCardUploadBack?:  {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } | null,
    medicareCardUploadFrontS3ObjectID?: string | null,
    medicareCardUploadBackS3ObjectID?: string | null,
    encounters?:  {
      __typename: "ModelEncounterConnection",
      nextToken?: string | null,
    } | null,
    coordinator:  {
      __typename: "Coordinator",
      id: string,
      authorizedTechIDs?: Array< string > | null,
      // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
      isCSR?: boolean | null,
      isActive?: boolean | null,
      managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
      authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
      phone?: string | null,
      email?: string | null,
      billingCustomerID?: string | null,
      outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
      stripeCustomerID?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    commentsFromTechsAboutEncounters?:  Array< {
      __typename: "CommentsFromTechAboutEncounter",
      encounterID: string,
      appointmentStart: string,
      comments?: string | null,
    } > | null,
    bloodCollectionDetailsFromEncounters?:  Array< {
      __typename: "SpecimenCollectionDetailsFromEncounter",
      encounterID: string,
      appointmentStart: string,
      failureReason?: ServiceFailureReason | null,
    } > | null,
    capillaryDrawCollectionDetailsFromEncounters?:  Array< {
      __typename: "SpecimenCollectionDetailsFromEncounter",
      encounterID: string,
      appointmentStart: string,
      failureReason?: ServiceFailureReason | null,
    } > | null,
    referrals?:  {
      __typename: "ModelReferralConnection",
      nextToken?: string | null,
    } | null,
    careNavigationEpisodes?:  {
      __typename: "ModelCareNavigationEpisodeConnection",
      nextToken?: string | null,
    } | null,
    campaigns?:  {
      __typename: "ModelPatientCampaignStateConnection",
      nextToken?: string | null,
    } | null,
    watchlistedAppointmentID?: string | null,
    watchlistedAppointment?:  {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    responsiblePractitionerID?: string | null,
    responsiblePractitioner?:  {
      __typename: "Practitioner",
      id: string,
      // national provider identifier
      NPI: string,
      practitionerGroupName?: string | null,
      phone?: string | null,
      email?: string | null,
      fax?: string | null,
      faxConfirmationStatus?: FaxConfirmationStatus | null,
      faxLastConfirmedAt?: string | null,
      testFaxLastSentAt?: string | null,
      incorrectFaxes?: Array< string > | null,
      role?: PractitionerRole | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
    commentsFromCaregiverAboutResponsiblePractitioner?: string | null,
    consentToContactForMarketingSMS?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    consentToContactForMarketingEmail?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    consentToContactForMarketingPhone?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    suggestedAppointmentLocation?:  {
      __typename: "Location",
      satellitePhotoURL?: string | null,
      streetViewPhotoURL?: string | null,
    } | null,
    suggestedAppointmentLocationAdditionalInformation?: string | null,
    mailingAddress?:  {
      __typename: "Address",
      use?: AddressUse | null,
      lines: Array< string >,
      subunit?: string | null,
      city: string,
      state: string,
      postalCode: string,
    } | null,
    preferredLanguage?: string | null,
    primaryLanguageBCP47?: string | null,
    secondaryLanguagesBCP47?: Array< string > | null,
    // A unique numerical patient reference number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
    patientNumber?: string | null,
    // latest validated gap list data referencing this patient.
    // - latestGapListUploadID: the upload ID of the latest ingestion for tracking purposes
    // - latestGapListRowJSON: the entire row
    // - latestGapListPassthroughJSON: a subset of the row for passing back to the partner with patient data in reports
    latestGapListUploadID?: string | null,
    latestGapListRowJSON?: string | null,
    latestGapListPassthroughJSON?: string | null,
    // A reference to a set of branding material provided for outreach.
    brandCode?: string | null,
    population?: PatientPopulation | null,
    elationID?: string | null,
    consents?:  Array< {
      __typename: "ConsentForm",
      consentedAt: string,
      type: ConsentType,
      platform: QuestionnairePlatform,
      formID: string,
      responseID: string,
      appointmentID: string,
      rawResponseJSON: string,
    } > | null,
    telecom?:  {
      __typename: "ModelPatientContactPointConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GenerateAppointmentChecklistMutationVariables = {
  input: GenerateChecklistInput,
};

export type GenerateAppointmentChecklistMutation = {
  generateAppointmentChecklist?:  {
    __typename: "Appointment",
    id: string,
    // The current status of the appointment (See AppointmentStatus enum)
    status: AppointmentStatus,
    // Whether the appointment has been marked failed
    isFailed?: boolean | null,
    // Whether the appointment has at least one service completed in any of the encounters
    hasSuccessfulServices?: boolean | null,
    // Whether the appointment has at least one service failed in any of the encounters
    hasFailedServices?: boolean | null,
    // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
    needsRebooking?: boolean | null,
    // Whether the appointment has passed the pre-review phase
    isPreReviewed?: boolean | null,
    // Whether the appointment has passed the post-review phase
    isPostReviewed?: boolean | null,
    // Whether the appointment has been escalated
    isEscalated?: boolean | null,
    // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
    start: string,
    // Expected appointment end date/time
    end: string,
    // The technician assigned to the appointment
    techID?: string | null,
    remoteTechID?: string | null,
    remoteTechMatchState?: RemoteTechMatchState | null,
    coordinatorID: string,
    location:  {
      __typename: "Location",
      satellitePhotoURL?: string | null,
      streetViewPhotoURL?: string | null,
    },
    partnerOrganization?: PartnerOrganization | null,
    preReviewChecklist?:  {
      __typename: "Checklist",
    } | null,
    checklist?:  {
      __typename: "Checklist",
    } | null,
    postReviewChecklist?:  {
      __typename: "Checklist",
    } | null,
    serviceRegionName: string,
    geographicalState?: string | null,
    avoidTechIDs?: Array< string > | null,
    lastBookedAt?: number | null,
    statusUpdatedAt?: number | null,
    stripeCustomerID?: string | null,
    // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
    stripePriceToCharge?: number | null,
    hasPetsAtLocation?: boolean | null,
    // Comments about logistics like door codes, directions, etc.
    commentsFromCoordinatorAboutAppointment?: string | null,
    // Comments about logistics like door codes, directions, etc. (but from techs)
    commentsFromTechAboutAppointment?: string | null,
    // Comments about app feedback, bugs, feature requests, etc.
    commentsFromTechAboutSystem?: string | null,
    // Comments about why an appointment was put into a failed state
    commentsFromTechAboutAppointmentFailure?: string | null,
    // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
    commentsFromSchedulerAboutAppointmentFailure?: string | null,
    // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
    commentsFromSchedulerAboutCancellation?: string | null,
    partnerOrganizationBookingURL?: string | null,
    encounters?:  {
      __typename: "ModelEncounterConnection",
      nextToken?: string | null,
    } | null,
    tech?:  {
      __typename: "Tech",
      id: string,
      gender?: Gender | null,
      phone?: string | null,
      email?: string | null,
      serviceTypes?: Array< ServiceType | null > | null,
      profilePictureS3ObjectID?: string | null,
      isActive?: boolean | null,
      biographyItems?: Array< string > | null,
      patientTestimonials?: Array< string > | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      capabilities?: Array< TechCapability > | null,
      isIneligibleForMatchmaking?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    remoteTech?:  {
      __typename: "RemoteTech",
      id: string,
      email?: string | null,
      phone?: string | null,
      profilePictureS3ObjectID?: string | null,
      timezone?: Timezone | null,
      isActive: boolean,
      remoteVisitZoomLink?: string | null,
      remoteVisitZoomLinkQRCodeS3ObjectID?: string | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    coordinator:  {
      __typename: "Coordinator",
      id: string,
      authorizedTechIDs?: Array< string > | null,
      // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
      isCSR?: boolean | null,
      isActive?: boolean | null,
      managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
      authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
      phone?: string | null,
      email?: string | null,
      billingCustomerID?: string | null,
      outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
      stripeCustomerID?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    overrideMode?: AppointmentOverrideMode | null,
    // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
    // The default value is 0.5.
    sharedSlotRank: number,
    sharedSlotAppointments?:  Array< {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } > | null,
    // Represents how long the appointment should take based on product types
    estimatedServiceDurationSeconds: number,
    // For invoiced partners, the invoice line items associated with this appointment.
    stripeInvoiceLineItemIDs?: Array< string | null > | null,
    // For patient-paid appointments, any payment attempts associated with this appointment.
    stripePaymentIntentIDs?: Array< string | null > | null,
    // Whether the appointment has been confirmed or if the patient requested a change
    // When undefined or null, it means that the confirmation is still pending
    hasPatientConfirmed?: boolean | null,
    // Whether or not the appointment was booked in an unauthenticated flow
    isGuestBooked?: boolean | null,
    // Referral information from which the appointment was created (optional)
    referralID?: string | null,
    referral?:  {
      __typename: "Referral",
      id: string,
      patientID: string,
      partnerOrganization: PartnerOrganization,
      requisitionUploadS3ObjectIDs?: Array< string > | null,
      serviceTypes: Array< ServiceType >,
      isBooked?: boolean | null,
      isExpired?: boolean | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
    historyJSON?: string | null,
    // An external ID associated with the appointment, such as a job ID from MPX
    externalID?: string | null,
    // The id of the appointment that this appointment is cloned from. Cloning includes information
    // such as location, patients, and requisitions that are carried over from the original.
    clonedFromAppointmentID?: string | null,
    // The type of qualification a remote tech needs to have for this appointment.
    // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
    // Not present if the appointment has no remote component.
    remoteTechQualificationTypeRequired?: QualificationType | null,
    // Appointment date/time added to "queue"
    // This timestamp is used to determine the ordering of when remoteTech should be assigned
    markedReadyForRemoteTechAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAppointmentChecklistMutationVariables = {
  input: UpdateChecklistInput,
};

export type UpdateAppointmentChecklistMutation = {
  updateAppointmentChecklist?:  {
    __typename: "Appointment",
    id: string,
    // The current status of the appointment (See AppointmentStatus enum)
    status: AppointmentStatus,
    // Whether the appointment has been marked failed
    isFailed?: boolean | null,
    // Whether the appointment has at least one service completed in any of the encounters
    hasSuccessfulServices?: boolean | null,
    // Whether the appointment has at least one service failed in any of the encounters
    hasFailedServices?: boolean | null,
    // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
    needsRebooking?: boolean | null,
    // Whether the appointment has passed the pre-review phase
    isPreReviewed?: boolean | null,
    // Whether the appointment has passed the post-review phase
    isPostReviewed?: boolean | null,
    // Whether the appointment has been escalated
    isEscalated?: boolean | null,
    // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
    start: string,
    // Expected appointment end date/time
    end: string,
    // The technician assigned to the appointment
    techID?: string | null,
    remoteTechID?: string | null,
    remoteTechMatchState?: RemoteTechMatchState | null,
    coordinatorID: string,
    location:  {
      __typename: "Location",
      satellitePhotoURL?: string | null,
      streetViewPhotoURL?: string | null,
    },
    partnerOrganization?: PartnerOrganization | null,
    preReviewChecklist?:  {
      __typename: "Checklist",
    } | null,
    checklist?:  {
      __typename: "Checklist",
    } | null,
    postReviewChecklist?:  {
      __typename: "Checklist",
    } | null,
    serviceRegionName: string,
    geographicalState?: string | null,
    avoidTechIDs?: Array< string > | null,
    lastBookedAt?: number | null,
    statusUpdatedAt?: number | null,
    stripeCustomerID?: string | null,
    // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
    stripePriceToCharge?: number | null,
    hasPetsAtLocation?: boolean | null,
    // Comments about logistics like door codes, directions, etc.
    commentsFromCoordinatorAboutAppointment?: string | null,
    // Comments about logistics like door codes, directions, etc. (but from techs)
    commentsFromTechAboutAppointment?: string | null,
    // Comments about app feedback, bugs, feature requests, etc.
    commentsFromTechAboutSystem?: string | null,
    // Comments about why an appointment was put into a failed state
    commentsFromTechAboutAppointmentFailure?: string | null,
    // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
    commentsFromSchedulerAboutAppointmentFailure?: string | null,
    // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
    commentsFromSchedulerAboutCancellation?: string | null,
    partnerOrganizationBookingURL?: string | null,
    encounters?:  {
      __typename: "ModelEncounterConnection",
      nextToken?: string | null,
    } | null,
    tech?:  {
      __typename: "Tech",
      id: string,
      gender?: Gender | null,
      phone?: string | null,
      email?: string | null,
      serviceTypes?: Array< ServiceType | null > | null,
      profilePictureS3ObjectID?: string | null,
      isActive?: boolean | null,
      biographyItems?: Array< string > | null,
      patientTestimonials?: Array< string > | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      capabilities?: Array< TechCapability > | null,
      isIneligibleForMatchmaking?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    remoteTech?:  {
      __typename: "RemoteTech",
      id: string,
      email?: string | null,
      phone?: string | null,
      profilePictureS3ObjectID?: string | null,
      timezone?: Timezone | null,
      isActive: boolean,
      remoteVisitZoomLink?: string | null,
      remoteVisitZoomLinkQRCodeS3ObjectID?: string | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    coordinator:  {
      __typename: "Coordinator",
      id: string,
      authorizedTechIDs?: Array< string > | null,
      // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
      isCSR?: boolean | null,
      isActive?: boolean | null,
      managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
      authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
      phone?: string | null,
      email?: string | null,
      billingCustomerID?: string | null,
      outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
      stripeCustomerID?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    overrideMode?: AppointmentOverrideMode | null,
    // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
    // The default value is 0.5.
    sharedSlotRank: number,
    sharedSlotAppointments?:  Array< {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } > | null,
    // Represents how long the appointment should take based on product types
    estimatedServiceDurationSeconds: number,
    // For invoiced partners, the invoice line items associated with this appointment.
    stripeInvoiceLineItemIDs?: Array< string | null > | null,
    // For patient-paid appointments, any payment attempts associated with this appointment.
    stripePaymentIntentIDs?: Array< string | null > | null,
    // Whether the appointment has been confirmed or if the patient requested a change
    // When undefined or null, it means that the confirmation is still pending
    hasPatientConfirmed?: boolean | null,
    // Whether or not the appointment was booked in an unauthenticated flow
    isGuestBooked?: boolean | null,
    // Referral information from which the appointment was created (optional)
    referralID?: string | null,
    referral?:  {
      __typename: "Referral",
      id: string,
      patientID: string,
      partnerOrganization: PartnerOrganization,
      requisitionUploadS3ObjectIDs?: Array< string > | null,
      serviceTypes: Array< ServiceType >,
      isBooked?: boolean | null,
      isExpired?: boolean | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
    historyJSON?: string | null,
    // An external ID associated with the appointment, such as a job ID from MPX
    externalID?: string | null,
    // The id of the appointment that this appointment is cloned from. Cloning includes information
    // such as location, patients, and requisitions that are carried over from the original.
    clonedFromAppointmentID?: string | null,
    // The type of qualification a remote tech needs to have for this appointment.
    // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
    // Not present if the appointment has no remote component.
    remoteTechQualificationTypeRequired?: QualificationType | null,
    // Appointment date/time added to "queue"
    // This timestamp is used to determine the ordering of when remoteTech should be assigned
    markedReadyForRemoteTechAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GeneratePreReviewChecklistMutationVariables = {
  input: GenerateChecklistInput,
};

export type GeneratePreReviewChecklistMutation = {
  generatePreReviewChecklist?:  {
    __typename: "Appointment",
    id: string,
    // The current status of the appointment (See AppointmentStatus enum)
    status: AppointmentStatus,
    // Whether the appointment has been marked failed
    isFailed?: boolean | null,
    // Whether the appointment has at least one service completed in any of the encounters
    hasSuccessfulServices?: boolean | null,
    // Whether the appointment has at least one service failed in any of the encounters
    hasFailedServices?: boolean | null,
    // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
    needsRebooking?: boolean | null,
    // Whether the appointment has passed the pre-review phase
    isPreReviewed?: boolean | null,
    // Whether the appointment has passed the post-review phase
    isPostReviewed?: boolean | null,
    // Whether the appointment has been escalated
    isEscalated?: boolean | null,
    // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
    start: string,
    // Expected appointment end date/time
    end: string,
    // The technician assigned to the appointment
    techID?: string | null,
    remoteTechID?: string | null,
    remoteTechMatchState?: RemoteTechMatchState | null,
    coordinatorID: string,
    location:  {
      __typename: "Location",
      satellitePhotoURL?: string | null,
      streetViewPhotoURL?: string | null,
    },
    partnerOrganization?: PartnerOrganization | null,
    preReviewChecklist?:  {
      __typename: "Checklist",
    } | null,
    checklist?:  {
      __typename: "Checklist",
    } | null,
    postReviewChecklist?:  {
      __typename: "Checklist",
    } | null,
    serviceRegionName: string,
    geographicalState?: string | null,
    avoidTechIDs?: Array< string > | null,
    lastBookedAt?: number | null,
    statusUpdatedAt?: number | null,
    stripeCustomerID?: string | null,
    // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
    stripePriceToCharge?: number | null,
    hasPetsAtLocation?: boolean | null,
    // Comments about logistics like door codes, directions, etc.
    commentsFromCoordinatorAboutAppointment?: string | null,
    // Comments about logistics like door codes, directions, etc. (but from techs)
    commentsFromTechAboutAppointment?: string | null,
    // Comments about app feedback, bugs, feature requests, etc.
    commentsFromTechAboutSystem?: string | null,
    // Comments about why an appointment was put into a failed state
    commentsFromTechAboutAppointmentFailure?: string | null,
    // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
    commentsFromSchedulerAboutAppointmentFailure?: string | null,
    // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
    commentsFromSchedulerAboutCancellation?: string | null,
    partnerOrganizationBookingURL?: string | null,
    encounters?:  {
      __typename: "ModelEncounterConnection",
      nextToken?: string | null,
    } | null,
    tech?:  {
      __typename: "Tech",
      id: string,
      gender?: Gender | null,
      phone?: string | null,
      email?: string | null,
      serviceTypes?: Array< ServiceType | null > | null,
      profilePictureS3ObjectID?: string | null,
      isActive?: boolean | null,
      biographyItems?: Array< string > | null,
      patientTestimonials?: Array< string > | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      capabilities?: Array< TechCapability > | null,
      isIneligibleForMatchmaking?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    remoteTech?:  {
      __typename: "RemoteTech",
      id: string,
      email?: string | null,
      phone?: string | null,
      profilePictureS3ObjectID?: string | null,
      timezone?: Timezone | null,
      isActive: boolean,
      remoteVisitZoomLink?: string | null,
      remoteVisitZoomLinkQRCodeS3ObjectID?: string | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    coordinator:  {
      __typename: "Coordinator",
      id: string,
      authorizedTechIDs?: Array< string > | null,
      // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
      isCSR?: boolean | null,
      isActive?: boolean | null,
      managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
      authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
      phone?: string | null,
      email?: string | null,
      billingCustomerID?: string | null,
      outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
      stripeCustomerID?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    overrideMode?: AppointmentOverrideMode | null,
    // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
    // The default value is 0.5.
    sharedSlotRank: number,
    sharedSlotAppointments?:  Array< {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } > | null,
    // Represents how long the appointment should take based on product types
    estimatedServiceDurationSeconds: number,
    // For invoiced partners, the invoice line items associated with this appointment.
    stripeInvoiceLineItemIDs?: Array< string | null > | null,
    // For patient-paid appointments, any payment attempts associated with this appointment.
    stripePaymentIntentIDs?: Array< string | null > | null,
    // Whether the appointment has been confirmed or if the patient requested a change
    // When undefined or null, it means that the confirmation is still pending
    hasPatientConfirmed?: boolean | null,
    // Whether or not the appointment was booked in an unauthenticated flow
    isGuestBooked?: boolean | null,
    // Referral information from which the appointment was created (optional)
    referralID?: string | null,
    referral?:  {
      __typename: "Referral",
      id: string,
      patientID: string,
      partnerOrganization: PartnerOrganization,
      requisitionUploadS3ObjectIDs?: Array< string > | null,
      serviceTypes: Array< ServiceType >,
      isBooked?: boolean | null,
      isExpired?: boolean | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
    historyJSON?: string | null,
    // An external ID associated with the appointment, such as a job ID from MPX
    externalID?: string | null,
    // The id of the appointment that this appointment is cloned from. Cloning includes information
    // such as location, patients, and requisitions that are carried over from the original.
    clonedFromAppointmentID?: string | null,
    // The type of qualification a remote tech needs to have for this appointment.
    // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
    // Not present if the appointment has no remote component.
    remoteTechQualificationTypeRequired?: QualificationType | null,
    // Appointment date/time added to "queue"
    // This timestamp is used to determine the ordering of when remoteTech should be assigned
    markedReadyForRemoteTechAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePreReviewChecklistMutationVariables = {
  input: UpdateChecklistInput,
};

export type UpdatePreReviewChecklistMutation = {
  updatePreReviewChecklist?:  {
    __typename: "Appointment",
    id: string,
    // The current status of the appointment (See AppointmentStatus enum)
    status: AppointmentStatus,
    // Whether the appointment has been marked failed
    isFailed?: boolean | null,
    // Whether the appointment has at least one service completed in any of the encounters
    hasSuccessfulServices?: boolean | null,
    // Whether the appointment has at least one service failed in any of the encounters
    hasFailedServices?: boolean | null,
    // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
    needsRebooking?: boolean | null,
    // Whether the appointment has passed the pre-review phase
    isPreReviewed?: boolean | null,
    // Whether the appointment has passed the post-review phase
    isPostReviewed?: boolean | null,
    // Whether the appointment has been escalated
    isEscalated?: boolean | null,
    // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
    start: string,
    // Expected appointment end date/time
    end: string,
    // The technician assigned to the appointment
    techID?: string | null,
    remoteTechID?: string | null,
    remoteTechMatchState?: RemoteTechMatchState | null,
    coordinatorID: string,
    location:  {
      __typename: "Location",
      satellitePhotoURL?: string | null,
      streetViewPhotoURL?: string | null,
    },
    partnerOrganization?: PartnerOrganization | null,
    preReviewChecklist?:  {
      __typename: "Checklist",
    } | null,
    checklist?:  {
      __typename: "Checklist",
    } | null,
    postReviewChecklist?:  {
      __typename: "Checklist",
    } | null,
    serviceRegionName: string,
    geographicalState?: string | null,
    avoidTechIDs?: Array< string > | null,
    lastBookedAt?: number | null,
    statusUpdatedAt?: number | null,
    stripeCustomerID?: string | null,
    // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
    stripePriceToCharge?: number | null,
    hasPetsAtLocation?: boolean | null,
    // Comments about logistics like door codes, directions, etc.
    commentsFromCoordinatorAboutAppointment?: string | null,
    // Comments about logistics like door codes, directions, etc. (but from techs)
    commentsFromTechAboutAppointment?: string | null,
    // Comments about app feedback, bugs, feature requests, etc.
    commentsFromTechAboutSystem?: string | null,
    // Comments about why an appointment was put into a failed state
    commentsFromTechAboutAppointmentFailure?: string | null,
    // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
    commentsFromSchedulerAboutAppointmentFailure?: string | null,
    // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
    commentsFromSchedulerAboutCancellation?: string | null,
    partnerOrganizationBookingURL?: string | null,
    encounters?:  {
      __typename: "ModelEncounterConnection",
      nextToken?: string | null,
    } | null,
    tech?:  {
      __typename: "Tech",
      id: string,
      gender?: Gender | null,
      phone?: string | null,
      email?: string | null,
      serviceTypes?: Array< ServiceType | null > | null,
      profilePictureS3ObjectID?: string | null,
      isActive?: boolean | null,
      biographyItems?: Array< string > | null,
      patientTestimonials?: Array< string > | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      capabilities?: Array< TechCapability > | null,
      isIneligibleForMatchmaking?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    remoteTech?:  {
      __typename: "RemoteTech",
      id: string,
      email?: string | null,
      phone?: string | null,
      profilePictureS3ObjectID?: string | null,
      timezone?: Timezone | null,
      isActive: boolean,
      remoteVisitZoomLink?: string | null,
      remoteVisitZoomLinkQRCodeS3ObjectID?: string | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    coordinator:  {
      __typename: "Coordinator",
      id: string,
      authorizedTechIDs?: Array< string > | null,
      // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
      isCSR?: boolean | null,
      isActive?: boolean | null,
      managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
      authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
      phone?: string | null,
      email?: string | null,
      billingCustomerID?: string | null,
      outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
      stripeCustomerID?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    overrideMode?: AppointmentOverrideMode | null,
    // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
    // The default value is 0.5.
    sharedSlotRank: number,
    sharedSlotAppointments?:  Array< {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } > | null,
    // Represents how long the appointment should take based on product types
    estimatedServiceDurationSeconds: number,
    // For invoiced partners, the invoice line items associated with this appointment.
    stripeInvoiceLineItemIDs?: Array< string | null > | null,
    // For patient-paid appointments, any payment attempts associated with this appointment.
    stripePaymentIntentIDs?: Array< string | null > | null,
    // Whether the appointment has been confirmed or if the patient requested a change
    // When undefined or null, it means that the confirmation is still pending
    hasPatientConfirmed?: boolean | null,
    // Whether or not the appointment was booked in an unauthenticated flow
    isGuestBooked?: boolean | null,
    // Referral information from which the appointment was created (optional)
    referralID?: string | null,
    referral?:  {
      __typename: "Referral",
      id: string,
      patientID: string,
      partnerOrganization: PartnerOrganization,
      requisitionUploadS3ObjectIDs?: Array< string > | null,
      serviceTypes: Array< ServiceType >,
      isBooked?: boolean | null,
      isExpired?: boolean | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
    historyJSON?: string | null,
    // An external ID associated with the appointment, such as a job ID from MPX
    externalID?: string | null,
    // The id of the appointment that this appointment is cloned from. Cloning includes information
    // such as location, patients, and requisitions that are carried over from the original.
    clonedFromAppointmentID?: string | null,
    // The type of qualification a remote tech needs to have for this appointment.
    // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
    // Not present if the appointment has no remote component.
    remoteTechQualificationTypeRequired?: QualificationType | null,
    // Appointment date/time added to "queue"
    // This timestamp is used to determine the ordering of when remoteTech should be assigned
    markedReadyForRemoteTechAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GeneratePostReviewChecklistMutationVariables = {
  input: GenerateChecklistInput,
};

export type GeneratePostReviewChecklistMutation = {
  generatePostReviewChecklist?:  {
    __typename: "Appointment",
    id: string,
    // The current status of the appointment (See AppointmentStatus enum)
    status: AppointmentStatus,
    // Whether the appointment has been marked failed
    isFailed?: boolean | null,
    // Whether the appointment has at least one service completed in any of the encounters
    hasSuccessfulServices?: boolean | null,
    // Whether the appointment has at least one service failed in any of the encounters
    hasFailedServices?: boolean | null,
    // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
    needsRebooking?: boolean | null,
    // Whether the appointment has passed the pre-review phase
    isPreReviewed?: boolean | null,
    // Whether the appointment has passed the post-review phase
    isPostReviewed?: boolean | null,
    // Whether the appointment has been escalated
    isEscalated?: boolean | null,
    // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
    start: string,
    // Expected appointment end date/time
    end: string,
    // The technician assigned to the appointment
    techID?: string | null,
    remoteTechID?: string | null,
    remoteTechMatchState?: RemoteTechMatchState | null,
    coordinatorID: string,
    location:  {
      __typename: "Location",
      satellitePhotoURL?: string | null,
      streetViewPhotoURL?: string | null,
    },
    partnerOrganization?: PartnerOrganization | null,
    preReviewChecklist?:  {
      __typename: "Checklist",
    } | null,
    checklist?:  {
      __typename: "Checklist",
    } | null,
    postReviewChecklist?:  {
      __typename: "Checklist",
    } | null,
    serviceRegionName: string,
    geographicalState?: string | null,
    avoidTechIDs?: Array< string > | null,
    lastBookedAt?: number | null,
    statusUpdatedAt?: number | null,
    stripeCustomerID?: string | null,
    // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
    stripePriceToCharge?: number | null,
    hasPetsAtLocation?: boolean | null,
    // Comments about logistics like door codes, directions, etc.
    commentsFromCoordinatorAboutAppointment?: string | null,
    // Comments about logistics like door codes, directions, etc. (but from techs)
    commentsFromTechAboutAppointment?: string | null,
    // Comments about app feedback, bugs, feature requests, etc.
    commentsFromTechAboutSystem?: string | null,
    // Comments about why an appointment was put into a failed state
    commentsFromTechAboutAppointmentFailure?: string | null,
    // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
    commentsFromSchedulerAboutAppointmentFailure?: string | null,
    // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
    commentsFromSchedulerAboutCancellation?: string | null,
    partnerOrganizationBookingURL?: string | null,
    encounters?:  {
      __typename: "ModelEncounterConnection",
      nextToken?: string | null,
    } | null,
    tech?:  {
      __typename: "Tech",
      id: string,
      gender?: Gender | null,
      phone?: string | null,
      email?: string | null,
      serviceTypes?: Array< ServiceType | null > | null,
      profilePictureS3ObjectID?: string | null,
      isActive?: boolean | null,
      biographyItems?: Array< string > | null,
      patientTestimonials?: Array< string > | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      capabilities?: Array< TechCapability > | null,
      isIneligibleForMatchmaking?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    remoteTech?:  {
      __typename: "RemoteTech",
      id: string,
      email?: string | null,
      phone?: string | null,
      profilePictureS3ObjectID?: string | null,
      timezone?: Timezone | null,
      isActive: boolean,
      remoteVisitZoomLink?: string | null,
      remoteVisitZoomLinkQRCodeS3ObjectID?: string | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    coordinator:  {
      __typename: "Coordinator",
      id: string,
      authorizedTechIDs?: Array< string > | null,
      // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
      isCSR?: boolean | null,
      isActive?: boolean | null,
      managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
      authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
      phone?: string | null,
      email?: string | null,
      billingCustomerID?: string | null,
      outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
      stripeCustomerID?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    overrideMode?: AppointmentOverrideMode | null,
    // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
    // The default value is 0.5.
    sharedSlotRank: number,
    sharedSlotAppointments?:  Array< {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } > | null,
    // Represents how long the appointment should take based on product types
    estimatedServiceDurationSeconds: number,
    // For invoiced partners, the invoice line items associated with this appointment.
    stripeInvoiceLineItemIDs?: Array< string | null > | null,
    // For patient-paid appointments, any payment attempts associated with this appointment.
    stripePaymentIntentIDs?: Array< string | null > | null,
    // Whether the appointment has been confirmed or if the patient requested a change
    // When undefined or null, it means that the confirmation is still pending
    hasPatientConfirmed?: boolean | null,
    // Whether or not the appointment was booked in an unauthenticated flow
    isGuestBooked?: boolean | null,
    // Referral information from which the appointment was created (optional)
    referralID?: string | null,
    referral?:  {
      __typename: "Referral",
      id: string,
      patientID: string,
      partnerOrganization: PartnerOrganization,
      requisitionUploadS3ObjectIDs?: Array< string > | null,
      serviceTypes: Array< ServiceType >,
      isBooked?: boolean | null,
      isExpired?: boolean | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
    historyJSON?: string | null,
    // An external ID associated with the appointment, such as a job ID from MPX
    externalID?: string | null,
    // The id of the appointment that this appointment is cloned from. Cloning includes information
    // such as location, patients, and requisitions that are carried over from the original.
    clonedFromAppointmentID?: string | null,
    // The type of qualification a remote tech needs to have for this appointment.
    // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
    // Not present if the appointment has no remote component.
    remoteTechQualificationTypeRequired?: QualificationType | null,
    // Appointment date/time added to "queue"
    // This timestamp is used to determine the ordering of when remoteTech should be assigned
    markedReadyForRemoteTechAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePostReviewChecklistMutationVariables = {
  input: UpdateChecklistInput,
};

export type UpdatePostReviewChecklistMutation = {
  updatePostReviewChecklist?:  {
    __typename: "Appointment",
    id: string,
    // The current status of the appointment (See AppointmentStatus enum)
    status: AppointmentStatus,
    // Whether the appointment has been marked failed
    isFailed?: boolean | null,
    // Whether the appointment has at least one service completed in any of the encounters
    hasSuccessfulServices?: boolean | null,
    // Whether the appointment has at least one service failed in any of the encounters
    hasFailedServices?: boolean | null,
    // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
    needsRebooking?: boolean | null,
    // Whether the appointment has passed the pre-review phase
    isPreReviewed?: boolean | null,
    // Whether the appointment has passed the post-review phase
    isPostReviewed?: boolean | null,
    // Whether the appointment has been escalated
    isEscalated?: boolean | null,
    // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
    start: string,
    // Expected appointment end date/time
    end: string,
    // The technician assigned to the appointment
    techID?: string | null,
    remoteTechID?: string | null,
    remoteTechMatchState?: RemoteTechMatchState | null,
    coordinatorID: string,
    location:  {
      __typename: "Location",
      satellitePhotoURL?: string | null,
      streetViewPhotoURL?: string | null,
    },
    partnerOrganization?: PartnerOrganization | null,
    preReviewChecklist?:  {
      __typename: "Checklist",
    } | null,
    checklist?:  {
      __typename: "Checklist",
    } | null,
    postReviewChecklist?:  {
      __typename: "Checklist",
    } | null,
    serviceRegionName: string,
    geographicalState?: string | null,
    avoidTechIDs?: Array< string > | null,
    lastBookedAt?: number | null,
    statusUpdatedAt?: number | null,
    stripeCustomerID?: string | null,
    // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
    stripePriceToCharge?: number | null,
    hasPetsAtLocation?: boolean | null,
    // Comments about logistics like door codes, directions, etc.
    commentsFromCoordinatorAboutAppointment?: string | null,
    // Comments about logistics like door codes, directions, etc. (but from techs)
    commentsFromTechAboutAppointment?: string | null,
    // Comments about app feedback, bugs, feature requests, etc.
    commentsFromTechAboutSystem?: string | null,
    // Comments about why an appointment was put into a failed state
    commentsFromTechAboutAppointmentFailure?: string | null,
    // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
    commentsFromSchedulerAboutAppointmentFailure?: string | null,
    // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
    commentsFromSchedulerAboutCancellation?: string | null,
    partnerOrganizationBookingURL?: string | null,
    encounters?:  {
      __typename: "ModelEncounterConnection",
      nextToken?: string | null,
    } | null,
    tech?:  {
      __typename: "Tech",
      id: string,
      gender?: Gender | null,
      phone?: string | null,
      email?: string | null,
      serviceTypes?: Array< ServiceType | null > | null,
      profilePictureS3ObjectID?: string | null,
      isActive?: boolean | null,
      biographyItems?: Array< string > | null,
      patientTestimonials?: Array< string > | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      capabilities?: Array< TechCapability > | null,
      isIneligibleForMatchmaking?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    remoteTech?:  {
      __typename: "RemoteTech",
      id: string,
      email?: string | null,
      phone?: string | null,
      profilePictureS3ObjectID?: string | null,
      timezone?: Timezone | null,
      isActive: boolean,
      remoteVisitZoomLink?: string | null,
      remoteVisitZoomLinkQRCodeS3ObjectID?: string | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    coordinator:  {
      __typename: "Coordinator",
      id: string,
      authorizedTechIDs?: Array< string > | null,
      // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
      isCSR?: boolean | null,
      isActive?: boolean | null,
      managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
      authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
      phone?: string | null,
      email?: string | null,
      billingCustomerID?: string | null,
      outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
      stripeCustomerID?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    overrideMode?: AppointmentOverrideMode | null,
    // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
    // The default value is 0.5.
    sharedSlotRank: number,
    sharedSlotAppointments?:  Array< {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } > | null,
    // Represents how long the appointment should take based on product types
    estimatedServiceDurationSeconds: number,
    // For invoiced partners, the invoice line items associated with this appointment.
    stripeInvoiceLineItemIDs?: Array< string | null > | null,
    // For patient-paid appointments, any payment attempts associated with this appointment.
    stripePaymentIntentIDs?: Array< string | null > | null,
    // Whether the appointment has been confirmed or if the patient requested a change
    // When undefined or null, it means that the confirmation is still pending
    hasPatientConfirmed?: boolean | null,
    // Whether or not the appointment was booked in an unauthenticated flow
    isGuestBooked?: boolean | null,
    // Referral information from which the appointment was created (optional)
    referralID?: string | null,
    referral?:  {
      __typename: "Referral",
      id: string,
      patientID: string,
      partnerOrganization: PartnerOrganization,
      requisitionUploadS3ObjectIDs?: Array< string > | null,
      serviceTypes: Array< ServiceType >,
      isBooked?: boolean | null,
      isExpired?: boolean | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
    historyJSON?: string | null,
    // An external ID associated with the appointment, such as a job ID from MPX
    externalID?: string | null,
    // The id of the appointment that this appointment is cloned from. Cloning includes information
    // such as location, patients, and requisitions that are carried over from the original.
    clonedFromAppointmentID?: string | null,
    // The type of qualification a remote tech needs to have for this appointment.
    // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
    // Not present if the appointment has no remote component.
    remoteTechQualificationTypeRequired?: QualificationType | null,
    // Appointment date/time added to "queue"
    // This timestamp is used to determine the ordering of when remoteTech should be assigned
    markedReadyForRemoteTechAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTechAvailabilitiesMutationVariables = {
  input: UpdateTechAvailabilitiesInput,
};

export type UpdateTechAvailabilitiesMutation = {
  updateTechAvailabilities?:  {
    __typename: "UpdateTechAvailabilitiesResponse",
    tech?:  {
      __typename: "Tech",
      id: string,
      gender?: Gender | null,
      phone?: string | null,
      email?: string | null,
      serviceTypes?: Array< ServiceType | null > | null,
      profilePictureS3ObjectID?: string | null,
      isActive?: boolean | null,
      biographyItems?: Array< string > | null,
      patientTestimonials?: Array< string > | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      capabilities?: Array< TechCapability > | null,
      isIneligibleForMatchmaking?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    conflictingAppointments?:  Array< {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } > | null,
  } | null,
};

export type UpdateEncounterRequisitionMutationVariables = {
  input: UpdateEncounterRequisitionInput,
};

export type UpdateEncounterRequisitionMutation = {
  updateEncounterRequisition?:  {
    __typename: "UpdateEncounterRequisitionResponse",
    appointment?:  {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    encounter?:  {
      __typename: "Encounter",
      id: string,
      appointmentID: string,
      patientID: string,
      coordinatorID: string,
      techID?: string | null,
      partnerOrganization?: PartnerOrganization | null,
      requisitionUploadS3ObjectIDs?: Array< string > | null,
      // Comments about patient info like medical/draw history, disabilities, etc.
      commentsFromCoordinatorAboutPatient?: string | null,
      // Comments about the clinical aspects of the encounter to be passed from scheduler to technician
      commentsFromSchedulerAboutEncounter?: string | null,
      // Comments about the clinical aspects of the encounter to be passed from scheduler to technician
      commentsFromSchedulerToTechAboutEncounter?: string | null,
      // Comments about the clinical aspects of the encounter and patient info not mentioned by the coordinator
      commentsFromTechAboutEncounter?: string | null,
      // Comments from customer support about actions taken on the encounter (e.g., services removed).
      commentsFromCustomerSupportAboutEncounter?: string | null,
      // Comments from the scheduler to the partner about actions taken on the encounter (e.g., services removed).
      commentsFromSchedulerToPartnerAboutEncounter?: string | null,
      productTypes?: Array< ProductType > | null,
      isMedicare?: boolean | null,
      isMedicareEligible?: boolean | null,
      wasPatientManuallyAdded?: boolean | null,
      patientEncounterSummaryLastSentAt?: string | null,
      cologuardOrderSuccessfullyPlacedAt?: string | null,
      hasPatientRequestedMailedResults?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type UpdateEncounterDocumentsMutationVariables = {
  documentType: DocumentType,
  encounterID?: string | null,
  documents: Array< S3ObjectInput >,
  documentIDs?: Array< string > | null,
};

export type UpdateEncounterDocumentsMutation = {
  updateEncounterDocuments?:  {
    __typename: "UpdateEncounterDocumentsResponse",
    appointment?:  {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    encounter?:  {
      __typename: "Encounter",
      id: string,
      appointmentID: string,
      patientID: string,
      coordinatorID: string,
      techID?: string | null,
      partnerOrganization?: PartnerOrganization | null,
      requisitionUploadS3ObjectIDs?: Array< string > | null,
      // Comments about patient info like medical/draw history, disabilities, etc.
      commentsFromCoordinatorAboutPatient?: string | null,
      // Comments about the clinical aspects of the encounter to be passed from scheduler to technician
      commentsFromSchedulerAboutEncounter?: string | null,
      // Comments about the clinical aspects of the encounter to be passed from scheduler to technician
      commentsFromSchedulerToTechAboutEncounter?: string | null,
      // Comments about the clinical aspects of the encounter and patient info not mentioned by the coordinator
      commentsFromTechAboutEncounter?: string | null,
      // Comments from customer support about actions taken on the encounter (e.g., services removed).
      commentsFromCustomerSupportAboutEncounter?: string | null,
      // Comments from the scheduler to the partner about actions taken on the encounter (e.g., services removed).
      commentsFromSchedulerToPartnerAboutEncounter?: string | null,
      productTypes?: Array< ProductType > | null,
      isMedicare?: boolean | null,
      isMedicareEligible?: boolean | null,
      wasPatientManuallyAdded?: boolean | null,
      patientEncounterSummaryLastSentAt?: string | null,
      cologuardOrderSuccessfullyPlacedAt?: string | null,
      hasPatientRequestedMailedResults?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type UpdateTechServiceRegionAvailabilitiesMutationVariables = {
  input: UpdateTechServiceRegionAvailabilitiesInput,
};

export type UpdateTechServiceRegionAvailabilitiesMutation = {
  updateTechServiceRegionAvailabilities?:  {
    __typename: "UpdateTechServiceRegionAvailabilitiesResponse",
    techServiceRegion?:  {
      __typename: "TechServiceRegion",
      id: string,
      techID: string,
      serviceRegionName: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    conflictingAppointments?:  Array< {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } > | null,
  } | null,
};

export type UpdateTechAllAvailabilitiesMutationVariables = {
  input: UpdateTechAllAvailabilitiesInput,
};

export type UpdateTechAllAvailabilitiesMutation = {
  updateTechAllAvailabilities?:  {
    __typename: "UpdateTechAllAvailabilitiesResponse",
    conflictingAppointments?:  Array< {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } > | null,
  } | null,
};

export type ConvertCoordinatorToPartnerCSRMutationVariables = {
  input: ConvertCoordinatorToPartnerCSRInput,
};

export type ConvertCoordinatorToPartnerCSRMutation = {
  convertCoordinatorToPartnerCSR?:  {
    __typename: "ConvertCoordinatorToPartnerCSRResponse",
    coordinator?:  {
      __typename: "Coordinator",
      id: string,
      authorizedTechIDs?: Array< string > | null,
      // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
      isCSR?: boolean | null,
      isActive?: boolean | null,
      managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
      authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
      phone?: string | null,
      email?: string | null,
      billingCustomerID?: string | null,
      outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
      stripeCustomerID?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type GenerateRequisitionsForPrintingMutationVariables = {
  input: GenerateRequisitionsForPrintingInput,
};

export type GenerateRequisitionsForPrintingMutation = {
  generateRequisitionsForPrinting?:  {
    __typename: "GenerateRequisitionsForPrintingResponse",
    output?:  {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } | null,
    url?: string | null,
  } | null,
};

export type GenerateEncounterSummaryMutationVariables = {
  input: GenerateEncounterSummaryInput,
};

export type GenerateEncounterSummaryMutation = {
  generateEncounterSummary?:  {
    __typename: "GenerateEncounterSummaryResponse",
    s3ObjectID: string,
    identityID: string,
    bucket?: string | null,
    key: string,
    type?: EncounterSummaryType | null,
  } | null,
};

export type GenerateTestFaxPDFMutationVariables = {
  input: GenerateTestFaxPDFInput,
};

export type GenerateTestFaxPDFMutation = {
  generateTestFaxPDF?:  {
    __typename: "GenerateTestFaxPDFResponse",
    output:  {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    },
  } | null,
};

export type MergeAppointmentsMutationVariables = {
  input: MergeAppointmentsInput,
};

export type MergeAppointmentsMutation = {
  mergeAppointments?:  {
    __typename: "MergeAppointmentsResponse",
    appointment?:  {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type GenerateMedicareBillingTemplateMutationVariables = {
  input: GenerateMedicareBillingTemplateInput,
};

export type GenerateMedicareBillingTemplateMutation = {
  generateMedicareBillingTemplate?:  {
    __typename: "GenerateMedicareBillingTemplateResponse",
    billingCSV: string,
  } | null,
};

export type RemovePatientFromAppointmentMutationVariables = {
  input: RemovePatientFromAppointmentInput,
};

export type RemovePatientFromAppointmentMutation = {
  removePatientFromAppointment?:  {
    __typename: "RemovePatientFromAppointmentResponse",
    appointment?:  {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type InviteUserToPartnerOrganizationMutationVariables = {
  input?: InviteUserToPartnerOrganizationInput | null,
};

export type InviteUserToPartnerOrganizationMutation = {
  inviteUserToPartnerOrganization?:  {
    __typename: "InviteUserToPartnerOrganizationResponse",
    invite?:  {
      __typename: "PartnerUserInvite",
      id: string,
      email: string,
      partnerOrganization: PartnerOrganization,
      status: PartnerUserInviteStatus,
      expiry: number,
      ttl?: number | null,
      managePartnerUsersAuthorization: ManagePartnerUsersAuthorization,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type ChangeUserAccountStatusOrPermissionMutationVariables = {
  input?: ChangeUserAccountStatusOrPermissionInput | null,
};

export type ChangeUserAccountStatusOrPermissionMutation = {
  changeUserAccountStatusOrPermission?:  {
    __typename: "ChangeUserAccountStatusOrPermissionResponse",
    coordinator:  {
      __typename: "Coordinator",
      id: string,
      authorizedTechIDs?: Array< string > | null,
      // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
      isCSR?: boolean | null,
      isActive?: boolean | null,
      managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
      authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
      phone?: string | null,
      email?: string | null,
      billingCustomerID?: string | null,
      outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
      stripeCustomerID?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type ExpirePartnerUserInviteMutationVariables = {
  input?: ExpirePartnerUserInviteInput | null,
};

export type ExpirePartnerUserInviteMutation = {
  expirePartnerUserInvite?:  {
    __typename: "ExpirePartnerUserInviteResponse",
    invite:  {
      __typename: "PartnerUserInvite",
      id: string,
      email: string,
      partnerOrganization: PartnerOrganization,
      status: PartnerUserInviteStatus,
      expiry: number,
      ttl?: number | null,
      managePartnerUsersAuthorization: ManagePartnerUsersAuthorization,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type UpdateContainerSpecimenVolumesMutationVariables = {
  input: UpdateContainerSpecimenVolumesInput,
};

export type UpdateContainerSpecimenVolumesMutation = {
  updateContainerSpecimenVolumes?:  {
    __typename: "UpdateContainerSpecimenVolumesResponse",
    id?: string | null,
  } | null,
};

export type CreateZendeskTicketMutationVariables = {
  input?: CreateZendeskTicketInput | null,
};

export type CreateZendeskTicketMutation = {
  createZendeskTicket?:  {
    __typename: "CreateZendeskTicketResponse",
    id?: number | null,
  } | null,
};

export type CreateReferralVerificationZendeskTicketMutationVariables = {
  input?: CreateReferralVerificationZendeskTicketInput | null,
};

export type CreateReferralVerificationZendeskTicketMutation = {
  createReferralVerificationZendeskTicket?:  {
    __typename: "CreateReferralVerificationZendeskTicketResponse",
    _?: number | null,
  } | null,
};

export type BookReferralMutationVariables = {
  input: BookReferralInput,
};

export type BookReferralMutation = {
  bookReferral?:  {
    __typename: "BookReferralResponse",
    appointment?:  {
      __typename: "CustomAuthPartialAppointment",
      id: string,
      serviceTypes: Array< ServiceType >,
      productTypes: Array< ProductType >,
      start: string,
      end: string,
      referralID?: string | null,
      status: AppointmentStatus,
      patientEncounterSummaryPresignedURL?: string | null,
    } | null,
  } | null,
};

export type CancelReferralBookingMutationVariables = {
  input: CancelReferralBookingInput,
};

export type CancelReferralBookingMutation = {
  cancelReferralBooking?:  {
    __typename: "CancelReferralBookingResponse",
    appointmentID?: string | null,
  } | null,
};

export type UpdateReferralPatientInformationMutationVariables = {
  input: UpdateReferralPatientInformationInput,
};

export type UpdateReferralPatientInformationMutation = {
  updateReferralPatientInformation?:  {
    __typename: "UpdateReferralPatientInformationMutationResponse",
    patientID: string,
    phone?: string | null,
    email?: string | null,
  } | null,
};

export type MarkFailedAllAppointmentServicesMutationVariables = {
  input: MarkFailedAllAppointmentServicesInput,
};

export type MarkFailedAllAppointmentServicesMutation = {
  markFailedAllAppointmentServices?:  {
    __typename: "Appointment",
    id: string,
    // The current status of the appointment (See AppointmentStatus enum)
    status: AppointmentStatus,
    // Whether the appointment has been marked failed
    isFailed?: boolean | null,
    // Whether the appointment has at least one service completed in any of the encounters
    hasSuccessfulServices?: boolean | null,
    // Whether the appointment has at least one service failed in any of the encounters
    hasFailedServices?: boolean | null,
    // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
    needsRebooking?: boolean | null,
    // Whether the appointment has passed the pre-review phase
    isPreReviewed?: boolean | null,
    // Whether the appointment has passed the post-review phase
    isPostReviewed?: boolean | null,
    // Whether the appointment has been escalated
    isEscalated?: boolean | null,
    // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
    start: string,
    // Expected appointment end date/time
    end: string,
    // The technician assigned to the appointment
    techID?: string | null,
    remoteTechID?: string | null,
    remoteTechMatchState?: RemoteTechMatchState | null,
    coordinatorID: string,
    location:  {
      __typename: "Location",
      satellitePhotoURL?: string | null,
      streetViewPhotoURL?: string | null,
    },
    partnerOrganization?: PartnerOrganization | null,
    preReviewChecklist?:  {
      __typename: "Checklist",
    } | null,
    checklist?:  {
      __typename: "Checklist",
    } | null,
    postReviewChecklist?:  {
      __typename: "Checklist",
    } | null,
    serviceRegionName: string,
    geographicalState?: string | null,
    avoidTechIDs?: Array< string > | null,
    lastBookedAt?: number | null,
    statusUpdatedAt?: number | null,
    stripeCustomerID?: string | null,
    // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
    stripePriceToCharge?: number | null,
    hasPetsAtLocation?: boolean | null,
    // Comments about logistics like door codes, directions, etc.
    commentsFromCoordinatorAboutAppointment?: string | null,
    // Comments about logistics like door codes, directions, etc. (but from techs)
    commentsFromTechAboutAppointment?: string | null,
    // Comments about app feedback, bugs, feature requests, etc.
    commentsFromTechAboutSystem?: string | null,
    // Comments about why an appointment was put into a failed state
    commentsFromTechAboutAppointmentFailure?: string | null,
    // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
    commentsFromSchedulerAboutAppointmentFailure?: string | null,
    // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
    commentsFromSchedulerAboutCancellation?: string | null,
    partnerOrganizationBookingURL?: string | null,
    encounters?:  {
      __typename: "ModelEncounterConnection",
      nextToken?: string | null,
    } | null,
    tech?:  {
      __typename: "Tech",
      id: string,
      gender?: Gender | null,
      phone?: string | null,
      email?: string | null,
      serviceTypes?: Array< ServiceType | null > | null,
      profilePictureS3ObjectID?: string | null,
      isActive?: boolean | null,
      biographyItems?: Array< string > | null,
      patientTestimonials?: Array< string > | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      capabilities?: Array< TechCapability > | null,
      isIneligibleForMatchmaking?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    remoteTech?:  {
      __typename: "RemoteTech",
      id: string,
      email?: string | null,
      phone?: string | null,
      profilePictureS3ObjectID?: string | null,
      timezone?: Timezone | null,
      isActive: boolean,
      remoteVisitZoomLink?: string | null,
      remoteVisitZoomLinkQRCodeS3ObjectID?: string | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    coordinator:  {
      __typename: "Coordinator",
      id: string,
      authorizedTechIDs?: Array< string > | null,
      // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
      isCSR?: boolean | null,
      isActive?: boolean | null,
      managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
      authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
      phone?: string | null,
      email?: string | null,
      billingCustomerID?: string | null,
      outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
      stripeCustomerID?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    overrideMode?: AppointmentOverrideMode | null,
    // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
    // The default value is 0.5.
    sharedSlotRank: number,
    sharedSlotAppointments?:  Array< {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } > | null,
    // Represents how long the appointment should take based on product types
    estimatedServiceDurationSeconds: number,
    // For invoiced partners, the invoice line items associated with this appointment.
    stripeInvoiceLineItemIDs?: Array< string | null > | null,
    // For patient-paid appointments, any payment attempts associated with this appointment.
    stripePaymentIntentIDs?: Array< string | null > | null,
    // Whether the appointment has been confirmed or if the patient requested a change
    // When undefined or null, it means that the confirmation is still pending
    hasPatientConfirmed?: boolean | null,
    // Whether or not the appointment was booked in an unauthenticated flow
    isGuestBooked?: boolean | null,
    // Referral information from which the appointment was created (optional)
    referralID?: string | null,
    referral?:  {
      __typename: "Referral",
      id: string,
      patientID: string,
      partnerOrganization: PartnerOrganization,
      requisitionUploadS3ObjectIDs?: Array< string > | null,
      serviceTypes: Array< ServiceType >,
      isBooked?: boolean | null,
      isExpired?: boolean | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
    historyJSON?: string | null,
    // An external ID associated with the appointment, such as a job ID from MPX
    externalID?: string | null,
    // The id of the appointment that this appointment is cloned from. Cloning includes information
    // such as location, patients, and requisitions that are carried over from the original.
    clonedFromAppointmentID?: string | null,
    // The type of qualification a remote tech needs to have for this appointment.
    // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
    // Not present if the appointment has no remote component.
    remoteTechQualificationTypeRequired?: QualificationType | null,
    // Appointment date/time added to "queue"
    // This timestamp is used to determine the ordering of when remoteTech should be assigned
    markedReadyForRemoteTechAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpsertPatientAndCreateReferralMutationVariables = {
  input: UpsertPatientAndCreateReferralInput,
};

export type UpsertPatientAndCreateReferralMutation = {
  upsertPatientAndCreateReferral:  {
    __typename: "UpsertPatientAndCreateReferralResponse",
    patient?:  {
      __typename: "Patient",
      id: string,
      csrPartnerOrganization?: PartnerOrganization | null,
      partnerOrganization?: PartnerOrganization | null,
      coordinatorID: string,
      medicareCardID?: string | null,
      imageMoverUserID?: string | null,
      externalID?: string | null,
      secondaryExternalID?: string | null,
      specimenLabelID?: string | null,
      qtMedicalPatientID?: string | null,
      planName?: string | null,
      diabeticType?: DiabeticType | null,
      authorizedTechIDs?: Array< string > | null,
      isCoordinator: boolean,
      // Phone & email to use in place of the coordinators for messaging. Used primarily for patients whose appointments are booked for them by a partner's customer service representatives.
      phone?: string | null,
      email?: string | null,
      secondaryPhone?: string | null,
      birthdate?: string | null,
      sex?: Sex | null,
      gender?: Gender | null,
      ethnicity?: Ethnicity | null,
      races?: Array< Race > | null,
      passportNumber?: string | null,
      insuranceCardUploadFrontS3ObjectID?: string | null,
      insuranceCardUploadBackS3ObjectID?: string | null,
      medicareCardUploadFrontS3ObjectID?: string | null,
      medicareCardUploadBackS3ObjectID?: string | null,
      watchlistedAppointmentID?: string | null,
      responsiblePractitionerID?: string | null,
      commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
      commentsFromCaregiverAboutResponsiblePractitioner?: string | null,
      suggestedAppointmentLocationAdditionalInformation?: string | null,
      preferredLanguage?: string | null,
      primaryLanguageBCP47?: string | null,
      secondaryLanguagesBCP47?: Array< string > | null,
      // A unique numerical patient reference number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
      patientNumber?: string | null,
      // latest validated gap list data referencing this patient.
      // - latestGapListUploadID: the upload ID of the latest ingestion for tracking purposes
      // - latestGapListRowJSON: the entire row
      // - latestGapListPassthroughJSON: a subset of the row for passing back to the partner with patient data in reports
      latestGapListUploadID?: string | null,
      latestGapListRowJSON?: string | null,
      latestGapListPassthroughJSON?: string | null,
      // A reference to a set of branding material provided for outreach.
      brandCode?: string | null,
      population?: PatientPopulation | null,
      elationID?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    referral?:  {
      __typename: "Referral",
      id: string,
      patientID: string,
      partnerOrganization: PartnerOrganization,
      requisitionUploadS3ObjectIDs?: Array< string > | null,
      serviceTypes: Array< ServiceType >,
      isBooked?: boolean | null,
      isExpired?: boolean | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  },
};

export type CreateServiceRequestMutationVariables = {
  input: CreateServiceRequestInput,
};

export type CreateServiceRequestMutation = {
  createServiceRequest?:  {
    __typename: "CreateServiceRequestResponse",
    serviceRequestTypeID: string,
  } | null,
};

export type OnboardTechMutationVariables = {
  input: OnboardTechInput,
};

export type OnboardTechMutation = {
  onboardTech?:  {
    __typename: "OnboardTechResponse",
    id: string,
  } | null,
};

export type UpsertPractitionersInZendeskMutationVariables = {
  input?: UpsertPractitionersInZendeskInput | null,
};

export type UpsertPractitionersInZendeskMutation = {
  upsertPractitionersInZendesk?:  {
    __typename: "UpsertPractitionersInZendeskResponse",
    updatedUsers?: number | null,
  } | null,
};

export type TriggerTestPatientCampaignStateUpdateMutationVariables = {
  input?: TriggerTestPatientCampaignStateUpdateInput | null,
};

export type TriggerTestPatientCampaignStateUpdateMutation = {
  triggerTestPatientCampaignStateUpdate?:  {
    __typename: "TriggerTestPatientCampaignStateUpdateResponse",
    result: string,
  } | null,
};

export type UnsubscribeFromEmailNotificationsMutationVariables = {
  input?: UnsubscribeFromEmailNotificationsInput | null,
};

export type UnsubscribeFromEmailNotificationsMutation = {
  unsubscribeFromEmailNotifications?:  {
    __typename: "UnsubscribeFromEmailNotificationsResponse",
    isUnsubscribed: boolean,
  } | null,
};

export type SendVerificationFaxesToPractitionersMutationVariables = {
  input?: SendPractitionersVerificationFaxesInput | null,
};

export type SendVerificationFaxesToPractitionersMutation = {
  sendVerificationFaxesToPractitioners?:  {
    __typename: "SendPractitionersVerificationFaxesResponse",
    totalNPI: number,
    totalPractitionersToSendTo: number,
    totalFaxesToSend: number,
    totalFaxesSent: number,
    totalPractitionersSent: number,
  } | null,
};

export type AutofillRequisitionFormMutationVariables = {
  input?: AutofillRequisitionFormInput | null,
};

export type AutofillRequisitionFormMutation = {
  autofillRequisitionForm?:  {
    __typename: "AutofillRequisitionFormResponse",
    encounter:  {
      __typename: "Encounter",
      id: string,
      appointmentID: string,
      patientID: string,
      coordinatorID: string,
      techID?: string | null,
      partnerOrganization?: PartnerOrganization | null,
      requisitionUploadS3ObjectIDs?: Array< string > | null,
      // Comments about patient info like medical/draw history, disabilities, etc.
      commentsFromCoordinatorAboutPatient?: string | null,
      // Comments about the clinical aspects of the encounter to be passed from scheduler to technician
      commentsFromSchedulerAboutEncounter?: string | null,
      // Comments about the clinical aspects of the encounter to be passed from scheduler to technician
      commentsFromSchedulerToTechAboutEncounter?: string | null,
      // Comments about the clinical aspects of the encounter and patient info not mentioned by the coordinator
      commentsFromTechAboutEncounter?: string | null,
      // Comments from customer support about actions taken on the encounter (e.g., services removed).
      commentsFromCustomerSupportAboutEncounter?: string | null,
      // Comments from the scheduler to the partner about actions taken on the encounter (e.g., services removed).
      commentsFromSchedulerToPartnerAboutEncounter?: string | null,
      productTypes?: Array< ProductType > | null,
      isMedicare?: boolean | null,
      isMedicareEligible?: boolean | null,
      wasPatientManuallyAdded?: boolean | null,
      patientEncounterSummaryLastSentAt?: string | null,
      cologuardOrderSuccessfullyPlacedAt?: string | null,
      hasPatientRequestedMailedResults?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    errors?: Array< string > | null,
  } | null,
};

export type EscalateEncounterMutationVariables = {
  id: string,
  triggeringObservation: ObservationInput,
};

export type EscalateEncounterMutation = {
  escalateEncounter?:  {
    __typename: "EscalateEncounterResponse",
    encounterID: string,
  } | null,
};

export type UpdateTechMutationVariables = {
  input: UpdateTechInput,
};

export type UpdateTechMutation = {
  updateTech?:  {
    __typename: "Tech",
    id: string,
    name:  {
      __typename: "HumanName",
      use: NameUse,
      family?: string | null,
      given?: Array< string > | null,
      prefix?: HumanNamePrefix | null,
      suffix?: string | null,
    },
    gender?: Gender | null,
    phone?: string | null,
    email?: string | null,
    vehicle?:  {
      __typename: "Vehicle",
      make: string,
      model: string,
      color: string,
    } | null,
    serviceTypes?: Array< ServiceType | null > | null,
    serviceTypesAvailabilityRules?:  Array< {
      __typename: "ServiceTypeAvailabilityRules",
      serviceType: ServiceType,
    } > | null,
    availabilityRules?:  Array< {
      __typename: "TimeRangeRule",
      // The unique ID for the time range.
      id: string,
      // Whether the time range represents an addition or subtraction when considering multiple ranges.
      inclusion: TimeRangeRuleInclusion,
      // The time of day each recurrence starts (inclusive).
      start: string,
      // The time of day each recurrence ends (exclusive). Use '24:00:00' for the end of the day.
      end: string,
      // The first date that the recurrence is valid (inclusive).
      recurrenceStart: string,
      // The last date that the recurrence is valid (inclusive). If not set, the range extends indefinitely.
      recurrenceEnd?: string | null,
      // The number of periods between each recurrence. For example, with a multiple of N and period of DAY, the time range will recur every N days.
      recurrenceMultiple: number,
      // The unit of recurrence. For example, with a period of WEEK and multiple of 2, the time range will recur every other week.
      recurrencePeriod: TimeRangeRulePeriod,
      // An optional reason for the time range rule, used with time-off unavailability rules (lunch breaks, PTO, etc.)
      unavailabilityReason?: TimeRangeRuleUnavailabilityReason | null,
      // Additional notes about the time range rule.
      notes?: string | null,
      // The date and time that the rule was last updated.
      updatedAt?: string | null,
      // The user who last updated the rule.
      updatedBy?: string | null,
    } > | null,
    locations?:  Array< {
      __typename: "Location",
      satellitePhotoURL?: string | null,
      streetViewPhotoURL?: string | null,
    } > | null,
    profilePicture?:  {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } | null,
    profilePictureS3ObjectID?: string | null,
    isActive?: boolean | null,
    serviceRegions?:  {
      __typename: "ModelTechServiceRegionConnection",
      nextToken?: string | null,
    } | null,
    appointments?:  {
      __typename: "ModelAppointmentConnection",
      nextToken?: string | null,
    } | null,
    biographyItems?: Array< string > | null,
    patientTestimonials?: Array< string > | null,
    partialAppointmentsForTeamAvailabilityJSON?: string | null,
    capabilities?: Array< TechCapability > | null,
    isIneligibleForMatchmaking?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UploadDNCListMutationVariables = {
  partnerOrganization: PartnerOrganization,
  driveURL: string,
  write: boolean,
};

export type UploadDNCListMutation = {
  uploadDNCList?:  {
    __typename: "UploadDNCListResponse",
    ok: boolean,
  } | null,
};

export type UploadPatientListMutationVariables = {
  partnerOrganization: PartnerOrganization,
  driveURL: string,
  patientListType: PatientListType,
  s3Filename?: string | null,
  skipColumns?: Array< string > | null,
  skipPatientExternalIDs?: Array< string > | null,
  campaignName?: CampaignName | null,
  write: boolean,
  verbose: boolean,
};

export type UploadPatientListMutation = {
  uploadPatientList?:  {
    __typename: "UploadPatientListResponse",
    resultsURL?: string | null,
  } | null,
};

export type PlaceLabOrderForEncounterMutationVariables = {
  encounterID?: string | null,
};

export type PlaceLabOrderForEncounterMutation = {
  placeLabOrderForEncounter?:  {
    __typename: "PlaceLabOrderForEncounterResponse",
    encounter?:  {
      __typename: "Encounter",
      id: string,
      appointmentID: string,
      patientID: string,
      coordinatorID: string,
      techID?: string | null,
      partnerOrganization?: PartnerOrganization | null,
      requisitionUploadS3ObjectIDs?: Array< string > | null,
      // Comments about patient info like medical/draw history, disabilities, etc.
      commentsFromCoordinatorAboutPatient?: string | null,
      // Comments about the clinical aspects of the encounter to be passed from scheduler to technician
      commentsFromSchedulerAboutEncounter?: string | null,
      // Comments about the clinical aspects of the encounter to be passed from scheduler to technician
      commentsFromSchedulerToTechAboutEncounter?: string | null,
      // Comments about the clinical aspects of the encounter and patient info not mentioned by the coordinator
      commentsFromTechAboutEncounter?: string | null,
      // Comments from customer support about actions taken on the encounter (e.g., services removed).
      commentsFromCustomerSupportAboutEncounter?: string | null,
      // Comments from the scheduler to the partner about actions taken on the encounter (e.g., services removed).
      commentsFromSchedulerToPartnerAboutEncounter?: string | null,
      productTypes?: Array< ProductType > | null,
      isMedicare?: boolean | null,
      isMedicareEligible?: boolean | null,
      wasPatientManuallyAdded?: boolean | null,
      patientEncounterSummaryLastSentAt?: string | null,
      cologuardOrderSuccessfullyPlacedAt?: string | null,
      hasPatientRequestedMailedResults?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    errors?: Array< string > | null,
  } | null,
};

export type TriggerPatientCampaignActionMutationVariables = {
  patientCampaignStateID: string,
  actionPropsJSON: string,
  expectedCurrentStateNameAfterUpdate?: PatientCampaignStateName | null,
  contextJSONPatch?: string | null,
};

export type TriggerPatientCampaignActionMutation = {
  triggerPatientCampaignAction?:  {
    __typename: "PatientCampaignState",
    id: string,
    patientID: string,
    patient:  {
      __typename: "Patient",
      id: string,
      csrPartnerOrganization?: PartnerOrganization | null,
      partnerOrganization?: PartnerOrganization | null,
      coordinatorID: string,
      medicareCardID?: string | null,
      imageMoverUserID?: string | null,
      externalID?: string | null,
      secondaryExternalID?: string | null,
      specimenLabelID?: string | null,
      qtMedicalPatientID?: string | null,
      planName?: string | null,
      diabeticType?: DiabeticType | null,
      authorizedTechIDs?: Array< string > | null,
      isCoordinator: boolean,
      // Phone & email to use in place of the coordinators for messaging. Used primarily for patients whose appointments are booked for them by a partner's customer service representatives.
      phone?: string | null,
      email?: string | null,
      secondaryPhone?: string | null,
      birthdate?: string | null,
      sex?: Sex | null,
      gender?: Gender | null,
      ethnicity?: Ethnicity | null,
      races?: Array< Race > | null,
      passportNumber?: string | null,
      insuranceCardUploadFrontS3ObjectID?: string | null,
      insuranceCardUploadBackS3ObjectID?: string | null,
      medicareCardUploadFrontS3ObjectID?: string | null,
      medicareCardUploadBackS3ObjectID?: string | null,
      watchlistedAppointmentID?: string | null,
      responsiblePractitionerID?: string | null,
      commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
      commentsFromCaregiverAboutResponsiblePractitioner?: string | null,
      suggestedAppointmentLocationAdditionalInformation?: string | null,
      preferredLanguage?: string | null,
      primaryLanguageBCP47?: string | null,
      secondaryLanguagesBCP47?: Array< string > | null,
      // A unique numerical patient reference number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
      patientNumber?: string | null,
      // latest validated gap list data referencing this patient.
      // - latestGapListUploadID: the upload ID of the latest ingestion for tracking purposes
      // - latestGapListRowJSON: the entire row
      // - latestGapListPassthroughJSON: a subset of the row for passing back to the partner with patient data in reports
      latestGapListUploadID?: string | null,
      latestGapListRowJSON?: string | null,
      latestGapListPassthroughJSON?: string | null,
      // A reference to a set of branding material provided for outreach.
      brandCode?: string | null,
      population?: PatientPopulation | null,
      elationID?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    name: PatientCampaignStateName,
    campaignName: CampaignName,
    eligibilityStart: string,
    eligibilityEnd: string,
    cohort?: number | null,
    contextJSON: string,
    enteredAt: number,
    partnerOrganization: PartnerOrganization,
    previousStateName?: PatientCampaignStateName | null,
    reasonForTransitionToTerminalState?: ReasonForTransitionToTerminalState | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GenerateQuestionnaireForEncounterMutationVariables = {
  questionnaireType: QuestionnaireType,
  patientID: string,
  encounterID: string,
};

export type GenerateQuestionnaireForEncounterMutation = {
  generateQuestionnaireForEncounter?:  {
    __typename: "GenerateQuestionnaireForEncounterResponse",
    questionnaire:  {
      __typename: "QuestionnaireEmbedded",
      type: QuestionnaireType,
      url: string,
      metadataJSON?: string | null,
    },
  } | null,
};

export type AssignRemoteTechMutationVariables = {
  appointmentID: string,
  remoteTechID: string,
};

export type AssignRemoteTechMutation = {
  assignRemoteTech?:  {
    __typename: "Appointment",
    id: string,
    // The current status of the appointment (See AppointmentStatus enum)
    status: AppointmentStatus,
    // Whether the appointment has been marked failed
    isFailed?: boolean | null,
    // Whether the appointment has at least one service completed in any of the encounters
    hasSuccessfulServices?: boolean | null,
    // Whether the appointment has at least one service failed in any of the encounters
    hasFailedServices?: boolean | null,
    // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
    needsRebooking?: boolean | null,
    // Whether the appointment has passed the pre-review phase
    isPreReviewed?: boolean | null,
    // Whether the appointment has passed the post-review phase
    isPostReviewed?: boolean | null,
    // Whether the appointment has been escalated
    isEscalated?: boolean | null,
    // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
    start: string,
    // Expected appointment end date/time
    end: string,
    // The technician assigned to the appointment
    techID?: string | null,
    remoteTechID?: string | null,
    remoteTechMatchState?: RemoteTechMatchState | null,
    coordinatorID: string,
    location:  {
      __typename: "Location",
      satellitePhotoURL?: string | null,
      streetViewPhotoURL?: string | null,
    },
    partnerOrganization?: PartnerOrganization | null,
    preReviewChecklist?:  {
      __typename: "Checklist",
    } | null,
    checklist?:  {
      __typename: "Checklist",
    } | null,
    postReviewChecklist?:  {
      __typename: "Checklist",
    } | null,
    serviceRegionName: string,
    geographicalState?: string | null,
    avoidTechIDs?: Array< string > | null,
    lastBookedAt?: number | null,
    statusUpdatedAt?: number | null,
    stripeCustomerID?: string | null,
    // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
    stripePriceToCharge?: number | null,
    hasPetsAtLocation?: boolean | null,
    // Comments about logistics like door codes, directions, etc.
    commentsFromCoordinatorAboutAppointment?: string | null,
    // Comments about logistics like door codes, directions, etc. (but from techs)
    commentsFromTechAboutAppointment?: string | null,
    // Comments about app feedback, bugs, feature requests, etc.
    commentsFromTechAboutSystem?: string | null,
    // Comments about why an appointment was put into a failed state
    commentsFromTechAboutAppointmentFailure?: string | null,
    // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
    commentsFromSchedulerAboutAppointmentFailure?: string | null,
    // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
    commentsFromSchedulerAboutCancellation?: string | null,
    partnerOrganizationBookingURL?: string | null,
    encounters?:  {
      __typename: "ModelEncounterConnection",
      nextToken?: string | null,
    } | null,
    tech?:  {
      __typename: "Tech",
      id: string,
      gender?: Gender | null,
      phone?: string | null,
      email?: string | null,
      serviceTypes?: Array< ServiceType | null > | null,
      profilePictureS3ObjectID?: string | null,
      isActive?: boolean | null,
      biographyItems?: Array< string > | null,
      patientTestimonials?: Array< string > | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      capabilities?: Array< TechCapability > | null,
      isIneligibleForMatchmaking?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    remoteTech?:  {
      __typename: "RemoteTech",
      id: string,
      email?: string | null,
      phone?: string | null,
      profilePictureS3ObjectID?: string | null,
      timezone?: Timezone | null,
      isActive: boolean,
      remoteVisitZoomLink?: string | null,
      remoteVisitZoomLinkQRCodeS3ObjectID?: string | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    coordinator:  {
      __typename: "Coordinator",
      id: string,
      authorizedTechIDs?: Array< string > | null,
      // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
      isCSR?: boolean | null,
      isActive?: boolean | null,
      managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
      authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
      phone?: string | null,
      email?: string | null,
      billingCustomerID?: string | null,
      outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
      stripeCustomerID?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    overrideMode?: AppointmentOverrideMode | null,
    // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
    // The default value is 0.5.
    sharedSlotRank: number,
    sharedSlotAppointments?:  Array< {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } > | null,
    // Represents how long the appointment should take based on product types
    estimatedServiceDurationSeconds: number,
    // For invoiced partners, the invoice line items associated with this appointment.
    stripeInvoiceLineItemIDs?: Array< string | null > | null,
    // For patient-paid appointments, any payment attempts associated with this appointment.
    stripePaymentIntentIDs?: Array< string | null > | null,
    // Whether the appointment has been confirmed or if the patient requested a change
    // When undefined or null, it means that the confirmation is still pending
    hasPatientConfirmed?: boolean | null,
    // Whether or not the appointment was booked in an unauthenticated flow
    isGuestBooked?: boolean | null,
    // Referral information from which the appointment was created (optional)
    referralID?: string | null,
    referral?:  {
      __typename: "Referral",
      id: string,
      patientID: string,
      partnerOrganization: PartnerOrganization,
      requisitionUploadS3ObjectIDs?: Array< string > | null,
      serviceTypes: Array< ServiceType >,
      isBooked?: boolean | null,
      isExpired?: boolean | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
    historyJSON?: string | null,
    // An external ID associated with the appointment, such as a job ID from MPX
    externalID?: string | null,
    // The id of the appointment that this appointment is cloned from. Cloning includes information
    // such as location, patients, and requisitions that are carried over from the original.
    clonedFromAppointmentID?: string | null,
    // The type of qualification a remote tech needs to have for this appointment.
    // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
    // Not present if the appointment has no remote component.
    remoteTechQualificationTypeRequired?: QualificationType | null,
    // Appointment date/time added to "queue"
    // This timestamp is used to determine the ordering of when remoteTech should be assigned
    markedReadyForRemoteTechAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UnassignRemoteTechMutationVariables = {
  appointmentID: string,
  remoteTechID: string,
};

export type UnassignRemoteTechMutation = {
  unassignRemoteTech?:  {
    __typename: "Appointment",
    id: string,
    // The current status of the appointment (See AppointmentStatus enum)
    status: AppointmentStatus,
    // Whether the appointment has been marked failed
    isFailed?: boolean | null,
    // Whether the appointment has at least one service completed in any of the encounters
    hasSuccessfulServices?: boolean | null,
    // Whether the appointment has at least one service failed in any of the encounters
    hasFailedServices?: boolean | null,
    // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
    needsRebooking?: boolean | null,
    // Whether the appointment has passed the pre-review phase
    isPreReviewed?: boolean | null,
    // Whether the appointment has passed the post-review phase
    isPostReviewed?: boolean | null,
    // Whether the appointment has been escalated
    isEscalated?: boolean | null,
    // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
    start: string,
    // Expected appointment end date/time
    end: string,
    // The technician assigned to the appointment
    techID?: string | null,
    remoteTechID?: string | null,
    remoteTechMatchState?: RemoteTechMatchState | null,
    coordinatorID: string,
    location:  {
      __typename: "Location",
      satellitePhotoURL?: string | null,
      streetViewPhotoURL?: string | null,
    },
    partnerOrganization?: PartnerOrganization | null,
    preReviewChecklist?:  {
      __typename: "Checklist",
    } | null,
    checklist?:  {
      __typename: "Checklist",
    } | null,
    postReviewChecklist?:  {
      __typename: "Checklist",
    } | null,
    serviceRegionName: string,
    geographicalState?: string | null,
    avoidTechIDs?: Array< string > | null,
    lastBookedAt?: number | null,
    statusUpdatedAt?: number | null,
    stripeCustomerID?: string | null,
    // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
    stripePriceToCharge?: number | null,
    hasPetsAtLocation?: boolean | null,
    // Comments about logistics like door codes, directions, etc.
    commentsFromCoordinatorAboutAppointment?: string | null,
    // Comments about logistics like door codes, directions, etc. (but from techs)
    commentsFromTechAboutAppointment?: string | null,
    // Comments about app feedback, bugs, feature requests, etc.
    commentsFromTechAboutSystem?: string | null,
    // Comments about why an appointment was put into a failed state
    commentsFromTechAboutAppointmentFailure?: string | null,
    // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
    commentsFromSchedulerAboutAppointmentFailure?: string | null,
    // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
    commentsFromSchedulerAboutCancellation?: string | null,
    partnerOrganizationBookingURL?: string | null,
    encounters?:  {
      __typename: "ModelEncounterConnection",
      nextToken?: string | null,
    } | null,
    tech?:  {
      __typename: "Tech",
      id: string,
      gender?: Gender | null,
      phone?: string | null,
      email?: string | null,
      serviceTypes?: Array< ServiceType | null > | null,
      profilePictureS3ObjectID?: string | null,
      isActive?: boolean | null,
      biographyItems?: Array< string > | null,
      patientTestimonials?: Array< string > | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      capabilities?: Array< TechCapability > | null,
      isIneligibleForMatchmaking?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    remoteTech?:  {
      __typename: "RemoteTech",
      id: string,
      email?: string | null,
      phone?: string | null,
      profilePictureS3ObjectID?: string | null,
      timezone?: Timezone | null,
      isActive: boolean,
      remoteVisitZoomLink?: string | null,
      remoteVisitZoomLinkQRCodeS3ObjectID?: string | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    coordinator:  {
      __typename: "Coordinator",
      id: string,
      authorizedTechIDs?: Array< string > | null,
      // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
      isCSR?: boolean | null,
      isActive?: boolean | null,
      managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
      authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
      phone?: string | null,
      email?: string | null,
      billingCustomerID?: string | null,
      outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
      stripeCustomerID?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    overrideMode?: AppointmentOverrideMode | null,
    // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
    // The default value is 0.5.
    sharedSlotRank: number,
    sharedSlotAppointments?:  Array< {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } > | null,
    // Represents how long the appointment should take based on product types
    estimatedServiceDurationSeconds: number,
    // For invoiced partners, the invoice line items associated with this appointment.
    stripeInvoiceLineItemIDs?: Array< string | null > | null,
    // For patient-paid appointments, any payment attempts associated with this appointment.
    stripePaymentIntentIDs?: Array< string | null > | null,
    // Whether the appointment has been confirmed or if the patient requested a change
    // When undefined or null, it means that the confirmation is still pending
    hasPatientConfirmed?: boolean | null,
    // Whether or not the appointment was booked in an unauthenticated flow
    isGuestBooked?: boolean | null,
    // Referral information from which the appointment was created (optional)
    referralID?: string | null,
    referral?:  {
      __typename: "Referral",
      id: string,
      patientID: string,
      partnerOrganization: PartnerOrganization,
      requisitionUploadS3ObjectIDs?: Array< string > | null,
      serviceTypes: Array< ServiceType >,
      isBooked?: boolean | null,
      isExpired?: boolean | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
    historyJSON?: string | null,
    // An external ID associated with the appointment, such as a job ID from MPX
    externalID?: string | null,
    // The id of the appointment that this appointment is cloned from. Cloning includes information
    // such as location, patients, and requisitions that are carried over from the original.
    clonedFromAppointmentID?: string | null,
    // The type of qualification a remote tech needs to have for this appointment.
    // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
    // Not present if the appointment has no remote component.
    remoteTechQualificationTypeRequired?: QualificationType | null,
    // Appointment date/time added to "queue"
    // This timestamp is used to determine the ordering of when remoteTech should be assigned
    markedReadyForRemoteTechAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CompleteRemoteTechMutationVariables = {
  appointmentID: string,
};

export type CompleteRemoteTechMutation = {
  completeRemoteTech?:  {
    __typename: "Appointment",
    id: string,
    // The current status of the appointment (See AppointmentStatus enum)
    status: AppointmentStatus,
    // Whether the appointment has been marked failed
    isFailed?: boolean | null,
    // Whether the appointment has at least one service completed in any of the encounters
    hasSuccessfulServices?: boolean | null,
    // Whether the appointment has at least one service failed in any of the encounters
    hasFailedServices?: boolean | null,
    // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
    needsRebooking?: boolean | null,
    // Whether the appointment has passed the pre-review phase
    isPreReviewed?: boolean | null,
    // Whether the appointment has passed the post-review phase
    isPostReviewed?: boolean | null,
    // Whether the appointment has been escalated
    isEscalated?: boolean | null,
    // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
    start: string,
    // Expected appointment end date/time
    end: string,
    // The technician assigned to the appointment
    techID?: string | null,
    remoteTechID?: string | null,
    remoteTechMatchState?: RemoteTechMatchState | null,
    coordinatorID: string,
    location:  {
      __typename: "Location",
      satellitePhotoURL?: string | null,
      streetViewPhotoURL?: string | null,
    },
    partnerOrganization?: PartnerOrganization | null,
    preReviewChecklist?:  {
      __typename: "Checklist",
    } | null,
    checklist?:  {
      __typename: "Checklist",
    } | null,
    postReviewChecklist?:  {
      __typename: "Checklist",
    } | null,
    serviceRegionName: string,
    geographicalState?: string | null,
    avoidTechIDs?: Array< string > | null,
    lastBookedAt?: number | null,
    statusUpdatedAt?: number | null,
    stripeCustomerID?: string | null,
    // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
    stripePriceToCharge?: number | null,
    hasPetsAtLocation?: boolean | null,
    // Comments about logistics like door codes, directions, etc.
    commentsFromCoordinatorAboutAppointment?: string | null,
    // Comments about logistics like door codes, directions, etc. (but from techs)
    commentsFromTechAboutAppointment?: string | null,
    // Comments about app feedback, bugs, feature requests, etc.
    commentsFromTechAboutSystem?: string | null,
    // Comments about why an appointment was put into a failed state
    commentsFromTechAboutAppointmentFailure?: string | null,
    // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
    commentsFromSchedulerAboutAppointmentFailure?: string | null,
    // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
    commentsFromSchedulerAboutCancellation?: string | null,
    partnerOrganizationBookingURL?: string | null,
    encounters?:  {
      __typename: "ModelEncounterConnection",
      nextToken?: string | null,
    } | null,
    tech?:  {
      __typename: "Tech",
      id: string,
      gender?: Gender | null,
      phone?: string | null,
      email?: string | null,
      serviceTypes?: Array< ServiceType | null > | null,
      profilePictureS3ObjectID?: string | null,
      isActive?: boolean | null,
      biographyItems?: Array< string > | null,
      patientTestimonials?: Array< string > | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      capabilities?: Array< TechCapability > | null,
      isIneligibleForMatchmaking?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    remoteTech?:  {
      __typename: "RemoteTech",
      id: string,
      email?: string | null,
      phone?: string | null,
      profilePictureS3ObjectID?: string | null,
      timezone?: Timezone | null,
      isActive: boolean,
      remoteVisitZoomLink?: string | null,
      remoteVisitZoomLinkQRCodeS3ObjectID?: string | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    coordinator:  {
      __typename: "Coordinator",
      id: string,
      authorizedTechIDs?: Array< string > | null,
      // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
      isCSR?: boolean | null,
      isActive?: boolean | null,
      managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
      authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
      phone?: string | null,
      email?: string | null,
      billingCustomerID?: string | null,
      outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
      stripeCustomerID?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    overrideMode?: AppointmentOverrideMode | null,
    // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
    // The default value is 0.5.
    sharedSlotRank: number,
    sharedSlotAppointments?:  Array< {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } > | null,
    // Represents how long the appointment should take based on product types
    estimatedServiceDurationSeconds: number,
    // For invoiced partners, the invoice line items associated with this appointment.
    stripeInvoiceLineItemIDs?: Array< string | null > | null,
    // For patient-paid appointments, any payment attempts associated with this appointment.
    stripePaymentIntentIDs?: Array< string | null > | null,
    // Whether the appointment has been confirmed or if the patient requested a change
    // When undefined or null, it means that the confirmation is still pending
    hasPatientConfirmed?: boolean | null,
    // Whether or not the appointment was booked in an unauthenticated flow
    isGuestBooked?: boolean | null,
    // Referral information from which the appointment was created (optional)
    referralID?: string | null,
    referral?:  {
      __typename: "Referral",
      id: string,
      patientID: string,
      partnerOrganization: PartnerOrganization,
      requisitionUploadS3ObjectIDs?: Array< string > | null,
      serviceTypes: Array< ServiceType >,
      isBooked?: boolean | null,
      isExpired?: boolean | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
    historyJSON?: string | null,
    // An external ID associated with the appointment, such as a job ID from MPX
    externalID?: string | null,
    // The id of the appointment that this appointment is cloned from. Cloning includes information
    // such as location, patients, and requisitions that are carried over from the original.
    clonedFromAppointmentID?: string | null,
    // The type of qualification a remote tech needs to have for this appointment.
    // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
    // Not present if the appointment has no remote component.
    remoteTechQualificationTypeRequired?: QualificationType | null,
    // Appointment date/time added to "queue"
    // This timestamp is used to determine the ordering of when remoteTech should be assigned
    markedReadyForRemoteTechAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UploadDailyMaxPatientsToTricklePerRegionFileMutationVariables = {
  data: string,
};

export type UploadDailyMaxPatientsToTricklePerRegionFileMutation = {
  uploadDailyMaxPatientsToTricklePerRegionFile?: string | null,
};

export type UpdateTrickleConfigurationMutationVariables = {
  configuration: string,
};

export type UpdateTrickleConfigurationMutation = {
  updateTrickleConfiguration?:  {
    __typename: "TrickleConfigurationResponse",
    configuration: string,
  } | null,
};

export type GetSerialCounterQueryVariables = {
  type: SerialCounterType,
};

export type GetSerialCounterQuery = {
  getSerialCounter?:  {
    __typename: "SerialCounter",
    type: SerialCounterType,
    serialValue: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSerialCountersQueryVariables = {
  type?: SerialCounterType | null,
  filter?: ModelSerialCounterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListSerialCountersQuery = {
  listSerialCounters?:  {
    __typename: "ModelSerialCounterConnection",
    items:  Array< {
      __typename: "SerialCounter",
      type: SerialCounterType,
      serialValue: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAppointmentQueryVariables = {
  id: string,
};

export type GetAppointmentQuery = {
  getAppointment?:  {
    __typename: "Appointment",
    id: string,
    // The current status of the appointment (See AppointmentStatus enum)
    status: AppointmentStatus,
    // Whether the appointment has been marked failed
    isFailed?: boolean | null,
    // Whether the appointment has at least one service completed in any of the encounters
    hasSuccessfulServices?: boolean | null,
    // Whether the appointment has at least one service failed in any of the encounters
    hasFailedServices?: boolean | null,
    // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
    needsRebooking?: boolean | null,
    // Whether the appointment has passed the pre-review phase
    isPreReviewed?: boolean | null,
    // Whether the appointment has passed the post-review phase
    isPostReviewed?: boolean | null,
    // Whether the appointment has been escalated
    isEscalated?: boolean | null,
    // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
    start: string,
    // Expected appointment end date/time
    end: string,
    // The technician assigned to the appointment
    techID?: string | null,
    remoteTechID?: string | null,
    remoteTechMatchState?: RemoteTechMatchState | null,
    coordinatorID: string,
    location:  {
      __typename: "Location",
      satellitePhotoURL?: string | null,
      streetViewPhotoURL?: string | null,
    },
    partnerOrganization?: PartnerOrganization | null,
    preReviewChecklist?:  {
      __typename: "Checklist",
    } | null,
    checklist?:  {
      __typename: "Checklist",
    } | null,
    postReviewChecklist?:  {
      __typename: "Checklist",
    } | null,
    serviceRegionName: string,
    geographicalState?: string | null,
    avoidTechIDs?: Array< string > | null,
    lastBookedAt?: number | null,
    statusUpdatedAt?: number | null,
    stripeCustomerID?: string | null,
    // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
    stripePriceToCharge?: number | null,
    hasPetsAtLocation?: boolean | null,
    // Comments about logistics like door codes, directions, etc.
    commentsFromCoordinatorAboutAppointment?: string | null,
    // Comments about logistics like door codes, directions, etc. (but from techs)
    commentsFromTechAboutAppointment?: string | null,
    // Comments about app feedback, bugs, feature requests, etc.
    commentsFromTechAboutSystem?: string | null,
    // Comments about why an appointment was put into a failed state
    commentsFromTechAboutAppointmentFailure?: string | null,
    // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
    commentsFromSchedulerAboutAppointmentFailure?: string | null,
    // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
    commentsFromSchedulerAboutCancellation?: string | null,
    partnerOrganizationBookingURL?: string | null,
    encounters?:  {
      __typename: "ModelEncounterConnection",
      nextToken?: string | null,
    } | null,
    tech?:  {
      __typename: "Tech",
      id: string,
      gender?: Gender | null,
      phone?: string | null,
      email?: string | null,
      serviceTypes?: Array< ServiceType | null > | null,
      profilePictureS3ObjectID?: string | null,
      isActive?: boolean | null,
      biographyItems?: Array< string > | null,
      patientTestimonials?: Array< string > | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      capabilities?: Array< TechCapability > | null,
      isIneligibleForMatchmaking?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    remoteTech?:  {
      __typename: "RemoteTech",
      id: string,
      email?: string | null,
      phone?: string | null,
      profilePictureS3ObjectID?: string | null,
      timezone?: Timezone | null,
      isActive: boolean,
      remoteVisitZoomLink?: string | null,
      remoteVisitZoomLinkQRCodeS3ObjectID?: string | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    coordinator:  {
      __typename: "Coordinator",
      id: string,
      authorizedTechIDs?: Array< string > | null,
      // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
      isCSR?: boolean | null,
      isActive?: boolean | null,
      managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
      authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
      phone?: string | null,
      email?: string | null,
      billingCustomerID?: string | null,
      outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
      stripeCustomerID?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    overrideMode?: AppointmentOverrideMode | null,
    // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
    // The default value is 0.5.
    sharedSlotRank: number,
    sharedSlotAppointments?:  Array< {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } > | null,
    // Represents how long the appointment should take based on product types
    estimatedServiceDurationSeconds: number,
    // For invoiced partners, the invoice line items associated with this appointment.
    stripeInvoiceLineItemIDs?: Array< string | null > | null,
    // For patient-paid appointments, any payment attempts associated with this appointment.
    stripePaymentIntentIDs?: Array< string | null > | null,
    // Whether the appointment has been confirmed or if the patient requested a change
    // When undefined or null, it means that the confirmation is still pending
    hasPatientConfirmed?: boolean | null,
    // Whether or not the appointment was booked in an unauthenticated flow
    isGuestBooked?: boolean | null,
    // Referral information from which the appointment was created (optional)
    referralID?: string | null,
    referral?:  {
      __typename: "Referral",
      id: string,
      patientID: string,
      partnerOrganization: PartnerOrganization,
      requisitionUploadS3ObjectIDs?: Array< string > | null,
      serviceTypes: Array< ServiceType >,
      isBooked?: boolean | null,
      isExpired?: boolean | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
    historyJSON?: string | null,
    // An external ID associated with the appointment, such as a job ID from MPX
    externalID?: string | null,
    // The id of the appointment that this appointment is cloned from. Cloning includes information
    // such as location, patients, and requisitions that are carried over from the original.
    clonedFromAppointmentID?: string | null,
    // The type of qualification a remote tech needs to have for this appointment.
    // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
    // Not present if the appointment has no remote component.
    remoteTechQualificationTypeRequired?: QualificationType | null,
    // Appointment date/time added to "queue"
    // This timestamp is used to determine the ordering of when remoteTech should be assigned
    markedReadyForRemoteTechAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAppointmentsQueryVariables = {
  filter?: ModelAppointmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAppointmentsQuery = {
  listAppointments?:  {
    __typename: "ModelAppointmentConnection",
    items:  Array< {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListAppointmentsByStatusByDateQueryVariables = {
  status: AppointmentStatus,
  start?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAppointmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAppointmentsByStatusByDateQuery = {
  listAppointmentsByStatusByDate?:  {
    __typename: "ModelAppointmentConnection",
    items:  Array< {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListAppointmentsByStatusByMarkedReadyForRemoteTechAtQueryVariables = {
  status: AppointmentStatus,
  markedReadyForRemoteTechAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAppointmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAppointmentsByStatusByMarkedReadyForRemoteTechAtQuery = {
  listAppointmentsByStatusByMarkedReadyForRemoteTechAt?:  {
    __typename: "ModelAppointmentConnection",
    items:  Array< {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListAppointmentsByTechByDateQueryVariables = {
  techID: string,
  start?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAppointmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAppointmentsByTechByDateQuery = {
  listAppointmentsByTechByDate?:  {
    __typename: "ModelAppointmentConnection",
    items:  Array< {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListAppointmentsByRemoteTechByDateQueryVariables = {
  remoteTechID: string,
  start?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAppointmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAppointmentsByRemoteTechByDateQuery = {
  listAppointmentsByRemoteTechByDate?:  {
    __typename: "ModelAppointmentConnection",
    items:  Array< {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListAppointmentsByCoordinatorByTechQueryVariables = {
  coordinatorID: string,
  techID?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAppointmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAppointmentsByCoordinatorByTechQuery = {
  listAppointmentsByCoordinatorByTech?:  {
    __typename: "ModelAppointmentConnection",
    items:  Array< {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListAppointmentsByPartnerOrganizationByDateQueryVariables = {
  partnerOrganization: PartnerOrganization,
  start?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAppointmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAppointmentsByPartnerOrganizationByDateQuery = {
  listAppointmentsByPartnerOrganizationByDate?:  {
    __typename: "ModelAppointmentConnection",
    items:  Array< {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListAppointmentsByServiceRegionNameByDateQueryVariables = {
  serviceRegionName: string,
  start?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAppointmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAppointmentsByServiceRegionNameByDateQuery = {
  listAppointmentsByServiceRegionNameByDate?:  {
    __typename: "ModelAppointmentConnection",
    items:  Array< {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListAppointmentsByGeographicalStateByDateQueryVariables = {
  geographicalState: string,
  start?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAppointmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAppointmentsByGeographicalStateByDateQuery = {
  listAppointmentsByGeographicalStateByDate?:  {
    __typename: "ModelAppointmentConnection",
    items:  Array< {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListAppointmentsByRemoteTechQualificationTypeRequiredByDateQueryVariables = {
  remoteTechQualificationTypeRequired: QualificationType,
  start?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAppointmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAppointmentsByRemoteTechQualificationTypeRequiredByDateQuery = {
  listAppointmentsByRemoteTechQualificationTypeRequiredByDate?:  {
    __typename: "ModelAppointmentConnection",
    items:  Array< {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchAppointmentsQueryVariables = {
  filter?: SearchableAppointmentFilterInput | null,
  sort?: Array< SearchableAppointmentSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableAppointmentAggregationInput | null > | null,
};

export type SearchAppointmentsQuery = {
  searchAppointments?:  {
    __typename: "SearchableAppointmentConnection",
    items:  Array< {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetEncounterQueryVariables = {
  id: string,
};

export type GetEncounterQuery = {
  getEncounter?:  {
    __typename: "Encounter",
    id: string,
    appointmentID: string,
    appointment:  {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    patientID: string,
    patient:  {
      __typename: "Patient",
      id: string,
      csrPartnerOrganization?: PartnerOrganization | null,
      partnerOrganization?: PartnerOrganization | null,
      coordinatorID: string,
      medicareCardID?: string | null,
      imageMoverUserID?: string | null,
      externalID?: string | null,
      secondaryExternalID?: string | null,
      specimenLabelID?: string | null,
      qtMedicalPatientID?: string | null,
      planName?: string | null,
      diabeticType?: DiabeticType | null,
      authorizedTechIDs?: Array< string > | null,
      isCoordinator: boolean,
      // Phone & email to use in place of the coordinators for messaging. Used primarily for patients whose appointments are booked for them by a partner's customer service representatives.
      phone?: string | null,
      email?: string | null,
      secondaryPhone?: string | null,
      birthdate?: string | null,
      sex?: Sex | null,
      gender?: Gender | null,
      ethnicity?: Ethnicity | null,
      races?: Array< Race > | null,
      passportNumber?: string | null,
      insuranceCardUploadFrontS3ObjectID?: string | null,
      insuranceCardUploadBackS3ObjectID?: string | null,
      medicareCardUploadFrontS3ObjectID?: string | null,
      medicareCardUploadBackS3ObjectID?: string | null,
      watchlistedAppointmentID?: string | null,
      responsiblePractitionerID?: string | null,
      commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
      commentsFromCaregiverAboutResponsiblePractitioner?: string | null,
      suggestedAppointmentLocationAdditionalInformation?: string | null,
      preferredLanguage?: string | null,
      primaryLanguageBCP47?: string | null,
      secondaryLanguagesBCP47?: Array< string > | null,
      // A unique numerical patient reference number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
      patientNumber?: string | null,
      // latest validated gap list data referencing this patient.
      // - latestGapListUploadID: the upload ID of the latest ingestion for tracking purposes
      // - latestGapListRowJSON: the entire row
      // - latestGapListPassthroughJSON: a subset of the row for passing back to the partner with patient data in reports
      latestGapListUploadID?: string | null,
      latestGapListRowJSON?: string | null,
      latestGapListPassthroughJSON?: string | null,
      // A reference to a set of branding material provided for outreach.
      brandCode?: string | null,
      population?: PatientPopulation | null,
      elationID?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    coordinatorID: string,
    coordinator?:  {
      __typename: "Coordinator",
      id: string,
      authorizedTechIDs?: Array< string > | null,
      // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
      isCSR?: boolean | null,
      isActive?: boolean | null,
      managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
      authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
      phone?: string | null,
      email?: string | null,
      billingCustomerID?: string | null,
      outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
      stripeCustomerID?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    techID?: string | null,
    tech?:  {
      __typename: "Tech",
      id: string,
      gender?: Gender | null,
      phone?: string | null,
      email?: string | null,
      serviceTypes?: Array< ServiceType | null > | null,
      profilePictureS3ObjectID?: string | null,
      isActive?: boolean | null,
      biographyItems?: Array< string > | null,
      patientTestimonials?: Array< string > | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      capabilities?: Array< TechCapability > | null,
      isIneligibleForMatchmaking?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    partnerOrganization?: PartnerOrganization | null,
    requisitions?:  Array< {
      __typename: "Requisition",
      id: string,
      labOrganization?: LabOrganization | null,
      // A unique numerical patient requisition number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
      requisitionNumber?: string | null,
    } > | null,
    requisitionUploads?:  Array< {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } > | null,
    requisitionUploadS3ObjectIDs?: Array< string > | null,
    // Comments about patient info like medical/draw history, disabilities, etc.
    commentsFromCoordinatorAboutPatient?: string | null,
    // Comments about the clinical aspects of the encounter to be passed from scheduler to technician
    commentsFromSchedulerAboutEncounter?: string | null,
    // Comments about the clinical aspects of the encounter to be passed from scheduler to technician
    commentsFromSchedulerToTechAboutEncounter?: string | null,
    // Comments about the clinical aspects of the encounter and patient info not mentioned by the coordinator
    commentsFromTechAboutEncounter?: string | null,
    // Comments from customer support about actions taken on the encounter (e.g., services removed).
    commentsFromCustomerSupportAboutEncounter?: string | null,
    // Comments from the scheduler to the partner about actions taken on the encounter (e.g., services removed).
    commentsFromSchedulerToPartnerAboutEncounter?: string | null,
    covid19Questions?:  {
      __typename: "COVID19Questions",
      isForTravel?: boolean | null,
      isForWorkOrSchool?: boolean | null,
      isForAPublicEvent?: boolean | null,
      isForPotentialExposure?: boolean | null,
      isForSymptoms?: boolean | null,
      isForOtherReasons?: boolean | null,
    } | null,
    bloodCollectionDetails?:  {
      __typename: "SpecimenCollectionDetails",
      bodySiteLaterality?: Laterality | null,
      bodySiteStructure?: BodyStructure | null,
      deviceType?: SpecimenCollectionDeviceType | null,
      attempts?: number | null,
    } | null,
    capillaryDrawCollectionDetails?:  {
      __typename: "SpecimenCollectionDetails",
      bodySiteLaterality?: Laterality | null,
      bodySiteStructure?: BodyStructure | null,
      deviceType?: SpecimenCollectionDeviceType | null,
      attempts?: number | null,
    } | null,
    productTypes?: Array< ProductType > | null,
    isMedicare?: boolean | null,
    isMedicareEligible?: boolean | null,
    wasPatientManuallyAdded?: boolean | null,
    serviceOutcomes?:  Array< {
      __typename: "ServiceOutcome",
      failureReason?: ServiceFailureReason | null,
      failureFault?: ServiceFailureFault | null,
      isRescheduleRequired?: boolean | null,
      serviceType: ServiceType,
      status?: ServiceStatus | null,
      commentsFromSchedulerAboutServiceFailure?: string | null,
      serviceDetailJSON?: string | null,
    } > | null,
    diagnosisCodes?:  Array< {
      __typename: "Coding",
      system: string,
      code: string,
      displayName?: string | null,
    } > | null,
    // The partner's identifier for this encounter/appointment:
    // - FMI Case number for Foundation,
    // - Accession Number for Guardant,
    // - Job ID for Natera
    partnersEncounterID?:  {
      __typename: "CustomProperty",
      key: CustomPropertyKey,
      value: string,
    } | null,
    documents?:  Array< {
      __typename: "Document",
      type?: DocumentType | null,
      url?: string | null,
      format?: DocumentFormat | null,
      s3ObjectID?: string | null,
      uploadedAt?: number | null,
      // stringified JSON object as a set of additional data we want to store about documents (requisition number, lab report status, etc.)
      metadataJSON?: string | null,
    } > | null,
    observations?:  Array< {
      __typename: "Observation",
      id: string,
      // Per FHIR specifications, we should only have value or valueCoding
      value?: string | null,
      units?: string | null,
      referenceRange?: string | null,
      category: ObservationCategory,
      performerType: ObservationPerformerType,
      performerIdentifier?: string | null,
      observedAt?: string | null,
      bodySiteLaterality?: Laterality | null,
      bodySiteStructure?: BodyStructure | null,
      notes?: string | null,
      // Also called Abnormal Flag. A categorical assessment, providing a rough qualitative interpretation of the observation value.
      // http://hl7.org/fhir/valueset-observation-interpretation.html
      interpretationCode?: ObservationInterpretationCode | null,
      source?: ObservationSource | null,
    } > | null,
    procedures?:  Array< {
      __typename: "Procedure",
      source: ProcedureSource,
    } > | null,
    escalation?:  {
      __typename: "Escalation",
      isResolved?: boolean | null,
      symptoms?: Array< EscalationSymptom > | null,
      // If OTHER is selected in the symptoms this contains the list of other symptoms.
      otherSymptoms?: string | null,
      // Comments from the patient to the escalation owner, like their medical history. The escalation owner is the scheduler who is filling out the report.
      commentsFromPatientToEscalationOwner?: string | null,
      // Comments from the escalation owner to the partner about the escalation. This includes the general assessment of the situation involving the patient. The escalation owner is the scheduler who is filling out the report.
      commentsFromEscalationOwnerToPartnerAboutEscalation?: string | null,
      outcome?: EscalationOutcome | null,
      // If OTHER is selected for the outcome this contains a description of the outcome.
      otherOutcome?: string | null,
      outcomeTimeframe?: EscalationOutcomeTimeframe | null,
      createdAt?: string | null,
      resolvedAt?: string | null,
    } | null,
    questionnaires?:  Array< {
      __typename: "QuestionnaireEmbedded",
      type: QuestionnaireType,
      url: string,
      metadataJSON?: string | null,
    } > | null,
    colorectalCancerScreeningAnswers?:  {
      __typename: "QuestionnaireResponse",
      // Canonical id of Questionnaire being answered
      responseID?: string | null,
      // Date the answers were gathered
      authoredAt?: string | null,
    } | null,
    patientEncounterSummaryLastSentAt?: string | null,
    cologuardOrderSuccessfullyPlacedAt?: string | null,
    hasPatientRequestedMailedResults?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListEncountersQueryVariables = {
  filter?: ModelEncounterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEncountersQuery = {
  listEncounters?:  {
    __typename: "ModelEncounterConnection",
    items:  Array< {
      __typename: "Encounter",
      id: string,
      appointmentID: string,
      patientID: string,
      coordinatorID: string,
      techID?: string | null,
      partnerOrganization?: PartnerOrganization | null,
      requisitionUploadS3ObjectIDs?: Array< string > | null,
      // Comments about patient info like medical/draw history, disabilities, etc.
      commentsFromCoordinatorAboutPatient?: string | null,
      // Comments about the clinical aspects of the encounter to be passed from scheduler to technician
      commentsFromSchedulerAboutEncounter?: string | null,
      // Comments about the clinical aspects of the encounter to be passed from scheduler to technician
      commentsFromSchedulerToTechAboutEncounter?: string | null,
      // Comments about the clinical aspects of the encounter and patient info not mentioned by the coordinator
      commentsFromTechAboutEncounter?: string | null,
      // Comments from customer support about actions taken on the encounter (e.g., services removed).
      commentsFromCustomerSupportAboutEncounter?: string | null,
      // Comments from the scheduler to the partner about actions taken on the encounter (e.g., services removed).
      commentsFromSchedulerToPartnerAboutEncounter?: string | null,
      productTypes?: Array< ProductType > | null,
      isMedicare?: boolean | null,
      isMedicareEligible?: boolean | null,
      wasPatientManuallyAdded?: boolean | null,
      patientEncounterSummaryLastSentAt?: string | null,
      cologuardOrderSuccessfullyPlacedAt?: string | null,
      hasPatientRequestedMailedResults?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListEncountersByPatientByTechQueryVariables = {
  patientID: string,
  techID?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEncounterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEncountersByPatientByTechQuery = {
  listEncountersByPatientByTech?:  {
    __typename: "ModelEncounterConnection",
    items:  Array< {
      __typename: "Encounter",
      id: string,
      appointmentID: string,
      patientID: string,
      coordinatorID: string,
      techID?: string | null,
      partnerOrganization?: PartnerOrganization | null,
      requisitionUploadS3ObjectIDs?: Array< string > | null,
      // Comments about patient info like medical/draw history, disabilities, etc.
      commentsFromCoordinatorAboutPatient?: string | null,
      // Comments about the clinical aspects of the encounter to be passed from scheduler to technician
      commentsFromSchedulerAboutEncounter?: string | null,
      // Comments about the clinical aspects of the encounter to be passed from scheduler to technician
      commentsFromSchedulerToTechAboutEncounter?: string | null,
      // Comments about the clinical aspects of the encounter and patient info not mentioned by the coordinator
      commentsFromTechAboutEncounter?: string | null,
      // Comments from customer support about actions taken on the encounter (e.g., services removed).
      commentsFromCustomerSupportAboutEncounter?: string | null,
      // Comments from the scheduler to the partner about actions taken on the encounter (e.g., services removed).
      commentsFromSchedulerToPartnerAboutEncounter?: string | null,
      productTypes?: Array< ProductType > | null,
      isMedicare?: boolean | null,
      isMedicareEligible?: boolean | null,
      wasPatientManuallyAdded?: boolean | null,
      patientEncounterSummaryLastSentAt?: string | null,
      cologuardOrderSuccessfullyPlacedAt?: string | null,
      hasPatientRequestedMailedResults?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchEncountersQueryVariables = {
  filter?: SearchableEncounterFilterInput | null,
  sort?: Array< SearchableEncounterSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableEncounterAggregationInput | null > | null,
};

export type SearchEncountersQuery = {
  searchEncounters?:  {
    __typename: "SearchableEncounterConnection",
    items:  Array< {
      __typename: "Encounter",
      id: string,
      appointmentID: string,
      patientID: string,
      coordinatorID: string,
      techID?: string | null,
      partnerOrganization?: PartnerOrganization | null,
      requisitionUploadS3ObjectIDs?: Array< string > | null,
      // Comments about patient info like medical/draw history, disabilities, etc.
      commentsFromCoordinatorAboutPatient?: string | null,
      // Comments about the clinical aspects of the encounter to be passed from scheduler to technician
      commentsFromSchedulerAboutEncounter?: string | null,
      // Comments about the clinical aspects of the encounter to be passed from scheduler to technician
      commentsFromSchedulerToTechAboutEncounter?: string | null,
      // Comments about the clinical aspects of the encounter and patient info not mentioned by the coordinator
      commentsFromTechAboutEncounter?: string | null,
      // Comments from customer support about actions taken on the encounter (e.g., services removed).
      commentsFromCustomerSupportAboutEncounter?: string | null,
      // Comments from the scheduler to the partner about actions taken on the encounter (e.g., services removed).
      commentsFromSchedulerToPartnerAboutEncounter?: string | null,
      productTypes?: Array< ProductType > | null,
      isMedicare?: boolean | null,
      isMedicareEligible?: boolean | null,
      wasPatientManuallyAdded?: boolean | null,
      patientEncounterSummaryLastSentAt?: string | null,
      cologuardOrderSuccessfullyPlacedAt?: string | null,
      hasPatientRequestedMailedResults?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetCareNavigationEpisodeQueryVariables = {
  id: string,
};

export type GetCareNavigationEpisodeQuery = {
  getCareNavigationEpisode?:  {
    __typename: "CareNavigationEpisode",
    closedAt?: string | null,
    careNavigationGroup: CareNavigationGroup,
    patientID: string,
    patient:  {
      __typename: "Patient",
      id: string,
      csrPartnerOrganization?: PartnerOrganization | null,
      partnerOrganization?: PartnerOrganization | null,
      coordinatorID: string,
      medicareCardID?: string | null,
      imageMoverUserID?: string | null,
      externalID?: string | null,
      secondaryExternalID?: string | null,
      specimenLabelID?: string | null,
      qtMedicalPatientID?: string | null,
      planName?: string | null,
      diabeticType?: DiabeticType | null,
      authorizedTechIDs?: Array< string > | null,
      isCoordinator: boolean,
      // Phone & email to use in place of the coordinators for messaging. Used primarily for patients whose appointments are booked for them by a partner's customer service representatives.
      phone?: string | null,
      email?: string | null,
      secondaryPhone?: string | null,
      birthdate?: string | null,
      sex?: Sex | null,
      gender?: Gender | null,
      ethnicity?: Ethnicity | null,
      races?: Array< Race > | null,
      passportNumber?: string | null,
      insuranceCardUploadFrontS3ObjectID?: string | null,
      insuranceCardUploadBackS3ObjectID?: string | null,
      medicareCardUploadFrontS3ObjectID?: string | null,
      medicareCardUploadBackS3ObjectID?: string | null,
      watchlistedAppointmentID?: string | null,
      responsiblePractitionerID?: string | null,
      commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
      commentsFromCaregiverAboutResponsiblePractitioner?: string | null,
      suggestedAppointmentLocationAdditionalInformation?: string | null,
      preferredLanguage?: string | null,
      primaryLanguageBCP47?: string | null,
      secondaryLanguagesBCP47?: Array< string > | null,
      // A unique numerical patient reference number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
      patientNumber?: string | null,
      // latest validated gap list data referencing this patient.
      // - latestGapListUploadID: the upload ID of the latest ingestion for tracking purposes
      // - latestGapListRowJSON: the entire row
      // - latestGapListPassthroughJSON: a subset of the row for passing back to the partner with patient data in reports
      latestGapListUploadID?: string | null,
      latestGapListRowJSON?: string | null,
      latestGapListPassthroughJSON?: string | null,
      // A reference to a set of branding material provided for outreach.
      brandCode?: string | null,
      population?: PatientPopulation | null,
      elationID?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    partnerOrganization: PartnerOrganization,
    referralID: string,
    referral:  {
      __typename: "Referral",
      id: string,
      patientID: string,
      partnerOrganization: PartnerOrganization,
      requisitionUploadS3ObjectIDs?: Array< string > | null,
      serviceTypes: Array< ServiceType >,
      isBooked?: boolean | null,
      isExpired?: boolean | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    },
    zendeskTicketID?: number | null,
    serviceOutcomes?:  Array< {
      __typename: "CareNavigationEpisodeServiceOutcome",
      id: string,
      failureReason?: ServiceFailureReason | null,
      failureFault?: ServiceFailureFault | null,
      isRescheduleRequired?: boolean | null,
      serviceType: ServiceType,
      status?: ServiceStatus | null,
      commentsFromCareNavigatorAboutServiceFailure?: string | null,
      statusChangedAt?: string | null,
      serviceDetailJSON: string,
    } > | null,
    documents?:  Array< {
      __typename: "Document",
      type?: DocumentType | null,
      url?: string | null,
      format?: DocumentFormat | null,
      s3ObjectID?: string | null,
      uploadedAt?: number | null,
      // stringified JSON object as a set of additional data we want to store about documents (requisition number, lab report status, etc.)
      metadataJSON?: string | null,
    } > | null,
    observations?:  Array< {
      __typename: "Observation",
      id: string,
      // Per FHIR specifications, we should only have value or valueCoding
      value?: string | null,
      units?: string | null,
      referenceRange?: string | null,
      category: ObservationCategory,
      performerType: ObservationPerformerType,
      performerIdentifier?: string | null,
      observedAt?: string | null,
      bodySiteLaterality?: Laterality | null,
      bodySiteStructure?: BodyStructure | null,
      notes?: string | null,
      // Also called Abnormal Flag. A categorical assessment, providing a rough qualitative interpretation of the observation value.
      // http://hl7.org/fhir/valueset-observation-interpretation.html
      interpretationCode?: ObservationInterpretationCode | null,
      source?: ObservationSource | null,
    } > | null,
    onboardingQuestionsAndAnswers?:  Array< {
      __typename: "OnboardingQuestionAnswerPair",
      key: string,
      question: string,
      answer: string,
    } > | null,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};

export type ListCareNavigationEpisodesQueryVariables = {
  filter?: ModelCareNavigationEpisodeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCareNavigationEpisodesQuery = {
  listCareNavigationEpisodes?:  {
    __typename: "ModelCareNavigationEpisodeConnection",
    items:  Array< {
      __typename: "CareNavigationEpisode",
      closedAt?: string | null,
      careNavigationGroup: CareNavigationGroup,
      patientID: string,
      partnerOrganization: PartnerOrganization,
      referralID: string,
      zendeskTicketID?: number | null,
      createdAt: string,
      id: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCareNavigationEpisodesByPartnerOrganizationByCreatedAtQueryVariables = {
  partnerOrganization: PartnerOrganization,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCareNavigationEpisodeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCareNavigationEpisodesByPartnerOrganizationByCreatedAtQuery = {
  listCareNavigationEpisodesByPartnerOrganizationByCreatedAt?:  {
    __typename: "ModelCareNavigationEpisodeConnection",
    items:  Array< {
      __typename: "CareNavigationEpisode",
      closedAt?: string | null,
      careNavigationGroup: CareNavigationGroup,
      patientID: string,
      partnerOrganization: PartnerOrganization,
      referralID: string,
      zendeskTicketID?: number | null,
      createdAt: string,
      id: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchCareNavigationEpisodesQueryVariables = {
  filter?: SearchableCareNavigationEpisodeFilterInput | null,
  sort?: Array< SearchableCareNavigationEpisodeSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableCareNavigationEpisodeAggregationInput | null > | null,
};

export type SearchCareNavigationEpisodesQuery = {
  searchCareNavigationEpisodes?:  {
    __typename: "SearchableCareNavigationEpisodeConnection",
    items:  Array< {
      __typename: "CareNavigationEpisode",
      closedAt?: string | null,
      careNavigationGroup: CareNavigationGroup,
      patientID: string,
      partnerOrganization: PartnerOrganization,
      referralID: string,
      zendeskTicketID?: number | null,
      createdAt: string,
      id: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetS3ObjectDataQueryVariables = {
  id: string,
};

export type GetS3ObjectDataQuery = {
  getS3ObjectData?:  {
    __typename: "S3ObjectData",
    id: string,
    region: string,
    bucket: string,
    key: string,
    identityID: string,
    parentType: S3ObjectParentType,
    parentID: string,
    contentType?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListS3ObjectDataQueryVariables = {
  filter?: ModelS3ObjectDataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListS3ObjectDataQuery = {
  listS3ObjectData?:  {
    __typename: "ModelS3ObjectDataConnection",
    items:  Array< {
      __typename: "S3ObjectData",
      id: string,
      region: string,
      bucket: string,
      key: string,
      identityID: string,
      parentType: S3ObjectParentType,
      parentID: string,
      contentType?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchS3ObjectDataQueryVariables = {
  filter?: SearchableS3ObjectDataFilterInput | null,
  sort?: Array< SearchableS3ObjectDataSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableS3ObjectDataAggregationInput | null > | null,
};

export type SearchS3ObjectDataQuery = {
  searchS3ObjectData?:  {
    __typename: "SearchableS3ObjectDataConnection",
    items:  Array< {
      __typename: "S3ObjectData",
      id: string,
      region: string,
      bucket: string,
      key: string,
      identityID: string,
      parentType: S3ObjectParentType,
      parentID: string,
      contentType?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetPartnerUserInviteQueryVariables = {
  id: string,
};

export type GetPartnerUserInviteQuery = {
  getPartnerUserInvite?:  {
    __typename: "PartnerUserInvite",
    id: string,
    email: string,
    partnerOrganization: PartnerOrganization,
    status: PartnerUserInviteStatus,
    expiry: number,
    ttl?: number | null,
    managePartnerUsersAuthorization: ManagePartnerUsersAuthorization,
    isArchived?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPartnerUserInvitesQueryVariables = {
  id?: string | null,
  filter?: ModelPartnerUserInviteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListPartnerUserInvitesQuery = {
  listPartnerUserInvites?:  {
    __typename: "ModelPartnerUserInviteConnection",
    items:  Array< {
      __typename: "PartnerUserInvite",
      id: string,
      email: string,
      partnerOrganization: PartnerOrganization,
      status: PartnerUserInviteStatus,
      expiry: number,
      ttl?: number | null,
      managePartnerUsersAuthorization: ManagePartnerUsersAuthorization,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListPartnerUserInvitesByPartnerOrganizationByEmailQueryVariables = {
  partnerOrganization: PartnerOrganization,
  email?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPartnerUserInviteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPartnerUserInvitesByPartnerOrganizationByEmailQuery = {
  listPartnerUserInvitesByPartnerOrganizationByEmail?:  {
    __typename: "ModelPartnerUserInviteConnection",
    items:  Array< {
      __typename: "PartnerUserInvite",
      id: string,
      email: string,
      partnerOrganization: PartnerOrganization,
      status: PartnerUserInviteStatus,
      expiry: number,
      ttl?: number | null,
      managePartnerUsersAuthorization: ManagePartnerUsersAuthorization,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchPartnerUserInvitesQueryVariables = {
  filter?: SearchablePartnerUserInviteFilterInput | null,
  sort?: Array< SearchablePartnerUserInviteSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchablePartnerUserInviteAggregationInput | null > | null,
};

export type SearchPartnerUserInvitesQuery = {
  searchPartnerUserInvites?:  {
    __typename: "SearchablePartnerUserInviteConnection",
    items:  Array< {
      __typename: "PartnerUserInvite",
      id: string,
      email: string,
      partnerOrganization: PartnerOrganization,
      status: PartnerUserInviteStatus,
      expiry: number,
      ttl?: number | null,
      managePartnerUsersAuthorization: ManagePartnerUsersAuthorization,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetPractitionerQueryVariables = {
  id: string,
};

export type GetPractitionerQuery = {
  getPractitioner?:  {
    __typename: "Practitioner",
    id: string,
    // national provider identifier
    NPI: string,
    name?:  {
      __typename: "HumanName",
      use: NameUse,
      family?: string | null,
      given?: Array< string > | null,
      prefix?: HumanNamePrefix | null,
      suffix?: string | null,
    } | null,
    practitionerGroupName?: string | null,
    address?:  {
      __typename: "Address",
      use?: AddressUse | null,
      lines: Array< string >,
      subunit?: string | null,
      city: string,
      state: string,
      postalCode: string,
    } | null,
    phone?: string | null,
    email?: string | null,
    fax?: string | null,
    faxConfirmationStatus?: FaxConfirmationStatus | null,
    faxLastConfirmedAt?: string | null,
    testFaxLastSentAt?: string | null,
    incorrectFaxes?: Array< string > | null,
    role?: PractitionerRole | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPractitionersQueryVariables = {
  id?: string | null,
  filter?: ModelPractitionerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListPractitionersQuery = {
  listPractitioners?:  {
    __typename: "ModelPractitionerConnection",
    items:  Array< {
      __typename: "Practitioner",
      id: string,
      // national provider identifier
      NPI: string,
      practitionerGroupName?: string | null,
      phone?: string | null,
      email?: string | null,
      fax?: string | null,
      faxConfirmationStatus?: FaxConfirmationStatus | null,
      faxLastConfirmedAt?: string | null,
      testFaxLastSentAt?: string | null,
      incorrectFaxes?: Array< string > | null,
      role?: PractitionerRole | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PractitionerByNPIQueryVariables = {
  NPI: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPractitionerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PractitionerByNPIQuery = {
  practitionerByNPI?:  {
    __typename: "ModelPractitionerConnection",
    items:  Array< {
      __typename: "Practitioner",
      id: string,
      // national provider identifier
      NPI: string,
      practitionerGroupName?: string | null,
      phone?: string | null,
      email?: string | null,
      fax?: string | null,
      faxConfirmationStatus?: FaxConfirmationStatus | null,
      faxLastConfirmedAt?: string | null,
      testFaxLastSentAt?: string | null,
      incorrectFaxes?: Array< string > | null,
      role?: PractitionerRole | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListPractitionersByFaxQueryVariables = {
  fax: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPractitionerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPractitionersByFaxQuery = {
  listPractitionersByFax?:  {
    __typename: "ModelPractitionerConnection",
    items:  Array< {
      __typename: "Practitioner",
      id: string,
      // national provider identifier
      NPI: string,
      practitionerGroupName?: string | null,
      phone?: string | null,
      email?: string | null,
      fax?: string | null,
      faxConfirmationStatus?: FaxConfirmationStatus | null,
      faxLastConfirmedAt?: string | null,
      testFaxLastSentAt?: string | null,
      incorrectFaxes?: Array< string > | null,
      role?: PractitionerRole | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListPractitionersByFaxConfirmationStatusAndTimeQueryVariables = {
  faxConfirmationStatus: FaxConfirmationStatus,
  faxLastConfirmedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPractitionerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPractitionersByFaxConfirmationStatusAndTimeQuery = {
  listPractitionersByFaxConfirmationStatusAndTime?:  {
    __typename: "ModelPractitionerConnection",
    items:  Array< {
      __typename: "Practitioner",
      id: string,
      // national provider identifier
      NPI: string,
      practitionerGroupName?: string | null,
      phone?: string | null,
      email?: string | null,
      fax?: string | null,
      faxConfirmationStatus?: FaxConfirmationStatus | null,
      faxLastConfirmedAt?: string | null,
      testFaxLastSentAt?: string | null,
      incorrectFaxes?: Array< string > | null,
      role?: PractitionerRole | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchPractitionersQueryVariables = {
  filter?: SearchablePractitionerFilterInput | null,
  sort?: Array< SearchablePractitionerSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchablePractitionerAggregationInput | null > | null,
};

export type SearchPractitionersQuery = {
  searchPractitioners?:  {
    __typename: "SearchablePractitionerConnection",
    items:  Array< {
      __typename: "Practitioner",
      id: string,
      // national provider identifier
      NPI: string,
      practitionerGroupName?: string | null,
      phone?: string | null,
      email?: string | null,
      fax?: string | null,
      faxConfirmationStatus?: FaxConfirmationStatus | null,
      faxLastConfirmedAt?: string | null,
      testFaxLastSentAt?: string | null,
      incorrectFaxes?: Array< string > | null,
      role?: PractitionerRole | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetTechQueryVariables = {
  id: string,
};

export type GetTechQuery = {
  getTech?:  {
    __typename: "Tech",
    id: string,
    name:  {
      __typename: "HumanName",
      use: NameUse,
      family?: string | null,
      given?: Array< string > | null,
      prefix?: HumanNamePrefix | null,
      suffix?: string | null,
    },
    gender?: Gender | null,
    phone?: string | null,
    email?: string | null,
    vehicle?:  {
      __typename: "Vehicle",
      make: string,
      model: string,
      color: string,
    } | null,
    serviceTypes?: Array< ServiceType | null > | null,
    serviceTypesAvailabilityRules?:  Array< {
      __typename: "ServiceTypeAvailabilityRules",
      serviceType: ServiceType,
    } > | null,
    availabilityRules?:  Array< {
      __typename: "TimeRangeRule",
      // The unique ID for the time range.
      id: string,
      // Whether the time range represents an addition or subtraction when considering multiple ranges.
      inclusion: TimeRangeRuleInclusion,
      // The time of day each recurrence starts (inclusive).
      start: string,
      // The time of day each recurrence ends (exclusive). Use '24:00:00' for the end of the day.
      end: string,
      // The first date that the recurrence is valid (inclusive).
      recurrenceStart: string,
      // The last date that the recurrence is valid (inclusive). If not set, the range extends indefinitely.
      recurrenceEnd?: string | null,
      // The number of periods between each recurrence. For example, with a multiple of N and period of DAY, the time range will recur every N days.
      recurrenceMultiple: number,
      // The unit of recurrence. For example, with a period of WEEK and multiple of 2, the time range will recur every other week.
      recurrencePeriod: TimeRangeRulePeriod,
      // An optional reason for the time range rule, used with time-off unavailability rules (lunch breaks, PTO, etc.)
      unavailabilityReason?: TimeRangeRuleUnavailabilityReason | null,
      // Additional notes about the time range rule.
      notes?: string | null,
      // The date and time that the rule was last updated.
      updatedAt?: string | null,
      // The user who last updated the rule.
      updatedBy?: string | null,
    } > | null,
    locations?:  Array< {
      __typename: "Location",
      satellitePhotoURL?: string | null,
      streetViewPhotoURL?: string | null,
    } > | null,
    profilePicture?:  {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } | null,
    profilePictureS3ObjectID?: string | null,
    isActive?: boolean | null,
    serviceRegions?:  {
      __typename: "ModelTechServiceRegionConnection",
      nextToken?: string | null,
    } | null,
    appointments?:  {
      __typename: "ModelAppointmentConnection",
      nextToken?: string | null,
    } | null,
    biographyItems?: Array< string > | null,
    patientTestimonials?: Array< string > | null,
    partialAppointmentsForTeamAvailabilityJSON?: string | null,
    capabilities?: Array< TechCapability > | null,
    isIneligibleForMatchmaking?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTechsQueryVariables = {
  id?: string | null,
  filter?: ModelTechFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTechsQuery = {
  listTechs?:  {
    __typename: "ModelTechConnection",
    items:  Array< {
      __typename: "Tech",
      id: string,
      gender?: Gender | null,
      phone?: string | null,
      email?: string | null,
      serviceTypes?: Array< ServiceType | null > | null,
      profilePictureS3ObjectID?: string | null,
      isActive?: boolean | null,
      biographyItems?: Array< string > | null,
      patientTestimonials?: Array< string > | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      capabilities?: Array< TechCapability > | null,
      isIneligibleForMatchmaking?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TechsByPhoneQueryVariables = {
  phone: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTechFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TechsByPhoneQuery = {
  techsByPhone?:  {
    __typename: "ModelTechConnection",
    items:  Array< {
      __typename: "Tech",
      id: string,
      gender?: Gender | null,
      phone?: string | null,
      email?: string | null,
      serviceTypes?: Array< ServiceType | null > | null,
      profilePictureS3ObjectID?: string | null,
      isActive?: boolean | null,
      biographyItems?: Array< string > | null,
      patientTestimonials?: Array< string > | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      capabilities?: Array< TechCapability > | null,
      isIneligibleForMatchmaking?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchTechsQueryVariables = {
  filter?: SearchableTechFilterInput | null,
  sort?: Array< SearchableTechSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableTechAggregationInput | null > | null,
};

export type SearchTechsQuery = {
  searchTechs?:  {
    __typename: "SearchableTechConnection",
    items:  Array< {
      __typename: "Tech",
      id: string,
      gender?: Gender | null,
      phone?: string | null,
      email?: string | null,
      serviceTypes?: Array< ServiceType | null > | null,
      profilePictureS3ObjectID?: string | null,
      isActive?: boolean | null,
      biographyItems?: Array< string > | null,
      patientTestimonials?: Array< string > | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      capabilities?: Array< TechCapability > | null,
      isIneligibleForMatchmaking?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetTechServiceRegionQueryVariables = {
  id: string,
};

export type GetTechServiceRegionQuery = {
  getTechServiceRegion?:  {
    __typename: "TechServiceRegion",
    id: string,
    techID: string,
    serviceRegionName: string,
    tech:  {
      __typename: "Tech",
      id: string,
      gender?: Gender | null,
      phone?: string | null,
      email?: string | null,
      serviceTypes?: Array< ServiceType | null > | null,
      profilePictureS3ObjectID?: string | null,
      isActive?: boolean | null,
      biographyItems?: Array< string > | null,
      patientTestimonials?: Array< string > | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      capabilities?: Array< TechCapability > | null,
      isIneligibleForMatchmaking?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    availabilityRules?:  Array< {
      __typename: "TimeRangeRule",
      // The unique ID for the time range.
      id: string,
      // Whether the time range represents an addition or subtraction when considering multiple ranges.
      inclusion: TimeRangeRuleInclusion,
      // The time of day each recurrence starts (inclusive).
      start: string,
      // The time of day each recurrence ends (exclusive). Use '24:00:00' for the end of the day.
      end: string,
      // The first date that the recurrence is valid (inclusive).
      recurrenceStart: string,
      // The last date that the recurrence is valid (inclusive). If not set, the range extends indefinitely.
      recurrenceEnd?: string | null,
      // The number of periods between each recurrence. For example, with a multiple of N and period of DAY, the time range will recur every N days.
      recurrenceMultiple: number,
      // The unit of recurrence. For example, with a period of WEEK and multiple of 2, the time range will recur every other week.
      recurrencePeriod: TimeRangeRulePeriod,
      // An optional reason for the time range rule, used with time-off unavailability rules (lunch breaks, PTO, etc.)
      unavailabilityReason?: TimeRangeRuleUnavailabilityReason | null,
      // Additional notes about the time range rule.
      notes?: string | null,
      // The date and time that the rule was last updated.
      updatedAt?: string | null,
      // The user who last updated the rule.
      updatedBy?: string | null,
    } > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTechServiceRegionsQueryVariables = {
  filter?: ModelTechServiceRegionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTechServiceRegionsQuery = {
  listTechServiceRegions?:  {
    __typename: "ModelTechServiceRegionConnection",
    items:  Array< {
      __typename: "TechServiceRegion",
      id: string,
      techID: string,
      serviceRegionName: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchTechServiceRegionsQueryVariables = {
  filter?: SearchableTechServiceRegionFilterInput | null,
  sort?: Array< SearchableTechServiceRegionSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableTechServiceRegionAggregationInput | null > | null,
};

export type SearchTechServiceRegionsQuery = {
  searchTechServiceRegions?:  {
    __typename: "SearchableTechServiceRegionConnection",
    items:  Array< {
      __typename: "TechServiceRegion",
      id: string,
      techID: string,
      serviceRegionName: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetRemoteTechQueryVariables = {
  id: string,
};

export type GetRemoteTechQuery = {
  getRemoteTech?:  {
    __typename: "RemoteTech",
    id: string,
    name:  {
      __typename: "HumanName",
      use: NameUse,
      family?: string | null,
      given?: Array< string > | null,
      prefix?: HumanNamePrefix | null,
      suffix?: string | null,
    },
    email?: string | null,
    phone?: string | null,
    profilePicture?:  {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } | null,
    profilePictureS3ObjectID?: string | null,
    timezone?: Timezone | null,
    availabilityRules?:  Array< {
      __typename: "TimeRangeRule",
      // The unique ID for the time range.
      id: string,
      // Whether the time range represents an addition or subtraction when considering multiple ranges.
      inclusion: TimeRangeRuleInclusion,
      // The time of day each recurrence starts (inclusive).
      start: string,
      // The time of day each recurrence ends (exclusive). Use '24:00:00' for the end of the day.
      end: string,
      // The first date that the recurrence is valid (inclusive).
      recurrenceStart: string,
      // The last date that the recurrence is valid (inclusive). If not set, the range extends indefinitely.
      recurrenceEnd?: string | null,
      // The number of periods between each recurrence. For example, with a multiple of N and period of DAY, the time range will recur every N days.
      recurrenceMultiple: number,
      // The unit of recurrence. For example, with a period of WEEK and multiple of 2, the time range will recur every other week.
      recurrencePeriod: TimeRangeRulePeriod,
      // An optional reason for the time range rule, used with time-off unavailability rules (lunch breaks, PTO, etc.)
      unavailabilityReason?: TimeRangeRuleUnavailabilityReason | null,
      // Additional notes about the time range rule.
      notes?: string | null,
      // The date and time that the rule was last updated.
      updatedAt?: string | null,
      // The user who last updated the rule.
      updatedBy?: string | null,
    } > | null,
    isActive: boolean,
    qualifications?:  Array< {
      __typename: "Qualification",
      qualificationType: QualificationType,
      geographicalState: string,
    } > | null,
    remoteVisitZoomLink?: string | null,
    remoteVisitZoomLinkQRCode?:  {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } | null,
    remoteVisitZoomLinkQRCodeS3ObjectID?: string | null,
    appointments?:  {
      __typename: "ModelAppointmentConnection",
      nextToken?: string | null,
    } | null,
    partialAppointmentsForTeamAvailabilityJSON?: string | null,
    matchableAndAcceptedAppointments?:  Array< {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListRemoteTechsQueryVariables = {
  id?: string | null,
  filter?: ModelRemoteTechFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListRemoteTechsQuery = {
  listRemoteTechs?:  {
    __typename: "ModelRemoteTechConnection",
    items:  Array< {
      __typename: "RemoteTech",
      id: string,
      email?: string | null,
      phone?: string | null,
      profilePictureS3ObjectID?: string | null,
      timezone?: Timezone | null,
      isActive: boolean,
      remoteVisitZoomLink?: string | null,
      remoteVisitZoomLinkQRCodeS3ObjectID?: string | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchRemoteTechsQueryVariables = {
  filter?: SearchableRemoteTechFilterInput | null,
  sort?: Array< SearchableRemoteTechSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableRemoteTechAggregationInput | null > | null,
};

export type SearchRemoteTechsQuery = {
  searchRemoteTechs?:  {
    __typename: "SearchableRemoteTechConnection",
    items:  Array< {
      __typename: "RemoteTech",
      id: string,
      email?: string | null,
      phone?: string | null,
      profilePictureS3ObjectID?: string | null,
      timezone?: Timezone | null,
      isActive: boolean,
      remoteVisitZoomLink?: string | null,
      remoteVisitZoomLinkQRCodeS3ObjectID?: string | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetCoordinatorQueryVariables = {
  id: string,
};

export type GetCoordinatorQuery = {
  getCoordinator?:  {
    __typename: "Coordinator",
    id: string,
    authorizedTechIDs?: Array< string > | null,
    name?:  {
      __typename: "HumanName",
      use: NameUse,
      family?: string | null,
      given?: Array< string > | null,
      prefix?: HumanNamePrefix | null,
      suffix?: string | null,
    } | null,
    // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
    isCSR?: boolean | null,
    isActive?: boolean | null,
    managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
    authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
    phone?: string | null,
    email?: string | null,
    consentToContactForAppointmentBySMS?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    billingCustomerID?: string | null,
    consentToContactForResearch?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    consentToContactForMarketing?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
    stripeCustomerID?: string | null,
    patients?:  {
      __typename: "ModelPatientConnection",
      nextToken?: string | null,
    } | null,
    isArchived?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCoordinatorsQueryVariables = {
  id?: string | null,
  filter?: ModelCoordinatorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCoordinatorsQuery = {
  listCoordinators?:  {
    __typename: "ModelCoordinatorConnection",
    items:  Array< {
      __typename: "Coordinator",
      id: string,
      authorizedTechIDs?: Array< string > | null,
      // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
      isCSR?: boolean | null,
      isActive?: boolean | null,
      managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
      authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
      phone?: string | null,
      email?: string | null,
      billingCustomerID?: string | null,
      outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
      stripeCustomerID?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CoordinatorsByPhoneQueryVariables = {
  phone: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCoordinatorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CoordinatorsByPhoneQuery = {
  coordinatorsByPhone?:  {
    __typename: "ModelCoordinatorConnection",
    items:  Array< {
      __typename: "Coordinator",
      id: string,
      authorizedTechIDs?: Array< string > | null,
      // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
      isCSR?: boolean | null,
      isActive?: boolean | null,
      managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
      authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
      phone?: string | null,
      email?: string | null,
      billingCustomerID?: string | null,
      outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
      stripeCustomerID?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CoordinatorsByEmailQueryVariables = {
  email: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCoordinatorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CoordinatorsByEmailQuery = {
  coordinatorsByEmail?:  {
    __typename: "ModelCoordinatorConnection",
    items:  Array< {
      __typename: "Coordinator",
      id: string,
      authorizedTechIDs?: Array< string > | null,
      // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
      isCSR?: boolean | null,
      isActive?: boolean | null,
      managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
      authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
      phone?: string | null,
      email?: string | null,
      billingCustomerID?: string | null,
      outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
      stripeCustomerID?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchCoordinatorsQueryVariables = {
  filter?: SearchableCoordinatorFilterInput | null,
  sort?: Array< SearchableCoordinatorSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableCoordinatorAggregationInput | null > | null,
};

export type SearchCoordinatorsQuery = {
  searchCoordinators?:  {
    __typename: "SearchableCoordinatorConnection",
    items:  Array< {
      __typename: "Coordinator",
      id: string,
      authorizedTechIDs?: Array< string > | null,
      // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
      isCSR?: boolean | null,
      isActive?: boolean | null,
      managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
      authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
      phone?: string | null,
      email?: string | null,
      billingCustomerID?: string | null,
      outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
      stripeCustomerID?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetPatientQueryVariables = {
  id: string,
};

export type GetPatientQuery = {
  getPatient?:  {
    __typename: "Patient",
    id: string,
    csrPartnerOrganization?: PartnerOrganization | null,
    partnerOrganization?: PartnerOrganization | null,
    location?:  {
      __typename: "Location",
      satellitePhotoURL?: string | null,
      streetViewPhotoURL?: string | null,
    } | null,
    coordinatorID: string,
    medicareCardID?: string | null,
    imageMoverUserID?: string | null,
    externalID?: string | null,
    secondaryExternalID?: string | null,
    specimenLabelID?: string | null,
    labOrganizationPatientIDs?:  Array< {
      __typename: "LabOrganizationPatientID",
      labOrganization: LabOrganization,
      externalID: string,
      planNameForLabOrdering: string,
    } > | null,
    qtMedicalPatientID?: string | null,
    planName?: string | null,
    diabeticType?: DiabeticType | null,
    authorizedTechIDs?: Array< string > | null,
    isCoordinator: boolean,
    // Phone & email to use in place of the coordinators for messaging. Used primarily for patients whose appointments are booked for them by a partner's customer service representatives.
    phone?: string | null,
    email?: string | null,
    secondaryPhone?: string | null,
    consentToContactForAppointmentBySMS?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    consentToContactForAppointmentByEmail?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    name:  {
      __typename: "HumanName",
      use: NameUse,
      family?: string | null,
      given?: Array< string > | null,
      prefix?: HumanNamePrefix | null,
      suffix?: string | null,
    },
    caregiverName?:  {
      __typename: "HumanName",
      use: NameUse,
      family?: string | null,
      given?: Array< string > | null,
      prefix?: HumanNamePrefix | null,
      suffix?: string | null,
    } | null,
    birthdate?: string | null,
    sex?: Sex | null,
    gender?: Gender | null,
    ethnicity?: Ethnicity | null,
    races?: Array< Race > | null,
    passportNumber?: string | null,
    insuranceCardUploadFront?:  {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } | null,
    insuranceCardUploadBack?:  {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } | null,
    insuranceCardUploadFrontS3ObjectID?: string | null,
    insuranceCardUploadBackS3ObjectID?: string | null,
    medicareCardUploadFront?:  {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } | null,
    medicareCardUploadBack?:  {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } | null,
    medicareCardUploadFrontS3ObjectID?: string | null,
    medicareCardUploadBackS3ObjectID?: string | null,
    encounters?:  {
      __typename: "ModelEncounterConnection",
      nextToken?: string | null,
    } | null,
    coordinator:  {
      __typename: "Coordinator",
      id: string,
      authorizedTechIDs?: Array< string > | null,
      // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
      isCSR?: boolean | null,
      isActive?: boolean | null,
      managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
      authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
      phone?: string | null,
      email?: string | null,
      billingCustomerID?: string | null,
      outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
      stripeCustomerID?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    commentsFromTechsAboutEncounters?:  Array< {
      __typename: "CommentsFromTechAboutEncounter",
      encounterID: string,
      appointmentStart: string,
      comments?: string | null,
    } > | null,
    bloodCollectionDetailsFromEncounters?:  Array< {
      __typename: "SpecimenCollectionDetailsFromEncounter",
      encounterID: string,
      appointmentStart: string,
      failureReason?: ServiceFailureReason | null,
    } > | null,
    capillaryDrawCollectionDetailsFromEncounters?:  Array< {
      __typename: "SpecimenCollectionDetailsFromEncounter",
      encounterID: string,
      appointmentStart: string,
      failureReason?: ServiceFailureReason | null,
    } > | null,
    referrals?:  {
      __typename: "ModelReferralConnection",
      nextToken?: string | null,
    } | null,
    careNavigationEpisodes?:  {
      __typename: "ModelCareNavigationEpisodeConnection",
      nextToken?: string | null,
    } | null,
    campaigns?:  {
      __typename: "ModelPatientCampaignStateConnection",
      nextToken?: string | null,
    } | null,
    watchlistedAppointmentID?: string | null,
    watchlistedAppointment?:  {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    responsiblePractitionerID?: string | null,
    responsiblePractitioner?:  {
      __typename: "Practitioner",
      id: string,
      // national provider identifier
      NPI: string,
      practitionerGroupName?: string | null,
      phone?: string | null,
      email?: string | null,
      fax?: string | null,
      faxConfirmationStatus?: FaxConfirmationStatus | null,
      faxLastConfirmedAt?: string | null,
      testFaxLastSentAt?: string | null,
      incorrectFaxes?: Array< string > | null,
      role?: PractitionerRole | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
    commentsFromCaregiverAboutResponsiblePractitioner?: string | null,
    consentToContactForMarketingSMS?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    consentToContactForMarketingEmail?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    consentToContactForMarketingPhone?:  {
      __typename: "Consent",
      permit: boolean,
      version: number,
    } | null,
    suggestedAppointmentLocation?:  {
      __typename: "Location",
      satellitePhotoURL?: string | null,
      streetViewPhotoURL?: string | null,
    } | null,
    suggestedAppointmentLocationAdditionalInformation?: string | null,
    mailingAddress?:  {
      __typename: "Address",
      use?: AddressUse | null,
      lines: Array< string >,
      subunit?: string | null,
      city: string,
      state: string,
      postalCode: string,
    } | null,
    preferredLanguage?: string | null,
    primaryLanguageBCP47?: string | null,
    secondaryLanguagesBCP47?: Array< string > | null,
    // A unique numerical patient reference number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
    patientNumber?: string | null,
    // latest validated gap list data referencing this patient.
    // - latestGapListUploadID: the upload ID of the latest ingestion for tracking purposes
    // - latestGapListRowJSON: the entire row
    // - latestGapListPassthroughJSON: a subset of the row for passing back to the partner with patient data in reports
    latestGapListUploadID?: string | null,
    latestGapListRowJSON?: string | null,
    latestGapListPassthroughJSON?: string | null,
    // A reference to a set of branding material provided for outreach.
    brandCode?: string | null,
    population?: PatientPopulation | null,
    elationID?: string | null,
    consents?:  Array< {
      __typename: "ConsentForm",
      consentedAt: string,
      type: ConsentType,
      platform: QuestionnairePlatform,
      formID: string,
      responseID: string,
      appointmentID: string,
      rawResponseJSON: string,
    } > | null,
    telecom?:  {
      __typename: "ModelPatientContactPointConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPatientsQueryVariables = {
  filter?: ModelPatientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPatientsQuery = {
  listPatients?:  {
    __typename: "ModelPatientConnection",
    items:  Array< {
      __typename: "Patient",
      id: string,
      csrPartnerOrganization?: PartnerOrganization | null,
      partnerOrganization?: PartnerOrganization | null,
      coordinatorID: string,
      medicareCardID?: string | null,
      imageMoverUserID?: string | null,
      externalID?: string | null,
      secondaryExternalID?: string | null,
      specimenLabelID?: string | null,
      qtMedicalPatientID?: string | null,
      planName?: string | null,
      diabeticType?: DiabeticType | null,
      authorizedTechIDs?: Array< string > | null,
      isCoordinator: boolean,
      // Phone & email to use in place of the coordinators for messaging. Used primarily for patients whose appointments are booked for them by a partner's customer service representatives.
      phone?: string | null,
      email?: string | null,
      secondaryPhone?: string | null,
      birthdate?: string | null,
      sex?: Sex | null,
      gender?: Gender | null,
      ethnicity?: Ethnicity | null,
      races?: Array< Race > | null,
      passportNumber?: string | null,
      insuranceCardUploadFrontS3ObjectID?: string | null,
      insuranceCardUploadBackS3ObjectID?: string | null,
      medicareCardUploadFrontS3ObjectID?: string | null,
      medicareCardUploadBackS3ObjectID?: string | null,
      watchlistedAppointmentID?: string | null,
      responsiblePractitionerID?: string | null,
      commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
      commentsFromCaregiverAboutResponsiblePractitioner?: string | null,
      suggestedAppointmentLocationAdditionalInformation?: string | null,
      preferredLanguage?: string | null,
      primaryLanguageBCP47?: string | null,
      secondaryLanguagesBCP47?: Array< string > | null,
      // A unique numerical patient reference number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
      patientNumber?: string | null,
      // latest validated gap list data referencing this patient.
      // - latestGapListUploadID: the upload ID of the latest ingestion for tracking purposes
      // - latestGapListRowJSON: the entire row
      // - latestGapListPassthroughJSON: a subset of the row for passing back to the partner with patient data in reports
      latestGapListUploadID?: string | null,
      latestGapListRowJSON?: string | null,
      latestGapListPassthroughJSON?: string | null,
      // A reference to a set of branding material provided for outreach.
      brandCode?: string | null,
      population?: PatientPopulation | null,
      elationID?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListPatientsByCSRPartnerOrganizationQueryVariables = {
  csrPartnerOrganization: PartnerOrganization,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPatientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPatientsByCSRPartnerOrganizationQuery = {
  listPatientsByCSRPartnerOrganization?:  {
    __typename: "ModelPatientConnection",
    items:  Array< {
      __typename: "Patient",
      id: string,
      csrPartnerOrganization?: PartnerOrganization | null,
      partnerOrganization?: PartnerOrganization | null,
      coordinatorID: string,
      medicareCardID?: string | null,
      imageMoverUserID?: string | null,
      externalID?: string | null,
      secondaryExternalID?: string | null,
      specimenLabelID?: string | null,
      qtMedicalPatientID?: string | null,
      planName?: string | null,
      diabeticType?: DiabeticType | null,
      authorizedTechIDs?: Array< string > | null,
      isCoordinator: boolean,
      // Phone & email to use in place of the coordinators for messaging. Used primarily for patients whose appointments are booked for them by a partner's customer service representatives.
      phone?: string | null,
      email?: string | null,
      secondaryPhone?: string | null,
      birthdate?: string | null,
      sex?: Sex | null,
      gender?: Gender | null,
      ethnicity?: Ethnicity | null,
      races?: Array< Race > | null,
      passportNumber?: string | null,
      insuranceCardUploadFrontS3ObjectID?: string | null,
      insuranceCardUploadBackS3ObjectID?: string | null,
      medicareCardUploadFrontS3ObjectID?: string | null,
      medicareCardUploadBackS3ObjectID?: string | null,
      watchlistedAppointmentID?: string | null,
      responsiblePractitionerID?: string | null,
      commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
      commentsFromCaregiverAboutResponsiblePractitioner?: string | null,
      suggestedAppointmentLocationAdditionalInformation?: string | null,
      preferredLanguage?: string | null,
      primaryLanguageBCP47?: string | null,
      secondaryLanguagesBCP47?: Array< string > | null,
      // A unique numerical patient reference number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
      patientNumber?: string | null,
      // latest validated gap list data referencing this patient.
      // - latestGapListUploadID: the upload ID of the latest ingestion for tracking purposes
      // - latestGapListRowJSON: the entire row
      // - latestGapListPassthroughJSON: a subset of the row for passing back to the partner with patient data in reports
      latestGapListUploadID?: string | null,
      latestGapListRowJSON?: string | null,
      latestGapListPassthroughJSON?: string | null,
      // A reference to a set of branding material provided for outreach.
      brandCode?: string | null,
      population?: PatientPopulation | null,
      elationID?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListPatientsByPartnerOrganizationAndByExternalIDQueryVariables = {
  partnerOrganization: PartnerOrganization,
  externalID?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPatientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPatientsByPartnerOrganizationAndByExternalIDQuery = {
  listPatientsByPartnerOrganizationAndByExternalID?:  {
    __typename: "ModelPatientConnection",
    items:  Array< {
      __typename: "Patient",
      id: string,
      csrPartnerOrganization?: PartnerOrganization | null,
      partnerOrganization?: PartnerOrganization | null,
      coordinatorID: string,
      medicareCardID?: string | null,
      imageMoverUserID?: string | null,
      externalID?: string | null,
      secondaryExternalID?: string | null,
      specimenLabelID?: string | null,
      qtMedicalPatientID?: string | null,
      planName?: string | null,
      diabeticType?: DiabeticType | null,
      authorizedTechIDs?: Array< string > | null,
      isCoordinator: boolean,
      // Phone & email to use in place of the coordinators for messaging. Used primarily for patients whose appointments are booked for them by a partner's customer service representatives.
      phone?: string | null,
      email?: string | null,
      secondaryPhone?: string | null,
      birthdate?: string | null,
      sex?: Sex | null,
      gender?: Gender | null,
      ethnicity?: Ethnicity | null,
      races?: Array< Race > | null,
      passportNumber?: string | null,
      insuranceCardUploadFrontS3ObjectID?: string | null,
      insuranceCardUploadBackS3ObjectID?: string | null,
      medicareCardUploadFrontS3ObjectID?: string | null,
      medicareCardUploadBackS3ObjectID?: string | null,
      watchlistedAppointmentID?: string | null,
      responsiblePractitionerID?: string | null,
      commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
      commentsFromCaregiverAboutResponsiblePractitioner?: string | null,
      suggestedAppointmentLocationAdditionalInformation?: string | null,
      preferredLanguage?: string | null,
      primaryLanguageBCP47?: string | null,
      secondaryLanguagesBCP47?: Array< string > | null,
      // A unique numerical patient reference number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
      patientNumber?: string | null,
      // latest validated gap list data referencing this patient.
      // - latestGapListUploadID: the upload ID of the latest ingestion for tracking purposes
      // - latestGapListRowJSON: the entire row
      // - latestGapListPassthroughJSON: a subset of the row for passing back to the partner with patient data in reports
      latestGapListUploadID?: string | null,
      latestGapListRowJSON?: string | null,
      latestGapListPassthroughJSON?: string | null,
      // A reference to a set of branding material provided for outreach.
      brandCode?: string | null,
      population?: PatientPopulation | null,
      elationID?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListPatientsByPartnerOrganizationByExternalIDQueryVariables = {
  partnerOrganization: PartnerOrganization,
  externalID?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPatientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPatientsByPartnerOrganizationByExternalIDQuery = {
  listPatientsByPartnerOrganizationByExternalID?:  {
    __typename: "ModelPatientConnection",
    items:  Array< {
      __typename: "Patient",
      id: string,
      csrPartnerOrganization?: PartnerOrganization | null,
      partnerOrganization?: PartnerOrganization | null,
      coordinatorID: string,
      medicareCardID?: string | null,
      imageMoverUserID?: string | null,
      externalID?: string | null,
      secondaryExternalID?: string | null,
      specimenLabelID?: string | null,
      qtMedicalPatientID?: string | null,
      planName?: string | null,
      diabeticType?: DiabeticType | null,
      authorizedTechIDs?: Array< string > | null,
      isCoordinator: boolean,
      // Phone & email to use in place of the coordinators for messaging. Used primarily for patients whose appointments are booked for them by a partner's customer service representatives.
      phone?: string | null,
      email?: string | null,
      secondaryPhone?: string | null,
      birthdate?: string | null,
      sex?: Sex | null,
      gender?: Gender | null,
      ethnicity?: Ethnicity | null,
      races?: Array< Race > | null,
      passportNumber?: string | null,
      insuranceCardUploadFrontS3ObjectID?: string | null,
      insuranceCardUploadBackS3ObjectID?: string | null,
      medicareCardUploadFrontS3ObjectID?: string | null,
      medicareCardUploadBackS3ObjectID?: string | null,
      watchlistedAppointmentID?: string | null,
      responsiblePractitionerID?: string | null,
      commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
      commentsFromCaregiverAboutResponsiblePractitioner?: string | null,
      suggestedAppointmentLocationAdditionalInformation?: string | null,
      preferredLanguage?: string | null,
      primaryLanguageBCP47?: string | null,
      secondaryLanguagesBCP47?: Array< string > | null,
      // A unique numerical patient reference number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
      patientNumber?: string | null,
      // latest validated gap list data referencing this patient.
      // - latestGapListUploadID: the upload ID of the latest ingestion for tracking purposes
      // - latestGapListRowJSON: the entire row
      // - latestGapListPassthroughJSON: a subset of the row for passing back to the partner with patient data in reports
      latestGapListUploadID?: string | null,
      latestGapListRowJSON?: string | null,
      latestGapListPassthroughJSON?: string | null,
      // A reference to a set of branding material provided for outreach.
      brandCode?: string | null,
      population?: PatientPopulation | null,
      elationID?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListPatientsByBirthdateByWatchlistedAppointmentIDQueryVariables = {
  birthdate: string,
  watchlistedAppointmentID?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPatientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPatientsByBirthdateByWatchlistedAppointmentIDQuery = {
  listPatientsByBirthdateByWatchlistedAppointmentID?:  {
    __typename: "ModelPatientConnection",
    items:  Array< {
      __typename: "Patient",
      id: string,
      csrPartnerOrganization?: PartnerOrganization | null,
      partnerOrganization?: PartnerOrganization | null,
      coordinatorID: string,
      medicareCardID?: string | null,
      imageMoverUserID?: string | null,
      externalID?: string | null,
      secondaryExternalID?: string | null,
      specimenLabelID?: string | null,
      qtMedicalPatientID?: string | null,
      planName?: string | null,
      diabeticType?: DiabeticType | null,
      authorizedTechIDs?: Array< string > | null,
      isCoordinator: boolean,
      // Phone & email to use in place of the coordinators for messaging. Used primarily for patients whose appointments are booked for them by a partner's customer service representatives.
      phone?: string | null,
      email?: string | null,
      secondaryPhone?: string | null,
      birthdate?: string | null,
      sex?: Sex | null,
      gender?: Gender | null,
      ethnicity?: Ethnicity | null,
      races?: Array< Race > | null,
      passportNumber?: string | null,
      insuranceCardUploadFrontS3ObjectID?: string | null,
      insuranceCardUploadBackS3ObjectID?: string | null,
      medicareCardUploadFrontS3ObjectID?: string | null,
      medicareCardUploadBackS3ObjectID?: string | null,
      watchlistedAppointmentID?: string | null,
      responsiblePractitionerID?: string | null,
      commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
      commentsFromCaregiverAboutResponsiblePractitioner?: string | null,
      suggestedAppointmentLocationAdditionalInformation?: string | null,
      preferredLanguage?: string | null,
      primaryLanguageBCP47?: string | null,
      secondaryLanguagesBCP47?: Array< string > | null,
      // A unique numerical patient reference number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
      patientNumber?: string | null,
      // latest validated gap list data referencing this patient.
      // - latestGapListUploadID: the upload ID of the latest ingestion for tracking purposes
      // - latestGapListRowJSON: the entire row
      // - latestGapListPassthroughJSON: a subset of the row for passing back to the partner with patient data in reports
      latestGapListUploadID?: string | null,
      latestGapListRowJSON?: string | null,
      latestGapListPassthroughJSON?: string | null,
      // A reference to a set of branding material provided for outreach.
      brandCode?: string | null,
      population?: PatientPopulation | null,
      elationID?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListPatientsByBirthdateByPartnerOrganizationQueryVariables = {
  birthdate: string,
  partnerOrganization?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPatientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPatientsByBirthdateByPartnerOrganizationQuery = {
  listPatientsByBirthdateByPartnerOrganization?:  {
    __typename: "ModelPatientConnection",
    items:  Array< {
      __typename: "Patient",
      id: string,
      csrPartnerOrganization?: PartnerOrganization | null,
      partnerOrganization?: PartnerOrganization | null,
      coordinatorID: string,
      medicareCardID?: string | null,
      imageMoverUserID?: string | null,
      externalID?: string | null,
      secondaryExternalID?: string | null,
      specimenLabelID?: string | null,
      qtMedicalPatientID?: string | null,
      planName?: string | null,
      diabeticType?: DiabeticType | null,
      authorizedTechIDs?: Array< string > | null,
      isCoordinator: boolean,
      // Phone & email to use in place of the coordinators for messaging. Used primarily for patients whose appointments are booked for them by a partner's customer service representatives.
      phone?: string | null,
      email?: string | null,
      secondaryPhone?: string | null,
      birthdate?: string | null,
      sex?: Sex | null,
      gender?: Gender | null,
      ethnicity?: Ethnicity | null,
      races?: Array< Race > | null,
      passportNumber?: string | null,
      insuranceCardUploadFrontS3ObjectID?: string | null,
      insuranceCardUploadBackS3ObjectID?: string | null,
      medicareCardUploadFrontS3ObjectID?: string | null,
      medicareCardUploadBackS3ObjectID?: string | null,
      watchlistedAppointmentID?: string | null,
      responsiblePractitionerID?: string | null,
      commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
      commentsFromCaregiverAboutResponsiblePractitioner?: string | null,
      suggestedAppointmentLocationAdditionalInformation?: string | null,
      preferredLanguage?: string | null,
      primaryLanguageBCP47?: string | null,
      secondaryLanguagesBCP47?: Array< string > | null,
      // A unique numerical patient reference number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
      patientNumber?: string | null,
      // latest validated gap list data referencing this patient.
      // - latestGapListUploadID: the upload ID of the latest ingestion for tracking purposes
      // - latestGapListRowJSON: the entire row
      // - latestGapListPassthroughJSON: a subset of the row for passing back to the partner with patient data in reports
      latestGapListUploadID?: string | null,
      latestGapListRowJSON?: string | null,
      latestGapListPassthroughJSON?: string | null,
      // A reference to a set of branding material provided for outreach.
      brandCode?: string | null,
      population?: PatientPopulation | null,
      elationID?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchPatientsQueryVariables = {
  filter?: SearchablePatientFilterInput | null,
  sort?: Array< SearchablePatientSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchablePatientAggregationInput | null > | null,
};

export type SearchPatientsQuery = {
  searchPatients?:  {
    __typename: "SearchablePatientConnection",
    items:  Array< {
      __typename: "Patient",
      id: string,
      csrPartnerOrganization?: PartnerOrganization | null,
      partnerOrganization?: PartnerOrganization | null,
      coordinatorID: string,
      medicareCardID?: string | null,
      imageMoverUserID?: string | null,
      externalID?: string | null,
      secondaryExternalID?: string | null,
      specimenLabelID?: string | null,
      qtMedicalPatientID?: string | null,
      planName?: string | null,
      diabeticType?: DiabeticType | null,
      authorizedTechIDs?: Array< string > | null,
      isCoordinator: boolean,
      // Phone & email to use in place of the coordinators for messaging. Used primarily for patients whose appointments are booked for them by a partner's customer service representatives.
      phone?: string | null,
      email?: string | null,
      secondaryPhone?: string | null,
      birthdate?: string | null,
      sex?: Sex | null,
      gender?: Gender | null,
      ethnicity?: Ethnicity | null,
      races?: Array< Race > | null,
      passportNumber?: string | null,
      insuranceCardUploadFrontS3ObjectID?: string | null,
      insuranceCardUploadBackS3ObjectID?: string | null,
      medicareCardUploadFrontS3ObjectID?: string | null,
      medicareCardUploadBackS3ObjectID?: string | null,
      watchlistedAppointmentID?: string | null,
      responsiblePractitionerID?: string | null,
      commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
      commentsFromCaregiverAboutResponsiblePractitioner?: string | null,
      suggestedAppointmentLocationAdditionalInformation?: string | null,
      preferredLanguage?: string | null,
      primaryLanguageBCP47?: string | null,
      secondaryLanguagesBCP47?: Array< string > | null,
      // A unique numerical patient reference number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
      patientNumber?: string | null,
      // latest validated gap list data referencing this patient.
      // - latestGapListUploadID: the upload ID of the latest ingestion for tracking purposes
      // - latestGapListRowJSON: the entire row
      // - latestGapListPassthroughJSON: a subset of the row for passing back to the partner with patient data in reports
      latestGapListUploadID?: string | null,
      latestGapListRowJSON?: string | null,
      latestGapListPassthroughJSON?: string | null,
      // A reference to a set of branding material provided for outreach.
      brandCode?: string | null,
      population?: PatientPopulation | null,
      elationID?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetPatientContactPointQueryVariables = {
  id: string,
};

export type GetPatientContactPointQuery = {
  getPatientContactPoint?:  {
    __typename: "PatientContactPoint",
    id: string,
    patientID: string,
    patient:  {
      __typename: "Patient",
      id: string,
      csrPartnerOrganization?: PartnerOrganization | null,
      partnerOrganization?: PartnerOrganization | null,
      coordinatorID: string,
      medicareCardID?: string | null,
      imageMoverUserID?: string | null,
      externalID?: string | null,
      secondaryExternalID?: string | null,
      specimenLabelID?: string | null,
      qtMedicalPatientID?: string | null,
      planName?: string | null,
      diabeticType?: DiabeticType | null,
      authorizedTechIDs?: Array< string > | null,
      isCoordinator: boolean,
      // Phone & email to use in place of the coordinators for messaging. Used primarily for patients whose appointments are booked for them by a partner's customer service representatives.
      phone?: string | null,
      email?: string | null,
      secondaryPhone?: string | null,
      birthdate?: string | null,
      sex?: Sex | null,
      gender?: Gender | null,
      ethnicity?: Ethnicity | null,
      races?: Array< Race > | null,
      passportNumber?: string | null,
      insuranceCardUploadFrontS3ObjectID?: string | null,
      insuranceCardUploadBackS3ObjectID?: string | null,
      medicareCardUploadFrontS3ObjectID?: string | null,
      medicareCardUploadBackS3ObjectID?: string | null,
      watchlistedAppointmentID?: string | null,
      responsiblePractitionerID?: string | null,
      commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
      commentsFromCaregiverAboutResponsiblePractitioner?: string | null,
      suggestedAppointmentLocationAdditionalInformation?: string | null,
      preferredLanguage?: string | null,
      primaryLanguageBCP47?: string | null,
      secondaryLanguagesBCP47?: Array< string > | null,
      // A unique numerical patient reference number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
      patientNumber?: string | null,
      // latest validated gap list data referencing this patient.
      // - latestGapListUploadID: the upload ID of the latest ingestion for tracking purposes
      // - latestGapListRowJSON: the entire row
      // - latestGapListPassthroughJSON: a subset of the row for passing back to the partner with patient data in reports
      latestGapListUploadID?: string | null,
      latestGapListRowJSON?: string | null,
      latestGapListPassthroughJSON?: string | null,
      // A reference to a set of branding material provided for outreach.
      brandCode?: string | null,
      population?: PatientPopulation | null,
      elationID?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    system: ContactPointSystem,
    value: string,
    status: ContactPointStatus,
    statusLastUpdatedAt?: string | null,
    sources?:  Array< {
      __typename: "PatientContactPointSource",
      source: ContactPointSource,
      // Specifies a preferred order in which to use a set of contacts. ContactPoints with lower rank values are more preferred than those with higher rank values.
      rank?: number | null,
      system: ContactPointSystem,
      // The date and time the source reports the contact point as last being utilized. For example in the LexisNexis report they include the last time they saw the contact point in their data. This differs from the last time they reported it to _us_.
      reportedAsLastSeenAt?: string | null,
      // The date and time the source first reported this contact point associated to the patient
      firstReportedAt?: string | null,
      // The date and time the source last reported this contact point associated to the patient
      lastReportedAt?: string | null,
      metadataJSON?: string | null,
    } > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPatientContactPointsQueryVariables = {
  filter?: ModelPatientContactPointFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPatientContactPointsQuery = {
  listPatientContactPoints?:  {
    __typename: "ModelPatientContactPointConnection",
    items:  Array< {
      __typename: "PatientContactPoint",
      id: string,
      patientID: string,
      system: ContactPointSystem,
      value: string,
      status: ContactPointStatus,
      statusLastUpdatedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListContactPointsByPatientQueryVariables = {
  patientID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPatientContactPointFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContactPointsByPatientQuery = {
  listContactPointsByPatient?:  {
    __typename: "ModelPatientContactPointConnection",
    items:  Array< {
      __typename: "PatientContactPoint",
      id: string,
      patientID: string,
      system: ContactPointSystem,
      value: string,
      status: ContactPointStatus,
      statusLastUpdatedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchPatientContactPointsQueryVariables = {
  filter?: SearchablePatientContactPointFilterInput | null,
  sort?: Array< SearchablePatientContactPointSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchablePatientContactPointAggregationInput | null > | null,
};

export type SearchPatientContactPointsQuery = {
  searchPatientContactPoints?:  {
    __typename: "SearchablePatientContactPointConnection",
    items:  Array< {
      __typename: "PatientContactPoint",
      id: string,
      patientID: string,
      system: ContactPointSystem,
      value: string,
      status: ContactPointStatus,
      statusLastUpdatedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetReferralQueryVariables = {
  id: string,
};

export type GetReferralQuery = {
  getReferral?:  {
    __typename: "Referral",
    id: string,
    appointments?:  {
      __typename: "ModelAppointmentConnection",
      nextToken?: string | null,
    } | null,
    patientID: string,
    patient:  {
      __typename: "Patient",
      id: string,
      csrPartnerOrganization?: PartnerOrganization | null,
      partnerOrganization?: PartnerOrganization | null,
      coordinatorID: string,
      medicareCardID?: string | null,
      imageMoverUserID?: string | null,
      externalID?: string | null,
      secondaryExternalID?: string | null,
      specimenLabelID?: string | null,
      qtMedicalPatientID?: string | null,
      planName?: string | null,
      diabeticType?: DiabeticType | null,
      authorizedTechIDs?: Array< string > | null,
      isCoordinator: boolean,
      // Phone & email to use in place of the coordinators for messaging. Used primarily for patients whose appointments are booked for them by a partner's customer service representatives.
      phone?: string | null,
      email?: string | null,
      secondaryPhone?: string | null,
      birthdate?: string | null,
      sex?: Sex | null,
      gender?: Gender | null,
      ethnicity?: Ethnicity | null,
      races?: Array< Race > | null,
      passportNumber?: string | null,
      insuranceCardUploadFrontS3ObjectID?: string | null,
      insuranceCardUploadBackS3ObjectID?: string | null,
      medicareCardUploadFrontS3ObjectID?: string | null,
      medicareCardUploadBackS3ObjectID?: string | null,
      watchlistedAppointmentID?: string | null,
      responsiblePractitionerID?: string | null,
      commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
      commentsFromCaregiverAboutResponsiblePractitioner?: string | null,
      suggestedAppointmentLocationAdditionalInformation?: string | null,
      preferredLanguage?: string | null,
      primaryLanguageBCP47?: string | null,
      secondaryLanguagesBCP47?: Array< string > | null,
      // A unique numerical patient reference number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
      patientNumber?: string | null,
      // latest validated gap list data referencing this patient.
      // - latestGapListUploadID: the upload ID of the latest ingestion for tracking purposes
      // - latestGapListRowJSON: the entire row
      // - latestGapListPassthroughJSON: a subset of the row for passing back to the partner with patient data in reports
      latestGapListUploadID?: string | null,
      latestGapListRowJSON?: string | null,
      latestGapListPassthroughJSON?: string | null,
      // A reference to a set of branding material provided for outreach.
      brandCode?: string | null,
      population?: PatientPopulation | null,
      elationID?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    partnerOrganization: PartnerOrganization,
    requisitions?:  Array< {
      __typename: "Requisition",
      id: string,
      labOrganization?: LabOrganization | null,
      // A unique numerical patient requisition number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
      requisitionNumber?: string | null,
    } > | null,
    requisitionUploads?:  Array< {
      __typename: "S3Object",
      identityID: string,
      bucket?: string | null,
      region?: string | null,
      key: string,
      // url accepts 2 params, accessLevel private | public, shouldFlatted is responsible for
      // making PDF form as regular PDF without editable fields (resolves issues during printing)
      url?: string | null,
    } > | null,
    requisitionUploadS3ObjectIDs?: Array< string > | null,
    serviceTypes: Array< ServiceType >,
    diagnosisCodes?:  Array< {
      __typename: "Coding",
      system: string,
      code: string,
      displayName?: string | null,
    } > | null,
    isBooked?: boolean | null,
    isExpired?: boolean | null,
    outreachOutcomesPerServiceRequest?:  Array< {
      __typename: "ServiceRequestOutreachOutcome",
      serviceRequestExternalID: string,
      productType?: ProductType | null,
      serviceTypes?: Array< ServiceType > | null,
      labOrganization: LabOrganization,
      outcome: string,
      notes?: string | null,
    } > | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListReferralsQueryVariables = {
  filter?: ModelReferralFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReferralsQuery = {
  listReferrals?:  {
    __typename: "ModelReferralConnection",
    items:  Array< {
      __typename: "Referral",
      id: string,
      patientID: string,
      partnerOrganization: PartnerOrganization,
      requisitionUploadS3ObjectIDs?: Array< string > | null,
      serviceTypes: Array< ServiceType >,
      isBooked?: boolean | null,
      isExpired?: boolean | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReferralsByPatientQueryVariables = {
  patientID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReferralFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReferralsByPatientQuery = {
  listReferralsByPatient?:  {
    __typename: "ModelReferralConnection",
    items:  Array< {
      __typename: "Referral",
      id: string,
      patientID: string,
      partnerOrganization: PartnerOrganization,
      requisitionUploadS3ObjectIDs?: Array< string > | null,
      serviceTypes: Array< ServiceType >,
      isBooked?: boolean | null,
      isExpired?: boolean | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchReferralsQueryVariables = {
  filter?: SearchableReferralFilterInput | null,
  sort?: Array< SearchableReferralSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableReferralAggregationInput | null > | null,
};

export type SearchReferralsQuery = {
  searchReferrals?:  {
    __typename: "SearchableReferralConnection",
    items:  Array< {
      __typename: "Referral",
      id: string,
      patientID: string,
      partnerOrganization: PartnerOrganization,
      requisitionUploadS3ObjectIDs?: Array< string > | null,
      serviceTypes: Array< ServiceType >,
      isBooked?: boolean | null,
      isExpired?: boolean | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetPatientCampaignStateQueryVariables = {
  id: string,
};

export type GetPatientCampaignStateQuery = {
  getPatientCampaignState?:  {
    __typename: "PatientCampaignState",
    id: string,
    patientID: string,
    patient:  {
      __typename: "Patient",
      id: string,
      csrPartnerOrganization?: PartnerOrganization | null,
      partnerOrganization?: PartnerOrganization | null,
      coordinatorID: string,
      medicareCardID?: string | null,
      imageMoverUserID?: string | null,
      externalID?: string | null,
      secondaryExternalID?: string | null,
      specimenLabelID?: string | null,
      qtMedicalPatientID?: string | null,
      planName?: string | null,
      diabeticType?: DiabeticType | null,
      authorizedTechIDs?: Array< string > | null,
      isCoordinator: boolean,
      // Phone & email to use in place of the coordinators for messaging. Used primarily for patients whose appointments are booked for them by a partner's customer service representatives.
      phone?: string | null,
      email?: string | null,
      secondaryPhone?: string | null,
      birthdate?: string | null,
      sex?: Sex | null,
      gender?: Gender | null,
      ethnicity?: Ethnicity | null,
      races?: Array< Race > | null,
      passportNumber?: string | null,
      insuranceCardUploadFrontS3ObjectID?: string | null,
      insuranceCardUploadBackS3ObjectID?: string | null,
      medicareCardUploadFrontS3ObjectID?: string | null,
      medicareCardUploadBackS3ObjectID?: string | null,
      watchlistedAppointmentID?: string | null,
      responsiblePractitionerID?: string | null,
      commentsFromPatientOrCaregiverAboutResponsiblePractitioner?: string | null,
      commentsFromCaregiverAboutResponsiblePractitioner?: string | null,
      suggestedAppointmentLocationAdditionalInformation?: string | null,
      preferredLanguage?: string | null,
      primaryLanguageBCP47?: string | null,
      secondaryLanguagesBCP47?: Array< string > | null,
      // A unique numerical patient reference number used for sending orders electronically. This should be only numeric and less than 10 digits. These are stored as strings so they can have leading zeros. The goal of leading zeros is be more easily readable in HL7 documents.
      patientNumber?: string | null,
      // latest validated gap list data referencing this patient.
      // - latestGapListUploadID: the upload ID of the latest ingestion for tracking purposes
      // - latestGapListRowJSON: the entire row
      // - latestGapListPassthroughJSON: a subset of the row for passing back to the partner with patient data in reports
      latestGapListUploadID?: string | null,
      latestGapListRowJSON?: string | null,
      latestGapListPassthroughJSON?: string | null,
      // A reference to a set of branding material provided for outreach.
      brandCode?: string | null,
      population?: PatientPopulation | null,
      elationID?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    name: PatientCampaignStateName,
    campaignName: CampaignName,
    eligibilityStart: string,
    eligibilityEnd: string,
    cohort?: number | null,
    contextJSON: string,
    enteredAt: number,
    partnerOrganization: PartnerOrganization,
    previousStateName?: PatientCampaignStateName | null,
    reasonForTransitionToTerminalState?: ReasonForTransitionToTerminalState | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPatientCampaignStatesQueryVariables = {
  filter?: ModelPatientCampaignStateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPatientCampaignStatesQuery = {
  listPatientCampaignStates?:  {
    __typename: "ModelPatientCampaignStateConnection",
    items:  Array< {
      __typename: "PatientCampaignState",
      id: string,
      patientID: string,
      name: PatientCampaignStateName,
      campaignName: CampaignName,
      eligibilityStart: string,
      eligibilityEnd: string,
      cohort?: number | null,
      contextJSON: string,
      enteredAt: number,
      partnerOrganization: PartnerOrganization,
      previousStateName?: PatientCampaignStateName | null,
      reasonForTransitionToTerminalState?: ReasonForTransitionToTerminalState | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCampaignsByPatientQueryVariables = {
  patientID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPatientCampaignStateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCampaignsByPatientQuery = {
  listCampaignsByPatient?:  {
    __typename: "ModelPatientCampaignStateConnection",
    items:  Array< {
      __typename: "PatientCampaignState",
      id: string,
      patientID: string,
      name: PatientCampaignStateName,
      campaignName: CampaignName,
      eligibilityStart: string,
      eligibilityEnd: string,
      cohort?: number | null,
      contextJSON: string,
      enteredAt: number,
      partnerOrganization: PartnerOrganization,
      previousStateName?: PatientCampaignStateName | null,
      reasonForTransitionToTerminalState?: ReasonForTransitionToTerminalState | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListPatientCampaignStatesByNameQueryVariables = {
  name: PatientCampaignStateName,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPatientCampaignStateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPatientCampaignStatesByNameQuery = {
  listPatientCampaignStatesByName?:  {
    __typename: "ModelPatientCampaignStateConnection",
    items:  Array< {
      __typename: "PatientCampaignState",
      id: string,
      patientID: string,
      name: PatientCampaignStateName,
      campaignName: CampaignName,
      eligibilityStart: string,
      eligibilityEnd: string,
      cohort?: number | null,
      contextJSON: string,
      enteredAt: number,
      partnerOrganization: PartnerOrganization,
      previousStateName?: PatientCampaignStateName | null,
      reasonForTransitionToTerminalState?: ReasonForTransitionToTerminalState | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListPatientCampaignStatesByCampaignNameQueryVariables = {
  campaignName: CampaignName,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPatientCampaignStateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPatientCampaignStatesByCampaignNameQuery = {
  listPatientCampaignStatesByCampaignName?:  {
    __typename: "ModelPatientCampaignStateConnection",
    items:  Array< {
      __typename: "PatientCampaignState",
      id: string,
      patientID: string,
      name: PatientCampaignStateName,
      campaignName: CampaignName,
      eligibilityStart: string,
      eligibilityEnd: string,
      cohort?: number | null,
      contextJSON: string,
      enteredAt: number,
      partnerOrganization: PartnerOrganization,
      previousStateName?: PatientCampaignStateName | null,
      reasonForTransitionToTerminalState?: ReasonForTransitionToTerminalState | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchPatientCampaignStatesQueryVariables = {
  filter?: SearchablePatientCampaignStateFilterInput | null,
  sort?: Array< SearchablePatientCampaignStateSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchablePatientCampaignStateAggregationInput | null > | null,
};

export type SearchPatientCampaignStatesQuery = {
  searchPatientCampaignStates?:  {
    __typename: "SearchablePatientCampaignStateConnection",
    items:  Array< {
      __typename: "PatientCampaignState",
      id: string,
      patientID: string,
      name: PatientCampaignStateName,
      campaignName: CampaignName,
      eligibilityStart: string,
      eligibilityEnd: string,
      cohort?: number | null,
      contextJSON: string,
      enteredAt: number,
      partnerOrganization: PartnerOrganization,
      previousStateName?: PatientCampaignStateName | null,
      reasonForTransitionToTerminalState?: ReasonForTransitionToTerminalState | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetProjectedEngagementActivityScheduleQueryVariables = {
  id: string,
};

export type GetProjectedEngagementActivityScheduleQuery = {
  getProjectedEngagementActivitySchedule?:  {
    __typename: "ProjectedEngagementActivitySchedule",
    id: string,
    // A friendly(-ish) name for the schedule.
    name: string,
    // The status of the schedule. This largely serves as a partition key so we can query for schedules sorted by accepted at.
    status: ProjectedEngagementActivityScheduleStatus,
    acceptedAt?: string | null,
    totalBatches?: number | null,
    processedBatches?: number | null,
    parametersJSON?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListProjectedEngagementActivitySchedulesQueryVariables = {
  filter?: ModelProjectedEngagementActivityScheduleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProjectedEngagementActivitySchedulesQuery = {
  listProjectedEngagementActivitySchedules?:  {
    __typename: "ModelProjectedEngagementActivityScheduleConnection",
    items:  Array< {
      __typename: "ProjectedEngagementActivitySchedule",
      id: string,
      // A friendly(-ish) name for the schedule.
      name: string,
      // The status of the schedule. This largely serves as a partition key so we can query for schedules sorted by accepted at.
      status: ProjectedEngagementActivityScheduleStatus,
      acceptedAt?: string | null,
      totalBatches?: number | null,
      processedBatches?: number | null,
      parametersJSON?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchProjectedEngagementActivitySchedulesQueryVariables = {
  filter?: SearchableProjectedEngagementActivityScheduleFilterInput | null,
  sort?: Array< SearchableProjectedEngagementActivityScheduleSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableProjectedEngagementActivityScheduleAggregationInput | null > | null,
};

export type SearchProjectedEngagementActivitySchedulesQuery = {
  searchProjectedEngagementActivitySchedules?:  {
    __typename: "SearchableProjectedEngagementActivityScheduleConnection",
    items:  Array< {
      __typename: "ProjectedEngagementActivitySchedule",
      id: string,
      // A friendly(-ish) name for the schedule.
      name: string,
      // The status of the schedule. This largely serves as a partition key so we can query for schedules sorted by accepted at.
      status: ProjectedEngagementActivityScheduleStatus,
      acceptedAt?: string | null,
      totalBatches?: number | null,
      processedBatches?: number | null,
      parametersJSON?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetUnlinkableReportQueryVariables = {
  id: string,
};

export type GetUnlinkableReportQuery = {
  getUnlinkableReport?:  {
    __typename: "UnlinkableReport",
    id: string,
    externalID: string,
    documents:  Array< {
      __typename: "Document",
      type?: DocumentType | null,
      url?: string | null,
      format?: DocumentFormat | null,
      s3ObjectID?: string | null,
      uploadedAt?: number | null,
      // stringified JSON object as a set of additional data we want to store about documents (requisition number, lab report status, etc.)
      metadataJSON?: string | null,
    } >,
    zendeskTicketID?: number | null,
    unlinkedReason: UnlinkedReason,
    hasBeenResolved?: boolean | null,
    patientName?:  {
      __typename: "HumanName",
      use: NameUse,
      family?: string | null,
      given?: Array< string > | null,
      prefix?: HumanNamePrefix | null,
      suffix?: string | null,
    } | null,
    patientNumber?: string | null,
    requisitionNumber?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUnlinkableReportsQueryVariables = {
  id?: string | null,
  filter?: ModelUnlinkableReportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUnlinkableReportsQuery = {
  listUnlinkableReports?:  {
    __typename: "ModelUnlinkableReportConnection",
    items:  Array< {
      __typename: "UnlinkableReport",
      id: string,
      externalID: string,
      zendeskTicketID?: number | null,
      unlinkedReason: UnlinkedReason,
      hasBeenResolved?: boolean | null,
      patientNumber?: string | null,
      requisitionNumber?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchUnlinkableReportsQueryVariables = {
  filter?: SearchableUnlinkableReportFilterInput | null,
  sort?: Array< SearchableUnlinkableReportSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableUnlinkableReportAggregationInput | null > | null,
};

export type SearchUnlinkableReportsQuery = {
  searchUnlinkableReports?:  {
    __typename: "SearchableUnlinkableReportConnection",
    items:  Array< {
      __typename: "UnlinkableReport",
      id: string,
      externalID: string,
      zendeskTicketID?: number | null,
      unlinkedReason: UnlinkedReason,
      hasBeenResolved?: boolean | null,
      patientNumber?: string | null,
      requisitionNumber?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetDropOffQueryVariables = {
  id: string,
};

export type GetDropOffQuery = {
  getDropOff?:  {
    __typename: "DropOff",
    id: string,
    organization: DropOffOrganization,
    name: string,
    phone?: string | null,
    email?: string | null,
    location:  {
      __typename: "Location",
      satellitePhotoURL?: string | null,
      streetViewPhotoURL?: string | null,
    },
    // Timezone generated based on the lat/long location of this dropoff
    timezone?: Timezone | null,
    availabilityRules:  Array< {
      __typename: "TimeRangeRule",
      // The unique ID for the time range.
      id: string,
      // Whether the time range represents an addition or subtraction when considering multiple ranges.
      inclusion: TimeRangeRuleInclusion,
      // The time of day each recurrence starts (inclusive).
      start: string,
      // The time of day each recurrence ends (exclusive). Use '24:00:00' for the end of the day.
      end: string,
      // The first date that the recurrence is valid (inclusive).
      recurrenceStart: string,
      // The last date that the recurrence is valid (inclusive). If not set, the range extends indefinitely.
      recurrenceEnd?: string | null,
      // The number of periods between each recurrence. For example, with a multiple of N and period of DAY, the time range will recur every N days.
      recurrenceMultiple: number,
      // The unit of recurrence. For example, with a period of WEEK and multiple of 2, the time range will recur every other week.
      recurrencePeriod: TimeRangeRulePeriod,
      // An optional reason for the time range rule, used with time-off unavailability rules (lunch breaks, PTO, etc.)
      unavailabilityReason?: TimeRangeRuleUnavailabilityReason | null,
      // Additional notes about the time range rule.
      notes?: string | null,
      // The date and time that the rule was last updated.
      updatedAt?: string | null,
      // The user who last updated the rule.
      updatedBy?: string | null,
    } >,
    hasParking?: boolean | null,
    hasDropBox?: boolean | null,
    commentsFromScheduler?: string | null,
    googleMapsPlaceID?: string | null,
    metroName: string,
    isActive: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDropOffsQueryVariables = {
  id?: string | null,
  filter?: ModelDropOffFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDropOffsQuery = {
  listDropOffs?:  {
    __typename: "ModelDropOffConnection",
    items:  Array< {
      __typename: "DropOff",
      id: string,
      organization: DropOffOrganization,
      name: string,
      phone?: string | null,
      email?: string | null,
      // Timezone generated based on the lat/long location of this dropoff
      timezone?: Timezone | null,
      hasParking?: boolean | null,
      hasDropBox?: boolean | null,
      commentsFromScheduler?: string | null,
      googleMapsPlaceID?: string | null,
      metroName: string,
      isActive: boolean,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListDropOffsByMetroNameQueryVariables = {
  metroName: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDropOffFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDropOffsByMetroNameQuery = {
  listDropOffsByMetroName?:  {
    __typename: "ModelDropOffConnection",
    items:  Array< {
      __typename: "DropOff",
      id: string,
      organization: DropOffOrganization,
      name: string,
      phone?: string | null,
      email?: string | null,
      // Timezone generated based on the lat/long location of this dropoff
      timezone?: Timezone | null,
      hasParking?: boolean | null,
      hasDropBox?: boolean | null,
      commentsFromScheduler?: string | null,
      googleMapsPlaceID?: string | null,
      metroName: string,
      isActive: boolean,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchDropOffsQueryVariables = {
  filter?: SearchableDropOffFilterInput | null,
  sort?: Array< SearchableDropOffSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableDropOffAggregationInput | null > | null,
};

export type SearchDropOffsQuery = {
  searchDropOffs?:  {
    __typename: "SearchableDropOffConnection",
    items:  Array< {
      __typename: "DropOff",
      id: string,
      organization: DropOffOrganization,
      name: string,
      phone?: string | null,
      email?: string | null,
      // Timezone generated based on the lat/long location of this dropoff
      timezone?: Timezone | null,
      hasParking?: boolean | null,
      hasDropBox?: boolean | null,
      commentsFromScheduler?: string | null,
      googleMapsPlaceID?: string | null,
      metroName: string,
      isActive: boolean,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetServiceRequestTypeQueryVariables = {
  id: string,
};

export type GetServiceRequestTypeQuery = {
  getServiceRequestType?:  {
    __typename: "ServiceRequestType",
    id: string,
    cptCodes: Array< string | null >,
    externalID: string,
    displayName: string,
    // Alternate names for service requests, to be used in service request lookup
    synonyms?: Array< string | null > | null,
    labOrganization: LabOrganization,
    container?: string | null,
    serviceRequestTypeContainerID?: string | null,
    serviceRequestTypeContainer?:  {
      __typename: "ServiceRequestTypeContainer",
      id: string,
      containerType: string,
      alternativeContainerTypes?: Array< string > | null,
      substituteContainerTypesForCalculations?: Array< string > | null,
      containerCategory: string,
      capacity: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    specimen?: string | null,
    preferredSpecimen?: string | null,
    preferredVolumeDescription?: string | null,
    preferredVolumeMilliliters?: number | null,
    minimumVolume?: string | null,
    minimumVolumeAmount?: number | null,
    alternateSpecimen?: string | null,
    transportInstructions?: string | null,
    collectionInstructions?: string | null,
    collectionInstructionsVideoURL?: string | null,
    rejectionCriteria?: string | null,
    specimenStability?: string | null,
    patientPreparation?: string | null,
    resultAvailability?: string | null,
    serviceTypes?: Array< ServiceType > | null,
    containerSpecimenVolumes?:  Array< {
      __typename: "SpecimenContainerSpecimenVolumes",
      // The ID of the container assigned to a serviceRequest, only applicable for non-combinable serviceRequests
      containerOverrideID?: string | null,
      // The type of tube e.g., SST, LAV EDTA, Urine Cup
      containerCategory?: SpecimenContainerCategory | null,
      // The minimum volume needed for a test
      minimumMilliliters?: number | null,
      // The preferred volume for a test in case we do not have minumum volume set above.
      preferredMilliliters?: number | null,
      // Whether it's acceptable to combine the specimens for this test and others into a single container during container calculations.
      // For example, if true, then blood for two tests requiring 2 mL of whole blood each can be drawn into a single 5 mL tube rather than two separate 3 mL tubes.
      isCombinable: boolean,
      // minimum container count needed for the specimen
      minimumContainerCount?: number | null,
      specimenTestTypes?: Array< SpecimenTestType | null > | null,
    } > | null,
    abstentions?:  Array< {
      __typename: "Abstention",
      abstentionType: AbstentionType,
      preferredDurationMinutes: number,
      requiredDurationMinutes?: number | null,
    } > | null,
    customKit?:  {
      __typename: "ServiceRequestCustomKit",
      photoURL: string,
      barcodePhotoURL?: string | null,
      recipient: CustomKitRecipient,
      // requires test requisition form (TRF) completion
      requiresTRFCompletion: boolean,
      // requires benefit correction form (BCF) completion
      requiresBCFCompletion: boolean,
    } | null,
    collectionTimeRules?:  Array< {
      __typename: "TimeRangeRule",
      // The unique ID for the time range.
      id: string,
      // Whether the time range represents an addition or subtraction when considering multiple ranges.
      inclusion: TimeRangeRuleInclusion,
      // The time of day each recurrence starts (inclusive).
      start: string,
      // The time of day each recurrence ends (exclusive). Use '24:00:00' for the end of the day.
      end: string,
      // The first date that the recurrence is valid (inclusive).
      recurrenceStart: string,
      // The last date that the recurrence is valid (inclusive). If not set, the range extends indefinitely.
      recurrenceEnd?: string | null,
      // The number of periods between each recurrence. For example, with a multiple of N and period of DAY, the time range will recur every N days.
      recurrenceMultiple: number,
      // The unit of recurrence. For example, with a period of WEEK and multiple of 2, the time range will recur every other week.
      recurrencePeriod: TimeRangeRulePeriod,
      // An optional reason for the time range rule, used with time-off unavailability rules (lunch breaks, PTO, etc.)
      unavailabilityReason?: TimeRangeRuleUnavailabilityReason | null,
      // Additional notes about the time range rule.
      notes?: string | null,
      // The date and time that the rule was last updated.
      updatedAt?: string | null,
      // The user who last updated the rule.
      updatedBy?: string | null,
    } > | null,
    requiresBinaryPatientSex?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListServiceRequestTypesQueryVariables = {
  id?: string | null,
  filter?: ModelServiceRequestTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListServiceRequestTypesQuery = {
  listServiceRequestTypes?:  {
    __typename: "ModelServiceRequestTypeConnection",
    items:  Array< {
      __typename: "ServiceRequestType",
      id: string,
      cptCodes: Array< string | null >,
      externalID: string,
      displayName: string,
      // Alternate names for service requests, to be used in service request lookup
      synonyms?: Array< string | null > | null,
      labOrganization: LabOrganization,
      container?: string | null,
      serviceRequestTypeContainerID?: string | null,
      specimen?: string | null,
      preferredSpecimen?: string | null,
      preferredVolumeDescription?: string | null,
      preferredVolumeMilliliters?: number | null,
      minimumVolume?: string | null,
      minimumVolumeAmount?: number | null,
      alternateSpecimen?: string | null,
      transportInstructions?: string | null,
      collectionInstructions?: string | null,
      collectionInstructionsVideoURL?: string | null,
      rejectionCriteria?: string | null,
      specimenStability?: string | null,
      patientPreparation?: string | null,
      resultAvailability?: string | null,
      serviceTypes?: Array< ServiceType > | null,
      requiresBinaryPatientSex?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListServiceRequestTypeByExternalIDByLabOrganizationQueryVariables = {
  externalID: string,
  labOrganization?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelServiceRequestTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListServiceRequestTypeByExternalIDByLabOrganizationQuery = {
  listServiceRequestTypeByExternalIDByLabOrganization?:  {
    __typename: "ModelServiceRequestTypeConnection",
    items:  Array< {
      __typename: "ServiceRequestType",
      id: string,
      cptCodes: Array< string | null >,
      externalID: string,
      displayName: string,
      // Alternate names for service requests, to be used in service request lookup
      synonyms?: Array< string | null > | null,
      labOrganization: LabOrganization,
      container?: string | null,
      serviceRequestTypeContainerID?: string | null,
      specimen?: string | null,
      preferredSpecimen?: string | null,
      preferredVolumeDescription?: string | null,
      preferredVolumeMilliliters?: number | null,
      minimumVolume?: string | null,
      minimumVolumeAmount?: number | null,
      alternateSpecimen?: string | null,
      transportInstructions?: string | null,
      collectionInstructions?: string | null,
      collectionInstructionsVideoURL?: string | null,
      rejectionCriteria?: string | null,
      specimenStability?: string | null,
      patientPreparation?: string | null,
      resultAvailability?: string | null,
      serviceTypes?: Array< ServiceType > | null,
      requiresBinaryPatientSex?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchServiceRequestTypesQueryVariables = {
  filter?: SearchableServiceRequestTypeFilterInput | null,
  sort?: Array< SearchableServiceRequestTypeSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableServiceRequestTypeAggregationInput | null > | null,
};

export type SearchServiceRequestTypesQuery = {
  searchServiceRequestTypes?:  {
    __typename: "SearchableServiceRequestTypeConnection",
    items:  Array< {
      __typename: "ServiceRequestType",
      id: string,
      cptCodes: Array< string | null >,
      externalID: string,
      displayName: string,
      // Alternate names for service requests, to be used in service request lookup
      synonyms?: Array< string | null > | null,
      labOrganization: LabOrganization,
      container?: string | null,
      serviceRequestTypeContainerID?: string | null,
      specimen?: string | null,
      preferredSpecimen?: string | null,
      preferredVolumeDescription?: string | null,
      preferredVolumeMilliliters?: number | null,
      minimumVolume?: string | null,
      minimumVolumeAmount?: number | null,
      alternateSpecimen?: string | null,
      transportInstructions?: string | null,
      collectionInstructions?: string | null,
      collectionInstructionsVideoURL?: string | null,
      rejectionCriteria?: string | null,
      specimenStability?: string | null,
      patientPreparation?: string | null,
      resultAvailability?: string | null,
      serviceTypes?: Array< ServiceType > | null,
      requiresBinaryPatientSex?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetSpecimenContainerTypeQueryVariables = {
  id: string,
};

export type GetSpecimenContainerTypeQuery = {
  getSpecimenContainerType?:  {
    __typename: "SpecimenContainerType",
    id: string,
    name: string,
    category: SpecimenContainerCategory,
    // The whole capacity of the container. This would be the "whole blood capacity" for SST containers and the
    // general capacity for the containers which are not used for serum tests like Urine Cups and the Insure FOBT.
    capacityMilliliters: number,
    // Serum Capacity. The capacity of the container. Usually this is the volume of the container,
    // but in some cases the relevant capacity might reflect only a portion of the overall volume
    // (e.g. serum capacity in an SST, where the remaining volume is filled with clotted blood components).
    serumBloodCapacityMilliliters?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSpecimenContainerTypesQueryVariables = {
  id?: string | null,
  filter?: ModelSpecimenContainerTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListSpecimenContainerTypesQuery = {
  listSpecimenContainerTypes?:  {
    __typename: "ModelSpecimenContainerTypeConnection",
    items:  Array< {
      __typename: "SpecimenContainerType",
      id: string,
      name: string,
      category: SpecimenContainerCategory,
      // The whole capacity of the container. This would be the "whole blood capacity" for SST containers and the
      // general capacity for the containers which are not used for serum tests like Urine Cups and the Insure FOBT.
      capacityMilliliters: number,
      // Serum Capacity. The capacity of the container. Usually this is the volume of the container,
      // but in some cases the relevant capacity might reflect only a portion of the overall volume
      // (e.g. serum capacity in an SST, where the remaining volume is filled with clotted blood components).
      serumBloodCapacityMilliliters?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchSpecimenContainerTypesQueryVariables = {
  filter?: SearchableSpecimenContainerTypeFilterInput | null,
  sort?: Array< SearchableSpecimenContainerTypeSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableSpecimenContainerTypeAggregationInput | null > | null,
};

export type SearchSpecimenContainerTypesQuery = {
  searchSpecimenContainerTypes?:  {
    __typename: "SearchableSpecimenContainerTypeConnection",
    items:  Array< {
      __typename: "SpecimenContainerType",
      id: string,
      name: string,
      category: SpecimenContainerCategory,
      // The whole capacity of the container. This would be the "whole blood capacity" for SST containers and the
      // general capacity for the containers which are not used for serum tests like Urine Cups and the Insure FOBT.
      capacityMilliliters: number,
      // Serum Capacity. The capacity of the container. Usually this is the volume of the container,
      // but in some cases the relevant capacity might reflect only a portion of the overall volume
      // (e.g. serum capacity in an SST, where the remaining volume is filled with clotted blood components).
      serumBloodCapacityMilliliters?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetServiceRequestTypeContainerQueryVariables = {
  id: string,
};

export type GetServiceRequestTypeContainerQuery = {
  getServiceRequestTypeContainer?:  {
    __typename: "ServiceRequestTypeContainer",
    id: string,
    containerType: string,
    alternativeContainerTypes?: Array< string > | null,
    substituteContainerTypesForCalculations?: Array< string > | null,
    containerCategory: string,
    capacity: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListServiceRequestTypeContainersQueryVariables = {
  id?: string | null,
  filter?: ModelServiceRequestTypeContainerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListServiceRequestTypeContainersQuery = {
  listServiceRequestTypeContainers?:  {
    __typename: "ModelServiceRequestTypeContainerConnection",
    items:  Array< {
      __typename: "ServiceRequestTypeContainer",
      id: string,
      containerType: string,
      alternativeContainerTypes?: Array< string > | null,
      substituteContainerTypesForCalculations?: Array< string > | null,
      containerCategory: string,
      capacity: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListServiceRequestTypeContainersByContainerTypeQueryVariables = {
  containerType: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelServiceRequestTypeContainerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListServiceRequestTypeContainersByContainerTypeQuery = {
  listServiceRequestTypeContainersByContainerType?:  {
    __typename: "ModelServiceRequestTypeContainerConnection",
    items:  Array< {
      __typename: "ServiceRequestTypeContainer",
      id: string,
      containerType: string,
      alternativeContainerTypes?: Array< string > | null,
      substituteContainerTypesForCalculations?: Array< string > | null,
      containerCategory: string,
      capacity: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchServiceRequestTypeContainersQueryVariables = {
  filter?: SearchableServiceRequestTypeContainerFilterInput | null,
  sort?: Array< SearchableServiceRequestTypeContainerSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableServiceRequestTypeContainerAggregationInput | null > | null,
};

export type SearchServiceRequestTypeContainersQuery = {
  searchServiceRequestTypeContainers?:  {
    __typename: "SearchableServiceRequestTypeContainerConnection",
    items:  Array< {
      __typename: "ServiceRequestTypeContainer",
      id: string,
      containerType: string,
      alternativeContainerTypes?: Array< string > | null,
      substituteContainerTypesForCalculations?: Array< string > | null,
      containerCategory: string,
      capacity: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetTrickleConfigurationQueryVariables = {
};

export type GetTrickleConfigurationQuery = {
  getTrickleConfiguration?:  {
    __typename: "TrickleConfigurationResponse",
    configuration: string,
  } | null,
};

export type ListAvailableAppointmentsQueryVariables = {
  appointmentID?: string | null,
  partnerOrganization?: PartnerOrganization | null,
  serviceTypes?: Array< ServiceType > | null,
  start?: string | null,
  end?: string | null,
  location?: LocationInput | null,
  forceTechID?: string | null,
  shouldCalculateSlotScore?: boolean | null,
  patientIDs?: Array< string > | null,
  patientBirthdates?: Array< string > | null,
};

export type ListAvailableAppointmentsQuery = {
  listAvailableAppointments?:  Array< {
    __typename: "AvailableAppointment",
    start: string,
    end: string,
    slotScore?: number | null,
  } | null > | null,
};

export type ListAppointmentsForPostReviewQueryVariables = {
  start: string,
};

export type ListAppointmentsForPostReviewQuery = {
  listAppointmentsForPostReview?:  {
    __typename: "ListAppointmentsForPostReviewResponse",
    appointments?:  Array< {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } > | null,
  } | null,
};

export type ExportTechScheduleQueryVariables = {
  input: ExportTechScheduleInput,
};

export type ExportTechScheduleQuery = {
  exportTechSchedule?:  {
    __typename: "ExportTechScheduleResponse",
    exportedTechSchedule: string,
  } | null,
};

export type ListBookedAppointmentsQueryVariables = {
  input: BookedAppointmentsInput,
};

export type ListBookedAppointmentsQuery = {
  listBookedAppointments?:  Array< {
    __typename: "Appointment",
    id: string,
    // The current status of the appointment (See AppointmentStatus enum)
    status: AppointmentStatus,
    // Whether the appointment has been marked failed
    isFailed?: boolean | null,
    // Whether the appointment has at least one service completed in any of the encounters
    hasSuccessfulServices?: boolean | null,
    // Whether the appointment has at least one service failed in any of the encounters
    hasFailedServices?: boolean | null,
    // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
    needsRebooking?: boolean | null,
    // Whether the appointment has passed the pre-review phase
    isPreReviewed?: boolean | null,
    // Whether the appointment has passed the post-review phase
    isPostReviewed?: boolean | null,
    // Whether the appointment has been escalated
    isEscalated?: boolean | null,
    // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
    start: string,
    // Expected appointment end date/time
    end: string,
    // The technician assigned to the appointment
    techID?: string | null,
    remoteTechID?: string | null,
    remoteTechMatchState?: RemoteTechMatchState | null,
    coordinatorID: string,
    location:  {
      __typename: "Location",
      satellitePhotoURL?: string | null,
      streetViewPhotoURL?: string | null,
    },
    partnerOrganization?: PartnerOrganization | null,
    preReviewChecklist?:  {
      __typename: "Checklist",
    } | null,
    checklist?:  {
      __typename: "Checklist",
    } | null,
    postReviewChecklist?:  {
      __typename: "Checklist",
    } | null,
    serviceRegionName: string,
    geographicalState?: string | null,
    avoidTechIDs?: Array< string > | null,
    lastBookedAt?: number | null,
    statusUpdatedAt?: number | null,
    stripeCustomerID?: string | null,
    // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
    stripePriceToCharge?: number | null,
    hasPetsAtLocation?: boolean | null,
    // Comments about logistics like door codes, directions, etc.
    commentsFromCoordinatorAboutAppointment?: string | null,
    // Comments about logistics like door codes, directions, etc. (but from techs)
    commentsFromTechAboutAppointment?: string | null,
    // Comments about app feedback, bugs, feature requests, etc.
    commentsFromTechAboutSystem?: string | null,
    // Comments about why an appointment was put into a failed state
    commentsFromTechAboutAppointmentFailure?: string | null,
    // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
    commentsFromSchedulerAboutAppointmentFailure?: string | null,
    // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
    commentsFromSchedulerAboutCancellation?: string | null,
    partnerOrganizationBookingURL?: string | null,
    encounters?:  {
      __typename: "ModelEncounterConnection",
      nextToken?: string | null,
    } | null,
    tech?:  {
      __typename: "Tech",
      id: string,
      gender?: Gender | null,
      phone?: string | null,
      email?: string | null,
      serviceTypes?: Array< ServiceType | null > | null,
      profilePictureS3ObjectID?: string | null,
      isActive?: boolean | null,
      biographyItems?: Array< string > | null,
      patientTestimonials?: Array< string > | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      capabilities?: Array< TechCapability > | null,
      isIneligibleForMatchmaking?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    remoteTech?:  {
      __typename: "RemoteTech",
      id: string,
      email?: string | null,
      phone?: string | null,
      profilePictureS3ObjectID?: string | null,
      timezone?: Timezone | null,
      isActive: boolean,
      remoteVisitZoomLink?: string | null,
      remoteVisitZoomLinkQRCodeS3ObjectID?: string | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    coordinator:  {
      __typename: "Coordinator",
      id: string,
      authorizedTechIDs?: Array< string > | null,
      // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
      isCSR?: boolean | null,
      isActive?: boolean | null,
      managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
      authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
      phone?: string | null,
      email?: string | null,
      billingCustomerID?: string | null,
      outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
      stripeCustomerID?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    overrideMode?: AppointmentOverrideMode | null,
    // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
    // The default value is 0.5.
    sharedSlotRank: number,
    sharedSlotAppointments?:  Array< {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } > | null,
    // Represents how long the appointment should take based on product types
    estimatedServiceDurationSeconds: number,
    // For invoiced partners, the invoice line items associated with this appointment.
    stripeInvoiceLineItemIDs?: Array< string | null > | null,
    // For patient-paid appointments, any payment attempts associated with this appointment.
    stripePaymentIntentIDs?: Array< string | null > | null,
    // Whether the appointment has been confirmed or if the patient requested a change
    // When undefined or null, it means that the confirmation is still pending
    hasPatientConfirmed?: boolean | null,
    // Whether or not the appointment was booked in an unauthenticated flow
    isGuestBooked?: boolean | null,
    // Referral information from which the appointment was created (optional)
    referralID?: string | null,
    referral?:  {
      __typename: "Referral",
      id: string,
      patientID: string,
      partnerOrganization: PartnerOrganization,
      requisitionUploadS3ObjectIDs?: Array< string > | null,
      serviceTypes: Array< ServiceType >,
      isBooked?: boolean | null,
      isExpired?: boolean | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
    historyJSON?: string | null,
    // An external ID associated with the appointment, such as a job ID from MPX
    externalID?: string | null,
    // The id of the appointment that this appointment is cloned from. Cloning includes information
    // such as location, patients, and requisitions that are carried over from the original.
    clonedFromAppointmentID?: string | null,
    // The type of qualification a remote tech needs to have for this appointment.
    // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
    // Not present if the appointment has no remote component.
    remoteTechQualificationTypeRequired?: QualificationType | null,
    // Appointment date/time added to "queue"
    // This timestamp is used to determine the ordering of when remoteTech should be assigned
    markedReadyForRemoteTechAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } > | null,
};

export type ListAppointmentsByViewerPartnerOrganizationsQueryVariables = {
  input: BookedAppointmentsPartnerOrganizationInput,
};

export type ListAppointmentsByViewerPartnerOrganizationsQuery = {
  listAppointmentsByViewerPartnerOrganizations?:  Array< {
    __typename: "Appointment",
    id: string,
    // The current status of the appointment (See AppointmentStatus enum)
    status: AppointmentStatus,
    // Whether the appointment has been marked failed
    isFailed?: boolean | null,
    // Whether the appointment has at least one service completed in any of the encounters
    hasSuccessfulServices?: boolean | null,
    // Whether the appointment has at least one service failed in any of the encounters
    hasFailedServices?: boolean | null,
    // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
    needsRebooking?: boolean | null,
    // Whether the appointment has passed the pre-review phase
    isPreReviewed?: boolean | null,
    // Whether the appointment has passed the post-review phase
    isPostReviewed?: boolean | null,
    // Whether the appointment has been escalated
    isEscalated?: boolean | null,
    // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
    start: string,
    // Expected appointment end date/time
    end: string,
    // The technician assigned to the appointment
    techID?: string | null,
    remoteTechID?: string | null,
    remoteTechMatchState?: RemoteTechMatchState | null,
    coordinatorID: string,
    location:  {
      __typename: "Location",
      satellitePhotoURL?: string | null,
      streetViewPhotoURL?: string | null,
    },
    partnerOrganization?: PartnerOrganization | null,
    preReviewChecklist?:  {
      __typename: "Checklist",
    } | null,
    checklist?:  {
      __typename: "Checklist",
    } | null,
    postReviewChecklist?:  {
      __typename: "Checklist",
    } | null,
    serviceRegionName: string,
    geographicalState?: string | null,
    avoidTechIDs?: Array< string > | null,
    lastBookedAt?: number | null,
    statusUpdatedAt?: number | null,
    stripeCustomerID?: string | null,
    // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
    stripePriceToCharge?: number | null,
    hasPetsAtLocation?: boolean | null,
    // Comments about logistics like door codes, directions, etc.
    commentsFromCoordinatorAboutAppointment?: string | null,
    // Comments about logistics like door codes, directions, etc. (but from techs)
    commentsFromTechAboutAppointment?: string | null,
    // Comments about app feedback, bugs, feature requests, etc.
    commentsFromTechAboutSystem?: string | null,
    // Comments about why an appointment was put into a failed state
    commentsFromTechAboutAppointmentFailure?: string | null,
    // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
    commentsFromSchedulerAboutAppointmentFailure?: string | null,
    // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
    commentsFromSchedulerAboutCancellation?: string | null,
    partnerOrganizationBookingURL?: string | null,
    encounters?:  {
      __typename: "ModelEncounterConnection",
      nextToken?: string | null,
    } | null,
    tech?:  {
      __typename: "Tech",
      id: string,
      gender?: Gender | null,
      phone?: string | null,
      email?: string | null,
      serviceTypes?: Array< ServiceType | null > | null,
      profilePictureS3ObjectID?: string | null,
      isActive?: boolean | null,
      biographyItems?: Array< string > | null,
      patientTestimonials?: Array< string > | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      capabilities?: Array< TechCapability > | null,
      isIneligibleForMatchmaking?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    remoteTech?:  {
      __typename: "RemoteTech",
      id: string,
      email?: string | null,
      phone?: string | null,
      profilePictureS3ObjectID?: string | null,
      timezone?: Timezone | null,
      isActive: boolean,
      remoteVisitZoomLink?: string | null,
      remoteVisitZoomLinkQRCodeS3ObjectID?: string | null,
      partialAppointmentsForTeamAvailabilityJSON?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    coordinator:  {
      __typename: "Coordinator",
      id: string,
      authorizedTechIDs?: Array< string > | null,
      // If this Coordinator is a Customer Service Representative for one of our partners (should also have PartnerOrganization set)
      isCSR?: boolean | null,
      isActive?: boolean | null,
      managePartnerUsersAuthorization?: ManagePartnerUsersAuthorization | null,
      authorizedPartnerOrganizations?: Array< PartnerOrganization | null > | null,
      phone?: string | null,
      email?: string | null,
      billingCustomerID?: string | null,
      outstandingPartnerOrganizationBookingURLs?: Array< string > | null,
      stripeCustomerID?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    overrideMode?: AppointmentOverrideMode | null,
    // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
    // The default value is 0.5.
    sharedSlotRank: number,
    sharedSlotAppointments?:  Array< {
      __typename: "Appointment",
      id: string,
      // The current status of the appointment (See AppointmentStatus enum)
      status: AppointmentStatus,
      // Whether the appointment has been marked failed
      isFailed?: boolean | null,
      // Whether the appointment has at least one service completed in any of the encounters
      hasSuccessfulServices?: boolean | null,
      // Whether the appointment has at least one service failed in any of the encounters
      hasFailedServices?: boolean | null,
      // Whether the appointment has at least one service that needs to be rebooked (isRescheduleRequired) in any of the encounters
      needsRebooking?: boolean | null,
      // Whether the appointment has passed the pre-review phase
      isPreReviewed?: boolean | null,
      // Whether the appointment has passed the post-review phase
      isPostReviewed?: boolean | null,
      // Whether the appointment has been escalated
      isEscalated?: boolean | null,
      // Planned appointment start date/time. Must be in UTC since this field is used as a sort key
      start: string,
      // Expected appointment end date/time
      end: string,
      // The technician assigned to the appointment
      techID?: string | null,
      remoteTechID?: string | null,
      remoteTechMatchState?: RemoteTechMatchState | null,
      coordinatorID: string,
      partnerOrganization?: PartnerOrganization | null,
      serviceRegionName: string,
      geographicalState?: string | null,
      avoidTechIDs?: Array< string > | null,
      lastBookedAt?: number | null,
      statusUpdatedAt?: number | null,
      stripeCustomerID?: string | null,
      // For patient-paid appointments, the amount the patient will be charged for this appointment (in cents).
      stripePriceToCharge?: number | null,
      hasPetsAtLocation?: boolean | null,
      // Comments about logistics like door codes, directions, etc.
      commentsFromCoordinatorAboutAppointment?: string | null,
      // Comments about logistics like door codes, directions, etc. (but from techs)
      commentsFromTechAboutAppointment?: string | null,
      // Comments about app feedback, bugs, feature requests, etc.
      commentsFromTechAboutSystem?: string | null,
      // Comments about why an appointment was put into a failed state
      commentsFromTechAboutAppointmentFailure?: string | null,
      // Comments about why the appointment failed to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutAppointmentFailure?: string | null,
      // Comments about why the appointment was canceled to be shown publicly (e.g., to CSRs)
      commentsFromSchedulerAboutCancellation?: string | null,
      partnerOrganizationBookingURL?: string | null,
      overrideMode?: AppointmentOverrideMode | null,
      // Represents the priority for a shared-slot appointment, if it is a shared-slot. Lower value means higher priority.
      // The default value is 0.5.
      sharedSlotRank: number,
      // Represents how long the appointment should take based on product types
      estimatedServiceDurationSeconds: number,
      // For invoiced partners, the invoice line items associated with this appointment.
      stripeInvoiceLineItemIDs?: Array< string | null > | null,
      // For patient-paid appointments, any payment attempts associated with this appointment.
      stripePaymentIntentIDs?: Array< string | null > | null,
      // Whether the appointment has been confirmed or if the patient requested a change
      // When undefined or null, it means that the confirmation is still pending
      hasPatientConfirmed?: boolean | null,
      // Whether or not the appointment was booked in an unauthenticated flow
      isGuestBooked?: boolean | null,
      // Referral information from which the appointment was created (optional)
      referralID?: string | null,
      // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
      historyJSON?: string | null,
      // An external ID associated with the appointment, such as a job ID from MPX
      externalID?: string | null,
      // The id of the appointment that this appointment is cloned from. Cloning includes information
      // such as location, patients, and requisitions that are carried over from the original.
      clonedFromAppointmentID?: string | null,
      // The type of qualification a remote tech needs to have for this appointment.
      // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
      // Not present if the appointment has no remote component.
      remoteTechQualificationTypeRequired?: QualificationType | null,
      // Appointment date/time added to "queue"
      // This timestamp is used to determine the ordering of when remoteTech should be assigned
      markedReadyForRemoteTechAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } > | null,
    // Represents how long the appointment should take based on product types
    estimatedServiceDurationSeconds: number,
    // For invoiced partners, the invoice line items associated with this appointment.
    stripeInvoiceLineItemIDs?: Array< string | null > | null,
    // For patient-paid appointments, any payment attempts associated with this appointment.
    stripePaymentIntentIDs?: Array< string | null > | null,
    // Whether the appointment has been confirmed or if the patient requested a change
    // When undefined or null, it means that the confirmation is still pending
    hasPatientConfirmed?: boolean | null,
    // Whether or not the appointment was booked in an unauthenticated flow
    isGuestBooked?: boolean | null,
    // Referral information from which the appointment was created (optional)
    referralID?: string | null,
    referral?:  {
      __typename: "Referral",
      id: string,
      patientID: string,
      partnerOrganization: PartnerOrganization,
      requisitionUploadS3ObjectIDs?: Array< string > | null,
      serviceTypes: Array< ServiceType >,
      isBooked?: boolean | null,
      isExpired?: boolean | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    // The history of past events that happened to this appointment and its encounters/patients, serialized as JSON.
    historyJSON?: string | null,
    // An external ID associated with the appointment, such as a job ID from MPX
    externalID?: string | null,
    // The id of the appointment that this appointment is cloned from. Cloning includes information
    // such as location, patients, and requisitions that are carried over from the original.
    clonedFromAppointmentID?: string | null,
    // The type of qualification a remote tech needs to have for this appointment.
    // For example, this will be NURSE_PRACTITIONER if the appointment includes an AWV.
    // Not present if the appointment has no remote component.
    remoteTechQualificationTypeRequired?: QualificationType | null,
    // Appointment date/time added to "queue"
    // This timestamp is used to determine the ordering of when remoteTech should be assigned
    markedReadyForRemoteTechAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } > | null,
};

export type GenerateClaimsBillingTemplateQueryVariables = {
  input: GenerateClaimsBillingTemplateInput,
};

export type GenerateClaimsBillingTemplateQuery = {
  generateClaimsBillingTemplate?:  {
    __typename: "GenerateClaimsBillingTemplateResponse",
    billingCSV: string,
  } | null,
};

export type CalculateSpecimenContainerTypeQuantitiesQueryVariables = {
  input: CalculateSpecimenContainerTypeQuantitiesInput,
};

export type CalculateSpecimenContainerTypeQuantitiesQuery = {
  calculateSpecimenContainerTypeQuantities?:  {
    __typename: "CalculateSpecimenContainerTypeQuantitiesResponse",
    specimenContainerTypeQuantities?:  Array< {
      __typename: "SpecimenContainerTypeQuantity",
      containerName: string,
      quantity: number,
    } > | null,
  } | null,
};

export type GetContainerSpecimenVolumesQueryVariables = {
  input: GetContainerSpecimenVolumesInput,
};

export type GetContainerSpecimenVolumesQuery = {
  getContainerSpecimenVolumes?:  Array< {
    __typename: "GetContainerSpecimenVolumesResponse",
    containerOverrideID?: string | null,
    containerCategory?: SpecimenContainerCategory | null,
    minimumMilliliters?: number | null,
    preferredMilliliters?: number | null,
    isCombinable: boolean,
    minimumContainerCount?: number | null,
    specimenTestTypes?: Array< SpecimenTestType | null > | null,
    containerName?: string | null,
  } | null > | null,
};

export type GetAllContainersQueryVariables = {
};

export type GetAllContainersQuery = {
  getAllContainers?:  Array< {
    __typename: "GetAllContainersResponse",
    id: string,
    name: string,
    category: SpecimenContainerCategory,
    capacityMilliliters: number,
  } | null > | null,
};

export type ValidatePartnerUserInviteQueryVariables = {
  input?: ValidatePartnerUserInviteInput | null,
};

export type ValidatePartnerUserInviteQuery = {
  validatePartnerUserInvite?:  {
    __typename: "ValidatePartnerUserInviteResponse",
    isValid: boolean,
  } | null,
};

export type ValidateUnsubscribeFromEmailNotificationsQueryVariables = {
  input?: ValidateUnsubscribeFromEmailNotificationsInput | null,
};

export type ValidateUnsubscribeFromEmailNotificationsQuery = {
  validateUnsubscribeFromEmailNotifications?:  {
    __typename: "ValidateUnsubscribeFromEmailNotificationsResponse",
    isValid: boolean,
  } | null,
};

export type ListMatchmadeDropOffsQueryVariables = {
  input?: ListMatchmadeDropOffsInput | null,
};

export type ListMatchmadeDropOffsQuery = {
  listMatchmadeDropOffs?:  Array< {
    __typename: "ListMatchmadeDropOffsResponseItem",
    estimatedDriveTimeToDropOffSeconds: number,
    estimatedDriveTimeFromDropOffToNextRouteStopSeconds: number,
    dropOff:  {
      __typename: "DropOff",
      id: string,
      organization: DropOffOrganization,
      name: string,
      phone?: string | null,
      email?: string | null,
      // Timezone generated based on the lat/long location of this dropoff
      timezone?: Timezone | null,
      hasParking?: boolean | null,
      hasDropBox?: boolean | null,
      commentsFromScheduler?: string | null,
      googleMapsPlaceID?: string | null,
      metroName: string,
      isActive: boolean,
      createdAt: string,
      updatedAt: string,
    },
  } | null > | null,
};

export type ExportMonthlyPatientsWithAppointmentStatusQueryVariables = {
  input?: ExportMonthlyPatientsListWithAppointmentStatusInput | null,
};

export type ExportMonthlyPatientsWithAppointmentStatusQuery = {
  exportMonthlyPatientsWithAppointmentStatus?:  {
    __typename: "ExportMonthlyPatientsListWithAppointmentStatusResponse",
    patientListCSV: string,
  } | null,
};

export type SendTestNotificationQueryVariables = {
  input?: SendTestNotificationInput | null,
};

export type SendTestNotificationQuery = {
  sendTestNotification?:  {
    __typename: "SendTestNotificationResponse",
    result: string,
  } | null,
};

export type GetReferralByPatientInfoQueryVariables = {
  input: GetReferralByPatientInfoInput,
};

export type GetReferralByPatientInfoQuery = {
  getReferralByPatientInfo?:  {
    __typename: "GetReferralByPatientInfoResponse",
    referral?:  {
      __typename: "CustomAuthPartialReferral",
      id: string,
      serviceTypes: Array< ServiceType >,
    } | null,
    appointment?:  {
      __typename: "CustomAuthPartialAppointment",
      id: string,
      serviceTypes: Array< ServiceType >,
      productTypes: Array< ProductType >,
      start: string,
      end: string,
      referralID?: string | null,
      status: AppointmentStatus,
      patientEncounterSummaryPresignedURL?: string | null,
    } | null,
    patient?:  {
      __typename: "CustomAuthPartialPatient",
      phone?: string | null,
      email?: string | null,
      brandCode?: string | null,
      id?: string | null,
      partnerOrganization: PartnerOrganization,
      birthdate?: string | null,
      responsiblePractitionerPhone?: string | null,
    } | null,
    guestJWT?: string | null,
  } | null,
};

export type ExportAppointmentsListForBillingQueryVariables = {
  input: ExportAppointmentsListForBillingInput,
};

export type ExportAppointmentsListForBillingQuery = {
  exportAppointmentsListForBilling?:  {
    __typename: "ExportAppointmentsListForBillingResponse",
    listAppointmentsForBillingCSV: string,
  } | null,
};

export type GetCampaignMetricsURLQueryVariables = {
  date?: string | null,
};

export type GetCampaignMetricsURLQuery = {
  getCampaignMetricsURL?: string | null,
};

export type GetStatsigIDQueryVariables = {
  tableName: StatsigIDTableName,
  id: string,
};

export type GetStatsigIDQuery = {
  getStatsigID?:  {
    __typename: "GetStatsigIDResponse",
    id: string,
  } | null,
};

export type ExportServiceRegionCumulativeAvailabilityQueryVariables = {
};

export type ExportServiceRegionCumulativeAvailabilityQuery = {
  exportServiceRegionCumulativeAvailability?:  {
    __typename: "ExportServiceRegionCumulativeAvailabilityResponse",
    result: string,
  } | null,
};

export type GetDailyMaxPatientsToTricklePerRegionFileQueryVariables = {
};

export type GetDailyMaxPatientsToTricklePerRegionFileQuery = {
  getDailyMaxPatientsToTricklePerRegionFile: string,
};

export type GetPUTS3ObjectPresignedURLQueryVariables = {
  accessLevel: string,
  key: string,
  identityID: string,
  contentType: string,
  parentID?: string | null,
  parentType?: S3ObjectParentType | null,
};

export type GetPUTS3ObjectPresignedURLQuery = {
  getPUTS3ObjectPresignedURL:  {
    __typename: "GetPUTS3ObjectPresignedURLResponse",
    url: string,
    s3ObjectID?: string | null,
  },
};

export type GetHistoricalAvailabilityForTechQueryVariables = {
  input: GetHistoricalAvailabilityForTechInput,
};

export type GetHistoricalAvailabilityForTechQuery = {
  getHistoricalAvailabilityForTech:  {
    __typename: "GetHistoricalAvailabilityForTechResponse",
    techAvailabilityRules:  Array< {
      __typename: "TimeRangeRule",
      // The unique ID for the time range.
      id: string,
      // Whether the time range represents an addition or subtraction when considering multiple ranges.
      inclusion: TimeRangeRuleInclusion,
      // The time of day each recurrence starts (inclusive).
      start: string,
      // The time of day each recurrence ends (exclusive). Use '24:00:00' for the end of the day.
      end: string,
      // The first date that the recurrence is valid (inclusive).
      recurrenceStart: string,
      // The last date that the recurrence is valid (inclusive). If not set, the range extends indefinitely.
      recurrenceEnd?: string | null,
      // The number of periods between each recurrence. For example, with a multiple of N and period of DAY, the time range will recur every N days.
      recurrenceMultiple: number,
      // The unit of recurrence. For example, with a period of WEEK and multiple of 2, the time range will recur every other week.
      recurrencePeriod: TimeRangeRulePeriod,
      // An optional reason for the time range rule, used with time-off unavailability rules (lunch breaks, PTO, etc.)
      unavailabilityReason?: TimeRangeRuleUnavailabilityReason | null,
      // Additional notes about the time range rule.
      notes?: string | null,
      // The date and time that the rule was last updated.
      updatedAt?: string | null,
      // The user who last updated the rule.
      updatedBy?: string | null,
    } >,
    serviceRegionsAvailabilityRules:  Array< {
      __typename: "ServiceRegionAvailabilityRules",
      serviceRegionName: string,
    } >,
    partialAppointmentsForTeamAvailabilityJSON: string,
  },
};
