export interface Threshold {
  min: number;
  max: number;
}

export interface UrgencyThreshold {
  normal: Threshold;
  abnormal: Threshold;
  abnormalEscalated: Threshold;
  invalid?: Threshold;
}

export type VitalThreshold = {
  systolicBloodPressureThresholds: UrgencyThreshold;
  diastolicBloodPressureThresholds: UrgencyThreshold;
  heartRateThresholds: UrgencyThreshold;
  respirationRateThresholds: UrgencyThreshold;
  temperatureThresholds: UrgencyThreshold;
  oxygenSaturationThresholds: UrgencyThreshold;
};

export enum Vital {
  TEMPERATURE = 'TEMPERATURE',
  OXYGEN_SATURATION = 'OXYGEN_SATURATION',
  SYSTOLIC_BLOOD_PRESSURE = 'SYSTOLIC_BLOOD_PRESSURE',
  DIASTOLIC_BLOOD_PRESSURE = 'DIASTOLIC_BLOOD_PRESSURE',
  PULSE = 'PULSE',
  RESPIRATORY_RATE = 'RESPIRATORY_RATE',
}

export enum VitalRange {
  NORMAL = 'NORMAL',
  ABNORMAL = 'ABNORMAL',
  ABNORMAL_ESCALATED = 'ABNORMAL_ESCALATED',
  CRITICAL_EMERGENT = 'CRITICAL_EMERGENT',
  INVALID = 'INVALID',
}

export type VitalReading = {
  reading: number | null;
  subreading?: number;
  updatedAt?: number;
};
