import KHColors from 'khshared/KHColors';
import React, { ReactNode } from 'react';
import { StyleProp, StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';

import KHButton, { Props as KHButtonProps } from './KHButton';

const styles = {
  selector: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 8,
    marginBottom: 16,
  } as ViewStyle,
  selectorArrow: {
    width: 32,
  } as ViewStyle,
  selectorTitle: {
    width: 240,
    fontSize: 18,
    marginHorizontal: 16,
    textAlign: 'center',
    color: KHColors.text,
  } as TextStyle,
  content: {
    flex: 1,
  } as ViewStyle,
  tableBody: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'center',
  } as ViewStyle,
  element: {
    margin: -1,
    borderWidth: StyleSheet.hairlineWidth,
    borderRadius: 0,
    borderColor: KHColors.separator,
    width: 140,
    height: 50,
    backgroundColor: 'white',
  } as TextStyle,
  elementLabel: {
    fontSize: 12,
  } as TextStyle,
  title: {
    width: 140,
    textAlign: 'center',
    fontSize: 14,
    paddingBottom: 16,
    color: KHColors.textSecondary,
  } as TextStyle,
  subtitle: {
    paddingBottom: 16,
    width: 140,
    textAlign: 'center',
    fontSize: 18,
    color: KHColors.textDark,
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderColor: KHColors.separator,
    margin: -1,
  } as TextStyle,
  labelColumn: {
    marginTop: 28,
  } as ViewStyle,
  labelColumnLabel: {
    textAlign: 'right',
    paddingRight: 8,
    color: KHColors.textSecondary,
    height: 48,
    minHeight: 48,
  } as TextStyle,
};

function KHButtonTable({
  pageTitle,
  onPageLeft,
  onPageRight,
  children,
  style,
}: {
  pageTitle?: string;
  onPageLeft: () => void;
  onPageRight: () => void;
  children: ReactNode;
  style?: StyleProp<ViewStyle>;
}) {
  return (
    <View style={[styles.content, style]}>
      <View style={styles.selector}>
        <Text style={styles.selectorTitle}>{pageTitle}</Text>
        <View style={styles.selectorArrow}>
          <KHButton textOnly icon="chevron-left" onPress={onPageLeft} testID="go-to-left-button" />
        </View>
        <View style={styles.selectorArrow}>
          <KHButton
            textOnly
            icon="chevron-right"
            onPress={onPageRight}
            testID="go-to-right-button"
          />
        </View>
      </View>
      <View style={styles.tableBody}>{children}</View>
    </View>
  );
}

const LabelColumn = ({
  children,
  style,
}: {
  children: ReactNode;
  style?: StyleProp<ViewStyle>;
}) => <View style={[styles.labelColumn, style]}>{children}</View>;

const Label = ({ children, style }: { children: ReactNode; style?: StyleProp<TextStyle> }) => (
  <Text style={[styles.labelColumnLabel, style]}>{children}</Text>
);

const Column = ({
  title,
  subtitle,
  children,
  style,
}: {
  title?: string | ReactNode;
  subtitle?: string;
  children: ReactNode;
  style?: StyleProp<ViewStyle>;
}) => (
  <View style={style}>
    <Text style={styles.title}>{title}</Text>
    <Text style={styles.subtitle}>{subtitle}</Text>
    {children}
  </View>
);

function Element<E extends `${string}ButtonClick`>(props: KHButtonProps<E>) {
  const { style, labelStyle } = props;
  return (
    <KHButton<E>
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      style={[styles.element, style]}
      labelStyle={[styles.elementLabel, labelStyle]}
    />
  );
}

export default Object.assign(KHButtonTable, { LabelColumn, Label, Column, Element });
