import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, FontAwesomeIconStyle } from '@fortawesome/react-native-fontawesome';
import KHColors from 'khshared/KHColors';
import React from 'react';
import {
  Image,
  ImageSourcePropType,
  ImageStyle,
  StyleProp,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';

import { alertIfFontAwesomeIconNotImported } from '../utils/setupFontAwesome';
import useTooltipRef from '../utils/useTooltipRef';

const fontAwesomeIconPrefixes: { [key in NonNullable<Props['iconSource']>]: IconPrefix } = {
  'font-awesome-solid': 'fas',
  'font-awesome-light': 'fal',
  'font-awesome-regular': 'far',
};

type Props = {
  iconSource?: 'font-awesome-solid' | 'font-awesome-light' | 'font-awesome-regular';
  color?: string;
  size: 8 | 12 | 16 | 20 | 24 | 32 | 48 | 64 | 96;
  tooltip?: string;
  source: string | ImageSourcePropType;
  style?: StyleProp<TextStyle> | StyleProp<ImageStyle> | StyleProp<ViewStyle>;
};

export default function KHIcon({
  color = KHColors.icon,
  size,
  source,
  tooltip,
  style,
  iconSource,
}: Props): JSX.Element {
  const tooltipRefMaterialCommunityIcon = useTooltipRef<MaterialCommunityIcon>(tooltip);
  const tooltipRefFontAwesomeIcon = useTooltipRef<View>(tooltip);
  const tooltipRefImage = useTooltipRef<Image>(tooltip);

  if (iconSource?.startsWith('font-awesome-')) {
    const prefix = fontAwesomeIconPrefixes[iconSource];
    alertIfFontAwesomeIconNotImported(prefix, String(source));
    // FontAwesome doesn't support refs natively. Setting the ref to a View component wrapping it.
    return (
      // pointerEvents=none prevents the SVG created by fontawesome from being focused on click.
      <View ref={tooltipRefFontAwesomeIcon} pointerEvents="none">
        <FontAwesomeIcon
          icon={[prefix, source as IconName]}
          color={color}
          size={size}
          style={style as FontAwesomeIconStyle}
        />
      </View>
    );
  }

  if (typeof source === 'string') {
    return (
      <MaterialCommunityIcon
        ref={tooltipRefMaterialCommunityIcon}
        name={source}
        color={color}
        size={size}
        style={style}
      />
    );
  }

  return (
    <Image
      ref={tooltipRefImage}
      source={source}
      style={[
        {
          width: size,
          height: size,
          tintColor: color,
          resizeMode: 'contain',
        },
        style as StyleProp<ImageStyle>,
      ]}
    />
  );
}
