import i18n, { PostProcessorModule } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {
  I18N_FALLBACK_LNG,
  I18N_FRONTEND_NAMESPACE_JSON_OBJECTS_BY_LANGUAGE,
  I18N_SUPPORTED_LNGS_FRONTEND,
  I18N_SUPPORTED_NAMESPACES_FRONTEND,
} from 'khshared/i18nConstants';
import { initReactI18next } from 'react-i18next';
import { Platform } from 'react-native';

// custom middleware to throw exceptions for unresolved placeholders
const UNRESOLVED_PLACEHOLDER: PostProcessorModule = {
  type: 'postProcessor',
  name: 'unresolvedPlaceholder',

  process(value: string) {
    // Check for unresolved placeholders
    const unresolvedPlaceholders = value.match(/{{\s?(\w+)\s?}}/g);

    // If there are unresolved placeholders, throw an error
    if (unresolvedPlaceholders) {
      throw new Error(
        `Unresolved placeholders found in translation: ${unresolvedPlaceholders.join(', ')}`,
      );
    }

    return value;
  },
};

export const i18nPromise = (window?.location == null || Platform.OS !== 'web'
  ? i18n.use(initReactI18next)
  : i18n.use(LanguageDetector).use(initReactI18next)
)
  .use(UNRESOLVED_PLACEHOLDER)
  .init({
    postProcess: ['unresolvedPlaceholder'],
    initImmediate: true,
    load: 'languageOnly', // Without this option, i18next was selecting the en language when the navigator languages were ['es-CO', 'en-US', 'en']. This may be an issue with how my browser is configured but this seems more reliable.
    fallbackLng: I18N_FALLBACK_LNG,
    ns: I18N_SUPPORTED_NAMESPACES_FRONTEND,
    resources: I18N_FRONTEND_NAMESPACE_JSON_OBJECTS_BY_LANGUAGE,
    supportedLngs: I18N_SUPPORTED_LNGS_FRONTEND,
    returnEmptyString: false, // When a translation file contains an empty string due to missing translation, allows the fall back to be used
    saveMissing: false,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['localStorage', 'navigator', 'querystring'],
      caches: ['localStorage'],
    },
  });

export default i18n;
