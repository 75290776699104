// currently these values were manually added as needed
// in the future this file will be autogenerated based on an export from figma

export const ColorSecondaryDark = '#7393a5';
export const ColorSecondaryMain = '#93b0c0';
export const ColorSecondaryLight = '#b0cad9';
export const ColorSecondaryLighter = '#d8e3e9';
export const ColorSecondaryLightest = '#e9eef0';
export const ColorNeutralDarkest = '#1e1e1e';
export const ColorNeutralDark = '#7e849d';
export const ColorNeutralMain = '#a5a9bb';
export const ColorNeutralLighter = '#d9dadd';
export const ColorNeutralLightest = '#f3f4f5';
export const ColorTypeDarkest = '#1e1e1e';
export const ColorTypeDarker = '#5c677e';
export const ColorTypeDark = '#7e849d';
export const ColorTypeInverse = '#ffffff';
export const ColorBackgroundPaper = '#ffffff';
export const ColorBackgroundDefault = '#f5f5f5';
export const ColorActionTaskActiveType = '#425365';
export const ColorButtonPrimaryActive = '#502997';
export const ColorPrimaryDarker = '#5d4b9f';
export const ColorPrimaryDark = '#806ad1';
export const ColorPrimaryLightest = '#f7f1ff';
export const ColorCriticalDarker = '#9b2c13';
export const ColorCriticalLight = '#efb6b6';
export const ColorCriticalLightest = '#ffe8e8';
export const ColorWarningDarkest = '#664010';
export const ColorWarningDarker = '#714918';
export const ColorWarningDark = '#af6d09';
export const ColorWarningMain = '#cfa05a';
export const ColorWarningLight = '#f5bc67';
export const ColorWarningLightest = '#ffefc5';
export const ColorHighlightDarkest = '#42512a';
export const ColorHighlightMain = '#9ab46f';
export const ColorHighlightLight = '#B9CB9B';
export const ColorHighlightLightest = '#e8f0ca';
export const ColorTabsTextActive = '#3b5e72';
export const ColorCloseButton = '#605f78';
export const ColorFilterButtonV2BackgroundHover = '#9257fe';
export const ColorFilterButtonV2BackgroundSelected = '#3b5e72';
export const ColorTaskFontSelected = '#2c4556';

export const ColorLightPine = '#D4DBBC';
export const ColorPine = '#69724B';
export const ColorBrandTertiaryDarkest = '#663E1A';

// colors for each sprinter on the team availability page

export const SprinterColors = [
  { lighter: '#ffd5ba', darker: '#b04e13' }, // Rainbow Sienna
  { lighter: '#f7c0dd', darker: '#985789' }, // Rainbow Orchid
  { lighter: '#b2d5ef', darker: '#315b7b' }, // Rainbow Danube
  { lighter: '#b6ecec', darker: '#4b7070' }, // Rainbow Gulf
  { lighter: '#d9e9a9', darker: '#636e43' }, // Rainbow Chelsea
  { lighter: '#f8df8c', darker: '#7f6204' }, // Rainbow Jasmine
  { lighter: '#e5d1c3', darker: '#745c48' }, // Rainbow Zorba
  { lighter: '#ffcab1', darker: '#be3014' }, // Rainbow Apricot
  { lighter: '#dbd0f9', darker: '#7663a8' }, // Rainbow Biloba
  { lighter: '#a6d4f7', darker: '#37709c' }, // Rainbow Glacier️
  { lighter: '#c0d7cc', darker: '#0d703f' }, // Rainbow Bay
  { lighter: '#d9da9b', darker: '#6e7004' }, // Rainbow Husk
  { lighter: '#fada94', darker: '#916403' }, // Rainbow Gold
  { lighter: '#d9dadd', darker: '#5c677e' }, // Rainbow Pearl
  { lighter: '#e9cac8', darker: '#99554e' }, // Rainbow Brandy
  { lighter: '#c6cdef', darker: '#4d64d3' }, // Rainbow Portage
  { lighter: '#aedae8', darker: '#387386' }, // Rainbow Neptune
  { lighter: '#c0d7ae', darker: '#57773b' }, // Rainbow Tea
  { lighter: '#ece19e', darker: '#71692a' }, // Rainbow Mongoose
  { lighter: '#f5d6ab', darker: '#a05d0a' }, // Rainbow Twine
];
