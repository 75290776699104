import email_en from './locales/en/email.json';
import referrals_flow_en from './locales/en/referralsFlow.json';
import sms_en from './locales/en/sms.json';
import email_es from './locales/es/email.json';
import referrals_flow_es from './locales/es/referralsFlow.json';
import sms_es from './locales/es/sms.json';

export const I18N_FALLBACK_LNG = 'en';

export const I18N_SUPPORTED_LNGS_BACKEND: string[] = ['en', 'es'];
export const I18N_SUPPORTED_LNGS_FRONTEND: string[] = ['en', 'es'];

export const I18N_ALL_SUPPORTED_LNGS: string[] = [
  ...new Set([...I18N_SUPPORTED_LNGS_FRONTEND, ...I18N_SUPPORTED_LNGS_BACKEND]),
];

export const I18N_SUPPORTED_NAMESPACES_BACKEND: string[] = ['sms', 'email'];
export const I18N_SUPPORTED_NAMESPACES_FRONTEND: string[] = ['referralsFlow'];

export const I18N_ALL_SUPPORTED_NAMESPACES: string[] = [
  ...new Set([...I18N_SUPPORTED_NAMESPACES_FRONTEND, ...I18N_SUPPORTED_NAMESPACES_BACKEND]),
];

export const I18N_FRONTEND_NAMESPACE_JSON_OBJECTS_BY_LANGUAGE: Record<
  string,
  Record<string, Record<string, string>>
> = {
  en: { referralsFlow: referrals_flow_en },
  es: { referralsFlow: referrals_flow_es },
};

export const I18N_BACKEND_NAMESPACE_JSON_OBJECTS_BY_LANGUAGE: Record<
  string,
  Record<string, Record<string, string>>
> = {
  en: { sms: sms_en, email: email_en },
  es: { sms: sms_es, email: email_es },
};

export const getAllNamespaceJsonObjectsByLanguage = (): Record<
  string,
  Record<string, Record<string, string>>
> => {
  const allNamespaceJsonObjectsByLanguage: Record<
    string,
    Record<string, Record<string, string>>
  > = {};
  for (const lng of I18N_ALL_SUPPORTED_LNGS) {
    allNamespaceJsonObjectsByLanguage[lng] = {
      ...I18N_FRONTEND_NAMESPACE_JSON_OBJECTS_BY_LANGUAGE[lng],
      ...I18N_BACKEND_NAMESPACE_JSON_OBJECTS_BY_LANGUAGE[lng],
    };
  }
  return allNamespaceJsonObjectsByLanguage;
};

export const LOKALISE_DEV_PROJECT_ID = '3460092864a87400d01623.47375839';
export const LOKALISE_PROD_PROJECT_ID = '9028054764af3a84674431.19892128';

export const LOKALISE_KEY_LIST_MAX_NUM_OF_KEYS_PER_RESPONSE = 500;
