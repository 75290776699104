import KHColors from 'khshared/KHColors';
import React, { ReactNode, useContext, useState } from 'react';
import { StyleProp, Text, TextStyle, View, ViewStyle } from 'react-native';

import KHButton from './KHButton';
import KHChip from './KHChip';
import KHErrorText from './KHErrorText';
import KHMessageField from './KHMessageField';
import KHThemeContext from './KHThemeContext';

const CHIP_MARGIN = 12;

const styles = {
  labelRow: {
    flexDirection: 'row',
  } as ViewStyle,
  label: {
    flexShrink: 1,
  } as TextStyle,
  explanationButton: {
    marginLeft: 12,
    marginTop: -4, // Centers the icon without pushing other content down.
    marginBottom: -4, // Centers the icon without pushing other content down.
  } as ViewStyle,
  explanation: {
    marginHorizontal: 0,
    marginTop: 8,
    marginBottom: 0,
  } as ViewStyle,
  chipRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginRight: -CHIP_MARGIN, // Trick to ensure that chips don't wrap too early.
  } as ViewStyle,
  chip: {
    marginTop: 8,
    marginRight: CHIP_MARGIN,
  } as ViewStyle,
  error: {
    marginTop: 4,
  } as ViewStyle,
  labelV2: {
    fontFamily: 'Red Hat Display',
    fontSize: 14,
    fontWeight: '500',
  } as TextStyle,
};

interface Option<V> {
  label: ReactNode;
  value: V;
  selected: boolean;
}

interface Props<V> {
  disabled?: boolean;
  error?: React.ComponentProps<typeof KHErrorText>['error'];
  label: ReactNode;
  labelStyle?: StyleProp<TextStyle>;
  titleStyle?: StyleProp<TextStyle>;
  explanation?: ReactNode;
  isExplanationShownByDefault?: boolean;
  explanationStyle?: StyleProp<ViewStyle>;
  onPress: (value: V) => void;
  style?: StyleProp<ViewStyle>;
  options: Option<V>[];
  multipleOptions?: boolean;
  messageFieldChildren?: ReactNode;
  testID?: string;
}

/**
 * NOTE: when using with `multipleOptions` parameter don't forget to sort values manually
 */

export default function KHOptionsField<V>({
  disabled = false,
  error,
  label,
  labelStyle,
  titleStyle,
  explanation,
  explanationStyle,
  onPress,
  style,
  options,
  multipleOptions = false,
  isExplanationShownByDefault = false,
  messageFieldChildren,
  testID,
}: Props<V>): JSX.Element {
  const theme = useContext(KHThemeContext);

  const [isExplanationShown, setIsExplanationShown] = useState<boolean>(
    false || isExplanationShownByDefault,
  );
  return (
    <>
      <View style={style} testID={testID}>
        <View style={styles.labelRow}>
          <Text style={[styles.label, labelStyle, titleStyle]}>
            {label}
            {multipleOptions && ' (select all)'}
          </Text>
          {Boolean(explanation) && (
            <KHButton
              textOnly
              icon="information-outline"
              iconColor={KHColors.iconInformation}
              onPress={() => setIsExplanationShown(!isExplanationShown)}
              style={styles.explanationButton}
            />
          )}
        </View>
        {isExplanationShown && (
          <KHMessageField
            title="Why do we ask?"
            message={explanation}
            type="information"
            onClosePress={() => setIsExplanationShown(!isExplanationShown)}
            style={[styles.explanation, explanationStyle]}
          >
            {messageFieldChildren}
          </KHMessageField>
        )}
        <View style={styles.chipRow}>
          {options.map((o) => (
            <KHChip
              key={String(o.value)}
              style={styles.chip}
              labelStyle={[labelStyle, theme === 'v2' && styles.labelV2]}
              disabled={disabled}
              selected={o.selected}
              label={o.label}
              onPress={() => onPress(o.value)}
            />
          ))}
        </View>
        <KHErrorText error={error} style={styles.error} />
      </View>
    </>
  );
}
